import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const SidebarDisplayFilterStyle = makeStyles({
    
    nameContainer: {
      marginTop: '5%', 
      marginLeft: '0%',
      position: 'absolute',
      fontFamily:'Ebrima',
    },
    card: {
        minWidth: 180,
        cursor: 'pointer',
        fontFamily:'Ebrima',
      },
      bullet: {
        display: 'inline-block',
        margin: '10px 2px',
        transform: 'scale(0.8)',
        fontFamily:'Ebrima',
      },
      title: {
        fontSize: 14,
        fontFamily:'Ebrima',
      },
      pos: {
        marginBottom: 2,
        fontFamily:'Ebrima',
      },
      body2: {
        marginBottom: 8,
        fontFamily:'Ebrima',
      },
});

export default SidebarDisplayFilterStyle;
import React, { useEffect, useState } from 'react';
import PropertyInventoryGroupCard from './PropertyInventoryGroupCard';
import PropertyInventorySectionDropdown from './SubComponents/PropertyInventorySectionDropdown';
import PropertyNoInventoryCard from './SubComponents/PropertyNoInventoryCard';
import {
    List,
    Grid,
    Typography,
    Paper,
    IconButton,
    Collapse,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: 'none',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
}));

export default function PropertyInventoryList(props) {

    const [inventorySelected, setInventorySelected] = useState(null);
    const [inventoryGroupsList, setInventoryGroupsList] = useState([]);
    const [formattedTotal, setFormattedTotal] = useState(0);
    const {
        handleInventoryCategorySelected,
        selectedProperty,
        retrievedSelectedInventoryGroups,
        retrieveSelectedInventorySection,
        retrieveByGroup,
        highlightSelectedInventory,
        selectedInventory,
        inventoryGroups,
        selectedInventorySection,
        selectedInventorySections,
        inventoryTotal,
        filterByGroup,
        displayInventory,
    } = props;

    const {
        DefImages,
        InventoryCM,
    } = selectedProperty;

    new Intl.NumberFormat('en').format

    const formatter = new Intl.NumberFormat('en', {
    });

    const handleInventorySelected = () => {
        setInventorySelected(!inventorySelected);
    };

    useEffect(() => {
        setInventoryGroupsList(inventoryGroups);

        handleInventoryCategorySelected(inventorySelected);
    }, [inventorySelected]);

    useEffect(() => {
        setInventoryGroupsList(inventoryGroups);

    }, [inventoryGroups]);

    useEffect(() => {
        // console.log('a')
        if (inventoryTotal) {
            // console.log('b')
            if (inventoryTotal != 'N/A') {

                const formatTotal = formatter.format(inventoryTotal);
                setFormattedTotal(formatTotal);
            }
        }
    }, [inventoryTotal]);

    useEffect(() => {
         console.log(filterByGroup)
    }, [filterByGroup]);

    // console.log(inventoryTotal)

    return (
        <Grid id='inventory-list-container' container direction='row' justify='center' alignItems='flex-start'>
            <Grid item xs={12} style={{ height: '100%' }}>
                <PropertyInventorySectionDropdown
                    selectedProperty={selectedProperty}
                    retrieveSelectedInventorySection={retrieveSelectedInventorySection}
                    retrieveByGroup={retrieveByGroup}
                    filterByGroup={filterByGroup}
                    displayInventory={displayInventory}
                />
               
                {displayInventory ?
                    <List style={{ width: '100%', padding: '1%' }}>
                        {inventoryGroupsList.length > 0 ?
                            inventoryGroupsList.map((inventoryGroup, i) => {
                                const groupCost = inventoryGroup.GroupCost;
                                return (
                                    <PropertyInventoryGroupCard
                                        i={i}
                                        key={'inventory' + i}
                                        inventoryGroup={inventoryGroup}
                                        groupCost={groupCost}
                                        inventoryImages={DefImages}
                                        retrievedSelectedInventoryGroups={retrievedSelectedInventoryGroups}
                                        highlightSelectedInventory={highlightSelectedInventory}
                                        selectedInventory={selectedInventory}
                                        selectedInventorySection={selectedInventorySection}
                                        selectedInventorySections={selectedInventorySections}
                                    />
                                )
                            })
                            : <PropertyNoInventoryCard />
                        }
                    </List>
                    : null}
            </Grid>
        </Grid>
    )
}
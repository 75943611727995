import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Grid,
  Paper,
  List,
  ListItem,
  Typography,
  IconButton,
  Collapse,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  listItem: {
    fontFamily: 'Ebrima',
  }
}));

export default function PropertySections(props) {
  const [documents, setDocuments] = useState([]);
  const [numberOfDocuments, setNumberOfDocuments] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const { selectedProperty } = props;

  const classes = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenFile = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
  };

  useEffect(() => {
    if (selectedProperty) {
      const propertyDocuments = selectedProperty.Files;
      setDocuments(propertyDocuments);
      setNumberOfDocuments(propertyDocuments.length);
    };
  }, [selectedProperty]);

  return (
    <Paper style={{ maxHeight: '15rem', marginBottom: '5%', marginLeft: '1%', boxShadow: 'none', backgroundColor: 'rgb(233, 233, 233)' }}>
      <Grid container direction={'row'} alignItems={'flex-end'} justify={'flex-start'}>
        <Grid direction={'row'} style={{ display: 'flex' }}>
          <Typography variant='h6' style={{fontFamily: 'Ebrima',}}>Property Documents <span style={{ color: 'rgba(0, 0, 0, 0.26)', fontFamily: 'Ebrima', }}>({numberOfDocuments})</span></Typography>
          <IconButton
            size={'small'}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            disabled={numberOfDocuments == 0}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} style={{ height: '90%' }}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List style={{ width: '100%', maxHeight: '10rem', overflowY: 'auto', backgroundColor: 'white' }}>
              { documents.map((document) => {
                const fileName = document.FileName;
                const url = document.FileUrl;
                return (
                  <ListItem button onClick={()=>handleOpenFile(url)}>
                    <ListItemIcon>
                      <InsertDriveFileIcon />
                    </ListItemIcon>
                    <ListItemText primary={fileName } classes={{primary:classes.listItem}} />
                  </ListItem>
                )
              }) }
            </List>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  )
}


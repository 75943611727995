import React, { useEffect, useState } from 'react';
import {
  Avatar,
  List,
  ListItem,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
  Grid,
  Typography,
  Collapse,
  IconButton,
  Divider,
  Chip,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
    margin: '2%',
    overflowY: 'hidden',
  },
  media: {
    paddingTop: '74.25%',
    objectFit: 'contain',
    minHeight: '8rem',
    margin: '1%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  chip: {
    label: {
      paddingLeft: '3px',
      paddingRight: '3px',
    }
  }
}));

export default function PropertyResultsCard(props) {
  const [selectedToCompanyName, setSelectedToCompanyName] = useState('');
  const [selectedToTags, setSelectedToTags] = useState([]);
  const {
    toSelectedCompany,
    selectedTags,
  } = props;

  useEffect(() => {
    if (toSelectedCompany) {
      const toSelectedCompanyExists = Object.values(toSelectedCompany).length;
      if (toSelectedCompanyExists) {
        const {
          Name,
        } = toSelectedCompany;
        setSelectedToCompanyName(Name);
      }
    }
  }, [toSelectedCompany]);

  useEffect(() => {
    if (selectedTags) {
      const selectedTagsExist = selectedTags.length;
      if (selectedTagsExist) {
        setSelectedToTags(selectedTags);
      } else {
        setSelectedToTags([]);
      }
    }
  }, [selectedTags]);

  const classes = useStyles();
  return (
    <Card key={`from-card`} className={classes.card}>
      <Grid container>
        <Grid container direction='row'>
          <Grid item xs={12}>
            <Grid container direction='row'>
              <Grid item xs={12}>
                <CardHeader
                  style={{ padding: 0, textAlign: 'center' }}
                  title={<Typography gutterBottom={false}><span style={{ color: 'darkGrey', fontSize: '95%' }}>Company: </span>{selectedToCompanyName}</Typography>}
                />
              </Grid>

              <Grid item xs={12} style={{ padding: '1%' }}>
                <Divider style={{ marginTop: '2%', marginBottom: '2%' }} />
                <CardContent style={{ padding: '0', textAlign: 'center' }}>
                  {selectedToTags.map((selectedTag, i) => {
                    const name = selectedTag.Title;
                    return (
                      <li key={`move-buildings-tag-list-item-${i}`}>{name}</li>
                    )
                  })}
                </CardContent>
                <Divider style={{ marginTop: '2%', marginBottom: '2%' }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
import React, { useState, useEffect, useRef } from 'react';
import WorkOrderCostItemsTable from './SubComponents/WorkOrderCostItemsTable';
import {
  Paper,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';

const textFieldStyle = {
  width: '90%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export default function WorkOrderCostItem(props) {
  const [ category, setCategory ] = useState(null);
  const [ description, setDescription ] = useState(null);
  const [ quantity, setQuantity ] = useState(null);
  const [ unitPrice, setUnitPrice ] = useState(null);
  const [ disableAdd, setDisableAdd ] = useState(true);
  const [ createdCostItems, setCreatedCostItems ] = useState([]);
  const { currentStep, previousCurrentStep, editingWorkOrderItem, editedWorkOrderItem, createdWorkOrderItem, handleRemoveCostItem, retrieveSelectedCostItems } = props;

  const handleCostCategoryChange = e => {
    const value = e.target.value;
    setCategory(value);
  };

  const handleDescriptionChange = e => {
    const value = e.target.value;
    setDescription(value);
  };

  const handleQuantityChange = e => {
    const value = e.target.value;
    setQuantity(value);
  };

  const handleUnitPriceChange = e => {
    const value = e.target.value;
    setUnitPrice(value);
  };

  const handleResetInputs = () => {
    setCategory('');
    setDescription('');
    setQuantity('');
    setUnitPrice('');
  };

  const handleAdd = (e) => {
    const createdCostItemsCopy = [...createdCostItems];
    const createdWorkOrderCostItem = {
      WoitemDetailId: -1,
      WoitemId: editingWorkOrderItem ? editingWorkOrderItem.WoitemId : createdWorkOrderItem.WoitemId,
      Category: category,
      Description: description,
      Quantity: parseFloat(quantity),
      Uom: 'ea', 
      UnitPrice: parseFloat(unitPrice),
    };
    createdCostItemsCopy.push(createdWorkOrderCostItem);
    setCreatedCostItems(createdCostItemsCopy);
    handleResetInputs();
  };

  const createExistingCostItems = (editingWorkOrderItem) => {
    const createdCostItemsCopy = [...createdCostItems];

    const {
      WoItemDetail,
    } = editingWorkOrderItem;

    WoItemDetail.map((workOrderItemDetail) => {
      const {
        WoitemDetailId,
        WoitemId,
        Category,
        Description,
        Quantity,
        Uom,
        UnitPrice,
      } = workOrderItemDetail;

      const createdWorkOrderCostItem = {
        WoitemDetailId,
        WoitemId,
        Category,
        Description,
        Quantity,
        Uom,
        UnitPrice,
      };
      createdCostItemsCopy.push(createdWorkOrderCostItem);
    })
    return createdCostItemsCopy;
  };

  const removeCreatedCostItems = (costItem) => {
    const costItemId = costItem.WoitemDetailId;
    const createdCostItemsCopy = [...createdCostItems];
    const itemToDeleteIndex = createdCostItemsCopy.findIndex(createdCostItem => JSON.stringify(createdCostItem) == JSON.stringify(costItem));
    createdCostItemsCopy.splice(itemToDeleteIndex, 1);
    setCreatedCostItems(createdCostItemsCopy);
        
    if (costItemId != -1) {
      handleRemoveCostItem(costItem)
    }
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousCreatedCostItems = usePrevious(createdCostItems);
  useEffect(() => {
    if (previousCreatedCostItems != createdCostItems) {
      retrieveSelectedCostItems(createdCostItems);
    }
  }, [createdCostItems]);

  useEffect(() => {
    if (editingWorkOrderItem) {
      const existingWorkOrderItems = createExistingCostItems(editingWorkOrderItem);
      setCreatedCostItems(existingWorkOrderItems);
      handleResetInputs();
    }
  }, [editingWorkOrderItem]);

  useEffect(() => {
    if (editedWorkOrderItem) {
     const editedItems = editedWorkOrderItem.WoItemDetail;
     const editingItems = editingWorkOrderItem.WoItemDetail;
      if (currentStep != previousCurrentStep) {
        if (JSON.stringify(editedItems) != JSON.stringify(editingItems)) {
         setCreatedCostItems(editedItems);
        }
      }
    }
  }, [editedWorkOrderItem]);

  useEffect(() => {
    if (category && description && quantity && unitPrice) {
      setDisableAdd(false);
    } else {
      setDisableAdd(true);
    }
  }, [category, description, quantity, unitPrice]);

  return (
      <Grid container direction='row' alignItems='center' justify='center'>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            id="filled-uncontrolled"
            label="Cost Category"
            variant="filled"
            value={ category }
            style={ textFieldStyle }
            onChange={ handleCostCategoryChange }
            InputLabelProps={{
              shrink: category ? true : false,
            }}
          />
         </Grid>
      
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            id="filled-uncontrolled"
            label="Description"
            variant="filled"
            value={description}
            style={textFieldStyle}
            onChange={handleDescriptionChange}
            InputLabelProps={{
              shrink: description ? true : false,
            }}
          />    
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            id="filled-uncontrolled"
            label="Quantity"
            variant="filled"
            style={textFieldStyle}
            value={quantity}
            onChange={handleQuantityChange}
            type='number'
            InputLabelProps={{
              shrink: quantity ? true : false,
            }}
          />    
        </Grid>
        <Grid item item xs={12} sm={12} md={2} lg={2} xl={2}>
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            id="filled-uncontrolled"
            label="Unit Price"
            variant="filled"
            style={textFieldStyle}
            value={unitPrice}
            onChange={handleUnitPriceChange}
            type='number'
            InputLabelProps={{
              shrink: unitPrice ? true : false,
            }}
          />    
        </Grid>
        <Grid item item xs={12} sm={12} md={2} lg={2} xl={2}>
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            disabled={true}
            id="filled-uncontrolled"
            label="Est. Total"
            variant="filled"
            style={textFieldStyle}
            value={unitPrice * quantity}
            type='number'
          />   
        </Grid>
        <Grid item xs={1}>
          <Button variant='contained' color='primary' onClick={handleAdd} disabled={disableAdd}> Add </Button>
        </Grid>
        <Grid container direction='row' style={{marginTop: '2%'}}>
          <Grid item xs={12} style={{ height: '100%', maxHeight: '17rem', overflow: 'auto' }}>
            <WorkOrderCostItemsTable 
              handleRemoveCostItem={handleRemoveCostItem}
              removeCreatedCostItems={removeCreatedCostItems}
              createdCostItems={createdCostItems}
            />
          </Grid>
        </Grid>
      </Grid>

  )
};
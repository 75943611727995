import React, { useState, useEffect, useRef } from 'react';
import {
  List,
  ListItem,
  ListSubheader,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'inline-block', 
    height: '100%', 
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    fontFamily: 'Ebrima'
  },
}));

export default function CheckboxList(props) {
  const [ propertyOptions, setProperties ] = useState([]);
  const [ tagOptions, setTags ] = useState([]);
  const [ CMSR, setCMSR ] = useState(false);
  const [ checked, setChecked ] = useState(null);
  const {
    localProperties,
    retrieveSelectedPropertyId,
    selectedReportType,
    selectedPropertyId,
    localUserTags,
  } = props;

  const handleSelect = (selected) => {
    if (checked == selected) {
      setChecked(null);
    } else {
      setChecked(selected)
    }
  }; 

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if (checked) {
      retrieveSelectedPropertyId(checked)
    } else {
      retrieveSelectedPropertyId(0);
    }
  }, [checked]);

  const previousSelectedPropertyId = usePrevious(selectedPropertyId);
  useEffect(() => {
    if (!selectedPropertyId) {
      if (previousSelectedPropertyId) {
        setChecked(null);
      }
    }
  }, [selectedPropertyId]);

  useEffect(() => {
    if (localUserTags) {
     setTags(localUserTags);
    }
  }, [localUserTags]);

  useEffect(() => {
    if (localProperties) {
      setProperties(localProperties);
    }
  }, [localProperties]);

  useEffect(() => {
    if (selectedReportType) {
      const CMSRId = 8;
      if (selectedReportType == CMSRId) {
        setCMSR(true);
      } else {
        setCMSR(false);
      }
    }
  }, [selectedReportType]);

  const classes = useStyles();
  return (
    <List className={classes.root}> 
      { CMSR ? 
        tagOptions.map(tag => {
          const tagId = tag.OrgUnitId;
          const tagTitle = tag.Title;
          const tagDescription = tag.Description;
          const tagCompanyId = tag.CompanyId;
          const labelId = `checkbox-list-label-${tagId}`;

          return (
            <ListItem key={tagId} role={undefined} dense button onClick={() => handleSelect(tagId)} style={{fontFamily: 'Ebrima'}}>
              <ListItemIcon style={{fontFamily: 'Ebrima'}}>
                <Checkbox
                  edge="start"
                  checked={checked == tagId}
                  tabIndex={-1}
                  disableRipple
                  inputprops={{ 'aria-labelledby': labelId }}
                  style={{fontFamily: 'Ebrima'}}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={tagTitle} classes={{primary:classes.root}}/>
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments">
                  <CommentIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })
        :
        propertyOptions.map(property => {
        const name = property.Name;
        const propertyId = property.Id;
        const labelId = `checkbox-list-label-${propertyId}`;
          return (
            <ListItem key={propertyId} role={undefined} dense button onClick={()=>handleSelect(propertyId)} style={{fontFamily: 'Ebrima'}}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked==propertyId}
                  tabIndex={-1}
                  disableRipple
                  inputprops={{ 'aria-labelledby': labelId }}
                  style={{fontFamily: 'Ebrima'}}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={name} classes={{primary:classes.root}}/>
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments">
                  <CommentIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        }) 
      }
    </List>
  );
}

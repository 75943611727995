import { makeStyles } from '@material-ui/core/styles';
import { blue600, grey900 } from 'material-ui/styles/colors';

const useStyles = makeStyles(theme => ({ 
  appBar: {
    height: 57,
    color: blue600,
    fontFamily: 'Ebrima',
  },
  drawer: {
    width: 230,
    color: grey900,
    fontFamily: 'Ebrima',
  },
  raisedButton: {
    primaryColor: blue600,
    fontFamily: 'Ebrima',
  }
 }));

 export default useStyles
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Slider,
  Button,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

export default function DiscreteSlider(props) {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const { recordCount, selectPageSize } = props;

  const handlePageSize = (event, newValue) => {
    selectPageSize(newValue);
  };

  const PrettoSlider = withStyles({
    root: {
      color: '#0672BA',
      height: 4,
      fontFamily: 'Ebrima',
    },
    thumb: {
      height: 12,
      width: 12,
      backgroundColor: '#0672BA',
      border: '2px solid currentColor',
      marginTop: -4,
      marginLeft: -6,
      fontFamily: 'Ebrima',
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {fontFamily: 'Ebrima',},
    valueLabel: {
      left: 'calc(-50% + 4px)',
      fontFamily: 'Ebrima'
      
    },
    track: {
      height: 4,
      borderRadius: 2,
      fontFamily: 'Ebrima'
    },
    rail: {
      height: 4,
      borderRadius: 2,
      fontFamily: 'Ebrima'
    },
  })(Slider);

  useEffect(() => {
    if (recordCount) {
      setMax(recordCount);
    }
  }, [recordCount]);

  useEffect(() => {
    if (recordCount > 10) {
      setMin(10);
    } else {
      recordCount ? setMin(1) : setMin(0);
    }
  }, [max]);

  return (
    <Grid container direction='row' justify={'center'} alignItems={'center'}>
      <Grid item xs={12} style={{ paddingBottom: '1%', paddingLeft: '1%', paddingRight: '1%' }}>
        <Grid container direction='row' justify={'space-evenly'} alignItems={'center'} style={{backgroundColor: '#A7A9AC' }}>
          <Grid item xs={2} style={{ textAlign: 'center',  fontFamily: 'Ebrima', }}>
            <Button disabled style={{ color: 'black',  fontFamily: 'Ebrima', }}>{min}</Button>
          </Grid>
          <Grid item xs={8}>
            <Grid container direction='row' alignItems='center' justify='center'>
              <Grid item xs={12} style={{ textAlign: 'center',  fontFamily: 'Ebrima', }}>
                <PrettoSlider
                  onChangeCommitted={handlePageSize}
                  defaultValue={10}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={10}
                  min={min}
                  max={max}
                  disabled={recordCount <= 10 ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={2} style={{ textAlign: 'center' }}>
            <Button disabled style={{ color: 'black',  fontFamily: 'Ebrima', }}>{max}</Button>
          </Grid>
        </Grid>
      </Grid>
      
    </Grid>

  );
}

import React, { useState, useEffect } from 'react';
import WorkOrderPaginationSlider from './WorkOrderPaginationSlider';
import {
  ListItem,
  Paper,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';

const typographyPagesStyle = {
  fontSize: '12px'
};

export default function ApplicationSearchPagination(props) {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [buttonCount, setButtonCount] = useState([]);
  const [buttonBegin, setButtonBegin] = useState(1);
  const [buttonEnd, setButtonEnd] = useState(4);

  const { pages, dispatchPaginationSelect, recordCount, selectPageSize } = props;

  const createPagination = () => {
    const numberOfPages = pages ? pages.length : 0;
    const buttonArr = [];

    if (numberOfPages > 0) {
      if (numberOfPages > 3) {
        for (let i = buttonBegin; i < buttonEnd; i++) {
          buttonArr.push(i);
        }
      }
      if (numberOfPages <= 3) {
        for (let i = 0; i < numberOfPages; i++) {
          buttonArr.push(i + 1);
        }
      }
    }
    setNumberOfPages(numberOfPages);
    setButtonCount(buttonArr);
  };

  const paginationSelect = (pageNumber) => {
    setSelectedPage(pageNumber);
    dispatchPaginationSelect(pageNumber);
  }

  const incrementPagination = () => {
    if (buttonEnd < numberOfPages) {
      setButtonBegin(buttonBegin + 1);
      setButtonEnd(buttonEnd + 1);
    }
  };

  const decrementPagination = () => {
    if (buttonBegin > 1) {
      setButtonBegin(buttonBegin - 1);
      setButtonEnd(buttonEnd - 1);
    }
  };

  useEffect(() => {
    createPagination();
  }, [pages, buttonEnd]);

  return (
    <ListItem style={{ backgroundColor: 'white' }}>
      <Grid container>
        <Grid container alignItems={'center'} justify={'space-evenly'} direction={'row'}>
          <Grid item xs={2}>
            <Button onClick={() => decrementPagination()}>-</Button>
          </Grid>
          { buttonCount.map(pageNumber =>
            <Grid item xs={2}>
              <Button
                value={pageNumber}
                style={selectedPage == pageNumber ? { textDecoration: 'underline' } : null}
                onClick={() => paginationSelect(pageNumber)}>
                {pageNumber}
              </Button>
            </Grid>
          ) }
          <Grid item xs={2}>
            <Button onClick={() => incrementPagination()}>+</Button>
          </Grid>
        </Grid>
        <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
          <Grid item style={{ display: 'contents' }}>
            <WorkOrderPaginationSlider
              recordCount={recordCount}
              selectPageSize={selectPageSize}
            />
          </Grid>
        </Grid>
        <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
          <Grid item>
            <Typography align={'center'} style={typographyPagesStyle}>{pages ? numberOfPages : 1} pages</Typography>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
}

import React from 'react';
import dotnetify from 'dotnetify';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import MenuItem from 'material-ui/MenuItem';
import MenuItemCore from '@material-ui/core/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';
import { green400, green600, grey50, pink500, grey200, grey300, grey500, grey400, pink400 } from 'material-ui/styles/colors';
import BasePage from '../components/BasePage';
import ThemeDefault from '../styles/theme-default';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, TableCell } from 'material-ui/Table';
import InlineEdit from '../components/table/InlineEdit';
import InlineEditSpan from '../components/table/InlineEditSpan';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import OrgPermissions from '../components/OrgPermissions';
import { RouteLink } from 'dotnetify';
import Pagination from '../components/table/Pagination';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import Snackbar from 'material-ui/Snackbar';
import IconRemove from 'material-ui/svg-icons/content/clear';
import IconEdit from 'material-ui/svg-icons/content/create';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import TextFieldCore from '@material-ui/core/TextField';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Divider from '@material-ui/core/Divider';
import globalStyles from '../styles/styles';
import Paper from 'material-ui/Paper';
import auth from '../auth';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import { debounce } from 'lodash';

const useStyles = makeStyles => theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  saveButton2: {
    height: 35,
    width: 100,
    backgroundColor: '#8DC63F',
    padding:10,
    fontFamily: 'Ebrima',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8DC63F',
      color: '#fafafa',
  },
  '&:disabled': {
    backgroundColor: '#A7A9AC',
    color: '#cacbcc',
  }
}
});

const MantisCancelButton = styled(Button)({
  
  height: 35,
  width: 100,
  backgroundColor: '#A7A9AC',
  color: 'white',
  padding:10,

label: {
  textTransform: "capitalize"
},
'&:hover': {
  backgroundColor: '#d7d9db',
},
});

const MantisSaveButton = styled(Button)({
  
    height: 35,
    width: 100,
    backgroundColor: '#8DC63F',
    color: 'white',
    padding:10,
  
  label: {
    textTransform: "capitalize"
  },
  '&:hover': {
    backgroundColor: '#b5db81',
  },
});

const MantisBlueCheckbox = withStyles({
  root: {
    color: "#0672BA",
    "&$checked": {
      color: "#0672BA"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

const MantisBlueSmallCheckbox = withStyles({
  root: {
    size: "small",
    color: "#0672BA",
    "&$checked": {
      color: "#0672BA"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

const GreenCheckbox = withStyles({
  root: {
    color: green400,
    '&$checked': {
      color: green600,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);



class EditUserFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = dotnetify.react.connect('EditUserForm', this, {  
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
          auth.signOut();
      }
    });

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.dispatch = state => this.vm.$dispatch(state);
    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
      dirty: false,
      adddirty: false,
      orgsdirty: false,
      clientfilterdirty: false,
      updatepassdirty: false,
      adduserdirty: false,
      SelectedEmail: '',
      Phone1: '',
      Phone2: '',
      FirstName: '',
      LastName: '',
      Add1: '',
      Add2: '',
      City: '',
      Zip: '',
      State: '',
      Country: '',
      RoleId: '',
      RoleTitle: '',
      ClientId: '0',
      ClientName: 'None Selected',
      LoggedInUserRoleId: '',
      PortalUserOrgUnits: [],
      Roles: [],
      UnassignedOrgUnits: [],
      NavRoutes: [],
      Users: [],
      Pages: [],
      Companies: [],
      SelectedCompanies: [],
      AllCompanies: [],
      ContactTypes: [],
      ClientSearch: '',
      LoggedInUserRank: 10,
      Active: false,
      WebsiteAccess: false,
      NewPassword: '',
      ConfirmPassword: '',
      ChangePassword: true,
      minlength: false,
      lower: false,
      upper: false,
      number: false,
      special: false,
      confirmed: false,
      AddUserOpen: false,
      ApplySiteFilter: true,
      SearchText: '',
      CurrentRoute: {}

    };
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    this.vm.$destroy();
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    let { dirty, adddirty, orgsdirty, UserId, SelectedEmail, Phone1, Phone2, FirstName, LastName, Add1, Add2, City, Zip, State,
      Country, RoleId, RoleTitle, ClientId, ClientName, LoggedInUserRoleId,
      PortalUserOrgUnits, Roles, UnassignedOrgUnits, NavRoutes, Users, Pages, SelectedPage,
      ShowNotification, NotificationMessage, Companies, SelectedCompanies, AllCompanies, ContactTypes, ClientSearch, clientfilterdirty,
      LoggedInUserRank, Active, WebsiteAccess, updatepassdirty, NewPassword, ConfirmPassword, adduserdirty, ChangePassword,
      minlength, lower, upper, number, special, confirmed, AddUserOpen, ApplySiteFilter, SearchText, CurrentRoute } = this.state;

    const countrylist = ['US', 'CA', 'UK', 'MX', 'UM'];

    const {
      width,
      height
    } = this.state;

    const styles = {
      color: green400,
      addButton: {
        margin: '1em',
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
      },
      passwordprompts: {
        fontSize: 14
      },
      countryStateDropdown: {
        backgroundColor: 'white',
        fontSize: 16,
        fontFamily: "Ebrima",
        width: 260,
        height: 40,
        marginTop: 5
      },
      countryStateContainer: {
        marginTop: 10
      },
      countryStateLabel: {
        fontSize: 12,
        fontFamily: "Ebrima"
      },
      countryStateContainer: {
        marginTop: 10
      },
      formControl: {
        margin: ThemeDefault.spacing.unit,
        minWidth: 220,
        maxWidth: 300,
        display: 'flex',
        wrap: 'nowrap'
      },
      selectLabel: { color: pink400 },
      toggleDiv: {
        maxWidth: 300,
        marginTop: 40,
        marginBottom: 5
      },
      toggleLabel: {
        color: grey400,
        fontWeight: 100
      },
      buttons: {
        marginTop: 10,
        marginLeft: 40,
        float: 'right'
      },
      button: {
        marginTop: 10,
        marginLeft: 20,
        float: 'left'
      },
      names: {
        marginTop: 0,
        marginLeft: 0,
        marginRight: 10,
        float: 'none'
      },
      divider: {
        marginTop: 5,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 5,
        float: 'none'
      },
      columns: {
        Active: { width: '15%' },
        FirstName: { width: '25%', paddingRight: 5, paddingLeft: 5 },
        Email: { width: '25%', paddingRight: 5, paddingLeft: 5 },
        LastLogin: { width: '15%', paddingRight: 5, paddingLeft: 5 },
        Address: { width: '25%', paddingRight: 5, paddingLeft: 5 },
        City: { width: '10%', paddingRight: 5, paddingLeft: 5 },
        State: { width: '10%', paddingRight: 5, paddingLeft: 5 },
        Edit: { width: '15%', paddingRight: 5, paddingLeft: 5 },
        Remove: { width: '15%', paddingRight: 5, paddingLeft: 5 },
      },
      textField: {
        marginLeft: 20,
        marginRight: 20,
        width: 400,
        fontFamily: 'Ebrima',
      },
      clear: {
        height: 0,
        borderWidth: 0,
        float: 'left'
      },
      formControl: {
        margin: 25,
      },
      saveButton: {
        height: 35,
        width: 130,
        backgroundColor: '#A7A9AC',
        fontFamily: 'Ebrima',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#A7A9AC',
          color: '#fafafa',
      },
      '&:disabled': {
        backgroundColor: '#A7A9AC',
        color: '#cacbcc',
      }
    },
      removeIcon: { fill: grey50 },
      editIcon: { fill: grey50 },
    }

    const ITEM_HEIGHT = 28;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 20.5 + ITEM_PADDING_TOP,
          width: 350,
        },
      },
    };
    const handleUpdate = zeeuser => {

    };
    zeeuser => {
      let newState = Users.map(item => (item.UserId === zeeuser.UserId ? Object.assign(item, zeeuser) : item));
      this.setState({ Users: newState });
      this.dispatch({ UpdateActive: zeeuser });
    };

    const handleSelectCompanySave = event => {
      console.log("handleSelectCompanySave");
      this.dispatch({ SelectCompany: { UserId: UserId, Companies: SelectedCompanies.Companies } });
      this.setState({ clientfilterdirty: false, ClientSearch: '' });
    };

    const handleSelectCompanyChange = company => {

      let comps = AllCompanies.map(item => (item.CompanyId === company.CompanyId ? Object.assign(item, company) : item))

      let newCompanies = SelectedCompanies.Companies.map(cmpitem => (cmpitem.CompanyId === company.CompanyId ? Object.assign(cmpitem, company) : cmpitem))

      if (company.Selected) {
        newCompanies.push(company);
      } else {
        newCompanies = newCompanies.filter(item => item.Selected === true);
      }

      //this.dispatch({SelectCompany: {UserId: UserId, Companies: newCompanies}});

      SelectedCompanies.Companies = newCompanies;
      let newSelectedCompanies = SelectedCompanies;

      this.setState({ AllCompanies: comps, SelectedCompanies: newSelectedCompanies, clientfilterdirty: true });
    };

    const modCompanySelect = item => {
      item.Selected = true;
      return item;
    }

    const modCompanyUnSelect = item => {
      item.Selected = false;
      return item;
    }

    const handleToggleOffCompany = _ => {

      let comps = AllCompanies.map(modCompanyUnSelect);

      SelectedCompanies.Companies = [];
      let newSelectedCompanies = SelectedCompanies;

      //this.dispatch({SelectCompany: {UserId: UserId, Companies: SelectedCompanies.Companies}});

      this.setState({ AllCompanies: comps, SelectedCompanies: newSelectedCompanies, clientfilterdirty: true });
    };

    const handleToggleOnCompany = _ => {

      //change all
      let comps = AllCompanies.map(modCompanySelect);

      let newCompanies = AllCompanies;

      SelectedCompanies.Companies = AllCompanies;
      let newSelectedCompanies = SelectedCompanies;

      //this.dispatch({SelectCompany: {UserId: UserId, Companies: SelectedCompanies.Companies}});

      this.setState({ AllCompanies: comps, SelectedCompanies: newSelectedCompanies, clientfilterdirty: true });
    };

    const handleClientSearchChange = cliensearch => event => {
      if (event.target.value != null && event.target.value.length > 0) {
        AllCompanies.map(item => item.Name.includes(event.target.value) ? item.Visible = true : item.Visible = false);
      } else {
        AllCompanies.map(item => item.Visible = true);
      }

      this.setState({ ClientSearch: event.target.value, AllCompanies: AllCompanies });

    };

    const handleCancel = _ => {
      this.dispatch({ Cancel: UserId });
      this.setState({ dirty: false, adddirty: false });
    };

    const handleSave = _ => {
      let newState = PortalUserOrgUnits.filter(item => (item.View === false && item.Edit === false));

      let newUOU = UnassignedOrgUnits.concat(newState);

      let newPUOU = PortalUserOrgUnits.filter(item => (item.View === true || item.Edit === true));

      console.log("COUNT:" + PortalUserOrgUnits.length);
      this.dispatch({
        Save: {
          UserId: UserId, Email: SelectedEmail, Phone1: Phone1, Phone2: Phone2, FirstName: FirstName, LastName: LastName, Add1: Add1, Add2: Add2, City: City,
          Zip: Zip, State: State, Country: Country, RoleId: RoleId, RoleTitle: RoleTitle,
          PortalUserOrgUnits: PortalUserOrgUnits, Active: Active, WebsiteAccess: WebsiteAccess
        }
      });

      this.setState({ PortalUserOrgUnits: newPUOU, UnassignedOrgUnits: newUOU, dirty: false, adddirty: false });

      //vm.$routeTo(CurrentRoute);
    };

    const handleResendEmail = _ => {
      this.dispatch({
        ResendAccessEmail: {
          Email: SelectedEmail
        }
      });

      this.setState({ PortalUserOrgUnits: newPUOU, UnassignedOrgUnits: newUOU, dirty: false, adddirty: false });
    };

    const handleSaveOrgTags = _ => {
      let newState = PortalUserOrgUnits.filter(item => (item.View === false && item.Edit === false));

      let newUOU = UnassignedOrgUnits.concat(newState);

      let newPUOU = PortalUserOrgUnits.filter(item => (item.View === true || item.Edit === true));

      console.log("COUNT:" + PortalUserOrgUnits.length);
      this.dispatch({ SaveOrgTags: { UserId: UserId, PortalUserOrgUnits: PortalUserOrgUnits, SelectedCompanies: SelectedCompanies } });

      this.setState({ PortalUserOrgUnits: newPUOU, UnassignedOrgUnits: newUOU, orgsdirty: false, adddirty: false });
    };

    const handleAdd = _ => {
      let newState = UnassignedOrgUnits.filter(item => (item.View === true || item.Edit === true));

      let newPUOU = PortalUserOrgUnits.concat(newState);

      let newUOU = UnassignedOrgUnits.filter(item => (item.View === false && item.Edit === false));

      console.log("COUNT:" + newState.length);
      this.dispatch({ SaveOrgTags: { UserId: UserId, PortalUserOrgUnits: newPUOU, SelectedCompanies: SelectedCompanies } });

      this.setState({ PortalUserOrgUnits: newPUOU, UnassignedOrgUnits: newUOU, adddirty: false });
    };

    const handleViewPermUpdate = orgunit => {
      let orgsStillTrue = PortalUserOrgUnits.filter(item => (item.Edit === true || item.View === true));
      if (orgsStillTrue.length > 1 || orgunit.View === true) {
        if (orgunit.View === false) orgunit.Edit = false;

        let newState = PortalUserOrgUnits.map(item => (item.OrgUnitId === orgunit.OrgUnitId && item.UserId == orgunit.UserId ? Object.assign(item, orgunit) : item));

        this.setState({ PortalUserOrgUnits: newState, orgsdirty: true });
      }
    };

    const handleEditPermUpdate = orgunit => {
      let orgsStillTrue = PortalUserOrgUnits.filter(item => (item.Edit === true || item.View === true));
      if (orgsStillTrue.length >= 1 || orgunit.Edit === true) {
        if (orgunit.Edit === true) orgunit.View = true;
        let newState = PortalUserOrgUnits.map(item => (item.OrgUnitId === orgunit.OrgUnitId && item.UserId == orgunit.UserId ? Object.assign(item, orgunit) : item));

        this.setState({ PortalUserOrgUnits: newState, orgsdirty: true });
      }
    };

    const handleNewViewPermUpdate = orgunit => {
      if (orgunit.View === false) orgunit.Edit = false;
      let newState = UnassignedOrgUnits.map(item => (item.OrgUnitId === orgunit.OrgUnitId && item.UserId == orgunit.UserId ? Object.assign(item, orgunit) : item));

      this.setState({ UnassignedOrgUnits: newState, adddirty: true });
    };

    const handleNewEditPermUpdate = orgunit => {
      if (orgunit.Edit === true) orgunit.View = true;
      let newState = UnassignedOrgUnits.map(item => (item.OrgUnitId === orgunit.OrgUnitId && item.UserId == orgunit.UserId ? Object.assign(item, orgunit) : item));

      this.setState({ UnassignedOrgUnits: newState, adddirty: true });
    };

    const handleRoleUpdate = role => {
      let newState = { RoleId: role.RoleId, RoleTitle: role.RoleTitle };

      this.setState({ RoleId: newState.RoleId, RoleTitle: newState.RoleTitle, dirty: true });
    };

    const handleClientSelectedUpdate = comp => {
      console.log(comp.CompanyId);
      let newState = { ClientId: comp.CompanyId, ClientName: comp.ClientName };

      this.dispatch({ GetContactTypes: { CompanyId: comp.CompanyId, UserId: UserId } });

      this.setState({ ClientId: newState.ClientId, ClientName: newState.ClientName });
    };


    const handleClose = () => {
      console.log("handleClose");
      this.setState({ open: false });
    };


    const handleNavLinkClick = obj => {
      console.log(obj)
      obj.e.preventDefault();
      console.log(obj.item);
      this.routeTo(obj.item.Route);
    };
    const handleSelectPage = page => {
      const newState = { SelectedPage: page };
      this.setState(newState);
      this.dispatch(newState);
    };

    const handleEditItemClick = user => {
      console.log(user);
      console.log(Users.find(i => i.UserId == user.UserId));
      this.routeTo(Users.find(i => i.UserId == user.UserId).Route);
    };

    const handleActiveUpdate = zeeuser => {
      let newState = Users.map(item => (item.UserId === zeeuser.UserId ? Object.assign(item, zeeuser) : item));
      this.setState({ Users: newState });
      this.dispatch({ UpdateActive: zeeuser });
    };

    const handleWebsiteAccessUpdate = zeeuser => {
      let newState = Users.map(item => (item.UserId === zeeuser.UserId ? Object.assign(item, zeeuser) : item));
      this.setState({ Users: newState });
      this.dispatch({ UpdateWebsiteAccess: zeeuser });
    };

    const handleContactTypeChecked = contacttype => {
      let newState = ContactTypes.map(item => (item.ContactTypeId === contacttype.ContactTypeId ? Object.assign(item, contacttype) : item));

      console.log("what" + contacttype.yes);
      this.dispatch({ UpdateContact: { ContactTypeId: contacttype.ContactTypeId, Title: contacttype.Title, Selected: contacttype.Selected } });

      this.setState({ ContactTypes: newState });
    };

    const hideNotification = _ => this.setState({ ShowNotification: false });

    const selectCountry = val => {
      this.setState({ Country: val, dirty: true, State: '' });
    }

    const selectRegion = val => {
      this.setState({ State: val, dirty: true });
    }

    const validatePasswords = _ => {

      var errors = '';

      if (NewPassword == null || NewPassword.length < 8) {
        //Check capital characters
        errors = 'The password must contain 8 or more characters.';
        this.setState({ minlength: false });

      } else {
        this.setState({ minlength: true })

      }

      if (!(/([A-Z]+)/g.test(NewPassword))) {
        errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        this.setState({ upper: false })

      } else {
        this.setState({ upper: true })

      }

      if (!(/([a-z]+)/g.test(NewPassword))) {
        errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        this.setState({ lower: false })
      } else {
        this.setState({ lower: true })
      }

      if (!(/([0-9]+)/g.test(NewPassword))) {
        errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        this.setState({ number: false })
      } else {
        this.setState({ number: true })
      }

      if (!(/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(NewPassword))) {
        errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        this.setState({ special: false })
      } else {
        this.setState({ special: true })
      }

      if (ConfirmPassword != null && NewPassword != null) {
        if (ConfirmPassword == NewPassword) {
          this.setState({ confirmed: true });
          confirmed = true;
        } else {
          this.setState({ confirmed: false })
          confirmed = false;

        }
      } else {
        this.setState({ confirmed: false });
        confirmed = false;

      }

      console.log(ConfirmPassword == NewPassword)
      console.log("ConfirmPassword:" + ConfirmPassword + " NewPassword:" + NewPassword)
      console.log("confirmed:" + confirmed + " special:" + special + " number:" + number + " upper:" + upper + " lower:" + lower + " MinLength:" + minlength)

      if (confirmed && special && number && upper && lower && minlength) {
        this.setState({ updatepassdirty: true });
      } else {
        this.setState({ updatepassdirty: false });
      }
    }

    const updatePasswordChange = val => {
      NewPassword = val.NewPassword;
      this.setState({ NewPassword: val.NewPassword });
      validatePasswords()
    }

    const updateConfirmPasswordChange = val => {
      ConfirmPassword = val.ConfirmPassword;
      this.setState({ ConfirmPassword: val.ConfirmPassword });

      validatePasswords()
    }

    const cancelPasswordUpdate = _ => {
      this.setState({ updatepassdirty: false, ConfirmPassword: '', NewPassword: '', special: false, number: false, lower: false, upper: false, minlength: false });
    }

    const updatePassword = _ => {
      this.dispatch({ UpdatePassword: { UserId: UserId, Password: NewPassword } });
      this.setState({ updatepassdirty: false, ConfirmPassword: '', NewPassword: '', special: false, number: false, lower: false, upper: false, minlength: false });

    }


    const addNewPasswordChange = val => {
      this.NewPassword = val.NewPassword;

      var errors = '';

      if (ConfirmPassword === null || NewPassword === null || ConfirmPassword.length === 0 || NewPassword.length === 0) {
        setState({ adduserdirty: false, NewPassword: val.NewPassword });
      } else {

        if (ConfirmPassword.toString() !== NewPassword.toString()) {
          errors = 'The password must match the confirmed password.';
        } else if (val.NewPassword != null && val.NewPassword.length < 8) {
          //Check capital characters
          errors = 'The password must contain 8 or more characters.';
        } else if (!(/([A-Z]+)/g.test(val.NewPassword))) {
          errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        } else if (!(/([a-z]+)/g.test(val.NewPassword))) {
          errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        } else if (!(/([0-9]+)/g.test(val.NewPassword))) {
          errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        } else if (!(/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(val.NewPassword))) {
          errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        }


        if (errors.length === 0) {
          this.setState({ adduserdirty: true, NewPassword: val.NewPassword });
        } else {
          this.setState({ adduserdirty: false, NewPassword: val.NewPassword });
        }

      }
    }

    const addConfirmPasswordChange = val => {
      this.ConfirmPassword = val.ConfirmPassword;
      var errors = '';

      if (this.ConfirmPassword === null || this.NewPassword === null || this.ConfirmPassword.length === 0 || this.NewPassword.length === 0) {
        this.setState({ adduserdirty: false, ConfirmPassword: val.ConfirmPassword });
      } else {
        if (this.ConfirmPassword.toString() !== this.NewPassword.toString()) {
          errors = 'The password must match the confirmed password.';
        } else if (val.ConfirmPassword != null && val.ConfirmPassword.length < 8) {
          //Check capital characters
          errors = 'The password must contain 8 or more characters.';
        } else if (!(/([A-Z]+)/g.test(val.ConfirmPassword))) {
          errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        } else if (!(/([a-z]+)/g.test(val.ConfirmPassword))) {
          errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        } else if (!(/([0-9]+)/g.test(val.ConfirmPassword))) {
          errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        } else if (!(/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(val.ConfirmPassword))) {
          errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        }


        if (errors.length === 0) {
          this.setState({ adduserdirty: true, ConfirmPassword: val.ConfirmPassword })
        } else {
          this.setState({ adduserdirty: false, ConfirmPassword: val.ConfirmPassword })
        }
      }
    }

    const cancelAddUser = _ => {
      this.setState({
        adduserdirty: false, ConfirmPassword: '', NewPassword: '', RoleId: 0,
        LastName: '',
        FirstName: '',
        SelectedEmail: '',
        SelectedCompanies: SelectedCompanies
      });
    }

    const addUser = _ => {
      this.dispatch({
        AddUser: {
          CreatedBy: '', Email: SelectedEmail,
          LastName: LastName, FirstName: FirstName, RoleId: RoleId, SelectedCompanies: SelectedCompanies,
          SendEmail: true, WebsiteAccess: WebsiteAccess
        }
      });
      this.setState({
        adduserdirty: false,
        RoleId: 0,
        LastName: '',
        FirstName: '',
        SelectedEmail: '',
        SelectedCompanies: SelectedCompanies
      });
    }


    const validateAddUser = _ => {

      var errors = [];

      if (SelectedEmail === undefined || SelectedEmail.length < 1 || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(SelectedEmail))) {
        errors.push("Invalid Email Address");
      }

      //check at least 1 client selected
      if (SelectedCompanies === undefined || SelectedCompanies.Companies === undefined || SelectedCompanies.Companies.length < 1) {
        errors.push("At least 1 client must be selected.");
      }

      //check first, last
      if (FirstName == undefined || FirstName.length <= 0) {
        errors.push("First Name is required.");
      }

      if (LastName === undefined || LastName.length <= 0) {
        errors.push("Last Name is required.");
      }

      if (WebsiteAccess === undefined) {
        WebsiteAccess = false;
      }

      return errors;
    }

    const handleAddUserFieldChange = val => {
      console.log("what the?" + val.SelectedEmail);

      if (val.SelectedEmail != undefined) {
        this.setState({
          adduserdirty: isdirty,
          SelectedEmail: val.SelectedEmail
        });
      }

      if (val.LastName != undefined) {
        this.setState({
          adduserdirty: isdirty,
          LastName: val.LastName
        });
      }

      if (val.FirstName != undefined) {
        this.setState({
          adduserdirty: isdirty,
          FirstName: val.FirstName
        });
      }

      if (val.WebsiteAccess != undefined) {
        this.setState({
          adduserdirty: isdirty,
          WebsiteAccess: val.WebsiteAccess
        });
      }

      if (val.RoleId != undefined) {
        this.setState({
          adduserdirty: isdirty,
          RoleId: val.RoleId
        });
      }

      if (val.SelectedCompanies != undefined) {
        this.setState({
          adduserdirty: isdirty,
          SelectedCompanies: val.SelectedCompanies
        });
      }

      var errors = validateAddUser();

      var isdirty = false;
      if (errors.length == 0) {
        this.setState({ adduserdirty: true })
      } else {
        this.setState({ adduserdirty: false })
      }
    }

    const classes = useStyles();

    const handleAddNewUserClose = _ => {
      console.log("Add New User Close: ");
      handleCloseNewUser();
    }

    const handleAddNewUser = _ => {
      console.log("Add New User Open: ");
      handleOpenNewUser();
    };

    const handleOpenNewUser = () => this.setState({ AddUserOpen: true });

    const handleCloseNewUser = () => this.setState({ AddUserOpen: false });

    const handleApplySiteFilter = (item) => {
      console.log(item);
      this.setState({ ApplySiteFilter: item.ApplySiteFilter });
      this.dispatch({ ApplySiteFilter: item.ApplySiteFilter })
    };

    const handleClickSearch= debounce(item => {
      console.log(item);
      this.dispatch({ SearchText: SearchText })
    }, 1000);

    const handleSearchChange = (prop) => (event) => {
      this.setState({ SearchText: event.target.value });
      this.dispatch({SearchText: event.target.value})
    };
  

    return (UserId > 0) ? (
      <MuiThemeProvider muiTheme={ThemeDefault}>
        <BasePage title={"Manage User: " + FirstName + " " + LastName} navigation={NavRoutes} HandleNavLinkClick={handleNavLinkClick} >
          <form>
          <List style={{ height: height-191, overflowY: 'scroll' }}>
             <ListItem key={"Li44"} id={"Li4"}>
                <div style={{ marginTop: 0 }}>
           
                
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}><h3 style={globalStyles.title}>Edit Detail</h3></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{ paddingTop: 0, paddingBottom: 10 }}>
                    <div >
                      <span style={styles.buttons}>
                        <RaisedButton label="Resend Mantis Access Email" onClick={handleResendEmail} />
                      </span>
                      <span>
                        <MenuItemCore>
                          <MantisBlueCheckbox key={'cbxActive'}
                            checked={Active === true}
                            onClick={(e) => this.setState({ Active: e.target.checked, dirty: true })} />
                          <ListItemText primary='Active' />
                        </MenuItemCore>
                      </span>
                      <span>
                        <MenuItemCore>
                          <MantisBlueCheckbox key={'cbxWebAccess'}
                            checked={WebsiteAccess === true}
                            onClick={(e) => this.setState({ WebsiteAccess: e.target.checked, dirty: true })} />
                          <ListItemText primary='Website Access' />
                        </MenuItemCore>
                      </span>
                    </div>
                    <div>
                      <span style={styles.names}>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          hintText="Enter email address"
                          floatingLabelText="Email/Username"
                          fullWidth={false}
                          value={SelectedEmail}
                          
                        />
                      </span>
                      <span style={styles.names}>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          hintText="Enter first name"
                          floatingLabelText="First Name"
                          fullWidth={false}
                          value={FirstName}
                          onChange={event => this.setState({ FirstName: event.target.value, dirty: true })}
                        />
                      </span>
                      <span style={styles.names}>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          hintText="Enter last name"
                          floatingLabelText="Last Name"
                          fullWidth={false}
                          value={LastName}
                          onChange={event => this.setState({ LastName: event.target.value, dirty: true })} />

                      </span>
                    </div>
                    <div >
                      <span style={styles.names}>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          hintText="Enter Primary Phone"
                          floatingLabelText="Primary Phone"
                          fullWidth={false}
                          value={Phone1}
                          onChange={event => this.setState({ Phone1: event.target.value, dirty: true })}
                        />
                      </span>
                      <span style={styles.names}>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          hintText="Enter Secondary Phone"
                          floatingLabelText="Secondary Phone"
                          fullWidth={false}
                          value={Phone2}
                          onChange={event => this.setState({ Phone2: event.target.value, dirty: true })}
                        />
                      </span>
                    </div>
                    <div >
                      <span style={styles.names}>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          hintText="Enter address"
                          floatingLabelText="Address"
                          fullWidth={false}
                          value={Add1}
                          onChange={event => this.setState({ Add1: event.target.value, dirty: true })}
                        />
                      </span>
                      <span style={styles.names}>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          hintText="Address cont."
                          floatingLabelText="Address Cont."
                          fullWidth={false}
                          value={Add2}
                          onChange={event => this.setState({ Add2: event.target.value, dirty: true })}
                        />
                      </span>
                      <span style={styles.names}>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          hintText="Enter city"
                          floatingLabelText="City"
                          fullWidth={false}
                          value={City}
                          onChange={event => this.setState({ City: event.target.value, dirty: true })}
                        />
                      </span>
                      <span style={styles.names}>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          hintText="Enter postal code"
                          floatingLabelText="Postal Code"
                          fullWidth={false}
                          value={Zip}
                          onChange={event => this.setState({ Zip: event.target.value, dirty: true })}
                        />
                      </span>
                    </div>

                    <div style={styles.countryStateContainer}>
                      <span style={styles.names}>
                        <FormControl className={styles.formControl}>
                          <FormLabel style={styles.countryStateLabel}>Country</FormLabel>
                          <CountryDropdown
                            value={Country}
                            whitelist={countrylist}
                            onChange={selectCountry}
                            style={styles.countryStateDropdown} />
                        </FormControl>
                      </span>
                      <span style={styles.names}>
                        <FormControl className={styles.formControl}>
                          <FormLabel style={styles.countryStateLabel}>State/Region</FormLabel>
                          <RegionDropdown
                            country={Country}
                            value={State}
                            onChange={selectRegion}
                            style={styles.countryStateDropdown} />
                        </FormControl>
                      </span>
                    </div>
                    <div>
                      <span style={styles.names}>
                        {LoggedInUserRoleId == 1 || LoggedInUserRoleId == 5 || LoggedInUserRoleId == 6 ?
                          <SelectField value={RoleId} fullWidth={false} floatingLabelText="Select Role" floatingLabelStyle={styles.selectLabel}>
                            {Roles.map(role => <MenuItem key={role.RoleId} value={role.RoleId} primaryText={role.Title}
                              onClick={(e) => handleRoleUpdate({ RoleId: role.RoleId, RoleTitle: role.RoleTitle })} />)}
                          </SelectField>
                          : <FormControl component="fieldset" style={{ marginTop: 10 }}>
                            <FormLabel component="legend">Role</FormLabel>{RoleTitle}
                          </FormControl>
                        }
                      </span>
                    </div>
                    <div style={styles.divider}>
                      <Divider variant="fullWidth" />
                    </div>
                    <div>
                    <div style={styles.buttons}>
                        <MantisSaveButton disabled={!dirty} onClick={handleSave}  >Save</MantisSaveButton>
                      </div>
                      <div style={styles.buttons}>
                        <MantisCancelButton disabled={!dirty} onClick={handleCancel} >Cancel</MantisCancelButton>
                      </div>
                    </div>

                    <div style={globalStyles.clear} />

                  </div></ExpansionPanelDetails></ExpansionPanel>
            </div>
             </ListItem>
             <ListItem key={"Li11"} id={"Li1"}>
            <div style={{ marginTop: 10 }}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}><h3 style={globalStyles.title}>Contact Types</h3></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{ paddingTop: 0, paddingBottom: 20 }}>
                    <div style={{ marginTop: 0 }}>

                    </div>
                    <div>
                      <span style={styles.names}>
                        {<SelectField value={ClientId} fullWidth={false} floatingLabelText="Select Client" floatingLabelStyle={styles.selectLabel}>
                          {AllCompanies.map(item => <MenuItem key={item.CompanyId} value={item.CompanyId} primaryText={item.Name}
                            onClick={(e) => handleClientSelectedUpdate({ CompanyId: item.CompanyId, CompanyName: item.Name })} />)}

                        </SelectField>
                        }
                      </span>
                      <span>
                        {ClientId > 0 && ContactTypes != null ? ContactTypes.map(item => (
                          <span><MantisBlueCheckbox key={'cbx' + item.ContactTypeId} checked={item.Selected === true}
                            onClick={(e) => handleContactTypeChecked({ ContactTypeId: item.ContactTypeId, Title: item.Title, Selected: e.target.checked })} />{item.Title}</span>
                        )) : ''}
                      </span>
                    </div>
                    <div style={globalStyles.clear} />
                  </div>
                </ExpansionPanelDetails></ExpansionPanel>
            </div>
             </ListItem>
             <ListItem key={"Li22"} id={"Li2"}>
            <div style={{ marginTop: 10 }}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}><h3 style={globalStyles.title}>Update Password</h3></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{ paddingTop: 0, paddingBottom: 20 }}>
                    <div style={{ marginTop: 0 }}>

                    </div>
                    <div>
                      <span style={styles.names}>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          id="standard-password-input"
                          hintText="New Password"
                          floatingLabelText="New Password"
                          type="password"
                          value={NewPassword}
                          fullWidth={false}
                          onChange={event => updatePasswordChange({ NewPassword: event.target.value })}
                        />
                      </span>
                      <span style={styles.names}>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          id="standard-confirm-password-input"
                          hintText="Confirm Password"
                          floatingLabelText="Confirm Password"
                          type="password"
                          value={ConfirmPassword}
                          fullWidth={false}
                          onChange={event => updateConfirmPasswordChange({ ConfirmPassword: event.target.value })}
                        />
                      </span>
                    </div>
                    <div style={styles.passwordprompts}>

                      <span>Password Requirements: </span><span>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={lower}

                              value={lower}
                            />
                          }
                          label="Lower Case"
                        />  </span><span>

                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={upper}

                              value={upper}
                            />
                          }
                          label="Upper Case"
                        /></span><span><FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={number}

                              value={number}
                            />
                          }
                          label="Number"
                        /></span><span>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={special}

                              value={special}
                            />
                          }
                          label="Special Character"
                        /> </span><span>

                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={minlength}

                              value={minlength}
                            />
                          }
                          label="Min. 8 Characters"
                        />

                      </span>

                    </div>
                    <div style={styles.divider}>
                      <Divider variant="fullWidth" />
                    </div>
                    <div style={styles.buttons}>
                      <RaisedButton label="Cancel" onClick={cancelPasswordUpdate} disabled={!updatepassdirty} style={styles.saveButton} primary={true} />
                      <RaisedButton label="Update Password" onClick={updatePassword} disabled={!updatepassdirty} style={styles.saveButton} primary={true} />
                    </div>
                    <div style={globalStyles.clear} />

                  </div>
                </ExpansionPanelDetails></ExpansionPanel>
            </div>
            </ListItem>
            <ListItem key={"Li33"} id={"Li3"}>
            <div style={{ marginTop: 10 }}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}><h3 style={globalStyles.title}>Assign Organization Tags</h3></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={styles.buttons}>
                    {LoggedInUserRank <= 10 ?
                      <div>
                        <FormControl component="fieldset" style={{ marginTop: 10, marginRight: 10, width: 600 }}>
                          <FormLabel component="legend">Filter Clients</FormLabel>
                          <Select
                            multiple={true}
                            onChange={this.handleClose}
                            autoWidth={true}
                            value={SelectedCompanies.Companies.map(item => item.Name)}
                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => selected.join('\n, ')}
                            MenuProps={MenuProps} >
                            <div style={styles.clear}></div>

                            <div id='select-menu-header' style={{position: 'sticky', top: '0', left: '0', zIndex: '1', backgroundColor: 'white'}}>

                              <div style={styles.textField}>
                                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}Core
                                  id="client-search"
                                  label="Search Clients"
                                  value={ClientSearch}
                                  defaultValue={ClientSearch}
                                  type="search"
                                  className={styles.textField}
                                  margin="normal"
                                  onChange={handleClientSearchChange('ClientSearch')}
                                />
                              </div>
                              <div style={styles.textField}>
                                <FormControlLabel
                                  control={
                                    <Button
                                      color="primary"
                                      onClick={(e) => handleToggleOnCompany()}
                                    />
                                  }
                                  label="All"
                                />
                                <FormControlLabel
                                  control={
                                    <Button
                                      color="primary"
                                      onClick={(e) => handleToggleOffCompany()}
                                    />
                                  }
                                  label="None"
                                />
                              </div>

                            </div>

                            {AllCompanies.map(item => (
                              item.Visible === true ?
                                <MenuItemCore key={item.CompanyId} value={item.CompanyId}>
                                  <MantisBlueCheckbox key={'cbx' + item.CompanyId} checked={item.Selected === true} onClick={(e) => handleSelectCompanyChange({
                                    CompanyId: item.CompanyId, Name: item.Name,
                                    Selected: e.target.checked
                                  })} />
                                  <ListItemText primary={item.Name} />
                                </MenuItemCore> : ''
                            ))}
                          </Select>
                          <div style={styles.buttons}>
                            <MantisSaveButton onClick={handleSelectCompanySave} disabled={!clientfilterdirty} >Save</MantisSaveButton>
                          </div>

                        </FormControl>

                      </div>
                      : ''}
                    <div>

                      <FormControl component="fieldset" style={{ marginTop: 10 }}>
                        <FormLabel component="legend">Organization Tags</FormLabel>

                        <div>
                          <OrgPermissions styles={styles.header}
                            userPortalOrgUnits={PortalUserOrgUnits}
                            handleEditPermUpdate={handleEditPermUpdate}
                            handleViewPermUpdate={handleViewPermUpdate}
                            UserId={UserId} />
                        </div>
                      </FormControl>
                    </div>
                    <div style={styles.divider}>
                      <Divider variant="fullWidth" />
                    </div>
                    <div style={styles.buttons}>
                      <RaisedButton label="Cancel" onClick={handleCancel} disabled={!dirty} />
                      <RaisedButton label="Save Tags" onClick={handleSaveOrgTags} disabled={!orgsdirty} style={styles.saveButton} primary={true} />
                      <RaisedButton label="Remove All Tags"
                        onClick={(e) => { if (window.confirm('Are you sure you wish to remove all organization tags for this user?')) this.dispatch({ RemoveTags: UserId }) }}
                        style={styles.saveButton} primary={true} />
                    </div>
                    <div>
                      <FormControl component="fieldset" style={{ marginTop: 10 }}>
                        <FormLabel component="legend">Unassigned Organization Tags</FormLabel>

                        <div>
                          <OrgPermissions styles={styles.header}
                            userPortalOrgUnits={UnassignedOrgUnits}
                            handleEditPermUpdate={handleNewEditPermUpdate}
                            handleViewPermUpdate={handleNewViewPermUpdate}
                            UserId={UserId} />
                        </div>

                      </FormControl>
                    </div>
                    <div style={styles.divider}>
                      <Divider variant="fullWidth" />
                    </div>
                    <div style={styles.buttons}>
                      <RaisedButton label="Cancel" onClick={handleCancel} disabled={!adddirty} style={styles.saveButton} />

                      <RaisedButton label="Add Selected" onClick={handleAdd} disabled={!adddirty} style={styles.saveButton} primary={true} />
                    </div>
                    <Snackbar open={ShowNotification} message={NotificationMessage} autoHideDuration={1000} onRequestClose={hideNotification} />
                  </div>
                  <div style={globalStyles.clear} />
                </ExpansionPanelDetails></ExpansionPanel>
            </div>
            </ListItem>
            </List>

          </form>
        </BasePage>
      </MuiThemeProvider>
    ) : (
        <MuiThemeProvider muiTheme={ThemeDefault}>
          <BasePage title="Manage Users" navigation={NavRoutes} HandleNavLinkClick={handleNavLinkClick}>
            <div>
              <div>
                <MantisBlueCheckbox key={'cbxApplySiteFilter'}
                  checked={ApplySiteFilter === true}
                  onClick={(e) => handleApplySiteFilter({ ApplySiteFilter: e.target.checked })} />Apply Site Filter
            </div>
            <div>
              <FormControl className={clsx(classes.margin, classes.textField)}>
                <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>
                <Input
                  id="standard-adornment-password"
                  type='text'
                  value={SearchText}
                    onChange={handleSearchChange('password')}
                  
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Search"
                        onClick={handleClickSearch}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
              <Table>
                <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                  <TableRow>
                    <TableHeaderColumn style={styles.columns.Active}>Active</TableHeaderColumn>
                    <TableHeaderColumn style={styles.columns.Active}>Access</TableHeaderColumn>
                    <TableHeaderColumn style={styles.columns.FirstName}>Name</TableHeaderColumn>
                    <TableHeaderColumn style={styles.columns.Email}>Email</TableHeaderColumn>
                    <TableHeaderColumn style={styles.columns.LastLogin}>Last Login</TableHeaderColumn>

                    <TableHeaderColumn style={styles.columns.Edit}>Edit</TableHeaderColumn>
                    <TableHeaderColumn style={styles.columns.Remove}>Remove</TableHeaderColumn>
                  </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                  {Users.map(item => (
                    <TableRow key={item.UserId}>
                      <TableRowColumn style={styles.columns.Active}>
                      <MantisBlueSmallCheckbox size="small" key={'cbxActive' + UserId}
                  checked={item.Active === true}
                  onClick={(e) => handleActiveUpdate({ UserId: item.UserId, Active: e.target.checked })} />
                       
                      </TableRowColumn>
                      <TableRowColumn style={styles.columns.Active}>
                      <MantisBlueSmallCheckbox size="small" key={'cbxActive' + UserId}
                    checked={item.WebsiteAccess === true}
                    onClick={(e) => handleWebsiteAccessUpdate({ UserId: item.UserId, WebsiteAccess: e.target.checked })} />
                      </TableRowColumn>
                      <TableRowColumn style={styles.columns.FirstName}>
                        <div>{item.FirstName} {item.LastName}</div>
                      </TableRowColumn>
                      <TableRowColumn style={styles.columns.Email}>
                        <div>{item.Email}</div>
                      </TableRowColumn>
                      <TableRowColumn style={styles.columns.LastLogin}>
                        <div>{item.LastLoginDate}</div>
                      </TableRowColumn>
                      <TableRowColumn style={styles.columns.Edit}>
                        <FloatingActionButton
                          onClick={value => handleEditItemClick({ UserId: item.UserId })}
                          zDepth={0}
                          mini={true}
                          backgroundColor={'#A7A9AC'}
                          iconStyle={styles.editIcon}
                        >
                          <IconEdit />
                        </FloatingActionButton>
                      </TableRowColumn>
                      <TableRowColumn style={styles.columns.Remove}>
                        <FloatingActionButton
                          onClick={(e) => { if (window.confirm('Are you sure you wish to delete this user?')) this.dispatch({ Remove: item.UserId }) }}

                          zDepth={0}
                          mini={true}
                          backgroundColor={'#A7A9AC'}
                          iconStyle={styles.removeIcon}
                        >
                          <IconRemove />
                        </FloatingActionButton>
                      </TableRowColumn>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <FloatingActionButton onClick={handleAddNewUser} style={styles.addButton} backgroundColor={'#8DC63F'} mini={true}>
                <ContentAdd />
              </FloatingActionButton>
              <Pagination style={styles.pagination} pages={Pages} select={SelectedPage} onSelect={handleSelectPage} />
              <div style={styles.divider}>

              </div>

              <Dialog open={AddUserOpen} onClose={handleAddNewUserClose} aria-labelledby="form-dialog-title" fullWidth={true}>

                <DialogActions>
                  <Button onClick={handleAddNewUserClose} color="primary">
                    Close
                </Button>
                </DialogActions>
                <div style={{ marginTop: 20 }}>
                  <DialogTitle id="form-dialog-title">Add User</DialogTitle>
                </div>

                <DialogContent>
                  <MuiThemeProvider muiTheme={ThemeDefault}>
                    <div style={{ marginTop: 4 }}>
                      <Paper style={globalStyles.paper}><div>

                        {LoggedInUserRank <= 2 ?
                          <div>
                            <div>
                              <span style={styles.names}>
                                <SelectField value={RoleId} fullWidth={false} floatingLabelText="Select Role"
                                  floatingLabelStyle={styles.selectLabel}  >
                                  {Roles.map(role => <MenuItem key={role.RoleId} value={role.RoleId}
                                    primaryText={role.Title}
                                    onClick={(e) => handleAddUserFieldChange({
                                      LastName: LastName,
                                      FirstName: FirstName,
                                      SelectedEmail: SelectedEmail,
                                      RoleId: role.RoleId,
                                      WebsiteAccess: WebsiteAccess,
                                      SelectedCompanies: SelectedCompanies
                                    })} />)}
                                </SelectField>
                              </span>
                              <span style={styles.names}>
                                <FormControl component="fieldset" style={{ marginTop: 16, marginRight: 10 }}>
                                  <FormLabel component="legend">Assign Clients *</FormLabel>
                                  <Select
                                    required={true}
                                    multiple={true}
                                    onChange={this.handleClose}
                                    autoWidth={true}
                                    value={SelectedCompanies.Companies.map(item => item.Name)}
                                    input={<Input id="select-multiple-checkbox" />}
                                    renderValue={selected => selected.join('\n, ')}
                                    MenuProps={MenuProps} >
                                    <div style={styles.clear}></div>
                                    <div style={styles.textField}>
                                       <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}Core
                                        id="client-search"
                                        label="Search Clients"
                                        value={ClientSearch}
                                        defaultValue={ClientSearch}
                                        type="search"
                                        className={styles.textField}
                                        margin="normal"
                                        onChange={handleClientSearchChange('ClientSearch')}
                                      />
                                    </div>
                                    <div style={styles.textField}>
                                      <FormControlLabel
                                        control={
                                          <Button
                                            color="primary"
                                            onClick={(e) => handleToggleOnCompany()}
                                          />
                                        }
                                        label="All"
                                      />
                                      <FormControlLabel
                                        control={
                                          <Button
                                            color="primary"
                                            onClick={(e) => handleToggleOffCompany()}
                                          />
                                        }
                                        label="None"
                                      />
                                    </div>
                                    {AllCompanies.map(item => (
                                      item.Visible === true ?
                                        <MenuItemCore key={item.CompanyId} value={item.CompanyId}>
                                          <MantisBlueCheckbox key={'cbx' + item.CompanyId} checked={item.Selected === true} onClick={(e) => handleSelectCompanyChange({
                                            CompanyId: item.CompanyId, Name: item.Name,
                                            Selected: e.target.checked
                                          })} />
                                          <ListItemText primary={item.Name} />
                                        </MenuItemCore> : ''
                                    ))}
                                  </Select>
                                  <div style={styles.buttons}>
                                    <MantisSaveButton onClick={handleSelectCompanySave} disabled={!clientfilterdirty} >Save</MantisSaveButton>

                                  </div>

                                </FormControl>
                              </span>
                              <span style={{ marginBottom: 50 }}>

                                <FormControlLabel
                                  control={
                                    <MantisBlueCheckbox key={'cbxWebAccess'} checked={WebsiteAccess} value={WebsiteAccess}
                                      onClick={(e) => handleAddUserFieldChange({
                                        WebsiteAccess: e.target.checked
                                      })} />
                                  }
                                  label="Website Access"
                                />
                              </span>
                            </div>
                            <div>
                              <span style={styles.names}>
                                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                                  id="standard-required"
                                  required={true}
                                  hintText="Email Address"
                                  floatingLabelText="Email"
                                  fullWidth={false}
                                  value={SelectedEmail}
                                  onChange={event => handleAddUserFieldChange({
                                    RoleId: RoleId,
                                    LastName: LastName,
                                    FirstName: FirstName,
                                    SelectedEmail: event.target.value,
                                    WebsiteAccess: WebsiteAccess,
                                    SelectedCompanies: SelectedCompanies
                                  })}
                                />
                              </span>
                              <span style={styles.names}>
                                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                                  required='true'
                                  hintText="First Name *"
                                  floatingLabelText="First Name"
                                  fullWidth={false}
                                  value={FirstName}
                                  onChange={event => handleAddUserFieldChange({
                                    RoleId: RoleId,
                                    LastName: LastName,
                                    FirstName: event.target.value,
                                    SelectedEmail: SelectedEmail,
                                    WebsiteAccess: WebsiteAccess,
                                    SelectedCompanies: SelectedCompanies
                                  })}
                                />
                              </span>
                              <span style={styles.names}>
                                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                                  required='true'
                                  hintText="Last Name"
                                  floatingLabelText="Last Name"
                                  fullWidth={false}
                                  value={LastName}
                                  onChange={event => handleAddUserFieldChange({
                                    RoleId: RoleId,
                                    LastName: event.target.value,
                                    FirstName: FirstName,
                                    SelectedEmail: SelectedEmail,
                                    WebsiteAccess: WebsiteAccess,
                                    SelectedCompanies: SelectedCompanies
                                  })}
                                />
                              </span>
                            </div>
                            <div style={styles.divider}>
                              <Divider variant="fullWidth" />
                            </div>
                            <div style={styles.buttons}>
                              <div style={styles.button}>
                              <MantisCancelButton   onClick={cancelAddUser} >Clear</MantisCancelButton>
                              </div>
                              <div style={styles.button}>
                              <MantisSaveButton onClick={addUser} disabled={!adduserdirty}>Add User</MantisSaveButton>
                              </div>
                            </div>
                          </div>
                          : ''}
                        <div style={globalStyles.clear} />
                      </div></Paper> </div>
                  </MuiThemeProvider>
                </DialogContent>
              </Dialog>
              <Snackbar open={ShowNotification} message={NotificationMessage} autoHideDuration={1000} onRequestClose={hideNotification} />
            </div>
          </BasePage>
        </MuiThemeProvider>
      )
  }
}

export default EditUserFormPage;

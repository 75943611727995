import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { white } from 'material-ui/styles/colors';

const drawerWidth = 240;

const ApplicationSidebarStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontFamily: 'Ebrima',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    fontFamily: 'Ebrima',
  },
  drawerOpen: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerClosed: {
    width: 0,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#0672BA',
    fontFamily: 'Ebrima',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    fontFamily: 'Ebrima',
  }, 
  list: {
    // backgroundColor: '#0672BA',
    // position: 'fixed',
    left: 0,
    color: white,
    height: '90%',
    width: '90%',
    margin: '5%',
    fontFamily: 'Ebrima',
  },
  listItem: {
    margin: 10,
    lineHeight: 0,
    cursor: 'pointer',
    fontFamily: 'Ebrima',
    whiteSpace: "nowrap"
  },
  siteFilter: {
    lineHeight: 0,
    fontFamily:'Ebrima',
  },
  toolbar: theme.mixins.toolbar,
}));

export default ApplicationSidebarStyle
import React, { useState, useEffect, useRef } from 'react';
import DocumentForm from '../DocumentForm';
import {
  Paper,
  Grid,
  Modal,
  Typography,
  Avatar,
  IconButton,
  Button,
  LinearProgress,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DocumentStyles from '../../../../styles/DocumentStyles';

export default function DocumentFormModal(props) {
  const [ value, setValue ] = useState(false);
  const [ enableSave, setEnableSave ] = useState(false);
  const {
    handleModalClosed,
    handleWarrantySectionsDispatch,
    handleSaveDocument,
    handleRetrieveDocument,
    
    documentModalOpen,
    saveDocument,
    
    document,
    
    properties,
    tags,
    fileTypes,
    propertySections,
    uploadProgress,
  } = props;

  const style = DocumentStyles();
  const {
    saveButton,
  } = style;

  const handleSave = () => {
    setValue(true);
  };

  const handleModalClose = () => {
    setEnableSave(false);
    handleModalClosed();
  };

  //goes to child, tells modal whther there is a file selected
  const handleEnableSave = (bool) => {
    setEnableSave(bool);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value != previousValue) {
      if (value) {
        handleSaveDocument(value);
        setValue(false);
      }
    }
  }, [value]);

  return (
    <Modal id='modal' open={documentModalOpen}>
      <Grid container direction='row' justify='center' alignItems='center' style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', overflow: 'auto' }}>
        <Grid xs={12} sm={6}>
          <Paper id='paper'>
            <Grid container direction='row' style={{ height: '100%', display: 'table' }}>
              <Grid item xs={12} style={{ display: 'table-header-group', top: '0', left: '0', zIndex: '1' }}>
                <Grid container direction='row' alignItems='center' justify='space-between' style={{ backgroundColor: '#B2DFFD', textAlign: 'center' }}>
                  <Grid item xs={1} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    <Avatar style={{ backgroundColor: '#B2DFFD', margin: '2%' }}>
                      <InsertDriveFileIcon />
                    </Avatar>
                    <Typography variant='h6' style={{ margin: '4%', fontFamily: 'Ebrima', }}>Documents</Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right', color: 'white', display: 'contents' }}>
                    <IconButton disabled={uploadProgress} onClick={() => handleModalClose()}>
                      <HighlightOffIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={uploadProgress ? { display: 'table-cell', opacity: '0.4', pointerEvents: 'none' } : { display: 'table-cell' }}>
                {uploadProgress ? <LinearProgress variant="determinate" value={uploadProgress} /> : null}
                <DocumentForm
                  handleRetrieveDocument={handleRetrieveDocument}
                  handleWarrantySectionsDispatch={handleWarrantySectionsDispatch}
                  handleEnableSave={handleEnableSave}
                  document={document}
                  saveDocument={saveDocument}
                  properties={properties}
                  tags={tags}
                  fileTypes={fileTypes}
                  propertySections={propertySections}
                />
              </Grid>
              <Grid item xs={12} style={{ display: 'table-footer-group' }}>
                <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#B2DFFD' }}>
                  <Grid item xs={3} style={{ textAlign: 'center', padding: '4px' }}>
                    <Button disabled={!enableSave && !uploadProgress} onClick={() => handleSave()} className={saveButton} >Save</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Modal>
  );
};
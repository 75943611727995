import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '95%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddAssignedToSelect(props) {
  const [ assignedId, setAssignedId ] = useState(null);
  const classes = useStyles();

  const { 
    retrieveAssignedValue,
    contractorList,
    category4Finished,
   } = props;

   const handleChange = (e) => {
     const id = e.target.value;
     setAssignedId(id);
   };

   useEffect(() => {
    if (assignedId) {
      const assigned = contractorList.find(contractor => contractor.UserID == assignedId);
      retrieveAssignedValue(assigned);
    } else {
      retrieveAssignedValue(null);
    }
   }, [assignedId]);

  return (
    <div>
      <FormControl disabled={ !category4Finished } variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-assigned-native-simple">Assigned To</InputLabel>
        <Select
          native
          value={assignedId}
          onChange={handleChange}
          inputprops={{
            assignedId: 'assignedId',
            id: 'filled-assigned-native-simple',
          }}
        >
          <option value="" />
          { contractorList.map((contractor, i) => {
            const name = contractor.UserName;
            const value = contractor.UserID;
            return (
              <option value={ value }>{ name }</option>
            )
          }) }
        </Select>
      </FormControl>
    </div>
  );
}

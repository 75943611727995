import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import auth from '../../auth';
//components
import WorkOrderCard from './WorkOrderCard';
import WorkOrderPagination from './SubComponents/WorkOrderPagination';
import WorkOrderSearch from './SubComponents/WorkOrderSearch';
//modals
import WorkOrderAddModal from './Modals/WorkOrderAddModal';
import WorkOrderDeleteModal from './Modals/WorkOrderDeleteModal';
import WorkOrderEditModal from './Modals/WorkOrderEditModal';
import WorkOrderDetailsModal from './Modals/WorkOrderDetailsModal';
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { debounce } from 'lodash';

class WorkOrders extends Component {
  constructor(props){
    super(props);
    this.vm = dotnetify.react.connect('WorkOrders', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        console.log(ex)
          auth.signOut();
      }
    });
    this.dispatch = state => this.vm.$dispatch(state);
    this.routeTo = route => this.vm.$routeTo(route);
    
    this.state = {
      //data
      Results: [],
      WorkOrder: {},
      Pages: [],
      //converted data
      workOrders: null,
      lastAddedWorkOrder: null,
      //selects
      PropertyList: [],
      StatusList: [],
      UrgencyList: [],
      WOTypeList: [],
      BFAdminUsers: [],
      ContractorList: [],
      //selections
      searchText: '',
      deleteWorkOrderFile: null,
      editWorkOrder: null,
      //modal booleans
      workOrderAddModalOpen: false,
      workOrderDeleteModalOpen: false, 
      workOrderEditModalOpen: false,
      workOrderDetailsModalOpen: false,
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { 
      Results, 
      WorkOrder, 

      workOrders, 
      editWorkOrder, 
      selectedWO,  

      WorkOrderID,
      workOrderEditModalOpen,
    } = this.state;
    const previousWorkOrderEditModalOpen = prevState.workOrderEditModalOpen;

    if (Results != workOrders) {
      if (Results) {
        this.setState({
          workOrders: Results,
        });
      }
    }

    //if navigated here from app search
    if (WorkOrderID) {
      if (Results && Results.length == 1) {
        if (!WorkOrder) {
          const workOrderFromAppSearch = Results.find(workOrder => workOrder.WOID == WorkOrderID);
          if (workOrderEditModalOpen == previousWorkOrderEditModalOpen) {
            if (!workOrderEditModalOpen && workOrderFromAppSearch) {
              this.handleEditModalOpen(workOrderFromAppSearch)
            }
          }
        }
        if (WorkOrder) {
          console.log(1)
          if (workOrderEditModalOpen != previousWorkOrderEditModalOpen) {
            console.log(2)
            console.log(workOrderEditModalOpen, selectedWO)
            if (!workOrderEditModalOpen && selectedWO) {
              this.handleAppSearchCleanUp();
            }
          }
        }
      }
    }
    //TODO: this selects the wrong work order and opens the edit menu after creating a work order
    // if (workOrders != previousWorkOrders) {
    //   if (previousWorkOrders) {
    //     const lastAddedWorkOrder = workOrders.pop();
    //     this.setState({
    //       lastAddedWorkOrder,
    //     }, this.handleEditModalOpen(lastAddedWorkOrder))
    //   }
    // }

    //initializing modal with a WorkOrder
    if (WorkOrder) {
      if (!editWorkOrder) {
        this.setState({
          editWorkOrder: WorkOrder,
        })
      }
      
      //for every selection there after, coordinates with modal page to refire loading logic
      if (editWorkOrder) {
        if (selectedWO) {
          const workOrderId = WorkOrder.WOID;
          const selectedWorkOrderId = selectedWO.WOID;

          if (WorkOrder != prevState.WorkOrder) {
            if (workOrderId == selectedWorkOrderId) {
              this.setState({
                editWorkOrder: WorkOrder,
              })
            }
          }
        }
      }
    }
  };

  handleAppSearchCleanUp = () => {
    const { NavRoutes } = this.state;
    this.setState({
      selectedWO: null,
    }, 
    this.handleEditModalClose(), 
    this.routeTo(NavRoutes[0].Route));
  };

  //<----search---->
  handleSearch = (text) => {
    if (text) {
      this.setState({
        searchText: text,
      }, this.dispatchSearch(text));
    }
  };

  dispatchSearch = debounce(text => {
    this.dispatch({ SearchAll: text });
  }, 400);

  //<----details---->
  handleDetailsOpen = () => {
    this.setState({
      workOrderDetailsModalOpen: true,
    })
  };

  handleDetailsClose = () => {
    this.setState({
      workOrderDetailsModalOpen: false,
    })
  };

  //<----add---->
  handleAddModalOpen = () => {
    this.setState({
      workOrderAddModalOpen: true,
    })
  };

  dispatchPropertyContractors = (selectedProperty) => {
    this.setState({
      selectedPropertyAdd: selectedProperty,
    });
    if (selectedProperty) {
      const propertyId = selectedProperty.Id;
      this.dispatch({ SelectProperty: propertyId });
    }
  };

  handleAddModalClose = () => {
    this.setState({
      workOrderAddModalOpen: false,
    })
  };

  handleSubmitWorkOrder = (addWorkOrder) => {
    this.dispatch({ AddWorkOrder: addWorkOrder })
  };

  //<----edit---->
  handleEditModalOpen = (editWorkOrder) => {
    const workOrderId = editWorkOrder.WOID;    
    this.setState({
      workOrderEditModalOpen: true,
      selectedWO: editWorkOrder,
    }, this.dispatch({ GetWorkOrder: workOrderId } ));
  };

  dispatchWorkOrderStatus = (workOrderStatus, note) => {
    const { editWorkOrder } = this.state;

    const workOrderId = editWorkOrder.WOID;
    const workOrderStatusId = workOrderStatus.WostatusTypeId;
    const workOrderStatusNote = note ? note : '';
    const workOrderStatusName = workOrderStatus.Wostatus;

    const workOrderStatusObject = {
      ID: workOrderStatus.id ? workOrderStatus.id : -1,
      WOID: workOrderId, 
      WOStatusTypeID: workOrderStatusId,
      Note: workOrderStatusNote,
      Name: workOrderStatusName
    };

    this.setState({
      newWorkOrderStatus: workOrderStatus,
    }, this.dispatch({ UpdateStatus: workOrderStatusObject } ));
  };

  handleEditGeneralDispatch = (woUrgency, woType) => {
    this.dispatch({ UpdateGeneral: { wourgency: woUrgency, wotype: woType } });
  };

  dispatchEditDates = (date, start, end) => {
    this.dispatch({ UpdateDates: { scheddate: date, windowStart: start, windowEnd: end } });
  };

  dispatchEditContractor = (name, email, phone) => {
    this.dispatch({ UpdateContractor: { name: name, email: email, phone: phone } })
  };

  dispatchEditSubmitter = (name, email, phone) => {
    this.dispatch({ UpdateSubmitter: { name: name, email: email, phone: phone } })
  };

  dispatchEditAssigned = (name, email, phone) => {
    this.dispatch({ UpdateAssigned: { name: name, email: email, phone: phone } })
  };

  dispatchEditEstimateNumbers = (NTE, WOEstimate, bid) => {
    this.dispatch({ UpdateEstimates: { nte: NTE, estimate: WOEstimate, bid: bid } })
  };

  dispatchEditInvoiceNumbers = (contractInvoice, bluefinInvoice, purchaseOrderNumber, externalWorkOrder) => {
    this.dispatch({ UpdateInvoiceNumbers: { ContractorInvoice: contractInvoice, BluefinInvoice: bluefinInvoice, ExternalWorkOrder: externalWorkOrder, PO: purchaseOrderNumber } })
  };

  // edit documents
  dispatchAddBuildingFile = (editedDocument) => {
    this.dispatch({ AddBuildingFile: editedDocument })
  };

  dispatchUpdateScopeOfWork = (file, data) => {
    const url = data ? data.key : '';
    this.dispatch({ UpdateScopeOfWorkUrl: url })
  };
  
  dispatchUpdateBid = (file, data) => {
    const url = data ? data.key : '';
    this.dispatch({ UpdateBidUrl: url })
  };

  dispatchUpdateContractorInvoice = (file, data) => {
    const url = data ? data.key : '';
    this.dispatch({ UpdateContractorInvoiceURL: url });
  };

  dispatchBluefinInvoice = (file, data) => {
    const url = data ? data.key : '';
    this.dispatch({ UpdateBFInvoiceURL: url });
  };

  // edit work order items
  dispatchCreateNewWorkOrderItem = (createdWorkOrderItem) => {
    this.setState({
      createdWorkOrderItem,
    }, this.dispatch({ AddWorkOrderItem: createdWorkOrderItem }));
  };

  dispatchUpdateWorkOrderItem = (editedWorkOrderItem) => {
    console.log('dispatchUpdateWorkOrderItem')
    console.log(editedWorkOrderItem)
    this.setState({
      editedWorkOrderItem,
    }, this.dispatch({ UpdateWorkOrderItem: editedWorkOrderItem }));
  };

  dispatchDeleteWorkOrderItem = (deleteWorkOrderItem) => {
    this.setState({
      deleteWorkOrderItem,
    }, this.dispatch({ DeleteWorkOrderItem: deleteWorkOrderItem }));
  };

  handleEditModalClose = () => {
    this.setState({
      workOrderEditModalOpen: false,
    })
  };

  // Comments
  dispatchAddComment = (comment) => {
    const { ActiveUser, WOID } = this.state;
    const activeUserId = ActiveUser.UserID;
    const currentDate = new Date();

    const updatedComment = {
      Id: 0,
      Woid: WOID,
      CommentDate: currentDate.getFullYear()+'-'+(currentDate.getMonth()+1)+'-'+currentDate.getDate(),
      CommentBy: activeUserId,
      Comment: comment,
    };

    this.setState({
      updatedComment,
    }, this.dispatch({ UpsertComment: updatedComment }))
  };

  dispatchUpdateComment = (updatedComment) => {  
    this.setState({
      updatedComment,
    }, this.dispatch({ UpsertComment: updatedComment }))
  };

  dispatchDeleteComment = (deletedComment) => {
    this.setState({
      deletedComment,
    }, this.dispatch({ DeleteComment: deletedComment }))
  };

  //<----pagination---->
  dispatchPaginationSelect = (SelectedPage) => {
    this.setState({
      SelectedPage,
    }, this.dispatch({ SelectedPage: SelectedPage }));
  };

  selectPageSize = (pageSize) => {
    this.setState({
      SelectedPageSize: pageSize,
    }, this.dispatch({ SelectedPageSize: pageSize }))
  };

  componentWillUnmount = () => {
    console.log('unmounting WorkOrders');
    this.vm.$destroy();
  };

  render() {
    const {
      //old data
      workOrders,
      WorkOrder,
      //new data
      WOID,
      WOItems,
      WONumber,
      WOType,
      WOTypeId,
      ScheduledDate,
      TimeWindowStart,
      TimeWindowEnd,
      Contractor,
      ContractorId,
      ContractorName,
      ContractorPhone,
      ContractorEmail,
      Description,
      Ponumber,
      CompanyId,
      ExternalNumber,
      ContractorInvoiceUrl,
      BFInvoice,
      BuildingId,
      NTE,
      Estimate,
      BidPrice,
      ActualPrice,
      ProjectId,
      Building,
      CurrentStatus,
      SelectedStatus,
      StatusHistory,
      Status,
      SubmittedById,
      SubmitName,
      SubmitEmail,
      SubmitPhone,
      AssignedToId,
      AssignedToName,
      AssignedToEmail,
      AssignedToPhone,
      UrgencyTypeId,
      UrgencyType,
      Comments,
      ScopeOfWorkUrl,
      BidUrl,
      BFInvoiceUrl,
      CompletedWOURL,
      ContractorInvoice,
      ActiveUser,
      //selects
      PropertyList,
      PropertySections,
      PropertyDefects,
      StatusList,
      UrgencyList,
      WOTypeList,
      BFAdminUsers,
      ContractorList,
      //user selections
      searchText,
      editWorkOrder,
      selectedWO,
      editedWorkOrderItem,
      //modal booleans
      workOrderAddModalOpen,
      workOrderDeleteModalOpen,
      workOrderEditModalOpen,
      workOrderDetailsModalOpen,
      //pagination
      RecordCount,
      SelectedPageSize,
      // PageSizes,
      Pages,
      
      Token,
      IdentityId,
    } = this.state;

    return (
      <Grid container alignItems='center' justify='center' style={{ position: 'relative', height: '100%', width: '100%' }} id='container'>
        <WorkOrderDetailsModal 
          handleDetailsClose={ this.handleDetailsClose }
          workOrderDetailsModalOpen={ workOrderDetailsModalOpen }
        />
        <WorkOrderAddModal
          //submit
          handleSubmitWorkOrder={ this.handleSubmitWorkOrder }
          //modal
          handleAddModalClose={ this.handleAddModalClose }
          workOrderAddModalOpen={ workOrderAddModalOpen }
          //dispatch
          dispatchPropertyContractors={ this.dispatchPropertyContractors }
          //selects
          propertyList={PropertyList}
          statusList={StatusList}
          urgencyList={UrgencyList}
          woTypeList={WOTypeList}
          bfAdminUsers={BFAdminUsers}
          contractorList={ContractorList}
        />
        <WorkOrderDeleteModal
          handleDeleteModalClose={ this.handleDeleteModalClose }
          workOrderDeleteModalOpen={ workOrderDeleteModalOpen }
        /> 
        <WorkOrderEditModal
          handleEditModalClose={ this.handleEditModalClose }
          workOrderEditModalOpen={ workOrderEditModalOpen }
          editWorkOrder={ editWorkOrder }
          propertyList={PropertyList}
          statusList={StatusList}
          urgencyList={UrgencyList}
          woTypeList={WOTypeList}
          bfAdminUsers={BFAdminUsers}
          contractorList={ContractorList}
          selectedWO={selectedWO}
          editedWorkOrderItem={editedWorkOrderItem}
          //dispatch functions
          dispatchCreateNewWorkOrderItem={this.dispatchCreateNewWorkOrderItem}
          dispatchUpdateWorkOrderItem={this.dispatchUpdateWorkOrderItem}
          dispatchDeleteWorkOrderItem={this.dispatchDeleteWorkOrderItem}
          dispatchWorkOrderStatus={this.dispatchWorkOrderStatus}
          dispatchEditDates={this.dispatchEditDates}
          dispatchEditContractor={this.dispatchEditContractor}
          dispatchEditSubmitter={this.dispatchEditSubmitter}
          dispatchEditAssigned={this.dispatchEditAssigned}
          dispatchEditEstimateNumbers={this.dispatchEditEstimateNumbers}
          dispatchEditInvoiceNumbers={this.dispatchEditInvoiceNumbers}
          dispatchAddBuildingFile={this.dispatchAddBuildingFile}
          dispatchUpdateContractorInvoice={this.dispatchUpdateContractorInvoice}
          dispatchUpdateScopeOfWork={this.dispatchUpdateScopeOfWork}
          dispatchUpdateBid={this.dispatchUpdateBid}
          dispatchBluefinInvoice={this.dispatchBluefinInvoice}
          dispatchAddComment={this.dispatchAddComment}
          dispatchUpdateComment={this.dispatchUpdateComment}
          dispatchDeleteComment={this.dispatchDeleteComment}
          handleEditGeneralDispatch={this.handleEditGeneralDispatch}
          //new data
          workOrderId={WOID}
          workOrderItems={WOItems}
          workOrderNumber={WONumber}
          workOrderType={WOType}
          workOrderTypeId={WOTypeId}
          workOrderUrgency={UrgencyType}
          scheduleDate={ScheduledDate}
          timeWindowStart={TimeWindowStart}
          timeWindowEnd={TimeWindowEnd}
          contractor={Contractor}
          contractorId={ContractorId}
          contractorName={ContractorName}
          contractorPhone={ContractorPhone}
          contractorEmail={ContractorEmail}
          description={Description}
          poNumber={Ponumber}
          companyId={CompanyId}
          externalNumber={ExternalNumber}
          contractorInvoiceUrl={ContractorInvoiceUrl}
          bfInvoice={BFInvoice}
          buildingId={BuildingId}
          nte={NTE}
          estimate={Estimate}
          bidPrice={BidPrice}
          actualPrice={ActualPrice}
          projectId={ProjectId}
          propertySections={PropertySections}
          propertyDefects={PropertyDefects}
          building={Building}
          currentStatus={CurrentStatus}
          statusHistory={StatusHistory}
          status={Status}
          submittedById={SubmittedById}
          submitName={SubmitName}
          submitEmail={SubmitEmail}
          submitPhone={SubmitPhone}
          assignedToId={AssignedToId}
          assignedToName={AssignedToName}
          assignedToEmail={AssignedToEmail}
          assignedToPhone={AssignedToPhone}
          urgencyTypeId={UrgencyTypeId}
          urgencyType={UrgencyType}
          comments={Comments}
          scopeOfWorkUrl={ScopeOfWorkUrl}
          bidUrl={BidUrl}
          bfInvoiceUrl={BFInvoiceUrl}
          completedWorkOrderUrl={CompletedWOURL}
          contractorInvoice={ContractorInvoice} 
          activeUser={ActiveUser} 
          token={Token}
          identityId={IdentityId}
        />
        <Grid container direction='row' alignItems='center' justify='center' style={{width: '100%' }}>
          <Grid item xs={8} sm={8} md={6} lg={5} xl={6}  style={{ width: '100%' }}>
            <WorkOrderSearch
              handleSearch={this.handleSearch}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} style={{ width: '100%' }}>
            <Button disabled={PropertyList.length == 0} variant='contained' color="primary" onClick={() => this.handleAddModalOpen()} style={{ height: '3rem', width: '100%', maxWidth: '15rem' }}>
              <AddIcon />
              <Typography noWrap>Add Work Order</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems={'center'} justify={'center'} style={{ position: 'relative', height: '73%', width: '100%', overflow: 'auto', backgroundColor: 'lightGrey' }}>
         { workOrders ?
            workOrders.map((workOrder, i) => {
              return (
                <Grid item style={{ margin: '1%' }}>
                  <WorkOrderCard
                    i={i}
                    handleEditModalOpen={this.handleEditModalOpen}
                    handleDeleteModalOpen={this.handleDeleteModalOpen}
                    workOrder={workOrder}
                  />
                </Grid>
              )
            }) : <div>LOADING..</div> }
        </Grid>
        <Grid item style={{ margin: '1%' }}>
          <WorkOrderPagination
            dispatchPaginationSelect={this.dispatchPaginationSelect}
            selectPageSize={this.selectPageSize}
            pages={Pages}
            selectedPageSize={SelectedPageSize}
            recordCount={RecordCount}
          />
        </Grid>
      </Grid>
    )
  }
};

export default WorkOrders

import React, { useState, useEffect } from 'react';
import SurfaceAttributeListItem from './SurfaceAttributeListItem';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  Collapse,
  Avatar,
  IconButton,
  Paper,
  Grid,
  Chip,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
    boxShadow: 'none',
    padding: 'none',
    backgroundColor: 'inherit',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PropertySectionMaterialCard(props) {
  const [ attributes, setAttributes ] = useState([]);
  const {
    i,
    surface,
    invTitle,
  } = props;

  useEffect(() => {
   if (surface) {
     const surfaceExists = Object.values(surface).length;
     if (surfaceExists) {
       const {
         attrs,
       } = surface;
       setAttributes(attrs);
     }
   }
  }, [surface]);

  const classes = useStyles();
  return (
    <Card square key={i} className={classes.card}>
      <CardHeader
        title={invTitle}
      />
      <CardContent>
        <List style={{ paddingTop: '0' }}>
          { attributes.map((attribute, i) => {
            return (
              <SurfaceAttributeListItem
                i={i}
                attribute={attribute}
              />
            )
          }) }
        </List>
      </CardContent>
    </Card>
  );
}

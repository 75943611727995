import React from 'react';
import PropertiesResultsImages from './PropertiesResultsImages';
import { 
  Modal,
  Grid,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function PropertiesResultsImagesModal(props) {
  const { 
    handleOpenModal,
    openResultsModal,
    images,
    results, 
    mobile,
  } = props;

  const resultImages = images ? images.length > 0 ? images : null : null;

  return (

    <Modal
      open={ resultImages ? openResultsModal : null }
      style={{display: 'none'}}
    >
      <Grid container>
        <PropertiesResultsImages 
          mobile={ mobile }
          handleOpenModal={ handleOpenModal }
          images={ resultImages }
          results={ results }
        /> 
      </Grid>
    </Modal>    
  )
}
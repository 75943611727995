import React, { useState, useEffect, useRef } from 'react';
import WorkOrderEditForm from './Forms/Edit/WorkOrderEditForm';
import EditSubmit from './Forms/Edit/SubComponents/EditSubmit';
import EditContainsWarranty from './Forms/Edit/SubComponents/EditContainsWarranty';
import EditStatus from './Forms/Edit/SubComponents/EditStatus';
import {
  Paper,
  Grid,
  Modal,
  Typography,
  LinearProgress,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EditWorkOrderForm from './Forms/Edit/WorkOrderEditForm';

export default function WorkOrderEditModal(props) {
  const [ loading, setLoading ] = useState(true);
  const [ done, setDone ] = useState(false);
  const [ buildingName, setBuildingName] = useState('');
  const [ streetAddress, setStreetAddress ] = useState('');
  const [ stateAddress, setStateAddress ] = useState('');
  const {
    handleAddWorkOrderItemsDispatch,
    handleEditModalClose,
    workOrderEditModalOpen,
    editWorkOrder,
    propertyList, 
    statusList,
    urgencyList,
    woTypeList,
    bfAdminUsers, 
    contractorList, 
    selectedWO,
    editedWorkOrderItem,
    //dispatch functions
    dispatchCreateNewWorkOrderItem,
    dispatchUpdateWorkOrderItem,
    dispatchDeleteWorkOrderItem,
    dispatchWorkOrderStatus,
    dispatchAddBuildingFile,
    dispatchEditDates,
    dispatchEditContractor,
    dispatchEditSubmitter,
    dispatchEditAssigned,
    dispatchUpdateContractorInvoice,
    dispatchUpdateScopeOfWork,
    dispatchUpdateBid,
    dispatchBluefinInvoice,
    dispatchEditEstimateNumbers,
    dispatchEditInvoiceNumbers,
    dispatchAddComment,
    dispatchUpdateComment,
    dispatchDeleteComment,
    handleEditGeneralDispatch,
    //new data
    workOrderId,
    workOrderItems,
    workOrderNumber,
    workOrderType,
    workOrderTypeId,
    workOrderUrgency,
    scheduleDate,
    timeWindowStart,
    timeWindowEnd,
    contractor,
    contractorId,
    contractorName,
    contractorPhone,
    contractorEmail,
    description,
    poNumber,
    propertySections,
    propertyDefects,
    companyId,
    externalNumber,
    contractorInvoiceUrl,
    bfInvoice,
    buildingId,
    nte,
    estimate,
    bidPrice,
    actualPrice,
    projectId,
    building,
    currentStatus,
    statusHistory,
    status,
    submittedById,
    submitName,
    submitEmail,
    submitPhone,
    assignedToId,
    assignedToName,
    assignedToEmail,
    assignedToPhone,
    urgencyTypeId,
    urgencyType,
    comments,
    scopeOfWorkUrl,
    bidUrl,
    bfInvoiceUrl,
    completedWorkOrderUrl,
    contractorInvoice,
    activeUser,
    token,
    identityId,
  } = props;

  const handleModalBlur = ({ nativeEvent }) => {
    const targetId = nativeEvent.target.id;
    if (targetId == 'edit-container') {
      handleEditModalClose();
    }
  };

  const handleDone = () => {
    setDone(true);
  };

  useEffect(() => {
    if (editWorkOrder) {
      if (selectedWO) {
        if (editWorkOrder.WOID != selectedWO.WOID) {
          setLoading(true);
        } else {
          setLoading(false)
        }
      }
    } else {
      setLoading(true);
    }
  }, [editWorkOrder, selectedWO]);

  useEffect(() => {
    if (done) {
      handleEditModalClose();
      setDone(false);
    }
  }, [done]);

  useEffect(() => {
    if (building) {
      const { Name, Address1, Address2, City, StateName, Zip } = building;
      const buildingStreetAddress = `${Address1} ${Address2}`;
      const buildingStateAddress = `${ City }, ${ StateName } ${ Zip }`;
      setStateAddress(buildingStateAddress);
      setStreetAddress(buildingStreetAddress);
      setBuildingName(Name);
    }
  }, [building]);

  return (
    <Modal open={workOrderEditModalOpen}>
      <div id={'edit-container'} style={{ width: '100%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} onClick={handleModalBlur}>
        <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
          <Grid item xs={10}>
            <Paper id='paper' style={{ width: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgb(246, 243, 235)'}}>
              
              <Grid container direction='row' style={{ width: '100%', backgroundColor: '#3f51b5' }}>
                <Grid item style={{ margin: '1%', display: 'inline-flex' }}>
                  <EditIcon style={{ color: 'white' }} />
                  <Typography style={{ color: 'white' }}>{buildingName} Work Order #{workOrderNumber}</Typography>
                </Grid>
              </Grid>

            { loading ?
            <Grid>
              <LinearProgress color="secondary" />
                <Paper style={{ height: '48.5rem', backgroundColor: 'rgb(246, 243, 235)'}}>
              </Paper>
            </Grid> 
            :
              <Grid container direction='row' style={{ height: '100%', position: 'relative', maxHeight: '56rem' }}>
                <Grid container direction='row'>
                  <Grid style={{ backgroundColor: 'lightGrey' }} item xs={12}>
                    <Grid container direction='row' alignItems='center' style={{ height: '100%' }}>
                      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <EditContainsWarranty
                          buildingName={buildingName}
                          streetAddress={streetAddress}
                          stateAddress={stateAddress}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <EditStatus
                          dispatchWorkOrderStatus={dispatchWorkOrderStatus}
                          currentStatus={currentStatus}
                          status={status}
                          statusList={statusList}
                          editWorkOrder={editWorkOrder}
                          statusHistory={statusHistory}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction='row' style={{ maxHeight: '45rem', overflow: 'auto', }}>
                  <Grid item xs={12}>
                    <WorkOrderEditForm 
                      editWorkOrder={ editWorkOrder }
                      propertyList={ propertyList }
                      statusList={ statusList }
                      urgencyList={ urgencyList }
                      woTypeList={ woTypeList }
                      bfAdminUsers={ bfAdminUsers }
                      contractorList={ contractorList }
                      editedWorkOrderItem={ editedWorkOrderItem }
                      //dispatch functions
                      dispatchCreateNewWorkOrderItem={dispatchCreateNewWorkOrderItem}
                      dispatchUpdateWorkOrderItem={dispatchUpdateWorkOrderItem}
                      dispatchDeleteWorkOrderItem={dispatchDeleteWorkOrderItem}
                      dispatchEditDates={dispatchEditDates}
                      dispatchEditContractor={dispatchEditContractor}
                      dispatchEditSubmitter={dispatchEditSubmitter}
                      dispatchEditAssigned={dispatchEditAssigned}
                      dispatchEditEstimateNumbers={dispatchEditEstimateNumbers}
                      dispatchEditInvoiceNumbers={dispatchEditInvoiceNumbers}
                      dispatchAddBuildingFile={ dispatchAddBuildingFile }
                      dispatchUpdateContractorInvoice={dispatchUpdateContractorInvoice}
                      dispatchUpdateScopeOfWork={dispatchUpdateScopeOfWork}
                      dispatchUpdateBid={dispatchUpdateBid}
                      dispatchBluefinInvoice={dispatchBluefinInvoice}
                      dispatchAddComment={dispatchAddComment}
                      dispatchUpdateComment={dispatchUpdateComment}
                      dispatchDeleteComment={dispatchDeleteComment}
                      handleEditGeneralDispatch={ handleEditGeneralDispatch }
                      //new data
                      workOrderId={workOrderId}
                      workOrderItems={workOrderItems}
                      workOrderNumber={workOrderNumber}
                      workOrderType={workOrderType}
                      workOrderTypeId={workOrderTypeId}
                      workOrderUrgency={workOrderUrgency}
                      scheduleDate={scheduleDate}
                      timeWindowStart={timeWindowStart}
                      timeWindowEnd={timeWindowEnd}
                      contractor={contractor}
                      contractorId={contractorId}
                      contractorName={contractorName}
                      contractorPhone={contractorPhone}
                      contractorEmail={contractorEmail}
                      description={description}
                      poNumber={poNumber}
                      propertySections={propertySections}
                      propertyDefects={propertyDefects}
                      companyId={companyId}
                      externalNumber={externalNumber}
                      contractorInvoiceUrl={contractorInvoiceUrl}
                      bfInvoice={bfInvoice}
                      buildingId={buildingId}
                      nte={nte}
                      estimate={estimate}
                      bidPrice={bidPrice}
                      actualPrice={actualPrice}
                      projectId={projectId}
                      building={building}
                      status={status}
                      submittedById={submittedById}
                      submitName={submitName}
                      submitEmail={submitEmail}
                      submitPhone={submitPhone}
                      assignedToId={assignedToId}
                      assignedToName={assignedToName}
                      assignedToEmail={assignedToEmail}
                      assignedToPhone={assignedToPhone}
                      urgencyTypeId={urgencyTypeId}
                      urgencyType={urgencyType}
                      comments={comments}
                      scopeOfWorkUrl={scopeOfWorkUrl}
                      bidUrl={bidUrl}
                      bfInvoiceUrl={bfInvoiceUrl}
                      completedWorkOrderUrl={completedWorkOrderUrl}
                      contractorInvoice={contractorInvoice}
                      activeUser={activeUser}
                      token={token}
                      identityId={identityId}
                    />
                  </Grid>
                </Grid>
                <Grid container direction='row' style={{ position: 'sticky', top: '100%', left: '0', backgroundColor: '#3f51b5', width: '100%' }}>
                  <Grid container direction='row' alignItems='center' justify='center' styl={{width: '100%'}}>
                    <Grid item xs={1} style={{ margin: '1%' }}>
                      <EditSubmit
                        handleDone={handleDone}
                      />
                    </Grid>
                  </Grid>
                </Grid> 
              </Grid>
            }           
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
} 
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Stepper,
  Step,
  StepButton,
} from '@material-ui/core';

function getSteps() {
  return ['Request Leak Response', 'Select Property/Contact', 'Map/Locate Leak', 'Add Photos/Documents', 'Review/Submit Request'];
};

export default function HorizontalNonLinearStepper(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const {
    wizardStep,
  } = props;

  const steps = getSteps();
  const windowWidth = window.outerWidth;

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  useEffect(() => {
    if (wizardStep != activeStep) {
      setActiveStep(wizardStep);
      handleComplete()
    }
  }, [wizardStep]);

  return (
    <Grid container direction='row' style={{ height: '100%' }}>
      <Grid item xs={12} style={{ height: '100%' }}>
        <Stepper nonLinear activeStep={activeStep} style={{ height: '100%', padding: '0' }}>
          {steps.map((label, index) => (
            <Step key={label} style={{ height: '100%' }}>
              <StepButton completed={completed[index]} style={{ height: '100%' }}>
                { windowWidth > 1279 ? label : null }
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
}

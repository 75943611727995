import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

var moment = require('moment');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function EditWorkOrderCommentCard(props) {
  const [ initials, setInitials ] = useState('');
  const [ editable, setEditable ] = useState(false);
  const [ editing, setEditing ] = useState(null);
  const [ edited, setEdited ] = useState(null);
  const [ commentObj, setCommentObj ] = useState(null);
  const [ commentText, setCommentText ] = useState('');

  const { 
    comment,
    comments, 
    activeUser, 
    dispatchUpdateComment,
    dispatchDeleteComment 
  } = props;

  const {
    Id, 
    Woid,
    CommentDate,
    CommentBy,
    CommentUserInfo,
    Comment,
  } = comment;

  const userName = CommentUserInfo.UserName;
  const commentDate = `${moment(CommentDate).format('YYYY/MM/DD')} at ${moment(CommentDate).format('LT')}`;

  const handleDeleteComment = () => {
    const commentToDelete = comments.find(comment => comment.Id == Id);
    dispatchDeleteComment(commentToDelete);
  };

  const handleUpdateComment = () => {
    if (editing == Id) {
      setEditing(null);
      setCommentObj(null);
    }

    if (editing != Id) {
      const commentToEditObj = comments.find(comment => comment.Id == Id);
      const commentToEditText = commentToEditObj.Comment;
      setEditing(Id);
      setCommentObj(commentToEditObj);
      setCommentText(commentToEditText);
    }
  };

  const handleEditComment = (e) => {
    const value = e.target.value;
    setCommentText(value);
  };

  const handleDispatchUpdate = () => {
    const commentObjCopy = { ...commentObj };
    commentObjCopy.Comment = commentText;
    dispatchUpdateComment(commentObjCopy);
    setEdited(null);
    setEditing(null);
    setCommentObj(null);
    setCommentText('');
  };

  useEffect(() => {
    if (commentObj) {
      const textFromObj = commentObj.Comment;
      if (commentText) {
        if (commentText != textFromObj) {
          setEdited(Id);
        }
      }
    }
  }, [commentText]);
  
  useEffect(() => {
    if (userName) {
      const nameArray = userName.split(' ');
      const firstInitial = nameArray[0].split('')[0];
      const lastInitial = nameArray[nameArray.length-1].split('')[0];
      const userInitials = firstInitial+lastInitial;

      if (userInitials) {
        setInitials(userInitials);
      }
    }
  }, [userName]);

  useEffect(() => {
    if (activeUser) {
      const activeUserId = activeUser.UserID;
      const commentUserId = CommentUserInfo.UserID;
      if (activeUserId == commentUserId) {
        setEditable(true);
      } else {
        setEditable(false);
      }
    }
  }, [activeUser]);

  const classes = useStyles();
  return (
    <div style={{display: 'contents'}}>
      <ListItem alignItems="flex-start" style={ editable ? {backgroundColor: '#66BFFA', display: 'inline-block'} : { display: 'inline-block'} }>
        <Grid container direction='row'>
          <Grid item xs={2}>
            <ListItemAvatar>
              <Avatar children={initials ? initials : '?'} />
            </ListItemAvatar>
           </Grid>
           <Grid item xs={6}>
            <ListItemText
              primary={userName}
              secondary={commentDate}
            />
          </Grid>
          { editable ?
            <div style={{display: 'contents'}}>
              <Grid item xs={2}>
                { edited == Id ? 
                  <IconButton onClick={() => handleDispatchUpdate()}>
                    <SaveIcon />
                  </IconButton>
                  :
                  <IconButton onClick={() => handleUpdateComment()}>
                    <EditIcon />
                  </IconButton>
                }
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => handleDeleteComment() }>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </div> 
           :
            <Grid item xs={4}></Grid>
           }
        </Grid>
        <Grid container direction='row'>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <ListItemText
              secondary={
                <React.Fragment>
                  { editing == Id ?
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                      id="standard-multiline-static"
                      label="Edit Comment"
                      multiline
                      rows="6"
                      defaultValue="Default Value"
                      value={ commentText }
                      onChange={handleEditComment}
                      style={{width: '100%'}}
                    />
                    :
                    <Typography>{Comment}</Typography>
                  }
                </React.Fragment>
              }
            />
          </Grid>
        </Grid>
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  )
}
import React, { useEffect, useState, useReducer } from 'react';
import PropertyDefectsCardImages from './SubComponents/PropertyDefectsCardImages';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RoomIcon from '@material-ui/icons/Room';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: '2%',
    border: '1px solid lightgrey',
    maxWidth: 345,
    fontFamily: 'Ebrima'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'rgb(31, 89, 100)',
  },
}));

export default function ProeprtyRepairCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  // const [update, setUpdate] = React.useState(0);

  const { defect, images, highlightSelectedDefect } = props;
  const { SecId, SeverityDesc, DefectStateFirst, DefectStateLast } = defect;
  const firstSeen = DefectStateFirst.DateTimeStamp;
  const lastInspected = DefectStateLast.DateTimeStamp;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSelectedDefect = () => {
    const defectId = defect.DefId;
    highlightSelectedDefect(defectId);
  };

  return (
    <Card square className={classes.card}>
      <CardHeader classes={{title: classes.card, subheader: classes.card}}
        onClick={() => handleSelectedDefect(defect)}
        avatar={<Avatar aria-label="repair" className={classes.avatar}>{SecId}</Avatar>}
        action={
          <IconButton aria-label="settings">
            <RoomIcon />
          </IconButton>
        }
        title={`Severity: ${SeverityDesc}`}
      />
      <CardMedia>
        <PropertyDefectsCardImages
          images={images}
        />
      </CardMedia>
      <CardContent>
        <Typography variant="body2" color="textSecondary" style={{fontFamily: 'Ebrima',}} component="p">
          First Seen: {firstSeen}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{fontFamily: 'Ebrima',}} component="p">
          Last Inspected: {lastInspected}
        </Typography>
      </CardContent>
    </Card>
  );
}

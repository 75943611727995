import React, { Component } from 'react';
import CreateWorkOrderItem from './Components/CreateWorkOrderItem';
import WorkOrderCostItems from './Components/WorkOrderCostItems';
import WorkOrderDefectItems from './Components/WorkOrderDefectItems';
import WorkOrderItemImages from './Components/WorkOrderItemImages';
import WorkOrderItemPhotos from './Components/SubComponents/WorkOrderItemPhotos';
import SaveOrCancelWorkOrderItemsAlert from './SubComponents/SaveOrCancelWorkOrderItemsAlert';
import {
  Grid,
} from '@material-ui/core';
import AWS from 'aws-sdk';
import S3FileUpload from 'react-s3';

class WorkOrderItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: null,
      
      selectedSections: [],
      selectedName: null,
      selectedDescription: null,
      selectedCostItems: null,
      selectedDefects: null,
      selectedPhotos: [],
      uploadedPhotos: [],

      createdWorkOrderItem: null,
      editedWorkOrderItem: null,
    }
  }

  componentDidMount = () => {
    const { activeStep } = this.props;
    this.setState({
      currentStep: activeStep,
    });

    const { editingWorkOrderItem } = this.props;
    if (editingWorkOrderItem) {
      this.setState({
        editingWorkOrderItem,
        editedWorkOrderItem: editingWorkOrderItem,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { activeStep } = this.props;
    const { previousCurrentStep } = this.state;
    const previousActiveStep = prevProps.activeStep;

    if (activeStep != previousActiveStep) {
      this.setState({
        currentStep: activeStep,
        previousCurrentStep: previousActiveStep,
      })
    } else {
      if (previousCurrentStep != activeStep) {
        this.setState({
          previousCurrentStep: activeStep,
        })
      }
    }

    const {
      selectedName,
      selectedDescription,
      selectedSections,
      selectedCostItems,
      selectedDefects,
      selectedPhotos,
      uploadedPhotos,

      createdWorkOrderItem,
      editingWorkOrderItem,
      editedWorkOrderItem,
    } = this.state;

    const {
      workOrderId,
    } = this.props;

    const previousSelectedName = prevState.selectedName;
    const previousSelectedDescription = prevState.selectedDescription;
    const previousSelectedSections = prevState.selectedSections;
    const previousCreatedWorkOrderItem = prevState.createdWorkOrderItem;
    const previousEditedWorkOrderItem = prevState.editedWorkOrderItem;
    const previousSelectedCostItems = prevState.selectedCostItems;
    const previousSelectedDefects = prevState.selectedDefects;
    const previousSelectedPhotos = prevState.selectedPhotos;
    const previousUploadedPhotos = prevState.uploadedPhotos;
    //add
    if (!editingWorkOrderItem) {
      if ( selectedName != previousSelectedName 
        || selectedDescription != previousSelectedDescription 
        || selectedSections != previousSelectedSections 
        || selectedCostItems != previousSelectedCostItems
        || selectedDefects != previousSelectedDefects 
        || selectedPhotos != previousSelectedPhotos
        || uploadedPhotos != previousUploadedPhotos) {
        
        //set arrays to [] in state as default?
        const createdWorkOrderItem = {
          WoitemId: -1,
          Woid: workOrderId,
          WoitemNum: 0,
          Name: selectedName,
          Recommendation: selectedDescription,
          TotalEstimate: 0,
          TotalItemDetail: 0,
          WoItemDefects: selectedDefects ? selectedDefects : [],
          WoItemDetail: selectedCostItems ? selectedCostItems : [],
          WoItemPhoto: uploadedPhotos,
          WoSection: selectedSections ? selectedSections : [],
        };
        
        const { retrieveWorkOrderItemFulfilling } = this.props;

        if (selectedPhotos && selectedPhotos.length > 0) {
          //if selectedPhotos have changed, pass it to the s3 bucket
          if (selectedPhotos != previousSelectedPhotos) {
            retrieveWorkOrderItemFulfilling(true);
            this.handleUpdatePhotoBucket(selectedPhotos);
          }
        }

        if (selectedPhotos.length == uploadedPhotos.length) {
          createdWorkOrderItem.WoItemPhoto = uploadedPhotos;
          retrieveWorkOrderItemFulfilling(false);
        }

        this.setState({
          createdWorkOrderItem,
        })
      } 
    } 
    
    //edit
    if (editingWorkOrderItem) {
      //step 1
      if (selectedName != previousSelectedName) {
        const editingWorkOrderItemCopy = editedWorkOrderItem ? { ...editedWorkOrderItem } : { ...editingWorkOrderItem };
        editingWorkOrderItemCopy.Name = selectedName;
        this.setState({
          editedWorkOrderItem: editingWorkOrderItemCopy, 
          selectedName,
        })
      }

      if (selectedDescription != previousSelectedDescription) {
        const editingWorkOrderItemCopy = editedWorkOrderItem ? { ...editedWorkOrderItem } : { ...editingWorkOrderItem };
        editingWorkOrderItemCopy.Recommendation = selectedDescription;
        this.setState({
          editedWorkOrderItem: editingWorkOrderItemCopy, 
          selectedDescription,
        })
      }

      if (selectedSections != previousSelectedSections) {
        const editingWorkOrderItemCopy = editedWorkOrderItem ? { ...editedWorkOrderItem } : { ...editingWorkOrderItem };
        editingWorkOrderItemCopy.WoSection = selectedSections;
        this.setState({
          editedWorkOrderItem: editingWorkOrderItemCopy, 
          selectedSections,
        })
      }

      //step 2
      if (selectedCostItems != previousSelectedCostItems) {
        const editingWorkOrderItemCopy = editedWorkOrderItem ? { ...editedWorkOrderItem } : { ...editingWorkOrderItem };
        editingWorkOrderItemCopy.WoItemDetail = selectedCostItems;
        this.setState({
          editedWorkOrderItem: editingWorkOrderItemCopy,
          selectedCostItems,
        })
      }

      //step 3
      if (selectedDefects != previousSelectedDefects) {
        const editingWorkOrderItemCopy = editedWorkOrderItem ? { ...editedWorkOrderItem } : { ...editingWorkOrderItem };
        editingWorkOrderItemCopy.WoItemDefects = selectedDefects;
        this.setState({
          editedWorkOrderItem: editingWorkOrderItemCopy,
          selectedDefects,
        })
      }

      //step 4
      if (selectedPhotos && selectedPhotos.length > 0) {
        const { retrieveWorkOrderItemFulfilling } = this.props;
        //if selectedPhotos have changed, pass it to the s3 bucket
        if (selectedPhotos != previousSelectedPhotos) {
          retrieveWorkOrderItemFulfilling(true);
          this.handleUpdatePhotoBucket(selectedPhotos);
        }
        
        //once selectedPhotos have been uploaded and uploadedPhotos is defined, add them into the editingWorkOrderItem
        if (uploadedPhotos.length == selectedPhotos.length) {
          const editingWorkOrderItemCopy = editedWorkOrderItem ? { ...editedWorkOrderItem } : { ...editingWorkOrderItem };
          editingWorkOrderItemCopy.WoItemPhoto = uploadedPhotos;
          retrieveWorkOrderItemFulfilling(false);
          this.setState({
            editedWorkOrderItem: editingWorkOrderItemCopy,
            uploadedPhotos: [],
            selectedPhotos: [],
          })
        }
      }

      //pass to parent if editing
      if (editedWorkOrderItem) {
        if (JSON.stringify(editedWorkOrderItem) != JSON.stringify(previousEditedWorkOrderItem)) {
          const { retrieveEditedWorkOrderItem } = this.props;
          retrieveEditedWorkOrderItem(editedWorkOrderItem);
        }
      }
    } 
    //pass to parent if creating
    if (createdWorkOrderItem != previousCreatedWorkOrderItem) {
      const { retrieveCreateNewWorkOrderItem } = this.props;
      const createdWorkOrderItemCopy = { ...createdWorkOrderItem };
      const recommendationCopy = createdWorkOrderItemCopy.Recommendation;

      //update created work order when all selectedPhotos have been successfully uploaded
      if (uploadedPhotos && selectedPhotos) {
        if (uploadedPhotos.length == selectedPhotos.length) {
          createdWorkOrderItemCopy.WoItemPhoto = uploadedPhotos;
        }
      }

      //guard against null
      if (!recommendationCopy) {
        createdWorkOrderItemCopy.Recommendation = '';
      }
      retrieveCreateNewWorkOrderItem(createdWorkOrderItemCopy);
    }
  };

  retrieveName = (selectedName) => {
    this.setState({
      selectedName,
    })
  };

  retrieveDescriptions = (selectedDescription) => {
    this.setState({
      selectedDescription,
    })
  };

  retrieveSelectedSections = (selectedSectionArray) => {
    this.setState({
      selectedSections: selectedSectionArray,
    })
  };

  retrieveSelectedCostItems = (selectedCostItems) => {
    this.setState({
      selectedCostItems,
    })
  };

  retrieveSelectedWorkOrderDefects = (selectedDefects) => {
    this.setState({
      selectedDefects,
    })
  };

  retrieveWorkOrderItemPhotos = (selectedPhotos) => {
    this.setState({
      selectedPhotos,
    })
  };

  uploadPhoto = async (photo) => {
    const { token, identityId } = this.props;
    const { editingWorkOrderItem, createdWorkOrderItem } = this.state;
    const { Woid, WoitemId } = editingWorkOrderItem ? editingWorkOrderItem : createdWorkOrderItem;

    const location = WoitemId !== -1 ? `${Woid}/${WoitemId}` : `${Woid}`;
    const dirName = location.split(' ').join('');

    AWS.config.region = 'us-west-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token
      }
    });

    const s3 = new AWS.S3;

    s3.config.region = 'us-west-2';
    s3.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token
      }
    });

    const blob = new Blob([photo], { type: 'text/plain' });

    const parameters = {
      bucket: 'workorderphotos',
      key: dirName,
      s3: s3,
      stream: blob,
      acl: 'public-read',
      contentType: photo.type
    };

    return new Promise((resolve, reject) => {
      this.uploadBlob(parameters)
        .then(response => {
          resolve(response);
          reject(Error);
        })
        .catch(err => console.error(err))
    });
  };

  uploadBlob = async (parameters) => {
    const {
      bucket,
      key,
      s3,
      stream,
      acl,
      contentType
    } = parameters;
    const params = { Bucket: bucket, Key: key, Body: stream, ACL: acl, ContentType: contentType };
    return s3.upload(params).promise();
  };

  handleUpdatePhotoBucket = (selectedPhotos) => {
    const promises = [];

    selectedPhotos.map((photo) => {
      const upload = this.uploadPhoto(photo)
      promises.push(upload);
    });

    Promise.all(promises)
    .then(response => {
      this.setState({
        uploadedPhotos: response,
      })
    })
    .catch(err => console.error(err));
  };

  handleNextClick = () => {
    const { createdWorkOrderItem, editingWorkOrderItem } = this.state;
    const { dispatchCreateNewWorkOrderItem } = this.props;
    dispatchCreateNewWorkOrderItem(createdWorkOrderItem);
  };

  render() {
    const {
      currentStep,
      previousCurrentStep,
      createdWorkOrderItem,
      editingWorkOrderItem,
      editedWorkOrderItem,
      uploadedPhotos,
    } = this.state;

    const {
      propertySections,
      saveOrCancelWorkOrderItems,
      handleSaveAndCancelWorkOrderItem,
      handleCancelSaveWorkOrderItemModal,
      handleRemoveCostItem,
      handleDisableNextButton,
      handleDisableFinishButton,
      propertyDefects,
      itemPromiseFulfilling,
    } = this.props;

    console.log(this.state)

    return(
      <Grid container direction='row' alignItems='center' justify='center'>
        <SaveOrCancelWorkOrderItemsAlert 
          handleCancelSaveWorkOrderItemModal={handleCancelSaveWorkOrderItemModal}
          handleSaveAndCancelWorkOrderItem={handleSaveAndCancelWorkOrderItem}
          saveOrCancelWorkOrderItems={saveOrCancelWorkOrderItems}
        />
        <Grid item xs={12}>
          {
            currentStep == 0 ?
             <CreateWorkOrderItem
                retrieveName={this.retrieveName}
                retrieveDescriptions={this.retrieveDescriptions}
                retrieveSelectedSections={this.retrieveSelectedSections}
                propertySections={propertySections}
                editingWorkOrderItem={editingWorkOrderItem}
                editedWorkOrderItem={editedWorkOrderItem}
                currentStep={currentStep}
                previousCurrentStep={previousCurrentStep}
                handleDisableNextButton={handleDisableNextButton}
             /> :
            currentStep == 1 ?
              <WorkOrderCostItems 
                retrieveSelectedCostItems={this.retrieveSelectedCostItems}
                retrieveCostItems={this.retrieveCostItems}
                handleRemoveCostItem={handleRemoveCostItem}
                createdWorkOrderItem={createdWorkOrderItem}
                editingWorkOrderItem={editingWorkOrderItem}
                editedWorkOrderItem={editedWorkOrderItem}
                currentStep={currentStep}
                previousCurrentStep={previousCurrentStep}
              /> :
            currentStep == 2 ? 
              <WorkOrderDefectItems 
                retrieveSelectedWorkOrderDefects={this.retrieveSelectedWorkOrderDefects}
                propertyDefects={propertyDefects}
                propertySections={propertySections}
                createdWorkOrderItem={createdWorkOrderItem}
                editingWorkOrderItem={editingWorkOrderItem}
                editedWorkOrderItem={editedWorkOrderItem}
                currentStep={currentStep}
                previousCurrentStep={previousCurrentStep}
              /> :
            currentStep == 3 ?
              <WorkOrderItemPhotos
                retrieveWorkOrderItemPhotos={this.retrieveWorkOrderItemPhotos}
                handleDisableFinishButton={handleDisableFinishButton} 
                editingWorkOrderItem={editingWorkOrderItem}
                editedWorkOrderItem={editedWorkOrderItem}
                itemPromiseFulfilling={itemPromiseFulfilling}
              /> : 
            null            
          }
        </Grid>
      </Grid>
    )
  }
} 

export default WorkOrderItems
import 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import {
  Grid, 
  IconButton
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import UndoIcon from '@material-ui/icons/Undo';
var moment = require('moment');

export default function EditDateSelect(props) {
  const [ selectedDate, setSelectedDate ] = useState(null);
  const { retrieveDate, scheduledDate, editSchedule } = props;
  const currentDateTime = moment();

  const handleDateChange = date => {
    // console.log(date > currentDateTime)
    setSelectedDate(date);
  };

  const handleUndo = () => {
    setSelectedDate(scheduledDate);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if (scheduledDate) {
      setSelectedDate(scheduledDate);
    } else {
      setSelectedDate(currentDateTime);
    }
  }, [scheduledDate]);

  const prevSelectedDate = usePrevious(selectedDate);
  useEffect(() => {
    if (selectedDate) {
      if (selectedDate != prevSelectedDate) {
        const formattedSelectedDate = moment(selectedDate).format('YYYY-MM-DD');
        retrieveDate(formattedSelectedDate);
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    let element = document.getElementsByClassName('MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal');
    element[0].style.marginTop = '7px';
  }, []);
 
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={10}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disabled={!editSchedule}
            fullWidth
            margin="normal"
            id="date-picker-dialog"
            label="Est. Service Date"
            format="MM/dd/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            // disablePast={true}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2}>
        { selectedDate != scheduledDate ?
        <IconButton style={{marginTop: '18%'}} color="default" aria-label="edit" onClick={() => handleUndo()}>
          <UndoIcon />
        </IconButton>
        : null }
      </Grid>
    </Grid>
  );
}


// import React, { useState, useEffect } from 'react';
// import TextField from '@material-ui/core/TextField';

// var moment = require('moment');
// moment().format(); import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//   container: {
//     backgroundColor: 'white',
//     width: '100%',
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   textField: {
//     margin: theme.spacing(1),
//     width: '95%',
//   },
// }));

// export default function DatePickers(props) {
//   const [date, setDate] = useState(null);
//   const classes = useStyles();
//   const { retrieveDate, scheduledDate } = props;
//   const today = moment().format('YYYY-MM-DD');

//   const handleChange = (e) => {
//     const date = e.target.value;
//     setDate(date);
//   };

//   useEffect(() => {
//     if (scheduledDate) {
//       const formattedDate = moment(scheduledDate).format('YYYY-MM-DD');
//       setDate(formattedDate);
//     } else {
//       setDate(today);
//     }
//   }, [scheduledDate]);

//   return (
//     <form className={classes.container} noValidate>
//        <TextField
//            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
//            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
//         onChange={handleChange}
//         // disabled={!category3Finished}
//         id="date"
//         label="Est. Service Date"
//         type="date"
//         value={date}
//         className={classes.textField}
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />
//     </form>
//   );
// }

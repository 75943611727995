import React, { useEffect, useState, useReducer } from 'react';
import PropertyInventoryCardImages from './SubComponents/PropertyInventoryCardImages';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RoomIcon from '@material-ui/icons/Room';
import ShowMore from 'react-show-more';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
        boxShadow: 'none',
        fontFamily: 'Ebrima',

    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        fontFamily: 'Ebrima',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        fontFamily: 'Ebrima',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
        fontFamily: 'Ebrima',
    },
    avatar: {
        backgroundColor: 'rgb(31, 89, 100)',
        fontFamily: 'Ebrima',
    },
}));

export default function PropertyInventoryCard(props) {
    const [expanded, setExpanded] = useState(false);

    const {
        inventory,
        images,
        highlightSelectedInventory
    } = props;

    const {
        SecId,
        wbsInvTitle,
        repairCost,
        capitalCost,
        opEx,
        efficiencyCat,
        efficiency,
        ersl,
        make,
        model,
        serial,
        mfgDate,
        score
    } = inventory;

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    
    const handleSelectedInventory = () => {
        const inventoryId = inventory.InvId;
        highlightSelectedInventory(inventoryId);
    };

    const classes = useStyles();

    return (
        <Card square variant="outlined" className={classes.card}>
            <CardHeader classes={{ title: classes.card, subheader: classes.card }}
                onClick={() => handleSelectedInventory(inventory)}
                avatar={<Avatar aria-label="inventory" className={classes.avatar}>{SecId}</Avatar>}
                action={
                    <IconButton aria-label="settings">
                        <RoomIcon />
                    </IconButton>
                }
                title={`${wbsInvTitle}`}
            />
            <CardMedia>
                <PropertyInventoryCardImages
                    images={images}
                />
            </CardMedia>
            <CardContent>
                <div>
                    <Typography variant="body2" color="textPrimary" style={{ fontFamily: 'Ebrima', }} component="p">
                        Estimated Replacement Cost: {formatter.format(capitalCost)}
                    </Typography>
                </div>
                <div>
                    <Typography variant="body2" color="textPrimary" component="p" style={{ fontFamily: 'Ebrima', }}>
                        <ShowMore
                            lines={2}
                            more='more'
                            less='less'
                            anchorClass=''
                        >
                            <Typography variant="body2" color="textPrimary" style={{ fontFamily: 'Ebrima', }} component="p">
                                Operating Expense: {formatter.format(opEx) + ' '}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" style={{ fontFamily: 'Ebrima', }} component="p">
                                Estimated Repairs: {formatter.format(repairCost) + ' '}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" component="p" style={{ fontFamily: 'Ebrima', }}>
                                Manufacturer: {make + ' '}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" component="p" style={{ fontFamily: 'Ebrima', }}>
                                Manufacturer Date: {new Date(mfgDate).toLocaleDateString("en-US")}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" component="p" style={{ fontFamily: 'Ebrima', }}>
                                Model: {model}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" component="p" style={{ fontFamily: 'Ebrima', }}>
                            Serial: {serial}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" component="p" style={{ fontFamily: 'Ebrima', }}>
                            ERSL: {ersl}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" component="p" style={{ fontFamily: 'Ebrima', }}>
                            Criticality Score: {score}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" component="p" style={{ fontFamily: 'Ebrima', }}>
                            Efficiency: {efficiency}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" component="p" style={{ fontFamily: 'Ebrima', }}>
                            Efficiency Category: {efficiencyCat.Category}
                            </Typography>
                        </ShowMore>
                    </Typography>
                </div>
                <div>
                    <Typography variant="body2" color="textPrimary" component="p" style={{ fontFamily: 'Ebrima', }}>
                        <ShowMore
                            lines={1}
                            more='more'
                            less='less'
                            anchorClass=''
                        >
                            <Typography variant="body2" color="textPrimary" style={{ fontFamily: 'Ebrima', }} component="p">
                                Criticality Score: {score + ' '}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" style={{ fontFamily: 'Ebrima', }} component="p">
                                Question 1: 
                            </Typography>
                           
                        </ShowMore>
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
}

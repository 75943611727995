import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '2%',
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Ebrima',
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
    minWidth: 120,
    fontFamily: 'Ebrima',
  },
  selectEmpty: {
    width: '100%',
    marginTop: theme.spacing(2),
    fontFamily: 'Ebrima',
  },
}));

export default function PropertyDefectsSectionDropdown(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [ options, setOptions ] = React.useState([]);

  const { 
    selectedProperty, 
    retrieveSelectedDefectSection, 
    displayRepairs,
  } = props;

  const handleChange = event => {
    const changedValue = event.target.value;
    setValue(changedValue);
  };

  const setDefault = () => {
    if (value) {
      const selectedOption = options.find(option => option.SectionId == value);
      return `Section: ${selectedOption.SecId}`;
    } else {
      return 'All';
    }
  };

  useEffect(() => {
    setOptions(selectedProperty.Sections);
  }, []);

  useEffect(() => {
    retrieveSelectedDefectSection(value);
  }, [value]);

  return (
    <form className={classes.root} autoComplete="off">
      <FormControl className={classes.formControl}>
        <Select
          value={value}
          onChange={handleChange}
          name="Section"
          displayEmpty={true}
          renderValue={setDefault}
          className={classes.selectEmpty}
          defaultValue={'All'}
          disabled={!displayRepairs}
        >
         <MenuItem value={null} style={{fontFamily: 'Ebrima',}}>All</MenuItem>  
         { options.map((option => <MenuItem value={option.SectionId} style={{fontFamily: 'Ebrima',}}>Section: {option.SecId}</MenuItem>)) }
        </Select>
        <FormHelperText style={{fontFamily: 'Ebrima',}}>Section</FormHelperText>
      </FormControl>
    </form>
  );
}

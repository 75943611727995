import React, { useState, useEffect } from 'react';
import PropertyWarrantyCard from './SubComponents/PropertyWarrantyCard'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Grid,
  Paper,
  List,
  Typography,
  IconButton,
  Collapse,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
}));

export default function PropertyWarranties(props) {
  const [warrantyFiles, setWarrantyFiles] = useState([]);
  const [numberOfContacts, setNumberOfContacts] = useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const { property } = props;

  const classes = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (property) {
      const { WarrantyFilesWSecIds } = property;
      if (WarrantyFilesWSecIds) {
        const warrantyFilesExist = WarrantyFilesWSecIds.length;
        if (warrantyFilesExist) {
          const warrantyFiles = property.WarrantyFilesWSecIds;
          const warrantyFilesLength = property.WarrantyFilesWSecIds.length;
          setWarrantyFiles(warrantyFiles);
          setNumberOfContacts(warrantyFilesLength);
        } else {
          setNumberOfContacts(0)
        }
      } else {
        setNumberOfContacts(0);
      }
    }
  }, [property]);

  return (
    <Paper style={{ marginBottom: '5%', marginLeft: '1%', boxShadow: 'none', backgroundColor: 'rgb(233, 233, 233)' }}>
      <Grid container direction={'row'} alignItems={'flex-end'} justify={'flex-start'}>
        <Grid direction={'row'} style={{ display: 'flex' }}>
          <Typography variant='h6' style={{fontFamily: 'Ebrima',}}>Property Warranties <span style={{ color: 'rgba(0, 0, 0, 0.26)', fontFamily: 'Ebrima', }}>({numberOfContacts})</span> </Typography>

          <IconButton
            size={'small'}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            disabled={warrantyFiles.length == 0}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} style={{ height: '90%' }}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List style={{ padding: '0%', width: '99%', maxHeight: 'fit-content', overflowY: 'auto', backgroundColor: 'white' }}>
              { warrantyFiles.map((warrantyFile, i) => {
                return (
                  <PropertyWarrantyCard 
                        i={i}
                        key={'propWty' + i}
                    warrantyFile={warrantyFile}
                  />
                )                
              }) }
            </List>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  )
}


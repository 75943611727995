import React, { useState, useEffect, useRef } from 'react';
import {
  Grid, 
  FormControl,
  TextField, 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root:{
    fontFamily:'Ebrima',
  },
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
    fontFamily: 'Ebrima',
  },
}));

export default function BasicTextFields(props) {
  const [ value, setValue ] = useState('');
  const {
    handleUpdateNote,
    document,
  } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value != previousValue) {
      handleUpdateNote(value);
    }
  }, [value]);

  useEffect(() => {
    if (document) {
      const { Id, Notes } = document;
      if (Id) {
        setValue(Notes);
      } else {
        setValue('');
      }
    }
  }, [document]);

  const classes = useStyles();
  return (
    <Grid container direction='row' justify='center' alignItems='center'>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} noValidate autoComplete="off">
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            id="standard-basic"
            label="Add Note"
            value={value}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  // TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  table: {
  
  },
});

export default function SimpleTable(props) {
  const { removeCreatedCostItems, createdCostItems } = props;
  const classes = useStyles();

  const handleRemoveCreatedCostItem = (selectedCostItem) => {   
    removeCreatedCostItems(selectedCostItem);
  };

  return (
    <Paper style={{width: '100%', height: '100%', overflow: 'auto'}}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Unit Price&nbsp;($)</TableCell>
            <TableCell align="right">Est Cost&nbsp;($)</TableCell>
            <TableCell align="right">Remove Item</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { createdCostItems.map((createdCostItem, i) => {
              const category = createdCostItem.Category;
              const description = createdCostItem.Description;
              const quantity = createdCostItem.Quantity;
              const unitPrice = createdCostItem.UnitPrice;
              return (
                    <TableRow key={`${i}-${category}`}>
                      <TableCell component="th" scope="row">
                        {category}
                      </TableCell>
                      <TableCell align="right">{description}</TableCell>
                      <TableCell align="right">{quantity}</TableCell>
                      <TableCell align="right">{unitPrice}</TableCell>
                      <TableCell align="right">{quantity * unitPrice}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => handleRemoveCreatedCostItem(createdCostItem)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
              ) 
          }) }
        </TableBody>
      </Table>
    </Paper>
  );
}

/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  FormControl,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Tags(props) {
  const [ value, setValue ] = useState([]);
  const {
    handleFromBuildingSelect,
    userBuildings,
    selectedCompany,
  } = props;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValueLength = usePrevious(value.length);
  useEffect(() => {
    if (value.length != previousValueLength) {
      if (value) {
        // console.log('passing')
        // console.log(value)
        handleFromBuildingSelect(value);
      } else {
        // console.log('passing []')
        // handleFromBuildingSelect([]);
      }
    }
  }, [value]);

  //set selected values back to initial state if 
  //company from which the buildings were determined, is changed
  // const previousUserBuildings = usePrevious(userBuildings);
  // useEffect(() => {
  //   if (userBuildings) {
  //     const userBuildingsExists = userBuildings.length;
  //     if (!userBuildingsExists) {
  //       if (previousUserBuildings) {
  //         if (previousUserBuildings.length) {
  //           console.log('reset 1')
  //           setValue([]);
  //         }
  //       }
  //     }
  //   }
  // }, [userBuildings]);

  const previousSelectedCompany = usePrevious(selectedCompany);
  useEffect(() => {
    if (selectedCompany) {
      const selectedCompanyId = selectedCompany.CompanyId;
      const previousSelectedCompanyId = previousSelectedCompany ? previousSelectedCompany.CompanyId : 0;
      console.log(selectedCompanyId, previousSelectedCompanyId)
      if (!selectedCompanyId && !previousSelectedCompanyId) {
        setValue([]);
        //dispatch set userBuildings to []
      }
      if (selectedCompanyId != previousSelectedCompanyId) {
        setValue([]);
      }
    }
  }, [selectedCompany]);

  const classes = useStyles();
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            multiple
            disableClearable	
            disableCloseOnSelect
            disabled={!userBuildings || !userBuildings.length}
            id="tags-standard"
            onChange={(e, newValue) => setValue(newValue)}
            value={value}
            options={userBuildings ? userBuildings : []}
            getOptionLabel={(building) => building.Name}
            renderInput={(params) => (
               <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                {...params}
                variant="standard"
                label="From Buildings"
                placeholder={ !value.length ? "Select Buildings" : null }
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import ManageContactsUsers from './ManageContactsUsers';
import ManageContactAddContactModal from './SubComponents/ManageContactsAddContactModal';
import auth from '../../auth';
import {
  Grid,
  Modal,
} from '@material-ui/core';
import ManageContactsBldgsContainer from './ManageContactsBldgsContainer';

class ManageContacts extends Component {
  constructor(props){
    super(props)

    
    
    this.vm = dotnetify.react.connect('ManageContacts', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        console.log(ex);
          auth.signOut();
      }
    });

    this.vm.onRouteEnter = (path, template) => (template.Target = 'Contact-Content');
    this.dispatch = state => this.vm.$dispatch(state);
    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
      Loading: true,
      Contacts: [],
      Properties: [],
      NavRoutes: [],
      UserBuildingContacts: [],
      CompanyBuildings: [],
      selectedContact: null,
      addContactToProperty: false,
      selectedContactType: null,
      filteredContacts: [],
      removedBuildings: [],
      selectedBuildings: [],
      lastPropertySelected: null,
      addContactToPropertyModal: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      selectedContact,
      Contacts,
      filteredContacts,
      selectedContactType,
      Id,
      saveAddContacts,
      addContactToPropertyModal,
      selectedBuildings,
    } = this.state;

    const previouslySelectedContact = prevState.selectedContact;
    const previouslySelectedContactType = prevState.selectedContactType;
    const previousAddContactToPropertyModal = prevState.addContactToPropertyModal;

    if (selectedContact) {
      if (selectedContact != previouslySelectedContact) {
        const contactsCopy = [...Contacts];
        const filteredContacts = contactsCopy.filter(contact => contact == selectedContact)
        this.filterContacts(filteredContacts);
      }

      if (selectedContactType) {
        if (selectedContactType != previouslySelectedContactType) {
          const { companyId, contactType } = selectedContactType;
          const { ContactTypeId } = contactType;
          const userId = Id;
          const contactTypeSelected = { CompanyId: companyId, ContactTypeId: ContactTypeId, UserId: userId };
          this.dispatch({ ContactTypeSelected: contactTypeSelected });
        }
      } 

      if (!previousAddContactToPropertyModal) {
        if (addContactToPropertyModal) {
          this.openAddContactToPropertyModal();
        }
      }
    }

    if (!selectedContact) {
      if (Contacts != filteredContacts) {
        this.filterContacts(Contacts);
      }
    }

    if (saveAddContacts) {
      const contactTypeId = selectedContactType.contactType.ContactTypeId;
      const companyId = selectedContactType.companyId;

      const dispatchAssignContacts = {
        CompanyId: companyId,
        ContactTypeId: contactTypeId,
        UserId: Id,
        SelectedBuildings: selectedBuildings,
      };

      this.setState({
        saveAddContacts: false,
      }, this.dispatch({ AssignPropertyContacts: dispatchAssignContacts }));
    }
  }

  filterContacts = (filteredContacts) => {
    this.setState({
      filteredContacts,
    })
  };

  handleCardClick = (selectedContact) => {
    const currentlySelectedContact = this.state.selectedContact;
    const selectedId = selectedContact.Id;
    selectedContact == currentlySelectedContact ?
    this.setState({ selectedContact: null },
    this.dispatch({ ContactSelected: 0 })) : 
    this.setState({ selectedContact },
    this.dispatch({ ContactSelected: selectedId }));
  };

  handleRemoveBuilding = (building) => {
    const deletedBuildingContact = {
      PropertyId: building.Id,
      CompanyId: 0,
      UserId: this.state.selectedContact.Id,
      ContactTypeId: building.ContactType.ContactTypeId,
      Selected: building.ContactType.Selected,
    };
    this.dispatch({ DeleteContact: deletedBuildingContact })
  };

  addContactToPropertyClick = () => {
    const { addContactToPropertyModal } = this.state;
    this.setState({
      addContactToPropertyModal: !addContactToPropertyModal,
    })
  };

  openAddContactToPropertyModal = () => {
    this.setState({
      addContactToPropertyModal: true,
    })
  }

  closeAddContactToPropertyModal = () => {
    this.setState({
      addContactToPropertyModal: false,
    })
  }

  cancelAddContactToPropertyModal = () => {
    this.setState({
      selectedBuildings: [],
      noBuildingsForContactType: false,
      selectedContactType: null,
    }, this.closeAddContactToPropertyModal())
  };

  selectContactType = (selectedContactType) => {
    this.setState({ selectedContactType })
  };

  selectBuilding = (selectedBuilding) => {
    const { selectedBuildings } = this.state;
    const buildingToRemove = selectedBuildings.find(
      building => building.Id == selectedBuilding.Id
    );

    if (buildingToRemove) {
      const filteredSelectedBuildings = selectedBuildings.filter(
        building => building.Id != buildingToRemove.Id
        );
      this.setState({
        selectedBuildings: filteredSelectedBuildings,
      })
    }
    if (!buildingToRemove){
      this.setState({
        selectedBuildings: [...selectedBuildings, selectedBuilding]
      });
    }
  };

  saveAddContacts = () => {
    this.setState({
      saveAddContacts: true,
    }, this.closeAddContactToPropertyModal())
  };

  componentWillUnmount = () => {
    console.log('unmounting MANAGECONTACTS')
    this.vm.$destroy();
  };

  render() {   
      
    const {
      Loading,
      filteredContacts,
      UserBuildingContacts,
      selectedContact,
      addContactToProperty,
      CompanyBuildings,
      addContactToPropertyModal,
      selectedContactType,
    } = this.state;

    return(
      <div id='Contact-Content' style={{ display: 'contents' }}>
        <Grid id='contacts-master-grid' style={{ padding: '5%', height: '40rem' }} container> 
          <Modal
            open={ addContactToPropertyModal } 
            onClose={ this.closeAddContactToPropertyModal } 
            onBackdropClick={ this.cancelAddContactToPropertyModal }
          >
            <ManageContactAddContactModal
              selectBuilding={ this.selectBuilding }
              saveAddContacts={ this.saveAddContacts }
              selectContactType={ this.selectContactType }
              filteredContacts={ filteredContacts }
              companyBuildings={ CompanyBuildings }
              selectedContactType={ selectedContactType }
            />  
          </Modal>
          <ManageContactsUsers
            handleCardClick={ this.handleCardClick }
            addContactToPropertyClick={ this.addContactToPropertyClick }
            selectContactType={ this.selectContactType }
            selectBuilding={ this.selectBuilding }
            saveAddContacts={ this.saveAddContacts }
          
            userBuildingsContacts={ UserBuildingContacts }
            filteredContacts={ filteredContacts }
            selectedContact={ selectedContact }
            addContactToProperty={ addContactToProperty }
            companyBuildings={ CompanyBuildings }
            loading={ Loading }
          />
          <ManageContactsBldgsContainer
            handleRemoveBuilding={ this.handleRemoveBuilding }
            userBuildingsContacts={ UserBuildingContacts }
            selectedContact={ selectedContact }
          />
        </Grid>
      </div>
     )
   }
 };
 
export default ManageContacts
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Button,
  Grid,
  IconButton,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '95%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

export default function AddContractorInvoiceDoc(props) {
  const [fileName, setFileName] = useState(null);
  const { 
    handleOpenDocument,
    retrieveContractorInvoiceDoc,
    contractorInvoiceUrl 
} = props;
  const classes = useStyles();
  const inputFile = useRef(null);

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onSelectFile = () => {
    const file = inputFile.current.files[0];
    const name = file.name;
    setFileName(name);
    retrieveContractorInvoiceDoc(file);
  };

  const onFileClick = () => {
    handleOpenDocument();
  };

  const onDeleteFile = () => {
    setFileName(null);
    retrieveContractorInvoiceDoc(null);
  };

  useEffect(() => {
    if (contractorInvoiceUrl) {
      setFileName(contractorInvoiceUrl);
    }
  }, [contractorInvoiceUrl]);

  return (
    <FormControl variant="filled" className={classes.formControl}>
      <Grid container style={{ minHeight: '3rem' }}>
        { fileName ?
          <Grid container direction='row' alignItems='center' justify='center'>
            <input type='file' id='file' ref={inputFile} onChange={() => onSelectFile()} style={{ display: 'none' }} />
            <Grid item xs={11}>
              <Button variant='contained' color='primary' style={{ width: '95%', maxWidth: '18rem', maxHeight: '2rem', overflow: 'hidden', backgroundColor: 'seagreen' }} onClick={onFileClick}>{fileName}</Button>
            </Grid>
            <Grid item xs={1}>
              <IconButton edge='start' style={{ textAlign: 'center' }} onClick={() => onDeleteFile()}>
                <DeleteOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
          :
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={12}>
              <input type='file' id='file' ref={inputFile} onChange={() => onSelectFile()} style={{ display: 'none' }} />
              <Button variant='contained' color='primary' style={{ width: '100%' }} onClick={onButtonClick}>{'Add Contractor Invoice'}</Button>
            </Grid>
          </Grid>
        }
      </Grid>
    </FormControl>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Card,
  Divider,
  Typography,
  IconButton,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import EditIcon from '@material-ui/icons/Edit';

const headerStyle = {
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  overflow: 'hidden',
  maxHeight: '3.5em',
  lineHeight: '1.8em',
  fontFamily: 'Ebrima',
};

const typographyStyle = { 
  width: '240px', 
  whiteSpace: 'nowrap', 
  overflow: 'hidden', 
  textOverflow: 'ellipsis',
  fontFamily: 'Ebrima', 
};

const spanStyle = {
  fontSize: '75%',
  color: 'darkGray',
  marginLeft: '2%',
  marginRight: '2%',
  fontFamily: 'Ebrima',
};

const tagStyle = {
  width: '190px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  fontFamily: 'Ebrima',
};

const notesStyle = {
  display:  'inline-block',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  overflow: 'hidden',
  maxHeight: '3.5em',
  lineHeight: '1.8em',
  fontFamily: 'Ebrima',
};


export default function DocumentCard(props) {
  const [ tagNames, setTagNames ] = useState('');
  const { 
    handleModalOpen,
    handleModalTagOpen,

    handleDeleteModalOpen,
    document,
  } = props;

  const {
    BuildingName,
    FileName,
    FileUrl,
    Id,
    Notes,
    FileTypeNavigation,
    OrgTags,
  } = document;

  const handleOpenFile = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
  };

  useEffect(() => {
    if (OrgTags) {
      const orgTagNames = [];
      OrgTags.map((orgTag) => orgTagNames.push(orgTag.Title));
      const orgTagString = orgTagNames.join(', ');
      setTagNames(orgTagString);
    }
  }, [OrgTags]);
  
  return (
    <Card square style={{ height: '100%' }}>
      <Grid container direction='row' alignItems='center' justify='center'>

        <Grid item xs={12} style={{ backgroundColor: '#66BFFA' }}>
          <Grid container direction='row' alignItems='center' justify='space-between'>
            <Grid item xs={1}>
              {!OrgTags ? <LocationCityIcon /> : <LocalOfferIcon />}
            </Grid>
            <Grid item xs={11}>
              <Grid container direction='row' alignItems='center' justify='flex-end'>
                <Grid item xs={1}>
                  <IconButton style={{ color: 'white' }} size='small' onClick={() => handleDeleteModalOpen(document)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  <IconButton style={{ color: 'white' }} size='small' onClick={!OrgTags ? () => handleModalOpen(Id) : () => handleModalTagOpen(Id)}>
                    <EditIcon />
                  </IconButton>
                </Grid> 
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    
      <Grid container direction='row' alignItems='center' justify='center'>
        <Grid item xs={12} style={{ height: '3rem', width: '100%', overflow: 'auto', textAlign: 'center' }}>
          <Grid container direction='row' justify='center' alignItems='center' style={{ height: '100%', width: '100%' }}>
            <Grid item xs={12}>
              <Typography variant='button' style={headerStyle}> {FileName ? FileName : 'Untitled'} </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
      <Divider />

      <Grid container direction='row' alignItems='center' justify='space-evenly' onClick={() => handleOpenFile(FileUrl)} style={{ height: '8rem', padding: '1%' }}>
        { !OrgTags ?
          <Grid item xs={3} style={{ textAlign: 'left' }}>
            <Typography style={typographyStyle} variant='button'> <span style={spanStyle}> Building: </span> </Typography>
          </Grid>
          :
          <Grid item xs={3} style={{ textAlign: 'left' }}>
            <Typography style={typographyStyle} variant='button'> <span style={spanStyle}> Tags: </span> </Typography>
          </Grid>
        }
        { !OrgTags ?
          <Grid item xs={9} style={{ textAlign: 'left' }}>
            <Typography style={typographyStyle} variant='button'> {BuildingName} </Typography>
          </Grid>
          :
          <Grid item xs={9} style={{ textAlign: 'left' }}>
            <Typography variant='button' noWrap style={tagStyle}> {tagNames} </Typography>
          </Grid>
        }
        <Grid item xs={3} style={{ textAlign: 'left' }}>
          <Typography style={typographyStyle} variant='button'> <span style={spanStyle}> Type: </span> </Typography>
        </Grid>
        <Grid item xs={9} style={{ textAlign: 'left' }}>
          <Typography style={typographyStyle} variant='button'> {FileTypeNavigation.Name} </Typography>
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'left' }}>
          <Typography style={typographyStyle} variant='button'> <span style={spanStyle}> Notes: </span> </Typography>
        </Grid>
        <Grid item xs={9} style={{ textAlign: 'left' }}>
          <Typography style={notesStyle} variant='button'> {Notes} </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

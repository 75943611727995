import React from 'react';
import {SvgIcon} from '@material-ui/core';

let SvgWorkOrders = (props) => (
  <SvgIcon {...props} >
       <path d="M22,2V22H2V2Zm2-2H0V24H24ZM20,7H12V8h8Zm0,5H12v1h8Zm0,5H12v1h8ZM9.5,5.7,8.8,5,6.2,7.6,4.7,6.2,4,6.9,6.2,9Zm0,5L8.8,10,6.2,12.6,4.7,11.1l-.7.8L6.2,14Zm0,5L8.8,15,6.2,17.6,4.7,16.1l-.7.8L6.2,19Z" />
  </SvgIcon>
);

export default SvgWorkOrders;

import React from 'react';
import Iframe from 'react-iframe';
import {
  Grid,
} from '@material-ui/core';

export default function PropertyBuildingPlan(props) {
  const {
    property,
  } = props;

  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%', width: '100%' }}>
      <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
        <Iframe
          id="receipt"
          url={property.BuildingPlanURLPDF}
          width='100%'
          height={'100%'}
          id='myId'
          display='initial'
          position='relative'
          frameBorder='0'
        />
      </Grid>
    </Grid>        
  );
}
import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  Card,
  TextField,
  Switch,
  Button,
} from '@material-ui/core';
import LeakResponse from '../../../LeakResponse';

const COMPONENT_LEAK_RESPONSE_STEP = 0;

function RequestLeakResponse(props) {
  const [ emergency, setEmergency ] = useState(false);
  const [ description, setDescription ] = useState('');
  const [ disabled, setDisabled ] = useState(true);
  const [ nextSelected, setNextSelected ] = useState(false);
  const {
    handleIncrementWizardStep,
    handleEmergencySwitch,
    handleUpdateDescription,

    leakResponse,
  } = props;

  
  const handleTextChange = (e) => {
    const text = e.target.value;
    setDescription(text);
  };

  const handleNextClick = () => {
    setNextSelected(true);
  };

  const handleSwitch = (e) => {
    const value = e.target.checked;
    setEmergency(value);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousEmergency = usePrevious(emergency);
  useEffect(() => {
    if (typeof previousEmergency === 'undefined') {
      if (leakResponse) {
        const leakResponseExists = Object.values(leakResponse).length;
        if (leakResponseExists) {
          const {
            Emergency,
          } = leakResponse;
          if (emergency) {
            if (emergency != Emergency) {
              handleEmergencySwitch(emergency);
            }
          }
        }
      }
    }
    if (typeof previousEmergency === 'boolean') {
      if (emergency != previousEmergency) {
        handleEmergencySwitch(emergency);
      }
    }
  }, [emergency]);

  useEffect(() => {
    if (description && description.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [description]);

  useEffect(() => {
    if (nextSelected) {
      const nextStep = COMPONENT_LEAK_RESPONSE_STEP+1;
      handleUpdateDescription(description);
      handleIncrementWizardStep(nextStep);
      setNextSelected(false);
      setDisabled(true);
    }
  }, [nextSelected]);

  useEffect(() => {
    if (leakResponse) {
      const leakResponseExists = Object.values(leakResponse).length;
      if (leakResponseExists) {
        const {
          Description,
          Emergency,
        } = leakResponse;
        if (!description) {
          if (Description) {
            setDescription(Description);
          }
        }
        if (Emergency != emergency) {
          setEmergency(Emergency);
        }
      }
    }
  }, [leakResponse]);

  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>
      <Grid item xs={12} style={{ height: '100%' }}>
        <Card square style={{ height: '100%', width: '100%', padding: '2%', marginBottom: '12%', boxShadow: 'none' }}>
          <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>
            
            <Grid item xs={12}>
              <Grid container direction='row'>
                
                <Grid container direction='row' alignItems='center' justify='center'>
                  <Grid item xs={12} style={{ borderBottom: '3px solid #e74683'}}>
                    <Typography variant='h4'>Leak Response Request Form</Typography>
                  </Grid>
                </Grid>

                <Grid container direction='row' alignItems='center' justify='center'>
                  <Grid item xs={12} style={{ textAlign: 'left' }}>
                    <Typography>BLUEFIN provides 24/7 leak response to our clients.</Typography>
                    <Typography style={{ marginTop: '1%' }}>You can notify BLUEFIN of your leak by</Typography>
                  </Grid>
                </Grid>

                <Grid container direction='row' alignItems='center' justify='center'>
                  <Grid item xs={12}>
                    <ul>
                      <li style={{ color: '#4f99bc' }}>
                        <Typography style={{ color: '#333333' }}>calling <span style={{ fontWeight: 'bold' }}>844-511-8364</span></Typography>
                      </li>
                      <li style={{ color: '#4f99bc' }}>
                        <Typography style={{ color: '#333333' }}>emailing <span style={{ fontWeight: 'bold' }}>leakresponse@mantisinnovation.com</span></Typography>
                      </li>
                      <li style={{ color: '#4f99bc' }}>
                        <Typography style={{ color: '#333333' }}>filling out and submitting this leak response request</Typography>
                      </li>
                    </ul>
                  </Grid>
                </Grid>

                <Grid container direction='row' alignItems='center' justify='center'>
                  <Grid item xs={12} style={{ textAlign: 'left' }}>
                    <Typography>Thank you, we will be in contact with you soon</Typography>
                  </Grid>
                </Grid>

                <Grid container direction='row' alignItems='center' justify='flex-start'>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={1} style={{ textAlign: 'left' }}>
                    
                    <Grid container direction='row' alignItems='center' justify='flex-start'>
                      <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography>Is this an Emergency Leak?</Typography>
                      </Grid>
                    </Grid>

                    <Grid container direction='row' alignItems='center' justify='center'>
                      <Grid item xs={3} style={{ textAlign: 'center' }}>
                        <Typography>No</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container direction='row' alignItems='center' justify='center' >
                          <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Switch
                              color='secondary'
                              checked={emergency}
                              onChange={handleSwitch}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'center',  }}>
                        <Typography>Yes</Typography>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

              </Grid>
            </Grid>            
            
            <Grid container direction='row' alignItems='center' justify='center' style={{ display: 'flex' }}>
              <Grid item xs={12}>
                <Grid container direction='row'>
                  
                  <Grid item xs={12}>
                    <Grid container direction='row'>
                      
                      <Grid item xs={12}>
                        <Grid container direction='row' alignItems='center' justify='flex-start'>
                          <Grid item xs={12} sm={12} md={12} lg={9} xl={6}>
                            <Typography variant='h6'>Describe Request:</Typography>
                          </Grid>
                        </Grid>                        
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container direction='row' alignItems='center' justify='center'>
                          <Grid item xs={12}>
                             <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                              id="outlined-multiline-static"
                              multiline
                              margin='none'
                              rows={2}
                              variant='filled'
                              autoFocus
                              value={description}
                              onChange={handleTextChange}
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid container direction='row' alignItems='center' justify='space-evenly' style={{ position: 'fixed', bottom: '1%', left: '1.5%', width: '97.5%', backgroundColor: '#66BFFA' }}>
                    <Grid item xs={3} style={{ textAlign: 'center', padding: '5px' }}>
                      <Button variant='contained' color='default' disabled={true} style={{ boxShadow: 'none' }}>Back</Button>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'center', padding: '5px' }} >
                      <Button variant='contained' color='default' disabled={disabled} onClick={() => handleNextClick()} style={{ boxShadow: 'none' }}>Next</Button>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
         
        </Card>
      </Grid>
    </Grid>
  )
};

export default RequestLeakResponse
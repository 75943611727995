import React, { useState, useEffect } from 'react';
import PropertyMediaDefectPlan from './PropertyMediaDefectPlan';
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export default function PropertyMediaDefectPlanContainer(props) {
  const [buildingName, setBuildingName] = useState('');
  const [defectPlanDwg, setBuildingPlanDwg] = useState('');
  const {
    property,
  } = props;

  useEffect(() => {
    if (property) {
      const {
        Name,
        DefectPlanURLDWG
      } = property;

      if (Name) {
        setBuildingName(Name);
      }

      if (DefectPlanURLDWG) {
        setBuildingPlanDwg(DefectPlanURLDWG);
      }
    }
  }, [property]);

  return (
    <Grid id='building-plan-container-master-row' container direction='row' alignItems='center' justify='space-between' style={{ height: '100%', display: 'table' }}>
      <Grid item xs={12} style={{ display: 'table-cell' }}>
        <Grid container direction='row' alignItems='center' style={{ width: '100%', backgroundColor: 'cadetBlue' }}>
          <Grid item xs={12} style={{ margin: '1%', display: 'inline-flex' }}>
            <SearchIcon style={{ color: 'white' }} />
            <Typography style={{ color: 'white', fontFamily:'Ebrima',  }}> Building Plan - {buildingName}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ display: 'table-row', height: '100%' }}>
        <Grid container direction='row' justify='center' alignItems='space-between' style={{ height: '100%', display: 'table' }}>
          <Grid item xs={12} style={{ display: 'table-cell', height: '100%' }}>
            <PropertyMediaDefectPlan
              property={property}
            />
          </Grid>
          <Grid item xs={12} style={{ display: 'table-row', backgroundColor: 'cadetBlue' }}>
            <Grid container direction='row' alignItems='center' justify='center' style={{ width: '100%', padding: '2px' }}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button variant='contained' color='secondary' style={{fontFamily: 'Ebrima',}} href={defectPlanDwg} disabled={!defectPlanDwg}>Download DWG</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
} 
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const ManageContactsAddUserModalCard = makeStyles(theme => ({
  card: {
    width: '50%',
    margin: 'auto',
    marginTop: '10%',
    maxHeight: '40rem'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  cardContent: {
  },
}));

export default ManageContactsAddUserModalCard
import React from 'react';
import {SvgIcon} from '@material-ui/core';

let SvgWorkOrders = (props) => (
  <SvgIcon {...props} >
       <path d="M14.9,5.2c-4.3,5.6-7.1,9.2-7.1,12.6S11,24,14.9,24,22,21.2,22,17.8C21.9,14.4,19.1,10.8,14.9,5.2Z" transform="translate(-2.1)"/>
       <path d="M11,6.3A32.2288,32.2288,0,0,0,6.8,0C3.9,3.8,2.1,6.1,2.1,8.4a4.4163,4.4163,0,0,0,4.5,4.1A68.0607,68.0607,0,0,1,11,6.3Z" transform="translate(-2.1)"/>
  </SvgIcon>
);

export default SvgWorkOrders;

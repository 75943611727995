import { makeStyles } from '@material-ui/core/styles';

const ApplicationSearchItemStyle = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    display: 'contents',
    fontFamily: 'Ebrima',
  },
}));

export default ApplicationSearchItemStyle
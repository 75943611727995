
import React from 'react';
import dotnetify from 'dotnetify';
import {MuiThemeProvider, createMuiTheme} from 'material-ui/styles';
import MenuItem from 'material-ui/MenuItem';
import MenuItemCore from '@material-ui/core/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import Button from '@material-ui/core/Button';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';
import { green400, green600, grey50, pink500, grey200, grey300, grey500, grey400, pink400 } from 'material-ui/styles/colors';
import BasePage from '../components/BasePage';
import ThemeDefault from '../styles/theme-default';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, TableCell } from 'material-ui/Table';
import InlineEdit from '../components/table/InlineEdit';
import InlineEditSpan from '../components/table/InlineEditSpan';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import OrgPermissions from '../components/OrgPermissions';
import { RouteLink } from 'dotnetify';
import Pagination from '../components/table/Pagination';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import Snackbar from 'material-ui/Snackbar';
import IconRemove from 'material-ui/svg-icons/content/clear';
import IconEdit from 'material-ui/svg-icons/content/create';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import TextFieldCore from '@material-ui/core/TextField';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Divider from '@material-ui/core/Divider';
import globalStyles from '../styles/styles';
import Paper from 'material-ui/Paper';
import auth from '../auth';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Properties from '../components/Properties/Properties';
import OrgTagSearchBar from './OrgTags/SubComponents/OrgTagSearchBar';
import OrgTagFilterSelect from './OrgTags/SubComponents/OrgTagFilterSelect';
import { Chip } from '@material-ui/core';
import PropertyBuildingImages from '../components/Property/PropertyImageGalleries/PropertyBuildingImages';


const useStyles = makeStyles => theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class OrgTagsPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = dotnetify.react.connect('OrgTags', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        auth.signOut();
      }
    });

    this.dispatch = state => this.vm.$dispatch(state);
    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
      dirty: false,
      NavRoutes: [],
      LoggedInUserRoleId: '',
      Clients: [],
      SelectedClient: null,
      Tags: [],
      SelectedTag: null,
      Properties: [],
      addTagOpen: false,
      editTagOpen: false,
      NewTag: '',
      newtagdirty: false,
      edittagdirty: false,
      EditTag: '',
      orgTagFilter: 0,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { 
      SearchText, 
      orgTagFilter, 
      Properties,
      TagUsers 
    } = this.state;
    
    console.log(Properties);
  }


  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  retrieveOrgTagSearch = (searchText) => {
    console.log(searchText);

    this.dispatch({ SearchText: searchText });
    this.setState({
      searchText,
    })
  };

  retrieveOrgTagFilter = (orgTagFilter) => {
    this.dispatch({TagFilter: orgTagFilter});
    this.setState({
      orgTagFilter,
    })
  };

  componentWillUnmount() {
    console.log('unmounting TAG MANAGER')
    this.vm.$destroy();
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    let { dirty, NavRoutes, Clients, SelectedClient, Tags, SelectedTag, Properties, 
      SearchText, addTagOpen, editTagOpen, NewTag, newtagdirty, ShowNotification,
      NotificationMessage, edittagdirty, EditTag, TagUsers } = this.state;

      const {
        width,
        height
      } = this.state;

    const styles = {
      color: green400,
      addButton: {
        margin: '1em',
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
      },
      passwordprompts: {
        fontSize: 14
      },
      formControl: {
        margin: ThemeDefault.spacing.unit,
        minWidth: 220,
        maxWidth: 300,
        display: 'flex',
        wrap: 'nowrap'
      },
      selectLabel: { color: pink400 },
      toggleDiv: {
        maxWidth: 300,
        marginTop: 40,
        marginBottom: 5
      },
      toggleLabel: {
        color: grey400,
        fontWeight: 100
      },
      buttons: {
        marginTop: 10,
        marginLeft: 40,
        float: 'right'
      },
      names: {
        marginTop: 0,
        marginLeft: 0,
        marginRight: 10,
        float: 'none'
      },
      divider: {
        marginTop: 5,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 5,
        float: 'none'
      },
      columns: {
        Tagged: { width: '7%' },
        BuildingName: { width: '20%', paddingRight: 5, paddingLeft: 5 },
        BuildingNameTagged: { width: '15%', paddingRight: 5, paddingLeft: 5 },
        BuildingNameTags: { width: '40%', paddingRight: 5, paddingLeft: 5 },
        BuildingNameAddress: { width: '15%', paddingRight: 5, paddingLeft: 5 },
        BuildingNameCity: { width: '7%', paddingRight: 5, paddingLeft: 5 },
        BuildingNameZip: { width: '4%', paddingRight: 5, paddingLeft: 5 },
        UserName: { width: '20%', paddingRight: 5, paddingLeft: 5},
        Tags: { width: '65%', paddingRight: 5, paddingLeft: 5},
      },
      textField: {
        marginLeft: 20,
        marginRight: 20,
        width: 400,
      },
      clear: {
        height: 0,
        borderWidth: 0,
        float: 'left'
      },
      formControl: {
        margin: 25,
      },
      saveButton: {
        marginLeft: 15,
      },
      addButton: {
        marginLeft: 15,
        float: 'right',
      },
      removeIcon: { fill: grey50 },
      editIcon: { fill: grey50 },
      buttonContainer: { marginTop: 10, display: 'inline-flex', width: '100%' },
    }

    const ITEM_HEIGHT = 28;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 20.5 + ITEM_PADDING_TOP,
          width: 350,
        },
      },
    };

    const handleNavLinkClick = obj => {
      obj.e.preventDefault();
      console.log(obj.item);
      this.routeTo(obj.item.Route);
    };

    const handleSelectCompanySave = event => {
      console.log("handleSelectCompanySave");
      this.dispatch({ SelectCompany: { UserId: UserId, Companies: SelectedCompanies.Companies } });
      this.setState({ clientfilterdirty: false, ClientSearch: '' });
    };

    const handleCancel = _ => {
      this.dispatch({ Cancel: UserId });
      this.setState({ dirty: false, adddirty: false });
    };

    const handleSave = _ => {
      let newState = PortalUserOrgUnits.filter(item => (item.View === false && item.Edit === false));

      let newUOU = UnassignedOrgUnits.concat(newState);

      let newPUOU = PortalUserOrgUnits.filter(item => (item.View === true || item.Edit === true));

      console.log("COUNT:" + PortalUserOrgUnits.length);
      this.dispatch({
        Save: {
          UserId: UserId, Email: Email, Phone1: Phone1, Phone2: Phone2, FirstName: FirstName, LastName: LastName, Add1: Add1, Add2: Add2, City: City,
          Zip: Zip, State: State, Country: Country, RoleId: RoleId, RoleTitle: RoleTitle,
          PortalUserOrgUnits: PortalUserOrgUnits, Active: Active, WebsiteAccess: WebsiteAccess
        }
      });

      this.setState({ PortalUserOrgUnits: newPUOU, UnassignedOrgUnits: newUOU, dirty: false, adddirty: false });
    };

    const handleSaveOrgTags = _ => {
      let newState = PortalUserOrgUnits.filter(item => (item.View === false && item.Edit === false));

      let newUOU = UnassignedOrgUnits.concat(newState);

      let newPUOU = PortalUserOrgUnits.filter(item => (item.View === true || item.Edit === true));

      console.log("COUNT:" + PortalUserOrgUnits.length);
      this.dispatch({ SaveOrgTags: { UserId: UserId, PortalUserOrgUnits: PortalUserOrgUnits, SelectedCompanies: SelectedCompanies } });

      this.setState({ PortalUserOrgUnits: newPUOU, UnassignedOrgUnits: newUOU, orgsdirty: false, adddirty: false });
    };

    const handleSetAllSelectedOrgTag = event => {
      console.log("handleSelectCompanySave");
      this.dispatch({ SetOrgTagOnAllBuildings: SelectedTag });
    };

    const handleUnsetAllSelectedOrgTag = event => {
      console.log("handleSelectCompanySave");
      this.dispatch({ UnsetOrgTagOnAllBuildings: SelectedTag });
    };

    const handleTaggedUpdate = event => {
      console.log("handleTaggedUpdate" + event.Properrr);
      
      event.Property.Selected = event.Selected;
      this.dispatch({ UpdatePropertyTag: event.Property });
    };

    const handleTaggedUserUpdate = event => {
      console.log("handleTaggedUserUpdate" + event.Selected);
      console.log(event);
      console.log(event.User);

      //event.User.Selected = event.Selected;
      this.dispatch({ UpdateUserTag: event.User });
    };

    const handleAdd = _ => {
      let newState = UnassignedOrgUnits.filter(item => (item.View === true || item.Edit === true));

      let newPUOU = PortalUserOrgUnits.concat(newState);

      let newUOU = UnassignedOrgUnits.filter(item => (item.View === false && item.Edit === false));

      this.dispatch({ SaveOrgTags: { UserId: UserId, PortalUserOrgUnits: newPUOU, SelectedCompanies: SelectedCompanies } });

      this.setState({ PortalUserOrgUnits: newPUOU, UnassignedOrgUnits: newUOU, adddirty: false });
    };

    const handleClientUpdate = _ => {
      console.log('called')
      let newState = Clients.filter(item => (item.CompanyId === _.CompanyId));
      this.dispatch({ SelectedClientUpdated: { CompanyId: _.CompanyId, Name: _.Name, Selected: true, Visible: true } });

      this.setState({ SelectedClient: { CompanyId: _.CompanyId, Name: _.Name } });
    };

    const handleTagSelected = tag => {
      let newState = Tags.filter(item => (item.OrgUnitId === tag.OrgUnitId));
      this.dispatch({ TagSelected: tag });

      this.setState({ SelectedClient: { CompanyId: _.CompanyId, Name: _.Name } });
    };

    const onChangeEditTag = event => {
      EditTag = event.target.value;
      this.setState({ EditTag: event.target.value });
      this.setState({ SelectedClient: { CompanyId: _.CompanyId, Name: _.Name } });
    };

    const handleUpdateTag = tag => {
      console.log(SelectedTag.Title);
      console.log(EditTag);
      var updateTag = SelectedTag;
      updateTag.Title = EditTag;

      this.dispatch({ TagUpdate: updateTag });
      
      this.setState({ editTagOpen: false, EditTag: '' });
      this.setState({ SelectedClient: { CompanyId: _.CompanyId, Name: _.Name } });
    };

    const handleAddClient = _ => {
      console.log(_);

    };

    const handleAddTag = _ => {
      console.log(_);
      handleOpen();

    };

    const handleAddNewTag = _ => {
      console.log(_);
      this.dispatch({ AddNewTag: { Tag: NewTag } });
      this.setState({ addTagOpen: false, NewTag: '' });
    };

    const handleEditTag = _ => {
      console.log(_);
      this.setState({ EditTag: SelectedTag.Title });
      handleOpenEdit();

    };

    const handleOpen = () => this.setState({ addTagOpen: true });

    const handleClose = () => this.setState({ addTagOpen: false });

    const handleOpenEdit = () => this.setState({ editTagOpen: true });

    const handleCloseEdit = () => this.setState({ editTagOpen: false });

    const hideNotification = _ => this.setState({ ShowNotification: false });

    const classes = useStyles();

    const {
      orgTagFilter,
    } = this.state;

    console.log(this.state)

    return (
      <div>
        {<MuiThemeProvider muiTheme={ThemeDefault}>
          <BasePage 
            title={"Org Tag"} 
            navigation={NavRoutes} 
            HandleNavLinkClick={handleNavLinkClick} 
          >
            <form>
            <List style={{ height: height-191, overflowY: 'scroll' }}>
             <ListItem key={"Li1"} id={"Li1"}>
              <div style={{ marginTop: 25 }}>
                <span>
                  <SelectField value={SelectedClient != null ? SelectedClient.CompanyId : 0} fullWidth={false} floatingLabelText="Select Client"
                    floatingLabelStyle={styles.selectLabel}>
                    {Clients.map(client => <MenuItem key={client.CompanyId} value={client.CompanyId} primaryText={client.Name}
                      onClick={(e) => handleClientUpdate({ CompanyId: client.CompanyId, Name: client.Name })} />)}
                  </SelectField>
                </span>
              </div>
              <div style={{ marginTop: 0 }}>
                <span>
                  <SelectField value={SelectedTag != null ? SelectedTag.OrgUnitId : 0} fullWidth={false} floatingLabelText="Select Tag"
                    floatingLabelStyle={styles.selectLabel}>
                    {Tags != null ? Tags.map(tag => <MenuItem key={tag != null ? tag.OrgUnitId : 0} value={tag != null ? tag.OrgUnitId : 0} primaryText={tag != null ? tag.Title : ''}
                      onClick={(e) => handleTagSelected({
                        CompanyId: tag.CompanyId,
                        CompanyName: tag.CompanyName,
                        Description: tag.Description,
                        OrgUnitId: tag.OrgUnitId,
                        Title: tag.Title
                      })} />) : ''}
                  </SelectField>
                  <span style={{ padding: 5 }}>
                    <FloatingActionButton onClick={handleAddTag}
                      disabled={SelectedClient == null}
                      backgroundColor={pink500} mini={true}>
                      <ContentAdd>
                      </ContentAdd>
                    </FloatingActionButton>
                  </span>
                  <span style={{ padding: 5 }}>
                    <FloatingActionButton
                      onClick={(e) => { if (window.confirm('Are you sure you wish to delete this tag?')) this.dispatch({ DeleteTag: SelectedTag }) }}
                      disabled={SelectedTag == null}
                      zDepth={0}
                      mini={true}
                      backgroundColor={grey500}
                      iconStyle={styles.removeIcon}
                    >
                      <IconRemove />
                    </FloatingActionButton>
                  </span>
                  <span style={{ padding: 5 }}>
                    <FloatingActionButton
                      onClick={handleEditTag}
                      disabled={SelectedTag == null}
                      zDepth={0}
                      mini={true}
                      backgroundColor={grey500}
                      iconStyle={styles.removeIcon}
                    >
                      <IconEdit />
                    </FloatingActionButton>
                  </span>
                </span>
              </div>
              </ListItem>
              <ListItem>
              <div style={{ marginTop: 10, float:'left' }}>
                <ExpansionPanel defaultExpanded>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}><h3 style={globalStyles.title}>Properties</h3></Typography>
                  </ExpansionPanelSummary>

                  {/* SEARCHBAR HERE */}
                  <OrgTagSearchBar 
                    retrieveOrgTagSearch={this.retrieveOrgTagSearch}
                    disabled={SelectedTag == null}
                  />                      

                  <ExpansionPanelDetails>
                    <div style={{ paddingTop: 0, paddingBottom: 20 }}>
                      <div>
                        <div style={styles.buttonContainer}>
                          <span style={{ padding: 5 }}> <RaisedButton label={"Tag Properties with: " + (SelectedTag != null ? SelectedTag.Title : '')}
                            onClick={(e) => { if (window.confirm('Are you sure you wish to tag properties?')) handleSetAllSelectedOrgTag(e) }}
                            disabled={SelectedTag == null || SearchText || orgTagFilter != 0}
                            style={styles.saveButton}
                            primary={true} />
                          </span>
                          <span style={{ padding: 5 }}> <RaisedButton disabled={SearchText || orgTagFilter != 0} label={"Untag Properties with: " + (SelectedTag != null ? SelectedTag.Title : '')}
                            onClick={(e) => { if (window.confirm('Are you sure you wish to remove tags from all properties?')) handleUnsetAllSelectedOrgTag(e) }}
                            disabled={SelectedTag == null || SearchText || orgTagFilter != 0}
                            style={styles.saveButton}
                            primary={true} />
                          </span>
                        
                      

                          {/* DROPDOWN HERE */}
                          <OrgTagFilterSelect 
                            retrieveOrgTagFilter={this.retrieveOrgTagFilter}
                            disabled={SelectedTag == null}
                          />


                        </div>
                      </div>
                      <div style={{ marginTop: 0 }}>
                        <Table>
                          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                            <TableRow>
                              <TableHeaderColumn style={styles.columns.BuildingNameTagged}>Tagged</TableHeaderColumn>
                              <TableHeaderColumn style={styles.columns.BuildingName}>Name</TableHeaderColumn>
                              <TableHeaderColumn style={styles.columns.BuildingNameAddress}>Address</TableHeaderColumn>
                              <TableHeaderColumn style={styles.columns.BuildingNameCity}>City</TableHeaderColumn>
                              <TableHeaderColumn style={styles.columns.BuildingNameCity}>State</TableHeaderColumn>
                              <TableHeaderColumn style={styles.columns.BuildingNameZip}>Zip</TableHeaderColumn>
                              <TableHeaderColumn style={styles.columns.BuildingNameTags}>Tags Associated</TableHeaderColumn>
                            </TableRow>
                          </TableHeader>
                          <TableBody displayRowCheckbox={false}>
                            { Properties ? Properties.map(item => {
                              const { Address1, Address2, City, Zip, StateName, CountryName, OrgUnitHashTags } = item;
                              const tagArray = OrgUnitHashTags ? OrgUnitHashTags.split('#') : 'N/A';
                              const tagsToRender = [];
                              if (tagArray != 'N/A') {
                                tagArray.map((tag) => {
                                  if (tag.length > 0) {
                                    tagsToRender.push(tag.trim());
                                  }
                                })
                              }
                              const address = `${Address1 ? Address1.trim() : ''} ${Address2 ? Address2.trim() : ''}  ${CountryName ? CountryName.trim() : ''}` 
                              const city = `${City ? City.trim() : ''}`;
                              const stateNm = `${StateName ? StateName.trim() : ''}`;
                              const zip = `${Zip ? Zip.trim() : ''}`;
                              
                              return (
                                <TableRow key={item.UserId}>
                                  <TableRowColumn style={styles.columns.BuildingNameTagged}>
                                    <Checkbox key={'cbx' + item.Id} checked={item.Selected}
                                      onClick={(e) => handleTaggedUpdate({ Property: item, Selected: e.target.checked })} />
                                  </TableRowColumn>
                                  <TableRowColumn style={styles.columns.BuildingName}>
                                    <div>{item.Name}</div>
                                  </TableRowColumn>
                                  <TableRowColumn style={styles.columns.BuildingNameAddress}>
                                    <div style={{ width: '15rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{address}</div>
                                  </TableRowColumn>
                                  <TableRowColumn style={styles.columns.BuildingNameCity}>
                                    <div style={{ width: '15rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{city}</div>
                                  </TableRowColumn>
                                  <TableRowColumn style={styles.columns.BuildingNameCity}>
                                    <div style={{ width: '15rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{stateNm}</div>
                                  </TableRowColumn>
                                  <TableRowColumn style={styles.columns.BuildingNameZip}>
                                    <div style={{ width: '15rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{zip}</div>
                                  </TableRowColumn>
                                  <TableRowColumn style={styles.columns.BuildingNameTags}>
                                    <div>{tagsToRender.map((tag) => ( <Chip key={'proptag' + tag} label={tag} /> ))}</div>
                                  </TableRowColumn>
                                </TableRow>
                              )
                              }) : null };
                          </TableBody>
                        </Table>
                      </div>
                      <div style={globalStyles.clear} />
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              </ListItem>
             <ListItem key={"Li44"} id={"Li4"}>
              <div style={{ marginTop: 10, float: 'right' }}>
                <ExpansionPanel defaultExpanded>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}><h3 style={globalStyles.title}>Users</h3></Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div style={{ paddingTop: 0, paddingBottom: 20 }}>
                      <div style={{ marginTop: 0 }}></div>
                      <div style={{ marginTop: 0 }}>
                        <Table>
                          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                            <TableRow>
                              <TableHeaderColumn style={styles.columns.Tagged}>Tagged</TableHeaderColumn>
                              <TableHeaderColumn style={styles.columns.UserName}>User</TableHeaderColumn>
                              <TableHeaderColumn style={styles.columns.Tags}>Tags Associated</TableHeaderColumn>
                            </TableRow>
                          </TableHeader>
                          <TableBody displayRowCheckbox={false}>
                            { TagUsers ? TagUsers.map(item => {
                              const { UserName, Email, OrgUnitHashTags } = item;
                              const tagArray = OrgUnitHashTags ? OrgUnitHashTags.split('#') : 'N/A';
                              const tagsToRender = [];
                              if (tagArray != 'N/A') {
                                tagArray.map((tag) => {
                                  if (tag.length > 0) {
                                    tagsToRender.push(tag.trim());
                                  }
                                })
                              }
                              return (
                                <TableRow key={"tagUsers" + item.UserID}>
                                  <TableRowColumn style={styles.columns.Tagged}>
                                    <Checkbox key={'cbx' + item.UserID} checked={item.Selected}
                                      onClick={(e) => handleTaggedUserUpdate({ User: item, Selected: e.target.checked })} />
                                  </TableRowColumn>
                                  <TableRowColumn style={styles.columns.UserName}>
                                    <div>{item.UserName}</div>
                                    <div style={{ width: '15rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.Email}</div>
                                  </TableRowColumn>
                                  <TableRowColumn style={styles.columns.Tags}>
                                    <div style={{display: 'inline-block'}}>{tagsToRender.map((tag) => ( <Chip key={'usertag' + tag} label={tag} /> ))}</div>
                                  </TableRowColumn>
                                </TableRow>
                              )
                              }) : null };
                          </TableBody>
                        </Table>
                      </div>
                      <div style={globalStyles.clear} />
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              </ListItem>
              </List>
              <Dialog open={addTagOpen} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">Close</Button>
                </DialogActions>
                <DialogTitle id="form-dialog-title">Create Tag for  {SelectedClient != null ? SelectedClient.Name : ''}</DialogTitle>
                <DialogContent>
                  <MuiThemeProvider muiTheme={ThemeDefault}>
                    <div>
                      <div>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          hintText="Enter Organization Tag"
                          floatingLabelText="New Tag"
                          value={NewTag}
                          onChange={event => this.setState({ NewTag: event.target.value, newtagdirty: true })}
                          fullWidth={true}
                        />
                      </div><div>
                        <RaisedButton label="Add Tag"
                          onClick={handleAddNewTag}
                          disabled={SelectedClient == null && !newtagdirty}
                          style={styles.addButton}
                          primary={true} />
                      </div>
                    </div>
                  </MuiThemeProvider>
                </DialogContent>
              </Dialog>
              <Dialog open={editTagOpen} onClose={handleCloseEdit} aria-labelledby="form-dialog-title" fullWidth={true}>
                <DialogActions>
                  <Button onClick={handleCloseEdit} color="primary">Close</Button>
                </DialogActions>
                <DialogTitle id="form-dialog-title">Edit Tag {SelectedTag != null ? SelectedTag.Title : ''}</DialogTitle>
                <DialogContent>
                  <MuiThemeProvider muiTheme={ThemeDefault}>
                    <div>
                      <div>
                         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                          hintText="Organization Tag"
                          floatingLabelText="Edit Tag"
                          value={EditTag}
                          onChange={onChangeEditTag}
                          fullWidth={true}
                        />
                      </div><div>
                        <RaisedButton label="Save"
                          onClick={handleUpdateTag}
                          disabled={SelectedTag == null && !edittagdirty}
                          style={styles.addButton}
                          primary={true} />
                      </div>
                    </div>
                  </MuiThemeProvider>
                </DialogContent>
              </Dialog>
              <Snackbar open={ShowNotification} message={NotificationMessage} autoHideDuration={1000} onRequestClose={hideNotification} />
            </form>
          </BasePage>
        </MuiThemeProvider>}
      </div>
    )
  }
}

export default OrgTagsPage;

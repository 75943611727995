import React from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Paper,
} from '@material-ui/core';
import SiteFilterTagsStyle from '../../styles/SiteFilterStyles/SiteFilterTagsStyle';

export default function SimpleTable(props) {
  const classes = SiteFilterTagsStyle();
  const { portalUserOrgUnits } = props;

    const handleTagSelected = tag => event => {
      tag.FilterSelected = event.target.checked;
      props.tagSelect(tag);
  };
    
  return (
    <Paper className={classes.root}>      
      <div style={{ height: "100%", overflow: "auto" }} >
        <Table className={classes.table}>
          <TableBody>
            { portalUserOrgUnits.map((unit) => {
              const organizationalTag = unit.Title;
              const isSelected = unit.FilterSelected;
              const orgId = unit.OrgUnitId;
              return (
                <TableRow key={orgId}>
                 <TableCell>
                  <Checkbox 
                    key={orgId + 'cbx'}
                    checked={ isSelected }
                    value={ unit }
                    onChange={ handleTagSelected(unit) }
                  />
                    { organizationalTag }
                  </TableCell>
                </TableRow>
              )}) }
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}

import React from 'react';
import dotnetify from 'dotnetify';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import Snackbar from 'material-ui/Snackbar';
import TextField from 'material-ui/TextField';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, TableCell } from 'material-ui/Table';
import IconRemove from 'material-ui/svg-icons/content/clear';
import IconEdit from 'material-ui/svg-icons/content/create';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { pink500, grey200, grey500, grey400, pink400 } from 'material-ui/styles/colors';
import BasePage from '../components/BasePage';
import Pagination from '../components/table/Pagination';
import InlineEdit from '../components/table/InlineEdit';
import InlineEditSpan from '../components/table/InlineEditSpan';
import ThemeDefault from '../styles/theme-default';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from "material-ui/Checkbox";

class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = dotnetify.react.connect('User', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        console.log(ex);
          auth.signOut();
      }
    });
    
      this.dispatch = state => this.vm.$dispatch(state);
      this.routeTo = route => this.vm.$routeTo(route)

    this.state = {
      addName: '',
      Users: [],
      StatesRegions: [],
      Pages: [],
      ShowNotification: false,
      NavRoutes: []
    };
  }

  componentWillUnmount() {
    this.vm.$destroy();
  }

  render() {
    let { addName, Users, StatesRegions, Pages, SelectedPage, ShowNotification, NavRoutes } = this.state;
      let UserId = 0;
    const styles = {
      addButton: { margin: '1em' },
      removeIcon: { fill: grey500 },
      editIcon: { fill: grey500 },
      columns: {
        Active: { width: '5%', paddingRight: 5, paddingLeft: 5},
        FirstName: { width: '8%', paddingRight: 5, paddingLeft: 5},
        LastName: { width: '7%', paddingRight: 5, paddingLeft: 5},
        Email: { width: '12%', paddingRight: 5, paddingLeft: 5},
        Add1: { width: '15%', paddingRight: 5, paddingLeft: 5 },
        Add2: { width: '10%', paddingRight: 5, paddingLeft: 5 },
        AddCity: { width: '10%', paddingRight: 5, paddingLeft: 5 },
        AddState: { width: '5%', paddingRight: 5, paddingLeft: 5 },
        AddCountry: { width: '5%', paddingRight: 5, paddingLeft: 5 },
        AddZip: { width: '5%', paddingRight: 5, paddingLeft: 5 },
        remove: { width: '6%', paddingRight: 5, paddingLeft: 5 },
        edit: { width: '6%', paddingRight: 5, paddingLeft: 5 }
      },
      pagination: { marginTop: '1em' },
      selectLabel: { color: pink400 },
      toggleDiv: {
        maxWidth: 300,
        marginTop: 40,
        marginBottom: 5
      },
      toggleLabel: {
        color: grey400,
        fontWeight: 100
      },
    };

    const handleAdd = _ => {
      if (addName) {
        this.dispatch({ Add: addName });
        this.setState({ addName: '' });
      }
    };

    const handleUpdate = zeeuser => {
      let newState = Users.map(item => (item.UserId === zeeuser.UserId ? Object.assign(item, zeeuser) : item));
      this.setState({ Users: newState });
      this.dispatch({ Update: zeeuser });
    };


      const handleActiveUpdate = zeeuser => {
      let newState = Users.map(item => (item.UserId === zeeuser.UserId ? Object.assign(item, zeeuser) : item));
      this.setState({ Users: newState });
      this.dispatch({ UpdateActive: zeeuser });
    };

    const handleSelectPage = page => {
      const newState = { SelectedPage: page };
      this.setState(newState);
      this.dispatch(newState);
    };

      const handleSelectFieldChange = (event, idx, value) => {
          console.log(value);
          this.routeTo(Users.find(i => i.UserId == value).Route);
          }

      const handleEditItemClick = user => {
          console.log(user);
          console.log(Users.find(i => i.UserId == user.UserId));
          this.routeTo(Users.find(i => i.UserId == user.UserId).Route);
        };

    const handleCancel = _ => {
      this.dispatch({ Cancel: UserId });
      this.setState({ dirty: false });
    };

    const handleSave = _ => {
      this.dispatch({ Save: { UserId: UserId, FirstName: FirstName, LastName: LastName } });
      this.setState({ dirty: false });
    };

      const handleNavLinkClick = obj => {
      obj.e.preventDefault();
        console.log(obj.item);
        this.routeTo(obj.item.Route);
    };

    const hideNotification = _ => this.setState({ ShowNotification: false });

     


    return (
      <MuiThemeProvider muiTheme={ThemeDefault}>
        <BasePage id="what" title="Manage Users" navigation={NavRoutes}  HandleNavLinkClick={handleNavLinkClick}>
          <div>
            <div>
              <FloatingActionButton onClick={handleAdd} style={styles.addButton} backgroundColor={pink500} mini={true}>
                <ContentAdd />
                            </FloatingActionButton>
               <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                id="AddName"
                floatingLabelText="Add"
                hintText="Type user name here"
                floatingLabelFixed={true}
                value={addName}
                onKeyPress={event => (event.key === 'Enter' ? handleAdd() : null)}
                onChange={event => this.setState({ addName: event.target.value })}
              />
            </div>

            <Table>
              <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                  <TableRow>
                  <TableHeaderColumn style={styles.columns.Active}>Active</TableHeaderColumn>
                  <TableHeaderColumn style={styles.columns.FirstName}>First Name</TableHeaderColumn>
                  <TableHeaderColumn style={styles.columns.LastName}>Last Name</TableHeaderColumn>
                  <TableHeaderColumn style={styles.columns.Email}>Email</TableHeaderColumn>
                  <TableHeaderColumn style={styles.columns.Add1}>Address</TableHeaderColumn>
                  <TableHeaderColumn style={styles.columns.edit}>Edit</TableHeaderColumn>
                  <TableHeaderColumn style={styles.columns.remove}>Remove</TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody displayRowCheckbox={false}>
                {Users.map(item => (
                    <TableRow key={item.UserId}>
                        <TableRowColumn style={styles.columns.Active}>
                            <input type="checkbox"
                                checked={item.Active===true}
                                            onClick={(e) => handleActiveUpdate({ UserId: item.UserId, Active: e.target.checked})}/>
                    </TableRowColumn>
                    <TableRowColumn style={styles.columns.FirstName}>
                      <InlineEdit onChange={value => handleUpdate({ UserId: item.UserId, FirstName: value })}>{item.FirstName}</InlineEdit>
                    </TableRowColumn>
                    <TableRowColumn style={styles.columns.LastName}>
                      <InlineEdit onChange={value => handleUpdate({ UserId: item.UserId, LastName: value })}>{item.LastName}</InlineEdit>
                    </TableRowColumn>
                    <TableRowColumn style={styles.columns.Email}>
                      <InlineEdit onChange={value => handleUpdate({ UserId: item.UserId, Email: value })}>{item.Email}</InlineEdit>
                    </TableRowColumn>
                    <TableRowColumn style={styles.columns.Add1}>
                                        <InlineEdit onChange={value => handleUpdate({ UserId: item.UserId, Add1: value })}>{item.Add1}</InlineEdit>
                                        <InlineEdit onChange={value => handleUpdate({ UserId: item.UserId, Add2: value })}>{item.Add2}</InlineEdit>
                                        <InlineEditSpan onChange={value => handleUpdate({ UserId: item.UserId, City: value })}>{item.City},</InlineEditSpan>
                                        <InlineEditSpan onChange={value => handleUpdate({ UserId: item.UserId, Country: value })}>{item.Country}</InlineEditSpan>
                                        <InlineEditSpan onChange={value => handleUpdate({ UserId: item.UserId, State: value })}>{item.State}</InlineEditSpan>
                                        <InlineEditSpan on onChange={value => handleUpdate({ UserId: item.UserId, Zip: value })}>{item.Zip}</InlineEditSpan>
                    </TableRowColumn>
                    <TableRowColumn style={styles.columns.edit}>
                      <FloatingActionButton
                        onClick={value => handleEditItemClick({UserId: item.UserId})}
                        zDepth={0}
                        mini={true}
                        backgroundColor={grey200}
                        iconStyle={styles.editIcon}
                      >
                        <IconEdit />
                      </FloatingActionButton>
                    </TableRowColumn>
                    <TableRowColumn style={styles.columns.remove}>
                      <FloatingActionButton
                        onClick={_ => this.dispatch({ Remove: item.UserId })}
                        zDepth={0}
                        mini={true}
                        backgroundColor={grey200}
                        iconStyle={styles.removeIcon}
                      >
                        <IconRemove />
                      </FloatingActionButton>
                    </TableRowColumn>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

                    <Pagination style={styles.pagination} pages={Pages} select={SelectedPage} onSelect={handleSelectPage} />

                     <FloatingActionButton onClick={handleAdd} style={styles.addButton} backgroundColor={pink500} mini={true}>
                            <ContentAdd />
                            </FloatingActionButton>

            <Snackbar open={ShowNotification} message="Changes saved" autoHideDuration={1000} onRequestClose={hideNotification} />
          </div>
        </BasePage>
      </MuiThemeProvider>
    );
  }
}

export default UserPage;

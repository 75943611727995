import React, { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';

export default function EditContainsWarranty(props) {
  const { streetAddress, stateAddress } = props;

  return (
    <Button disabled variant='contained' style={{ margin: '2%', backgroundColor: '#66BFFA', color: 'black', width: '96%', whiteSpace: 'nowrap', overflow: 'hidden' }}> 
      <Grid container direction='row' alignItems='center' style={{ height: '100%' }}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography noWrap>{streetAddress}</Typography>
          <Typography noWrap>{stateAddress}</Typography>
        </Grid>
      </Grid>
    </Button>
  )
} 
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '95%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddSubmittedBySelect(props) {
  const classes = useStyles();
  const [ submitterId, setSubmitterId ] = useState(null);

  const { 
    retrieveSubmitterValue, 
    category3Finished, 
    bfAdminUsers,
  } = props;

  const handleChange = (e) => {
    const id = e.target.value;
    setSubmitterId(id);
  };

  useEffect(() => {
    if (submitterId) {
      const submitter = bfAdminUsers.find(bfAdminUser => bfAdminUser.UserID == submitterId);
      retrieveSubmitterValue(submitter);
    } else {
      retrieveSubmitterValue(null);
    }
  }, [submitterId]);

  return (
    <div>
      <FormControl disabled={!category3Finished} variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-submitter-native-simple">Submitted By</InputLabel>
        <Select
          native
          value={submitterId}
          onChange={handleChange}
          inputprops={{
            submitterId: 'submitterId',
            id: 'filled-submitter-native-simple',
          }}
        >
          <option value="" />
          { bfAdminUsers.map((bfAdminUser, i) => {
            const name = bfAdminUser.UserName;
            const value = bfAdminUser.UserID;
            return (
              <option value={value}>{ name }</option>
            )
          }) }
        </Select>
      </FormControl>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { DropzoneArea } from 'material-ui-dropzone'
import { debounce } from 'lodash';

export default function DropZoneArea(props) {
  const [existingFiles, setExistingFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [numberOfFilesSelected, setNumberOfFilesSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    handleDocumentChange,
    leakResponse,
    setListLoading,
  } = props;

  const handleChange = (files) => {
    setLoading(true);
    handleFileSelected(files);
  };
  
  const handleFileSelected = debounce(files => {
    if (existingFiles.length) {
      const newFiles = [];
      files.map(file => {
        const existingFileIndex = existingFiles.findIndex(existingFile => existingFile.FileName == file.name);
        if (existingFileIndex === -1) {
          newFiles.push(file);
        }
      })
      setSelectedFiles(newFiles);
    }
    if (!existingFiles.length) {
      setSelectedFiles(files);
    }
  }, 1000);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      handleDocumentChange(selectedFiles);
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (leakResponse) {
      const {
        Files,
      } = leakResponse;
      if (Files) {
        setExistingFiles(Files);
        setSelectedFiles([]);
      }
    }
  }, [leakResponse]);

  const previousExistingFiles = usePrevious(existingFiles);
  const previousSelectedFiles = usePrevious(selectedFiles);
  useEffect(() => {
    if (existingFiles && previousExistingFiles) {
      if (existingFiles.length - previousExistingFiles.length == previousSelectedFiles.length) {
        setLoading(false);
      }
    }
  }, [existingFiles]);

  const previousLoading = usePrevious(loading);
  useEffect(() => {
    if (loading != previousLoading) {
      setListLoading(loading);
    }
  }, [loading]);
  
  return (
    <div id='dropzone-area-wrapper'>
      <DropzoneArea
        key={selectedFiles.length+1}
        style={{ padding: '5%' }}
        maxFileSize={20000000}
        filesLimit={12}
        showPreviewsInDropzone={false}
        showAlerts={['error', 'info']}
        onChange={handleChange}
        fileObjects={existingFiles}
        dropzoneText={'Drag And Drop File Or Click'}
        showPreviews={false}
        showFileNamesInPreview={false}
      />
    </div>
  );
};
import React, { useState, useEffect, useRef } from 'react';
import FolderNameTextField from './FolderNameTextField';
import FolderTypeSelect from './FolderTypeSelect';
import BuildingFolderForm from './BuildingFolderForm/BuildingFolderForm';
import {
  Paper,
  Grid,
  Modal,
  Typography,
  Avatar,
  IconButton,
  Button,
  Collapse,
  // LinearProgress,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

export default function ManageFoldersForm(props) {
  const {
    handleUpdateAddFolderName,
    handleAddFolder,
    handleAddFolderTypeSelect,
    handleAddFolderUnselected,
      Countries,
    States,
    handleUpdateBuildingName,
    handleUpdateBuildingAddress,
    handleUpdateBuildingAddressCont,
    handleUpdateBuildingCity,
    handleUpdateBuildingCountry,
    handleUpdateBuildingState,
    handleUpdateBuildingPostalCode,
    handleAddBuildingFolder,
    
    addSelected,
    selectedAddFolder,
    addFolderType,
    addFolderName,
    readyAddBuildingFolder,
  } = props;

    console.log(props);

  return (
    <Modal open={addSelected}>
      <Grid container direction='row' alignItems='flex-start' justify='center' style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', overflow: 'auto' }}>
        <Grid item xs={4} style={{ height: '100%', textAlign: 'center' }}>
          <Paper>
            <Grid container direction='row' style={{ height: '100%', display: 'table' }}>
              <Grid item xs={12} style={{ display: 'table-header-group', top: '0', left: '0', zIndex: '1' }}>
                <Grid container direction='row' alignItems='center' justify='space-between' style={{ backgroundColor: '#66BFFA', textAlign: 'center' }}>
                  <Grid item xs={1} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    <Avatar style={{ backgroundColor: 'gainsboro', margin: '2%' }}>
                      <InsertDriveFileIcon />
                    </Avatar>
                    <Typography variant='h6' style={{ margin: '4%' }}>Add Folder To {selectedAddFolder.Name}</Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right', color: 'white', display: 'contents' }}>
                    <IconButton onClick={ () => handleAddFolderUnselected() }>
                      <HighlightOffIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ display: 'table-cell' }}>
                <Grid container direction='row' justify='center' alignItems='center'>
                  <Grid item xs={12}>
                    <FolderTypeSelect
                      handleAddFolderTypeSelect={handleAddFolderTypeSelect}
                    />
                    <Collapse in={addFolderType} timeout='auto'>
                    { addFolderType == 1 ?
                      <FolderNameTextField
                        handleUpdateAddFolderName={handleUpdateAddFolderName}
                        addFolderType={addFolderType}
                      /> 
                      :
                      addFolderType == 2 ?
                      <BuildingFolderForm
                        handleUpdateAddFolderName={handleUpdateAddFolderName}
                        addFolderType={addFolderType}
                                                      Countries={Countries}
                                                      States={States}
                        handleUpdateBuildingName={handleUpdateBuildingName}
                        handleUpdateBuildingAddress={handleUpdateBuildingAddress}
                        handleUpdateBuildingAddressCont={handleUpdateBuildingAddressCont}
                        handleUpdateBuildingCity={handleUpdateBuildingCity}
                        handleUpdateBuildingCountry={handleUpdateBuildingCountry}
                        handleUpdateBuildingState={handleUpdateBuildingState}
                        handleUpdateBuildingPostalCode={handleUpdateBuildingPostalCode}
                      />   
                      :
                    null }
                    </Collapse>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ display: 'table-footer-group' }}>
                <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA' }}>
                  <Grid item xs={3} style={{ textAlign: 'center', padding: '4px' }}>
                    { addFolderType == 1 ?
                      <Button disabled={ !addFolderName} style={{ backgroundColor: 'gainsboro' }} onClick={() => handleAddFolder()}>Add</Button>
                      : addFolderType == 2 ?
                      <Button disabled={ !readyAddBuildingFolder } style={{ backgroundColor: 'gainsboro' }} onClick={() => handleAddBuildingFolder()}>Add</Button>
                    : null }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Modal>
    
  );
}

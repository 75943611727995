import React, { Component } from 'react';
import dotnetify, { RouteLink } from 'dotnetify';
import ApplicationSearchNoResults from './ApplicationSearchNoResults';
import ApplicationSearchItem from './ApplicationSearchItem';
import ApplicationSearchPagination from './ApplicationSearchPagination';
import ApplicationSearchLoading from './ApplicationSearchLoading';
import ApplicationSearchFilters from './ApplicationSearchFilters';
import auth from '../../../auth';
import {
  InputBase,
  Paper,
  List,
} from '@material-ui/core';

const masterPaperStyle = { 
  width: '22.75rem', 
  position: 'absolute', 
  marginTop: '1%' ,
  fontFamily: 'Ebrima', 
};

const listPaperStyle = { 
  overflowY: 'auto', 
  maxHeight: '30rem', 
  boxShadow: 'none',
  fontFamily: 'Ebrima', 
};

const listStyle = { 
  display: 'contents',
  fontFamily: 'Ebrima', 
};

class ApplicationSearch extends Component {
  constructor(props) {
    super(props);

    this.vm = dotnetify.react.connect('ApplicationSearch', this, {
       headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        //TODO: BUG! auto signing out
        auth.signOut();
      }
    });
    this.routeTo = route => this.vm.$routeTo(route);
    this.dispatch = state => this.vm.$dispatch(state);
    this.inputBase = React.createRef();

    this.state={
      searchActive: false,
      loading: true,
      Results: [],
      Pages: [],
      ShowNotification: false,
      NotificationMessage: "",
      SelectedPage: 0,
      searchText: "",
      NavRoutes: [],
      SelectedTab: 0,
    };
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleBlur, false);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { 
      SelectedTab,
      searchActive, 
      Results, 
      loading,
    } = this.state;
    const previouslyActiveSearch = prevState.searchActive;
    const previousResults = prevState.Results;
    const previousSelectedTab = prevState.SelectedTab;
    const previousLoading = prevState.loading;

    //navigation
    if (SelectedTab || previousSelectedTab) {
      if (SelectedTab != previousSelectedTab) {
        this.setState({
          loading: true,
        })
      }
    }

    if (loading) {
      if (!previousLoading) {
        if (Results != previousResults) {
          this.setState({
            loading: false,
          })
        }
      }
    }

    // !!! space must be given to dispatch (rather than empty string) 
    // in order to trigger Results population
    if (searchActive) {
      if (!previouslyActiveSearch) {
        this.dispatchSearch(' ');
      }

      if (Results != previousResults) {
        if (!Results) {
          this.setState({
            loading: true,
          })
        }
        if (Results) {
          if (Results.length > 0) {
            this.setState({
              loading: false,
            })
          } 
        }
      }
    }

    // TODO: shutting search off requires no space?
    if (previouslyActiveSearch) {
      if (!searchActive) {
        this.setState({
          searchText: '',
        }, this.dispatchSearch(''))
      }
    }
  };

  searchSelected = () => {
    this.setState({
      searchActive: true,
    });
  };

  searchDeselected = () => {
    this.setState({
      searchActive: false,
    });
  };

  handleSearch = () => {
    const { searchText } = this.state;
    if (searchText) {
      this.dispatchSearch(searchText);
    } else {
      this.dispatchSearch('');
    }
  };

  dispatchSearch = (searchText) => {
    const dispatchObj = {
      SearchText: searchText,
    }
    this.dispatch(dispatchObj);
  };

  dispatchPaginationSelect = (SelectedPage) => {
    this.setState({
      SelectedPage,
    }, this.dispatch({ SelectedPage }));
  };

  handleBlur = (e) => {
    if (this.node.contains(e.target)) {
      return
    }else{
      this.searchDeselected();
    }
  }; 

  handleSearchTypeSelect = (selectedTab) => {
    this.setState({
      SelectedTab: selectedTab
    }, this.dispatch({ SelectTab: selectedTab }));
  };

  handleSelectRoute = (selectedRoute, title) => {
    const { selectRoute } = this.props;
    this.setState({
      selectedRoute,
    }, 
      selectRoute(selectedRoute, title),
      this.searchDeselected());
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleBlur, false);
    this.vm.$destroy();
  };


  render(){
    const { 
      style,
     } = this.props;
    
    const { 
      inputRoot, 
      inputInput 
    } = style;

    const {
      searchActive,
      searchText,
      loading,
      Results, 
      Pages, 
      SelectedTab,
    } = this.state;
    
    return(
      <div ref={ node => this.node = node }>
        <InputBase 
          onClick={ this.searchSelected } 
          placeholder={ searchText ? searchText : 'Search...'} 
          classes={{ root: inputRoot,  input: inputInput }} 
          value={ searchText }
          onChange={event => this.setState({ searchText: event.target.value })}
          onKeyUp={this.handleSearch}
          style={{fontFamily: 'Ebrima', }}
        />       
        { searchActive ? 
          <Paper style={ masterPaperStyle }>       
            <ApplicationSearchFilters 
              handleSearchTypeSelect={this.handleSearchTypeSelect}
            /> 
            { loading ? <ApplicationSearchLoading /> : 
              <Paper style={listPaperStyle}>
                <List style={listStyle}>
                  { Results ? Results.length > 0 ? Results.map(searchResult =>
                    <ApplicationSearchItem
                      selectedTab={SelectedTab}
                      searchResult={searchResult}
                      handleSelectRoute={this.handleSelectRoute}
                    />)
                    : <ApplicationSearchNoResults />
                  : null }
                </List>
              </Paper>
            }   
            <List>
              { Pages ?
                <ApplicationSearchPagination
                  dispatchPaginationSelect={this.dispatchPaginationSelect}
                  searchSelected={this.searchSelected}
                  pages={Pages}
                />
                : null }
            </List>
          </Paper>
        : null }
      </div>
    )
  }
};

export default ApplicationSearch


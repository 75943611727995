import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  TextField,
  FormControl,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
    fontFamily:'Ebrima',
  }
}));

//!IMPORTANT!

//THIS COMPONENT USES A STRING INSTEAD OF AN ID, 
//IN ORDER TO RETRIEVE THE CORRECT VALUE, 2 USE EFFECTS
//ARE USED (ONE FOR THE VALUE IN THE TEXTFIELD, AND 
//ONE FOR THE OBJECT PASSED TO THE PARENT)

export default function DocumentPropertySelect(props) {
  const [value, setValue] = useState('');
  const [selectedProperty, setSelectedProperty] = useState({});
  const {
    handlePropertySelect,
    properties,
    document,
  } = props;

  //handles string
  const handleChange = (value) => {
    setValue(value);
  };
  
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  //detects selection of string, and finds corresponding property, trigers next useEffect
  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value != previousValue) {
      if (value) {
        const selection = properties.find(property => property.Name == value);
        setSelectedProperty(selection);
      }
    }
  }, [value]);

  //passes selection of property to parent
  const previousSelectedProperty = usePrevious(selectedProperty);
  useEffect(() => {
    if (selectedProperty != previousSelectedProperty) {  
      if (selectedProperty) {
        handlePropertySelect(selectedProperty);
      } else {
        handlePropertySelect({});
      }     
    }
  }, [selectedProperty]);

  //editing a doc, loads doc and kicks off useEffect process
  useEffect(() => {
    if (document) {
      const { Id, BuildingName } = document;
      if (Id) {
        setValue(BuildingName);
      } else {
        setValue('');
      }
    }
  }, [document]);
  
  const classes = useStyles();
  return (
    <Grid container direction='row' justify='center' alignItems='center'>
      <Grid xs={12}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            style={{ marginTop: 'none', fontFamily:'Ebrima',}}
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            value={value}
            options={properties.map((property) => property.Name)}
            onChange={(e, value) => handleChange(value)}
            renderOption={(option) => (
                <span style={{fontFamily:'Ebrima',}}>
                {option}
                </span>
            )}
            renderInput={(params) => {
              return (
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}} 
                  {...params}
                  label={<span style={{fontFamily:'Ebrima',}}>{"Select Property"}</span>}
                  // margin="normal"  
                  inputprops={{ ...params.inputprops, style: { fontSize: `50 !important` }, type: 'text', className:classes.formControl }}
                />
              )
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};


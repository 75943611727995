// import React, { useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';

// const useStyles = makeStyles(theme => ({
//   container: {
//     width: '100%',
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   textField: {
//     margin: theme.spacing(1),
//     width: '95%',
//   },
// }));



// export default function TimePickers(props) {
//   const classes = useStyles();
//   const { category3Finished, retrieveTimeEnd } = props;
//   var today = new Date();
//   var time = today.getHours() + ":" + today.getMinutes();

//   const handleChange = (e) => {
//     const time = e.target.value;
//     retrieveTimeEnd(time);
//   };

//   useEffect(() => {
//     retrieveTimeEnd(time);
//   }, []);

//   return (
//     <form className={classes.container} noValidate>
//        <TextField
//            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
//            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
//         onChange={handleChange}
        // disabled={!category3Finished}
//         id="time"
//         label="Estimated End"
//         type="time"
//         defaultValue={time}
//         className={classes.textField}
//         InputLabelProps={{
//           shrink: true,
//         }}
//         inputprops={{
//           step: 300, // 5 min
//         }}
//       />
//     </form>
//   );
// }
import 'date-fns';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import ScheduleIcon from '@material-ui/icons/Schedule';
var moment = require('moment');

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1),
    width: '95%',
  },
}));

export default function AddTimeSelect(props) {
  const [selectedTime, setSelectedTime] = useState(null);
  const { category3Finished, retrieveTimeEnd } = props;
  const currentTime = moment();
  const classes = useStyles();

  const handleTimeChange = time => {
    setSelectedTime(time);
  };

  useEffect(() => {
    setSelectedTime(currentTime);
  }, []);

  useEffect(() => {
    retrieveTimeEnd(selectedTime);
  }, [selectedTime])

  useEffect(() => {
    let element = document.getElementsByClassName('MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal');
    element[1].style.marginTop = '7px';
    element[1].style.marginLeft = '2%';
    element[1].style.width = '96%';
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} inputprops={{ classes: { root: classes.container } }}>
      <Grid container justify="space-around" style={{backgroundColor: 'white', width: '95%', marginLeft: '3%'}}>
        <KeyboardTimePicker
          disabled={!category3Finished}
          margin="normal"
          id="time-picker"
          label="Est. End Time"
          value={selectedTime}
          onChange={handleTimeChange}
          keyboardIcon={<ScheduleIcon />}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import LeakResponseDocumentCard from './SubComponents/LeakResponseDocumentCard';
import LeakResponseExistingDocumentCard from './SubComponents/LeakResponseExistingDocumentCard'
import LeakResponseDropZone from './LeakResponseDropZone';
import LeakResponseDocumentsLoading from './SubComponents/LeakResponseDocumentsLoading';
import {
  Grid,
  Typography,
  Card,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  Switch,
  Button,
} from '@material-ui/core';
import { debounce } from 'lodash';

const COMPONENT_LEAK_RESPONSE_STEP = 3;

function LeakResponseDocuments(props) {
  const [documents, setDocuments] = useState([]);
  const [removedDocument, setRemovedDocument] = useState({});
  const [backSelected, setBackSelected] = useState(false);
  const [nextSelected, setNextSelected] = useState(false);
  const [existingPreviews, setExistingPreviews] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const {
    handleUpdateDocuments,
    handleRemovedDocument,
    handleIncrementWizardStep,

    leakResponse,
    selectedDocuments,
  } = props;

  const handleNextClick = () => {
    setNextSelected(true);
  };

  const handleBackClick = () => {
    setBackSelected(true);
  };

  const handleDocumentChange = debounce(documents => {
    setDocuments(documents);
  }, 1000);

  const handleRemoveDocument = (document) => {
    setRemovedDocument(document);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousRemovedDocument = usePrevious(removedDocument);
  useEffect(() => {
    if (removedDocument != previousRemovedDocument) {
      handleRemovedDocument(removedDocument);
    }
  }, [removedDocument]);

  useEffect(() => {
    if (nextSelected) {
      const nextStep = COMPONENT_LEAK_RESPONSE_STEP + 1;
      handleIncrementWizardStep(nextStep);
    }
  }, [nextSelected]);

  useEffect(() => {
    if (backSelected) {
      const backStep = COMPONENT_LEAK_RESPONSE_STEP - 1;
      handleIncrementWizardStep(backStep);
    }
  }, [backSelected]);

  const previousDocuments = usePrevious(documents);
  useEffect(() => {
    if (documents && previousDocuments) {
      if (documents != previousDocuments) {
        if (JSON.stringify(documents) != JSON.stringify(selectedDocuments)) {
          handleUpdateDocuments(documents);
        }
      }
    }
  }, [documents]);

  useEffect(() => {
    if (leakResponse) {
      const leakResponseExists = Object.values(leakResponse).length;
      if (leakResponseExists) {
        const {
          Files,
        } = leakResponse;
        if (Files) {
          setExistingPreviews(Files);
        }
      }
    }
  }, [leakResponse]);
  
  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%', width: '100%' }}>
      <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
        <Card square style={{ boxShadow: 'none' }}>
          <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>

            <Grid container direction='row' alignItems='center' justify='center' style={{ display: 'flex', height: '95%' }}>
              <Grid item xs={12} style={{ height: '100%' }}>
                
                <Paper square style={{ height: '100%', width: '100%', backgroundColor: 'gainsboro', boxShadow: 'none' }}>
                  
                  <Grid container direction='row' justify='flex-start' align='flex-start' style={{ height: '100%' }}>
                    <Grid item xs={12} style={{ height: '100%' }}>
                      { listLoading ? <LeakResponseDocumentsLoading /> : null }
                      <Grid container direction='row' justify='flex-start' align='flex-start' style={{ height: '100%', overflow: 'auto' }}>
                        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} style={{ height: '49%', padding: '1%' }}>
                          <LeakResponseDropZone
                            handleDocumentChange={handleDocumentChange}

                            leakResponse={leakResponse}
                            setListLoading={setListLoading}
                          />
                        </Grid>

                        { existingPreviews.map((existingPreview) => {
                          return (
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} style={{ height: '17rem', padding: '1%' }}>
                              <LeakResponseExistingDocumentCard
                                handleRemoveDocument={handleRemoveDocument}

                                existingPreview={existingPreview}
                              />
                            </Grid>
                          )
                        })}
                          
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
               
              </Grid>
            </Grid>
            
            <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA', position: 'fixed', width: '97.5%', bottom: '1%', left: '1.5%' }}>
              <Grid item xs={12} style={{ width: '100%' }}>
                <Grid container direction='row' alignItems='center' justify='space-evenly'>
                  <Grid item xs={3} style={{ textAlign: 'center', padding: '5px' }}>
                    <Button variant='contained' color='default' style={{ boxShadow: 'none' }} onClick={()=>handleBackClick()}>Back</Button>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: 'center' }}>
                    <Button variant='contained' color='default' onClick={() => handleNextClick()} style={{ boxShadow: 'none' }}>Next</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Card>
      </Grid>
    </Grid>
  )
};

export default LeakResponseDocuments
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Paper,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '96%',
    left: '.5%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect(props) {
  const [ disabled, setDisabled ] = useState(true);
  const [ propertyOptions, setPropertyOptions ] = useState([]);
  const [ selectedBuilding, setSelectedBuilding] = useState(0);

  const { 
    handleUpdateProperty,
    
    leakResponse,
    originalLeakResponseId,
    editedPropertyCopy,
    lastSelectedProperty,
    selectedTag,
    propertyList, 
  } = props;

  const handleChange = (e) => {
    const buildingId = e.target.value;
    setSelectedBuilding(buildingId);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if (propertyList.length) {
      setPropertyOptions(propertyList);
    }
  }, [propertyList]);

  const previousSelectedBuilding = usePrevious(selectedBuilding);
  useEffect(() => {
    if (selectedBuilding && propertyOptions.length) {
      const selectedBuildingObject = propertyOptions.find(property => property.Id == selectedBuilding);
      handleUpdateProperty(selectedBuildingObject);
    }

    //if initializing
    if (!previousSelectedBuilding) {
      if (selectedBuilding && propertyOptions.length) {
        const selectedBuildingObject = propertyOptions.find(property => property.Id == selectedBuilding);
        handleUpdateProperty(selectedBuildingObject);
      }
    }

    //if initialized
    if (previousSelectedBuilding) {
      if (selectedBuilding && propertyOptions.length) {
        const selectedBuildingObject = propertyOptions.find(property => property.Id == selectedBuilding);
        handleUpdateProperty(selectedBuildingObject);      
      }

      // user set building to null
      if (!selectedBuilding) {
        if (selectedBuilding != previousSelectedBuilding) {
          handleUpdateProperty(null);
        }
      }
    }
  }, [selectedBuilding]);

  const previousSelectedTag = usePrevious(selectedTag);
  useEffect(() => {
    if (selectedTag) {
      if (Object.values(selectedTag).length) {
        setDisabled(false);
        if (selectedTag != previousSelectedTag) {
          setSelectedBuilding(0);
        }
      } else {
        setDisabled(true);
      }
    } else {
      if (previousSelectedTag) {
        if (selectedBuilding) {
          setSelectedBuilding(0);
        }
      }
      setDisabled(true);
    }
  }, [selectedTag]);

  useEffect(() => {
    if (lastSelectedProperty) {
      if (Object.values(lastSelectedProperty).length) {
        const lastSelectedPropertyId = lastSelectedProperty.Id;
        setSelectedBuilding(lastSelectedPropertyId);
      }
    }
  }, [lastSelectedProperty]);

  useEffect(() => {
    if (editedPropertyCopy) {
      if (Object.values(editedPropertyCopy).length) {
        const editedPropertyCopyId = editedPropertyCopy.Id;
        setSelectedBuilding(editedPropertyCopyId);
      }
    }
  }, [editedPropertyCopy]);
 
  const classes = useStyles();
  
  return (
    <Grid container direction='row'>
      <Grid xs={12} style={{ padding: '2%' }}>
        <Paper style={{ width: '100%', boxShadow: 'none' }}>
          
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Buildings
            </InputLabel>
            <Select
              disabled={disabled}
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              autoFocus
              value={selectedBuilding}
              onChange={handleChange}
              className={classes.selectEmpty}
            >
              <MenuItem value={0}>
                <em>Select A Building</em>
              </MenuItem> 
              { propertyOptions.map((property) => {
                const id = property.Id;
                const name = property.Name;
                return (
                  <MenuItem value={id}>{name}</MenuItem>
                )
              }) }
            </Select>
          </FormControl>

        </Paper>
      </Grid>
    </Grid>
  );
}

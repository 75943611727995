import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ThemeDefault from '../../styles/theme-default';
import globalStyles from '../../styles/styles';

import {
    CartesianGrid,
    BarChart,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ResponsiveContainer,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const graphContainerStyle ={
    textAlign: 'center'
};

const barChartMarginStyle = {
    top: 20, 
    right: 30, 
    left: 20, 
    bottom: 5,
};

const XAxisTickFormatter = (value) => {
    return `${new Intl.NumberFormat('en').format(value)} years`;
};

const CustomTooltipContent = (props) => {
    const payload = props.payload;

    if (props.active) {
        const newPayload = payload; 

        if (payload) {
            payload.map((entry, i) => {
                const dataKey = entry.dataKey;
                const id = payload.indexOf(entry);

                if (dataKey == 'uv') {

                    const newEntry = {
                        color: '#707070', 
                        dataKey: entry.dataKey,
                        fill: '#707070',
                        formatter: entry.formatter = (value) => XAxisTickFormatter(value),
                        name: entry.name,
                        payload: entry.payload,
                        type: entry.type,
                        unit: entry.unit,
                        value: entry.value,
                    }
                    newPayload.splice(id, 1, newEntry);
                } else {
                    entry.formatter = (value) => XAxisTickFormatter(value);
                }
            })    
        }

        return <DefaultTooltipContent {...props} payload={newPayload} />
    } else {
        return <DefaultTooltipContent {...props} payload={payload}/>
    }
};


class FinancialForecastGraph extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        const { remainingServiceData } = this.props;

        return (
            <div id='graph-container' style={ graphContainerStyle }>
                {/* <MuiThemeProvider muiTheme={ThemeDefault}> */}
                <Typography color='textSecondary' variant='h6'>Avg. Estimated Remaining Service Life by Material Type</Typography>
                    <ResponsiveContainer width='96%' height='100%' aspect={ 12.0 / 5.0 } >
                        <BarChart
                            width={ 500 }
                            height={ 300 }
                            data={ remainingServiceData }
                            margin={ barChartMarginStyle }
                            layout="vertical"
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <YAxis type="category" dataKey="name" />
                            <XAxis type="number" tickFormatter={ XAxisTickFormatter }/>
                            <Tooltip content={ <CustomTooltipContent props={ remainingServiceData } /> } />
                            <Legend />
                            <Bar dataKey="pv" stackId="a" name="Avg. Life" fill="#4C9DD2" />
                            <Bar dataKey="uv" stackId="a" name="Max Life" fill="#DBDBDB" />
                        </BarChart>
                    </ResponsiveContainer>
                {/* </MuiThemeProvider> */}
            </div>
        );
    }
}

export default FinancialForecastGraph

import React, { Component } from 'react';
import PropertyGraphsLoadingDataCard from './SubComponents/PropertyGraphsLoadingDataCard';
import PropertyGraphsNoDataCard from './PropertyGraphsNoDataCard';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Line,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import {
  Typography,
} from '@material-ui/core';

const lineLabelStyle = {
  fill: "#000000",
  fontSize: 12,
  dataKey: "Score",
  position: "top",
  fontFamily:'Ebrima',
};

const graphContainerStyle = {
  textAlign: 'center', 
  overflow: 'overlay',
  minWidth: '21rem',
  minHeight: '16.78rem',
  fontFamily:'Ebrima',
};

const barChartMarginStyle = {
  top: 10,
  right: 20,
  left: 20,
  bottom: 10,
  fontFamily:'Ebrima',
};

const RCSTickFormatter = (value) => {
  return `$${new Intl.NumberFormat('en').format(value)}`;
};

const YAxisPercentageFormatter = (value) => {
  return `${value}`;
};

const CustomTooltipContent = (props) => {
  const payload = props.payload;
  if (props.active) {
    const newPayload = payload;
    if (payload) {
      payload.map((entry, i) => {
        const dataKey = entry.dataKey;
        switch (dataKey) {
          case 'Score':
            return entry.formatter = (value) => `${value}`;
            break;
          case 'Funded':
            return entry.formatter = (value) => `$${new Intl.NumberFormat('en').format(value)}`;
            break;
          case 'Accumulated':
            return entry.formatter = (value) => `$${new Intl.NumberFormat('en').format(value)}`;
            break;
        }
      })
    }
    return <DefaultTooltipContent {...props} payload={newPayload} />
  } else {
    return <DefaultTooltipContent {...props} payload={payload} />
  }
};

class FinancialForecastGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: null,
    };
  }

  componentDidMount = () => {
    const { financialForecastData, property } = this.props;
    if (!property) {
      if (financialForecastData.length == 0) {
        this.setState({
          loading: true,
        })
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      loading,
    } = this.state;
    const previousLoading = prevState.loading;

    const {
      property,
      selectedResult,
      financialForecastData
    } = this.props;

    if (property) {
      if (selectedResult) {
        const propertyId = property.Id;
        const selectedResultId = selectedResult.Id;
        if (propertyId != selectedResultId) {
          if (!loading) {
            this.setState({
              loading: true,
            })
          }
        }
        if (propertyId == selectedResultId) {
          if (loading) {
            this.setState({
              loading: false,
            })
          }
        }
      }
    }

    if (!loading) {
      if (previousLoading) {
        const containsFinancialData = financialForecastData.length > 0 ? true : false;
        if (!containsFinancialData) {
          this.setState({
            noChartData: true,
          })
        }
        if (containsFinancialData) {
          this.setState({
            noChartData: false,
          })
        }
      }
    }
  };
  
  render() {
    const {
      openFinancialForecastWindow,
      financialForecastData,
      materialType,
    } = this.props;

    const {
      loading,
      noChartData,
    } = this.state;


    return (
      <div style={{ display: 'grid', overflow: 'hidden' }}>
        <div id='graph-container' style={graphContainerStyle}>
                <Typography color='subtitle2' style={{ fontFamily: 'Ebrima', }}>{materialType != 6 ? '3 Year Financial Forecast' : '5 Year Financial Forecast'}</Typography>
          <ResponsiveContainer
            width={'100%'}
            aspect={12.0 / 6.0}
          >
            {loading ? <PropertyGraphsLoadingDataCard /> : noChartData ? <PropertyGraphsNoDataCard /> :
              <ComposedChart
                onClick={financialForecastData.length > 0 ? () => openFinancialForecastWindow() : null}
                data={financialForecastData}
                barGap={0}
                margin={barChartMarginStyle}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                />
                <XAxis
                  dataKey="Year"
                />
                <YAxis
                  datakey="Score"
                  tickFormatter={RCSTickFormatter}
                  angle={-45} 
                  textAnchor="end"
                />
                <YAxis
                  yAxisId="right"
                  orientation='right'
                  tickFormatter={YAxisPercentageFormatter}
                />
                <Tooltip
                  content={<CustomTooltipContent props={financialForecastData} />}
                />
                <Legend />
                <Bar
                  barSize={25}
                  dataKey="Funded"
                  name="Capital ($)"
                  fill="#6FAC51"
                />
                <Bar
                  barSize={25}
                  dataKey="Accumulated"
                  name='Operating Cost ($)'
                  fill="#1F5998"
                />
                <Line
                  type="monotone"
                  dataKey="Score"
                  name={materialType == 2 ? "PCI" : (materialType == 1 ? "RCS" : (materialType == 6 ? "Criticality" : "CS"))}
                  yAxisId="right"
                  stroke="#1072bd"
                  label={lineLabelStyle}
                />
              </ComposedChart>
            }
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}
export default FinancialForecastGraph
import React, { useEffect, useState, useRef } from 'react';
import BuildingFolderCard from './BuildingFolderCard';
import {
  TreeItem,
  TreeView,
} from '@material-ui/lab';
import {
  Grid, IconButton, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import BusinessIcon from '@material-ui/icons/Business';
import FolderIcon from '@material-ui/icons/Folder';

const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
    textAlign: 'left',
  },
});

let parentDirectory = {
  NodeId: 1,
  ParentNodeId: 0,
  Name: '',
  Selected: false,
  Type: 0,
  Expanded: false,
  Folders: [],
  BuildingFolders: []
};

export default function CompanyFolderTree(props) {
  const [ selected, setSelected ] = useState(0);
  const [ expanded, setExpanded ] = useState([]);
  const {
    handleAddSelected,
    handleDeleteSelected,
    Folders, 
    selectedCompany,
    handleFolderSelected,
  } = props;

  const handleSelect = (e, selectedId) => {
    // console.log(selectedId)
    const index = expanded.findIndex(expand => expand == selectedId);
    setSelected(selectedId);
    if (index == -1) {
       console.log('add id to expanded')
       handleFolderSelected(selectedId, selectedCompany);
      setExpanded([...expanded, selectedId]);
    } else {
      // console.log('remove id from expanded')
      const expandedCopy = [...expanded];
      expandedCopy.splice(index, 1);
      setExpanded(expandedCopy);
    }
  };

  const handleAddFolder = (e, node) => {
    e.stopPropagation();
    handleAddSelected(selected, node);
  };

  const handleDeleteFolder = (e, deleteType, node) => {
    e.stopPropagation();
    handleDeleteSelected(deleteType, node);
  };

  const createBuildingFolderLabel = (node) => {
    const name = node.Name;
    const nodeId = node.NodeId;
    const open = expanded.findIndex(expand => expand == nodeId);
    const renderDeleteButton = nodeId == selected && open != -1 ? true : false; 

    return (
      <Grid container direction='row'>
        <Grid item xs={11}>
          <Typography variant='inherit'>{name}</Typography>
        </Grid>
        <Grid item xs={1}>
          { renderDeleteButton ? 
            <IconButton size='small' onClick={(e) => handleDeleteFolder(e, 2, node)}>
              <DeleteIcon fontSize='small' />
            </IconButton>
        : null }
        </Grid>
      </Grid>
    )
  };

  const renderBuildingsFolder = (node) => {
    return (
      <TreeItem key={node.NodeId} nodeId={node.NodeId} label={createBuildingFolderLabel(node)} icon={<BusinessIcon />} >
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item xs={12} style={{ padding: '2%' }}>
            <BuildingFolderCard
              node={node}
            />
          </Grid>
        </Grid>
      </TreeItem>
    )
  };

  const createFolderLabel = (node) => {
    const name = node.Name;
    const nodeId = node.NodeId;
    const open = expanded.findIndex(expand => expand == nodeId);
    const renderAddButton = nodeId == selected && open != -1 ? true : false; 
    
    return (
      <Grid container direction='row'>
        <Grid item xs={10}>
          <Typography variant='inherit'>{name}</Typography>
        </Grid>
        <Grid item xs={2}>
          { renderAddButton ?
            <Grid container direction='row' alignItems='center' justify='center'>
              <Grid item xs={6}>
                <IconButton size='small' onClick={ (e)=>handleAddFolder(e, node) }>
                  <AddIcon fontSize='small' />
                </IconButton> 
              </Grid>
              <Grid item xs={6}> 
                <IconButton size='small' onClick={ (e)=>handleDeleteFolder(e, 1, node) }>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          : null }
        </Grid>
      </Grid>
    )
  };

  const renderTree = (nodes) => {
    return (
      <TreeItem key={nodes.NodeId} nodeId={nodes.NodeId} label={createFolderLabel(nodes)}>
        { Array.isArray(nodes.Folders) ? nodes.Folders.map((node) => renderTree(node)) : null }
        { Array.isArray(nodes.BuildingFolders) ? nodes.BuildingFolders.map((node) => renderBuildingsFolder(node)) : null }
      </TreeItem>
    );
  }

  const createHomeFolderLabel = (node) => {
    const nodeId = node.NodeId;
    const open = expanded.findIndex(expand => expand == nodeId);
    const renderAddButton = nodeId == selected && open != -1 ? true : false;
    const companyName = node.Name;

    return (
      <Grid container direction='row'>
        <Grid item xs={11}>
          <Typography variant='inherit'>{companyName}</Typography>
        </Grid>
        <Grid item xs={1}>
          { renderAddButton ?
            <Grid container direction='row' alignItems='center' justify='center'>
              <Grid item xs={12}>
                <IconButton size='small' onClick={(e) => handleAddFolder(e, node)}>
                  <AddIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
           : null } 
        </Grid>
      </Grid>
    )
  };

  const renderCompanyParentDirectory = (node) => {
    const name = node.Name;
    const folders = node.Folders;
    if (name) {
      return (
        <TreeItem 
              icon={<FolderIcon />} 
          key={0} 
          nodeId={0} 
          label={createHomeFolderLabel(node)} 
          onClick={(e) => handleSelect(e, 1)}
          // onClick={() => renderTree(folders)}
        >
          { folders.map((folder) => {
            // const nodeId = folder.NodeId;
            return renderTree(folder);
          })}
        </TreeItem>
      );
    }
  };

  const folders = [ ...Folders ];
  const companyName = selectedCompany.Name;
  parentDirectory.Name = companyName;
  //folders.splice(0, 0, parentDirectory);

  const classes = useStyles();
  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={ <ExpandMoreIcon /> }
      defaultExpandIcon={ <ChevronRightIcon /> }
      onNodeSelect={ (e, value)=>handleSelect(e, value) }
    >
      { Folders.map((folder) => {
        return renderCompanyParentDirectory(folder);
      }) }
      {/* { folders.map((folder) => {
        const nodeId = folder.NodeId;

        if (nodeId == 1) {
          return renderCompanyParentDirectory(folder);
        }
        return renderTree(folder);
      }) } */}
    {/* { Folders.map((Folder) => {
      const nodeId = Folder.NodeId;
      if (nodeId == 1) {
        return renderCompanyParentDirectory(Folder);
      }
      return renderTree(Folder);
    })  } */}
    </TreeView>
  );
}

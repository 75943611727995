import React, { Component } from 'react';
import DocumentAppendSelect from './Subcomponents/DocumentAppendSelect';
import DocumentPropertySelect from './Subcomponents/DocumentPropertySelect';
import DocumentTagSelect from './Subcomponents/DocumentTagSelect';
import DocumentTypeSelect from './Subcomponents/DocumentTypeSelect';
import DocumentNoteField from './Subcomponents/DocumentNoteField';
import DocumentFiles from './Subcomponents/DocumentFiles';
import DocumentWarranty from './Warranty/DocumentWarranty';
import {
  Grid,
  Collapse,
} from '@material-ui/core';

const WARRANTY_TYPE = 2;
const APPEND_TO_PROPERTY = 1;
const APPEND_TO_TAG = 2;

class DocumentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      appendTo: 1,
      property: {},
      tags: [], //was {}
      type: {},
      notes: '',
      files: [],
      sections: [],
      startDate: '',
      duration: 0,
      contractor: '',
      manufacturer: '',
      typeDisabled: false,
      enableSave: false,
    };
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { 
      document,
      handleRetrieveDocument,
      saveDocument, 
    } = this.props;
    const previousSaveDocument = prevProps.saveDocument;
    const previousDocument = prevProps.document;

    if (document != previousDocument) {
      if (document) {
        const { Id, OrgTags } = document;
        if (Id) {
          if (Id != id) {
            this.setState({
              id: Id,
            })
          } else {
            this.setState({
              id: 0,
            })
          }
          if (OrgTags && OrgTags.length) {
            this.setState({
              appendTo: APPEND_TO_TAG,
            })
          } else {
            this.setState({
              appendTo: APPEND_TO_PROPERTY,
            })
          }
        }
      }
    }

    const {
      id,
      appendTo,
      contractor,
      duration,
      files,
      manufacturer,
      notes,
      property,
      sections,
      startDate,
      tags,
      type,
      uploadDisabled,
      enableSave,
    } = this.state;
    const previousProperty = prevState.property;
    const previousTags = prevState.tags;
    const previousEnableSave = prevState.enableSave;
    const previousFiles = prevState.files;

    //validation
    if (appendTo == APPEND_TO_PROPERTY) {
      if (property != previousProperty) {
        const propertyExists = Object.values(property).length;
        if (propertyExists) {
          this.setState({
            typeDisabled: false,
          })
        } else {
          this.setState({
            typeDisabled: true,
          })
        }
      }
    }

    if (appendTo == APPEND_TO_TAG) {
      if (tags != previousTags) {
        const tagsExist = tags.length;
        if (tagsExist) {
          this.setState({
            typeDisabled: false,
          })
        } else {
          this.setState({
            typeDisabled: true,
          })
        }
      }
    }

    if (type != WARRANTY_TYPE) {
      if (!type) {
        if (!uploadDisabled) {
          this.setState({
            uploadDisabled: true,
          })
        } 
      } else {
        if (uploadDisabled) {
          this.setState({
            uploadDisabled: false,
          })
        }
      }
    }

    if (type == WARRANTY_TYPE) {
      if (!startDate) {
        if (!uploadDisabled) {
          this.setState({
            uploadDisabled: true,
          })
        }
      } else {
        if (uploadDisabled) {
          this.setState({
            uploadDisabled: false,
          })
        }
      }
    }

    if (!id) {
      if (files.length != previousFiles.length) {
        if (!enableSave) {
          this.setState({
            enableSave: true,
          })
        } else {
          this.setState({
            enableSave: false,
          })
        }
      }
    } else {
      if (!enableSave) {
        this.setState({
          enableSave: true,
        })
      }
    }

    if (enableSave != previousEnableSave) {
      const { handleEnableSave } = this.props;
      handleEnableSave(enableSave);
    }
  
    //save
    if (saveDocument != previousSaveDocument) {
      if (saveDocument) {
        const editedDocument = {
          id,
          appendTo,
          contractor,
          duration,
          files,
          manufacturer,
          notes,
          property,
          sections,
          startDate,
          tags,
          type,
        };
        handleRetrieveDocument(editedDocument);
      }
    }
  };

  handleAppendSelect = (id) => {
    this.setState({
      appendTo: id,
    })
  };

  handlePropertySelect = (property) => {
    const { handleWarrantySectionsDispatch } = this.props;
    const { Id } = property;
    this.setState({
      property,
    }, () => handleWarrantySectionsDispatch(Id))
  };

  handleTagSelect = (tags) => {
    this.setState({
      tags,
    })
  };

  handleTypeSelect = (type) => {
    this.setState({
      type,
    })
  };

  handleSectionSelect = (sections) => {
    this.setState({
      sections,
    })
  };

  handleStartDateSelect = (startDate) => {
    this.setState({
      startDate,
    })
  };

  handleDurationSelect = (duration) => {
    this.setState({
      duration,
    })
  };

  handleContractorSelect = (contractor) => {
    this.setState({
      contractor,
    })
  };

  handleManufacturerSelect = (manufacturer) => {
    this.setState({
      manufacturer,
    })
  };

  handleUpdateNote = (notes) => {
    this.setState({
      notes,
    })
  };

  handleFileSelect = (files) => {
    this.setState({
      files,
    })
  };

  render() {
    const {
      document,
      properties,
      tags,
      fileTypes,
      propertySections,
    } = this.props;

    const {
      appendTo,
      type,
      typeDisabled,
      uploadDisabled,
    } = this.state;

    return (
      <Grid container direction='row' alignItems='center' justify='center'>
        <Grid item xs={12}>
          <DocumentAppendSelect
            handleAppendSelect={this.handleAppendSelect}
            document={document}
            appendTo={appendTo}
          />
        </Grid>
        <Grid item xs={12}>
          { appendTo == 1 ? 
            <DocumentPropertySelect
              handlePropertySelect={this.handlePropertySelect}
              properties={properties}
              document={document}
            />    
            :
            <DocumentTagSelect 
              handleTagSelect={this.handleTagSelect}
              tags={tags}
              document={document}
            /> }
        </Grid>
        <Grid item xs={12}>
          <DocumentTypeSelect 
            handleTypeSelect={this.handleTypeSelect}
            fileTypes={fileTypes}
            document={document}
            typeDisabled={typeDisabled}
            appendTo={appendTo}
          />
          <Collapse in={type == WARRANTY_TYPE} timeout='auto'>
            <DocumentWarranty 
              handleSectionSelect={this.handleSectionSelect}
              handleStartDateSelect={this.handleStartDateSelect}
              handleDurationSelect={this.handleDurationSelect}
              handleContractorSelect={this.handleContractorSelect}
              handleManufacturerSelect={this.handleManufacturerSelect}              
              document={document}
              propertySections={propertySections}
            />
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <DocumentNoteField 
            handleUpdateNote={this.handleUpdateNote}
            document={document}
          />
        </Grid>
        <Grid item xs={12}>
          <DocumentFiles 
            handleFileSelect={this.handleFileSelect}
            document={document}
            uploadDisabled={uploadDisabled}
          />
        </Grid>
      </Grid>
    );
  };
};

export default DocumentForm

import React from 'react';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Avatar,
  IconButton,
  Grid,
  ListItem,
} from '@material-ui/core';
import ManageContactsCardStyle from '../../styles/ManageContactsStyles/ManageContactsCardStyle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import EmailIcon from '@material-ui/icons/Email';

export default function ManageContactsCard(props) {
  const [ expanded, setExpanded ] = React.useState(false);
  function handleExpandClick() { setExpanded(!expanded) };

  const {
    i,
    contact,
    selectedContact,
    handleCardClick,
  } = props;
  
  const {
    Id,
    ContactTypesFormatted,
    Email,
    Phone1,
    Phone2,
    Add1,
    Add2,
    City,
    State,
    Zip,
    Country,
    FullName,
    FirstName,
    LastName,
    ProfileUrl,
  } = contact;

  const classes = ManageContactsCardStyle();
  const {
    card,
    avatar,
    cardContent
  } = classes;
  
  const initials = `${FirstName.charAt(0)}${LastName.charAt(0)}`;
  const selectedContactId = selectedContact ? selectedContact.Id : null;
  const managedContactsCardId = `${i}-${Id}`;
  
  return (
      <Grid container>
      <ListItem key={ managedContactsCardId } id={ managedContactsCardId }>
        <Grid style={{ width: '100%'}} item>
            <Card onClick={ ()=>handleCardClick(contact) } className={ card }>
              <CardHeader
                avatar={ <Avatar className={ avatar } src={ ProfileUrl }>{ initials }</Avatar> }
                title={ FullName }
                subheader={ ContactTypesFormatted }
                action={
                  <div>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: selectedContactId == Id ? true : false,
                      })}  
                      aria-expanded={ selectedContactId == Id ? true : false }
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </div>
                }
              />
              <Collapse in={ selectedContactId == Id ? true : false } timeout="auto" unmountOnExit>
                <CardContent className={cardContent}>
                  <Grid container>
                    <Grid item align={'center'} xs={4}>
                    <HomeIcon />
                    </Grid>
                    <Grid item align={'left'} xs={8}>
                      {Add1} {Add2} {City}, {State} {Zip} {Country}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item align={'center'} xs={4}>
                    <ContactPhoneIcon />
                    </Grid>
                    <Grid item align={'left'} xs={8}>
                     {Phone1}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item align={'center'} xs={4}>
                      <EmailIcon />
                    </Grid>
                    <Grid item align={'left'} xs={8}>
                      {Email}
                  </Grid>
                </Grid>
                </CardContent>
              </Collapse>
          </Card>
        </Grid>
      </ListItem>
    </Grid>
  );
}

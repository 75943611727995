import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Input,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function DialogSelect(props) {
  const [ updatedStatus, setUpdatedStatus ] = useState(null);
  const [ comment, setComment ] = useState(null);
  const classes = useStyles();

  const { 
    openEdit, 
    handleClickCloseEdit, 
    statusList, 
    selectedStatus, 
    retrieveUpdatedStatus, 
    retrieveUpdatedNote 
  } = props;

  const handleChange = e => {
    const id = e.target.value;
    const newlySelectedStatus = statusList.find(status => status.WostatusTypeId == id);
    if (newlySelectedStatus.WostatusTypeId != updatedStatus.WostatusTypeId) {
      setUpdatedStatus(newlySelectedStatus);
    }
  };

  const handleComment = e => {
    const value = e.target.value;
    setComment(value);
  };

  useEffect(() => {
    if (selectedStatus) {
      const selectedStatusId = selectedStatus.WOStatusTypeID;
      const updateStatus = statusList.find(status => status.WostatusTypeId == selectedStatusId);
      setUpdatedStatus(updateStatus);
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (updatedStatus) {
      retrieveUpdatedStatus(updatedStatus);
    }
  }, [updatedStatus]);

  useEffect(() => {
    if (comment) {
      retrieveUpdatedNote(comment);
    }
  }, [comment]);

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open={openEdit} onClose={handleClickCloseEdit}>
      <DialogTitle>Update Status</DialogTitle>
      <DialogContent>
        <form className={classes.container}>
          <FormControl className={classes.formControl}>
            <Select
              native={false}
              onChange={handleChange}
              value={ updatedStatus ? updatedStatus.WostatusTypeId : null }
            >
            { statusList.map((status) => {
              const title = status.Wostatus;
              const value = status.WostatusTypeId;
              return (
                <option value={ value }>{ title }</option>
              )
            }) }
            </Select>
            <FormHelperText>Work Order Status</FormHelperText>
            <br></br>
            <br></br>
             <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
              id="standard-multiline-static"
              placeholder="Optional"
              onChange={handleComment}
            />
            <FormHelperText>Add A Comment</FormHelperText>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCloseEdit} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClickCloseEdit} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import {
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function PositionedSnackbar(props) {
  const [ open, setOpen ] = useState(false);
  const {
    selectedResult,
    property
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousProperty = usePrevious(property);
  useEffect(() => {
    if (property != previousProperty) {
      if (property && selectedResult) {
        const propertyId = property.Id;
        const selectedResultId = selectedResult.Id;
        if (propertyId == selectedResultId) {
          const {
            SectionWarrantyExists,
          } = property;
          setOpen(SectionWarrantyExists);
        }
      }
    }
  }, [property]);

  return (
    <div>
      <Snackbar open={open} autoHideDuration={9000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="info">
          This Property Contains Warrantied Sections
        </Alert>
      </Snackbar>
    </div>
  );
};

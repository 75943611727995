import React from 'react';
import PropTypes from 'prop-types';
import LoginPage from './LoginPage';
import ResetPasswordPage from './ResetPassword';
import ApplicationContainer from '../components/Application/ApplicationContainer';
import auth from '../auth';
import packageJson from '../../package.json';
global.appVersion = packageJson.version;



class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      authenticated: auth.hasAccessToken() 
    };
  }

  render() {
    const handleAuthenticated = _ => this.setState({ authenticated: true });

    const parser = document.createElement('a');
    parser.href = window.location.href;
  
    const path = parser.pathname.split("/");
    const reseturl = path[1];

    // console.log('dkdkstart');
    // console.log(process.env);
    // console.log(process.env.REACT_APP_BASE_URL);
    // console.log('dkdkdkend');

    if(reseturl != null && reseturl === "ResetPassword"){
      var user = path[2];
      var tmppass = path[3];
  
      if(this.state.authenticated){
        window.location.href = parser.origin;
      }
    }

    return reseturl!=null && reseturl === "ResetPassword"?
    (
    <ResetPasswordPage onAuthenticated={handleAuthenticated} tmppass={tmppass} user={user} />
    ):(
        !this.state.authenticated ? <LoginPage onAuthenticated={handleAuthenticated} /> : <ApplicationContainer />
    );
    
      
    }
};

App.propTypes = {
  children: PropTypes.element
};

export default App;

import React from 'react';
import AddContactCheckBox from './AddContactCheckBox';
import {
  Card,
  CardHeader,
  ListItem,
  Grid,
} from '@material-ui/core';
import ManageContactsCardStyle from '../../../styles/ManageContactsStyles/ManageContactsCardStyle';

export default function CompanyBuildingsCard(props) {
  const classes = ManageContactsCardStyle();
  const { card } = classes;
  const { building, selectBuilding, companyAssetsAndTypes } = props;
  const { Name, Id } = building;
  const listItemId = `${building.Id}-${companyAssetsAndTypes.Id}`;

  return (
    <ListItem id={listItemId} key={listItemId}>
      <Card className={card}>
        <Grid container direction={'row'}>
          <Grid item xs={10}>
            <CardHeader
              subheader={ Name }
            />
          </Grid>
          <Grid item xs={2} style={{textAlign: 'center'}}>
            <AddContactCheckBox
              building={ building }
              selectBuilding={ selectBuilding }
              companyAssetsAndTypes={ companyAssetsAndTypes }
            />
          </Grid>
        </Grid>
      </Card>
    </ListItem>
  );
}


// const handleContactSelectedUpdate = contact => {
//   this.dispatch({ ContactTypeSelected: { CompanyId: contact.CompanyId, ContactTypeId: contact.ContactTypeId, UserId: Id } });
// };

// const handleBuildingSelected = comp => {
//   this.dispatch({ PropertySelected: { PropertyId: comp.BuildingId, CompanyId: comp.CompanyId, UserId: Id, ContactTypeId: comp.ContactTypeId, Selected: comp.Selected } });
// };

// const handleAssignContacts = comp => {
//   this.dispatch({ AssignContacts: { PropertyId: -1, CompanyId: comp.CompanyId, UserId: Id, ContactTypeId: comp.ContactTypeId, Selected: true } });
// };

// const handleDeleteContact = contact => {
//   this.setState({ Loading: true, LoadingMessage: "Removing Contact" });
//   this.dispatch({ DeleteContact: { PropertyId: contact.PropertyId, CompanyId: 0, UserId: Id, ContactTypeId: contact.ContactTypeId, Selected: true } });
// }

{/* <Checkbox key={"cbxview" + item.Selected.toString()}
  value={item.Id.toString()}
  onClick={(e) => props.handleBuildingSelected({
    BuildingId: item.Id,
    CompanyId: compbldg.Id,
    ContactTypeId: compbldg.SelectedContactTypeId,
    Selected: e.target.checked
  })}
  checked={item.Selected}
/> */}
import 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

//TODO: needs to initiated to NULL and require a date
export default function WarrantyStartDatePicker(props) {
  const [value, setValue] = useState('');
  const {
    handleStartDateSelect,
    document,
  } = props;

  const handleDateChange = (value) => {
    setValue(value);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value != previousValue) {
      if (value) {
        handleStartDateSelect(value);
      } else {
        handleStartDateSelect('');
      }
    }
  }, [value]);

  useEffect(() => {
    if (document) {
      const { Id, StartDate } = document;
      if (Id) {
        setValue(StartDate);
      } else {
        setValue('');
      }
    }
  }, [document]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ zIndex: '2' }}>
      <Grid container justify="space-around" style={{ zIndex: '2' }}>
        <KeyboardDatePicker
          style={{ minWidth: '95%', zIndex: '2' }}
          InputLabelProps={{
            shrink: true,
          }}
          error={false}
          helperText={null}
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Select Start Date"
          value={value}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

import React, { useEffect, useState } from 'react';
import PropertyRepairsGroupCard from './PropertyRepairsGroupCard';
import PropertyDefectsSectionDropdown from './SubComponents/PropertyDefectsSectionDropdown';
import PropertyNoRepairsCard from './SubComponents/PropertyNoRepairsCard';
import {
  List,
  Grid,
  Typography,
  Paper,
  IconButton,
  Collapse,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
    fontFamily: 'Ebrima',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
}));

export default function PropertyDefectsList(props) {
  const [defectsSelected, setDefectsSelected] = useState(null);
  const [defectGroupsList, setDefectGroupsList] = useState([]);

  const classes = useStyles();

  const {
    handleDefectsCategorySelected,
    selectedProperty,
    retrievedSelectedDefectsGroups,
    retrieveSelectedDefectSection,
    highlightSelectedDefect,
    selectedDefects,
    defectGroups,
    selectedDefectSection,
    displayRepairs,
  } = props;

  const {
    DefectRepairs,
  } = selectedProperty;

  const handleDefectSelected = () => {
    setDefectsSelected(!defectsSelected);
  };

  useEffect(() => {
    setDefectGroupsList(defectGroups);
    handleDefectsCategorySelected(defectsSelected);
  }, [defectsSelected]);

  useEffect(() => {
    setDefectGroupsList(DefectRepairs);
  }, [DefectRepairs]);

  return (
      <Grid container direction='row' alignItems='flex-start' justify='center'>
        <Grid item xs={12} style={{ height: '100%' }}>
          <PropertyDefectsSectionDropdown
            selectedProperty={selectedProperty}
            retrieveSelectedDefectSection={retrieveSelectedDefectSection}
            displayRepairs={displayRepairs}
          />
        { displayRepairs ? 
          <List style={{ width: '100%', padding: '2%' }}>
            { defectGroupsList.length > 0 ?
              defectGroupsList.map((defectGroup, i) => {
                return (
                  <PropertyRepairsGroupCard
                    i={i}
                    defectGroup={defectGroup}
                    retrievedSelectedDefectsGroups={retrievedSelectedDefectsGroups}
                    highlightSelectedDefect={highlightSelectedDefect}
                    selectedDefects={selectedDefects}
                    selectedDefectSection={selectedDefectSection}
                  />
                )
              })
            : <PropertyNoRepairsCard />  }
            </List>
          : null }
        </Grid>
      </Grid>
  )
}
import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '55px',
    backgroundColor: '#0672BA',
    fontFamily: 'Ebrima',
  },
  saveButton: {
    height: 35,
    width: 100,
    fontFamily: 'Ebrima',
    backgroundColor: '#A7A9AC',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#A7A9AC',
      color: '#fafafa',
      fontFamily: 'Ebrima',
  },
  '&:disabled': {
    backgroundColor: '#A7A9AC',
    color: '#cacbcc',
    fontFamily: 'Ebrima',
}
}
}));

export default useStyles
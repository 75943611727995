import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  Modal,
  Button,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function DocumentDeleteModal(props) {
  const [fileName, setFileName] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const {
    handleDeleteModalClose,
    workOrderDeleteModalOpen,
  } = props;

  const handleModalBlur = ({ nativeEvent }) => {
    const targetId = nativeEvent.target.id;
    if (targetId == 'delete-container') {
      handleDeleteModalClose();
    }
  };

  const deleteWorkOrder = () => {
    handleDeleteModalClose();
  };

  // useEffect(() => {
  //   if (deleteDocumentFile) {
  //     const { FileName, BuildingName } = deleteDocumentFile;
  //     setFileName(FileName)
  //     setBuildingName(BuildingName)
  //   }
  // }, [deleteDocumentFile]);

  return (
    <Modal open={workOrderDeleteModalOpen}>
      <div id={'delete-container'} style={{ width: '100%', height: '100%' }} onClick={handleModalBlur}>
        <Grid container>
          <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
            <Grid item xs={10}>
              <Paper id='paper' style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', height: '15rem', width: '22rem', backgroundColor: 'rgb(246, 243, 235)', textAlign: 'center' }}>
                <Grid container direction='row' style={{ width: '100%', backgroundColor: '#3f51b5', height: '15%' }}>
                  <Grid item style={{ margin: '1%', display: 'inline-flex' }}>
                    <DeleteIcon style={{ color: 'white' }} />
                    <Typography style={{ color: 'white' }}>Delete Work Order</Typography>
                  </Grid>
                </Grid>

                <Grid container direction='row'>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1'>Are you sure you want to delete </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' style={{ color: 'darkGrey' }}>{'JJ'}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1'>from</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' style={{ color: 'darkGrey' }}>{'The Face of The Earth??'} <span style={{ color: 'black' }}>?</span></Typography>
                  </Grid>
                </Grid>
                <Grid container direction='row' style={{ position: 'absolute', bottom: '5%', bottom: '5%' }}>
                  <Grid item xs={12}>
                    <Button onClick={() => deleteWorkOrder()} variant='contained' color='secondary'>
                      <DeleteIcon />
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const HeaderAvatarStyle = makeStyles({
    avatar: {
        
        marginLeft: 0,
        marginTop: 5,
        marginBottom: 5
    },
    noimageavatar: {
        margin: 0,
    },
    avatarContainer: {
      display: '-webkit-inline-box', 
      cursor: 'pointer',
      width: '100%',
      border: '0px red solid',
      marginLeft: '5px',
    },
    nameContainer: {
      marginTop: '5%',
      marginLeft: '2%',
      width: '600px',
      border: '0px red solid'
    }
});

export default HeaderAvatarStyle;
import React from 'react';
import {SvgIcon} from '@material-ui/core';

let SvgWorldMap = (props) => (
  <SvgIcon {...props} >
       <path d="M12,0A12,12,0,1,0,24,12,12.0353,12.0353,0,0,0,12,0Zm4,17.3-.5.5a2.1859,2.1859,0,0,0-.3.5c-.1.3-.1.6-.2.8l-.8,2.3a14.9189,14.9189,0,0,1-2.1.2V20.3A3.68,3.68,0,0,0,11,17.8a1.6058,1.6058,0,0,1-.5-1.1V15.2a1.5959,1.5959,0,0,0-.8-1.4C9,13.5,8,13,7.3,12.6a4.1768,4.1768,0,0,1-1.5-1.1h0a9.016,9.016,0,0,1-.9-1A27.0662,27.0662,0,0,1,3.2,8a9.57,9.57,0,0,1,5-4.9l1.2.6c.4.3,1.1-.1,1.1-.7V2.5a4.8686,4.8686,0,0,1,1.2-.1L13,3.7a.7847.7847,0,0,1,0,1.1l-.2.2-.5.5c-.2.2-.2.4,0,.5l.2.2c.2.2.2.4,0,.5l-.4.4a.3674.3674,0,0,1-.3.1h-.4a.3674.3674,0,0,0-.3.1l-.5.5a.44.44,0,0,0-.1.5l.8,1.5c.1.3-.1.6-.3.6h-.3a.3674.3674,0,0,1-.3-.1L10,10a.6628.6628,0,0,0-.7-.2l-1.5.5a.6644.6644,0,0,0-.4.5.55.55,0,0,0,.3.5l.5.3a4.0126,4.0126,0,0,0,1.5.3c.5,0,1.1,1.3,1.5,1.5h3.2a1.6058,1.6058,0,0,1,1.1.5l.7.7a1.4327,1.4327,0,0,1,.4,1A2.3522,2.3522,0,0,1,16,17.3Zm4.2-4.4a1.1572,1.1572,0,0,1-.7-.5l-.9-1.3a1.0514,1.0514,0,0,1,0-1.3l.9-1.4A1.7556,1.7556,0,0,1,20,8l.6-.3a9.0709,9.0709,0,0,1,1,4.3,4.8686,4.8686,0,0,1-.1,1.2Z" />
  </SvgIcon>
);

export default SvgWorldMap;

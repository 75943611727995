import React, { useState, useEffect } from 'react';
import DocumentsPaginationSlider from './DocumentsPaginationSlider';
import {
  ListItem,
  Divider,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';

const typographyPagesStyle = {
  fontSize: '12px',
  fontFamily: 'Ebrima',
};

export default function ApplicationSearchPagination(props) {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [buttonCount, setButtonCount] = useState([]);
  const [buttonBegin, setButtonBegin] = useState(1);
  const [buttonEnd, setButtonEnd] = useState(4);

  const { pages, dispatchPaginationSelect, recordCount, selectPageSize } = props;

  const createPagination = () => {
    const numberOfPages = pages ? pages.length : 0;
    const buttonArr = [];

    if (numberOfPages > 0) {
      if (numberOfPages > 3) {
        for (let i = buttonBegin; i < buttonEnd; i++) {
          buttonArr.push(i);
        }
      }
      if (numberOfPages <= 3) {
        for (let i = 0; i < numberOfPages; i++) {
          buttonArr.push(i + 1);
        }
      }
    }
    setNumberOfPages(numberOfPages);
    setButtonCount(buttonArr);
  };

  const paginationSelect = (pageNumber) => {
    setSelectedPage(pageNumber);
    dispatchPaginationSelect(pageNumber);
  }

  const incrementPagination = () => {
    if (buttonEnd < numberOfPages) {
      setButtonBegin(buttonBegin + 1);
      setButtonEnd(buttonEnd + 1);
    }
  };

  const decrementPagination = () => {
    if (buttonBegin > 1) {
      setButtonBegin(buttonBegin - 1);
      setButtonEnd(buttonEnd - 1);
    }
  };

  useEffect(() => {
    createPagination();
  }, [pages, buttonEnd]);

  return (
      <Grid container direction={'row'} alignItems={'center'} justify={'space-evenly'} style={{ backgroundColor: '#66BFFA' }}>
        
        <Grid item xs={12} style={{ paddingBottom: '1%', paddingTop: '1%', paddingLeft: '1%', paddingRight: '1%' }}>
          <Grid container direction={'row'} alignItems={'center'} justify={'space-evenly'} style={{backgroundColor: '#A7A9AC' }}>
            <Grid item xs={2} style={{ textAlign: 'center', fontFamily:'Ebrima', }}>
              <Button onClick={() => decrementPagination()}>-</Button>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction='row' alignItems={'center'} justify={'space-evenly'}>
                {buttonCount.map(pageNumber =>
                  <Grid item xs={1}>
                    <Button
                      value={pageNumber}
                      style={selectedPage == pageNumber ? { textDecoration: 'underline', fontFamily:'Ebrima', } : null}
                      onClick={() => paginationSelect(pageNumber)}>
                      {pageNumber}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'center', fontFamily:'Ebrima', }}>
              <Button onClick={() => incrementPagination()}>+</Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction={'row'} alignItems={'center'} justify={'center'} style={{ paddingBottom: '1%' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography align={'center'} style={typographyPagesStyle}>{pages ? numberOfPages : 1} Pages</Typography>
          </Grid>
        </Grid>
          
        <Divider />

        <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
          <Grid item xs={12}>

            <DocumentsPaginationSlider
              recordCount={recordCount}
              selectPageSize={selectPageSize}
            />

          </Grid>
        </Grid>
      </Grid>
  );
}

import React,  { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    fontFamily: 'Ebrima',
  },
  formControl: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    width: '100%',
    fontFamily: 'Ebrima',
  },
}));

export default function DocumentSearch(props) {
  const [labelWidth, setLabelWidth] = useState(0);
  const [value, setValue] = useState('');
  const labelRef = useRef(null);
  const classes = useStyles();

  const { 
    handleSearch,
  } = props;

  useEffect(() => {
    setLabelWidth(labelRef.current.offsetWidth);
  }, []);

  function handleChange(event) {
    const value = event.target.value;
    setValue(value);
  };

  useEffect(() => {
    if (value) {
      handleSearch(value);
    } else {
      handleSearch('')
    }
  }, [value]);

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl} variant="outlined">
        <InputLabel ref={labelRef} htmlFor="component-outlined" style={{fontFamily: 'Ebrima',}}>
          Search Documents
        </InputLabel>
        <OutlinedInput
          id="component-outlined"
          autoComplete={'off'}
          value={value}
          onChange={handleChange}
          labelWidth={labelWidth}
          style={{fontFamily: 'Ebrima',}}
        />
      </FormControl>
    </div>
  );
}

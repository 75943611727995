import React, { useState, useEffect } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Grid,
    Button,
} from '@material-ui/core';
import SiteFilterInputStyle from '../../styles/SiteFilterStyles/SiteFilterInputStyle';

function SiteFilterMaterial(props) {
  const classes = SiteFilterInputStyle();
  const [values, setValues] = useState({
      name: props.selectedMaterial != null ? props.selectedMaterial.id : null,
      value: props.selectedMaterial != null ? props.selectedMaterial.id : null,
  });

  const { name } = values;
  const { root, formControl } = classes;
  const inputProps = { name: 'name', id: 'material-id' };
 
  const {
      allMaterials
  } = props;

  useEffect(() => {
      props.materialSelect(name);
  }, [values, setValues]);

  const handleMaterialSelect = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues(oldValues => ({
        ...oldValues,
        [name]: value,
    }));
  };

  return (
      <Grid container direction={'row'} justify={'flex-end'}>
        <Grid item xs={0} sm={12}>
          <form className={root}>
            <FormControl className={formControl}>
              <InputLabel htmlFor="material-id">Material</InputLabel>
              <Select
                value={name}
                onChange={handleMaterialSelect}
                inputProps={inputProps}
              >
              { allMaterials.map((material, i) => {
                  const name = material.name;
                  const value = material.id;
                  return <MenuItem key={`${i-value}`} value={value}>{name}</MenuItem>
              }) }
              </Select>
            </FormControl>
          </form>
        </Grid>
      </Grid>
  )
}

export default SiteFilterMaterial

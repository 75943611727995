import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Input,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function OrgTagSearchBar(props) {
  const [ SearchText, setSearchText ] = useState('');

  const { retrieveOrgTagSearch, disabled } = props;

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    debounceSearch(value);
  };

  const debounceSearch = debounce(value => {
    setSearchText(value);
  }, 600);

  useEffect(() => {
    retrieveOrgTagSearch(SearchText);
  }, [SearchText]);
  
  const classes = useStyles();
  return (
    <div>
      <FormControl className={classes.margin}>
        <Input
          id="input-with-icon-adornment"
          disabled={disabled}
          placeholder='Search'
          onChange={handleSearchTextChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import {
  Button,
} from '@material-ui/core';
import TextField from 'material-ui/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ThemeDefault from '../styles/theme-default';
import auth from '../auth';
// import LoginBackground from '../../wwwroot/mantis_perform_login_screen_full.png';
import BluefinLogo from '../images/Mantis_Logo_Perform_Color_Vertical.png';
import { withStyles } from '@material-ui/core/styles';

const MantisBlueCheckbox = withStyles({
  root: {
    color: "#0672BA",
    "&$checked": {
      color: "#0672BA"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { reset: false };
  }

  render() {
    let { user, password, reset, error } = this.state;
      const { onAuthenticated } = this.props;

      this.reset = true;

    const styles = {
      loginContainer: {
        minWidth: 360,
        maxWidth:360,
        height: 'auto',
        position: 'absolute',
        backgroundImage: `url("/mantis_perform_login_screen.png")`,
        top: '20%',
        left: 0,
        right: 0,
        margin: 'auto',
        fontFamily: 'Ebrima',
      },
      paper: {
        padding: 20,
        overflow: 'auto',
        fontFamily: 'Ebrima',
      },
      loginBtn: {
        float: 'right',
        fontFamily: 'Ebrima',
      },
      logo: {
      minHeight: '64px', 
      textAlign: 'center',
      paddingTop: '5px',
      fontFamily: 'Ebrima',
      },
      text: {
        marginTop: 16,
        color: '#333',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        verticalAlign: 'text-bottom',
        textAlign: 'center',
        fontSize: '18px',
        fontFamily: 'Ebrima',
      },
      error: { color: 'red', fontFamily: 'Ebrima', }
    };


    const handleLogin = _ => {
      this.setState({ error: null });
        auth.signIn(user, password, reset).then(_ => onAuthenticated()).catch(error => { 
          console.log(error.message);
          if ((error.message == '400' || error.message == '200') && reset) {
            console.log(error);
            this.setState({ error: "A new temporary password has been created and emailed!", reset:false });
          }else if(error.message == '400') {
            this.setState({ error: "Invalid password!", reset:false });
          }else this.setState({ error: error.message });
      });
    };

    return (
      <MuiThemeProvider muiTheme={ThemeDefault}>
        <div style={{ height: '98%', overflow: 'hidden' }}>
          
          <img src={require('../../wwwroot/MantisPerformLoginScreen_v3.png')} style={{ position: 'fixed', top: '0', left: '0', bottom:'0', right:'0', width:'100%', height:'100%' }}></img>
          <div style={styles.loginContainer}>
            <Paper style={{ padding: 20, overflow: 'auto' }}>
              <div style={{ minHeight: '64px', textAlign: 'center', paddingTop: '5px', fontFamily:'Ebrima', }}>
                <img src={ BluefinLogo } width="270"></img>
                <div style={styles.text}>Sign In</div>
              </div>
              <form>
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  hintText="User"
                  floatingLabelText="User"
                  fullWidth={true}
                  value={user}
                  style={{fontFamily: 'Ebrima',}}
                  onChange={event => this.setState({ user: event.target.value })}
                />
                             <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                                disabled={reset}
                  hintText="Password"
                  floatingLabelText="Password"
                  fullWidth={true}
                  type="password"
                  value={password}
                  style={{fontFamily: 'Ebrima',}}
                  onChange={event => this.setState({ password: event.target.value })}
                                />
                <div style={{marginTop:'10px'}}>
                            <MantisBlueCheckbox key={'cbxEmailUser'} checked={reset} style={{fontFamily: 'Ebrima',}}
                                onClick={(e) => this.setState({ reset: e.target.checked})} />
                                <span style={{fontFamily: 'Ebrima', }}>
                      reset password
                      </span>
                      </div>
               
                {error ? <div style={styles.error}>{error}</div> : null}
                <div style={{float: 'right', marginTop:'10px',}}>
                  <span>
                  <Button variant='contained' color="primary" onClick={handleLogin} style={{ height: '3rem',  color:'#ffffff', backgroundColor: '#00C2F3', fontFamily: 'Ebrima', }} >
                  {reset?"Reset Password": "Login"} 
                    </Button>
                    
                  </span>
                </div>
              </form>
            </Paper>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

LoginPage.propTypes = {
  onAuthenticated: PropTypes.func
};

export default LoginPage;


import React from 'react';
import dotnetify from 'dotnetify';
import { withStyles } from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import ThemeDefault from '../styles/theme-default';
import auth from '../auth';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { green, green400, green600, grey50, pink500, grey200, grey300, grey500, grey400, pink400 } from 'material-ui/styles/colors';

const GreenCheckbox = withStyles({
          root: {
            color: green400,
            '&$checked': {
              color: green600,
            },
          },
          checked: {},
        })(props => <Checkbox color="default" {...props} />);

class UserChangePasswordPage extends React.Component {
  constructor(props) {
      super(props);
      this.vm = dotnetify.react.connect('UserChangePassword', this, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
          console.log(ex);
            auth.signOut();
        }
      });
      this.dispatch = state => this.vm.$dispatch(state);
      this.routeTo = route => this.vm.$routeTo(route);

        this.state = { UserId: 0, 
            newpassdirty: false, 
            NewPassword: '', 
            ConfirmPassword: '', 
            minlength: false,
            lower: false,
            upper: false,
            number: false,
            special: false,
            confirmed: false };
        }


  render() {
    let { UserId, newpassdirty, NewPassword, ConfirmPassword, error, minlength, lower, upper, number, special, confirmed } = this.state;

    const styles = {
      loginContainer: {
        minWidth: 320,
        maxWidth: 320,
        height: 'auto',
        position: 'absolute',
        top: '20%',
        left: 0,
        right: 0,
        margin: 'auto'
        },
        color: green400,
       passwordprompts: {
           fontSize: 14
          },
      paper: {
        padding: 20,
        overflow: 'auto'
      },
      loginBtn: {
        float: 'right'
      },
      logo: {
        width: 16,
        height: 16,
        borderRadius: 16,
        backgroundColor: '#92d050',
        marginRight: 6,
        display: 'inline-block'
      },
      text: {
        color: '#333',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        verticalAlign: 'text-bottom'
      },
      error: { color: 'red' }
    };


      const validatePasswords = _ =>{

        var errors = '';

        if (this.NewPassword ==null || this.NewPassword.length<8 ) {
            //Check capital characters
            errors = 'The password must contain 8 or more characters.';
            this.setState({minlength: false});

        } else {
            this.setState({minlength: true})
           
        }

        if (!(/([A-Z]+)/g.test(this.NewPassword))){
            errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
            this.setState({upper: false})

        } else {
            this.setState({upper: true})

        }

        if (!(/([a-z]+)/g.test(this.NewPassword))){
            errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
            this.setState({lower: false})
        } else {
            this.setState({lower: true})
        }

        if (!(/([0-9]+)/g.test(this.NewPassword))){
            errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
            this.setState({number: false})
        } else {
            this.setState({number: true})
        }

        if (!(/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(this.NewPassword))){
            errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
            this.setState({special: false})
        }else {
            this.setState({special: true})
        }

        if (this.ConfirmPassword != null && this.NewPassword != null){
            if (this.ConfirmPassword == this.NewPassword) {
                this.setState({confirmed: true});
                confirmed = true;
            } else {
                this.setState({confirmed: false})
                confirmed = false;

            }
        } else {
            this.setState({confirmed: false});
            confirmed = false;

        }

        console.log(this.ConfirmPassword == this.NewPassword)
        console.log("ConfirmPassword:" + this.ConfirmPassword + " NewPassword:" + this.NewPassword)
        console.log("confirmed:" + confirmed + " special:" + special + " number:" + number + " upper:" +  upper + " lower:" + lower + " MinLength:" +  minlength)  

        if(confirmed && special && number && upper && lower && minlength) {
            this.setState({newpassdirty: true});
        } else {
            this.setState({newpassdirty: false});
        }     
    }

      const updatePasswordChange = val => {
          this.NewPassword = val.NewPassword;

          validatePasswords(val);
      }

       const updateConfirmPasswordChange = val => {
           this.ConfirmPassword = val.ConfirmPassword;
           var errors = '';

           validatePasswords(val);
      }

      const cancelPasswordUpdate = _ => {
          this.setState({newpassdirty: false, ConfirmPassword: '', NewPassword: ''});
      }

      const updatePassword = _ => {
          this.dispatch({UpdatePassword: {UserId: UserId, Password: this.NewPassword}});
          this.setState({newpassdirty: false, ConfirmPassword: '', NewPassword: ''});
          this.props.handlePasswordChanged(true);

      }

    return (
      <MuiThemeProvider muiTheme={ThemeDefault}>
        <div>
          <div style={styles.loginContainer}>
            <Paper style={styles.paper}>
              <div>
                <span style={styles.text}>Change Password</span>
              </div>
              <form>
                 <div>
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                      id="standard-password-input"
                      hintText="New Password"
                      floatingLabelText="New Password"
                                  type="password"
                                  value={this.NewPassword}
                      fullWidth={true}
                      onChange={event =>updatePasswordChange({ NewPassword: event.target.value})}
                    />
                </span>
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                      id="standard-confirm-password-input"
                      hintText="Confirm Password"
                      floatingLabelText="Confirm Password"
                                  type="password"
                                  value={this.ConfirmPassword}
                      fullWidth={true}
                      onChange={event => updateConfirmPasswordChange({ ConfirmPassword: event.target.value})}
                    />
                </span> 
                                </div>
                            <div style={styles.passwordprompts}>
                                         
                    <span>Password Requirements: </span><span>
                    <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={lower} 
                            
                            value={lower}
                          />
                        }
                        label="Lower Case"
                      />  </span><span> 

                  <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={upper} 
                            
                            value={upper}
                          />
                        }
                        label="Upper Case"
                      /></span><span><FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={number} 
                            
                            value={number}
                          />
                        }
                        label="Number"
                      /></span><span>
                    <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={special} 
                            
                            value={special}
                          />
                        }
                        label="Special Character"
                      /> </span><span>

                                              <FormControlLabel
        control={
          <GreenCheckbox
            checked={minlength} 
            
            value={minlength}
          />
        }
        label="Min. 8 Characters"
      />

                                              </span>
                
            </div>

                {error ? <div style={styles.error}>{error}</div> : null}
                <div>
                  <span>
                    <RaisedButton label="Save" disabled={!newpassdirty} onClick={updatePassword} primary={true} style={styles.loginBtn} />
                  </span>
                </div>
              </form>
            </Paper>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

UserChangePasswordPage.propTypes = {
   isResetPassword: PropTypes.bool,
};

export default UserChangePasswordPage;

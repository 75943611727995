import React, { useState, useEffect } from 'react';
import PropertiesPaginationSlider from './PropertiesPaginationSlider';
import {
  ListItem,
  Paper,
  Button,
  Typography,
  Grid,
  Divider,
} from '@material-ui/core';

const typographyPagesStyle = {
  fontSize: '12px',
  backgroundColor: '#A7A9AC',
  color: '#ffffff',
  fontFamily: 'Ebrima',
};

export default function ApplicationSearchPagination(props) {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [selectedSize, setSelectedSize] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [buttonCount, setButtonCount] = useState([]);
  const [buttonBegin, setButtonBegin] = useState(1);
  const [buttonEnd, setButtonEnd] = useState(4);

  const { pages, dispatchPaginationSelect, recordCount, selectPageSize, SelectedPageSize } = props;

  const createPagination = () => {
    const numberOfPages = pages ? pages.length : 0;
    const buttonArr = [];

    if (numberOfPages > 0) {
      if (numberOfPages > 3) {
        for (let i = buttonBegin; i < buttonEnd; i++) {
          buttonArr.push(i);
        }
      }
      if (numberOfPages <= 3) {
        for (let i = 0; i < numberOfPages; i++) {
          buttonArr.push(i+1);
        }
      }
    }
    setNumberOfPages(numberOfPages);
    setButtonCount(buttonArr);
  };

  const paginationSelect = (pageNumber) => {
    setSelectedPage(pageNumber);
    dispatchPaginationSelect(pageNumber);
  }

  const incrementPagination = () => {
    if (buttonEnd < numberOfPages) {
      setButtonBegin(buttonBegin + 1);
      setButtonEnd(buttonEnd + 1);
    }
  };

  const decrementPagination = () => {
    if (buttonBegin > 1) {
      setButtonBegin(buttonBegin - 1);
      setButtonEnd(buttonEnd - 1);
    }
  };

  const retrieveResultSize = (selectedSize) => {
    setSelectedSize(selectedSize);
  };

  useEffect(() => {
    createPagination();
  }, [pages, buttonEnd]);

  return (
    <Grid container direction='row' style={{ backgroundColor: '#66BFFA' }}>
      <Grid item xs={12} style={{ padding: '2%' }}>
        <Grid container direction={'row'} alignItems={'center'} justify={'center'} style={{backgroundColor: '#A7A9AC'}}>
          <Grid item>
            <Typography align={'center'} style={typographyPagesStyle}>Pages: </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems={'center'} justify={'space-evenly'} direction={'row'}>
          <Grid item xs={2}>
            <Button onClick={() => decrementPagination()}>-</Button>
          </Grid>
          { buttonCount.map(pageNumber =>
            <Grid item xs={2}>
              <Button
                value={ pageNumber }
                style={ selectedPage == pageNumber ? { textDecoration: 'underline', fontFamily: 'Ebrima', } : { fontFamily: 'Ebrima', } }
                onClick={ () => paginationSelect(pageNumber) }>
                { pageNumber }
              </Button>
            </Grid>
          ) }
          <Grid item xs={2}>
            <Button onClick={ () => incrementPagination() }>+</Button>
          </Grid>
        </Grid>
        <Grid container direction={'row'} alignItems={'center'} justify={'center'} style={{ backgroundColor: '#A7A9AC' }}>
          <Grid item>
            <Typography align={'center'} style={typographyPagesStyle}>Results:</Typography>
          </Grid>
        </Grid>
        <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
          <Grid item style={{ display: 'contents' }}>
            <PropertiesPaginationSlider 
              recordCount={recordCount}
              selectPageSize={selectPageSize}
              retrieveResultSize={retrieveResultSize}
              selectedPageSize={SelectedPageSize}
            />
          </Grid>
        </Grid>
        <Grid container direction={'row'} alignItems={'center'} justify={'center'} style={{backgroundColor: '#A7A9AC'}}>
          <Grid item>
            <Typography align={'center'} style={typographyPagesStyle}>{selectedSize} results on {pages ? numberOfPages : 1} pages</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

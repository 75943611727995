import React, { useState, useEffect } from 'react';
import {
  Typography, 
  Avatar,
  Grid,
  Chip, 
} from '@material-ui/core';
import HeaderAvatarStyle from '../../../styles/HeaderAvatarStyle';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(2),
      maxHeight: '35px',
      color:'#FFFFFF',
      width:'100%',
      fontFamily: 'Ebrima',
    },
    fab: {
        margin: theme.spacing(1),
        color: '#CCC111',
        fontFamily: 'Ebrima',
      },
      extendedIcon: {
        marginRight: theme.spacing(1),
        fontFamily: 'Ebrima',
      },
      root: {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Ebrima',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(0.5),
        },
        fontFamily: 'Ebrima',
      },
  }));
  

export default function HeaderAvatar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userInitials, setUserInitials] = useState('');
  const classes = HeaderAvatarStyle();
  const {
    avatarContainer,
  } = classes;
  
  const { 
    handleLogOut,  
    profilePhotoUrl, 
    handleProfileClick, 
    profile, 
    userName,
    mobile, 
  } = props;

  const handleClick = (event) => {
    const currentTarget = event.currentTarget;
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (userName) {
      if (userName.length >= 2) {
        const userNameArray = userName.split(' ');
        const initialsArray = [];
        userNameArray.map(name => {
          const firstLetter = name.charAt(0);
          initialsArray.push(firstLetter);
        })
        const initials = initialsArray.join('');
        setUserInitials(initials);
      }
    }
  }, [userName]);

  return (
    <div>
      { 
        mobile ? 
        <Grid container direction='row' alignItems='center' justify='flex-end'>
          <Grid item xs={10}>
            <Avatar onClick={handleClick} src={ profilePhotoUrl }>{ profilePhotoUrl ? profilePhotoUrl : userInitials }</Avatar>
          </Grid>
          <Grid item xs={10}>
            <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={handleProfileClick} style={{ fontFamily: 'Ebrima',}}>Profile</MenuItem>
              <MenuItem onClick={handleLogOut} style={{ fontFamily: 'Ebrima',}}>Logout</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      :
        <Grid container alignItems="flex-start">
          <div className={ avatarContainer }>
            <Chip 
              avatar={ 
                profilePhotoUrl && userName ? 
                  <Avatar
                    className={ classes.avatar }
                    alt={ userName != undefined ? userName : '' }
                    src={ profilePhotoUrl }  
                    profile={ profile }
                  /> 
                :
                  <Avatar className={classes.noimageavatar}>
                    { userName != undefined ? userName[0] : '' }
                  </Avatar>
              } 
              label={ userName != undefined && userName.length>0 ? userName : '' } 
              aria-owns={ anchorEl ? 'simple-menu' : '' }
              aria-haspopup="true"
              onClick={handleClick} 
            />
            <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={ handleProfileClick } style={{ fontFamily: 'Ebrima',}}>Profile</MenuItem>
              <MenuItem onClick={ handleLogOut } style={{ fontFamily: 'Ebrima',}}>Logout</MenuItem>
            </Menu>
          </div>
        </Grid>
      }
    </div>
  );
}
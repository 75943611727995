import React, { useState, useEffect } from 'react';
import LeakResponseUploadLoading from './Subcomponents/LeakResponseUploadLoading';
import LeakResponseComments from './Subcomponents/LeakResponseComments';
import LeakResponseReviewMap from './Subcomponents/LeakResponseReviewMap';
import LeakResponseReviewDocumentsCard from './Subcomponents/LeakResponseReviewDocumentCard';
import {
  Grid,
  Typography,
  Card,
  Button,
  Paper,
  List,
} from '@material-ui/core';

const COMPONENT_LEAK_RESPONSE_STEP = 4;

function RequestLeakResponse(props) {
  const [ backSelected, setBackSelected ] = useState(false);
  const [ emergency, setEmergency ] = useState(false);
  const [ description, setDescription ] = useState('');
  const [ address, setAddress ] = useState('');
  const [ buildingName, setBuildingName ] = useState('');
  const [ property, setProperty ] = useState({});
  const [ requester, setRequester ] = useState({});
  const [ propertyContact, setPropertyContact ] = useState({});
  const [ leakLocations, setLeakLocations ] = useState([]);
  const [ files, setFiles ] = useState([]);
  const [ previews, setPreviews ] = useState([]);
  const [ uploading, setUploading ] = useState(false);
  const [ comment, setComment ] = useState('');
  const {
    handleIncrementWizardStep, 
    handleSubmitLeakResponse,
    handleNavigateToLeakResponseList,
    handleUpdateComment,

    leakResponse,
    editedLeakResponse, 
    uploadProgress,   
    leakResponseComments,
    selectedDocuments,
    width,
  } = props;

  const handleBackClick = () => {
    setBackSelected(true);
  };

  const handleSubmitClick = () => {
    handleSubmitLeakResponse();
  };

  const handleAddComment = (comment) => {
    setComment(comment);
  };

  useEffect(() => {
    if (comment.length) {
      handleUpdateComment(comment);
    }
  }, [comment]);

  useEffect(() => {
    if (editedLeakResponse) {
      const {
        Emergency,
        Description,
        Locations,
      } = editedLeakResponse;
      setEmergency(Emergency);
      setDescription(Description);
      setLeakLocations(Locations);
    }
  }, [editedLeakResponse]);

  useEffect(() => {
    if (leakResponse) {
      const leakResponseExists = Object.values(leakResponse).length;
      if (leakResponseExists) {
        const {
          Property,
          Files,
          Requester,
          PropertyContact,
        } = leakResponse;
        setProperty(Property);
        setFiles(Files);
        setRequester(Requester);
        setPropertyContact(PropertyContact);
      }
    }
  }, [leakResponse]);

  useEffect(() => {
    if (Object.values(property).length) {
      const {
        Name, 
        Address1,
        Address2,
        City,
        Zip,
        StateName,
        CountryName,
      } = property;
      const address = `${Address1} ${Address2} ${City}, ${StateName} ${Zip}`;
      setBuildingName(Name);
      setAddress(address);
    }
  }, [property]);

  useEffect(() => {
    if (selectedDocuments.length) {
      const previews = [];
      selectedDocuments.map((selectedDocument) => {
        const name = selectedDocument.name;
        const type = selectedDocument.type;
        const url = URL.createObjectURL(selectedDocument);
        const previewObject = {
          name: name,
          type: type,
          url: url,
        };
        previews.push(previewObject);
      }, setPreviews(previews))
    }
  }, [selectedDocuments]);

  useEffect(() =>{
    if (uploadProgress) {
      setUploading(true);
    }
  }, [uploadProgress]);

  useEffect(() => {
    if (backSelected) {
      const backStep = COMPONENT_LEAK_RESPONSE_STEP - 1;
      handleIncrementWizardStep(backStep);
    }
  }, [backSelected]);

  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%', width: '100%' }}>
      <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
        <Card square style={{ boxShadow: 'none', padding: '2%' }}>
          
          <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>
            { uploading ?
              <LeakResponseUploadLoading
                handleNavigateToLeakResponseList={handleNavigateToLeakResponseList}
                uploadProgress={uploadProgress}
                files={files}
              />
            : 
              <Grid container direction='row' alignItems='center' justify='center'>
                <Grid item xs={12} style={{ height: '85%', marginBottom: '2%', }}>
                  <Grid container direction='row'>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Is this an Emergency Leak?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      { emergency ? 'Yes' : 'No' }
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Describe Request</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      { description }
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Property</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      { buildingName }
                      { address }
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Requester</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {requester.Name}
                      {requester.Phone}
                      {requester.Email}
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Property Contact</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {propertyContact.Name}
                      {propertyContact.Phone}
                      {propertyContact.Email}
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Leak Locations</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <Grid container direction='row' alignItems='center' justify='center'>
                        <Grid item xs={12} style={{ height: '20rem', width: '100%' }}>
                          <LeakResponseReviewMap
                            leakResponse={leakResponse}
                            editedLeakResponse={editedLeakResponse}
                            leakLocations={leakLocations}
                            width={width}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={12}>
                      <Typography variant='h6' style={{fontFamily: 'Ebrima',}}>Documents</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction='row' alignItems='flex-start' justify='flex-start'>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                          <List style={{ height: '20rem', overflow: 'auto', fontFamily: 'Ebrima', }}>
                            {files.map((file) => {
                              return (
                                <LeakResponseReviewDocumentsCard
                                  file={file}
                                />
                              )
                            })}
                          </List>
                        </Grid>
                      </Grid>
                     
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={12}>
                      <LeakResponseComments 
                        handleAddComment={handleAddComment}
                        leakResponse={leakResponse}
                        leakResponseComments={leakResponseComments}
                      />
                    </Grid>
                  </Grid>
                      
                </Grid>
              </Grid>
            }
            <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA', position: 'fixed', bottom: '1%', left: '1.5%', width: '97.5%' }}>
              <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
                <Grid container direction='row' alignItems='center' justify='space-evenly' style={{ height: '100%' }}>
                  <Grid item xs={3} style={{ textAlign: 'center', padding: '5px' }}>
                    <Button variant='contained' color='default' style={{ boxShadow: 'none' }} onClick={ () => handleBackClick() }>Back</Button>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: 'center', padding: '5px' }} >
                    <Button variant='contained' color='secondary' onClick={handleSubmitClick}>Submit</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
};

export default RequestLeakResponse
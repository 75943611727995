import React,  { Component } from 'react';
import SiteFilterContainer from '../SiteFilter/SiteFilterContainer';
import ApplicationFeedBackModal from './ApplicationFeedback/ApplicationFeedbackModal';
import {
  Grid,
  Paper,
} from '@material-ui/core';

class ApplicationContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { 
      handleCloseFeedBackModal,
      siteFilterSelect,

      contentId, 
      siteFilterOpen, 
      sidebarOpen, 
      feedbackOpen,
    } = this.props;

    const {
      width,
      height
    } = this.state;

    const sidebarOpenStyle = {
      backgroundColor: '#f6f3eb',
      position: 'absolute',
      // left: '240px',
      width: '100%',
      height: '100%', 
    };

    const sidebarClosedStyle = {
      backgroundColor: '#f6f3eb',
      position: 'absolute',
      // left: '10px',
      width: '100%',
      height: '100%',
    };
    
    return (
      <Grid container direction={'row'} style={sidebarOpen ? { height: '90%', width: '83%', position: 'absolute', left: '250px', top: '70px' } : { height: '90%', width: '97%', position: 'absolute', left: '6px', top: '70px' }}>
          <Grid item xs={12}>
            <div id={contentId} style={sidebarOpen ? sidebarOpenStyle : sidebarClosedStyle}>
              <SiteFilterContainer
                siteFilterOpen={siteFilterOpen}
                siteFilterSelect={siteFilterSelect}
              />
              <ApplicationFeedBackModal 
                feedbackOpen={feedbackOpen} 
                handleCloseFeedBackModal={handleCloseFeedBackModal} 
              />
            </div>
          </Grid>
        </Grid>      
    )
  }
}

export default ApplicationContent

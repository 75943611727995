import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Divider
} from '@material-ui/core';
import ApplicationSidebarStyle from '../../../styles/ApplicationSidebarStyle';
import SvgUsers from '../../SvgIcons/SvgUsers';
import SvgDashboard from '../../SvgIcons/SvgDashboard';
import SvgReports from '../../SvgIcons/SvgReports';
import SvgTags from '../../SvgIcons/SvgTags';
import SvgWorkOrders from '../../SvgIcons/SvgWorkOrders';
import SvgProperties from '../../SvgIcons/SvgProperties';
import SvgContacts from '../../SvgIcons/SvgContacts';
import SvgDocs from '../../SvgIcons/SvgDocs';
import SvgSiteMap from '../../SvgIcons/SvgWorldMap';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Folder from '@material-ui/icons/Folder';
import TrendingFlatOutlined from '@material-ui/icons/TrendingFlatTwoTone';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { white } from '@material-ui/core/colors';


function ApplicationSidebarMenus(props) {
  const classes = ApplicationSidebarStyle();
  const { listItem, list } = classes;
  const { menus, selectRoute } = props;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };


  return (
    <div >
      <List className={ list }>
        <Divider />
        {
          menus.map((menu, i) => {
            const title = menu.Title;
            const route = menu.Route;
            const icon = menu.Icon;
            const submenus = menu.SubMenus?menu.SubMenus:[];

            if(menu.SubMenus != null){

              return(
              <div key={"divmenu"+i}>
              <ListItem button onClick={handleClick}>
                <SvgReports props={props}/>
                <ListItemText className={ listItem } primary={ title } classes={{primary:classes.list}} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {menu.SubMenus.map((submenu, i) => {
                    const stitle = submenu.Title;
                    const sroute = submenu.Route;
                    const sicon = submenu.Icon;
                  return(
                    
                       <ListItem key={i} onClick={() => selectRoute(sroute, stitle)} style={{border:'solid #ffffff 0px'}}  button >
                        <ListItemIcon>
                        {sicon === "MoveProperty"?<TrendingFlatOutlined style={{ marginLeft:20, color: '#ffffff'}}  /> :"" }
                        {sicon === "Folder"?<Folder style={{marginLeft:20, color: '#ffffff'}}  /> :"" }
                          
                        </ListItemIcon>
                        <ListItemText primary={stitle} classes={{primary:classes.listItem}}/>
                      </ListItem>
                      )
        
                })}
                </List>
              </Collapse>
              <Divider />
            </div>

              )
              
            }else{
           
            return (
                <div key={"divmenu"+i}>
                    <ListItem key={i} onClick={() => selectRoute(route, title)} style={{border:'solid #ffffff 0px'}} button={true}>
                          {icon === "Dashboard"?<SvgDashboard props={props} />:"" }
                          {icon === "ManageUsers"?<SvgUsers props={props} /> :"" }
                          {icon === "ManageContacts"?<SvgContacts props={props} /> :"" }
                          {icon === "Properties"?<SvgProperties props={props} /> :"" }
                          {icon === "Documents"?<SvgDocs props={props}/> :"" }
                          {icon === "SiteMap"?<SvgSiteMap props={props}/> :"" }
                          {icon === "TagManager"?<SvgTags props={props}/> :"" }
                          {icon === "Reports"?<SvgReports props={props}/> :"" }
                          {icon === "WorkOrders"?<SvgWorkOrders props={props}/> :"" }
                          <ListItemText primary={ title } classes={{primary:classes.listItem}} />

                   </ListItem>
                  <Divider />
                </div>
              )
            }
          })
        }
        </List>
    </div>
  )
};

export default ApplicationSidebarMenus
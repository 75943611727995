import { makeStyles } from '@material-ui/core/styles';

const SiteFilterTagsStyle = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    width: '100%',
    height: '78%',
    overflow: 'auto',
    fontFamily: 'Ebrima',
  },
  table: {
    minWidth: 350,
    fontFamily: 'Ebrima',
  },
}));

export default SiteFilterTagsStyle
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import uuid from 'react-uuid'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useProjectPhaseStyles } from '../../../styles/ProjectPhaseStyle';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import produce from "immer";

const options = ['Option 1', 'Option 2'];


const onDragEnd = (result, columns, setColumns, dispatch) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.Project];
    const destItems = [...destColumn.Project];
    const projectId = sourceItems[source.index].ProjectId;
    const previosPhase = sourceColumn.Phase.PhaseItemId;
    const nextPhase = destColumn.Phase.PhaseItemId;

    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        Project: sourceItems
      },
      [destination.droppableId]: {
        ...destColumn,
        Project: destItems
      }
    });

    UpdatePhase(previosPhase, nextPhase, projectId, dispatch);
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.Project];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        Project: copiedItems
      }
    });
  }
};

const UpdatePhase = (previosPhaseIdKanban, nextPhaseIdKanban, projectId, dispatch) => {
  dispatch({
    UpdatePhaseKanban: {
      PreviosPhaseIdKanban: previosPhaseIdKanban,
      NextPhaseIdKanban: nextPhaseIdKanban,
      Id: projectId,
    },
  });
};

function LinearProgressWithLabel(props) {
  return (
    <Box style={{ width: '100%', padding: '10px', position: 'sticky', left: 0, width: '50%' }} display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>

      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const CheckBoxsTaskList = ({ classes, updateTaskListInColumns, setColumns, projectPhase, dispatch }) => {

  const [taskList, setTaskList] = useState({ checkBoxs: {} });
  const [percentCompleted, setPercentCompleted] = useState(0);
  React.useEffect(() => {
    if (projectPhase && projectPhase.PhaseTasks && projectPhase.PhaseTasks.length > 0) {
      const checkBoxs = {};
      projectPhase.PhaseTasks.map((t) => {
        checkBoxs[t.PhaseTaskId] = t.Done;
      });
      setTaskList({ ...taskList, checkBoxs });
      const percentProgress = 100 / projectPhase.PhaseTasks.length;
      const taskDone = projectPhase.PhaseTasks.filter((x) => x.Done).length;
      const currentPercent = taskDone > 0 ? percentProgress * taskDone : 0;
      setPercentCompleted(currentPercent);
    }
  }, []);

  if (Object.keys(taskList.checkBoxs).length > 0) {
    const handleChangeCheckTaskList = (phaseTaskId) => {
      return () => {
        setTaskList({
          ...taskList,
          checkBoxs: {
            ...taskList.checkBoxs,
            [phaseTaskId]: !taskList.checkBoxs[phaseTaskId],
          },
        });

        const percentProgress = 100 / projectPhase.PhaseTasks.length;
        if (!taskList.checkBoxs[phaseTaskId]) {
          setPercentCompleted(percentProgress + percentCompleted);
        } else {
          setPercentCompleted(percentCompleted - percentProgress);
        }
        setColumns(prev => updateTaskListInColumns(prev, phaseTaskId, !taskList.checkBoxs[phaseTaskId]));
        dispatch({
          UpdatePhaseTask: {
            Done: !taskList.checkBoxs[phaseTaskId],
            PhaseItemTaskId: phaseTaskId,
          },
        });

      };
    };


    return (<span>
      <LinearProgressWithLabel value={percentCompleted} />
      {projectPhase.PhaseTasks.map((task, index) => {
        return (<ListItem key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={taskList.checkBoxs[task.PhaseTaskId]}
                onChange={handleChangeCheckTaskList(task.PhaseTaskId)}
                color="primary"
              />
            }
            className={taskList.checkBoxs[task.PhaseTaskId] ? classes.strikeThrough : ''}
            label={task.Description}
          />
        </ListItem>);
      })
      }
    </span>
    );
  } else {
    return (<ListItem><ListItemText primary="No tasks" /></ListItem>);
  }
}


const AutoCompleteTreeNode = ({ setProjectSelectedPercent, level, projectNodes, classes, dispatch }) => {

  const [projectFilterValue, setProjectFilterValue] = React.useState([]);
  const [projectIndexSelected, setProjecIndextSelected] = React.useState(-1);

  const handleChangeProjectFilter = (e, obj) => {
    e.stopPropagation();
    setProjectFilterValue(obj || []);
    setProjecIndextSelected(projectNodes.findIndex((x) => x.Project.ProjectId == obj.Project.ProjectId));
    if (obj.Children.length === 0) {
      dispatch({
        AddProjectChildren: {
          Id: obj.Project.ProjectId,
          OnlyFolders: true,
        }
      });
    }
    dispatch({
      GetProjectKanban: {
        Id: obj.Project.ProjectId,
        PhaseTemplateId: obj.Project.PhaseTemplateId,
      }
    });
    setProjectSelectedPercent(obj.Project.PercentComplete);
  };

  if (projectNodes && projectNodes.length > 0) {
    return (
      <React.Fragment key={level}>
        <Autocomplete
          className={classes.autocompleteCombo}
          id="project-filter"
          onChange={handleChangeProjectFilter}
          value={projectFilterValue || []}
          options={projectNodes}
          getOptionLabel={(option) => option && option.Project && option.Project.Title ? option.Project.Title : ''}
          style={{ width: 300 }}
          renderInput={(params) =>  <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}} className={classes.autocompleteTextboxFilters} {...params} label="Select project" variant="outlined" />}
        />
        {(projectIndexSelected > -1 && projectNodes[projectIndexSelected].Children.length > 0) &&
          <AutoCompleteTreeNode setProjectSelectedPercent={setProjectSelectedPercent} level={level + 1}
            dispatch={dispatch} classes={classes} projectNodes={projectNodes[projectIndexSelected].Children} />}
      </React.Fragment>
    );
  } else {
    return (<React.Fragment />);
  }
}

export default function Board({ projectPercentCompleted, rootProjects, properties, isLoadingProjects, projectsKanban, dispatch, navRoutes }) {
  const [showModal, setShowModal] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useProjectPhaseStyles();
  const [projectPhaseSelected, setProjectPhaseSelected] = React.useState({});
  const [projectSelected, setProjectSelected] = React.useState({});
  const [guidColumnsSelected, setGuidColumnsSelected] = React.useState({});
  const [columns, setColumns] = useState(projectsKanban);
  const [projectSelectedPercent, setProjectSelectedPercent] = React.useState(0);

  React.useEffect(() => {
    if (projectsKanban && projectsKanban.length > 0) {
      setColumns(Object.assign({}, projectsKanban));
    }
  }, [projectsKanban]);

  React.useEffect(() => {
    setProjectSelectedPercent(projectPercentCompleted);
  }, [projectPercentCompleted]);


  const handleEditProject = (id) => {
    return () => {
      window.location.replace(
        navRoutes.find((x) => x.Title == 'AddProject').Route.RedirectRoot + '/' + id + '?child'
      );
    };
  };

  const handleShowTaskList = (project, guid) => {
    return () => {
      const projectPhase = project.ProjectPhases.filter((x) => x.PhaseItemId == columns[guid].Phase.PhaseItemId)[0];
      setGuidColumnsSelected(guid);
      setProjectSelected(project);
      setProjectPhaseSelected(projectPhase);
      setShowModal(true);
    }
  }


  const updateTaskListInColumns = (columns, phaseTaskId, value) => {
    const indexProject = columns[guidColumnsSelected].Project.findIndex((x) => x.ProjectId == projectSelected.ProjectId);
    const indexProjectPhases = columns[guidColumnsSelected].Project[indexProject].ProjectPhases.findIndex((y) => y.PhaseItemId == columns[guidColumnsSelected].Phase.PhaseItemId);
    const indexPhaseTask = columns[guidColumnsSelected].Project[indexProject].ProjectPhases[indexProjectPhases].PhaseTasks.findIndex((j) => j.PhaseTaskId == phaseTaskId);
    return produce(columns, draft => {
      draft[guidColumnsSelected].Project[indexProject].ProjectPhases[indexProjectPhases].PhaseTasks[indexPhaseTask].Done = value;
    });

  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOkModal = () => {
    setShowModal(false);
  };

  if (!isLoadingProjects) {
    return (
      <div>
        <div className={classes.filtersWrapper} style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', flexBasis: '100%' }}>
            <AutoCompleteTreeNode setProjectSelectedPercent={setProjectSelectedPercent} level={0} classes={classes} dispatch={dispatch} projectNodes={rootProjects} />
          </div>
          <LinearProgressWithLabel value={projectSelectedPercent} />
        </div>
        <div className={classes.dragWrapper}>
          <DragDropContext
            onDragEnd={result => onDragEnd(result, columns, setColumns, dispatch)}
          >
            {Object.entries(columns).map(([columnId, items], i) => {
              const guid = String(columnId);
              return (
                <div
                  className={classes.columnWrapper}
                  key={guid}
                >

                  <div className={classes.divColumnWrapper}>
                    <h2 className={classes.h2Column}>{items.Phase.Name}</h2>
                    <Droppable droppableId={guid} key={i}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={classes.scrollBar}
                            style={{
                              background: snapshot.isDraggingOver
                                ? "lightblue"
                                : "lightgrey",
                              padding: 4,
                              width: 250,
                              maxHeight: 500,
                              overflowY: 'scroll'
                            }}
                          >
                            {items.Project.map((project, index) => {
                              let projectTitle = properties.filter((x) => x.BuildingId == project.AssetId);
                              projectTitle = projectTitle && projectTitle.length > 0 ? projectTitle[0].Name : project.Title;
                              projectTitle = projectTitle.substring(0, 17) + '...';
                              return (
                                <Draggable
                                  key={project.ProjectId}
                                  draggableId={project.ProjectId}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: "none",
                                          padding: 16,
                                          margin: "5px 5px 8px",
                                          minHeight: "50px",
                                          borderRadius: '5px',
                                          boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.75)',
                                          position: 'relative',
                                          backgroundColor: snapshot.isDragging
                                            ? "#263B4A"
                                            : "#456C86",
                                          color: "white",
                                          ...provided.draggableProps.style
                                        }}
                                      >
                                        <div>{projectTitle}</div>
                                        <IconButton
                                          size={'small'}
                                          edge="end"
                                          aria-label="edit"
                                          className={classes.editIcon}
                                          onClick={handleEditProject(project.ProjectId)}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                        <IconButton
                                          size={'small'}
                                          edge="end"
                                          onClick={handleShowTaskList(project, guid)}
                                          aria-label="task"
                                          className={classes.taskIcon}
                                        >
                                          <ListIcon />
                                        </IconButton>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          </DragDropContext>
        </div>
        <Dialog
          fullScreen={fullScreen}
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{'Tasks'}</DialogTitle>
          <DialogContent>
            <List className={classes.scrollBar} style={{ minWidth: '500px', maxHeight: '400px', overflowY: 'scroll' }}>
              {projectPhaseSelected ? <CheckBoxsTaskList classes={classes} updateTaskListInColumns={updateTaskListInColumns} setColumns={setColumns} dispatch={dispatch} projectPhase={projectPhaseSelected}></CheckBoxsTaskList> :
                <ListItem><ListItemText primary="No tasks" /></ListItem>}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOkModal} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  } else {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
}



import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import PropertiesResults from './PropertiesResults/PropertiesResults';
import PropertyResultsImageModal from './PropertiesResults/Components/PropertyResultsImageModal';
import PropertiesDetailModal from './PropertiesResults/Components/PropertiesDetailModal';
import auth from '../../auth';
import {
  Grid,
} from '@material-ui/core';
import { debounce } from 'lodash';

class Properties extends Component {
  constructor(props) {
    super(props)
    this.vm = dotnetify.react.connect('PropertiesSearch', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
         auth.signOut();
      }
    });
    this.dispatch = state => this.vm.$dispatch(state);
    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
      //data
      Property: {},
      PropertyId: 0,
      SystemTypes: [],
      Countries: [],
      StateRegions: [],
      Results: [],
      AllResults: [],
      Pages: [],
      RecordCount: 0,
      SelectedPageSize: 10,
      NavRoutes: [],

      //dispatch
      SelectedPage: 1,
      SortType: 0,
      SortDirection: 0,
      selectedFilterMax: -1,
      selectedFilterMin: -1,
      
      //user actions
      searchText: '',
      loading: true,
      openResultsModal: false,
      openPropertyDetailModal: false,
      selectedResult: null,
      filteredResults: null,
      images: null,
      mobile: false, 
      mapViewSelected: false,
      
    };
  }

  componentDidMount = () => {
    const { Results } = this.state;

    if (Results) {
      if (Results.length == 0) {
        this.setState({
          loading: true,
        })
      }
    }
    this.handleScreenResize();
  };

  componentDidUpdate = (prevProps, prevState) => {
    window.addEventListener('resize', this.handleScreenResize);

    const { 
      PropertyId,
      AllResults,
      Results,
      loading,
      selectedFilterMax,
      selectedFilterMin,
      SortType,
      SortDirection,
    } = this.state;
    const previousSelectedFilterMax = prevState.selectedFilterMax;
    const previousSelectedFilterMin = prevState.selectedFilterMin;
    const previousSortType = prevState.SortType;
    const previousSortDirection = prevState.SortDirection;
    const previousPropertyId = prevState.PropertyId;
    const previousProperty = prevState.Property;

    if (PropertyId) {
      if (PropertyId != previousPropertyId) {
        if (PropertyId > 0) {
          const propertyInList = AllResults.find(result => result.Id == PropertyId);
          if (propertyInList) {
            this.setState({
              openPropertyDetailModal: true,
            }, this.selectResult(propertyInList));
          }
        }
      }
    }

    //FOR DEWAYNE:
    // <-------------------------------------------------------------------->

    //if the current and previous value of the sort type do not match
    if (SortType != previousSortType) {
      //if there is a value that has been set, pass it to the back end
      if (SortType) {
        // console.log('sortType dispatch')
        // console.log(SortType)
        this.dispatchSortType(SortType);
      } else {
        // console.log('sortType dispatch')
        // console.log(0)
        //if user was deleting input values and sort type gets set to null, pass 0
        this.dispatchSortType(0);
      }
    }

    //if the current and previous value of the sort direction do not match
    if (SortDirection != previousSortDirection) {
      //if there is a value that has been set, pass it to the back end
      if (SortDirection) {
        // console.log('sortDirection dispatch')
        // console.log(SortDirection)
        this.dispatchSortDirection(SortDirection);
      } else {
        // console.log('sortDirection dispatch')
        // console.log(0)
          //if user was deleting input values and sort direction gets set to null, pass 0 
        this.dispatchSortDirection(0);
      }
    }

    //if the current and previous values of the max filter do not match
    if (selectedFilterMax != previousSelectedFilterMax) {
      //if  there is a value that has been set, pass it to the back end
      if (selectedFilterMax) {
        // console.log('dispatchMax dispatch')
        // console.log(selectedFilterMax)
        this.dispatchMax(selectedFilterMax);
      } else {
        // console.log('dispatchMax dispatch')
        // console.log(-1)
        //if user was deleting input values and selectedFilterMax gets set to null or 0, pass negative 1
        this.dispatchMax(-1)
      }
    }

    //if the current and previous values of the min filter do not match
    if (selectedFilterMin != previousSelectedFilterMin) {
      //if  there is a value that has been set, pass it to the back end
      if (selectedFilterMin) {
        // console.log('dispatchMin dispatch')
        // console.log(selectedFilterMin)
        this.dispatchMin(selectedFilterMin);
      } else {
        // console.log('dispatchMin dispatch')
        // console.log(-1)
        //if user was deleting input values and selectedFilterMin gets set to null or 0, pass negative 1
        this.dispatchMin(-1);
      }
    }
    // <-------------------------------------------------------------------->


    const {
      mobile,
      mapViewSelected,
      selectedResult, 
      Property,
      selectedReportType,
      sowReportLoading,
      carReportLoading,
      ReportUrl,
    } = this.state;
    const previousMobile = prevState.mobile;
    const previousSelectedResult = prevState.selectedResult;
    const previousReportUrl = prevState.ReportUrl;

    if (mobile != previousMobile) {
      if (mapViewSelected) {
       this.setState({
         mapViewSelected: false,
       })
      }
    }

    //to disable header and buttons on pick of different property
    if (loading) {

      if (Results && Results.length > 0) {
        if (!selectedResult) {
          this.setState({
            loading: false,
          })
        }

        if (selectedResult) {
          if (Property) {
            const selectedResultId = selectedResult.Id;
            const propertyId = Property.Id;
            if (selectedResultId != propertyId) {
              this.setState({
                loading: true,
              })
            }
          }
        }
      }
    }
    

    //handles report loading
    if (ReportUrl != previousReportUrl) {
      if (sowReportLoading && selectedReportType) {
        if (selectedReportType == 2) {
          this.setState({
            sowReportLoading: false,
            selectedReportType: null,
            sowReportUrl: ReportUrl,
          })
        }
      }

      if (carReportLoading && selectedReportType) {
        if (selectedReportType == 3) {
          this.setState({
            carReportLoading: false,
            selectedReportType: null,
            carReportUrl: ReportUrl,
          })
        }
      }
    }
   

    //disable entire component for mistaken click (on property)
    if (Property) {
      if (selectedResult) {
        const propertyId = Property.Id;
        const selectedResultId = selectedResult.Id;

        if (Property != previousProperty) {
          if (PropertyId != selectedResultId) {
            if (!loading) {
              this.setState({
                loading: true,
              })
            }
          }

          if (propertyId == selectedResultId) {
            if (loading) {
              this.setState({
                loading: false,
              })
            }
          }
        }

        if (Property == previousProperty) {
          if (selectedResult == previousSelectedResult) {
            if (loading) {
              this.setState({
                loading: false,
              })
            }
          }
        }
      }
    }
  };

  handleScreenResize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 1279) {
      this.setState({
        mobile: true,
      });
    } else {
      this.setState({
        mobile: false,
      });
    }
  };

  handleMapToggle = () => {
    const { mapViewSelected } = this.state;
    this.setState({
      mapViewSelected: !mapViewSelected,
    })
  };

  handleSearch = (text) => {
    const { searchText } = this.state;
    if (searchText != text) {
      this.setState({
        searchText: text,
      }, this.dispatchSearch(text)
      // , console.log('dispatchSearch'), console.log(text)
      )
    }
  };

  dispatchSearch = debounce(SearchText => {
    // console.log('dispatchSearch DEBOUNCE'), console.log(searchText)
    this.dispatch({ SearchText: SearchText });
  }, 400);

  selectResult = (result) => {
    if (result) {
      const propertyId = result.Id;
      this.setState({
        selectedResult: result,
      }, this.dispatch({ GetProperty: propertyId })
      // , console.log('dispatch GetProperty'), console.log(propertyId)
      );
    } else {
      this.setState({
        selectedResult: {},
      }, this.dispatch({ GetProperty: 0 })
      // , console.log('dispatch GetProperty'), console.log(0)
      )
    }
  };

  handleSortTypeDispatch = (sortType) => {
    this.setState({
      SortType: sortType,
    });
  };

  dispatchSortType = (sortType) => {
    // console.log('dispatch SortType'), console.log(sortType)
    this.dispatch({ SortType: sortType })
  };

  handleSortDirectionDispatch = (sortDirection) => {
    this.setState({
      SortDirection: sortDirection,
    });
  };

  dispatchSortDirection = (sortDirection) => {
    // console.log('dispatch SortDirection'), console.log(sortDirection)
    this.dispatch({ SortDirection: sortDirection })
  };

  handleDispatchMax = (max) => {
    this.setState({
      selectedFilterMax: max,
    });
  };

  dispatchMax = debounce(max => {
    // console.log('dispatch DispatchMax DEBOUNCE'), console.log(max)
    this.dispatch({ Max: max })
  }, 400);

  handleDispatchMin = (min) => {
    this.setState({
      selectedFilterMin: min,
    });
  };

  dispatchMin = debounce(min => {
    // console.log('dispatch DispatchMax DEBOUNCE'), console.log(max)
    this.dispatch({ Min: min })
  }, 400);

  dispatchPaginationSelect = (SelectedPage) => {
    this.setState({
      SelectedPage,
    }, this.dispatch({ SelectedPage: SelectedPage })
    // , console.log('SelectedPage Dispatch'), console.log(SelectedPage)
    );
  };

  handleDispatchGetRCS = (inspectId, sectionId) => {
    // console.log('dispatch RCS Details')
    // console.log(inspectId, sectionId)
    this.dispatch({ GetRCS: { InspectId: inspectId, SectionId: sectionId } });
  };

  handleOpenModal = (images) => {
    const { openResultsModal } = this.state;
    this.setState({
      openResultsModal: !openResultsModal,
      images,
    })
  };

  handleOpenDetailModal = () => {
    const { openPropertyDetailModal, NavRoutes } = this.state;
    this.setState({
      NavRoutes: NavRoutes,
      openPropertyDetailModal: !openPropertyDetailModal,
    },this.vm.$routeTo(NavRoutes[0].Route))
  };

  handleDispatchReport = (selectedReportType) => {
    if (selectedReportType) {
      const sowReportType = 2;
      const carReportType = 3;

      if (selectedReportType == sowReportType) {
        this.setState({
          sowReportLoading: true,
          selectedReportType,
        }, () => this.dispatchSOWReport()
        // , console.log('SOWReport Dispatch')
        );
      }

      if (selectedReportType == carReportType) {
        this.setState({
          carReportLoading: true,
          selectedReportType,
        }, () => this.dispatchCARReport()
        // , console.log('CARReport Dispatch')
        );
      }
    }
  };

  dispatchSOWReport = () => {
    const { 
      Property,
    } = this.state;
    const selectedPropertyId = Property.Id;
    
    const bfReport = {
      Properties: [selectedPropertyId],
      ReportType: 2,
      Publish: true,
    };

    this.setState({
      ReportOpen: true
    }, this.dispatch({ BFReport: bfReport })
    // , console.log('BFReport  Dispatch SOW'), console.log(bfReport)
    );
  };

  dispatchCARReport = () => {
    const {
      Property,
    } = this.state;
    const selectedPropertyId = Property.Id;

    const bfReport = {
      Properties: [selectedPropertyId],
      ReportType: 3,
      Publish: true,
    };

    this.setState({
      ReportOpen: true
    }, this.dispatch({ BFReport: bfReport })
    // , console.log('BFReport Dispatch CAR'), console.log(bfReport)
    );
  };
 
  handleEditExecutiveSummary = (summary) => {
    // console.log('handleEditExecutiveSummary CALLED');
    // console.log(summary)
    this.dispatch({ SetExecutiveSummary: summary });
  };

  selectPageSize = (pageSize) => {
    this.setState({
      SelectedPageSize: pageSize,
    }, this.dispatch({ SelectedPageSize: pageSize })
    // , console.log('SelectedPageSize Dispatch'), console.log(pageSize)
    )
  };

  componentWillUnmount = () => {
    console.log('unmounting PROPERTIES');
    this.vm.$destroy();
  };

  render() {
    const {
      Results,
      Pages,
      loading,
      openResultsModal,
      openPropertyDetailModal,
      images,
      selectedResult,
      mobile,
      mapViewSelected,
      Property,
      RecordCount,
      SelectedPageSize,
      NavRoutes,
      UserRank,
      MaterialType,
      reportLoading,
      sowReportUrl,
      carReportUrl,
      sowReportLoading,
      carReportLoading,
    } = this.state;

    return (
      <Grid id='properties-container' container direction={'row'} justify={'center'} alignItems={'center'} style={{ position: 'absolute', height: '100%', width: '100%' }}>
        <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
          <PropertyResultsImageModal
            handleOpenModal={ this.handleOpenModal }
            mobile={ mobile }
            results={ Results }
            openResultsModal={ openResultsModal }
            images={ images }
          />
          <PropertiesDetailModal 
            handleDispatchReport={this.handleDispatchReport}
            handleOpenDetailModal={this.handleOpenDetailModal}
            handleEditExecutiveSummary={this.handleEditExecutiveSummary}
            handleDispatchGetRCS={this.handleDispatchGetRCS}
            selectedResult={ selectedResult }
            property={ Property }
            images={ images }
            openPropertyDetailModal={ openPropertyDetailModal }
            loading={loading}
            userRank={UserRank}
            materialType={MaterialType}
            mobile={mobile}
            reportLoading={reportLoading}
            sowReportUrl={sowReportUrl}
            carReportUrl={carReportUrl}
            sowReportLoading={sowReportLoading}
            carReportLoading={carReportLoading}
          />
          <Grid container direction={'column'} style={{ height: '100%', width: '100%' }}>
            <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
              <PropertiesResults
                handleOpenModal={ this.handleOpenModal }
                handleOpenDetailModal={ this.handleOpenDetailModal }
                selectResult={ this.selectResult }
                dispatchPaginationSelect={ this.dispatchPaginationSelect }
                handleSearch={ this.handleSearch }
                handleMapToggle={ this.handleMapToggle }
                selectPageSize={ this.selectPageSize }
                handleSortTypeDispatch={ this.handleSortTypeDispatch }
                handleSortDirectionDispatch={ this.handleSortDirectionDispatch }
                handleDispatchMax={ this.handleDispatchMax }
                handleDispatchMin={ this.handleDispatchMin }
                results={ Results }
                pages={ Pages }
                loading={ loading }
                mobile={ mobile }
                mapViewSelected={ mapViewSelected }
                recordCount={ RecordCount }
                SelectedPageSize={ SelectedPageSize }
                selectedResult={ selectedResult }
                property={ Property }
                materialType={MaterialType}
              />
            </Grid>
          </Grid>
        </Grid>   
      </Grid>
    )
  }
};

export default Properties
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '95%',
  },
}));

export default function AddContractorNameSelect(props) {
  const classes = useStyles();
  const [value, setValue] = useState(null);

  const {
    retrieveContractorName,
    category2Finished,
    workOrderContractor,
  } = props;

  const handleChange = e => {
    const text = e.target.value;
    setValue(text);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const prevWorkOrderContractor = usePrevious(value);
  useEffect(() => {
    if (workOrderContractor) {
      const userName = workOrderContractor.UserName;      
      setValue(userName);
    };

    if (!workOrderContractor) {
      if (prevWorkOrderContractor) {
        setValue(null);
      }
    }
  }, [workOrderContractor]);

  useEffect(() => {
    retrieveContractorName(value);
  }, [value]);

  return (
    <form className={ classes.container } noValidate autoComplete="off">
       <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
        type={'text'}
        disabled={ !category2Finished || workOrderContractor }
        value={ value ? value : '' }
        onChange={ handleChange }
        id="standard-textarea"
        label={ "Contractor Name" }
        placeholder={ "Contractor Name" }
        className={ classes.textField }
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />
    </form>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import DocumentExistingFileCard from './DocumentExistingFileCard';
import {
  Grid,
} from '@material-ui/core';
import DocumentDropZone from './DocumentDropZone';

export default function DropZoneArea(props) {
  const [existingFiles, setExistingFiles] = useState(false);
  const {
    handleFileSelect,
    document,
    uploadDisabled,
  } = props;

  useEffect(() => {
    if (document) {
      const { Id } = document;
      if (Id) {
        setExistingFiles(true);
      } else {
        setExistingFiles(false);
      }
    }
  }, [document]);
  
  return (
    <Grid container direction='row' justify='center' alignItems='center'>
      <Grid item xs={12}>
        { uploadDisabled ? 
            null 
          : 
          existingFiles ?
          <DocumentExistingFileCard
            document={document}
          />
          :
          <DocumentDropZone
            handleFileSelect={handleFileSelect}
            uploadDisabled={uploadDisabled}
          />
        }
      </Grid>
    </Grid>
  );
};
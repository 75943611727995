import React, { useState, useEffect } from 'react';
import WorkOrderAddForm from './Forms/WorkOrderAddForm';
import {
  Paper,
  Grid,
  Modal,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

export default function WorkOrdersAddModal(props) {
  const [closeAddSelect, setCloseAddSelect] = useState(false);
  const {
    //submit
    handleSubmitWorkOrder,
    //modal
    handleAddModalClose,
    workOrderAddModalOpen,
    //dispatch
    dispatchPropertyContractors,
    //selects
    propertyList,
    statusList,
    urgencyList,
    woTypeList,
    bfAdminUsers,
    contractorList,
  } = props;

  const handleModalBlur = ({ nativeEvent }) => {
    const targetId = nativeEvent.target.id;
    console.log(targetId)
    if (targetId == 'work-order-add-container') {
      handleAddModalClose();
    }
  };

  const handleAddSelectBlur = ({ nativeEvent }) => {
    const targetId = nativeEvent.target.id;
    if (!targetId) {
      setCloseAddSelect(true);
    }
  };

  return (
    <Modal open={workOrderAddModalOpen}>
      <div id={'work-order-master-add-container'} style={{ width: '100%', height: '100%' }}>
        <Grid container direction='row' alignItems='center' alignContent='center' justify='center' onClick={handleAddSelectBlur} style={{ height: '100%' }}>
          <Grid item xs={12} id={'work-order-add-container'} onClick={handleModalBlur} style={{ height: '100%' }}>
            <Paper id='paper' style={{ width: '75%', maxHeight: '48rem', overflow: 'auto', marginLeft: '12%', marginRight: '12%', marginBottom: '1%', marginTop: '1%', backgroundColor: 'rgb(246, 243, 235)' }}>
              <Grid container direction='row' style={{ width: '100%', backgroundColor: '#3f51b5' }}>
                <Grid item style={{ margin: '1%', display: 'inline-flex' }}>
                  <AddCircleOutlineIcon style={{ color: 'white' }} />
                  <Typography style={{ color: 'white' }}>Add Work Order</Typography>
                </Grid>
              </Grid>

              <WorkOrderAddForm
                //submit
                handleSubmitWorkOrder={ handleSubmitWorkOrder } 
                //modal
                handleAddModalClose={ handleAddModalClose }
                closeAddSelect={ closeAddSelect }
                setCloseAddSelect={ setCloseAddSelect }
                //dispatch
                dispatchPropertyContractors={ dispatchPropertyContractors }
                //selects
                propertyList={ propertyList }
                statusList={ statusList }
                urgencyList={ urgencyList }
                woTypeList={ woTypeList }
                bfAdminUsers={ bfAdminUsers }
                contractorList={ contractorList }
              />
                        
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}


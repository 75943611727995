import React, { useState, useEffect } from 'react';
import {
  ListItem,
  Paper,
  Typography,
  Grid,
} from '@material-ui/core';

const listItemStyle = { 
  position: 'fixed', 
  zIndex: '2', 
  height: 'fit-content', 
  width: '-webkit-fill-available', 
  padding: '0' 
};

const paperStyle = { 
  minHeight: '5rem', 
  width: '22.75rem', 
  boxShadow: 'none' 
};

const headerTypographyStyle = { 
  margin: '1%',
  fontFamily: 'Ebrima',
};

const masterGridStyle = { 
  paddingRight: '5%', 
  marginTop: '2%',
  fontFamily: 'Ebrima',
};

const gridItemStyle = { 
  cursor: 'pointer',
  fontFamily: 'Ebrima',
};

export default function ApplicationSearchPagination(props) {
  const [clicked, setClicked] = useState(0);

  const { handleSearchTypeSelect } = props;

  useEffect(() => {
    handleSearchTypeSelect(clicked);
  }, [clicked]);

  return (
    <div>
      <ListItem style={ listItemStyle }>
        <Paper style={ paperStyle }>
          <Typography variant={'h6'} style={ headerTypographyStyle }>Search Results</Typography>
          <Grid container direction={'row'} alignItems={'center'} justify={'center'} style={ masterGridStyle }>
            {/* <Grid onClick={ ()=>setClicked('All')} style={ gridItemStyle } container item md={12} lg={3} xl={3} justify={'center'}>
              <Typography style={clicked == 'All' ? {textDecoration: 'underline'} : null } button variant={'h8'} noWrap>All</Typography>
            </Grid> */}
            <Grid onClick={() => setClicked(0)} style={ gridItemStyle } container item md={12} lg={3} xl={3} justify={'flex-start'}>
              <Typography style={clicked == 0 ? { textDecoration: 'underline', fontFamily:'Ebrima', } : { fontFamily:'Ebrima', } } button variant={'h8'} noWrap>Buildings</Typography>
            </Grid>
            {/*<Grid onClick={() => setClicked(1)} style={ gridItemStyle } container item md={12} lg={3} xl={3} justify={'flex-start'}>
              <Typography style={clicked == 1 ? { textDecoration: 'underline' } : null} button variant={'h8'} noWrap>Work Orders</Typography>
          </Grid>*/}
            <Grid onClick={() => setClicked(2)} style={ gridItemStyle } container item md={12} lg={3} xl={3} justify={'flex-end'}>
              <Typography style={clicked == 0 ? { textDecoration: 'underline', fontFamily:'Ebrima', } : { fontFamily:'Ebrima', } }button variant={'h8'} noWrap>Documents</Typography>
            </Grid>
          </Grid>
        </Paper>
      </ListItem> 
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

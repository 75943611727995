import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Paper,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '96%',
    left: '.5%',
    fontFamily: 'Ebrima',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontFamily: 'Ebrima',
  },
}));

export default function PropertyDefectsSelect(props) {
  const [imagesType, setImagesType] = useState(0);
  // const {
  //   setSelectedCategory,
  // } = props;

  const onChange = (e) => {
    const type = e.target.value;
    setImagesType(type);
  };

  // useEffect(() => {
  //   setSelectedCategory(imagesType);
  // }, [imagesType]);

  const classes = useStyles();

  return (
    <Grid container direction='row'>
      <Grid xs={12}>
        <Paper style={{ width: '100%', boxShadow: 'none' }}>

          <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Property Images
            </InputLabel>
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              autoFocus
              value={imagesType}
              onChange={onChange}
              className={classes.selectEmpty}
            >
              <MenuItem value={0} style={{fontFamily: 'Ebrima',}}>{'Defects'}</MenuItem>
              <MenuItem value={1} style={{fontFamily: 'Ebrima',}}>{'Repairs'}</MenuItem>
              <MenuItem value={3} style={{fontFamily: 'Ebrima',}}>{'All'}</MenuItem>
            </Select>
          </FormControl>

        </Paper>
      </Grid>
    </Grid>
  );
}

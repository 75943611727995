import React from 'react';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Avatar,
  IconButton,
  Grid,
  ListItem,
} from '@material-ui/core';
import ManageContactsCardStyle from '../../styles/ManageContactsStyles/ManageContactsCardStyle';
import DeleteButton from './Buttons/ManageContactsBldgsDelete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EmailIcon from './Icons/EmailIcon.svg';
import PhoneIcon from './Icons/PhoneIcon.svg';
import ProfileIcon from './Icons/ProfileIcon.svg';

export default function ManageContactsBldgsCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  function handleExpandClick() { setExpanded(!expanded) };

  const { building, handleRemoveBuilding } = props;

  const {Id, Name, Add1, Add2, City, Zip, State, Country} = building;
  const { ContactType } = building;
  const contactTypeTitle = ContactType.Title;
  const classes = ManageContactsCardStyle();
  const { card, avatar, expand, expandOpen, cardContent } = classes;
  const listItemId = `${Id}-${ContactType.ContactTypeId}`;
  const initials = `${Name.charAt(0)}`;

  return (
    <ListItem key={listItemId} id={listItemId}>
      <Card className={card}>
        <CardHeader
          avatar={<Avatar className={avatar}>{initials}</Avatar>}
          title={Name}
          subheader={"Contact Type:" + contactTypeTitle}
          action={
            <div>
            <IconButton
              className={clsx(expand, { [expandOpen]: expanded })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >            
              <ExpandMoreIcon />
            </IconButton>
            <IconButton onClick={ ()=>handleRemoveBuilding(building) }>
              <DeleteButton />
            </IconButton>
            </div>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={cardContent}>
            <Grid container>
              <Grid item align={'center'} xs={4}>
              
              </Grid>
              <Grid item align={'left'} xs={4}>
                {Add1} {Add2}  
              </Grid>
            </Grid>
            <Grid container>
              <Grid item align={'center'} xs={4}>
                
              </Grid>
              <Grid item align={'left'} xs={4}>
              {City}, {State}  {Zip}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item align={'center'} xs={4}>
             
              </Grid>
              <Grid item align={'left'} xs={4}>
                {Country}
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </ListItem>
  );
}

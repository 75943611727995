import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  IconButton,
  FormControl,
  Select,
  FormHelperText,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import UndoIcon from '@material-ui/icons/Undo';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '90%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function EditType(props) {
  const [ edited, setEdited ] = useState(false);
  const [ woTypeId, setWoTypeId ] = useState(null);
  const [ text, setText ] = useState('');
  
  const classes = useStyles();
  const { 
    retrieveWorkOrderType,
    editGeneral,
    woType, 
    woTypeList, 
  } = props;

  const handleChange = (e) => {
    const id = e.target.value;
    setWoTypeId(id);
  };

  const handleUndoChange = () => {
    const initialValue = woType.WotypeId;
    if (initialValue != woTypeId) {
      setWoTypeId(initialValue);
    } 
  };
  
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if(woType) {
      const id = woType.WotypeId;
      setWoTypeId(id);
    }
  }, []);

  useEffect(() => {
    if (woTypeId) {
      const selectedType = woTypeList.find(type => type.WotypeId == woTypeId);
      const text = selectedType.Wotype1;
      setText(text);
    }
  }, [woTypeId]);

  const prevTypeId = usePrevious(woTypeId);
  useEffect(() => {
    const initialValue = woType.WotypeId;
    if (woTypeId && prevTypeId) {
      if (woTypeId != prevTypeId) {
        const selectedType = woTypeList.find(type => type.WotypeId == woTypeId);
        retrieveWorkOrderType(selectedType);
      } 
    }
    if (woTypeId != initialValue) {
      setEdited(true);
    } else {
      setEdited(false);
    }
  }, [woTypeId]);

  useEffect(() => {
    if (!editGeneral) {
      setEdited(false);
    }
  }, [editGeneral]);

  return (
    <Grid container direction='row' style={{ height: '100%', width: '100%', minHeight: '4rem'}} alignItems='center'>
      <Grid item xs={10}>
        <FormControl variant="filled" className={classes.formControl}>
          <Select
            native
            disabled={!editGeneral}
            value={ woTypeId }
            onChange={ handleChange }
            inputprops={{
              statusId: 'statusId',
              id: 'filled-status-native-simple',
            }}
          >
            { woTypeList.map((woType) => {
              const name = woType.Wotype1;
              const value = woType.WotypeId;
              return (
                <option value={value}>{name}</option>
              )
            }) }
          </Select>
          <FormHelperText style={{ marginLeft: '0' }}>Work Order Type</FormHelperText>
        </FormControl>
      </Grid>
      { edited ? 
      <Grid item xs={2} style={{ textAlign: 'center', width: '100%', marginBottom: '10%' }}>
        <IconButton size='small' color="default" aria-label="edit" onClick={() => handleUndoChange(true)}>
          <UndoIcon />
        </IconButton>
      </Grid>
      : null }
    </Grid>
  );
}

import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { blue600 } from 'material-ui/styles/colors';
import { blue100 } from 'material-ui/styles/colors';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: blue100,
  },
  barColorPrimary: {
    backgroundColor: blue600,
  },
})(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: 2,
  },
});

export default function LinearIndeterminate() {
  const classes = useStyles();
    
  return (
    <div className={classes.root}>
      <ColorLinearProgress className={classes.margin} />
    </div>
  );
}
import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DocumentAppendSelect(props) {
  const [value, setValue] = useState(0);
  const {
    handleTaskSelect,
    tasks,
  } = props;

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value != previousValue) {
      console.log('selected task value change');
      console.log(value, previousValue);
      handleTaskSelect(value);
    }
  }, [value]);

  const classes = useStyles();
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label-id">Admin Task:</InputLabel>
          <Select
            labelId="demo-simple-select-label-li"
            id="demo-simple-select-id"
            value={value}
            onChange={handleChange}
          >
          { tasks.map((task) => {
            const { Id, Title } = task;
            return (
              <MenuItem value={Id}>{Title}</MenuItem>
            )
          }) }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

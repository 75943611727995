import React, { Component } from 'react';
import { 
  Typography, 
  Grid 
} from '@material-ui/core'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ThemeDefault from '../../styles/theme-default';
import globalStyles from '../../styles/styles';

import {
    CartesianGrid,
    BarChart,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ResponsiveContainer,
    LabelList,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';


const composedChartMarginStyle = { 
    right: 15, 
    left: 55, 
    top: 15, 
    bottom: 40
};

const graphContainerStyle = {
    textAlign: 'center',
    fontFamily: 'Ebrima', 
};

const headerStyle = {
    color: 'white',
    fontFamily: 'Ebrima',
  };

const XAxisTickFormatter = (value) => {
  return `${new Intl.NumberFormat('en').format(value)}`;
};

const YAxisTickFormatter = (value) => {
    return `${value.substring(0,10)}`;
  };

const CustomTooltipContent = (props) => {
    const payload = props.payload;

    if (props.active) {
        const newPayload = payload; 

        if (payload) {
            payload.map((entry, i) => {
                const dataKey = entry.dataKey;
                return entry.formatter = (value) => XAxisTickFormatter(value);
            })    
        }

        return <DefaultTooltipContent {...props} payload={newPayload} />
    } else {
        return <DefaultTooltipContent {...props} payload={payload}/>
    }
};

class ManufacturerDistribution extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        
        const { mfgUnitList } = this.props;

        return (
            <div id='graph-container' style={ graphContainerStyle }>
              <Grid container direction='row' style={{ width: '100%', backgroundColor: 'rgb(6, 114, 186)' }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography style={headerStyle} variant='h6'># of Units per Manufacturer</Typography>
                </Grid>
              </Grid>
              <ResponsiveContainer width='96%' height='100%' aspect={12.0 / 5.0} >
                  <BarChart 
                      width={730} 
                      height={250} 
                      data={ mfgUnitList }
                      layout='vertical'
                      style={{fontFamily: 'Ebrima', }}
                      margin={ composedChartMarginStyle }
                  >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type='number' tickFormatter={ XAxisTickFormatter }/>
                      <YAxis type='category' dataKey="Name" tickFormatter={YAxisTickFormatter} angle={-45} textAnchor="end"/>
                      <Tooltip content={ <CustomTooltipContent style={{fontFamily: 'Ebrima', }} props={ mfgUnitList } /> } style={{fontFamily: 'Ebrima', }} />
                      <Legend style={{fontFamily: 'Ebrima', }} />
                      <Bar barSize={20} dataKey="Value" name="# of units per manufacturer" fill="#00C2F3" style={{fontFamily: 'Ebrima', }} >
                        
                      </Bar>
                  </BarChart>

              </ResponsiveContainer>
            </div>
        );
    }
}

export default ManufacturerDistribution;
import React, { useState, useEffect } from 'react';
import ScriptLoaded from '../../../../../../GoogleMaps/LoadScript/LoadScript';
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  List,
  ListItem,
  ListSubheader,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

export default function WorkOrderDefectItems(props) {
  const [ sectionDefects, setSectionDefects ] = useState([]);
  const [ selected, setSelected ] = useState([]);
  const { 
    propertyDefects, 
    propertySections, 
    editingWorkOrderItem, 
    editedWorkOrderItem,
    retrieveSelectedWorkOrderDefects,
    currentStep, 
    previousCurrentStep, 
  } = props;
  const classes = useStyles();

  const handleSelect = (sectionDefect) => {
    const sectionDefectId = sectionDefect.V4Guid;
    const selectedCopy = [ ...selected ];
    const defectFoundInSelected = selectedCopy.find(select => select.V4Guid == sectionDefectId);
    
    if (defectFoundInSelected) {
      const indexToRemoveFromSelected = selectedCopy.findIndex(select => select.V4Guid == sectionDefectId);
      selectedCopy.splice(indexToRemoveFromSelected, 1);
    } else {
      selectedCopy.push(sectionDefect);
    }
    setSelected(selectedCopy);
  };

  useEffect(() => {
    if (editingWorkOrderItem) {
      const { WoItemDefects } = editingWorkOrderItem;
      setSelected(WoItemDefects);
    }
  }, [editingWorkOrderItem]);

  useEffect(()=> {
    const defectsBySection = [];
    propertySections.map(propertySection => {
      const sectionId = propertySection.SectionId;
      const sectionName = propertySection.Name;
      const defectArray = [];
      const sectionObject = { id: sectionId, name: sectionName, defects: defectArray };
      propertyDefects.map(propertyDefect => {
        if (propertyDefect.WosectionId == sectionId) {
          defectArray.push(propertyDefect);
        }
      })
      defectsBySection.push(sectionObject);
    }) 

    const sortedDefectsBySection = [...defectsBySection].sort((a, b) => a.name.localeCompare(b.name));
    setSectionDefects(sortedDefectsBySection);
  }, [propertySections]);

  useEffect(() => {
    retrieveSelectedWorkOrderDefects(selected);
  }, [selected]);

  useEffect(() => {
    if (editedWorkOrderItem) {
      if (currentStep != previousCurrentStep) {
        if (JSON.stringify(editedWorkOrderItem) != JSON.stringify(editingWorkOrderItem)) {
          const editedDefects = editedWorkOrderItem.WoItemDefects;
          const editingDefects = editingWorkOrderItem.WoItemDefects;
          if (JSON.stringify(editedDefects) != JSON.stringify(editingDefects)) {
            setSelected(editedDefects);
          }
        }
      }
    }
  }, [editedWorkOrderItem]);

  const center = {
    lat: 40.7080156,
    lng: -74.0132392
  }

  return (
    <Grid container direction='row'>
      <Grid item xs={5}>
        <FormControl variant='filled' component="fieldset" style={{ width: '90%', textAlign: 'left' }}>
          <FormGroup>
            <List className={classes.root} subheader={<li />}>
              {sectionDefects.map(section => (
                <li key={`section-${section.id}`} className={classes.listSection}>
                  <ul className={classes.ul}>
                    <ListSubheader>{`Section: ${section.name}`}</ListSubheader>
                    { section.defects.map(sectionDefect => (
                      <ListItem key={`defect-${section.id}-${sectionDefect.V4Guid}`}>
                        <FormControlLabel
                          control={<Checkbox onChange={() => handleSelect(sectionDefect)} checked={selected.findIndex(selected => selected.V4Guid == sectionDefect.V4Guid) != -1} />}
                          label={`${sectionDefect.Name}`}
                        />
                      </ListItem>
                    )) }
                  </ul>
                </li>
              ))}
            </List>
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <ScriptLoaded>
          <GoogleMap 
            id='example-map' 
            mapContainerStyle={{ height: '100%', width: '100%' }} 
            center={center} 
            zoom={18}>
          </GoogleMap>
        </ScriptLoaded>
      </Grid>
    </Grid>
    
  )
};
import 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  IconButton
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import UndoIcon from '@material-ui/icons/Undo';
var moment = require('moment');

export default function EditDateSelect(props) {
  const [selectedDate, setSelectedDate] = useState(null);
  const { retrieveDate, category3Finished } = props;
  
  const currentDateTime = moment().format('MM-DD-YYYY');

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleUndo = () => {
    setSelectedDate(currentDateTime);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    setSelectedDate(currentDateTime);
  }, []);

  const prevSelectedDate = usePrevious(selectedDate);
  useEffect(() => {
    if (selectedDate) {
      if (selectedDate != prevSelectedDate) {
        const formattedSelectedDate = moment(selectedDate).format('YYYY-MM-DD');
        retrieveDate(formattedSelectedDate);
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    let element = document.getElementsByClassName('MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal');
    element[0].style.marginTop = '7px';
    element[0].style.marginLeft = '2%';
    element[0].style.width = '96%';
  }, []);

  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: 'white', width: '95%' }} alignItems='center' justify='center'>
      <Grid item xs={10}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around" >
            <KeyboardDatePicker
              disabled={!category3Finished}
              margin="normal"
              id="date-picker-dialog"
              label="Est. Service Date"
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              disablePast={true}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2}>
        { category3Finished && selectedDate != currentDateTime  ?
          <IconButton size='small' color="default" aria-label="edit" onClick={() => handleUndo()}>
            <UndoIcon />
          </IconButton>
        : null }
      </Grid>
    </Grid>
  );
}


// import React, { useState, useEffect } from 'react';
// import TextField from '@material-ui/core/TextField';

// var moment = require('moment');
// moment().format(); import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//   container: {
//     backgroundColor: 'white',
//     width: '100%',
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   textField: {
//     margin: theme.spacing(1),
//     width: '95%',
//   },
// }));

// export default function DatePickers(props) {
//   const [date, setDate] = useState(null);
//   const classes = useStyles();
  // const { retrieveDate, scheduledDate } = props;
//   const today = moment().format('YYYY-MM-DD');

//   const handleChange = (e) => {
//     const date = e.target.value;
//     setDate(date);
//   };

//   useEffect(() => {
//     if (scheduledDate) {
//       const formattedDate = moment(scheduledDate).format('YYYY-MM-DD');
//       setDate(formattedDate);
//     } else {
//       setDate(today);
//     }
//   }, [scheduledDate]);

//   return (
//     <form className={classes.container} noValidate>
//        <TextField
 //           inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
//            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
//         onChange={handleChange}
//         // disabled={!category3Finished}
//         id="date"
//         label="Est. Service Date"
//         type="date"
//         value={date}
//         className={classes.textField}
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />
//     </form>
//   );
// }


// import React, { useEffect } from 'react';
// import TextField from '@material-ui/core/TextField';

// var moment = require('moment');
// moment().format();import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//   container: {
//     width: '100%',
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   textField: {
//     margin: theme.spacing(1),
//     width: '95%',
//   },
// }));

// export default function DatePickers(props) {
//   const classes = useStyles();
//   const { category3Finished, retrieveDate } = props;
//   const today = moment().format('YYYY-MM-DD');

//   const handleChange = (e) => {
//     const date = e.target.value;
//     retrieveDate(date);
//   };

//   useEffect(() => {
//     retrieveDate(today);
//   }, []);

//   return (
//     <form className={classes.container} noValidate>
//        <TextField
//            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
//            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
//         onChange={handleChange}
//         disabled={ !category3Finished }
//         id="date"
//         label="Day of Service"
//         type="date"
//         defaultValue={today}
//         className={classes.textField}
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />
//     </form>
//   );
// }

import React, { useState, useEffect } from 'react';
import Gallery from 'react-grid-gallery';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  body: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    margin: 0,
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
  image: {
    position: 'relative',
    maxHeight: '65%',
    minHeight: '65%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, 10%)',
    // padding: '10%'
  },
  imageMobile: {
    position: 'relative',
    maxHeight: '65%',
    minHeight: '65%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, 10%)',
    padding: '10%'
  },
  imageSelect: {
    maxWidth: '100%',
  },
  imageSelector: { 
    margin: '1%',
    position: 'relative',
    transform: 'translate(0%, 90%)',
    marginBottom: '24%',
    maxHeight: '10%',
  }
}));

export default function PropertiesResultsImages(props) {
  const style = useStyles();
  const { 
    body, 
    container, 
  } = style;

  const { 
    handleOpenModal, 
    images,
  } = props;

  const handleModalBlur = (target) => {
    const id = target.id;

    if (id != 'container') {
      return;
    } else {
      handleOpenModal();
    }
  };

  return (
   <Grid container direction={'row'} alignItems={'center'} justify={'center'} id='image-container' className={body} onClick={(e) => handleModalBlur(e.target)}>
      <div id={'container'} className={container}>
        <Gallery
          lightboxWillClose={handleOpenModal}
          isOpen={true}
          showLightboxThumbnails={true}
          backdropClosesModal={true}
          images={images}
        />
      </div>
    </Grid>
  )
}

//{/* <Grid container direction={'row'} alignItems={'center'} justify={'center'} id='image-container' className={body} onClick={(e) => handleModalBlur(e.target)}>
    //   <div id={'container'} className={container}>
    //     <img id='large-image' className={mobile ? imageMobile : image} src={selectedImage} />
    //     <Grid container direction='row' alignItems={'center'} justify={'center'} className={imageSelector}>
    //       <Grid id='thumbnails' container direction='row' alignItems={'center'} justify={'center'}>
    //         <Grid item xs={4} style={{ textAlign: 'right' }} id='decrement-thumbnails' onClick={() => manageImageSelect('-')}>
    //           <IconButton id='decrement-thumbnails'>
    //             <ArrowBackIosIcon id='decrement-thumbnails' color='secondary' fontSize='large' style={{ marginLeft: '30%' }} />
    //           </IconButton>
    //         </Grid>
    //         { thumbnailsToRender.map((imageUrl) => {
    //           return (
    //             <Grid id='image-row' item xs={1} onClick={() => setSelectedImage(imageUrl)}>
    //               <img id='image-thumbnail' className={imageSelect} src={imageUrl} />
    //             </Grid>
    //         )}) } 
    //         <Grid item xs={4} style={{ textAlign: 'left' }} id='increment-thumbnails' onClick={() => manageImageSelect('+')}>
    //           <IconButton id='increment-thumbnails'>
    //             <ArrowForwardIosIcon id='increment-thumbnails' color='secondary' fontSize='large' />
    //           </IconButton>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   </div>
    // </Grid></Grid> */}
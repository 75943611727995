import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DocumentAppendSelect(props) {
  const [value, setValue] = useState(1);
  const {
    appendTo,
    handleAppendSelect,
  } = props;

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value != previousValue) {
      handleAppendSelect(value);
    }
  }, [value]);

  const previousAppendTo = usePrevious(appendTo);
  useEffect(() =>{
    if (appendTo != previousAppendTo) {
      setValue(appendTo);
    }
  }, [appendTo]);

  const classes = useStyles();
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label" style={{fontFamily: 'Ebrima'}}>Append Document To:</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={handleChange}
            style={{fontFamily:'Ebrima',}}
          >
            <MenuItem value={1} style={{fontFamily:'Ebrima',}}>Property</MenuItem>
            <MenuItem value={2}style={{fontFamily:'Ebrima',}} >Tag</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

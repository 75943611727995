import React, { useState, useEffect } from 'react';
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from '@material-ui/core';

const steps = ['Request Leak Response', 'Select Property/Contact', 'Map/Locate Leak', 'Add Photos/Documents', 'Review/Submit Request'];

export default function HorizontalLabelPositionBelowStepper(props) {
  const [ activeStep, setActiveStep ] = useState(0);
  const [completed, setCompleted] = React.useState({});

  const {
    wizardStep,
  } = props;

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };
  
  useEffect(() => {
    if (wizardStep != activeStep) {
      setActiveStep(wizardStep);
      handleComplete()
    }
  }, [wizardStep]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid container direction='row' alignItems='center' justify='center' style={{ height: '65%', width: '100%' }}>
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} alternativeLabel style={{ height: '100%', padding: '2%' }}>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{null}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
    </Grid>
    <Grid container direction='row' alignItems='center' justify='center' style={{ height: '35%' }}>
      <Grid item xs={10} style={{ textAlign: 'center'}}>
        <Typography>{steps[activeStep]}</Typography>
      </Grid>
    </Grid>

    </div>
  );
}

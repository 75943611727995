import React, { useState, useEffect } from 'react';
import PropertyAerialMap from '../../PropertyFiles/PropertyAerialMap';
import {
  Paper,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export default function PropertyMediaAerialMapContainer(props) {
  const [aerialMapUrl, setAerialMapUrl] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const {
    property,
  } = props;

  const labelStyle = {
    color: 'white',
    fontFamily: 'Ebrima',
  }

  const buttonStyle = {
    fontFamily: 'Ebrima',
  }


  useEffect(() => {
    if (property) {
      const propertyExists = Object.values(property).length;
      if (propertyExists) {
        const {
          AerialMapURL,
          Name,
        } = property;
        setAerialMapUrl(AerialMapURL);
        setBuildingName(Name);
      }
    }
  }, [property]);

  return (
    <Grid id='aerial-map-container-row' container direction='row' alignItems='center' justify='space-between' style={{ height: '100%', display: 'table' }}>

      <Grid item xs={12} style={{ display: 'table-cell' }}>
        <Grid container direction='row' alignItems='center' style={{ width: '100%', backgroundColor: 'cadetBlue' }}>
          <Grid item xs={12} style={{ margin: '1%', display: 'inline-flex' }}>
            <SearchIcon style={labelStyle} />
            <Typography style={labelStyle}> Aerial Map - {buildingName}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ display: 'table-row', height: '100%' }}>
        <Grid container direction='row' justify='center' alignItems='space-between' style={{ height: '100%', display: 'table' }}>
          <Grid item xs={12} style={{ display: 'table-cell', height: '100%' }}>
            <PropertyAerialMap
              property={property}
            />
          </Grid>
          <Grid item xs={12} style={{ display: 'table-row', backgroundColor: 'cadetBlue' }}>
            <Grid container direction='row' alignItems='center' justify='center' style={{ width: '100%', padding: '2px' }}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button variant='contained' color='secondary' style={buttonStyle} disabled={!aerialMapUrl} onClick={() => { navigator.clipboard.writeText(aerialMapUrl) }}> Copy Url </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
} 
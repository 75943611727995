import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';

export default function RequestedByEmailInput(props) {
  const [ email, setEmail ] = useState('');
  const { 
    handleUpdateRequesterEmail,

    modifiedRequester,
    requestedIsUser,
   } = props;

  const handleUpdateEmail = (e) => {
    const text = e.target.value;
    setEmail(text);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if (modifiedRequester) {
      const {
        Email, 
      } = modifiedRequester;
      setEmail(Email);
    }
  }, [modifiedRequester]);

  const previousEmail = usePrevious(email);
  useEffect(() => {
    if (email != previousEmail) {      
      handleUpdateRequesterEmail(email);
    }
  }, [email]);
  
  return (
    <Grid container direction='row'>
      <Grid xs={12} style={{ padding: '2%' }}>
        <Paper style={{ width: '100%', boxShadow: 'none' }}>
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={12}>
               <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                id="standard-full-width"
                label="Email"
                placeholder="User's Email"
                autoFocus
                value={email}
                disabled={requestedIsUser}
                onChange={handleUpdateEmail}
                margin="normal"
                style={{ width: '95%', left: '2.5%', right: '2.5%', marginTop: '2%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}


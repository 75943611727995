import React, { useState, useEffect } from 'react';
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline-grid'
  }
}));

export default function DialogSelect(props) {
  const classes = useStyles();
  
  const { 
    status, 
    statusHistory, 
    openHistory, 
    handleClickCloseHistory 
  } = props;

  const reversedStatusHistory = [...statusHistory].reverse();

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open={openHistory} onClose={handleClickCloseHistory}>
      <DialogTitle>Status History</DialogTitle>
      <DialogContent>
        <List className={classes.root}>
          { reversedStatusHistory.map((statusChange) => {
           const date = statusChange.StatusDate;
           const status = statusChange.Name;
           const note = statusChange.Note;
           return (
             <Grid container direction='row'>
               <Grid item xs={12}>
                 <ListItem>
                   <ListItemAvatar>
                     <Avatar>
                       <CheckCircleIcon />
                     </Avatar>
                   </ListItemAvatar>
                   <ListItemText 
                    primary={status} 
                     secondary={
                       note ? 
                       <React.Fragment>
                         <div style={{display: 'inline-grid'}}>
                         <Typography                         
                           component="span"
                           variant="body2"
                           className={classes.inline}
                           color="textSecondary"
                         >
                           { `Note: ${note}` } 
                          </Typography>
                         {date}
                         </div>
                       </React.Fragment>
                       :
                       <React.Fragment>
                           <Typography
                             component="span"
                             variant="body2"
                             className={classes.inline}
                             color="textPrimary"
                           >
                           </Typography>
                           {date}
                       </React.Fragment>
                     }
                    />
                 </ListItem>
               </Grid>
               {/* <Grid container direction='row' alignItems='center' justify='center' style={{border: '1px solid red'}}>
                 <Grid item xs={1} style={{ border: '1px solid blue' }}>
                   <ListItemAvatar>
                     <Avatar>
                       <CommentIcon />

                     </Avatar>
                   </ListItemAvatar>
                 </Grid>
                 <Grid item xs={11} style={{ border: '1px solid green', textAlign: 'center' }}>
                   <ListItemText secondary={note} />
                 </Grid>
               </Grid> */}
             </Grid>
          
           )
         }) }      
        </List>  
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCloseHistory} color="primary">
          Cancel
          </Button>
        <Button onClick={handleClickCloseHistory} color="primary">
          Ok
          </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from 'react';
import dotnetify from 'dotnetify';
import Board from '../components/Project/ProjectPhasesView';

class ProjectPhasePage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = dotnetify.react.connect('ProjectPhasePage', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        console.log(ex);
          auth.signOut();
      }
    });
    
    this.dispatch = (state) => this.vm.$dispatch(state);
    this.routeTo = (route) => this.vm.$routeTo(route);

    this.state = {
      Users: [],
      Properties: [],
      NavRoutes: [],
      ProjectSelected: [],
      IsLoadingProjects: true,
      PhaseTemplatesFilter: [],
      PhaseTemplateItems: [],
      ProjectsKanban: [],
      RootProjects: [],
      projectPercentCompleted: 0
    };
  }

  componentWillUnmount() {
    this.vm.$destroy();
  }

  render() {
    let {
      RootProjects,
      NavRoutes,
      IsLoadingProjects,
      Properties,
      PhaseTemplatesFilter,
      ProjectPercentCompleted,
      ProjectsKanban,
    } = this.state;

    return (
      <div>
        <Board navRoutes={NavRoutes} projectPercentCompleted={ProjectPercentCompleted} rootProjects={RootProjects} phaseTemplatesFilter={PhaseTemplatesFilter} properties={Properties} isLoadingProjects={IsLoadingProjects} projectsKanban={ProjectsKanban} dispatch={this.dispatch} />
      </div>
    );
  }
}

export default ProjectPhasePage;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  List,
  Divider,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import BusinessIcon from '@material-ui/icons/Business';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    // boxShadow: 'none',
    padding: 'none',
    margin: 'none',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function BuildingFolderCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  const {
    node,
  } = props;

  const classes = useStyles();
  return (
    <Card square className={classes.root}>
      <CardHeader
        style={{ padding: '2%' }}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            <BusinessIcon />
          </Avatar>
        }
      
        title={node.Name}
        subheader={node.Address1}
      />
      {/* <Divider /> */}
      {/* <CardContent style={{ padding: '1%', margin: '0' }}>
        <List>
          <ul>{node.Address2}</ul>
          <ul>{node.City}</ul>
          <ul>{node.Country}</ul>
          <ul>{node.State}</ul>
          <ul>{node.Zip}</ul>
        </List>
      </CardContent> */}
    </Card>
  );
}

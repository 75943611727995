import React, { useState, useEffect } from 'react';
import PropertySectionMaterialsImagesStepper from './PropertySectionMaterialImagesStepper'
import Gallery from 'react-grid-gallery';
import {
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';

export default function PropertySectionMaterialImages(props) {
  const [openLightBox, setOpenLightBox] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  const { surfaceImages } = props;

  const handleLightBoxOpen = () => {
    setOpenLightBox(!openLightBox);
  };

  const currentImageWillChange = () => {
    return activeImage;
  };

  return (
    <Card square style={{ boxShadow: 'none', backgroundColor: 'inherit' }}>
      <CardContent style={{ paddingBottom: '0', paddingTop: '24px' }}>
        {surfaceImages.length > 0 ?
          <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' justify='center'>
                <Grid item xs={10} style={{ width: '100%', marginTop: '2%', marginBottom: '2%', marginLeft: '20%', marginRight: '20%' }}>
                  <Gallery
                    currentImage={activeImage}
                    lightboxWillOpen={handleLightBoxOpen}
                    lightboxWillClose={handleLightBoxOpen}
                    onClickPrev={() => setActiveImage(activeImage - 1)}
                    onClickNext={() => setActiveImage(activeImage + 1)}
                    enableImageSelection={false}
                    currentImageWillChange={currentImageWillChange}
                    showImageCount={false}
                    showLightboxThumbnails={true}
                    backdropClosesModal={true}
                    images={openLightBox ? surfaceImages : [surfaceImages[activeImage]]}
                    lightBoxProps={{ currentImage: activeImage }}
                    maxRows={1}
                  />
                </Grid>
                <Grid item xs={12} style={{ width: '100%', marginTop: '2%', marginBottom: '2%', marginLeft: '25%', marginRight: '25%' }}>
                  <PropertySectionMaterialsImagesStepper
                    numberOfImages={surfaceImages.length}
                    setActiveImage={setActiveImage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          : null}
      </CardContent>
    </Card>
  )
}
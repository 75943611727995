import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import auth from '../../auth';
import DocumentCard from './DocumentCard';
import DocumentSearch from './DocumentSearch';
import DocumentsPagination from './DocumentsPagination';
import DocumentDeleteModal from './DocumentDelete/DocumentDeleteModal';
import DocumentFormModal from './DocumentForm/Subcomponents/DocumentFormModal';
import TypeFilter from './DocumentTypeFilter';
import {
  Grid,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { debounce } from 'lodash';
import * as AWS from 'aws-sdk';
import 'regenerator-runtime/runtime';

const NEW_DOCUMENT_ID = 0;
const APPEND_TO_PROPERTY = 1;
const APPEND_TO_TAG = 2;

class Documents extends Component {
  constructor(props){
    super(props);
    this.vm = dotnetify.react.connect('Documents', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
         auth.signOut();
      }
    });
   
    this.dispatch = state => this.vm.$dispatch(state);
    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
      CompanyId: 0,
      Property: {},
      PropertyList: [],
      Results: [],
      Pages: [],
      RecordCount: 0,
      SelectedPageSize: 10,
      SelectedPage: 1,
      IdentityId: '',
      Token: '',
      UserOrgTags: [],
      FileTypes: [],
      ShowLoading: false,
      PropertySections: [],
      
      documentModalOpen: false,
      addedDocument: null,
      deleteDocumentFile: null,
      deleteDocumentId: null,
      deleteDocumentCompanyId: null,

      editedDocument: {},
      saveDocument: false,
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      saveDocument,
      editedDocument,
    } = this.state;

    //handles dispatch calls
    if (saveDocument) {
      const editedDocumentExists = Object.values(editedDocument).length;
      if (editedDocumentExists) {
        const { id } = editedDocument;
        if (id) {
          this.setState({
            saveDocument: false,
            editedDocument,
          }, () => this.handleUpdateDocumentDispatch(editedDocument));
        } else {
          this.setState({
            saveDocument: false,
            editedDocument,
          }, ()=>this.handleAddDocumentUpload(editedDocument));
        }
      } 
    }
  };
  
  // <----Search---->
  handleSearch = (text) => {
    if (text) {
      this.setState({
        searchText: text,
      }, this.dispatchSearch(text));
    } else {
      this.setState({
        searchText: '',
      }, this.dispatchSearch(''));
    }
  };

  dispatchSearch = debounce(text => {
    this.dispatch({ SearchAll: text });
  }, 400);

  // <----Add/Edit---->
  //dispatch returns empty 'Document' object if passed 0 ({})
  handleModalOpen = (id) => {
    this.setState({
      documentId: id,
      documentModalOpen: true,
    }, () => this.dispatch({ GetDocument: id }))
  };

  handleModalTagOpen = (id) => {
    this.setState({
      documentModalOpen: true,
    }, () => this.dispatch({ GetOrgTagDocument: id }))
  };

  handleModalClosed = () => {
    this.setState({
      editedDocument: {},
      documentModalOpen: false,
    });
  };

  handleSaveDocument = () => {
    this.setState({
      saveDocument: true,
    })
  };

  handleRetrieveDocument = (editedDocument) => {
    this.setState({
      editedDocument,
    })
  };

  // <----Delete---->
  handleDeleteModalOpen = (document) => {
    const { Id, CompanyId, } = document;
    this.setState({
      documentDeleteModalOpen: true,
      deleteDocumentFile: document,
      deleteDocumentId: Id,
      deleteDocumentCompanyId: CompanyId,
    });
  };

  handleDeleteDocument = (deleteDocumentId, buildingName) => {
    this.setState({
      documentDeleteModalOpen: false,
      deleteDocumentFile: null,
      deleteDocumentId: null,
      deleteDocumentCompanyId: null,
    })
    
    if(buildingName && buildingName.length>0){
      this.dispatch({ DeleteBuildingFile: deleteDocumentId });
    }else{
      this.dispatch({ DeleteTagFile: deleteDocumentId });
    }
  };

  handleDocumentTypeFilter = (documentTypeId) => {
    console.log("WHAT THE HELL!" + documentTypeId);
    this.dispatch({ UpdateDocumentTypeFilter: documentTypeId });
  };

  handleDeleteModalClose = () => {
    this.setState({
      documentDeleteModalOpen: false,
    })
  };

  // <----Result Selection---->
  dispatchPaginationSelect = (SelectedPage) => {
    this.setState({
      SelectedPage,
    }, this.dispatch({ SelectedPage: SelectedPage }));
  };

  selectPageSize = (pageSize) => {
    this.setState({
      SelectedPageSize: pageSize,
    }, this.dispatch({ SelectedPageSize: pageSize }))
  };

  handleWarrantySectionsDispatch = (id) => {
    this.dispatch({ GetPropertySections: id });
  };

  retrieveSelectedType = (selectedType) => {
    this.setState({
      selectedType,
    })
  };

  handleAddDocumentUpload = (editedDocument) => {
    const {
      appendTo,
      property,
      tags,
      files,
    } = editedDocument;

    const {
      CompanyId,
      Id,
    } = property;

    const promises = [];
    let dirName;
    files.map((file) => {
      const fileName = file.name;
      const contentType = file.type;
      
      if (appendTo == APPEND_TO_PROPERTY) {
        dirName = `${CompanyId}/${Id}/${fileName}`;
      };

      if (appendTo == APPEND_TO_TAG) {
        const companyId = tags[0].CompanyId;
        const designationId = tags[0].OrgUnitId;
        dirName = `${companyId}/${designationId}/${fileName}`;
      };
      
      const metaData = {
        fileName,
        dirName,
        // blob,
        file,
        contentType,
      };
      const upload = this.handleS3Upload(metaData);
      promises.push(upload);
    });

    Promise.all(promises)
      .then(response => {
        this.setState({
          addedFiles: response
        }, () => this.handleAddDocumentDispatch(response));
      })
      .catch(err => console.log(err))
  };

  //TODO: ask dewayne about file/blob
  handleS3Upload = (metaData) => {
    const { IdentityId, Token } = this.state;
    const { dirName, blob, file, contentType } = metaData;
    
    const parameters = {
      bucket: 'bluefinfiles',
      key: dirName,
      s3: s3,
      body: file,
      // body: blob,
      acl: 'public-read',
      contentType: contentType
    };

    AWS.config.region = 'us-west-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: IdentityId,
      Logins: {
        'cognito-identity.amazonaws.com': Token
      }
    });

    const s3 = new AWS.S3;

    s3.config.region = 'us-west-2';
    s3.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: IdentityId,
      Logins: {
        'cognito-identity.amazonaws.com': Token
      }
    });

    return new Promise((resolve, reject) => {
      this.uploadBlob(parameters)
        .then(result => {
          resolve(result);
          reject(Error);
        }) 
        .catch(err => console.log(err));
    })
  };

  uploadBlob = async (parameters) => {
    console.log(parameters);

    const {
      bucket,
      key,
      s3,
      body,
      acl,
      contentType
    } = parameters;
    const params = { Bucket: bucket, Key: key, Body: body, ACL: acl, ContentType: contentType };
    const manager = new AWS.S3.ManagedUpload({ params: params });
    manager.on('httpUploadProgress', (progress) => {
      if (progress) {
        const total = progress.total;
        const loaded = progress.loaded;
        if (total) {
          let percent = ((loaded * 100) / total);
          this.setState({
            uploadProgress: percent,
          })
        }
      }
    });
    return manager.promise();
  };

  handleAddDocumentDispatch = (responseArray) => {
    const { PropertySections, editedDocument, FileTypes, Document } = this.state;
    const {
      appendTo,
      property,
      sections,
      manufacturer,
      contractor,
      duration,
      startDate,
      tags,
      type,
      notes,
      files,
    } = editedDocument;

    const {
      CompanyId,
      Id,
      Name,
    } = property;

    responseArray.map((response) => {
      const { Key } = response;
      const fileName = files[0].name;
      const warrantySections = PropertySections.filter(PropertySection => sections.indexOf(PropertySection.SecId) > -1);
      const companyId = appendTo == APPEND_TO_PROPERTY ? CompanyId : 0;
      const buildingId = appendTo == APPEND_TO_PROPERTY ? Id : 0;
      const buildingName = appendTo == APPEND_TO_PROPERTY ? Name : '';
      const fileTypeNavigation = FileTypes.find(FileType => FileType.Id == type);
      
      const addedDocument = {
        Id: 0,
        BuildingId: buildingId,
        Notes: notes,
        FileUrl: Key,
        FileName: fileName,
        BuildingName: buildingName,
        CompanyId: companyId,
        FileTypeNavigation: fileTypeNavigation,
        FileType: type,
        OrgTags: tags,
        StartDate: startDate,
        Duration: duration,
        Contractor: contractor,
        Manufacturer: manufacturer,
        Sections: warrantySections,
      };

      console.log('add dispatch object')
      console.log(addedDocument)
      this.setState({
        addedDocument,
        editedDocument: {},
        documentModalOpen: false,
        uploadProgress: 0,
      }, appendTo == 1 ? 
      () => this.dispatch({ AddFile: addedDocument }) : 
      () => this.dispatch({ AddTagFile: addedDocument }))
    })
  };

  handleUpdateDocumentDispatch = (editedDocument) => {
    const { Document, PropertySections, FileTypes } = this.state;
    const {
      id,
      appendTo,
      manufacturer,
      contractor,
      startDate,
      duration,
      sections,
      type,

      property,
      tags,
      notes,
    } = editedDocument;
   
    const {
      FileName,
      FileUrl,
    } = Document;

    const {
      CompanyId,
      Id,
      Name,
    } = property;

    const fileUrl = FileUrl;
    const fileName = FileName;
    const warrantySections = PropertySections.filter(PropertySection => sections.indexOf(PropertySection.SecId) > -1);
    const companyId = appendTo == APPEND_TO_PROPERTY ? CompanyId : 0;
    const buildingId = appendTo == APPEND_TO_PROPERTY ? Id : 0;
    const buildingName = appendTo == APPEND_TO_PROPERTY ? Name : '';
    const fileTypeNavigation = FileTypes.find(FileType => FileType.Id == type);

    const dispatchDocument = {
      Id: id,
      BuildingId: buildingId,
      Notes: notes,
      FileUrl: fileUrl,
      FileName: fileName,
      BuildingName: buildingName,
      CompanyId: companyId,
      FileTypeNavigation: fileTypeNavigation,
      FileType: type,
      OrgTags: tags,
      StartDate: startDate,
      Duration: duration,
      Contractor: contractor,
      Manufacturer: manufacturer,
      Sections: warrantySections,
    };

    console.log('edit dispatch object')
    console.log(dispatchDocument)
    this.setState({
      editedDocument: {},
      documentModalOpen: false,
      uploadProgress: 0,
    }, appendTo == 1 ? 
    () => this.dispatch({ UpdateBuildingFile: dispatchDocument }) :
    () => this.dispatch({ UpdateTagFile: dispatchDocument }));
  };

  componentWillUnmount = () => {
    console.log('unmounting Documents');
    this.vm.$destroy();
  };

  render () {
    const {
      PropertyList,
      Results,
      Pages,
      SelectedPageSize,
      IdentityId,
      FileTypes,
      FileTypeId,
      Token,
      RecordCount,
      PropertySections,
      Document,

      documentModalOpen,
      documentDeleteModalOpen,
      deleteDocumentId,
      deleteDocumentCompanyId,
      deleteDocumentFile,
      UserOrgTags,

      saveDocument,
      uploadProgress,
    } = this.state;

    return (
      <Grid container direction='row' alignItems='center' justify='center' style={{height: '100%', width: '95%', padding: '2%'}}>
        <DocumentFormModal
          handleModalClosed={this.handleModalClosed}
          handleWarrantySectionsDispatch={this.handleWarrantySectionsDispatch}
          handleSaveDocument={this.handleSaveDocument}
          handleRetrieveDocument={this.handleRetrieveDocument}

          document={Document}
          properties={PropertyList}
          tags={UserOrgTags}
          fileTypes={FileTypes}
          propertySections={PropertySections}
          documentModalOpen={documentModalOpen}
          saveDocument={saveDocument}
          uploadProgress={uploadProgress}
        />
        <DocumentDeleteModal
          handleDeleteModalClose={this.handleDeleteModalClose}
          handleDeleteDocument={this.handleDeleteDocument}

          documentDeleteModalOpen={documentDeleteModalOpen}
          deleteDocumentCompanyId={deleteDocumentCompanyId}
          deleteDocumentId={deleteDocumentId}
          deleteDocumentFile={deleteDocumentFile}
          identityId={IdentityId}
          token={Token}
        />
        <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
          <Grid container direction='row' style={{ height: '100%', width: '100%' }}>
            <Grid item xs={12} style={{ display: 'table', height: '100%' }}>
              <Grid container direction='row' alignItems='center' justify='center' style={{ display: 'table-header-group' }}>
                <Grid item xs={12}>
                  <Grid container direction='row' alignItems='center' justify='center'>
                    <Grid item xs={6}>
                        <DocumentSearch
                          handleSearch={this.handleSearch}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TypeFilter
                          fileTypes={FileTypes}
                          fileTypeId={FileTypeId}
                          handleTypeSelect={this.handleDocumentTypeFilter}
                        />
                      </Grid>
                    <Grid item xs={3}>
                      <Button disabled={!Token} variant='contained' color="primary" onClick={() => this.handleModalOpen(NEW_DOCUMENT_ID)} style={{ height: '3rem',  backgroundColor: '#00C2F3', fontFamily: 'Ebrima', }}>
                        <AddIcon /> {'Add Document'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ height: '100%', backgroundColor: 'lightGrey', overflowY: 'auto', border: '1px solid gainsboro' }}>
                <Grid container direction='row' alignItems={'center'} justify={'center'} style={{ height: '100%', width: '100%' }}>
                  <Grid container direction='row' alignItems='center' justify='center' spacing={1} style={{ height: '100%', width: '100%', overflow: 'auto' }}>
                    { Results.map((document) => {
                      return (
                        <Grid item xs={4} style={{ height: '13rem' }}>
                          <DocumentCard
                            handleModalOpen={this.handleModalOpen}
                            handleModalTagOpen={this.handleModalTagOpen}

                            handleEditModalOpen={this.handleEditModalOpen}
                            handleDeleteModalOpen={this.handleDeleteModalOpen}
                            document={document}
                          />
                        </Grid>
                      );
                    }) }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ display: 'table-footer-group' }}>
                <Grid container direction='row' alignItems='center' justify='center'>
                  <Grid item xs={12}>
                    <DocumentsPagination
                      dispatchPaginationSelect={this.dispatchPaginationSelect}
                      selectPageSize={this.selectPageSize}
                      pages={Pages}
                      selectedPageSize={SelectedPageSize}
                      recordCount={RecordCount}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
};

export default Documents

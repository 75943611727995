import React, { useState, useEffect, useRef } from 'react';
import PropertyImages from '../../PropertyImageGalleries/PropertyImages';
import PropertyMediaImageTypeSelect from './PropertyMediaImageTypeSelect';
import PropertyMediaFilterSelect from './PropertyMediaFilterSelect';
import PropertyImagesContainer from '../../PropertyImageGalleries/PropertyImagesContainer';
import PropertyDetails from '../../PropertyDetails/PropertyDetails';
import PropertyDetailsInventory from '../../PropertyDetails/PropertyDetailsInventory';
import PropertyMediaAerialMapContainer from '../PropertyMediaAerialMap/PropertyMediaAerialMapContainer';
import PropertyMediaBuildingPlanContainer from '../PropertyMediaBuildingPlan/PropertyMediaBuildingPlanContainer';
import PropertyMediaDefectPlanContainer from '../PropertyMediaDefectPlan/PropertyMediaDefectPlanContainer';
import {
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// *************** !!! REQUIRED !!! overflowY set to 'scroll' ******************** 
// as dynamic introduction of scrollbar will cause 
// recursive calls and updates on resize
const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'scroll',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function PropertyMedia(props) {
  const [ selectedComponentId, setSelectedComponent ] = useState(0);
  const [ selectedCategory, setSelectedCategory ] = useState(0);
    const [images, setImages] = useState([]);
    
  const {
    property,
    selectedMedia,
    addressCoordinants,
    propertyBounds,
    selectedResult,
    selectedProperty,
    aerialMapURL,
    mobile,
  } = props;


    console.log(property);


  const setCategoryImages = () => {
    const {
      PropertyImages,
      SectionImages,
      PerimImages,
      InvImages,
      DefImages,
    } = property;

    switch (selectedCategory) {
      case 0:
        setImages(PropertyImages);
        break;
      case 1:
        setImages(SectionImages);
        break;
      case 2:
        setImages(PerimImages);
        break;
      case 3: 
        setImages(InvImages);
        break;
      case 4:
        setImages(DefImages);
        break;                  
    };
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  // for images
  const previousSelectedCategory = usePrevious(selectedCategory);
  useEffect(() => {
    if (selectedCategory != previousSelectedCategory) {
      setCategoryImages();
    }
  }, [selectedCategory]);

  //choose component
  const previousSelectedMedia = usePrevious(selectedMedia);
  useEffect(() => {
    if (selectedMedia != previousSelectedMedia) {
      setSelectedComponent(selectedMedia);
    }
  }, [selectedMedia]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction='row' style={{ height: '100%' }}>
        {/* <Grid id='images-container' item xs={12}>
          <PropertyImagesContainer
            property={property}
          />
        </Grid> */}
              {

                  property.MaterialType == 6 ?

                      selectedComponentId == 0 ?
                          <Grid id='details-item' item xs={12} style={{ height: '100%' }}>
                              <PropertyDetailsInventory
                                  addressCoordinants={addressCoordinants}
                                  propertyBounds={propertyBounds}
                                  selectedResult={selectedResult}
                                  selectedProperty={selectedProperty}
                                  mobile={mobile}
                              />
                          </Grid>
                          : selectedComponentId == 1 ?
                              <Grid id='images-container' item xs={12} style={{ height: '100%' }}>
                                  <PropertyImagesContainer
                                      property={property}
                                  />
                              </Grid>
                              : selectedComponentId == 2 ?
                                  <Grid id='building-plan-container' item xs={12} style={{ height: '100%' }}>
                                      <PropertyMediaBuildingPlanContainer
                                          property={property}
                                      />
                                  </Grid>
                                  : null
          :selectedComponentId == 0 ?
            <Grid id='details-item' item xs={12} style={{ height: '100%' } }>
              <PropertyDetails
                addressCoordinants={addressCoordinants}
                propertyBounds={propertyBounds}
                selectedResult={selectedResult}
                selectedProperty={selectedProperty}
                mobile={mobile}
              />
            </Grid>
            : selectedComponentId == 1 ?
            <Grid item xs={12} style={{ height: '100%' }}>
              <PropertyMediaAerialMapContainer 
                property={property}
              />
            </Grid>
          : selectedComponentId == 2 ?   
            <Grid id='images-container' item xs={12} style={{ height: '100%' }}>
              <PropertyImagesContainer 
                property={property}
              />
            </Grid>  
            : selectedComponentId == 3 ?   
            <Grid id='building-plan-container' item xs={12} style={{ height: '100%' }}>
              <PropertyMediaBuildingPlanContainer 
                property={property}
              />
            </Grid>  
            : selectedComponentId == 4 ?
              <Grid id='defect-plan-container' item xs={12} style={{ height: '100%' }}>
                <PropertyMediaDefectPlanContainer
                  property={property}
                />
              </Grid>  
          : null
                      
        }       
      </Grid>
    </div>
  );
}
import React from 'react';
import PropTypes from 'prop-types';
import { pink500, grey200, grey500, grey400, pink400 } from 'material-ui/styles/colors';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {DropzoneArea} from 'material-ui-dropzone';

import Typography from '@material-ui/core/Typography';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Avatar from 'material-ui/Avatar';


const ProfilePhoto = props => {
  const { styles, UserId, ProfilePhotoUrl, uploading} = props;

  const style = {
    container: {  marginLeft: 10,
      border:'0px solid #000000',
      fontFamily: 'Ebrima',
      },
      selectButtion: { margin: '1em',  fontFamily: 'Ebrima', },
      card: {
        maxWidth: 170,
        fontFamily: 'Ebrima',
      },
      media: {
        height: 140,
        fontFamily: 'Ebrima',
      },
      avatar: {
        div: {
            padding: '15px 0 10px 32px',
            backgroundColor: '#ffffff',
            height: 100,
            fontFamily: 'Ebrima',
        },
          icon: {
            float: 'left',
            display: 'block',
            boxShadow: '0px 0px 0px 8px rgba(0,0,0,0.2)',
            backgroundColor: '#ffffff',
            fontFamily: 'Ebrima',
          },
          span: {
            paddingTop: 0,
            display: 'block',
            color: 'black',
            fontWeight: 500,
            fontFamily: 'Ebrima',

          }
    }
  };

  return (
   
<div style = { style.container } >


          <Card style={style.card}>
              <CardActionArea>
                   <CardContent>
          <Typography gutterTop variant="h6" component="h6" style={{ fontFamily: 'Ebrima',}}>
            Profile Photo
          </Typography>
                  </CardContent>

       
                  <div style={style.avatar.div}>
                                                <Avatar src={ProfilePhotoUrl} size={85} style={style.avatar.icon} />
                          
        </div>
      </CardActionArea>
      <CardActions>
       
              <span style={style.avatar.span}>
                   <label htmlFor="icon-button-file">
        <IconButton
            color="primary"
            aria-label="Upload picture"
            component="span"
            onClick={props.handleOpenProfile}>
                              <EditIcon color="action" />
        </IconButton>
      </label>
       
                  
            </span>


                     
      </CardActions>
          </Card>


      </div>
   
  );
};

ProfilePhoto.propTypes = {
  styles: PropTypes.object,
    handleChangeRequestNavDrawer: PropTypes.func,
    ProfilePhotoUrl: PropTypes.string

};

export default ProfilePhoto;


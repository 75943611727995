import React, { useState, useEffect, useRef } from 'react';
import PropertyNameTextField from './SubComponents/PropertyNameTextField';
import PropertyAddressTextField from'./SubComponents/PropertyAddressTextField';
import PropertyAddressContTextField from './SubComponents/PropertyAddressContTextField';
import PropertyCityTextField from './SubComponents/PropertyCityTextField';
import PropertyCountrySelect from './SubComponents/PropertyCountySelect';
import PropertyStateSelect from './SubComponents/PropertyStateSelect';
import PropertyPostalCodeTextField from './SubComponents/PropertyPostalCodeTextField';
import {
  Grid,
} from '@material-ui/core';

export default function BuildingFolderForm(props) {
  const {
    handleUpdateBuildingName,
    handleUpdateBuildingAddress,
    handleUpdateBuildingAddressCont,
    handleUpdateBuildingCity,
    handleUpdateBuildingCountry,
    handleUpdateBuildingState,
    handleUpdateBuildingPostalCode,
    Countries,
    SelectedCountryId,
      States,
      SelectedStateId,

  } = props;
    console.log(States);
  // const [ ]
  return (
    <Grid container alignItems='center' justify='center' style={{ height: '100%', width: '99%', padding: '1%' }}>
      <Grid item xs={12}>
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
           <PropertyNameTextField 
              handleUpdateBuildingName={handleUpdateBuildingName}
           />
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <PropertyAddressTextField 
              handleUpdateBuildingAddress={handleUpdateBuildingAddress}
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <PropertyAddressContTextField 
              handleUpdateBuildingAddressCont={handleUpdateBuildingAddressCont}
            />
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <PropertyCityTextField 
              handleUpdateBuildingCity={handleUpdateBuildingCity}
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
                      <PropertyCountrySelect
                          Countries={Countries}
                          SelectedCountryId={SelectedCountryId}
                          handleUpdateBuildingCountry={handleUpdateBuildingCountry}
           />
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <PropertyStateSelect 
                          handleUpdateBuildingState={handleUpdateBuildingState}
                          States={States}
                          SelectedStateId={SelectedStateId}
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <PropertyPostalCodeTextField 
              handleUpdateBuildingPostalCode={handleUpdateBuildingPostalCode}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

import React from 'react';
import ReactDOM from 'react-dom';
import App from 'views/App';
import UserPage from 'views/UserPage';
import EditUserFormPage from 'views/EditUserFormPage';
import UserChangePasswordPage from 'views/UserChangePasswordPage';
import ProfileFormPage from 'views/ProfileFormPage';
import ManageContacts from 'components/ManageContacts/ManageContacts';
import ManageContactsBldgsContainer from 'components/ManageContacts/ManageContactsBldgsContainer';
import OrgTagsPage from 'views/OrgTagsPage';
import RoofDashboardLayout from 'components/RoofDashboard/RoofDashboardLayout';
import PavementDashboard from 'components/PavementDashboard/PavementDashboard';
import Properties from 'components/Properties/Properties';
import Documents from 'components/Documents/Documents';
import SiteMap from 'components/SiteMap/SiteMap';
import ReportWriter from 'components/ReportWriter/ReportWriter';
import WorkOrders from 'components/WorkOrders/WorkOrders';
import LeakResponse from 'components/LeakResponse/LeakResponse';
import Admin from 'components/Admin/Admin';
import MoveProperty from './components/Admin/MoveProperty/MoveProperty';
import ManageFolders from './components/Admin/ManageFolders/ManageFolders';
import ProjectHierarchyPage from 'views/ProjectHierarchyPage';
import AddProjectPage from 'views/AddProjectPage';
import ProjectPhasePage from 'views/ProjectPhasePage';
import MechanicalDashboard from 'components/MechanicalDashboard/MechanicalDashboard';

// Import all the routeable views into the global window variable.
Object.assign(window, {
    RoofDashboardLayout,
    ManageContacts,
    UserPage,
    EditUserFormPage,
    UserChangePasswordPage,
    ProfileFormPage,
    OrgTagsPage,
    ManageContactsBldgsContainer,
    Properties,
    PavementDashboard,
    Documents,
    ReportWriter,
    SiteMap,
    WorkOrders,
    LeakResponse,
    Admin,
    MoveProperty,
    ManageFolders,  
    ProjectHierarchyPage,
    AddProjectPage,
    ProjectPhasePage,
    MechanicalDashboard,
});

// Hot module replacement.  
if (module.hot) {
    const render = (react, elemId) => {
      console.log('HOT RELOAD')
      console.log(react, elemId);
        ReactDOM.unmountComponentAtNode(document.getElementById(elemId));
        ReactDOM.render(React.createElement(react), document.getElementById(elemId));
    }

    module.hot.accept('./views/App.js', _ => render(require('views/App').default, 'App'));
    module.hot.accept('./components/ManageContacts/ManageContacts.js', _ => render(require('components/ManageContacts/ManageContacts').default, 'Content'));
    module.hot.accept('./components/ManageContacts/ManageContactsBldgsContainer.js', _ => render(require('components/ManageContacts/ManageContactsBldgsContainer').default, 'Content'));
    module.hot.accept('./views/UserPage.js', _ => render(require('views/UserPage').default, 'Content'));
    module.hot.accept('./views/EditUserFormPage.js', _ => render(require('views/EditUserFormPage').default, 'Content'));
    module.hot.accept('./views/UserChangePasswordPage.js', _ => render(require('views/UserChangePasswordPage').default, 'Content'));
    module.hot.accept('./views/ProfileFormPage.js', _ => render(require('views/ProfileFormPage').default, 'Content'));
    module.hot.accept('./views/OrgTagsPage.js', _ => render(require('views/OrgTagsPage').default, 'Content'));
    module.hot.accept('./components/RoofDashboard/RoofDashboardLayout.js', _ => render(require('components/RoofDashboard/RoofDashboardLayout').default, 'Content'));
    module.hot.accept('./components/PavementDashboard/PavementDashboard.js', _ => render(require('components/PavementDashboard/PavementDashboard').default, 'Content'));
    module.hot.accept('./components/Application/ApplicationHeader/ApplicationSearch.js', _ => render(require('components/Application/ApplicationHeader/ApplicationSearch.js').default, 'Content'));
    module.hot.accept('./components/Properties/Properties.js', _ => render(require('components/Properties/Properties.js').default, 'Content'));
    module.hot.accept('./components/Documents/Documents.js', _ => render(require('./components/Documents/Documents.js').default, 'Content'));
    module.hot.accept('./components/ReportWriter/ReportWriter.js', _ => render(require('components/ReportWriter/ReportWriter.js').default, 'Content'));
    module.hot.accept('./components/SiteMap/SiteMap.js', _ => render(require('components/SiteMap/SiteMap.js').default, 'Content'));
    module.hot.accept('./components/WorkOrders/WorkOrders.js', _ => render(require('components/WorkOrders/WorkOrders.js').default, 'Content'));
    module.hot.accept('./components/LeakResponse/LeakResponse.js', _ => render(require('components/LeakResponse/LeakResponse.js').default, 'Content'));
    module.hot.accept('./components/Admin/Admin.js', _ => render(require('components/Admin/Admin.js').default, 'Content'));
    module.hot.accept('./components/Admin/MoveProperty/MoveProperty.js', _ => render(require('components/Admin/MoveProperty/MoveProperty.js').default, 'Content'));
    module.hot.accept('./components/Admin/ManageFolders/ManageFolders.js', _ => render(require('components/Admin/ManageFolders/ManageFolders.js').default, 'Content'));
    module.hot.accept('./components/MechanicalDashboard/MechanicalDashboard.js', _ => render(require('components//MechanicalDashboard/MechanicalDashboard.js').default, 'Content'));
    module.hot.accept('./views/ProjectHierarchyPage.js', (_) =>
    render(require('views/ProjectHierarchyPage').default, 'Content')
  );
  module.hot.accept('./views/ProjectPhasePage.js', (_) =>
    render(require('views/ProjectPhasePage').default, 'Content')
  );
  module.hot.accept('./views/AddProjectPage.js', (_) =>
    render(require('views/AddProjectPage').default, 'Content')
  );

}

export default App;
import React, { useState, useEffect, useRef } from 'react';
import {
  Paper,
  Grid,
  Modal,
  Typography,
  Avatar,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import BusinessIcon from '@material-ui/icons/Business';
import FolderIcon from '@material-ui/icons/Folder';

let childrenFolders = [];
let childrenBuildings = [];

export default function DeleteFolderModal(props) {
  const [ nodeName, setNodeName ] = useState('');
  const [ folderChildren, setFolderChildren ] = useState([]);
  const [ buildingChildren, setBuildingChildren ] = useState([]);
  const {
    handleDeleteFolder,
    handleDeleteBuildingFolder,
    handleDeleteUnselected,

    deleteSelected,
    deleteType,
    deleteNode,
  } = props;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const handleGetChildrenFolders = (folders) => {
    if (folders && folders.length) {
      folders.map((folder) => {
        const subFolders = folder.Folders;
        const subBuildings = folder.BuildingFolders;        
        
        childrenFolders.push(folder.Name);
        if (subBuildings) {
          handleGetChildrenBuildings(subBuildings);
        }
        
        if (subFolders && subFolders.length) {
          handleGetChildrenFolders(subFolders);
        } 
      }, setFolderChildren(childrenFolders));
    } else {
      setFolderChildren([]);
    }
  };  

  const handleGetChildrenBuildings = (buildings) => {
    if (buildings && buildings.length) {
      buildings.map((building) => {
        childrenBuildings.push(building.Name);
      }, setBuildingChildren(childrenBuildings));
    } else {
      setBuildingChildren([]);
    }
  };  

  const previousDeleteNode = usePrevious(deleteNode);
  useEffect(() => {
    if (deleteNode != previousDeleteNode) {
      if (deleteNode) {
        const folders = deleteNode.Folders;
        const buildingFolders = deleteNode.BuildingFolders;

        if (folders) { 
          
            handleGetChildrenFolders([]);
        }

        if (buildingFolders) {
          if (buildingFolders.length) {
            handleGetChildrenBuildings(buildingFolders);
          } else {
            handleGetChildrenBuildings([]);
          }
        }
      }
    }
  }, [deleteNode]);

  useEffect(() => {
    if (deleteNode != previousDeleteNode) {
      if (deleteNode) {
        const name = deleteNode.Name;
        setNodeName(name);
      }
    }
  }, [deleteNode]);

  useEffect(() => {
    if (!deleteSelected) {
      childrenFolders = [];
      childrenBuildings = [];
    }
  }, [deleteSelected]);

  return (
    <Modal open={deleteSelected} onBackdropClick={()=>handleDeleteUnselected()}>
      <Grid container direction='row' alignItems='flex-start' justify='center' style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', overflow: 'auto' }}>
        <Grid item xs={4} style={{ height: '100%', textAlign: 'center' }}>
          <Paper>
            <Grid container direction='row' style={{ height: '100%', display: 'table' }}>
              <Grid item xs={12} style={{ display: 'table-header-group', top: '0', left: '0', zIndex: '1' }}>
                <Grid container direction='row' alignItems='center' justify='space-between' style={{ backgroundColor: '#66BFFA', textAlign: 'center' }}>
                  <Grid item xs={1} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    <Avatar style={{ backgroundColor: 'gainsboro', margin: '2%' }}>
                      <InsertDriveFileIcon />
                    </Avatar>
                    <Typography variant='h6' style={{ margin: '4%' }}>Delete Folder</Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right', color: 'white', display: 'contents' }}>
                    <IconButton onClick={ () => handleDeleteUnselected() }>
                      <HighlightOffIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ display: 'table-cell' }}>
                <Grid container direction='row' justify='center' alignItems='center' style={{ padding: '2%' }}>
                  
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant='h6'>Are you sure you want to delete {nodeName} {folderChildren.length || buildingChildren.length ? 'and its contents? The following  will be removed.' : null}</Typography>
                  </Grid>
                  
                  <Grid item xs={12} style={{ backgroundColor: 'cadetBlue' }}>
                       
                      <Grid container direction='row' justify='center' alignItems='center'>
                        <Grid item xs={6}>
                          <List>
                            { folderChildren.map((child) => {
                              return (
                                <ListItem>
                                  <ListItemIcon>
                                    <FolderIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={child} />
                                </ListItem>
                              )
                            }) }
                            { buildingChildren.map((child) => {
                              return (
                                <ListItem>
                                  <ListItemIcon>
                                    <BusinessIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={child} />
                                </ListItem>
                              )
                            }) }
                          </List>
                        </Grid>
                      </Grid>

                    
                </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant='caption' style={{ color: 'darkGrey' }}>*This action cannot be undone.</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ display: 'table-footer-group' }}>
                <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA' }}>
                  <Grid item xs={3} style={{ textAlign: 'center', padding: '4px' }}>
                    { deleteType == 1 ?
                    <Button style={{ backgroundColor: 'gainsboro' }} onClick={() => handleDeleteFolder()}>Delete</Button>
                    : deleteType == 2 ?
                    <Button style={{ backgroundColor: 'gainsboro' }} onClick={() => handleDeleteBuildingFolder()}>Delete</Button>
                    : null }
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Modal>

  );
}

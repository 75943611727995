import React from 'react';
import ApplicationSearch from './ApplicationSearch';
import ApplicationMobileMenu from './ApplicationMobileMenu';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import ApplicationMobileMenuStyle from './Subcomponents/ApplicationMobileMenuStyle';
import Avatar from './ApplicationHeaderAvatar';
import SvgUsers from '../../SvgIcons/SvgUsers';
import SvgDashboard from '../../SvgIcons/SvgDashboard';
import SvgReports from '../../SvgIcons/SvgReports';
import SvgTags from '../../SvgIcons/SvgTags';
import SvgWorkOrders from '../../SvgIcons/SvgWorkOrders';
import SvgProperties from '../../SvgIcons/SvgProperties';
import SvgContacts from '../../SvgIcons/SvgContacts';
import SvgDocs from '../../SvgIcons/SvgDocs';
import ApplicationFeedBackButton from './Subcomponents/ApplicationFeedBackButton';

function ApplicationHeader(props) {
  const style = ApplicationMobileMenuStyle();
  const { 
    root,
    appBar, 
    menuButton, 
    feedbackButton,
    title, 
    search, 
    searchIcon,
  } = style;

  const {  
    toggleSidebar,
    handleTopbar,
    sidebarOpen,
    handleLogOut,
    componentTitle,
    profilePhotoUrl, 
    profile, 
    handleProfileClick, 
    handleOpenFeedback,
    userName,
    selectRoute,
    mobile,
    topbarOpen,
    menus,

    siteFilterOpen,
    siteFilterSelect, 
   } = props;

  return(
      <div id='app-bar-container' className={ root }>
        <AppBar 
          className={ appBar } 
          position="fixed"
        >
          <Toolbar>
            <IconButton 
              style={ sidebarOpen ? null : { marginLeft: '0' } }
              className={ menuButton } 
              color="inherit" 
              aria-label="open drawer" 
              onClick={ mobile ? handleTopbar : toggleSidebar }
            >
              <MenuIcon />
            </IconButton>
              { 
                mobile ? 
                null 
                : 
                <div>
                  {componentTitle === "" ? <SvgDashboard props={props} /> : ""}
                  {componentTitle === "Dashboard" ? <SvgDashboard props={props} /> : ""}
                  {componentTitle === "Manage Users" ? <SvgUsers props={props} /> : ""}
                  {componentTitle === "Manage Contacts" ? <SvgContacts props={props} /> : ""}
                  {componentTitle === "Properties" ? <SvgProperties props={props} /> : ""}
                  {componentTitle === "Documents" ? <SvgDocs props={props} /> : ""}
                  {componentTitle === "Portfolio Map" ? <SvgProperties props={props} /> : ""}
                  {componentTitle === "Tag Manager" ? <SvgTags props={props} /> : ""}
                  {componentTitle === "Reports" ? <SvgReports props={props} /> : ""}
                  {componentTitle === "Work Orders" ? <SvgWorkOrders props={props} /> : ""}
                </div>
              }
            <Typography 
              className={ title } 
              variant="h6" 
              noWrap
            >            
            { componentTitle } 
            </Typography>
            { 
              mobile ?
                null
              :  
                <ApplicationFeedBackButton 
                  handleOpenFeedback={handleOpenFeedback}
                />
            }
            <div className={ search }>
              <div className={ searchIcon }>
                <SearchIcon />
              </div>
              <ApplicationSearch 
                style={ style } 
                selectRoute={ selectRoute }
              />
              {
                topbarOpen ? 
                <ApplicationMobileMenu 
                  selectRoute={selectRoute}
                  handleLogOut={handleLogOut}
                  handleProfileClick={handleProfileClick}
                  handleTopbar={handleTopbar}
                  siteFilterSelect={siteFilterSelect}
                  handleOpenFeedback={handleOpenFeedback}

                  menus={ menus }
                  profilePhotoUrl={ profilePhotoUrl }
                  profile={ profile }
                  userName={ userName }
                  siteFilterOpen={siteFilterOpen}
                /> 
                : 
                null 
              }
            </div>
            <div>
              <Avatar
                handleLogOut={ handleLogOut }
                profilePhotoUrl={ profilePhotoUrl }
                profile={ profile }
                handleProfileClick={ handleProfileClick }
                userName={ userName }
                mobile={ mobile }
              />
            </div>
          </Toolbar>
        </AppBar>
      </div>
  )
};

export default ApplicationHeader
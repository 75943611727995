import React, { Component } from 'react';
import PropertyGraphsHeader from '../PropertyGraphs/SubComponents/PropertyGraphsHeader';
import PropertyDefects from '../PropertyDefects/PropertyDefects';
import PropertyDefectsMap from '../PropertyDefects/PropertyDefectsMap';
import PropertyRepairs from '../PropertyRepairs/PropertyRepairs';
import PropertyDefectsSelect from './SubComponents/PropertyDefectsSelect';
import {
  Grid,
} from '@material-ui/core';

class PropertyDetails extends Component{
  constructor(props) {
    super(props);

    this.state = {
      //defects
      selectedDefects: [],
      selectedDefectSection: null,
      highlightedDefect: null,
      //repairs
      selectedRepairs: [],
      selectedRepairSection: null,
      highlightedRepair: null,
      //screen resizing
      mobile: null,
    }
  }

  componentDidMount = () => {
    this.handleScreenResize();
  };

  componentDidUpdate = (prevProps, prevState) => {
    window.addEventListener('resize', this.handleScreenResize);
  };

  handleScreenResize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 600) {
      this.setState({
        mobile: true,
      });
    } else {
      this.setState({
        mobile: false,
      });
    }
  };

  retrieveSelectedDefectSection = (selectedDefectSection) => {
    this.setState({
      selectedDefectSection,
    })
  };

  retrieveSelectedDefects = (selectedDefects) => {
    this.setState({
      selectedDefects,
    })
  };

  retrieveHighlightedDefect = (highlightedDefect) => {
    this.setState({
      highlightedDefect,
    })
  };

  retrieveSelectedRepairSection = (selectedRepairSection) => {
    this.setState({
      selectedRepairSection,
    })
  };

  retrieveSelectedRepairs = (selectedRepairs) => {
    this.setState({
      selectedRepairs,
    })
  };

  retrieveHighlightedRepair = (highlightedRepair) => {
    this.setState({
      highlightedRepair,
    })
  };

  render() {
    const {
      addressCoordinants,
      propertyBounds,
      selectedResult,
      selectedProperty,
    } = this.props;

    const { 
      //defects
      selectedDefectSection,
      selectedDefects,
      highlightedDefect,
      //repairs
      selectedRepairs,
      selectedRepairSection,
      highlightedRepair,
      //resizing
      mobile,
     } = this.state;
     
    return (
      <Grid container direction={'row'} style={{ height: '100%' }}>
        <Grid item xs={12} sm={6} md={6} lg={8} xl={8} style={mobile ? { height: '50%' } : { height: '100%', position: 'sticky', bottom: '0', left: '0' } }>
          <PropertyDefectsMap
            //defects
            selectedDefectSection={selectedDefectSection}
            selectedDefects={selectedDefects}
            highlightedDefect={highlightedDefect}

            //repairs
            selectedRepairs={selectedRepairs}
            selectedRepairSection={selectedRepairSection}
            highlightedRepair={highlightedRepair}

            //data for initialization
            addressCoordinants={addressCoordinants}
            propertyBounds={propertyBounds}

            //default props
            selectedResult={selectedResult}
            selectedProperty={selectedProperty}

            mobile={mobile}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} style={mobile ? { height: '50%' } : { height: '100%', overflow: 'auto' } }>
          <PropertyDefects
            retrieveSelectedDefectSection={this.retrieveSelectedDefectSection}
            retrieveSelectedDefects={this.retrieveSelectedDefects}
            retrieveHighlightedDefect={this.retrieveHighlightedDefect}
            selectedResult={selectedResult}
            selectedProperty={selectedProperty}
          />
          <PropertyRepairs
            retrieveSelectedRepairSection={this.retrieveSelectedRepairSection}
            retrieveSelectedRepairs={this.retrieveSelectedRepairs}
            retrieveHighlightedRepair={this.retrieveHighlightedRepair}
            selectedResult={selectedResult}
            selectedProperty={selectedProperty}
          />
        </Grid>
      </Grid>
    )
  }
}

export default PropertyDetails
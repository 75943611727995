import React from 'react';
import {SvgIcon} from '@material-ui/core';

let SvgContacts = (props) => (
  <SvgIcon {...props} >
       <path d="M1.7,10.5H0v3H1.7v3H0v3H1.7V24H24V0H1.7V4.5H0v3H1.7ZM21,21.8H4.2V2.3H21ZM10.3,10.9c3.6,2.5,7.6-1.4,5.1-5.1l-.3-.3C11.4,3,7.4,7,10,10.6,10.1,10.7,10.2,10.9,10.3,10.9Zm8.5,8.4V16.7a3.4865,3.4865,0,0,0-3.7-3.2H10.2a3.4827,3.4827,0,0,0-3.7,3.2v2.6Z"/>
  </SvgIcon>
);

export default SvgContacts;


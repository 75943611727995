import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  TextField,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '95%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function WarrantyContractorInput(props) {
  const [value, setValue] = useState('');
  const {
    handleManufacturerSelect,
    document,
  } = props;

  const handleChange = (e) => {
    const userValue = e.target.value;
    setValue(userValue);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value != previousValue) {
      if (value) {
        handleManufacturerSelect(value);
      } else {
        handleManufacturerSelect('');
      }
    }
  }, [value]);

  useEffect(() => {
    if (document) {
      const { Id, Manufacturer } = document;
      if (Id) {
        setValue(Manufacturer);
      } else {
        setValue('');
      }
    }
  }, [document]);
  
  const classes = useStyles();
  return (
    <Grid container direction='row' justify='center' alignItems='center'>
      <FormControl className={classes.formControl} noValidate autoComplete="off">
         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
          id="standard-basic"
          label="Manufacturer"
          onChange={handleChange}
          value={value}
        />
      </FormControl>
    </Grid>
  );
}

import React from 'react';
import dotnetify from 'dotnetify';
import auth from '../../auth';
import { 
  Grid, 
  Paper 
} from '@material-ui/core';
import HVACDashboardHeader from '../MechanicalDashboard/Header';
import FinancialForecastGraph from '../MechanicalDashboard/FinancialForecastGraph';
import TagHVACUnits from './TagHVACUnits';
import ManufacturerDistribtion from './ManufacturerDistribution';
import ERSLDistribution from './ERSLDistribution';
import CriticalityDistribution from './CriticalityDistribution';
import GHGEmissions from './GHGEmissions';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
  BarChart,
  LabelList,
} from 'recharts';

class MechanicalDashboard extends React.Component {
  constructor(props) {
    super(props);

    // dotnetify.react.router.urlPath = null;
    dotnetify.react.router.notFound404Url = null;
    this.vm = dotnetify.react.connect('MechanicalDashboard', this, { 
       headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        auth.signOut();
      }
    });

    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
        numberOfForcastResults: 10,
        HeaderData: [],
        Projections: [],
        AreaByFilteredTags: [],
        AssetsByFilteredTags: [],
        EmergencyCostsData: [],
        CorrectiveMaintenanceData: [],
        EmergencyRepairsData: [],
        RemainingServiceData: [],
        ERSLByType: [],
    };
  }

  handleNumberOfResults = (event, data) => {
    const { props } = data;
    const { value } = props;

    this.setState({
      numberOfForcastResults: value,
    });
  };

  handleSelectRoute = (selectedRoute, title) => {
    const { selectRoute } = this.props;
    this.setState({
      selectedRoute,
    }, 
      selectRoute(selectedRoute, title),
      this.searchDeselected());
  };

  componentWillUnmount() {
    this.vm.$destroy();
  };

  render() {
    
    const { 
      HeaderData,
      AreaByFilteredTags,
      AssetsByFilteredTags, 
      CorrectiveMaintenanceData, 
      ERSLByType,
      MaterialType,
    } = this.state;

    return (  
      <Grid id='graph-grid' container direction='row' justify='center' alignItems='center' style={{ height: '100%', width: '100%', overflow: 'auto', backgroundColor: '#A7A9AC' }}>
        <Grid item xs={12}>
                <HVACDashboardHeader headerData={HeaderData} />
        </Grid>
        <Grid item xs={12} style={{ padding: '1%' }}>
          <Paper>
            <FinancialForecastGraph
                financialForcastData={ HeaderData.forecastDataList }
              />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '1%' }}>
          <Paper >
         
          <TagHVACUnits tagUnitList={HeaderData.tagUnitList} />
        
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '1%' }}>
          <Paper >
            <ManufacturerDistribtion mfgUnitList={HeaderData.mfgUnitList} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '1%' }}>
          <Paper >
          <ERSLDistribution erslUnitList={HeaderData.erslUnitList} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '1%' }}>
          <Paper>
            <CriticalityDistribution criticalityUnitList={HeaderData.criticalityUnitList} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '1%' }}>
          <Paper>
            <GHGEmissions ghgUnitList={HeaderData.ghgUnitList} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default MechanicalDashboard;

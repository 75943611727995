import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Paper,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '96%',
    left: '.5%',
    fontFamily: 'Ebrima',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontFamily: 'Ebrima',
  },
}));

export default function PropertyMediaFilterSelect(props) {
  const [ sectionOptions, setSectionOptions ] = useState([]);
  const [ sectionFilter, setSectionFilter ] = useState(0);
  const {
    setSelectedFilter,
    selectedCategory,
    property,
  } = props;

  const onChange = (e) => {
    const value = e.target.value;
    setSectionFilter(value);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if (property) {
      const propertyExists = Object.values(property).length;
      if (propertyExists) {
        const {
          Sections,
        } = property;
        setSectionOptions(Sections);
      }
    }
  }, [property]);

  const previousSectionFilter = usePrevious(sectionFilter);
  useEffect(() => {
    if (sectionFilter != previousSectionFilter) {
      setSelectedFilter(sectionFilter);
    }
  }, [sectionFilter]);

  const previousSelectedCategory = usePrevious(selectedCategory);
  useEffect(() => {
    if (selectedCategory != previousSelectedCategory) {
      setSectionFilter(0);
    }
  }, [selectedCategory]);

  const classes = useStyles();

  return (
    <Grid container direction='row'>
      <Grid xs={12} style={{ padding: '2%' }}>
        <Paper style={{ boxShadow: 'none' }}>

          <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label" style={{fontFamily: 'Ebrima',}}>
              Select Section
            </InputLabel>
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={sectionFilter}
              onChange={onChange}
              className={classes.selectEmpty}
              disabled={!selectedCategory}
            >
              <MenuItem value={0} style={{fontFamily: 'Ebrima',}}>{'All'}</MenuItem>
              { sectionOptions.map((sectionOption) => {
                const sectionId = sectionOption.SectionId;
                const name = sectionOption.Name;
                return (
                  <MenuItem value={ sectionId } style={{fontFamily: 'Ebrima',}}>{ name }</MenuItem>
                )
              }) }              
            </Select>
          </FormControl>

        </Paper>
      </Grid>
    </Grid>
  );
}

import React, { useEffect, useState, useReducer, useRef } from 'react';
import PropertyInventoryCard from './PropertyInventoryCard';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
    Card,
    List,
    CardHeader,
    Collapse,
    Avatar,
    IconButton,
    Typography,
    Grid,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

var moment = require('moment');

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: 'none',
        fontFamily: 'Ebrima',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        fontFamily: 'Ebrima',
    },
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        fontFamily: 'Ebrima',
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

export default function PropertyInventoryGroupCard(props) {
    const classes = useStyles();
    const [expandedGroup, setExpandedGroup] = useState(null);
    const [units, setUnits] = useState([]);
    const [formattedTotal, setFormattedTotal] = useState(0);
    const {
        i,
        inventoryGroup,
        retrievedSelectedInventoryGroups,
        highlightSelectedInventory,
        selectedInventorySection,
        selectedInventorySections,
        groupCost
    } = props;

    const {
        GroupName,
        Count,
        Units,
        WBSInvId
    } = inventoryGroup;
    const groupId = WBSInvId;
    const expanded = groupId == expandedGroup;

    const prevSelectedInventorySection = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const prevSelectedInventorySections = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const handleExpandClick = () => {
        if (!expanded) {
            retrievedSelectedInventoryGroups(groupId);
            setExpandedGroup(groupId);
        }

        if (expanded) {
            retrievedSelectedInventoryGroups(groupId);
            setExpandedGroup(false);
        }
    };

    const handleResetExpanded = () => {
        if (expanded) {
            setUnits(Units);
            setExpandedGroup(false);
        }
    };

    useEffect(() => {
        setUnits(Units);
    }, []);

    useEffect(() => {
        const formatTotal = formatter.format(groupCost);
        setFormattedTotal(formatTotal);
    }, [groupCost]);

    const previousSelectedInventorySection = prevSelectedInventorySection(selectedInventorySection);
    useEffect(() => {
        if (selectedInventorySection != previousSelectedInventorySection) {
            if (expandedGroup) {
                handleResetExpanded();
            }
        }
    }, [selectedInventorySection]);

    const previousSelectedInventorySections = prevSelectedInventorySections(selectedInventorySections);
    useEffect(() => {
        if (selectedInventorySections != previousSelectedInventorySections) {
            if (expandedGroup) {
                handleResetExpanded();
            }
        }
    }, [selectedInventorySections]);

    useEffect(() => {
        const filterUnitsForSelectedSection = () => {
            const unitsCopy = [...new Set(Units)];
            if (selectedInventorySection) {
                const filteredUnitsCopy = unitsCopy.filter(inventory => inventory.SectionId == selectedInventorySection);
                if (filteredUnitsCopy.length > 0) {
                    setUnits(filteredUnitsCopy);
                }
            } else {
                setUnits(Units);
            }
        };
        filterUnitsForSelectedSection();
    }, [expanded], [selectedInventorySection], [groupId]);

    useEffect(() => {
        const filterUnitsForSelectedSections = () => {
            const unitsCopy = [...new Set(Units)];
            if (selectedInventorySections && selectedInventorySections.length > 0) {

                let filteredUnitsCopy = [];

                selectedInventorySections.map((sect) => {

                    unitsCopy.map((unit) => {
                        if (unit.SectionId == sect.SectionId) {
                            filteredUnitsCopy.push(unit);
                        }
                    });
                });

                if (filteredUnitsCopy.length > 0) {
                    setUnits(filteredUnitsCopy);
                }
            }
            else {
                setUnits(Units);
            }
        };
        filterUnitsForSelectedSections();
    }, [expanded], [selectedInventorySections], [groupId]);

    return (
        <Card key={`${i}-inventory-card`} square className={classes.card} style={expanded ? { backgroundColor: 'gainsboro' } : null}>
            <CardHeader classes={{ title: classes.card, subheader: classes.card }}
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        {GroupName.indexOf('Life safety issue') !== -1 ?
                            <WarningIcon />
                            :
                            <ErrorIcon />
                        }


                    </Avatar>
                }
                action={
                    <IconButton className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more">
                        <ExpandMoreIcon />
                    </IconButton>
                }
                title={!selectedInventorySection && (!selectedInventorySections || selectedInventorySections.length == 0) ? `${GroupName} (${Count})` : `${GroupName}`}
                subheader=''//{`Estimated Cost: ${formattedTotal}`}
            />
            <Collapse in={expanded}>
                <Grid container direction='row' style={{ padding: '2%' }}>
                    <Grid item xs={12}>
                        {expanded ? units.map((inventory, i) => {
                            const images = inventory.Images;
                            const name = inventory.Inventory;
                            return (
                                <Grid container>
                                    <Grid container direction='row' alignItems={'center'} justify={'center'}>
                                        <Grid item xs={12}>
                                            <PropertyInventoryCard
                                                inventory={inventory}
                                                images={images}
                                                highlightSelectedInventory={highlightSelectedInventory}
                                                selectedInventorySection={selectedInventorySection}
                                                previousSelectedInventorySection={previousSelectedInventorySection}
                                                selectedInventorySections={selectedInventorySections}
                                                previousSelectedInventorySections={previousSelectedInventorySections}

                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }) : null}
                    </Grid>
                </Grid>
            </Collapse>
        </Card>
    );
}

import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    MenuItem,
    FormHelperText,
    FormControl,
    Select,
    Grid,
    Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        width: '100%',
        margin: theme.spacing(1),
        minWidth: 120,
        textAlign: 'left',
    },
    selectFloor: {
        width: '90%',
        marginTop: theme.spacing(2),
        fontFamily: 'Ebrima',
    },
    selectEmpty: {
        width: '90%',
        marginTop: theme.spacing(2),
        fontFamily: 'Ebrima',
    },
}));

const MantisBlueCheckbox = withStyles({
    root: {
        color: "#0672BA",
        "&$checked": {
            color: "#0672BA"
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

export default function PropertyInventorySectionDropdown(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [sectionValues, setSectionValues] = useState([]);
    const [groupValue, setGroupValue] = useState(0);
    const [levelValue, setLevelValue] = useState(0);
    const [options, setOptions] = useState([]);
    const [optionsGroup, setOptionsGroup] = useState([]);
    const [optionsLevels, setOptionsLevels] = useState([]);
    const [checked, setChecked] = useState(true);

    const {
        selectedProperty,
        retrieveSelectedInventorySection,
        retrieveByGroup,
        filterByGroup,
        displayInventory,
    } = props;

    const handleChange = event => {
        const changedValue = event.target.value;
        setValue(changedValue);
    };

    const handleGroupChange = event => {
        const changedValue = event.target.value;
        console.log(changedValue);
        console.log('Options' + options)
        
        setGroupValue(changedValue);
        setValue(0);
        setSectionValues(groupValue);
    };

    const handleLevelChange = event => {
        const changedValue = event.target.value;
        console.log(changedValue);
        setValue(0);
        setLevelValue(changedValue);
        setSectionValues(groupValue);
    };

    const handleChecked = () => {
        setChecked(!checked);
    };

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    };

    useEffect(() => {
        setOptions(selectedProperty.Sections);
        console.log(options);
    }, []);

    useEffect(() => {
        setOptionsGroup(selectedProperty.SectionGroups);
    }, []);

    useEffect(() => {
        setOptionsLevels(selectedProperty.SectionLevels);
    }, []);

    const previousValue = usePrevious(value);
    useEffect(() => {
        if (value != previousValue) {
            retrieveSelectedInventorySection(value);
        }
    }, [value]);

    useEffect(() => {
        retrieveByGroup(groupValue);
    }, [groupValue]);

    const previousFilterByGroup = usePrevious(filterByGroup);
    useEffect(() => {
        if (filterByGroup != previousFilterByGroup) {
            setValue(0);
        }
    }, [filterByGroup]);
     
    const previousDisplayInventory = usePrevious(displayInventory);
    useEffect(() => {
        if (displayInventory != previousDisplayInventory) {
            setValue(0);
            setChecked(true);
        }
    }, [displayInventory]);

    return (
        <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={12}>
                <form className={classes.root} autoComplete="off" key={'PropertyInvSectionDropForm'}>
                    <FormControl className={classes.formControl}>
                        <Grid container direction='row' alignItems='center' justify='space-between'>
                            <Grid item xs={4}>
                                <Select
                                    value={groupValue}
                                    onChange={handleGroupChange}
                                    name="Group"
                                    className={classes.selectEmpty}
                                    disabled={!displayInventory}
                                    key={'PropertyInvSectionDropGroup'}
                                >
                                    <MenuItem value={0} style={{ fontFamily: 'Ebrima', }} key={'PropertyInvSectionDropGroup0'}>All</MenuItem>
                                    {optionsGroup.map((optionG => <MenuItem value={optionG} key={'PropertyInvSectionDropGroup' + optionG} style={{ fontFamily: 'Ebrima', }}>{optionG}</MenuItem>))}
                                </Select>
                                <FormHelperText key={'PropertyInvSectionDropHeaderLevels'} style={{ fontFamily: 'Ebrima', }}>Group</FormHelperText>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    value={levelValue}
                                    onChange={handleLevelChange}
                                    name="Level"
                                    className={classes.selectEmpty}
                                    disabled={!displayInventory}
                                    key={'PropertyInvSectionDropLevels'}
                                >
                                    <MenuItem value={0} style={{ fontFamily: 'Ebrima', }} key={'PropertyInvSectionDropLevels0'}>All</MenuItem>
                                    {optionsLevels.map((optionL => <MenuItem value={optionL} key={'PropertyInvSectionDropLevels' + optionL} style={{ fontFamily: 'Ebrima', }}>{optionL}</MenuItem>))}
                                </Select>
                                <FormHelperText key={'PropertyInvSectionDropHeaderLevels'} style={{ fontFamily: 'Ebrima', }}>Level</FormHelperText>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    id="section-select"
                                    value={value}
                                    onChange={handleChange}
                                    name="Section"
                                    className={classes.selectEmpty}
                                    disabled={!displayInventory}
                                    key={'PropertyInvSectionDropSelect'}
                                >
                                    <MenuItem value={0} style={{ fontFamily: 'Ebrima', }} key={'PropertyInvSectionDropSelect0'}>All</MenuItem>
                                    {
                                        options.map((
                                            option =>
                                                ((option.SectionGroup == groupValue || groupValue == 0) && (option.Level == levelValue || levelValue == 0) ?
                                                    <MenuItem value={option.SectionId} key={'PropertyInvSectionDropSelect' + option.SectionId + (option.Level > 0 ? option.Level.toString() : '')} style={{ fontFamily: 'Ebrima', }}>{option.SecId}</MenuItem>
                                                   :'')
                                                    
                                                    ))}
                                </Select>
                                <FormHelperText key={'PropertyInvSectionDropHeader'} style={{ fontFamily: 'Ebrima', }}>Section</FormHelperText>
                            </Grid>
                            
                        </Grid>

                    </FormControl>
                </form>
            </Grid>
        </Grid>
    );
}

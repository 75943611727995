import React from 'react';
import ApplicationLayout from '../Application/ApplicationLayout';
import ApplicationStyle from '../../styles/ApplicationStyle';

function ApplicationContainer() {
  const style = ApplicationStyle();

  return (
    <div style={{height: '100%', overflow: 'hidden'}}>
      <ApplicationLayout style={ style } />
    </div>
  )
}

export default ApplicationContainer
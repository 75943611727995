import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import PropertyInventoryList from './PropertyInventoryList';
import PropertyInventoryListHeader from './SubComponents/PropertyInventoryListHeader';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { bool } from 'prop-types';

class PropertyInventory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inventory: null,
      inventoryCategorySelected: null,
      selectedInventorySection: null,
      selectedInventorySections: [],
      selectedInventoryGroupIds: [],
      inventoryGroups: [],
      selectedInventory: [],
      inventoryTotal: null,
      retrievedInventory: null,
      highlightedInventory: null,
      selectedGroupCard: null,
        displayInventory: true,
      filterByGroup: null,
    };
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { 
      inventoryCategorySelected,
      selectedInventorySection,
      selectedInventoryGroupIds,
      selectedInventory,
      inventoryGroups,
      highlightedInventory,
      inventoryTotal,
        displayInventory,
        filterByGroup,
        selectedInventorySections,
    } = this.state;

    const previousHighlightedInventory = prevState.highlightedInventory;
    const previousSelectedInventory = prevState.selectedInventory;
    const previousSelectedInventorySection = prevState.selectedInventorySection;
    const previousInventoryCategorySelected = prevState.inventoryCategorySelected;
    const previousSelectedInventoryGroupIds = prevState.selectedInventoryGroupIds;
      const previousDisplayInventory = prevState.displayInventory;
      const previousFilterByGroup = prevState.filterByGroup;
    
    const {
      selectedProperty,
    } = this.props;

    const allInventoryFilterByGroup = this.filterAllResultsByGroup(selectedProperty.MechanicalUnits);
    const allInventoryGroups = [...new Set(selectedProperty.MechanicalUnits)];
    const allInventoryArray = this.getAllInventory(allInventoryGroups);
    const inventoryGroupsInSection = this.filterInventoryGroups(allInventoryGroups, selectedInventorySection);
    const InventoryFromGroups = this.getInventoryFromGroups(inventoryGroupsInSection, selectedInventorySection);

      if (selectedProperty) {
          console.log('filterByGroup:' + filterByGroup);

          if (displayInventory != previousDisplayInventory) {
              if (displayInventory) {
                  console.log("SELECTED INVENTORY");
                  this.setState({
                      inventoryGroups: allInventoryGroups,
                      selectedInventory: allInventoryArray,
                  })
              }
              if (!displayInventory) {
                  console.log("SELECTED INVENTORY");
                  this.setState({
                      inventoryGroups: [],
                      selectedInventory: [],
                  })
              }
          }

          // if (inventoryCategorySelected) {
          console.log(filterByGroup);
          if (filterByGroup == 0 && allInventoryGroups.length > 0 && displayInventory) {
              if (inventoryGroups.length == 0) {
                  if (!selectedInventorySection) {
                      console.log("SELECTED INVENTORY");
                      this.setState({
                          inventoryGroups: allInventoryGroups,
                          selectedInventory: allInventoryArray,
                      }
                          // , console.log('initialing with allInventoryGroups')
                      )
                  }
                  console.log("SELECTED INVENTORY");
                  if (selectedInventorySection) {
                      if (selectedInventorySection != previousSelectedInventorySection) {
                          this.setState({
                              inventoryGroups: [...new Set(inventoryGroupsInSection)],
                              selectedInventory: [...new Set(InventoryFromGroups)],
                          }
                              // , console.log('initializing with inventoryGroupsInSection')
                          )
                      }
                  }
              }
          }
          // }

          if (inventoryGroups.length > 0) {
              if (inventoryCategorySelected != previousInventoryCategorySelected) {
                  if (inventoryCategorySelected) {
                      const allInventoryArray = this.getAllInventory(allInventoryGroups);
                      console.log("SELECTED INVENTORY");
                      this.setState({
                          selectedInventory: [...new Set(allInventoryArray)],
                      })
                  }
                  if (!inventoryCategorySelected) {
                      this.setState({
                          selectedInventory: [],
                      })
                  }
              }

              //if (selectedInventorySection == previousSelectedInventorySection) {
              console.log("selectedInventorySection same as before");
              if (filterByGroup == 0 && selectedInventoryGroupIds != previousSelectedInventoryGroupIds) {
                  console.log("selectedInventorySection not same");
                  if (selectedInventoryGroupIds.length > 0) {
                      const selectedInventoryArray = this.getSelectedInventory();

                      console.log("SELECTED INVENTORY");

                      this.setState({
                          selectedInventory: [...new Set(selectedInventoryArray)],
                      })
                  }
                  if (filterByGroup == 0 && selectedInventoryGroupIds.length == 0) {
                      let inventory;
                      if (!selectedInventorySection) {
                          inventory = this.getAllInventory(allInventoryGroups);
                      }
                      if (selectedInventorySection) {
                          const inventoryGroupsInSection = this.filterInventoryGroups(allInventoryGroups, selectedInventorySection);
                          const InventoryFromGroups = this.getInventoryFromGroups(inventoryGroupsInSection, selectedInventorySection);
                          inventory = InventoryFromGroups;
                      }
                      console.log("SELECTED INVENTORY");
                      this.setState({
                          selectedInventory: [...new Set(inventory)],
                          highlightedInventory: null,
                      })
                  }
              }
              //}

              if (selectedInventorySection != previousSelectedInventorySection) {
                  if (selectedInventorySection) {
                      if (!previousSelectedInventorySection) {
                          console.log(inventoryGroups);
                          const inventoryGroupsInSection = this.filterInventoryGroups(allInventoryGroups, selectedInventorySection);
                          const InventoryFromGroups = this.getInventoryFromGroups(inventoryGroupsInSection, selectedInventorySection);
                          console.log(inventoryGroupsInSection.length);
                          const totalInventoryCost = (inventoryGroupsInSection.length > 0 ? this.evaluateTotalInventoryCost(inventoryGroupsInSection) : 0);
                          console.log(totalInventoryCost);
                          console.log("SELECTED INVENTORY");
                          this.setState({
                              selectedInventory: [...new Set(InventoryFromGroups)],
                              inventoryGroups: [...new Set(inventoryGroupsInSection)],
                              selectedInventoryGroupIds: [],
                              highlightedInventory: null,
                              inventoryTotal: totalInventoryCost,
                          })
                      }
                      if (previousSelectedInventorySection) {

                          console.log(inventoryGroups);
                          const inventoryGroupsInSection = this.filterInventoryGroups(allInventoryGroups, selectedInventorySection);
                          const InventoryFromGroups = this.getInventoryFromGroups(inventoryGroupsInSection, selectedInventorySection);
                          console.log(inventoryGroupsInSection.length);
                          const totalInventoryCost = (inventoryGroupsInSection.length > 0 ? this.evaluateTotalInventoryCost(inventoryGroupsInSection) : 0);
                          console.log(totalInventoryCost);
                          console.log("SELECTED INVENTORY");
                          this.setState({
                              selectedInventory: [...new Set(InventoryFromGroups)],
                              inventoryGroups: [...new Set(inventoryGroupsInSection)],
                              selectedInventoryGroupIds: [],
                              highlightedInventory: null,
                              inventoryTotal: totalInventoryCost,
                          })
                      }
                  } else {
                      const allInventoryArray = this.getAllInventory(allInventoryGroups);
                      const totalInventoryCost = (inventoryGroupsInSection.length > 0 ? this.evaluateTotalInventoryCost(allInventoryGroups) : 0);
                      console.log(totalInventoryCost);
                      console.log("SELECTED INVENTORY");
                      this.setState({
                          selectedInventory: [...new Set(allInventoryArray)],
                          inventoryGroups: [...new Set(allInventoryGroups)],
                          selectedInventoryGroupIds: [],
                          highlightedInventory: null,
                          inventoryTotal: totalInventoryCost,
                      })
                  }
              } else if (filterByGroup != previousFilterByGroup) {
                  if (filterByGroup != 0) {

                      console.log(selectedProperty.Sections);
                      const sects = selectedProperty.Sections.filter(function (e) {

                          return e.SectionGroup == filterByGroup;

                      });

                      console.log(sects);

                      const inventoryGroupsInSections = this.filterInventoryGroupsBySections(allInventoryGroups, sects);
                      console.log(inventoryGroupsInSections);
                      const inventoryFromGroupsInSections = this.getInventoryFromGroupsBySections(inventoryGroupsInSections, sects);
                      console.log(inventoryFromGroupsInSections);
                      const totalInventoryCost = (inventoryGroupsInSections.length > 0 ? this.evaluateTotalInventoryCost(inventoryGroupsInSections) : 0);
                      //console.log(totalInventoryCost);

                      console.log("SELECTED INVENTORY");
                      console.log(inventoryFromGroupsInSections);
                      console.log(inventoryGroupsInSections);

                      this.setState({
                          selectedInventory: [...new Set(inventoryFromGroupsInSections)],
                          inventoryGroups: [...new Set(inventoryGroupsInSections)],
                          selectedInventoryGroupIds: [],
                          highlightedInventory: null,
                          inventoryTotal: totalInventoryCost,
                          selectedInventorySections: sects,
                      })
                  } else {
                      const allInventoryArray = this.getAllInventory(allInventoryGroups);
                      const totalInventoryCost = (inventoryGroupsInSection.length > 0 ? this.evaluateTotalInventoryCost(allInventoryGroups) : 0);

                      const sects = selectedProperty.Sections.filter(function (e) {

                          return e.SectionGroup == filterByGroup;

                      });

                      console.log(totalInventoryCost);
                      console.log("SELECTED INVENTORY");

                      this.setState({
                          selectedInventory: [...new Set(allInventoryArray)],
                          inventoryGroups: [...new Set(allInventoryGroups)],
                          selectedInventoryGroupIds: [],
                          highlightedInventory: null,
                          inventoryTotal: totalInventoryCost,
                          selectedInventorySections: sects,
                      })
                  }
              }
          }
      }

      //for property's total estimated inventory cost

        if (allInventoryGroups) {
            console.log("allInventoryGroups is valid" + selectedInventorySection);

            
            if (!inventoryTotal) {
                const totalInventoryCost = this.evaluateTotalInventoryCost(allInventoryGroups);

                console.log("Cost:" + totalInventoryCost);
                if (totalInventoryCost) {
                    this.setState({
                        inventoryTotal: totalInventoryCost,
                    })
                } else {
                    this.setState({
                        inventoryTotal: 'N/A',
                    })
                }
                console.log("Cost:" + totalInventoryCost);
            }
        }
    

    const { 
      retrieveSelectedInventorySection,
      retrieveSelectedInventory,
        retrieveHighlightedInventory,
        retrieveByGroup,
    } = this.props;

    if (selectedInventorySection != previousSelectedInventorySection) {
      retrieveSelectedInventorySection(selectedInventorySection);
    }

    if (selectedInventory != previousSelectedInventory) {
      retrieveSelectedInventory(selectedInventory);
    }

    if (highlightedInventory != previousHighlightedInventory) {
      retrieveHighlightedInventory(highlightedInventory);
    }
  };

  filterAllResultsByGroup = (allInventoryGroups) => {
    /*if (allInventoryGroups) {
      const proposedGroups = [];
      const proposedInventoryValue = 2;
      allInventoryGroups.map((inventoryGroup) => {
        const proposedInventoryInGroup = [];
        const inventoryGroupName = inventoryGroup.GroupName;
        const defId = inventoryGroup.WBSInvId;
        const unitCount = inventoryGroup.Count;
        const groupCost = inventoryGroup.GroupCost;
        const inventory = inventoryGroup.Units;
        inventory.map((inventory) => {
          const inventoryStatusState = inventory.InventoryStateLast;
          const proposedStatus = inventoryStatusState.ListId;
          if (proposedStatus == proposedInventoryValue) {
             proposedInventoryInGroup.push(inventory);
          }
        })
        if (proposedInventoryInGroup.length > 0) {
          const filteredInventoryGroup = {
            GroupName: inventoryGroupName,
            WBSInvId: defId,
            Count: unitCount,
            Units: proposedInventoryInGroup,
            GroupCost: groupCost,
          };
          proposedGroups.push(filteredInventoryGroup);
        }
      })
      return [...new Set(proposedGroups)];
    }*/
  };

  evaluateTotalInventoryCost = (allInventoryGroups) => {
    let totalInventoryCost = 0;

    allInventoryGroups.map((inventoryGroup) => {
      const groupCost = inventoryGroup.GroupCost;
      totalInventoryCost = totalInventoryCost + groupCost;
    });

    return totalInventoryCost;
  };

  filterInventoryGroups = (inventoryGroups, selectedInventorySection) => {
    const inventoryGroupsInSection = [];
    inventoryGroups.map((inventoryGroup) => {
        const units = inventoryGroup.Units;
      units.map((inventory) => {
        const inventorySectionId = inventory.SectionId;
          if (inventorySectionId == selectedInventorySection) {
              var alreadyInArray = false;

              inventoryGroupsInSection.map((defCheck) => {
                  if (defCheck.GroupName === inventoryGroup.GroupName && defCheck.WBSInvId === inventoryGroup.WBSInvId && defCheck.Count === inventoryGroup.Count) {
                      alreadyInArray = true;
                  }
              });

              if (!alreadyInArray) {
                  inventoryGroupsInSection.push(inventoryGroup);
              }
          }
      })
    })
    return inventoryGroupsInSection;
  };

    filterInventoryGroupsBySections = (inventoryGroups, sections) => {
        const inventoryGroupsInSection = [];
        inventoryGroups.map((inventoryGroup) => {
           
            const units = inventoryGroup.Units;

            units.map((inventory) => {
                const section = sections.filter(function (e) {

                    return e.SectionId == inventory.SectionId;

                });

                console.log(section);

                if (section.length > 0) {

                    var alreadyInArray = false;

                    inventoryGroupsInSection.map((defCheck) => {
                        if (defCheck.GroupName === inventoryGroup.GroupName && defCheck.WBSInvId === inventoryGroup.WBSInvId && defCheck.Count === inventoryGroup.Count) {
                            alreadyInArray = true;
                        }
                    });

                    if (!alreadyInArray) {
                        inventoryGroupsInSection.push(inventoryGroup);
                    }
                }
            })
        })

        console.log(inventoryGroupsInSection);
        return inventoryGroupsInSection;
    };

  getInventoryFromGroups = (inventoryGroupsInSection, selectedInventorySection) => {
    const inventoryInSection = [];
    inventoryGroupsInSection.map((inventoryGroup) => {
      const inventory = inventoryGroup.Units;
      inventory.map((inventory) => {
          if (selectedInventorySection) {
              if (inventory.SectionId == selectedInventorySection) {
                  inventoryInSection.push(inventory);
              }
          } else {
          inventoryInSection.push(inventory);
        }
      })
    })
    return inventoryInSection;
  };

    getInventoryFromGroupsBySections = (inventoryGroupsInSection, sections) => {
        const inventoryInSection = [];
        console.log(sections);
        console.log(inventoryGroupsInSection);
        inventoryGroupsInSection.map((inventoryGroup) => {
            const inventory = inventoryGroup.Units;
            inventory.map((inventory) => {
                if (sections) {
                    const section = sections.filter(function (e) {
                        return e.SectionId == inventory.SectionId;
                    });

                    if (section.length>0) {
                        inventoryInSection.push(inventory);
                    }
                } else {
                    inventoryInSection.push(inventory);
                }
            })
        })
        
        return inventoryInSection;
    };

  getAllInventory = (inventoryGroups) => {
    const allInventory = [];
    inventoryGroups.map((inventoryGroup) => {
      const units = inventoryGroup.Units;
      units.map((inventory) => {
        allInventory.push(inventory);
      })
    });
    return allInventory;
  };

  getSelectedInventory = () => {
    const { selectedInventoryGroupIds, inventoryGroups, selectedInventorySection } = this.state;
    const inventoryGroupsCopy = [...inventoryGroups];
    const selectedInventoryArray = [];
    selectedInventoryGroupIds.map((selectedInventoryGroupId => {
      const selectedGroup = inventoryGroupsCopy.find(inventoryGroupCopy => inventoryGroupCopy.WBSInvId == selectedInventoryGroupId);
      const selectedGroupInventory = selectedGroup.Units;
      selectedGroupInventory.map((selectedGroupInventory) => {
        const sectionId = selectedGroupInventory.SectionId; 
        if (selectedInventorySection) {
          if (selectedInventorySection == sectionId) {
            selectedInventoryArray.push(selectedGroupInventory);
          }
        } else {
          selectedInventoryArray.push(selectedGroupInventory);
        }
      })
    }))
    return selectedInventoryArray;
  };

  handleInventoryCategorySelected = (selected) => {
    this.setState({
      inventoryCategorySelected: selected,
    })
  };

  handleRepairsCategorySelected = (selected) => {
    this.setState({
      repairsCategorySelected: selected,
    })
  };

  retrieveSelectedInventorySection = (selectedInventorySection) => {
    this.setState({
      selectedInventorySection,
    })
  };

retrieveByGroup = (group) => {
    console.log("Section Group: " + group);
        this.setState({
           
            filterByGroup: group,
        })
    };

    retrievedSelectedInventoryGroups = (inventoryGroupId) => {
        const { selectedInventoryGroupIds } = this.state;
        const included = selectedInventoryGroupIds.includes(inventoryGroupId);
        if (included) {
            const selectedInventoryGroupIdsCopy = [...selectedInventoryGroupIds];
            const filteredInventoryGroups = selectedInventoryGroupIdsCopy.filter(selectedInventoryGroupCopy => selectedInventoryGroupCopy != inventoryGroupId);
            this.setState({
                selectedInventoryGroupIds: filteredInventoryGroups,
            })
        }

        if (!included) {
            this.setState({
                selectedInventoryGroupIds: [...selectedInventoryGroupIds, inventoryGroupId],
            })
        }
    };

  highlightSelectedInventory = (selectedInventoryId) => {
    const { highlightedInventory } = this.state;
    if (selectedInventoryId == highlightedInventory) {
      this.setState({
        highlightedInventory: null,
      })
    } else {
      this.setState({
        highlightedInventory: selectedInventoryId,
      })
    }
  };

  handleDisplayInventory = (checked) => {
    this.setState({
      displayInventory: checked,
    })
  };


  componentWillUnmount = () => {
    console.log('unmounting PROPERTYINVENTORYS');
  };

render() {

    const { 
      selectedProperty,
    } = this.props;

    const { 
        selectedInventorySection,
        selectedInventorySections,
        filterByGroup,
      inventoryGroups,
      inventoryTotal,
        displayInventory,
     } = this.state;

    return (
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item xs={12}>
          <PropertyInventoryListHeader 
            handleDisplayInventory={this.handleDisplayInventory}
          />
        </Grid>
        <Grid item xs={12}>
          <PropertyInventoryList
            handleInventoryCategorySelected={this.handleInventoryCategorySelected}
            retrievedSelectedInventoryGroups={this.retrievedSelectedInventoryGroups}
            retrieveSelectedInventorySection={this.retrieveSelectedInventorySection}
            retrieveByGroup={this.retrieveByGroup}
            highlightSelectedInventory={this.highlightSelectedInventory}
            inventoryGroups={inventoryGroups}
            selectedProperty={selectedProperty}
            selectedInventorySection={selectedInventorySection}
            selectedInventorySections={selectedInventorySections}
            filterByGroup={filterByGroup}
            inventoryTotal={inventoryTotal}
            displayInventory={displayInventory}
          />
        </Grid>
      </Grid>
    )
  }
};

export default PropertyInventory
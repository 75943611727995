import React, { useState, useEffect } from 'react';
import LeakResponseSelectedLocationsCard from './LeakResponseSelectedLocationsCard';
import {
  Grid,
  Paper,
  List,
  Typography,
} from '@material-ui/core';

function LeakResponseLocationList(props) {
  const [ locations, setLocations ] = useState([]);
  const {
    handleRemoveFromSelectedLocations,

    leakResponse,
    width,
  } = props;
  

  useEffect(() => {
    if (leakResponse) {
      const leakResponseExists = Object.values(leakResponse).length;
      if (leakResponseExists) {
        const {
          Locations,
        } = leakResponse;
        setLocations(Locations);
      }
    }
  }, [leakResponse]);

  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>
      <Grid item xs={12} style={{ height: '95%', overflow: 'auto' }}>
        <Grid container direction='row' alignItems='center' justify='center' style={ width > 959 ? { height: '95%', width: '95%' } : { height: '95%', width: '95%', left: '2.5%', position: 'relative' } }>
          <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
            <Paper square style={width > 959 ? { height: '100%', width: '100%', boxShadow: 'none' } : { height: '100%', width: '100%', boxShadow: 'none' } }>
              <List disableGutters style={ width > 959 ? { height: '100%', width: '100%', padding: '0', overflow: 'auto', backgroundColor: 'whiteSmoke' } : { height: '100%', padding: '0', overflow: 'auto', display: 'flex', backgroundColor: 'whiteSmoke' }}>
                { locations.length ? locations.map((location, i) => {
                  const coordinants = location.location;
                  const description = location.Description;
                  return (
                    <LeakResponseSelectedLocationsCard
                      handleRemoveFromSelectedLocations={handleRemoveFromSelectedLocations}

                      location={location}
                      coordinants={coordinants}
                      description={description}
                    />
                  )
                }) :
                  <Paper square style={{ height: '100%', width: '100%', padding: '2%', backgroundColor: 'whiteSmoke' }}>
                    <Grid container direction='row' justify='center' alignItems='center' style={{ height: '100%' }}>
                      <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography style={{ color: 'rgba(0, 0, 0, 0.26)' }}>Provide A Description & Add A Location</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                }
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default LeakResponseLocationList
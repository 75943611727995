import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function PropertyStateSelect(props) {
    const {
        States,
        SelectedStateId,
        handleUpdateBuildingState,
    } = props;

  const handleChange = (event) => {
      handleUpdateBuildingState(event.target.value);
  };

  const classes = useStyles();
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">State</InputLabel>
          <Select
            // disabled={typeDisabled}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={SelectedStateId}
            onChange={handleChange}
          >
                      <option aria-label="None" value={null} />
                      {States.map((State, i) => {
                          const name = State.Name;
                          const id = State.Id
                          const key = `${i}=${id}`;
                          return (
                              <option key={key} value={id}>{name}</option>
                          )
                      })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

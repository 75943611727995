import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function PropertyCondition(props) {
  const {
  } = props;


  return (
    <LinearProgress style={{ width: '100%' }} />
  );
}



import React, { Component } from 'react';
import PropertyLoading from '../SubComponents/PropertyLoading';
import PropertyGraphsNoDataCard from './PropertyGraphsNoDataCard';
import {
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import {
  Typography,
} from '@material-ui/core';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const graphContainerStyle = {
  textAlign: 'center',
  // overflow: 'overlay',
  minWidth: '21rem',
  minHeight: '14.78rem',
  fontFamily:'Ebrima',
};

const barChartMarginStyle = {
  top: 10,
  right: 50,
  left: 20,
  bottom: 10,
  fontFamily:'Ebrima',
};

const XAxisTickFormatter = (value) => {
  return `${new Intl.NumberFormat('en').format(value)}`;
};

const CustomTooltipContent = (props) => {
  const payload = props.payload;

  if (props.active) {
    const newPayload = payload;
    if (payload) {
      payload.map((entry, i) => {
        const dataKey = entry.dataKey;
        const id = payload.indexOf(entry);
        if (dataKey == 'uv') {
          const newEntry = {
            color: '#707070',
            dataKey: entry.dataKey,
            fill: '#707070',
            formatter: entry.formatter = (value) => XAxisTickFormatter(value),
            name: entry.name,
            payload: entry.payload,
            type: entry.type,
            unit: entry.unit,
            value: entry.value,
          }
          newPayload.splice(id, 1, newEntry);
        } else {
          entry.formatter = (value) => XAxisTickFormatter(value);
        }
      })
    }
    return <DefaultTooltipContent {...props} payload={newPayload} />
  } else {
    return <DefaultTooltipContent {...props} payload={payload} />
  }
};


class PropertyConditionGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: null,
      defectData: null,
    }
  }

  componentDidMount = () => {
    const { defectData } = this.props;

    if (defectData.length == 0) {
      this.setState({
        noChartData: true,
      })
    }
  };

  render() {
    const { 
      openDefectsWindow, 
      defectData 
    } = this.props;
    
    const { 
      loading, 
      noChartData 
    } = this.state;

    return (
      <div style={{ display: 'grid', overflow: 'hidden' }}>
        <div id='graph-container' style={graphContainerStyle} >
          <Typography color='subtitle2'   style={{fontFamily:'Ebrima',}}>Defects Vs. Repairs</Typography>
          <ResponsiveContainer width={'100%'} aspect={12.0 / 6.0}>
            { noChartData ? <PropertyGraphsNoDataCard /> : 
            <ComposedChart
              style={defectData.length > 0 ? { cursor: 'pointer' } : { cursor: 'wait' }}
              onClick={defectData.length > 0 ? () => openDefectsWindow() : null}
              data={defectData}
              margin={barChartMarginStyle}
              layout="vertical"
            >
              <CartesianGrid 
                strokeDasharray="3 3" 
              />
              <YAxis 
                type="category" 
                dataKey="name" 
                angle={-45} 
                textAnchor="end" 
              />
              <XAxis 
                type="number" 
                tickFormatter={XAxisTickFormatter} 
              />
              <Tooltip 
                content={<CustomTooltipContent props={defectData} />} 
              />
              <Legend />
              <Bar 
                barSize={25}
                dataKey="de" 
                stackId="a" 
                name="Defects" 
                fill="#B53F51"  
                style={ defectData ? { cursor: 'pointer' } : { cursor: 'wait' } } 
              />
              <Bar 
                barSize={25}
                dataKey="re" 
                stackId="a" 
                name="Repairs" 
                fill="#3fb5a3" 
                style={defectData ? { cursor: 'pointer' } : { cursor: 'wait' }} 
              />
            </ComposedChart>
            }
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default PropertyConditionGraph

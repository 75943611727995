import React, { useState, useEffect, useRef } from 'react';
import {
  InputLabel,
  FormControl,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ToCompanySelect(props) {
  const [selectedCompany, setSelectedCompany] = useState({});
  const {
    handleToCompanySelect,
    moveToCompanies,
    selectedFromBuildings,
    toSelectedCompany,
    masterSelectedCompany
  } = props;

  const handleChange = (e) => {
    const id = e.target.value;
    const company = moveToCompanies.find(company => company.CompanyId == id);
    if (company) {
      setSelectedCompany(company);
    } else {
      setSelectedCompany({});
    }
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousSelectedCompany = usePrevious(selectedCompany);
  useEffect(() => {
    if (selectedCompany != previousSelectedCompany) {
      const selectedCompanyExists = Object.values(selectedCompany).length;
      if (selectedCompanyExists) {
        const { CompanyId } = selectedCompany;
        if (CompanyId) {
          handleToCompanySelect(selectedCompany);
        }        
      } else {
        handleToCompanySelect({});
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (toSelectedCompany) {
      const toSelectedCompanyExists = Object.values(toSelectedCompany).length;
      if (toSelectedCompanyExists) {
        const toSelectedCompanyId = toSelectedCompany.CompanyId;
        const selectedCompanyId = selectedCompany.CompanyId;
        if (toSelectedCompanyId != selectedCompanyId) {
          // if (!toSelectedCompanyId) {
            setSelectedCompany({});
          // }
        }
      }
    }
  }, [toSelectedCompany]);

  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="age-native-simple">To Company</InputLabel>
      <Select
        native
        disabled={!selectedFromBuildings.length}
        value={selectedCompany.CompanyId ? selectedCompany.CompanyId : ''}
        onChange={handleChange}
        inputprops={{
          name: 'age',
          id: 'age-native-simple',
        }}
      >
        <option aria-label="None" value={''} />
        { moveToCompanies ? moveToCompanies.map((company) => {
          const name = company.Name;
          const id = company.CompanyId;
          return (
            <option disabled={masterSelectedCompany.CompanyId == id} value={id}>{name}</option>
          )
        }) : null }
      </Select>
    </FormControl>
  );
}

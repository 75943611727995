import React, { useState, useEffect } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Grid,
  Avatar,
  CardHeader,
  Divider,
  Collapse,
  Button,
  Typography,
} from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    fontFamily: 'Ebrima',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    fontFamily: 'Ebrima',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    fontFamily: 'Ebrima',
  },
  avatar: {
    backgroundColor: '#00C2F3',
    fontFamily: 'Ebrima',
  },
  button: {
    backgroundColor: '#00C2F3',
    color: 'white',
    fontFamily: 'Ebrima',
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    fontFamily: 'Ebrima',
  },
  expandOpen: {
    transform: 'rotate(0deg)',
    fontFamily: 'Ebrima',
  },
}));

export default function PropertyWarrantyCard(props) {
  const [ expand, setExpand ] = useState(false);
  const [ fileName, setFileName ] = useState('');
  const [ fileUrl, setFileUrl ] = useState('');
  const [ sections, setSections ] = useState([]);
  const [ expirationDate, setExpirationDate ] = useState('');
  const [ startDate, setStartDate ] = useState('');
  const [ manufacturer, setManufacturer ] = useState('');
  const [ contractor, setContractor ] = useState('');
  const [ notes, setNotes ] = useState('');
  const {
    i,
    warrantyFile,
  } = props;

  const handleOpenFile = (destination) => {
    const url = `https://bluefinfiles.s3-us-west-2.amazonaws.com/${destination}`;
    var win = window.open(url, '_blank');
    win.focus();
  };

  useEffect(() => {
    if (warrantyFile) {
      const warrantyFileExists = Object.values(warrantyFile).length;
      if (warrantyFileExists) {
        const {
          FileName,
          FileUrl,
          Sections,
          StartDate,
          Duration,
          Manufacturer,
          Contractor,
          Notes
        } = warrantyFile;
        if (StartDate) {
          let expirationDate = new Date(StartDate);
          expirationDate.setFullYear(expirationDate.getFullYear() + Duration);
          setExpirationDate(expirationDate.toISOString().slice(0, 10));
          let startDateTimeStamp = new Date(StartDate);
          const startDate = startDateTimeStamp.toISOString().slice(0, 10);
          setStartDate(startDate);
        }
        if (FileName) {
          setFileName(FileName);
        }
        if (FileUrl) {
          setFileUrl(FileUrl);
        }
        if (Sections) {
          setSections(Sections);
        }
        if (Manufacturer) {
          setManufacturer(Manufacturer);
        }
        if (Contractor) {
          setContractor(Contractor);
        }
        if (Notes) {
          setNotes(Notes);
        }
      }
    }
  }, [warrantyFile]);

  const classes = useStyles();
  return (
    <Card key={`${i}-warranty-card`} square className={classes.root}>
      <CardHeader classes={{title: classes.root, subheader: classes.root}}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            <InsertDriveFileIcon />
          </Avatar>
        }
        action={
          <IconButton 
            aria-label="settings"
            className={clsx(classes.expand, {
              [classes.expandOpen]: expand,
            })} 
            onClick={()=>setExpand(!expand)}
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        title={`File Name: ${fileName}`}
        subheader={`Expires: ${expirationDate}`}
      />
      <Divider />
      <Collapse in={expand}>
        <Grid container direction='row' justify='center' alignItems='center'>
          <CardContent style={{ paddingTop: '2%', paddingBottom: '2%' }}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
              <Grid xs={5} style={{ textAlign: 'left' }}>
                <Typography variant="body2" style={{fontFamily: 'Ebrima',}} color="textSecondary" component="p">
                  Sections:
                </Typography>
              </Grid>
              <Grid xs={5} style={{ textAlign: 'left' }}>
                <Typography variant="body2"  style={{fontFamily: 'Ebrima',}}  color="textPrimary" component="p">
                  { sections.join(', ') }
                </Typography>
              </Grid>
              <Grid xs={5} style={{ textAlign: 'left', fontFamily: 'Ebrima' }}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Manufacturer:
                </Typography>
              </Grid>
              <Grid xs={5} style={{ textAlign: 'left' }}>
                <Typography variant="body2" color="textPrimary"  style={{fontFamily: 'Ebrima',}}  component="p">
                  {manufacturer}
                </Typography>
              </Grid>
              <Grid xs={5} style={{ textAlign: 'left' }}>
                <Typography variant="body2" color="textSecondary"  style={{fontFamily: 'Ebrima',}}  component="p">
                  Contractor:
            </Typography>
              </Grid>
              <Grid xs={5} style={{ textAlign: 'left' }}>
                <Typography variant="body2" color="textPrimary"  style={{fontFamily: 'Ebrima',}}  component="p">
                  {contractor}
                </Typography>
              </Grid>
              <Grid xs={5} style={{ textAlign: 'left' }}>
                <Typography variant="body2" color="textSecondary"  style={{fontFamily: 'Ebrima',}}  component="p">
                  StartDate:
            </Typography>
              </Grid>
              <Grid xs={5} style={{ textAlign: 'left' }}>
                <Typography variant="body2" color="textPrimary"  style={{fontFamily: 'Ebrima',}}  component="p">
                  {startDate}
                </Typography>
              </Grid>
              <Grid xs={5} style={{ textAlign: 'left' }}>
                <Typography variant="body2" color="textSecondary"  style={{fontFamily: 'Ebrima',}}  component="p">
                  Notes:
            </Typography>
              </Grid>
              <Grid xs={5} style={{ textAlign: 'left' }}>
                <Typography variant="body2" color="textPrimary"  style={{fontFamily: 'Ebrima',}}  component="p">
                  {notes}
                </Typography>
              </Grid>
            </Grid>
        </CardContent>
        </Grid>
        <Divider />
        <Grid container direction='row' alignItems='center' justify='center'>
          <CardActions disableSpacing>
            <Button
              onClick={()=>handleOpenFile(fileUrl)}
              className={classes.button}
              size='small'
              aria-label="download"
              startIcon={<CloudDownloadIcon />}
            >
              Download
            </Button>
          </CardActions>
        </Grid>
      </Collapse>
    </Card>
  );
}

import React from 'react';
import {
  Card,
  CardHeader,
  ListItem,
} from '@material-ui/core';
import ManageContactsCardStyle from '../../../styles/ManageContactsStyles/ManageContactsCardStyle';

export default function CompanyBuildingsCard() {
  const classes = ManageContactsCardStyle();
  const { card } = classes;

  return (
    <ListItem>
      <Card className={card}>
        <CardHeader 
          align={'center'}
          subheader={'No Buildings for Selected Contact Type'}
        />
      </Card>
    </ListItem>
  );
}
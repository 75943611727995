import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '95%',
  },
}));

export default function AddSubmittedByEmailSelect(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState('Controlled');

  const {
    retrieveSubmitterEmail,
    category3Finished,
    workOrderSubmitter
  } = props;

  const handleChange = e => {
    const text = e.target.value;
    setValue(text);
  };

  useEffect(() => {
    if (workOrderSubmitter) {
      const email = 'hamburgers@gmail.com';
      setValue(email);
    } else {
      setValue(null);
    }
  }, [workOrderSubmitter]);

  useEffect(() => {
    retrieveSubmitterEmail(value);
  }, [value]);


  return (
    <form className={classes.container} noValidate autoComplete="off">
       <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
        disabled={!category3Finished || workOrderSubmitter}
        value={value ? value : ''}
        onChange={handleChange}
        id="standard-textarea"
        label="Submitted Email"
        placeholder="Submitted Email"
        multiline
        className={classes.textField}
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />
    </form>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import EditStatusModal from './EditStatusModal';
import StatusHistoryModal from './StatusHistoryModal';
import {
  IconButton,
  Button,
  Typography,
  Grid,
  Paper,
} from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';

export default function ControlledOpenSelect(props) {
  const [ choice, setChoice ] = useState(null);
  const [ note, setNote ] = useState(null);
  const [ selectedStatus, setSelectedStatus ] = useState(null);
  const [ openEdit, setOpenEdit ] = useState(false);
  const [ openHistory, setOpenHistory ] = useState(false);

  const { 
    editWorkOrder, 
    currentStatus, 
    status, 
    statusList, 
    statusHistory, 
    dispatchWorkOrderStatus 
  } = props;

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleClickCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClickOpenHistory = () => {
    setOpenHistory(true);
  };

  const handleClickCloseHistory = () => {
    setOpenHistory(false);
  };

  const retrieveUpdatedStatus = (updatedStatus) => {
    if (currentStatus) {
      const currentStatusId = currentStatus.WOStatusTypeID;
      const updatedStatusId = updatedStatus.WostatusTypeId;
      if (currentStatusId != updatedStatusId) {
        setChoice(updatedStatus);
      }
    } else {
      setChoice(updatedStatus);
    }
  };

  const retrieveUpdatedNote = (note) => {
    setNote(note);
  };

  useEffect(() => {
    if (!openEdit) {
      if (choice) {
        setSelectedStatus(choice);
      }
    }
  }, [!openEdit]);

  useEffect(() => {
    if (currentStatus) {
      setSelectedStatus(currentStatus);
    } 
  }, [currentStatus]);

  useEffect(() => {
    if (selectedStatus) {
      if (selectedStatus != currentStatus) {
        const selectedStatusId = selectedStatus.WostatusTypeId;
        const currentStatusId = currentStatus.WOStatusTypeID;
        if (selectedStatusId != currentStatusId) {
          dispatchWorkOrderStatus(selectedStatus, note);
        }
      }
    }
  }, [selectedStatus]);

  return (
    <Paper style={{ margin: '2%', height: '3.75rem', boxShadow: 'none', backgroundColor: '#66BFFA'}}>
      <Grid container direction='row' alignItems='center' justify='center' style={{ }}>
        <Grid item xs={3} style={{ height: '100%', textAlign: 'center' }}>
          <Typography variant='h6' style={{ color: 'steelblue' }}>
            Status:
          </Typography>
        </Grid>
        <Grid item xs={7} style={{ height: '100%' }}>
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={12}>
              <Button size='large' color='primary' variant='contained' style={{ width: '100%', maxHeight: '3rem', overflow: 'hidden', whiteSpace: 'nowrap' }} onClick={handleClickOpenEdit}>{ selectedStatus ? selectedStatus.Name : 'Not Defined' }</Button>
              <EditStatusModal 
                retrieveUpdatedStatus={retrieveUpdatedStatus}
                retrieveUpdatedNote={retrieveUpdatedNote}
                handleClickCloseEdit={handleClickCloseEdit}
                openEdit={openEdit}
                statusList={statusList}
                selectedStatus={selectedStatus}
                editWorkOrder={editWorkOrder}
              />
            </Grid>
          </Grid>        
        </Grid>
        <Grid item xs={2}>
          <IconButton style={{ margin: '8%' }} onClick={handleClickOpenHistory}>
            <HistoryIcon />
          </IconButton>
          <StatusHistoryModal 
            handleClickCloseHistory={handleClickCloseHistory}
            openHistory={openHistory}
            status={status}
            statusHistory={statusHistory}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

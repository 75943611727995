import React, { useState, useEffect } from 'react';
import AddContactDropDown from './AddContactDropDown';
import {
  Card,
  Grid,
  List,
  CardHeader,
  CardContent,
  Button,
} from '@material-ui/core';
import ManageContactsAddContactModalCardStyle from './Styles/ManageContactsAddContactModalCardStyle';
import CompanyBuildingsCard from './CompanyBuildingsCard';
import NoAvailableBuildings from './NoAvailableBuildings';

export default function ManageContactsAddContactModal(props) {
  const [count, setCount] = useState(0);
  const [buttonCount, setButtonCount] = useState(0);
  const [buttonBegin, setButtonBegin] = useState(1);
  const [buttonEnd, setButtonEnd] = useState(6);

  const {
    filteredContacts,
    companyBuildings,
    selectContactType,
    selectBuilding,
    saveAddContacts,
    selectedContactType,
  } = props;

  const contact = filteredContacts[0];
  const companyBuildingsCopy = companyBuildings ? [...companyBuildings] : [];
  const companyAssetsAndTypes = companyBuildingsCopy[0];
  const buildings = companyAssetsAndTypes.Buildings;
  const dataSize = buildings.length / 25;

  let buildingDataSet;

  const selectPaginationResults = ({nativeEvent}) => {
    const { target } = nativeEvent;
    const multiple = target.innerHTML;
    setCount(25 * multiple);
    buildingDataSet = buildings.slice(count, count + 25);
  };

  const createPagination = () => {
    let buttonArr = [];
    for (let i = buttonBegin; i < buttonEnd; i++) {
      buttonArr.push(i);
    }
    setButtonCount(buttonArr);
  };

  const incrementPagination = () => {
    if (buttonEnd < dataSize) {
      setButtonBegin(buttonBegin + 1);
      setButtonEnd(buttonEnd + 1);
    }
  };

  const decrementPagination = () => {
    if (buttonBegin < 1) {
      setButtonBegin(buttonBegin - 1);
      setButtonEnd(buttonEnd - 1);
    }
  };

  useEffect(() => {
    createPagination();
  }, [buttonBegin]);

  if (buildings.length > 25) {
    buildingDataSet = buildings.slice(count, count + 25).sort();
  } else {
    buildingDataSet = buildings.sort();
  }
  
  const {
    FirstName,
    LastName,
  } = contact;

  const classes = ManageContactsAddContactModalCardStyle();
  const {
    card,
    cardContent
  } = classes;

  return (
      <div>
        <Grid container>
          <Card className={card}>
            <Grid direction={'row'} justify={'center'} style={{display: 'flex', alignItems: 'center'}} item>
              <CardHeader
                align={'center'}
                title={ `Add ${ FirstName } ${ LastName } as a Contact` }
              />
              <Button style={{ width: 'fit-content', height: 'fit-content' }} variant="contained" color="primary" onClick={() => saveAddContacts()}>Save</Button>
            </Grid>
            <CardContent className={cardContent} align={'center'}>
              <Grid xs={4} item>
                <AddContactDropDown
                  companyAssetsAndTypes={ companyAssetsAndTypes }
                  selectContactType={ selectContactType }
                /> 
              </Grid>

            { buildings.length > 25 ?
              <div style={{margin: '2%'}}>
                <Button onClick={() => decrementPagination()}> - </Button>
                { buttonCount.map(number => <Button value={number} onClick={selectPaginationResults}>{number}</Button>) }
                <Button onClick={() => incrementPagination()}> + </Button>
              </div>
            : null }

              <Grid xs={8} item>
                <List style={{maxHeight: '25rem', overflowY: 'auto'}}>  
                  <div style={{ margin: '5%' }}>
                    { selectedContactType ? buildingDataSet.map(building =>
                      <CompanyBuildingsCard
                        building={ building }
                        selectBuilding={ selectBuilding }
                        companyAssetsAndTypes={ companyAssetsAndTypes }
                      /> )
                    : null }
                    { selectedContactType && buildings.length == 0 ? <NoAvailableBuildings /> : null }
                  </div>
                </List>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </div>
  );
}

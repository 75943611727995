import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Paper,
    Typography,
    List,
    ListItem,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        paddingTop: '0',
        border: 'solid 0px red',
        fontFamily: 'Ebrima'
    },
}));

export default function Header(props) {
  const classes = useStyles();
  const [titleHovered, setTitleHovered] = React.useState(false);
  const [cardHovered, setCardHovered] = React.useState(null);
  const { 
    headerData
   } = props;

   const labelStyle = {
     padding: '1%',
     color: 'darkGrey',
     fontFamily: 'Ebrima',
   };

   const headerStyle = {
    color: 'white',
    fontFamily: 'Ebrima',
  };

   const numberStyle = {
     padding: '1%',
     color: 'black', 
     fontWeight: 'bold',
     fontFamily: 'Ebrima',
   };
   
    return (
          <Grid container direction='row' alignItems={'center'} justify={'center'}>
            
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ padding: '1%' }}>
              <Paper style={{width: '100%', padding: '2%'}}>
                <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                  <Grid container direction='row' alignItems='center' justify='center'>
                    <Grid item xs={12} style={{ width: '100%', backgroundColor: '#0672BA', textAlign: 'center'}}>
                      <Typography variant='h6' style={headerStyle}>Assets</Typography>
                    </Grid>
                  </Grid>
                  <Grid direction='row' style={{ width: '100%', display: 'flex' }}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <Typography variant='subtitle1' style={labelStyle}> Properties: </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ textAlign: 'right' }}>
                      <Typography variant='subtitle1' style={numberStyle}> {headerData != null ?headerData.properties:''}</Typography>
                    </Grid>
                  </Grid>
                  <Grid direction='row' style={{ width: '100%', display: 'flex' }}>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Typography variant='subtitle1' style={labelStyle}> Mechanical Units: </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ textAlign: 'right' }}>
                        <Typography variant='subtitle1' style={numberStyle} >{headerData != null ?headerData.mechUnitCnt:''}</Typography>
                      </Grid>
                    </Grid>
               
                  <Grid direction='row' style={{ width: '100%', display: 'flex' }}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <Typography variant='subtitle1' style={labelStyle}> </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ textAlign: 'right' }}>
                      <Typography variant='subtitle1' style={numberStyle}></Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ padding: '1%' }}>
              <Paper style={{ width: '100%', padding: '2%' }}>
                <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                  <Grid container direction='row' alignItems='center' justify='center'>
                    <Grid item xs={12} style={{ width: '100%', backgroundColor: '#0672BA', textAlign: 'center' }}>
                      <Typography variant='h6' style={headerStyle}>Condition</Typography>
                    </Grid>
                  </Grid>
                  
                    <Grid direction='row' style={{ width: '100%', display: 'flex' }}>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Typography variant='subtitle1' style={labelStyle}> Criticality/ERSL (yrs): </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ textAlign: 'right' }}>
                        <Typography variant='subtitle1' style={numberStyle}> {headerData != null ?headerData.avgcs:''} / {headerData != null && headerData.avgersl>0?headerData.avgersl:'N/A'}</Typography>
                      </Grid>
                    </Grid>
                      <Grid direction='row' style={{ width: '100%', display: 'flex'}}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                          <Typography variant='subtitle1' style={labelStyle}>Repairs:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ textAlign: 'right' }}>
                          <Typography variant='subtitle1' style={numberStyle}> {headerData != null ?headerData.repairs:''} </Typography>
                        </Grid>
                      </Grid>
                   
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ padding: '1%' }}>
              <Paper style={{ width: '100%', padding: '2%' }}>
                <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                  <Grid item xs={12} style={{ width: '100%', backgroundColor: '#0672BA' }}>
                    <Grid container direction='row' alignItems='center' justify='center'>
                      <Grid item xs={12} style={{ width: '100%', backgroundColor: '#0672BA', textAlign: 'center' }}>
                        <Typography variant='h6' style={headerStyle}>Financial</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                  <Grid direction='row' style={{ width: '100%', display: 'flex'}}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <Typography variant='subtitle1' style={labelStyle}> Operating Expenses ($): </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ textAlign: 'right' }}>
                      <Typography variant='subtitle1' style={numberStyle}> {headerData != null ? headerData.oe : ''} </Typography>
                    </Grid>
                  </Grid>
                  <Grid direction='row' style={{ width: '100%', display: 'flex' }}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <Typography variant='subtitle1' style={labelStyle}> Replacements ($): </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ textAlign: 'right' }}>
                      <Typography variant='subtitle1' style={numberStyle}> {headerData != null ?headerData.replace:''} </Typography>
                    </Grid>
                  </Grid>
                  <Grid direction='row' style={{ width: '100%', display: 'flex'}}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                          <Typography variant='subtitle1' style={labelStyle}>{''}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ textAlign: 'right' }}>
                          <Typography variant='subtitle1' style={numberStyle}> {''} </Typography>
                        </Grid>
                      </Grid>
                </Grid>
              </Paper>
            </Grid>

          </Grid>
    );
}

import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Paper,
  TextField,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'

export default function PropertyContactName(props) {
  const [ disabled, setDisabled ] = useState(true);
  const [ description, setDescription ] = useState('');

  const {
    handleDescriptionChange,
    
    selectedCoordinants,
    selectedLocations,
    locationDescription,
    width,
  } = props;

  const handleChange = (e) => {
    const text = e.target.value;
    setDescription(text);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if (selectedCoordinants) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedCoordinants]);

  useEffect(() => {
    if (description) {
      handleDescriptionChange(description);
    }
  }, [description]);

  //reset after a new location card is created
  useEffect(() => {
    if (selectedLocations) {
      const found = selectedLocations.find(selectedLocation => selectedLocation.description == description);
      if (found) {
        if (locationDescription != description) {
          if (!locationDescription) {
            setDescription('');
          }
        }
      }
    }
  }, [selectedLocations]);

  //reset after delete card
  const previousLocationDescription = usePrevious(locationDescription);
  useEffect(() => {
    if (locationDescription != previousLocationDescription) {
      if (!locationDescription) {
        setDescription('');
      }
    }
  }, [locationDescription]);

  return (
    <Grid container direction='row'>
      <Grid xs={12} style={{padding: '1%'}}>
        <Paper square style={{ width: '100%', boxShadow: 'none' }}>
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={12}>
               <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                id="standard-full-width"
                placeholder="Describe the Leak"
                label="Description"
                margin="normal"
                autoFocus
                style={{ margin: '0', width: '99%', left: '.5%', bottom: '5%' }}
                disabled={disabled}
                onChange={handleChange}
                value={description}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}


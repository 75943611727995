import React from 'react';
import ApplicationSidebarLogo from '../ApplicationSidebar/ApplicationSidebarLogo';
import ApplicationHeaderAvatar from './ApplicationHeaderAvatar';
import ApplicationFeedBackButton from './Subcomponents/ApplicationFeedBackButton';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Grid,
  Button,
  IconButton,
  Avatar,
  Fab,
  Typography,
  ButtonGroup,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ApplicationSidebarStyle from '../../../styles/ApplicationSidebarStyle';
import SvgUsers from '../../SvgIcons/SvgUsers';
import SvgDashboard from '../../SvgIcons/SvgDashboard';
import SvgReports from '../../SvgIcons/SvgReports';
import SvgTags from '../../SvgIcons/SvgTags';
import SvgWorkOrders from '../../SvgIcons/SvgWorkOrders';
import SvgProperties from '../../SvgIcons/SvgProperties';
import SvgContacts from '../../SvgIcons/SvgContacts';
import SvgDocs from '../../SvgIcons/SvgDocs';
import SvgSiteMap from '../../SvgIcons/SvgWorldMap';

const list = {
  color: 'white',
  height: '100%',
  width: '90%',
  margin: '5%',
  fontFamily: 'Ebrima', 
};

const listItem = {
  margin: 10,
  lineHeight: 0,
  cursor: 'pointer',
  fontFamily: 'Ebrima', 
};

function ApplicationSidebarMenus(props) {
  const { 
    menus, 
    selectRoute,
    handleTopbar,
    siteFilterSelect,
    siteFilterOpen,
    handleOpenFeedback,
  } = props;

  const listItemHeight = `${(10/9)*10}%`;

  return (
      <Paper square style={{ backgroundColor: '#66BFFA', position: 'fixed', left: '0', top: '55px', width: '100%', height: '92%', border: '10px solid lightBlue', overflow: 'auto' }}>
        <Grid container direction='column' alignItems='center' justify='space-evenly' style={{height: '100%', display: 'block'}}>
          
          <Grid item xs={12} style={{ height: '10%' }}>
            <Grid container direction='row' alignItems='center' justify='center'>
              <Grid item xs={12}>
                <ApplicationSidebarLogo />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ height: '10%' }}>
            <Grid container direction='row' alignItems='center' justify='center' style={{height: '100%'}}>
              <Grid item xs={4}>
                <Button variant='contained' color='secondary' onClick={()=>siteFilterSelect()} style={{fontSize: '96%', boxShadow: 'none', fontFamily: 'Ebrima', }}>Site Filter</Button>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <ApplicationFeedBackButton
                  handleOpenFeedback={handleOpenFeedback}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ height: '60%' }}>
            <Grid container direction='row' alignItems='center' justify='center' style={{height: '100%', fontFamily: 'Ebrima', }}>
              <Grid xs={10} style={{ height: '100%' }}>
                <Grid alignItems='center' justify='center'style={{color: 'white', height: '100%', width: '100%' }}>
                <Paper style={{ color: 'white', height: '100%' }}>
                    {
                      menus.map((menu, i) => {
                        const title = menu.Title;
                        const route = menu.Route;
                        const icon = menu.Icon;
                        return (
                          <div style={{display: 'contents'}}>
                            <Grid container direction='row' alignItems='center' justify='center' key={'divmenu' + i} onClick={() => selectRoute(route, title)} style={{ backgroundColor: '#0672BA', height: listItemHeight, width: '100%' }}>
                              <Grid item xs={2} style={{ height: '66%', width: '100%', textAlign: 'center', fontFamily: 'Ebrima',  }}>
                                  {icon === 'Dashboard' ? <SvgDashboard props={props} /> : ''}
                                  {icon === 'ManageUsers' ? <SvgUsers props={props} /> : ''}
                                  {icon === 'ManageContacts' ? <SvgContacts props={props} /> : ''}
                                  {icon === 'Properties' ? <SvgProperties props={props} /> : ''}
                                  {icon === 'Documents' ? <SvgDocs props={props} /> : ''}
                                  {icon === 'SiteMap' ? <SvgSiteMap props={props} /> : ''}
                                  {icon === 'TagManager' ? <SvgTags props={props} /> : ''}
                                  {icon === 'Reports' ? <SvgReports props={props} /> : ''}
                                  {icon === 'WorkOrders' ? <SvgWorkOrders props={props} /> : ''}
                              </Grid>
                              <Grid item xs={10} style={{ textAlign: 'left' }}>
                                <Typography variant='body1' style={{ marginLeft: '20%', marginRight: '20%', fontFamily: 'Ebrima',  }}>{title}</Typography>
                              </Grid>
                            </Grid>
                            <Divider style={{ width: '100%', backgroundColor: '#66BFFA' }} />
                          </div>
                        )
                      })
                    }
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ height: '20%' }}>
            <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%', width: '100%' }}>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                <Fab onClick={()=>handleTopbar()}>
                  <CloseIcon />
                </Fab>
              </Grid>
            </Grid> 
          </Grid> 

        </Grid>
      </Paper>
  )
};

export default ApplicationSidebarMenus
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  CardHeader, 
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  card: {
    height: '14rem',
    width: '95%',
    margin: '2%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function EditWorkOrderItemCard(props) {
  const { workOrderItem, handleOpenWorkOrderItems, handleDeleteWorkOrderItem } = props;
  const {
    Name, 
    Recommendation,
    TotalEstimate,
    TotalItemDetail,
    Woid,
    WoitemId,
    WoitemNum,
  } = workOrderItem;

  const classes = useStyles();

  const handleEditClick = () => {
    handleOpenWorkOrderItems(workOrderItem);
  };

  const handleDeleteClick = () => {
    handleDeleteWorkOrderItem(workOrderItem);
  };

  return (
      <Card className={classes.card}>
        <CardHeader 
          style={{ height: '4rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%'}}
          title={Name} 
          subheader={Recommendation}
          action={
            <div>
            <IconButton onClick={()=>handleEditClick()}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={()=>handleDeleteClick()}>
              <DeleteIcon />
            </IconButton>
            </div>
          }
        />
        <CardContent>
          <Grid container direction='row'>
            <Grid item xs={12} style={{ height: '6rem' }}>

            </Grid>
          <Grid item xs={12} style={{ height: '2rem' }}>
            <Typography variant='h6'>Estimated Cost: ${TotalEstimate}</Typography>
          </Grid>
          </Grid>
        </CardContent>
      </Card>
  );
}

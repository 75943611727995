import React, { useState, useEffect, useRef } from 'react';
import PropertyImages from './PropertyImages';
import PropertyMediaImageTypeSelect from '../PropertyMedia/Subcomponents/PropertyMediaImageTypeSelect';
import PropertyMediaFilterSelect from '../PropertyMedia/Subcomponents/PropertyMediaFilterSelect';
import {
  Grid,
} from '@material-ui/core';

export default function PropertyMedia(props) {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [images, setImages] = useState([]);
  const [filter, setFilter] = useState([]);
  const {
    property,
  } = props;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousSelectedFilter = usePrevious(selectedFilter);
  const setImageCategory = () => {
    const {
      PropertyImages,
      SectionImages,
      PerimImages,
      InvImages,
      DefImages,
    } = property;
 
    switch (selectedCategory) {
      case 0:
        setImages(PropertyImages);
        break;
      case 1:
        if (selectedFilter != previousSelectedFilter) {
          if (selectedFilter) {
            const filteredImages = filterImages(selectedFilter, SectionImages);
            setImages(filteredImages);
          }
          if (!selectedFilter) {
            setImages(SectionImages);
          }
        }
        if (selectedFilter == previousSelectedFilter) {
          console.log(filterImages)
          setImages(SectionImages);
        }
        break;
      case 2:
        if (selectedFilter != previousSelectedFilter) {
          if (selectedFilter) {
            const filteredImages = filterImages(selectedFilter, PerimImages);
            setImages(filteredImages);
          }
          if (!selectedFilter) {
            setImages(PerimImages);
          }
        }
        if (selectedFilter == previousSelectedFilter) {
          setImages(PerimImages);
        }
        break;
      case 3:
        if (selectedFilter != previousSelectedFilter) {
          if (selectedFilter) {
            const filteredImages = filterImages(selectedFilter, InvImages);
            setImages(filteredImages);
          }
          if (!selectedFilter) {
            setImages(InvImages);
          }
        }
        if (selectedFilter == previousSelectedFilter) {
          setImages(InvImages);
        }
        break;
      case 4:
        if (selectedFilter != previousSelectedFilter) {
          if (selectedFilter) {
            const filteredImages = filterImages(selectedFilter, DefImages);
            setImages(filteredImages);
          }
          if (!selectedFilter) {
            setImages(DefImages);
          }
        }
        if (selectedFilter == previousSelectedFilter) {
          setImages(DefImages);
        }
        break;
    };
  };

  const filterImages = (selectedSection, images) => {
    const filteredImages = images.filter(image => image.SectionId == selectedSection);
    return filteredImages;
  }; 

  const previousSelectedCategory = usePrevious(selectedCategory);
  useEffect(() => {
    if (selectedCategory != previousSelectedCategory) {
      setImageCategory();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedFilter != previousSelectedFilter) {
      if (selectedFilter) {
        setImageCategory();
      }
    }
  }, [selectedFilter]);

  console.log(images)

  return (
    <Grid container direction='row' alignItems='center' justify='center'>
        <Grid item xs={12}>
          <Grid container direction='row' alignItems='center' justify='center' style={{ display: 'table-header-group' }}>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='flex-start' justify='space-between'>
                <Grid item xs={12} sm={6}>
                  <PropertyMediaImageTypeSelect
                    setSelectedCategory={setSelectedCategory}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PropertyMediaFilterSelect
                    setSelectedFilter={setSelectedFilter}
                    selectedCategory={selectedCategory}
                    property={property}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction='row' style={{ display: 'table-cell' }}>
            {/* NO HEIGHT ALLOWED - FREEZES IMAGES */}
            <Grid id='images-item' item xs={12}>
              <PropertyImages
                images={images}
              />
            </Grid>
          </Grid>
          
        </Grid>
      </Grid>
  );
}
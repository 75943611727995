import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export default function CreateWOItemSectionList(props) {
  const [selected, setSelected] = useState([]);
  const classes = useStyles();

  const { 
    propertySections, 
    retrieveSelectedSections,
    editingWorkOrderItem,
    editedWorkOrderItem,
    currentStep, 
    previousCurrentStep,
  } = props;
  const sections = [...propertySections].sort((a, b) => a.SecId.localeCompare(b.SecId));

  const handleChange = e => {
    const id = e.target.value;
    const selectedCopy = [...selected];
    const sectionObject = sections.find(section => section.SectionId == id);
    const index = selectedCopy.findIndex(selection => selection.SectionId == id);

    if (index == -1) {
      selectedCopy.push(sectionObject);
    } else {
      selectedCopy.splice(index, 1);
    }
    setSelected(selectedCopy);
  };

  useEffect(() => {
    retrieveSelectedSections(selected);
  }, [selected]);

  useEffect(() => {
    if (editingWorkOrderItem) {
      if (currentStep != previousCurrentStep) {
        const { WoSection } = editingWorkOrderItem;
        setSelected(WoSection);
      }
    }
  }, [editingWorkOrderItem]);

  useEffect(() => {
    if (editedWorkOrderItem) {
      const editedWOSections = editedWorkOrderItem.WoSection;
      const editingWOSections = editingWorkOrderItem.WoSection;
      if (currentStep != previousCurrentStep) {
        if (JSON.stringify(editedWOSections) != JSON.stringify(editingWOSections)) {
          setSelected(editedWOSections);
        }
      }
    }
  }, [editedWorkOrderItem]);

  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12} style={{ maxHeight: '14rem', overflow: 'auto' }}>
        <div className={classes.root}>
          <FormControl variant='filled' component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Select Section</FormLabel>
            <FormGroup>
              { sections.map((section, i) => {
                const name = section.Name;
                const id = section.SectionId;
                return (
                  <FormControlLabel
                    control={<Checkbox checked={ selected.findIndex(selection => selection.SectionId == id) != -1 } onChange={ handleChange } value={ id } />}
                    label={ name }
                  />
                )
              }) }
            </FormGroup>
          </FormControl>
        </div>
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect, useRef, Component } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'

export default function WorkOrderItemPhotos(props) {
  const [ photos, setPhotos ] = useState([]);
  const [ savedPhotos, setSavedPhotos ] = useState([]);
  const { 
    retrieveWorkOrderItemPhotos,
    handleDisableFinishButton, 
    editingWorkOrderItem,
    editedWorkOrderItem,
    itemPromiseFulfilling, 
  } = props;

  const handleChange = (photos) => {
    setPhotos(photos);
  };

  useEffect(() => {      
    retrieveWorkOrderItemPhotos(photos);
    if (photos.length > 0) {
      handleDisableFinishButton(false);
    }
  }, [photos]);

  useEffect(() => {
    if (editedWorkOrderItem) {
      const previouslyUploadedPhotos = editedWorkOrderItem.WoItemPhoto;
      if (previouslyUploadedPhotos) {
        console.log(previouslyUploadedPhotos);
        previouslyUploadedPhotos.map((prevPhoto) => {
          const url = prevPhoto.Location;
          setSavedPhotos(savedPhotos => [...savedPhotos, url]);
        })
       
        // handleRetrievePhotosFromS3(previouslyUploadedPhotos);
      }
    }
  }, [editedWorkOrderItem]);

  useEffect(() => {
    if (savedPhotos.length > 0) {
      console.log(savedPhotos)
    }
  }, [savedPhotos]);

  return (
    <DropzoneArea
      // acceptedFiles={'image/*', 'png/*'}
      maxFileSize={180000000}
      onChange={handleChange}
      showAlerts={!itemPromiseFulfilling}
      showPreviewsInDropzone={!itemPromiseFulfilling}
      initialFiles={savedPhotos}
    />
  )
};
import React, { Component } from 'react';
import PropertyLoading from '../SubComponents/PropertyLoading';
import PropertyGraphsNoDataCard from './PropertyGraphsNoDataCard';
import {
  CartesianGrid,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

import {
  Typography,
} from '@material-ui/core';

const graphContainerStyle = {
  textAlign: 'center',
  height: '100%',
  minWidth: '21rem',
  minHeight: '14.78rem',
  fontFamily:'Ebrima',
};

const pieChartMarginStyle = {
  top: 10,
  right: 32,
  left: 32,
  bottom: 10,
  fontFamily:'Ebrima',
};

const COLORS = ["#f74649", "#ffe400", "#14a76c", "#3F51B5"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const percentage = `${(percent * 100).toFixed(0)}%`;
  if (percent > .00) {
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{fontFamily:'Ebrima',}}>
        {percentage}
      </text>
    );
  }
};

const XAxisTickFormatter = (value) => {
  return `${new Intl.NumberFormat('en').format(value)}%`;
};

const CustomTooltipContent = (props) => {
  const payload = props.payload;

  if (props.active) {
    const newPayload = payload;
    if (payload) {
      payload.map((entry, i) => {
        const dataKey = entry.dataKey;
        const id = payload.indexOf(entry);
        if (dataKey == 'uv') {
          const newEntry = {
            color: '#707070',
            dataKey: entry.dataKey,
            fill: '#707070',
            formatter: entry.formatter = (value) => XAxisTickFormatter(value),
            name: entry.name,
            payload: entry.payload,
            type: entry.type,
            unit: entry.unit,
            value: entry.value,
           fontFamily:'Ebrima',
          }
          newPayload.splice(id, 1, newEntry);
        } else {
          entry.formatter = (value) => XAxisTickFormatter(value);
        }
      })
    }
    return <DefaultTooltipContent {...props} payload={newPayload} />
  } else {
    return <DefaultTooltipContent {...props} payload={payload} />
  }
};

class PropertyConditionGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: null,
      noChartData: null,
    }
  }

  componentDidMount = () => {
    const { 
      ERSLGraphData, 
      handleDispatchGetRCS 
    } = this.props;

    if (ERSLGraphData.length == 0) {
      this.setState({
        noChartData: true,
      })
    }
  };

  render() {
    const { openRemainingLifeWindow, ERSLGraphData } = this.props;
    const { loading, noChartData } = this.state;

    return (
      <div style={{ display: 'grid', overflow: 'hidden' }}>
        <div id='graph-container' style={graphContainerStyle} >
          <Typography color='subtitle2' style={{fontFamily:'Ebrima',}}>Estimated Remaining Life</Typography>
          <ResponsiveContainer width={'100%'} aspect={12.0 / 6.0}>
            { noChartData ? <PropertyGraphsNoDataCard /> : 
              <PieChart
                onClick={ERSLGraphData.length > 0 ? () => openRemainingLifeWindow() : null}
                style={ERSLGraphData.length > 0 ? { cursor: 'pointer' } : { cursor: 'auto' }}
                margin={pieChartMarginStyle} 
              >
                <Tooltip style={{fontFamily:'Ebrima',}} content={<CustomTooltipContent props={ERSLGraphData} style={{fontFamily:'Ebrima',}} />} />
                <Pie
                  data={ERSLGraphData}
                  // cx={'50%'}
                  // cy={'50%'}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#FF8042"
                  dataKey="amt"
                >
                {
                  ERSLGraphData.map((entry, index) => {
                    return(
                      <Cell key={`cell-${index}`} dataKey={entry.amt} name={`${entry.name} yrs`} style={{fontFamily:'Ebrima',}} fill={COLORS[index % COLORS.length]} />
                    )
                  })
                  
                }
                </Pie>
                <Legend />
              </PieChart>
            }
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default PropertyConditionGraph

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  InputAdornment,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function AddBidNumber(props) {
  const { retrieveEstimate } = props;
  const classes = useStyles();

  const handleChange = (e) => {
    const value = e.target.value;
    retrieveEstimate(value);
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <div>
         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
          onChange={handleChange}
          id="standard-basic"
          className={classes.textField}
          margin="normal"
          helperText='Bid Amount'
          type={'number'}
          inputprops={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </div>
    </form>
  );
}

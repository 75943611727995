import React, { useState, useEffect } from 'react';
import SiteFilterMaterial from './SiteFilterMaterial';
import { 
    FormControl, 
    InputLabel,
    MenuItem,
    Select,  
    Grid,
    IconButton,
    Typography,
    Avatar,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FilterListIcon from '@material-ui/icons/FilterList';
import SiteFilterInputStyle from '../../styles/SiteFilterStyles/SiteFilterInputStyle';

function SiteFilterHeader(props){
  const classes = SiteFilterInputStyle();
  const [values, setValues] = useState({ 
    name: props.selectedCompany != null ? props.selectedCompany.CompanyId : null, 
    value: props.selectedCompany != null ? props.selectedCompany.CompanyId : null, 
  });

  const { name } = values;
  const { root, formControl } = classes;
  const inputprops = { name: 'name', id: 'company-id' };
  const {
    siteFilterSelect,

    allCompanies,
    includeAllTags,
    includeNoTags,
    //materials
    materialSelect,
    selectedMaterial,
    allMaterials,
  } = props;

  const handleCompanySelect = (e) => {
    console.log("handleCompanySelect");
    const name = e.target.name;
    const value = e.target.value;

    setValues(oldValues => ({
      ...oldValues,
      [name]: value,
    }))
  };

    useEffect(() => {
        console.log(name);
        props.companySelect(name);
  }, [values, setValues]);


  return(
      <Grid container direction={'row'} alignItems={'center'} justify={'space-between'} style={{ width: '100%', height: '15%' }}>
        
        <Grid item xs={12} style={{height: '44%'}}>
          <Grid container direction='row' alignItems='center' justify='space-between' style={{ backgroundColor: '#66BFFA', position: 'sticky', top: '0', left: '0', height: '100%', width: '100%', textAlign: 'center' }}>
            <Grid item xs={1} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
              <Avatar style={{ backgroundColor: 'gainsboro', margin: '2%' }}>
                <FilterListIcon />
              </Avatar>
              <Typography variant='h6' style={{ margin: '4%', fontFamily: 'Ebrima',  }}>Site Filter</Typography>
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right', color: 'white', display: 'contents' }}>
              <IconButton onClick={() => siteFilterSelect()}>
                <HighlightOffIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{backgroundColor: 'gainsboro', height: '56%'}}>
          <Grid container direction='row' alignItems='center' style={{ width: '100%', height: '100%' }}>
            <Grid item xs={6}>
              <form className={root}>
                <FormControl className={formControl}>
                  <InputLabel htmlFor="company-id">Company</InputLabel>
                  <Select
                    value={name}
                    onChange={handleCompanySelect}
                    inputProps={inputprops}
                  >
                    {allCompanies.map((company, i) => {
                      const name = company.Name;
                      const value = company.CompanyId;
                      return <MenuItem value={value}>{name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </form>
            </Grid> 
            <Grid item xs={6}>
              <SiteFilterMaterial
                materialSelect={materialSelect}
                selectedMaterial={selectedMaterial}
                allMaterials={allMaterials}
              />
            </Grid> 
          </Grid>
        </Grid>

      </Grid>
  )
}

export default SiteFilterHeader

import React, { useState, useEffect } from 'react';
import PropertyContactsCard from './PropertyContactsCard';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Grid,
  Paper,
  List,
  ListItem,
  Typography,
  IconButton,
  Collapse,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
}));

export default function PropertySections(props) {
  const [ contacts, setContacts ] = useState([]);
  const [ numberOfContacts,  setNumberOfContacts ] = useState(0);
  const [ expanded, setExpanded ] = React.useState(false);
  const { selectedProperty } = props;

  const classes = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (selectedProperty) {
        const propertyContacts = selectedProperty.Contacts;
        const propertyContactsLength = selectedProperty.Contacts == null?0:selectedProperty.Contacts.length;
      setContacts(propertyContacts);
      setNumberOfContacts(propertyContactsLength);
    }
  }, [selectedProperty])

  return (
    <Paper style={{ marginBottom: '5%', marginLeft: '1%', boxShadow: 'none', backgroundColor: 'rgb(233, 233, 233)' }}>
      <Grid container direction={'row'} alignItems={'flex-end'} justify={'flex-start'}>
        <Grid direction={'row'} style={{ display: 'flex' }}>
          <Typography variant='h6' style={{fontFamily: 'Ebrima',}}>Property Contacts <span style={{ color: 'rgba(0, 0, 0, 0.26)', fontFamily: 'Ebrima', }}>({numberOfContacts})</span> </Typography>
      
          <IconButton
            size={'small'}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            disabled={contacts.length == 0}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} style={{ height: '90%' }}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List style={{ width: '99%', maxHeight: 'fit-content', overflowY: 'auto', backgroundColor: 'white', borderRadius: '2%' }}>
            { contacts.map((contact) => {
              return(
                <PropertyContactsCard 
                  contact={ contact } 
                />
              )
            }) }
            </List>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  )
};


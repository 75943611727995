import React, { useState, useEffect, useRef } from 'react';
import WorkOrderItemsModalClose from './SubComponents/WorkOrderItemsModalClose';
import WorkOrderItemsStepper from './SubComponents/WorkOrderItemsStepper';
import {
  Paper,
  Grid,
  Modal,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export default function WorkOrderItemsModal(props) {
  const {
    dispatchCreateNewWorkOrderItem,
    handleSaveAndCancelWorkOrderItem,
    handleCancelSaveWorkOrderItemModal,
    handleCloseWorkOrderItems,
    handleRemoveCostItem,
    addingWorkOrderItems,
    workOrderId,
    propertySections,
    retrieveCreateNewWorkOrderItem,
    retrieveEditingWorkOrderItem,
    retrieveEditedWorkOrderItem,
    retrieveWorkOrderItemImages,
    retrieveWorkOrderItemFulfilling,
    saveOrCancelWorkOrderItems,
    propertyDefects,
    createdWorkOrderItem,
    editingWorkOrderItem,
    editedWorkOrderItem,
    selectedWorkOrder,
    editWorkOrder,
    itemPromiseFulfilling,
    token,
    identityId,
  } = props;

  return (
    <Modal open={addingWorkOrderItems}>
      <div id={'work-order-items-container'} style={{ width: '85%', height: '100%', maxHeight: '54rem', overflow: 'auto', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
          <Grid item xs={10}>
            <Paper id='paper' style={{ width: '100%', height: '100%', maxHeight: '45rem', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
              <Grid container direction='row' style={{ width: '100%', backgroundColor: '#3f51b5' }}>
                <Grid item style={{ margin: '1%', display: 'inline-flex' }}>
                  <EditIcon style={{ color: 'white' }} />
                  <Typography style={{ color: 'white' }}>Work Order Items</Typography>
                </Grid>
              </Grid>
              <Grid container direction='row' style={{ backgroundColor: 'rgb(246, 243, 235)', height: '91%' }}>
                <Grid item xs={12} style={{height: '100%', overflow: 'auto' }}>
                  <WorkOrderItemsStepper
                    propertySections={propertySections} 
                    workOrderId={workOrderId}
                    handleSaveAndCancelWorkOrderItem={handleSaveAndCancelWorkOrderItem}
                    handleCancelSaveWorkOrderItemModal={handleCancelSaveWorkOrderItemModal}
                    handleRemoveCostItem={handleRemoveCostItem}
                    dispatchCreateNewWorkOrderItem={dispatchCreateNewWorkOrderItem}
                    retrieveCreateNewWorkOrderItem={retrieveCreateNewWorkOrderItem}
                    retrieveEditingWorkOrderItem={retrieveEditingWorkOrderItem}
                    retrieveEditedWorkOrderItem={retrieveEditedWorkOrderItem}
                    retrieveWorkOrderItemImages={retrieveWorkOrderItemImages}
                    retrieveWorkOrderItemFulfilling={retrieveWorkOrderItemFulfilling}
                    saveOrCancelWorkOrderItems={saveOrCancelWorkOrderItems}
                    propertyDefects={propertyDefects}
                    createdWorkOrderItem={createdWorkOrderItem}
                    editedWorkOrderItem={editedWorkOrderItem}
                    editingWorkOrderItem={editingWorkOrderItem}
                    selectedWorkOrder={selectedWorkOrder}
                    editWorkOrder={editWorkOrder}
                    itemPromiseFulfilling={itemPromiseFulfilling}
                    token={token}
                    identityId={identityId}
                  />
                </Grid>               
              </Grid>
              <WorkOrderItemsModalClose 
                handleCloseWorkOrderItems={handleCloseWorkOrderItems}
                itemPromiseFulfilling={itemPromiseFulfilling}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
} 
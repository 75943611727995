import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ThemeDefault from '../../styles/theme-default';
import globalStyles from '../../styles/styles';

import {
    CartesianGrid,
    BarChart,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ResponsiveContainer,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const graphContainerStyle = {
    textAlign: 'center'
}

const YAxisTickFormatter = (value) => {
    return `$${new Intl.NumberFormat('en').format(value)}`;
};

const CustomTooltipContent = (props) => {
    const payload = props.payload;

    if (props.active) {
        const newPayload = payload; 

        if (payload) {
            payload.map((entry, i) => {
                const dataKey = entry.dataKey;
                return entry.formatter = (value) => YAxisTickFormatter(value);
            })    
        }

        return <DefaultTooltipContent {...props} payload={newPayload} />
    } else {
        return <DefaultTooltipContent {...props} payload={payload}/>
    }
};


class FinancialForecastGraph extends Component {
    constructor(props) {
        super(props);
    }

    render() { 
        const { emergencyCostsData } = this.props;

        return (
            <div id='graph-container' style={ graphContainerStyle }>
                {/* <MuiThemeProvider muiTheme={ ThemeDefault }> */}
                    <Typography color='textSecondary' variant='h6'>Emergency Repairs Cost Data By Location (Last Year)</Typography>
                    <ResponsiveContainer width='96%' height='100%' aspect={ 12.0 / 5.0 } >
                        <BarChart 
                            width={ 730 } 
                            height={ 250 } 
                            barGap={ 0 }
                            data={ emergencyCostsData }
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <YAxis type='number' tickFormatter={ YAxisTickFormatter }/>
                            <XAxis type='category' dataKey="name"/>
                            <Tooltip content={ <CustomTooltipContent props={ emergencyCostsData } /> } />
                            <Legend />
                            <Bar barSize={20} dataKey="pv" name="NTE Total ($)" fill="#00C2F3" />
                            <Bar barSize={20} dataKey="uv" name="Total Cost ($)" fill="#FFBE34" />
                        </BarChart>
                    </ResponsiveContainer>
                {/* </MuiThemeProvider> */}
            </div>
        );
    }
}

export default FinancialForecastGraph

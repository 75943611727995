import React, { useState, useEffect } from 'react';
import {
  TextField,
  InputAdornment,
  MenuItem,
}  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const options = [
  {
    value: 0,
    text: 'Show All Tags',
  },
  {
    value: 1,
    text: 'Show Tagged Only',
  },
  {
    value: 2,
    text: ' Show Untagged Only',
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

export default function MultilineTextFields(props) {
  const [ selectedValue, setSelectedValue ] = useState(0);
  const { retrieveOrgTagFilter, disabled } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
  };

  useEffect(() => {
    retrieveOrgTagFilter(selectedValue);
  }, [selectedValue]);

  const classes = useStyles();
  return (
    <form className={classes.root} noValidate autoComplete="off">
         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
          id="standard-select-currency"
          select
          disabled={disabled}
          value={selectedValue}
          onChange={handleChange}
          inputprops={{
            startAdornment: (
              <InputAdornment position="start">
                <LocalOfferIcon />
              </InputAdornment>
            ),
            shrink: true,
          }}
        >
          { options.map((option, i) => {
            const value = option.value;
            const text = option.text;
            return (
              <MenuItem
                key={`orgtagfilteroption-${i}`}
                value={value}
              >
                {text}
              </MenuItem>
            )
          }) }
        </TextField>
    </form>
  );
}

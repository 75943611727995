import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import { useTreeNodeStyles } from '../../../../styles/ProjectHierarchyStyle.js';
import LineStyleTwoToneIcon from '@material-ui/icons/LineStyleTwoTone';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function ProjectTreeNode({
  projectNode,
  handleEditProject,
  dispatch,
  level,
  properties,
  phaseTemplateId,
  phaseItemId,
  name,
}) {

  const [allProperties, setAllProperties] = React.useState(false);
  const handleChangeCheckAllProperty = (event) => {
    setAllProperties(event.target.checked);
  };
  const [showModal, setShowModal] = React.useState(false);
  const classes = useTreeNodeStyles();
  const theme = useTheme();
  const [propertiesToRemove, setPropertiesToRemove] = React.useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let phase = projectNode.Project.ProjectPhases.sort((a, b) => (a.Sort > b.Sort ? 1 : -1));
  const phasesActive = phase && phase.length > 0 ? phase.filter((x) => x.Active) : [];
  const phasesDoneFalse = phase && phase.length > 0 ? phase.filter((x) => !x.Done) : [];
  const phaseDoneTrue = phase && phase.length > 0 ? phase.filter((x) => x.Done) : [];
  phase = phasesActive.length > 0 ? phasesActive[0].PhaseItem.Name :
    phasesDoneFalse.length > 0
      ? phasesDoneFalse[0].PhaseItem.Name
      : phaseDoneTrue.length > 0
        ? phaseDoneTrue[phaseDoneTrue.length - 1].PhaseItem.Name
        : '--';
  const [expanded, setExpand] = React.useState(false);
  const [propertiesToShow, setPropertiesToShow] = React.useState([]);
  const [isSinglePropertyToRemove, setisSinglePropertyToRemove] = React.useState(false);
  const [projectChildSelected, setProjectChildSelected] = React.useState(-1);

  const expandCollapse = (e) => {
    e.stopPropagation();
    setExpand((current) => !current);

    if (projectNode.Children.length === 0) {
      dispatch({
        AddProjectChildren: {
          Id: projectNode.Project.ProjectId,
          PhaseTemplateId: phaseTemplateId,
          PhaseItemId: phaseItemId,
          Title: name,
        },
      });
    }
  };

  const setPropertiesChild = (projectId) => {
    return () => {
      let propertiesFiltered = [];
      setProjectChildSelected(projectId);
      if (projectNode && projectNode.Children && projectNode.Children.length > 0) {
        const propertiesIds = projectNode.Children.filter((x) => x.Project.AssetId > 0).map((y) => {
          return { AssetId: y.Project.AssetId, ProjectId: y.Project.ProjectId };
        });
        if (propertiesIds && propertiesIds.length > 0) {
          propertiesIds.forEach(function (property, index) {
            propertiesFiltered[index] = properties
              .filter((x) => x.BuildingId == property.AssetId)
              .map((y, i) => {
                return { Index: i, Id: y.BuildingId, Name: y.Name, ProjectId: property.ProjectId };
              })[0];
          });
        }
        setPropertiesToShow(propertiesFiltered || []);
        setShowModal(true);
      }
    };
  };

  const [optionsAnchor, setOptionsAnchor] = React.useState(null);
  const handleShowOptions = (event) => {
    setOptionsAnchor(event.currentTarget);
  };
  const handleCloseOptions = () => {
    setOptionsAnchor(null);
  };
  const openOptionsMenu = Boolean(optionsAnchor);
  const handleCloseModal = () => {
    setShowModal(false);
    setAllProperties(false);
    setProjectChildSelected([]);
    setisSinglePropertyToRemove(false);
    setPropertiesToRemove([]);
    setPropertiesToShow([]);
    setProjectChildSelected([]);
    setAllProperties(false);
  };

  const handleOkModal = () => {
    if (
      ((propertiesToRemove && propertiesToRemove.length > 0) || allProperties) &&
      projectNode &&
      projectNode.Children &&
      projectNode.Children.length > 0
    ) {
      let projectsID = propertiesToRemove.map((x) => x.ProjectId);

      if (allProperties) {
        projectsID = propertiesToShow.map((x) => x.ProjectId);
      }
      setExpand(false);
      dispatch({
        RemovePropertiesChild: {
          ProjectsID: projectsID,
        },
      });
      dispatch({
        AddProjectChildren: {
          Id: projectNode.Project.ParentProjectId,
          PhaseTemplateId: phaseTemplateId,
          PhaseItemId: phaseItemId,
          Title: name,
        }
      });
    }
    setShowModal(false);
    setPropertiesToRemove([]);
    setPropertiesToShow([]);
    setProjectChildSelected([]);
    setAllProperties(false);
    //window.location.reload(false);
  };

  const showModalToRemoveSingleProperty = (projectId) => {
    return () => {
      setisSinglePropertyToRemove(true);
      setProjectChildSelected(projectId);
      setShowModal(true);
    };
  };

  const removeSingleProperty = () => {
    setExpand(false);
    dispatch({
      RemovePropertiesChild: {
        ProjectsID: [projectChildSelected],
      },
    });
    dispatch({
      AddProjectChildren: {
        Id: projectNode.Project.ParentProjectId,
        PhaseTemplateId: phaseTemplateId,
        PhaseItemId: phaseItemId,
        Title: name,
      }
    });
    setShowModal(false);
    //window.location.reload(false);
    setTimeout(() => {
      setAllProperties(false);
      setProjectChildSelected([]);
      setisSinglePropertyToRemove(false);
    }, 100);
  };

  const handleChangePropertiesFilter = (event, obj) => {
    setPropertiesToRemove(obj);
  };

  function LinearProgressWithLabel(props) {
    return (
      <Box style={{ width: '100%', padding: '10px' }} display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  let projectTitle = properties.filter((x) => x.BuildingId == projectNode.Project.AssetId);
  projectTitle = projectTitle && projectTitle.length > 0 ? projectTitle[0].Name : projectNode.Project.Title;

  return (
    <div className={classes.treeNodeWrapper}>
      <div className={classes.treeNode}>
        <div className={classes.treeNodeHeader}>
          <div
            className={`${classes.treeNodeColumn} ${classes.treeNodeColumnTitle}`}
            style={{ paddingLeft: `${10 + 10 * level}px` }}
          >
            <div className={classes.projectName} onClick={expandCollapse}>
              {projectNode.Project.AssetId > 0 ? (
                <InputAdornment position="start">
                  <LineStyleTwoToneIcon />
                </InputAdornment>
              ) : (
                (expanded && <ExpandLessIcon className={classes.caretIcon} />) || (
                  <ExpandMoreIcon className={classes.caretIcon} />
                )
              )}
              {projectTitle}
            </div>
            <div className={classes.projectOptions}>
              <div className={classes.moreOptions}>
                <IconButton
                  className={classes.treeNodeButton}
                  size={'small'}
                  edge="end"
                  aria-label="edit"
                  onClick={handleShowOptions}
                >
                  <MoreVertIcon className={classes.dots} />
                </IconButton>
                <Menu
                  className={classes.optionsMenu}
                  anchorEl={optionsAnchor}
                  onClose={handleCloseOptions}
                  open={openOptionsMenu}
                  elevation={0}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem
                    className={
                      projectNode.Project.AssetId > 0 ? classes.hide : classes.treeNodeMenuOption
                    }
                    onClick={handleEditProject(projectNode.Project.ProjectId + '?property')}
                  >
                    Add Property
                  </MenuItem>
                  <MenuItem
                    className={
                      projectNode.Project.AssetId > 0 ? classes.hide : classes.treeNodeMenuOption
                    }
                    onClick={handleEditProject(projectNode.Project.ProjectId + '?subproject')}
                  >
                    Add Sub Project
                  </MenuItem>
                  <MenuItem
                    className={classes.treeNodeMenuOption}
                    onClick={
                      projectNode.Project.AssetId > 0
                        ? showModalToRemoveSingleProperty(projectNode.Project.ProjectId)
                        : setPropertiesChild(projectNode.Project.ProjectId)
                    }
                  >
                    Remove Property
                  </MenuItem>
                </Menu>
              </div>
              <IconButton
                className={classes.treeNodeButton}
                size={'small'}
                onClick={handleEditProject(projectNode.Project.ProjectId, level)}
                edge="end"
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>
          <div className={classes.treeNodeColumn}>{phase}</div>
          <div className={classes.treeNodeColumn}>{<LinearProgressWithLabel value={projectNode.Project.PercentComplete ? projectNode.Project.PercentComplete : 0} />}</div>
          <div className={classes.treeNodeColumn}>
            {projectNode.Project.StartDate.substring(0, 10)}
          </div>
          <div className={classes.treeNodeColumn}>
            {projectNode.Project.DueDate.substring(0, 10)}
          </div>
          <div className={classes.treeNodeColumn}>
            {`${projectNode.Project.ProjectManagerNavigation.FirstName} ${projectNode.Project.ProjectManagerNavigation.LastName}`}
          </div>
        </div>
        <div className={classes.treeNodeBody}>
          {expanded &&
            projectNode.Children.map((node) => (
              <ProjectTreeNode
                key={node.Project.ProjectId}
                projectNode={node}
                handleEditProject={handleEditProject}
                dispatch={dispatch}
                showButtons={node.Project.AssetId <= 0}
                level={level + 1}
                properties={properties}
                phaseTemplateId={phaseTemplateId}
                phaseItemId={phaseItemId}
                name={name}
              />
            ))}
        </div>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{'Properties'}</DialogTitle>
        <DialogContent>
          {isSinglePropertyToRemove ? (
            <DialogContentText>Are you sure you want to delete this property?</DialogContentText>
          ) : (
            <div style={{ padding: '10px', border: '1px solid' }}>
              <div className={classes.selectAll}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allProperties}
                      onChange={handleChangeCheckAllProperty}
                      defaultChecked
                      color="default"
                    />
                  }
                  label="Select all"
                />
              </div>
              <Autocomplete
                id="properties-filter"
                multiple
                limitTags={3}
                onChange={handleChangePropertiesFilter}
                value={propertiesToRemove}
                options={propertiesToShow}
                getOptionLabel={(option) => option.Name || ''}
                style={{ width: 300 }}
                disabled={!allProperties ? false : true}
                renderInput={(params) => (
                   <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                    {...params}
                    label={allProperties ? 'All properties are selected' : 'Properties'}
                    variant="outlined"
                  />
                )}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button
            disabled={
              isSinglePropertyToRemove
                ? false
                : !(propertiesToRemove && propertiesToRemove.length > 0) && !allProperties
            }
            onClick={isSinglePropertyToRemove ? removeSingleProperty : handleOkModal}
            color="primary"
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';

export default function PropertyContactName(props) {
  const [ name, setName ] = useState('');
  const {
    handleUpdatePropertyContactName,

    contactIsRequested, 
    modifiedContact, 
  } = props;

  const handleUpdateName = (e) => {
    const text = e.target.value;
    setName(text);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

 useEffect(() => {
   if (modifiedContact) {
     const {
       Name,
     } = modifiedContact;
     setName(Name);
   }
 }, [modifiedContact]);

 const previousName = usePrevious(name);
 useEffect(() => {
   if (name != previousName) {
    handleUpdatePropertyContactName(name);
   }
 }, [name]);

  return (
    <Grid container direction='row'>
      <Grid xs={12} style={{ padding: '2%' }}>
        <Paper style={{ width: '100%', boxShadow: 'none' }}>
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={12}>
               <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                id="standard-full-width"
                label="Name"
                placeholder="Contact's Name"
                autoFocus
                value={name}
                disabled={contactIsRequested}
                onChange={handleUpdateName}
                margin="normal"
                style={{ width: '95%', left: '2.5%', right: '2.5%', marginTop: '2%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}


import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import SiteFilterHeader from './SiteFilterHeader';
import SiteFilterTags from './SiteFilterTags';
import SiteFilterSave from './SiteFilterSave';
import auth from '../../auth';
import {
  Modal,
  Paper,
} from '@material-ui/core';

const siteFilterWrapperStyle={ 
  width: '95%', 
  height: '95%',
  position: 'absolute', 
  left: '50%', 
  top: '50%', 
  transform: 'translate(-50%, -50%)',
};

class SiteFilterContainer extends Component {
  constructor(props) {
    super(props)

    this.vm = dotnetify.react.connect('SiteFilter', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: _ => auth.signOut()
    });
    this.dispatch = state => this.vm.$dispatch(state);
    this.routeTo = route => this.vm.$routeTo(route);
    
    this.state = {
      numberOfCompanies: 0,
      UserId: 0,
      Companies: [],
      SelectedCompany: null,
      currentlySelectedCompany: null,
      AllCompanies: [],
      PortalUserOrgUnits: [],
      selectedTags: [],
      SelectedMaterial: null,
      AllMaterials: [],
    };
  }

  companySelect = (selectedCompanyId) => {
    console.log("companySelect");
    const { AllCompanies } = this.state;
      const selectedCompany = AllCompanies.find(company => selectedCompanyId == company.CompanyId);
      console.log("selectedCompanyId" + selectedCompanyId);
    this.setState(prevState => ({
      currentlySelectedCompany: selectedCompany,
    }), this.dispatch({ SelectCompany: { Company: selectedCompany } }));
  };

   materialSelect = (selectedMaterialId) => {
    const { AllMaterials } = this.state;
    const selectedMaterial = AllMaterials.find(mat => selectedMaterialId == mat.id);

    this.setState(({
      currentlySelectedMaterial: selectedMaterial,
    }), this.dispatch({ SelectedMaterial: selectedMaterial}));
   };

  tagSelect = (selectedTag) => {
    const { selectedTags } = this.state;

    const SaveUserOrgFilterSelections = { 
      UserId: selectedTag.UserId, 
      OrgUnitId: selectedTag.OrgUnitId, 
      FilterSelected: selectedTag.FilterSelected 
    }
    
    this.setState({
      selectedTags: [...selectedTags, SaveUserOrgFilterSelections]
    },  this.dispatch({ SaveUserOrgFilterSelections:  selectedTag}));
  };

  includeAllTags = () => {
    const { PortalUserOrgUnits } = this.state;
    const allOrgUnits = [...PortalUserOrgUnits];

    allOrgUnits.map((orgUnit => {
      orgUnit.FilterSelected = true;
    }));

    this.setState({
      PortalUserOrgUnits: allOrgUnits,
    },  dispatch({ SelectUnselectAllTags: 1 }));


  };

  includeNoTags = () => {
    const { PortalUserOrgUnits } = this.state;
    const allOrgUnits = [...PortalUserOrgUnits];

    allOrgUnits.map((orgUnit => {
      orgUnit.FilterSelected = false;
    }))

    this.setState({
      PortalUserOrgUnits: allOrgUnits,
    },  dispatch({ SelectUnselectAllTags: 0 }))
  };
 
  dispatchSave = () => {
    const { selectedTags, SelectedMaterial } = this.state;
    const { siteFilterSelect } = this.props;
    this.dispatch({ SaveAllUserOrgFilterSelections: { PortalUserOrgs: selectedTags, SiteFilterMaterial: SelectedMaterial.id} });
    siteFilterSelect();
    location.reload();
  };

  componentWillUnmount = () => {
    console.log('unmounting sitefilter');
    this.vm.$destroy();
  };
  
  render() {
    const { 
      siteFilterOpen, 
      siteFilterSelect,
    } = this.props;
    
    const { 
      AllCompanies, 
      PortalUserOrgUnits, 
      SelectedCompany,
      AllMaterials,
      SelectedMaterial,
    } = this.state;

    return (
      <Modal 
        open={siteFilterOpen}
        onBackdropClick={siteFilterSelect}
      >
        <div style={siteFilterWrapperStyle}>     
          <Paper style={{height: '100%'}}>
            <SiteFilterHeader 
              includeAllTags={this.includeAllTags}
              includeNoTags={this.includeNoTags}
              companySelect={this.companySelect}
              materialSelect={this.materialSelect}

              siteFilterSelect={siteFilterSelect}
              allCompanies={AllCompanies}
              selectedCompany={SelectedCompany}
              selectedMaterial={SelectedMaterial}
              allMaterials={AllMaterials}
            />
            <SiteFilterTags 
              portalUserOrgUnits={PortalUserOrgUnits}
              tagSelect={this.tagSelect}
            />
            <SiteFilterSave 
              dispatchSave={this.dispatchSave}
            />
          </Paper>
        </div>
      </Modal>            
    )
  }
}

export default SiteFilterContainer
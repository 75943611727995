import { makeStyles } from '@material-ui/core/styles';

const PropertySearchBarStyle = makeStyles(theme => ({
  root: {
    width: '100%',
    // marginLeft: '6%',
    // marginRight: '1%',
    // marginBottom: '2%',
    // marginTop: '2%',
    display: 'flex',
    // alignItems: 'center',
    // borderRadius: '2',
    boxShadow: 'none',
    // border: '1px solid #b3b3b3',
    // position: 'sticky',
    // top: '0',
    fontFamily: 'Ebrima',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontFamily: 'Ebrima',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default PropertySearchBarStyle
import React, { useState, useEffect, useLayoutEffect } from 'react';
import Gallery from 'react-grid-gallery';
import { 
  Grid,
  IconButton,
  Typography,
 } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function PropertyBuildingImages(props) {
  const [ activeImage, setActiveImage ] = useState(0);
  const [ disableDecrement, setDisableDecrement ] = useState(true);
  const [ disableIncrement, setDisableIncrement] = useState(false);
  const [ lightboxOpen, setLightboxOpen ] = useState(false);
  const [ lowerLimit, setLowerLimit ] = useState(0);
  const [ upperLimit, setUpperLimit ] = useState(3);
  const [ imageArray, setImageArray ] = useState([]);

  const { images, galleryTitle } = props;

  const handleIncriment = () => {
      const newLowerLimit = lowerLimit + 1;
      const newUpperLimit = upperLimit + 1;
      setLowerLimit(newLowerLimit);
      setUpperLimit(newUpperLimit);
  };

  const handleLightboxIncriment = () => {
    handleIncriment();
    setActiveImage(activeImage + 1);
  };

  const handleDecriment = () => {
    if (lowerLimit != 0) {
      const newLowerLimit = lowerLimit - 1;
      const newUpperLimit = upperLimit - 1;
      setLowerLimit(newLowerLimit);
      setUpperLimit(newUpperLimit);
    } 
  };

  const handleLightboxDecriment = () => {
    handleDecriment();
    setActiveImage(activeImage - 1);
  };

  const handleLightBox = () => {
    if (lightboxOpen) {
      setLightboxOpen(false);
      currentImageWillChange(0);
    } else {
      setLightboxOpen(true);
    }
  };

  const currentImageWillChange = (activeImage) => {
    if (lowerLimit > 0 && upperLimit > 3) {
      const number = lowerLimit + activeImage
      setActiveImage(number)
    }else{
      setActiveImage(activeImage)
    }
  };

  useEffect(() => {
    const imagesToRender = images.slice(lowerLimit, upperLimit);
    upperLimit < images.length ? setDisableIncrement(false) : setDisableIncrement(true);
    setImageArray(imagesToRender);
  }, [upperLimit]);

  useEffect(() => {
    const imagesToRender = images.slice(lowerLimit, upperLimit);
    lowerLimit == 0 ? setDisableDecrement(true) : setDisableDecrement(false);
    setImageArray(imagesToRender);
  }, [lowerLimit]);

  return (
    <div>{ images.length > 0 ? 
      <Grid container direction={'row'} alignItems={ 'center' } justify={ 'center' }>
        <Grid item xs={2} style={{ maxWidth: '1rem', marginRight: '3.5%', textAlign:'center'}}>
          <IconButton disabled={disableDecrement} onClick={handleDecriment} style={images.length > 3 ? null : { display: 'none' } }>
            <ArrowBackIosIcon style={{ marginLeft: '25%' }}/>
          </IconButton>
        </Grid>
        <Grid id='gallery-container' item xs={8} style={{ width: '100%', minWidth: '33rem' }}>
          <Typography variant={'h6'}>{galleryTitle}</Typography>
          <Gallery
            currentImageWillChange={currentImageWillChange}
            lightboxWillOpen={handleLightBox}
            lightboxWillClose={handleLightBox}
            onClickPrev={lightboxOpen ? handleLightboxDecriment : handleDecriment}
            onClickNext={lightboxOpen ? handleLightboxIncriment : handleIncriment}
            enableImageSelection={false}
            enableLightbox={true}
            showImageCount={false}
            showLightboxThumbnails={true}
            backdropClosesModal={true}
            images={lightboxOpen ? images : imageArray}
            lightBoxProps={{ currentImage: activeImage }}
            rowHeight={170}
            maxRows={0}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center', maxWidth: '1rem' }}>
          <IconButton disabled={disableIncrement} onClick={handleIncriment} style={images.length > 3 ? null : { display: 'none' }}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </Grid>
    : null }</div>
  )
}
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const ManageContactsCardStyle = makeStyles(theme => ({
  card: {
    width: '100%',
    boxShadow: 'none',
    marginTop: '-8px',
    fontFamily: 'Ebrima',
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    fontFamily: 'Ebrima',
  },
  expandOpen: {
    transform: 'rotate(0deg)',
    fontFamily: 'Ebrima',
  },
  avatar: {
    backgroundColor: red[500],
    fontFamily: 'Ebrima',
  },
  cardContent: {
    marginTop: '-15px',
    fontFamily: 'Ebrima',
  },
}));

export default ManageContactsCardStyle
import React, { Component } from 'react';
import EditType from './SubComponents/EditType';
import EditUrgency from './SubComponents/EditUrgency';
import EditDateSelect from './SubComponents/EditDateSelect';
import EditDocScopeOfWork from './SubComponents/EditDocScopeOfWork';
import EditDocContractorInvoice from './SubComponents/EditDocContractorInvoice';
import EditDocBluefinInvoice from './SubComponents/EditDocBluefinInvoice';
import EditDocBid from './SubComponents/EditDocBid';
import EditTimeStart from './SubComponents/EditTimeStart';
import EditTimeEnd from './SubComponents/EditTimeEnd';
import WorkOrderItemsModal from './WorkOrderItems/WorkOrderItemsModal';
import WorkOrderDeleteItemsModal from './WorkOrderItems/Components/WorkOrderDeleteItemsModal';
import EditWorkOrderItemsCard from './SubComponents/EditWorkOrderItemsCard';
import WorkOrderItemsLoading from './WorkOrderItems/SubComponents/WorkOrderItemsLoading';
import EditWorkOrderComments from './SubComponents/EditWorkOrderComments';
import EditWorkOrderCommentsList from './SubComponents/EditWorkOrderCommentsList';
import EditContractorInvoiceNumber from './SubComponents/EditContractorInvoiceNumber';
import EditBluefinInvoiceNumber from './SubComponents/EditBluefinInvoiceNumber';
import EditPurchaseOrderNumber from './SubComponents/EditPurchaseOrderNumber';
import EditExternalWONumber from './SubComponents/EditExternalWONumber';
import EditNotToExceed from './SubComponents/EditNotToExceed';
import EditEstimate from './SubComponents/EditEstimate';
import EditBid from './SubComponents/EditBid';
import EditContactsContainer from './SubComponents/EditContactsContainer';
import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import * as AWS from 'aws-sdk';
var moment = require('moment');

class EditWorkOrderForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedWorkOrder: null,
      editedWorkOrder: null,
      editedDocuments: [],
      //sections being edited
      editGeneral: false,
      editSchedule: false,
      editContractor: false,
      editSubmitter: false,
      editAssigned: false,
      editEstimates: false,
      editInvoices: false,
      //edited sections that can be saved
      woTypeEdited: false,
      woUrgencyEdited: false,
      woScheduleDateEdited: false,
      woStartEdited: false,
      woEndEdited: false,
      woSubmitterNameEdited: false,
      //edited categories that can be saved
      saveGeneral: null,
      saveSchedule: null,
      saveSubmitter: null,
      saveAssigned: null,
      saveEstimates: null,
      saveInvoices: null,
      //work order items 
      addingWorkOrderItems: false,
      saveOrCancelWorkOrderItems: false,
      createdWorkOrderItem: null,
      editingWorkOrderItem: null,
    };
  }

  componentDidMount = () => {
    const { editWorkOrder } = this.props;
    this.setState({
      selectedWorkOrder: editWorkOrder,
      editedWorkOrder: editWorkOrder,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    //GENERAL
    const {
      editedWorkOrderType,
      editedWorkOrderUrgency,
      woTypeEdited,
      woUrgencyEdited,
      saveGeneral,
    } = this.state;

    const {
      workOrderType,
      workOrderUrgency,
    } = this.props;

    // WOType
    if (editedWorkOrderType && workOrderType) {      
      const previousEditedWorkOrderType = prevState.editedWorkOrderType;
      if (previousEditedWorkOrderType != editedWorkOrderType) {
        if (JSON.stringify(editedWorkOrderType) != JSON.stringify(workOrderType)) {
          this.setState({
            woTypeEdited: true,
          }, this.setSaveGeneral(true))
        } else {
          this.setState({
            woTypeEdited: false,
          })
        }
      }
    }

    //Urgency
    if (editedWorkOrderUrgency && workOrderUrgency) {
      const previousEditedWorkOrderUrgency = prevState.editedWorkOrderUrgency;
      if (previousEditedWorkOrderUrgency != editedWorkOrderUrgency) {
        if (JSON.stringify(editedWorkOrderUrgency) != JSON.stringify(workOrderUrgency)) {
          this.setState({
            woUrgencyEdited: true,
          }, this.setSaveGeneral(true))
        } else {
          this.setState({
            woUrgencyEdited: false,
          })
        }
      }
    }

    if (saveGeneral) {
      if (!woTypeEdited && !woUrgencyEdited) {
       this.setSaveGeneral(false);
      } 
    }
    
    //Schedule
    const {
      editedWorkOrderDate,
      editedWorkOrderStartTime,
      editedWorkOrderEndTime,
      woScheduleDateEdited,
      woStartEdited,
      woEndEdited,
      saveSchedule,
    } = this.state;

    const {
      scheduleDate,
      timeWindowStart,
      timeWindowEnd,
    } = this.props;

    //date
    if (editedWorkOrderDate && scheduleDate) {
      const previousEditedWorkOrderDate = prevState.editedWorkOrderDate;
      const formattedEditDate = moment(editedWorkOrderDate).format('YYYY-MM-DD');
      const formattedScheduleDate = moment(scheduleDate).format('YYYY-MM-DD');

      if (editedWorkOrderDate != previousEditedWorkOrderDate) {
        if (formattedEditDate != formattedScheduleDate) {
          this.setState({
            woScheduleDateEdited: true,
          }, this.setSaveSchedule(true))
        } else {
          this.setState({
            woScheduleDateEdited: false
          })
        }
      }
    }

    //start time
    const previousEditedWorkOrderStartTime = prevState.editedWorkOrderStartTime;

    if (editedWorkOrderStartTime && timeWindowStart) {
      if (editedWorkOrderStartTime != previousEditedWorkOrderStartTime) {
        if (editedWorkOrderStartTime != timeWindowStart) {
          this.setState({
            woStartEdited: true
          }, this.setSaveSchedule(true))
        } else {
          this.setState({
            woStartEdited: false,
          })
        }
      }
    }

    if (!timeWindowStart && editedWorkOrderStartTime) {
      if (editedWorkOrderStartTime != previousEditedWorkOrderStartTime) {
        if (editedWorkOrderEndTime != moment().format('HH:mm')) {
          this.setState({
            woStartEdited: true,
          }, this.setSaveSchedule(true))
        } else {
          this.setState({
            woStartEdited: false,
          })
        }
      }
    }

    //end time
    const previousEditedWorkOrderEndTime = prevState.editedWorkOrderEndTime;

    if (editedWorkOrderEndTime && previousEditedWorkOrderEndTime && timeWindowEnd) {
      if (editedWorkOrderEndTime != previousEditedWorkOrderEndTime) {
        if (editedWorkOrderEndTime != timeWindowEnd) {
          this.setState({
            woEndEdited: true,
          }, this.setSaveSchedule(true))
        } else {
          this.setState({
            woEndEdited: false,
          })
        }
      }
    }

    if (!timeWindowEnd && editedWorkOrderEndTime) {
      const previousEditedWorkOrderEndTime = prevState.editedWorkOrderEndTime;
      if (editedWorkOrderEndTime != previousEditedWorkOrderEndTime) {
        if (editedWorkOrderEndTime != moment().format('HH:mm')) {
          this.setState({
            woEndEdited: true,
          }, this.setSaveSchedule(true))
        } else {
          this.setState({
            woEndEdited: false,
          })
        }
      }
    }

    if (saveSchedule) {
      if (!woScheduleDateEdited && !woStartEdited && !woEndEdited) {
        this.setSaveSchedule(false);
      }
    }    

    // Contractor
    const {
      editedWorkOrderContractorName,
      editedWorkOrderContractorEmail,
      editedWorkOrderContractorPhone,
      woContractorNameEdited,
      woContractorEmailEdited,
      woContractorPhoneEdited,
      saveContractor,
    } = this.state;

    const {
     contractorName,
     contractorEmail,
     contractorPhone,
    } = this.props;

    //contractor name
    if (editedWorkOrderContractorName && contractorName) {
      const previouseditedWorkOrderContractorName = prevState.editedWorkOrderContractorName;
      if (previouseditedWorkOrderContractorName != editedWorkOrderContractorName) {
        if (editedWorkOrderContractorName != contractorName) {
          this.setState({
            woContractorNameEdited: true,
          }, this.setSaveContractor(true))
        } else {
          this.setState({
            woContractorNameEdited: false,
          })
        }
      }
    }

    //contractor email
    if (editedWorkOrderContractorEmail && contractorEmail) {
      const previouseditedWorkOrderContractorEmail = prevState.editedWorkOrderContractorEmail;
      if (previouseditedWorkOrderContractorEmail != editedWorkOrderContractorEmail) {
        if (editedWorkOrderContractorEmail != contractorEmail) {
          this.setState({
            woContractorEmailEdited: true,
          }, this.setSaveContractor(true))
        } else {
          this.setState({
            woContractorEmailEdited: false,
          })
        }
      }
    }

    //contractor phone
    if (editedWorkOrderContractorPhone && contractorPhone) {
      const previouseditedWorkOrderContractorPhone = prevState.editedWorkOrderContractorPhone;
      if (previouseditedWorkOrderContractorPhone != editedWorkOrderContractorPhone) {
        if (editedWorkOrderContractorPhone != contractorPhone) {
          this.setState({
            woContractorPhoneEdited: true,
          }, this.setSaveContractor(true))
        } else {
          this.setState({
            woContractorPhoneEdited: false,
          })
        }
      }
    }

    if (saveContractor) {
      if (!woContractorNameEdited && !woContractorEmailEdited && !woContractorPhoneEdited) {
        this.setSaveContractor(false);
      }
    } 

    //Submitter
    const {
      editedWorkOrderSubmitterName,
      editedWorkOrderSubmitterEmail,
      editedWorkOrderSubmitterPhone,
      woSubmitterNameEdited,
      woSubmitterEmailEdited,
      woSubmitterPhoneEdited,
      saveSubmitter,
    } = this.state;

    const {
      submitName,
      submitEmail,
      submitPhone,
    } = this.props;

    //submitter name
    if (editedWorkOrderSubmitterName && submitName) {
      const previousEditedWorkOrderSubmitterName = prevState.editedWorkOrderSubmitterName;
      if (previousEditedWorkOrderSubmitterName != editedWorkOrderSubmitterName) {
        if (editedWorkOrderSubmitterName != submitName) {
          this.setState({
            woSubmitterNameEdited: true,
          }, this.setSaveSubmitter(true))
        } else {
          this.setState({
            woSubmitterNameEdited: false,
          })
        }
      }
    }

    //submitter email
    if (editedWorkOrderSubmitterEmail && submitEmail) {
      const previousEditedWorkOrderSubmitterEmail = prevState.editedWorkOrderSubmitterEmail;
      if (previousEditedWorkOrderSubmitterEmail != editedWorkOrderSubmitterEmail) {
        if (editedWorkOrderSubmitterEmail != submitEmail) {
          this.setState({
            woSubmitterEmailEdited: true,
          }, this.setSaveSubmitter(true))
        } else {
          this.setState({
            woSubmitterEmailEdited: false,
          })
        }
      }
    }

    //submitter phone
    if (editedWorkOrderSubmitterPhone && submitPhone) {
      const previousEditedWorkOrderSubmitterPhone = prevState.editedWorkOrderSubmitterPhone;
      if (previousEditedWorkOrderSubmitterPhone != editedWorkOrderSubmitterPhone) {
        if (editedWorkOrderSubmitterPhone != submitPhone) {
          this.setState({
            woSubmitterPhoneEdited: true,
          }, this.setSaveSubmitter(true))
        } else {
          this.setState({
            woSubmitterPhoneEdited: false,
          })
        }
      }
    }

    if (saveSubmitter) {
      if (!woSubmitterNameEdited && !woSubmitterEmailEdited && !woSubmitterPhoneEdited) {
        this.setSaveSubmitter(false);
      }
    } 

    //Assigned
    const {
      editedWorkOrderAssignedName,
      editedWorkOrderAssignedEmail,
      editedWorkOrderAssignedPhone,
      woAssignedNameEdited,
      woAssignedEmailEdited,
      woAssignedPhoneEdited,
      saveAssigned,
    } = this.state;

    const {
      assignedToName,
      assignedToEmail,
      assignedToPhone,
    } = this.props;

    //assigned name
    if (editedWorkOrderAssignedName && assignedToName) {
      const previouseditedWorkOrderAssignedName = prevState.editedWorkOrderAssignedName;
      if (previouseditedWorkOrderAssignedName != editedWorkOrderAssignedName) {
        if (editedWorkOrderAssignedName != assignedToName) {
          this.setState({
            woAssignedNameEdited: true,
          }, this.setSaveAssigned(true))
        } else {
          this.setState({
            woAssignedNameEdited: false,
          })
        }
      }
    }

    //assigned email
    if (editedWorkOrderAssignedEmail && assignedToEmail) {
      const previouseditedWorkOrderAssignedEmail = prevState.editedWorkOrderAssignedEmail;
      if (previouseditedWorkOrderAssignedEmail != editedWorkOrderAssignedEmail) {
        if (editedWorkOrderAssignedEmail != assignedToEmail) {
          this.setState({
            woAssignedEmailEdited: true,
          }, this.setSaveAssigned(true))
        } else {
          this.setState({
            woAssignedEmailEdited: false,
          })
        }
      }
    }

    //assigned phone
    if (editedWorkOrderAssignedPhone && assignedToPhone) {
      const previouseditedWorkOrderAssignedEmail = prevState.editedWorkOrderAssignedPhone;
      if (previouseditedWorkOrderAssignedEmail != editedWorkOrderAssignedPhone) {
        if (editedWorkOrderAssignedPhone != assignedToPhone) {
          this.setState({
            woAssignedPhoneEdited: true,
          }, this.setSaveAssigned(true))
        } else {
          this.setState({
            woAssignedPhoneEdited: false,
          })
        }
      }
    }

    if (saveAssigned) {
      if (!woAssignedNameEdited && !woAssignedEmailEdited && !woAssignedPhoneEdited) {
        this.setSaveAssigned(false);
      }
    } 

    //Estimates
    const {
      editedWorkOrderNTE,
      editedWorkOrderEstimate,
      editedWorkOrderEstimateBid,
      woEditedNTE,
      woEditedEstimate,
      woEditedBidPrice,
      saveEstimates,
    } = this.state;

    const {
      nte,
      estimate,
      bidPrice,
    } = this.props;

    if (editedWorkOrderNTE && nte) {
      const previousEditedWorkOrderNTE = prevState.editedWorkOrderNTE;
      if (previousEditedWorkOrderNTE !=editedWorkOrderNTE) {
        if (editedWorkOrderNTE != nte) {
          this.setState({
            woEditedNTE: true,
          }, this.setSaveEstimates(true));
        } else {
          this.setState({
            woEditedNTE: false,
          })
        }
      }  
    }

    if (editedWorkOrderEstimate && estimate) {
      const previousEditedWorkOrderEstimate = prevState.editedWorkOrderEstimate;
      if (previousEditedWorkOrderEstimate != editedWorkOrderEstimate) {
        if (editedWorkOrderEstimate != estimate) {
          this.setState({
            woEditedEstimate: true,
          }, this.setSaveEstimates(true));
        } else {
          this.setState({
            woEditedEstimate: false,
          })
        }
      }
    }

    if (editedWorkOrderEstimateBid && bidPrice) {
      const previousEditedWorkOrderEstimateBid = prevState.editedWorkOrderEstimateBid;
      if (previousEditedWorkOrderEstimateBid != editedWorkOrderEstimateBid) {
        if (editedWorkOrderEstimateBid != bidPrice) {
          this.setState({
            woEditedBidPrice: true,
          }, this.setSaveEstimates(true));
        } else {
          this.setState({
            woEditedBidPrice: false,
          })
        }
      }
    }

    if (saveEstimates) {
      if (!woEditedNTE && !woEditedEstimate && !woEditedBidPrice) {
        this.setSaveEstimates(false);
      }
    }

    //Invoices
    const {
      editedWorkOrderContractorInvoice,
      editedWorkOrderBluefinInvoice,
      editedWorkOrderPurchaseOrderNumber,
      editedWorkOrderExtWONumber,
      woEditedContractorInvoice,
      woEditedBluefinInvoice,
      woEditedPONumber,
      woEditedExtWONumber,
      saveInvoices,
    } = this.state;

    const {
      contractorInvoice,
      bfInvoice,
      poNumber,
      externalNumber,
    } = this.props;

    if (editedWorkOrderContractorInvoice && contractorInvoice) {
      const previousEditedWOContractorInvoice = prevState.editedWorkOrderContractorInvoice;
      if (previousEditedWOContractorInvoice != editedWorkOrderContractorInvoice) {
        if (editedWorkOrderContractorInvoice != contractorInvoice) {
          this.setState({
            woEditedContractorInvoice: true,
          }, this.setSaveInvoices(true));
        } else {
          this.setState({
            woEditedContractorInvoice: false,
          })
        }
      }
    }

    if (editedWorkOrderBluefinInvoice && bfInvoice) {
      const previousEditedWorkOrderBFInvoice = prevState.editedWorkOrderBluefinInvoice;
      if (previousEditedWorkOrderBFInvoice != editedWorkOrderBluefinInvoice) {
        if (editedWorkOrderBluefinInvoice != bfInvoice) {
          this.setState({
            woEditedBluefinInvoice: true,
          }, this.setSaveInvoices(true));
        } else {
          this.setState({
            woEditedBluefinInvoice: false,
          })
        }
      }
    }

    if (editedWorkOrderPurchaseOrderNumber && poNumber) {
      const previousEditedWorkOrderPONumber = prevState.editedWorkOrderPurchaseOrderNumber;
      if (previousEditedWorkOrderPONumber != editedWorkOrderPurchaseOrderNumber) {
        if (editedWorkOrderPurchaseOrderNumber != poNumber) {
          this.setState({
            woEditedPONumber: true,
          }, this.setSaveInvoices(true));
        } else {
          this.setState({
            woEditedPONumber: false,
          })
        }
      }
    }

    if (editedWorkOrderExtWONumber && externalNumber) {
      const previousEditedWorkOrderExtWONumber = prevState.editedWorkOrderExtWONumber;
      if (previousEditedWorkOrderExtWONumber != editedWorkOrderExtWONumber) {
        if (editedWorkOrderExtWONumber != externalNumber) {
          this.setState({
            woEditedExtWONumber: true,
          }, this.setSaveInvoices(true));
        } else {
          this.setState({
            woEditedExtWONumber: false,
          })
        }
      }
    }
    
    if (saveInvoices) {
      if (!woEditedContractorInvoice && !woEditedBluefinInvoice && !woEditedPONumber && !woEditedExtWONumber) {
        this.setSaveInvoices(false);
      }
    }

    // Editing Contents of Work Order Items (loading)
    const {
      editedWorkOrderItem,
      editingWorkOrderItem,
      addedSectionV4,
      workOrderItemsLoading,
      saveWorkOrderItem,
      deleteWorkOrderItem,
    } = this.state;

    const {
      workOrderItems,
    } = this.props;
    const previousWorkOrderItems = prevProps.workOrderItems;

    if (workOrderItems.length > 0 && !deleteWorkOrderItem) {
      // NAME
      if (editedWorkOrderItem && editingWorkOrderItem) {
        const editedName = editedWorkOrderItem.Name;
        const storedName = editingWorkOrderItem.Name;
        const editedWorkOrderItemId = editedWorkOrderItem.WoitemId;
        const returnedWorkOrderItem = workOrderItems.find(workOrderItem => workOrderItem.WoitemId == editedWorkOrderItemId);
        const returnedName = returnedWorkOrderItem.Name;
        
        // turns on loading
        if (editedName != storedName) {
          if (!workOrderItemsLoading) {
            if (saveWorkOrderItem) {
              this.setState({
                workOrderItemsLoading: true,
                saveWorkOrderItem: false,
              })
            }
          }
        }
        // turns off loading
        if (editedName == returnedName) {
          if (workOrderItemsLoading) {
            if (!saveWorkOrderItem) {
              if (JSON.stringify(editedWorkOrderItem) == JSON.stringify(returnedWorkOrderItem)) {
                this.setState({
                  workOrderItemsLoading: false,
                  editingWorkOrderItem: null,
                })
              }
            }
          }
        }
      }

      // DESCRIPTION
      if (editedWorkOrderItem && editingWorkOrderItem) {
        const editedDescription = editedWorkOrderItem.Recommendation;
        const storedDescription = editingWorkOrderItem.Recommendation;
        const editedWorkOrderItemId = editedWorkOrderItem.WoitemId;
        const returnedWorkOrderItem = workOrderItems.find(workOrderItem => workOrderItem.WoitemId == editedWorkOrderItemId);
        const returnedDescription = returnedWorkOrderItem.Recommendation;

        //turns on loading
        if (editedDescription != storedDescription) {
          if (!workOrderItemsLoading) {
            if (saveWorkOrderItem) {
              this.setState({
                workOrderItemsLoading: true,
                saveWorkOrderItem: false,
              })
            }
          }
        }
        // turns off loading
        if (editedDescription == returnedDescription) {
          if (workOrderItemsLoading) {
            if (!saveWorkOrderItem) {
              if (JSON.stringify(editedWorkOrderItem) == JSON.stringify(returnedWorkOrderItem)) {
                this.setState({
                  workOrderItemsLoading: false,
                }, console.log('description'))
              }
            }
          }
        }
      }

      // adding SECTIONS (turns on loading)
      if (editedWorkOrderItem && editingWorkOrderItem) {
        const editedSections = editedWorkOrderItem.WoSection;
        const databaseSections = editingWorkOrderItem.WoSection;
        const newSection = editedSections.find(editedSection => editedSection.WoitemId == 0);

        if (newSection) {
          const newSectionV4 = newSection.V4_GUID;
          const findNewInDB = databaseSections.find(databaseSection => databaseSection.V4_GUID == newSectionV4);
          if (!findNewInDB && !workOrderItemsLoading) {
            if (saveWorkOrderItem) {
              this.setState({
                workOrderItemsLoading: true,
                editingWorkOrderItem: null,
                saveWorkOrderItem: false,
                addedSectionV4: newSectionV4,
              })
            }
          }
        }
        // removing SECTIONS (turns on loading)
        if (!newSection) {
          if (saveWorkOrderItem) {
            if (JSON.stringify(editedSections) != JSON.stringify(databaseSections)) {
              this.setState({
                workOrderItemsLoading: true,
                editingWorkOrderItem: null,
                saveWorkOrderItem: false,
              })
            }
          }
        }
      }
      // adding SECTIONS (turns off loading)
      if (!editingWorkOrderItem && editedWorkOrderItem) {
        const selectedItem = workOrderItems.find(workOrderItem => workOrderItem.WoitemId == editedWorkOrderItem.WoitemId);
        const databaseSections = selectedItem.WoSection;
        const editedSections = editedWorkOrderItem.WoSection;
        
        if (addedSectionV4) {
          const findNewInDB = databaseSections.find(databaseSection => databaseSection.V4_GUID == addedSectionV4);
          if (findNewInDB && workOrderItemsLoading) {
            this.setState({
              workOrderItemsLoading: false,
              addedSectionV4: null,
            }, console.log('sections'))
          }
        }
        // removing SECTIONS (turns off loading)
        if (!addedSectionV4) {
          if (workOrderItemsLoading) {
            if (JSON.stringify(editedSections) == JSON.stringify(databaseSections)) {
              this.setState({
                workOrderItemsLoading: false,
              }, console.log('sections 2'))
            }
          }
        }
      }
      // edit COST ITEMS
      if (editedWorkOrderItem && editingWorkOrderItem) {
        const editedDetail = editedWorkOrderItem.WoItemDetail;
        const storedDetail = editingWorkOrderItem.WoItemDetail;
        const editedWorkOrderItemId = editedWorkOrderItem.WoitemId;
        const returnedWorkOrderItem = workOrderItems.find(workOrderItem => workOrderItem.WoitemId == editedWorkOrderItemId);
        const returnedDetail = returnedWorkOrderItem.WoItemDetail;
       
        // adding COST ITEMS (turns on loading)
        if (JSON.stringify(editedDetail) != JSON.stringify(storedDetail)) {
          if (!workOrderItemsLoading) {
            if (saveWorkOrderItem) {
              this.setState({
                workOrderItemsLoading: true,
                saveWorkOrderItem: false,
              })
            }
          }
        }
        // adding COST ITEMS (turns off loading)
        if (workOrderItems != previousWorkOrderItems) {
          if (editedDetail.length == returnedDetail.length) {
            if (workOrderItemsLoading) {
              if (!saveWorkOrderItem) {
                this.setState({
                  workOrderItemsLoading: false,
                }, console.log('cost items'))
              }
            }
          }
        }
      }

      // adding & removing DEFECTS
      if (editedWorkOrderItem && editingWorkOrderItem) {
        const editedWorkOrderItemDefects = editedWorkOrderItem.WoItemDefects;
        const storedWorkOrderItemDefects = editingWorkOrderItem.WoItemDefects;
        const editedWorkOrderItemId = editedWorkOrderItem.WoitemId;
        const returnedWorkOrderItem = workOrderItems.find(workOrderItem => workOrderItem.WoitemId == editedWorkOrderItemId);
        const returnedWorkOrderItemDefects = returnedWorkOrderItem.WoItemDefects;

        // turns on loading
        if (JSON.stringify(editedWorkOrderItemDefects) != JSON.stringify(storedWorkOrderItemDefects)) {
          if (!workOrderItemsLoading) {
            if (saveWorkOrderItem) {
              this.setState({
                workOrderItemsLoading: true,
                saveWorkOrderItem: false,
              })
            }
          }
          // turns off loading
          if (JSON.stringify(editedWorkOrderItemDefects) == JSON.stringify(returnedWorkOrderItemDefects)) {
            if (workOrderItemsLoading) {
              if (!saveWorkOrderItem) {
                this.setState({
                  workOrderItemsLoading: false,
                }, console.log('defects'))
              }
            }
          }
        }
      }

      // adding PHOTOS
      if (editedWorkOrderItem && editingWorkOrderItem) {
        const editedWorkOrderItemPhotos = editedWorkOrderItem.WoItemPhoto;
        const storedWorkOrderItemPhotos = editingWorkOrderItem.WoItemPhoto;
        const editedWorkOrderItemId = editedWorkOrderItem.WoitemId;
        const returnedWorkOrderItem = workOrderItems.find(workOrderItem => workOrderItem.WoitemId == editedWorkOrderItemId);
        const returnedWorkOrderItemPhotos = returnedWorkOrderItem.WoItemPhoto;
       
        // turns on loading
        if (editedWorkOrderItemPhotos && editedWorkOrderItemPhotos.length > 0) {
          if (JSON.stringify(editedWorkOrderItemPhotos) != JSON.stringify(storedWorkOrderItemPhotos)) {
            if (!workOrderItemsLoading) {
              if (saveWorkOrderItem) {
                this.setState({
                  workOrderItemsLoading: true,
                })
              }
            }
          }
        }
        // console.log(saveWorkOrderItem, prevState.saveWorkOrderItem)
        // console.log(editedWorkOrderItemPhotos, storedWorkOrderItemPhotos)
        //TODO: turns off loading (rough concept)
        console.log(1)
        if (JSON.stringify(editedWorkOrderItemPhotos) == JSON.stringify(returnedWorkOrderItemPhotos)) {
          console.log(2)
          if (workOrderItemsLoading) {
            console.log(3)
            if (!saveWorkOrderItem) {
              console.log(4)
              this.setState({
                workOrderItemsLoading: false,
              })
            }
          }
        }
      }

      //TODO: removing photos

      // set modified wo item to default so additional changes can be made without reload
      if (workOrderItems != previousWorkOrderItems) {
        if (editedWorkOrderItem != editingWorkOrderItem) {
          this.setState({
            editingWorkOrderItem: editedWorkOrderItem,
            editedWorkOrderItem: false,
          })
        }
      }
    }
    
    //<-------------------------------loading for ADDING AND REMOVING ITEMS-------------------------------->

    // adding and removing work order items
    const {
      createdWorkOrderItem,
    } = this.state;
    const previousDeleteWorkOrderItem = prevState.deleteWorkOrderItem;
    const previousCreatedWorkOrderItem = prevState.createdWorkOrderItem;
    const previousNumberOfItems = previousWorkOrderItems.length;
    const currentNumberOfItems = workOrderItems.length;

    // adding (turns loading on)
    if (!createdWorkOrderItem && previousCreatedWorkOrderItem) {
      if (currentNumberOfItems+1 > previousNumberOfItems) {
        if (!workOrderItemsLoading) {
          if (saveWorkOrderItem) {
            this.setState({
              workOrderItemsLoading: true,
              saveWorkOrderItem: false,
            })
          }
        }
      }
    }

    // adding (turns loading off)
    if (!previousCreatedWorkOrderItem) {
      if (previousNumberOfItems+1 == currentNumberOfItems) {
        if (workOrderItemsLoading) {
          if (!saveWorkOrderItem) {
            this.setState({
              workOrderItemsLoading: false,
            }, console.log('add'))
          }
        }
      }
    }
   
    // deleting (turns loading on)
    if (!deleteWorkOrderItem && previousDeleteWorkOrderItem) {
      if (currentNumberOfItems == previousNumberOfItems) {
        if (!workOrderItemsLoading) {
          this.setState({
            workOrderItemsLoading: true,
          })
        }
      }
    }

    // deleting (turns loading off)
    if (!deleteWorkOrderItem && !previousDeleteWorkOrderItem) {
      if (currentNumberOfItems == previousNumberOfItems-1) {
        if (workOrderItemsLoading) {
          this.setState({
            workOrderItemsLoading: false,
          }, console.log('delete'))
        }
      }
    }
  };

  // <---------GETTERS----------->
  retrieveWorkOrderType = (editedType) => {
    this.setState({
      editedWorkOrderType: editedType,
    })
  };

  retrieveWorkOrderUrgency = (editedUrgency) => {
    this.setState({
      editedWorkOrderUrgency: editedUrgency,
    })
  };

  retrieveDate = (editedDate) => {
    this.setState({
      editedWorkOrderDate: editedDate,
    })
  };

  retrieveStartTime = (editedStartTime) => {
    this.setState({
      editedWorkOrderStartTime: editedStartTime,
    })
  };

  retrieveEndTime = (editedEndTime) => {
    this.setState({
      editedWorkOrderEndTime: editedEndTime,
    })
  };

  retrieveWorkOrderStatus = (editedStatus) => {
    const { editedWorkOrder } = this.state;
    let editedWorkOrderCopy = { ...editedWorkOrder };
    editedWorkOrderCopy.Status = editedStatus;

    this.setState({
      editedWorkOrder: editedWorkOrderCopy,
    })
  };

  retrieveWorkOrderContractorName = (editedName) => {
    this.setState({
      editedWorkOrderContractorName: editedName,
    })
  };

  retrieveWorkOrderContractorEmail = (editedEmail) => {
    this.setState({
      editedWorkOrderContractorEmail: editedEmail,
    })
  };

  retrieveWorkOrderContractorPhone = (editedPhone) => {   
    this.setState({
      editedWorkOrderContractorPhone: editedPhone,
    })
  };

  retrieveWorkOrderSubmitterName = (editedName) => {
    this.setState({
      editedWorkOrderSubmitterName: editedName,
    })
  };
  
  retrieveWorkOrderSubmitterEmail = (editedEmail) => {
    this.setState({
      editedWorkOrderSubmitterEmail: editedEmail,
    })
  };

  retrieveWorkOrderSubmitterPhone = (editedPhone) => {
    this.setState({
      editedWorkOrderSubmitterPhone: editedPhone,
    })
  };

  retrieveWorkOrderAssignedName = (editedName) => {
    this.setState({
      editedWorkOrderAssignedName: editedName,
    })
  };

  retrieveWorkOrderAssignedEmail = (editedEmail) => {
    this.setState({
      editedWorkOrderAssignedEmail: editedEmail,
    })
  };

  retrieveWorkOrderAssignedPhone = (editedPhone) => {
    this.setState({
      editedWorkOrderAssignedPhone: editedPhone,
    })
  };

  retrieveWorkOrderNTE = (editedNTE) => {
    this.setState({
      editedWorkOrderNTE: editedNTE,
    })
  };

  retrieveWorkOrderEstimate = (editedEstimate) => {
    this.setState({
      editedWorkOrderEstimate: editedEstimate,
    })
  };

  retrieveWorkOrderEstimateBid = (estimateBid) => {
    this.setState({
      editedWorkOrderEstimateBid: estimateBid,
    })
  };

  retrieveWorkOrderContractorInvoice = (contractorInvoice) => {
    this.setState({
      editedWorkOrderContractorInvoice: contractorInvoice,
    })
  };

  retrieveWorkOrderBluefinInvoice = (bluefinInvoice) => {
    this.setState({
      editedWorkOrderBluefinInvoice: bluefinInvoice,
    })
  };

  retrieveWorkOrderPurchaseOrderNumber = (purchaseOrderNumber) => {
    this.setState({
      editedWorkOrderPurchaseOrderNumber: purchaseOrderNumber,
    })
  };

  retrieveWorkOrderExternalWONumber = (extWONumber) => {
    this.setState({
      editedWorkOrderExtWONumber: extWONumber,
    })
  };

  retrieveCreateNewWorkOrderItem = (createdWorkOrderItem) => {
    this.setState({
      createdWorkOrderItem,
    })
  };

  //the selected work order to edit and the object values stored in DB
  retrieveEditingWorkOrderItem = (editingWorkOrderItem) => {
    this.setState({
      editingWorkOrderItem,
    })
  };

  //the object containing the edited values (from front end), if different than those stored in 'editingWorkOrder'
  retrieveEditedWorkOrderItem = (editedWorkOrderItem) => {
    this.setState({
      editedWorkOrderItem,
    })
  };

  // <---------SETTERS----------->
  setEditGeneral = () => {
    const { editGeneral } = this.state;
    this.setState({
      editGeneral: !editGeneral,
    })
  };

  setEditSchedule = () => {
    const { editSchedule } = this.state;
    this.setState({
      editSchedule: !editSchedule,
    })
  };

  setEditContractor = () => {
    const { editContractor } = this.state;
    this.setState({
      editContractor: !editContractor,
    })
  };

  setEditSubmitter = () => {
    const { editSubmitter } = this.state;
    this.setState({
      editSubmitter: !editSubmitter,
    })
  };

  setEditAssigned = () => {
    const { editAssigned } = this.state;
    this.setState({
      editAssigned: !editAssigned,
    })
  };

  setEditEstimates = () => {
    const { editEstimates } = this.state;
    this.setState({
      editEstimates: !editEstimates,
    })
  };

  setEditInvoices = () => {
    const { editInvoices } = this.state;
    this.setState({
      editInvoices: !editInvoices,
    })
  };

  // GENERAL
  setSaveGeneral = (bool) => {
    this.setState({
      saveGeneral: bool,
    })
  };

  saveGeneralData = () => {
    const { handleEditGeneralDispatch, urgencyType, workOrderType } = this.props;
    const { editedWorkOrderType, editedWorkOrderUrgency } = this.state;
    const woUrgency = editedWorkOrderUrgency ? editedWorkOrderUrgency : urgencyType;
    const woType = editedWorkOrderType ? editedWorkOrderType : workOrderType;

    this.setState({
      saveGeneral: false,
      editGeneral: false,
    }, () => handleEditGeneralDispatch(woUrgency, woType));
  };

  // SCHEDULE
  setSaveSchedule = (bool) => {
    this.setState({
      saveSchedule: bool,
    })
  };

  saveScheduleData = () => {
    const { scheduleDate, timeWindowStart, timeWindowEnd, dispatchEditDates } = this.props;
    const { editedWorkOrderDate, editedWorkOrderStartTime, editedWorkOrderEndTime } = this.state;
    const date = editedWorkOrderDate ? editedWorkOrderDate : scheduleDate;
    const start = editedWorkOrderStartTime ? editedWorkOrderStartTime : timeWindowStart;
    const end = editedWorkOrderEndTime ? editedWorkOrderEndTime : timeWindowEnd;

    this.setState({
      saveSchedule: false,
      editedSchedule: false,
    }, () => dispatchEditDates(date, start, end));
  };

  // CONTRACTOR
  setSaveContractor = (bool) => {
    this.setState({
      saveContractor: bool,
    })
  };

  saveContractorData = () => {
    const { contractorName, contractorEmail, contractorPhone, dispatchEditContractor } = this.props;
    const { editedWorkOrderContractorName, editedWorkOrderContractorEmail, editedWorkOrderContractorPhone } = this.state;
    const name = editedWorkOrderContractorName ? editedWorkOrderContractorName : contractorName;
    const email = editedWorkOrderContractorEmail ? editedWorkOrderContractorEmail : contractorEmail;
    const phone = editedWorkOrderContractorPhone ? editedWorkOrderContractorPhone : contractorPhone;

    this.setState({
      saveContractor: false,
      editContractor: false,
    }, () => dispatchEditContractor(name, email, phone));
  };

  // SUBMITTER
  setSaveSubmitter = (bool) => {
    this.setState({
      saveSubmitter: bool,
    })
  };

  saveSubmitterData = () => {
    const { submitName, submitEmail, submitPhone, dispatchEditSubmitter } = this.props;
    const { editedWorkOrderSubmitterName, editedWorkOrderSubmitterEmail, editedWorkOrderSubmitterPhone } = this.state;
    const name = editedWorkOrderSubmitterName ? editedWorkOrderSubmitterName : submitName;
    const email = editedWorkOrderSubmitterEmail ? editedWorkOrderSubmitterEmail : submitEmail;
    const phone = editedWorkOrderSubmitterPhone ? editedWorkOrderSubmitterPhone : submitPhone;

    this.setState({
      saveSubmitter: false,
      editSubmitter: false,
    }, ()=>dispatchEditSubmitter(name, email, phone));
  };

  // ASSIGNED
  setSaveAssigned = (bool) => {
    this.setState({
      saveAssigned: bool,
    })
  };

  saveAssignedData = () => {
    const { assignedToName, assignedToEmail, assignedToPhone, dispatchEditAssigned } = this.props;
    const { editedWorkOrderAssignedName, editedWorkOrderAssignedEmail, editedWorkOrderAssignedPhone } = this.state;
    const name = editedWorkOrderAssignedName ? editedWorkOrderAssignedName : assignedToName;
    const email = editedWorkOrderAssignedEmail ? editedWorkOrderAssignedEmail : assignedToEmail;
    const phone = editedWorkOrderAssignedPhone ? editedWorkOrderAssignedPhone : assignedToPhone; 

    this.setState({
      saveAssigned: false,
      editAssigned: false,
    }, () => dispatchEditAssigned(name, email, phone));
  };

  // ESTIMATES
  setSaveEstimates = (bool) => {
    this.setState({
      saveEstimates: bool,
    })
  };

  saveEstimatesData = () => {
    const { nte, estimate, bidPrice, dispatchEditEstimateNumbers } = this.props;
    const { editedWorkOrderNTE, editedWorkOrderEstimate, editedWorkOrderEstimateBid } = this.state;
    const NTE = editedWorkOrderNTE ? editedWorkOrderNTE : nte;
    const WOEstimate = editedWorkOrderEstimate ? editedWorkOrderEstimate : estimate;
    const bid = editedWorkOrderEstimateBid ? editedWorkOrderEstimateBid : bidPrice; 

    this.setState({
      saveEstimates: false,
      editEstimates: false,
    }, () => dispatchEditEstimateNumbers(NTE, WOEstimate, bid));
  };

  // INVOICES
  setSaveInvoices = (bool) => {
    this.setState({
      saveInvoices: bool,
    })
  };

  saveInvoicesData = () => {
    const { contractorInvoice, bfInvoice, poNumber, externalNumber, dispatchEditInvoiceNumbers } = this.props;
    const { editedWorkOrderContractorInvoice, editedWorkOrderBluefinInvoice, editedWorkOrderPurchaseOrderNumber, editedWorkOrderExtWONumber } = this.state;
    
    const contractInvoice = editedWorkOrderContractorInvoice ? editedWorkOrderContractorInvoice : contractorInvoice;
    const bluefinInvoice = editedWorkOrderBluefinInvoice ? editedWorkOrderBluefinInvoice : bfInvoice;
    const purchaseOrderNumber = editedWorkOrderPurchaseOrderNumber ? editedWorkOrderPurchaseOrderNumber : poNumber;
    const externalWorkOrder = editedWorkOrderExtWONumber ? editedWorkOrderExtWONumber : externalNumber;

    this.setState({
      saveInvoices: false,
      editInvoices: false,
    }, () => dispatchEditInvoiceNumbers(contractInvoice, bluefinInvoice, purchaseOrderNumber, externalWorkOrder));
  };

  // WORK ORDER ITEMS
  handleOpenWorkOrderItems = (workOrderItem) => {
    if (workOrderItem) {
      this.setState({
        addingWorkOrderItems: true,
        editingWorkOrderItem: workOrderItem,
      })
    } else {
      this.setState({
        addingWorkOrderItems: true,
        editingWorkOrderItem: false,
      })
    }
  };

  retrieveWorkOrderItemFulfilling = (bool) => {
    this.setState({
      itemPromiseFulfilling: bool,
    })
  };

  findWorkOrderItemChanges = () => {
    const { 
      createdWorkOrderItem, 
      editingWorkOrderItem,
      editedWorkOrderItem, 
    } = this.state;

    //if user is creating a new work order item
    if (createdWorkOrderItem && !editingWorkOrderItem) {
      const defaultWorkOrderItem = {
        WoitemId: -1,
        Woid: this.props.workOrderId,
        WoitemNum: 0,
        Name: null,
        Recommendation: '',
        TotalEstimate: 0,
        TotalItemDetail: 0,
        WoItemDefects: [],
        WoItemDetail: [],
        WoItemPhoto: null,
        WoSection: [],
      };
      const createdStringObj = JSON.stringify(createdWorkOrderItem);
      const defaultStringObj = JSON.stringify(defaultWorkOrderItem);

      //if creating a new work order item, make sure it has a real name before saving to db
      if (createdStringObj != defaultStringObj) {
        const createdHasName = createdWorkOrderItem.Name;
        const isRealName = createdHasName ? createdHasName.trim().length > 0 : false;
        if (isRealName) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    //if user is editing a existing work order item
    if (editingWorkOrderItem && editedWorkOrderItem) { 
      const currentWorkOrderItemStr = JSON.stringify(editedWorkOrderItem);
      const previousWorkOrderItemStr = JSON.stringify(editingWorkOrderItem);

      if (currentWorkOrderItemStr != previousWorkOrderItemStr) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleCloseWorkOrderItems = () => {
    const { saveOrCancelWorkOrderItems } = this.state;
    const dirty = this.findWorkOrderItemChanges();

    if (dirty) {
      if (!saveOrCancelWorkOrderItems) {
        this.setState({
          saveOrCancelWorkOrderItems: true,
        })
      }
    } else {
      this.setState({
        addingWorkOrderItems: false,
        editingWorkOrderItem: null,
        saveOrCancelWorkOrderItems: false,
      })
    }
  };

  handleSaveAndCancelWorkOrderItem = (save) => {
    const { createdWorkOrderItem, editingWorkOrderItem, editedWorkOrderItem } = this.state;
    const { dispatchCreateNewWorkOrderItem, dispatchUpdateWorkOrderItem } = this.props;

    if (save) {
      this.setState({
        addingWorkOrderItems: false,
        saveOrCancelWorkOrderItems: false,
        createdWorkOrderItem: null,
        saveWorkOrderItem: true,
      }, createdWorkOrderItem && !editingWorkOrderItem ?
        dispatchCreateNewWorkOrderItem(createdWorkOrderItem) :
        dispatchUpdateWorkOrderItem(editedWorkOrderItem));
    } else {
      this.setState({
        addingWorkOrderItems: false,
        saveOrCancelWorkOrderItems: false,
        createdWorkOrderItem: null,
        editingWorkOrderItem: null,
        saveWorkOrderItem: false,
      }, editedWorkOrderItem ? 
        this.handleRemovePhotosFromBucketOnCancel(editedWorkOrderItem):
        this.handleRemovePhotosFromBucketOnCancel(createdWorkOrderItem));
    }
  };

  deletePhoto = (photo) => {
    const { token, identityId } = this.props;
    const { Woid, WoitemId } = photo;
    const dirName = `${Woid}/${WoitemId}`;

    AWS.config.region = 'us-west-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token
      }
    });

    const s3 = new AWS.S3;

    s3.config.region = 'us-west-2';
    s3.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token
      }
    });
    
    const key = photo.key;

    const params = {
      Bucket: 'workorderphotos',
      Key: key,
    };

    try {
      Promise.resolve(s3.headObject(params))
      console.log('File found in bucket')
      try {
        Promise.resolve(s3.deleteObject(params))
        console.log('file successfully deleted')
        return new Promise((resolve, reject) => {
         resolve(params);
         reject(false);
        })
      }
      catch (err) {
        console.error(err);
        return new Promise((resolve, reject) => {
          reject(false);
        })
      }
    }
    catch (err) {
      console.error(err);
      return new Promise((resolve, reject) => {
        reject(false);
      })
    }
  };

  handleRemovePhotosFromBucketOnCancel = (editedWorkOrderItem) => {
    const photosToRemove = editedWorkOrderItem.WoItemPhoto;
    if (photosToRemove) {
      const promises = [];

      photosToRemove.map((photoToRemove) => {
        const photoToDelete = this.deletePhoto(photoToRemove);
        if (photoToDelete) {
          promises.push(photoToDelete);
        } else {
          alert('s3 delete has failed, check console for errors')
        }
      })

      Promise.all(promises)
        .then(response => {        
          this.setState({
            canceledPhotos: response,
          })
        })
        .catch(err => console.error(err));
    } 
  };

  handleCancelSaveWorkOrderItemModal = () => {
    this.setState({
      saveOrCancelWorkOrderItems: false,
    })
  };

  handleDeleteWorkOrderItem = (workOrderItem) => {
    this.setState({
      deleteWorkOrderItem: workOrderItem,
    })
  };

  handleDeleteWorkOrderItemResponse = (response) => {
    const { deleteWorkOrderItem } = this.state;
    const { dispatchDeleteWorkOrderItem } = this.props;
    if (response) {
      this.setState({
        deleteWorkOrderItem: false,
      }, dispatchDeleteWorkOrderItem(deleteWorkOrderItem));
    } else {
      this.setState({
        deleteWorkOrderItem: false,
      });
    }
  };

  handleRemoveCostItem = (costItem) => {
    const { editedWorkOrderItem } = this.state;
    const editedWorkOrderItemDetails = editedWorkOrderItem.WoItemDetail;
    const detailToRemoveIndex = editedWorkOrderItemDetails.findIndex(editedWorkOrderItemDetail => JSON.stringify(editedWorkOrderItemDetail) == JSON.stringify(costItem));   
    const editedWorkOrderItemDetailsCopy = [ ...editedWorkOrderItemDetails ];
    const newEditedWorkOrderItemDetails = editedWorkOrderItemDetailsCopy.splice(detailToRemoveIndex, 0);
    const editedWorkOrderItemCopy = { ...editedWorkOrderItem };
    editedWorkOrderItemCopy.WoItemDetail = newEditedWorkOrderItemDetails;

    this.setState({
      editedWorkOrderItem: editedWorkOrderItemCopy,
    });
  };

  // DOCUMENTS
  retrieveScopeOfWorkDoc = (scopeOfWork) => {
    const { dispatchUpdateScopeOfWork } = this.props;
    this.setState({
      scopeOfWork,
    }, scopeOfWork ?
        () => this.handleUploadDocument(scopeOfWork, dispatchUpdateScopeOfWork) :
        () => this.handleDeleteDocument(scopeOfWork, dispatchUpdateScopeOfWork));
  };

  retrieveBidDoc = (bid) => {
    const { dispatchUpdateBid } = this.props;
    this.setState({
      bid,
    }, bid ?
        () => this.handleUploadDocument(bid, dispatchUpdateBid) :
        () => this.handleDeleteDocument(bid, dispatchUpdateBid));
  };

  retrieveContractorInvoiceDoc = (contractorInvoice) => {
    const { dispatchUpdateContractorInvoice } = this.props;
    this.setState({
      contractorInvoice,
    }, contractorInvoice ?
        () => this.handleUploadDocument(contractorInvoice, dispatchUpdateContractorInvoice) :
        () => this.handleDeleteDocument(contractorInvoice, dispatchUpdateContractorInvoice));
  };

  retrieveBluefinInvoiceDoc = (bluefinInvoice) => {
    const { dispatchBluefinInvoice } = this.props;
    this.setState({
      bluefinInvoice,
    }, bluefinInvoice ?
        () => this.handleUploadDocument(bluefinInvoice, dispatchBluefinInvoice) :
        () => this.handleDeleteDocument(bluefinInvoice, dispatchBluefinInvoice));
  };

  handleUploadDocument = (file, dispatch) => {
    const { editWorkOrder, token, identityId } = this.props;
    const { CompanyId, BuildingId, WOID } = editWorkOrder;
    const dirName = `${CompanyId}/${BuildingId}/${WOID}`;

    AWS.config.region = 'us-west-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token
      }
    });

    const s3 = new AWS.S3;
    s3.config.region = 'us-west-2';
    s3.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token
      }
    });

    const blob = new Blob([file], { type: 'text/plain' });

    const parameters = {
      bucket: 'bluefin-wo-files',
      key: dirName,
      s3: s3,
      stream: blob,
      acl: 'public-read',
      contentType: file.type
    };

    if (file && dispatch) {
      this.uploadBlob(parameters)
      .then(response => {
        if (response) {
          dispatch(file, response);
        }
      })
      .catch(err => console.log(err))
    }
  };

  uploadBlob = async (parameters) => {
    const {
      bucket,
      key,
      s3,
      stream,
      acl,
      contentType
    } = parameters;
    const params = { Bucket: bucket, Key: key, Body: stream, ACL: acl, ContentType: contentType };
    return s3.upload(params).promise();
  };

  handleDeleteDocument = (file, dispatch) => {
    const { editWorkOrder, token, identityId } = this.props;
    const { CompanyId, BuildingId, WOID } = editWorkOrder;

    const dirName = `${CompanyId}/${BuildingId}/${WOID}`;
    AWS.config.region = 'us-west-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token
      }
    });

    const s3 = new AWS.S3;

    s3.config.region = 'us-west-2';
    s3.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token
      }
    });

    const key = `https://bluefin-wo-files.s3-us-west-2.amazonaws.com/${file}`;

    var params = {
      Bucket: 'bluefin-wo-files',
      Key: key,
    };

    try {
      Promise.resolve(s3.headObject(params))
      console.log("File Found in S3")
      try {
        Promise.resolve(s3.deleteObject(params))
        console.log("file deleted Successfully")
        dispatch('');
      }
      catch (err) {
        console.log("ERROR in file Deleting : " + JSON.stringify(err))
      }
    } catch (err) {
      console.log("File not Found ERROR : " + err.code)
    }
  };

  handleOpenDocument = () => {
    const { editWorkOrder } = this.props;
    const { CompanyId, BuildingId, WOID } = editWorkOrder;

    const dirName = `${CompanyId}/${BuildingId}/${WOID}`;
    const url = `https://bluefin-wo-files.s3-us-west-2.amazonaws.com/${dirName}`;
    var win = window.open(url, '_blank');
    win.focus();
  };

  render() {
    const {
      editWorkOrder,
      propertyList,
      statusList,
      urgencyList,
      woTypeList,
      bfAdminUsers,
      contractorList, 
      //new data
      workOrderId,
      workOrderItems,
      workOrderNumber,
      workOrderType,
      workOrderTypeId,
      workOrderUrgency,
      scheduleDate,
      timeWindowStart,
      timeWindowEnd,
      contractor,
      contractorId,
      contractorName,
      contractorPhone,
      contractorEmail,
      description,
      poNumber,
      propertySections,
      propertyDefects,
      companyId,
      externalNumber,
      contractorInvoiceUrl,
      bfInvoice,
      buildingId,
      nte,
      estimate,
      bidPrice,
      actualPrice,
      projectId,
      building,
      status,
      submittedById,
      submitName,
      submitEmail,
      submitPhone,
      assignedToId,
      assignedToName,
      assignedToEmail,
      assignedToPhone,
      urgencyTypeId,
      urgencyType,
      comments,
      scopeOfWorkUrl,
      bidUrl,
      bfInvoiceUrl,
      completedWorkOrderUrl,
      contractorInvoice,
      activeUser,
      //dispatch
      dispatchCreateNewWorkOrderItem,
      dispatchAddComment,
      dispatchUpdateComment,
      dispatchDeleteComment,
      
      token, 
      identityId,
    } = this.props;

    const {
     selectedWorkOrder,
     editedWorkOrder,
     //sections to edit
     editGeneral,
     editSchedule,
     editContractor,
     editSubmitter,
     editAssigned,
     editEstimates,
     editInvoices,
     //sections to save
     saveGeneral,
     saveSchedule,
     saveContractor,
     saveSubmitter,
     saveAssigned,
     saveEstimates,
     saveInvoices,
     //work order items 
     workOrderItemsLoading,
     addingWorkOrderItems,
     saveOrCancelWorkOrderItems,
     createdWorkOrderItem,
     editingWorkOrderItem,
     editedWorkOrderItem,
     deleteWorkOrderItem,
     itemPromiseFulfilling,
    } = this.state;

    console.log(this.state)

    return (
        <Grid container direction='row' alignItems='center' justify='center'>
          <WorkOrderItemsModal 
            handleCloseWorkOrderItems={this.handleCloseWorkOrderItems}
            retrieveCreateNewWorkOrderItem={this.retrieveCreateNewWorkOrderItem}
            retrieveEditingWorkOrderItem={this.retrieveEditingWorkOrderItem}
            retrieveEditedWorkOrderItem={this.retrieveEditedWorkOrderItem}
            retrieveWorkOrderItemImages={this.retrieveWorkOrderItemImages}
            retrieveWorkOrderItemFulfilling={this.retrieveWorkOrderItemFulfilling}
            handleSaveAndCancelWorkOrderItem={this.handleSaveAndCancelWorkOrderItem}
            handleCancelSaveWorkOrderItemModal={this.handleCancelSaveWorkOrderItemModal}
            handleRemoveCostItem={this.handleRemoveCostItem}
            dispatchCreateNewWorkOrderItem={dispatchCreateNewWorkOrderItem}
            addingWorkOrderItems={addingWorkOrderItems}
            workOrderItems={workOrderItems}
            workOrderId={workOrderId}
            propertySections={propertySections}
            saveOrCancelWorkOrderItems={saveOrCancelWorkOrderItems}
            propertyDefects={propertyDefects}
            createdWorkOrderItem={createdWorkOrderItem}
            editingWorkOrderItem={editingWorkOrderItem}
            editedWorkOrderItem={editedWorkOrderItem}
            selectedWorkOrder={selectedWorkOrder}
            editWorkOrder={editWorkOrder}
            itemPromiseFulfilling={itemPromiseFulfilling}
            token={token}
            identityId={identityId}
          />
          <WorkOrderDeleteItemsModal 
            deleteWorkOrderItem={deleteWorkOrderItem}
            handleDeleteWorkOrderItemResponse={this.handleDeleteWorkOrderItemResponse}
          />
          <Grid item xs={9} style={{ height: '100%', maxHeight: '128rem' }}>
            <Grid container direction='row' style={{ height: '48%' }}>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Grid container direction='row' style={{ height: '40%' }}>
                  <Paper style={{ width: '100%', margin: '1%' }}>
                    <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                      <Grid item xs={10}>
                        <Typography variant='h6' style={{ margin: '2%' }}>General:</Typography>
                      </Grid>
                      <Grid item xs={2}>
                      { saveGeneral ?
                        <IconButton onClick={() => this.saveGeneralData()}>
                          <GetAppIcon />
                        </IconButton> 
                        : 
                        <IconButton onClick={() => this.setEditGeneral()}>
                          <EditIcon />
                        </IconButton>
                      }
                      </Grid>
                    </Grid>
                    <Grid container direction='row'>
                      <Grid item xs={6} style={{ height: '100%' }}>
                        <Grid container direction='row'>
                          <Grid item xs={12}>
                            <EditType
                              retrieveWorkOrderType={this.retrieveWorkOrderType}
                              editGeneral={editGeneral}
                              woType={workOrderType}
                              woTypeList={woTypeList}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} style={{ height: '100%' }}>
                        <Grid container direction='row'>
                          <Grid item xs={12}>
                            <EditUrgency
                              retrieveWorkOrderUrgency={this.retrieveWorkOrderUrgency}
                              editGeneral={editGeneral}
                              urgencyType={workOrderUrgency}
                              urgencyList={urgencyList}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid container direction='row' style={{ height: '60%' }}>
                  <Paper style={{ width: '100%', margin: '1%', marginTop: '0%' }}>
                    <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                      <Grid item xs={10}>
                        <Typography variant='h6' style={{ margin: '2%' }}>Schedule:</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        { saveSchedule ?
                          <IconButton onClick={() => this.saveScheduleData()}>
                            <GetAppIcon />
                          </IconButton>
                          :
                          <IconButton onClick={() => this.setEditSchedule()}>
                            <EditIcon />
                          </IconButton>
                        }
                      </Grid>
                    </Grid>
                    <Grid container direction='row' style={{margin: '2%'}}>    
                      <Grid item xs={12}>
                        <EditDateSelect
                          retrieveDate={this.retrieveDate}
                          editSchedule={editSchedule}
                          scheduledDate={scheduleDate}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ height: '100%' }}>
                        <EditTimeStart
                          retrieveStartTime={this.retrieveStartTime}
                          editSchedule={editSchedule}
                          timeWindowStart={timeWindowStart}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ height: '100%' }}>
                        <EditTimeEnd
                          retrieveEndTime={this.retrieveEndTime}
                          editSchedule={editSchedule}
                          timeWindowEnd={timeWindowEnd}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid container direction='row' style={{ height: '100%' }}>

                  <Paper style={{ width: '100%', margin: '1%', display: 'inline-block' }}>
                    <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                      <Grid item xs={11}>
                        <Typography variant='h6' style={{ margin: '1%' }}>Work Order Items: ({workOrderItems.length})</Typography>
                      </Grid>
                      <Grid itemxs={1}>
                        <IconButton onClick={()=>this.handleOpenWorkOrderItems()}>
                          <AddIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='center' justify='center'>
                      <List style={{ maxHeight: '16rem', overflow: 'auto', display: 'flex', width: '100%' }}>

                          <Grid item xs={12} style={{ width: '100%' }}>
                            <Grid container direction='row' style={{ width: '100%' }}>
                              <Box style={{width: '100%'}} display={{ xs: 'block', sm: 'block', md: 'block', lg: 'contents', xl: 'contents' }}>

                                { workOrderItemsLoading ? <WorkOrderItemsLoading /> : workOrderItems.map((workOrderItem) => {
                                  return (
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{ width: '100%' }}>
                                      <EditWorkOrderItemsCard
                                        handleOpenWorkOrderItems={this.handleOpenWorkOrderItems}
                                        handleDeleteWorkOrderItem={this.handleDeleteWorkOrderItem}
                                        workOrderItem={workOrderItem}
                                      />
                                    </Grid>
                                  )
                                }) }
                          </Box>

                            </Grid>
                              
                          </Grid>
                      </List>
                    </Grid>
                  </Paper>

                </Grid>
              </Grid>
            </Grid>
            <Grid container direction='row'>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Grid container direction='row'>
                  <Paper style={{ width: '100%', margin: '1%', height: '40%' }}>
                    <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                      <Grid item xs={10}>
                        <Typography variant='h6' style={{ margin: '2%' }}>Estimates:</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        { saveEstimates ?
                          <IconButton onClick={() => this.saveEstimatesData()}>
                            <GetAppIcon />
                          </IconButton>
                          :
                          <IconButton onClick={() => this.setEditEstimates()}>
                            <EditIcon />
                          </IconButton>
                        }
                      </Grid>
                    </Grid>
                    <Grid container direction='row'>
                      <Grid item xs={4}>
                        <EditNotToExceed
                          retrieveWorkOrderNTE={this.retrieveWorkOrderNTE}
                          editEstimates={editEstimates} 
                          nte={nte}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <EditEstimate 
                          retrieveWorkOrderEstimate={this.retrieveWorkOrderEstimate}
                          editEstimates={editEstimates}
                          estimate={estimate}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <EditBid 
                          retrieveWorkOrderEstimateBid={this.retrieveWorkOrderEstimateBid}
                          editEstimates={editEstimates}
                          bidPrice={bidPrice}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper style={{ width: '100%', margin: '1%', height: '60%' }}>
                    <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                      <Grid item xs={10}>
                        <Typography variant='h6' style={{ margin: '2%' }}>Ext Invoices:</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        { saveInvoices ?
                          <IconButton onClick={() => this.saveInvoicesData()}>
                            <GetAppIcon />
                          </IconButton>
                          :
                          <IconButton onClick={() => this.setEditInvoices()}>
                            <EditIcon />
                          </IconButton>
                        } 
                      </Grid>
                    </Grid>
                    <Grid container direction='row'>
                      <Grid item xs={6}>
                        <EditContractorInvoiceNumber 
                          retrieveWorkOrderContractorInvoice={this.retrieveWorkOrderContractorInvoice}
                          contractorInvoice={contractorInvoice}
                          editInvoices={editInvoices}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <EditBluefinInvoiceNumber 
                          retrieveWorkOrderBluefinInvoice={this.retrieveWorkOrderBluefinInvoice}
                          bluefinInvoice={bfInvoice}
                          editInvoices={editInvoices}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction='row'>
                      <Grid item xs={6}>
                        <EditPurchaseOrderNumber 
                          retrieveWorkOrderPurchaseOrderNumber={this.retrieveWorkOrderPurchaseOrderNumber}
                          purchaseOrderNumber={poNumber}
                          editInvoices={editInvoices}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <EditExternalWONumber 
                          retrieveWorkOrderExternalWONumber={this.retrieveWorkOrderExternalWONumber}
                          externalWorkOrderNumber={externalNumber}
                          editInvoices={editInvoices}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Grid container direction='row' style={{ height: '100%' }}>
                  <Paper style={{ width: '100%', margin: '1%' }}>
                    <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                      <Grid item xs={12}>
                        <Typography variant='h6' style={{ margin: '2%' }}>Documents:</Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction='row'>
                      <Grid item xs={12}>
                        <EditDocScopeOfWork
                          retrieveScopeOfWorkDoc={this.retrieveScopeOfWorkDoc}
                          handleOpenDocument={this.handleOpenDocument}
                          scopeOfWorkUrl={scopeOfWorkUrl}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <EditDocBid
                          handleOpenDocument={this.handleOpenDocument}
                          retrieveBidDoc={this.retrieveBidDoc}
                          bidUrl={bidUrl}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <EditDocContractorInvoice
                          handleOpenDocument={this.handleOpenDocument}
                          retrieveContractorInvoiceDoc={this.retrieveContractorInvoiceDoc}
                          contractorInvoiceUrl={contractorInvoiceUrl}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <EditDocBluefinInvoice
                          handleOpenDocument={this.handleOpenDocument}
                          retrieveBluefinInvoiceDoc={this.retrieveBluefinInvoiceDoc}
                          bfInvoiceUrl={bfInvoiceUrl}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Grid container direction='row' style={{ height: '100%' }}>
                  <Paper style={{ width: '100%', margin: '1%' }}>
                    <Grid container direction='row'>
                      <Grid item xs={12}>
                        <EditContactsContainer
                          //Container 
                          setEditContractor={this.setEditContractor}
                          setEditSubmitter={this.setEditSubmitter}
                          setEditAssigned={this.setEditAssigned}
                          saveContractorData={this.saveContractorData}
                          saveSubmitterData={this.saveSubmitterData}
                          saveAssignedData={this.saveAssignedData}
                          saveContractor={saveContractor}
                          saveSubmitter={saveSubmitter}
                          saveAssigned={saveAssigned}
                          //Contractor
                          retrieveWorkOrderContractorName={this.retrieveWorkOrderContractorName}
                          retrieveWorkOrderContractorEmail={this.retrieveWorkOrderContractorEmail}
                          retrieveWorkOrderContractorPhone={this.retrieveWorkOrderContractorPhone}
                          contractorName={contractorName}
                          contractorEmail={contractorEmail}
                          contractorPhone={contractorPhone}
                          editContractor={editContractor}
                          //Submitter
                          retrieveWorkOrderSubmitterName={this.retrieveWorkOrderSubmitterName}
                          retrieveWorkOrderSubmitterEmail={this.retrieveWorkOrderSubmitterEmail}
                          retrieveWorkOrderSubmitterPhone={this.retrieveWorkOrderSubmitterPhone}
                          submitName={submitName}
                          submitEmail={submitEmail}
                          submitPhone={submitPhone}
                          editSubmitter={editSubmitter}
                          //Assigned
                          retrieveWorkOrderAssignedName={this.retrieveWorkOrderAssignedName}
                          retrieveWorkOrderAssignedEmail={this.retrieveWorkOrderAssignedEmail}
                          retrieveWorkOrderAssignedPhone={this.retrieveWorkOrderAssignedPhone}
                          assignedToName={assignedToName}
                          assignedToEmail={assignedToEmail}
                          assignedToPhone={assignedToPhone}
                          editAssigned={editAssigned}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9} sm={9} md={9} lg={3} xl={3} style={{ height: '100%', maxHeight: '43rem' }}>
            <Grid container direction='row' style={{ height: '100%' }}>
              <Paper style={{ height: '100%', width: '100%', margin: '1%' }}>
                <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                  <Grid item xs={12}>
                    <Typography variant='h6' style={{ margin: '2%' }}>Comments:</Typography>
                  </Grid>
                </Grid>
                <Grid container direction='row' style={{ height: '93%', display: 'inline-block' }}>
                  <Grid item xs={12}>
                    <EditWorkOrderComments
                      dispatchAddComment={dispatchAddComment}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EditWorkOrderCommentsList
                      comments={comments}
                      activeUser={activeUser}
                      dispatchUpdateComment={dispatchUpdateComment}
                      dispatchDeleteComment={dispatchDeleteComment}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>  
    )
  }
};

export default EditWorkOrderForm
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  IconButton,
  FormControl,
  Select,
  FormHelperText,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import UndoIcon from '@material-ui/icons/Undo';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '90%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function EditUrgency(props) {
  const [ edited, setEdited ] = useState(false);
  const [ urgencyTypeId, setUrgencyTypeId ] = useState(null);
  const [ text, setText ] = useState('');

  const classes = useStyles();
  const {
    retrieveWorkOrderUrgency,
    setSaveGeneral,
    editGeneral,
    urgencyType,
    urgencyList,
  } = props;

  const handleChange = (e) => {
    const id = e.target.value;
    setUrgencyTypeId(id);
  };

  const handleUndoChange = () => {
    const initialValue = urgencyType.UrgencyId;
    if (initialValue != urgencyTypeId) {
      setUrgencyTypeId(initialValue);
    } 
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if (urgencyType) {
      const id = urgencyType.UrgencyId;
      setUrgencyTypeId(id);
    }
  }, []);

  useEffect(() => {
    if (urgencyTypeId) {
      const selectedType = urgencyList.find(urgencyItem => urgencyItem.UrgencyId == urgencyTypeId);
      const text = selectedType.UrgencyName;
      setText(text);
    }
  }, [urgencyTypeId]);

  const prevTypeId = usePrevious(urgencyTypeId);
  useEffect(() => {
    const initialValue = urgencyType.UrgencyId;

    if (urgencyTypeId && prevTypeId) {
      if (urgencyTypeId != prevTypeId) {
        const selectedType = urgencyList.find(item => item.UrgencyId == urgencyTypeId);
        retrieveWorkOrderUrgency(selectedType);       
      }
    }

    if (urgencyTypeId != initialValue) {
      setEdited(true);
    } else {
      setEdited(false)
    }
  }, [urgencyTypeId]);

  useEffect(() => {
    if (!editGeneral) {
      setEdited(false);
    }
  }, [editGeneral]);

  return (
    <Grid container direction='row' style={{ height: '100%', width: '100%', minHeight: '4rem' }} alignItems='center'>
      <Grid item={10}>
        <FormControl variant="filled" className={classes.formControl}>
          <Select
            native
            disabled={!editGeneral}
            value={urgencyTypeId}
            onChange={handleChange}
            inputprops={{
              statusId: 'statusId',
              id: 'filled-status-native-simple',
            }}
          >
            { urgencyList.map((urgencyItem) => {
              const name = urgencyItem.UrgencyName;
              const value = urgencyItem.UrgencyId;
              return (
                <option value={value}>{name}</option>
              )
            }) }
          </Select>
          <FormHelperText style={{marginLeft: '0'}}>Urgency</FormHelperText>
        </FormControl>
      </Grid>
      { edited ?
        <Grid item xs={2} style={{ textAlign: 'center', width: '100%', marginBottom: '10%' }}>
          <IconButton size='small' color="default" aria-label="edit" onClick={() => handleUndoChange(true)}>
            <UndoIcon />
          </IconButton>
        </Grid>
      : null }
    </Grid>
  );
}

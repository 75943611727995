import React, { Component } from 'react';
import { 
  Typography,
  Grid,
} from '@material-ui/core';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ThemeDefault from '../../styles/theme-default';
import globalStyles from '../../styles/styles';

import {
    CartesianGrid,
    BarChart,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ResponsiveContainer,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const graphContainerStyle = {
    textAlign: 'center',
    fontFamily: 'Ebrima', 
}

const YAxisTickFormatter = (value) => {
    return `${new Intl.NumberFormat('en').format(value)} Years`;
};

const CustomTooltipContent = (props) => {
    const payload = props.payload;

    if (props.active) {
        const newPayload = payload; 

        if (payload) {
            payload.map((entry, i) => {
                const dataKey = entry.dataKey;
                return entry.formatter = (value) => YAxisTickFormatter(value);
            })    
        }

        return <DefaultTooltipContent {...props} payload={newPayload} />
    } else {
        return <DefaultTooltipContent {...props} payload={payload}/>
    }
};

class ERSLByTypeChart extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        
        
        const { ERSLByType } = this.props;
        console.log(ERSLByType);
        return (
            <div id='graph-container' style={ graphContainerStyle }>
              <Grid container direction='row' style={{ width: '100%', backgroundColor: 'rgb(6, 114, 186)' }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography style={{ color: 'white', fontFamily: 'Ebrima',  }} variant='h6'>Estimated Remaining Service Life by Material</Typography>
                </Grid>
              </Grid>                    
              <ResponsiveContainer width='96%' height='100%' aspect={12.0 / 5.0} >
                  <BarChart 
                      width={ 730 } 
                      height={ 250 } 
                      barGap= { 0 }
                      data={ ERSLByType }
                      style={{fontFamily: 'Ebrima', }}
                  >
                      <CartesianGrid strokeDasharray="3 3" />
                      <YAxis type='number' tickFormatter={YAxisTickFormatter}  textAnchor="end"/>
                      <XAxis type='category' dataKey="type" />
                      <Tooltip style={{fontFamily: 'Ebrima',}} content={ <CustomTooltipContent style={{fontFamily: 'Ebrima', }} props={ graphContainerStyle } /> } />
                      <Legend />
                      <Bar barSize={20} dataKey="reactiveAvg" name="Reactive ERSL" fill="#F47B3C" stackId="ERSL" style={{fontFamily: 'Ebrima', }}  />
                      <Bar barSize={20} dataKey="proactiveAvg" name="Proactive ERSL" fill="#00C2F3" stackId="ERSL" style={{fontFamily: 'Ebrima', }} />
                  </BarChart>
              </ResponsiveContainer>
            </div>
        );
    }
}

export default ERSLByTypeChart

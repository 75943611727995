import React, { useState, useEffect } from 'react';
import PropertySectionCards from './PropertySectionCards';
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Grid,
  Paper,
  List,
  Typography,
  IconButton,
  Collapse,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    fontFamily: 'Ebrima',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    fontFamily: 'Ebrima',
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    fontFamily: 'Ebrima',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
    fontFamily: 'Ebrima',
  },
}));

export default function PropertySections(props){
  const [sections, setSections] = useState([]);
  const [propertyBounds, setPropertyBounds] = useState({});
  const [addressCoordinants, setAddressCoordinants] = useState({});
  const [expanded, setExpanded] = React.useState(false);
  const { 
    selectedProperty, 
    selectedResult,
    property, 
  } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const createBounds = (sections) => {
    const bounds = new google.maps.LatLngBounds();

    sections.map((section, i) => {
      const extPerimeters = section.ExteriorPerimeter;
      extPerimeters.map((extPerimeter, i) => {
        const perimetersPoints = extPerimeter.points;
        perimetersPoints.map((perimeterPoint, i) => {
          const lat = parseFloat(perimeterPoint.Lat);
          const lng = parseFloat(perimeterPoint.Lng);
          if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
            if (lat != 0 && lng != 0) {
              const latLng = { lat, lng };
              bounds.extend(latLng);
            }
          }
        })
      })
    })
    return bounds;
  };
  
  useEffect(() => {
    if (selectedProperty) {
      const propertySections = selectedProperty.Sections;
      const sortedPropertySections = [...propertySections.sort((a, b) => a.Name.localeCompare(b.Name))];
      setSections(sortedPropertySections);
      const bounds = createBounds(sortedPropertySections);
      setPropertyBounds(bounds);
    }
  }, [selectedProperty]);

  useEffect(() => {
    const addressCoordinants = {
      lat: parseFloat(selectedResult.Lat),
      lng: parseFloat(selectedResult.Lng),
    };

    if (!isNaN(addressCoordinants.lat) && !isNaN(addressCoordinants.lng)) {
      setAddressCoordinants(addressCoordinants);
    }
  }, [selectedResult]);

  const classes = useStyles();

  return(
    <Paper square style={{ width: '100%', marginBottom: '5%', marginTop: '3%', boxShadow: 'none', backgroundColor: 'rgb(233, 233, 233)' }}>
      <Grid container direction={'row'} alignItems={'flex-end'} justify={'flex-start'}>
        <Grid direction={'row'} style={{ display: 'flex'}}>
          <Typography variant='h6' style={{fontFamily: 'Ebrima',}}> Property Sections <span style={{ color: 'rgba(0, 0, 0, 0.26)', fontFamily: 'Ebrima', }}>({sections.length})</span></Typography>
          <IconButton
            size={'small'}  
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            disabled={sections.length == 0}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} style={{ height: '90%' }}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List style={{ width: '100%', overflowY: 'auto', backgroundColor: 'white' }}>
              { sections ? sections.map((section, i) => 
                <PropertySectionCards 
                      i={i}
                      key={'sectionCard' + i }
                  section={ section } 
                  propertyBounds={ propertyBounds }
                  addressCoordinants={ addressCoordinants }
                />
              ) : null }
            </List>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  )
}


import React from 'react';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import ProjectTreeNode from './SubComponents/ProjectTreeNode';
import useStyles from '../../../styles/ProjectHierarchyStyle.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function InteractiveList({
  dispatch,
  rootProjects,
  vm,
  navRoutes,
  isLoadingProjects,
  properties,
  phaseTemplatesFilter,
  phaseTemplateItems,
}) {
  const [phaseItemsFilters, setPhaseItemsFilters] = React.useState([]);
  const classes = useStyles();
  const [phaseItem, setPhaseItem] = React.useState([]);
  const [name, setName] = React.useState('');
  const [phaseTemplateFilterValue, setPhaseTemplateFilter] = React.useState([]);
  React.useEffect(() => {
    if (phaseTemplateItems && phaseTemplateItems.length > 0) {
      setPhaseItemsFilters(phaseTemplateItems.map((x) => x.PhaseItem));
    }
  }, [phaseTemplateItems]);

  const handleAddProject = () => {
    window.location.replace(navRoutes.find((x) => x.Title == 'AddProject').Route.RedirectRoot);
  };

  const handleEditProject = (id, level) => {
    return () => {
      if (level > 0) {
        window.location.replace(
          navRoutes.find((x) => x.Title == 'AddProject').Route.RedirectRoot + '/' + id + '?child'
        );
      } else {
        window.location.replace(
          navRoutes.find((x) => x.Title == 'AddProject').Route.RedirectRoot + '/' + id
        );
      }
    };
  };

  const handleChangePhaseTemplateFilter = (event, obj) => {
    setPhaseTemplateFilter(obj || []);
    if (obj && 'PhaseTemplateId' in obj) {
      getPhaseTemplateItems(obj.PhaseTemplateId);
    } else {
      phaseTemplateItems = [];
    }
  };

  const handleFilter = () => {
    isLoadingProjects = true;
    dispatch({
      getProjectsTreeFiltered: {
        PhaseTemplateId:
          phaseTemplateFilterValue && 'PhaseTemplateId' in phaseTemplateFilterValue
            ? phaseTemplateFilterValue.PhaseTemplateId
            : -1,
        PhaseItemId: phaseItem && 'PhaseItemId' in phaseItem ? phaseItem.PhaseItemId : -1,
        Title: name.length > 0 ? name : '',
      },
    });
  };

  const handleClearFilter = () => {
    setPhaseTemplateFilter([]);
    setPhaseItem([]);
    setName('');
    dispatch({
      getProjectsTreeFiltered: {
        PhaseTemplateId: -1,
        PhaseItemId: -1,
        Title: '',
      },
    });
  };

  const handleChangePhaseFilter = (event, obj) => {
    setPhaseItem(obj || []);
  };

  const handleChangeName = (event, obj) => {
    setName(event.target.value || '');
  };

  function getPhaseTemplateItems(phaseTemplateId) {
    dispatch({
      getPhaseTemplateItem: {
        PhaseTemplateId: phaseTemplateId,
      },
    });
  }

  if (!isLoadingProjects) {
    return (
      <div className={classes.wrapper}>
        <div>
          <Autocomplete
            className={classes.autocompleteFilters}
            id="template-filter"
            onChange={handleChangePhaseTemplateFilter}
            value={phaseTemplateFilterValue}
            options={phaseTemplatesFilter || []}
            getOptionLabel={(option) => option.Name || ''}
            style={{ width: 300 }}
            renderInput={(params) =>  <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}} className={classes.autocompleteTextboxFilters} {...params} label="Template" variant="outlined" />}
          />
          <Autocomplete
            className={classes.autocompleteFilters}
            id="phase-filter"
            onChange={handleChangePhaseFilter}
            value={phaseItem}
            options={phaseItemsFilters || []}
            getOptionLabel={(option) => option.Name || ''}
            style={{ width: 300 }}
            renderInput={(params) =>  <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}} className={classes.autocompleteTextboxFilters} {...params} label="Phase" variant="outlined" />}
          />
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            className={classes.autocompleteFilters}
            onChange={handleChangeName}
            value={name}
            label="Name"
            variant="outlined"
          />
          <div className={classes.buttonsFilters}>
            <Button
              style={{ marginRight: '10px' }}
              variant="contained"
              color="default"
              endIcon={<Icon>search</Icon>}
              onClick={handleFilter}
            >
              Search
            </Button>
            <Button
              variant="contained"
              color="default"
              endIcon={<Icon>clear</Icon>}
              onClick={handleClearFilter}
            >
              Clear filters
            </Button>
          </div>
          <Button
            className={classes.addbutton}
            variant="contained"
            color="primary"
            endIcon={<Icon>add</Icon>}
            onClick={handleAddProject}
          >
            Project
          </Button>
        </div>
        <div className={classes.tableWrapper}>
          <div className={classes.tableHeader}>
            <div className={`${classes.columnTitle} ${classes.columnTitleMain}`}>Name</div>
            <div className={classes.columnTitle}>Status</div>
            <div className={classes.columnTitle}>% Complete</div>
            <div className={classes.columnTitle}>Start Date</div>
            <div className={classes.columnTitle}>End Date</div>
            <div className={classes.columnTitle}>Project Manager</div>
          </div>
          <div className={classes.tableBody}>
            {rootProjects &&
              rootProjects.map((projectNode, index) => {
                return (
                  <ProjectTreeNode
                    key={index}
                    dispatch={dispatch}
                    projectNode={projectNode}
                    handleEditProject={handleEditProject}
                    showButtons={true}
                    level={0}
                    properties={properties}
                    phaseTemplateId={
                      phaseTemplateFilterValue && 'PhaseTemplateId' in phaseTemplateFilterValue
                        ? phaseTemplateFilterValue.PhaseTemplateId
                        : -1
                    }
                    phaseItemId={
                      phaseItem && 'PhaseItemId' in phaseItem ? phaseItem.PhaseItemId : -1
                    }
                    name={name.length > 0 ? name : ''}
                  />
                );
              })}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
}

import React, { useState, useEffect } from 'react';
import Property from '../../../Property/Property';
import {
  Modal,
  Grid,
  Paper,
  Button,
  Typography,
  Avatar,
  IconButton,
} from '@material-ui/core';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export default function PropertiesDetailModal(props) {
  const [name, setName ] = useState('');
  const {
    handleDispatchReport,
    handleOpenDetailModal,
    handleEditExecutiveSummary,
    handleDispatchGetRCS,
    openPropertyDetailModal,
    selectedResult,
    property,
    loading,
    userRank,
    materialType,
    mobile,
    reportLoading,
    sowReportUrl,
    carReportUrl,
    sowReportLoading,
    carReportLoading,
  } = props;

  const handleModalBlur = ({nativeEvent}) => {
    const targetId = nativeEvent.target.id;
    if (targetId == 'modal-container') {
      handleOpenDetailModal();
    }
  };

  const handleCloseModal = () => {
    handleOpenDetailModal();
  };

  useEffect(() => {
    if (selectedResult) {
      const { Title } = selectedResult;
      setName(Title);
    }
  }, [selectedResult]);

  return (
    <Modal open={openPropertyDetailModal}>
      <Grid id={'modal-container'} alignItems='center' justify='center' style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} onClick={handleModalBlur}>
        <Grid item xs={12} style={{ position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}>
          <Grid container direction={'row'} justify={'center'} alignItems={'center'} style={{ height: '100%', width: '100%' }}>
            <Grid item xs={12} style={{ width: '100%', height: '100%' }}>
                
                <Grid id='modal-content-row' container direction='row' alignItems='stretch' justify='space-between' style={{ height: '100%', width: '100%', display: 'table' }}>
                 
                      <Grid id='header-item-1' item xs={12} style={{ display: 'table-header-group', top: '0', left: '0' }}>
                        <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA', width: '100%', textAlign: 'center' }}>
                          <Grid item xs={12}>
                            <Grid container direction='row' alignItems='center' justify='center'>
                              
                              <Grid item xs={12}>
                                <Grid container direction='row' alignItems='center' justify='space-between'>
                                  <Grid item xs={1} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                                    <Avatar style={{ backgroundColor: 'gainsboro', margin: '2%' }}>
                                      <LocationCityIcon />
                                    </Avatar>
                                    <Typography variant='h6' style={{ margin: '4%', fontFamily:'Ebrima', }}>Property</Typography>
                                  </Grid>
                                  <Grid item xs={1} style={{ textAlign: 'right', color: 'white', display: 'contents' }}>
                                    <IconButton onClick={() => handleCloseModal()}>
                                      <HighlightOffIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>

                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ height: '100%' }}>
                        <Property
                          handleDispatchReport={handleDispatchReport}
                          handleModalBlur={handleModalBlur}
                          handleEditExecutiveSummary={handleEditExecutiveSummary}
                          handleDispatchGetRCS={handleDispatchGetRCS}
                          selectedResult={selectedResult}
                          property={property}
                          loading={loading}
                          userRank={userRank}
                          materialType={materialType}
                          mobile={mobile}
                          reportLoading={reportLoading}
                          sowReportUrl={sowReportUrl}
                          carReportUrl={carReportUrl}
                          sowReportLoading={sowReportLoading}
                          carReportLoading={carReportLoading}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ display: 'table-footer-group' }}>
                        <Grid container direction='row' alignItems='center' justify='center'>
                          <Grid item xs={12}>
                            <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA', width: '100%', textAlign: 'center' }}>
                              <Grid item xs={2}>
                                <Button variant='contained' color='default' onClick={() => handleCloseModal()} style={{ backgroundColor: 'gainsboro', color: '#4C4C4C', boxShadow: 'none', fontFamily:'Ebrima', }}>Close</Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    
                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 30,
    paddingTop: '56.25%', // 16:9
  },
});

export default function PropertiesMapCard(props) {
  const classes = useStyles();
  const { id, title, address, imageUrl } = props;

  return (
    <Card className={classes.card}>
      <CardHeader 
        title={<span id={`map-card-title-${id}`}>{title}</span>}
        subheader={ address }
      />
      <CardMedia
        className={classes.media}
        image={ imageUrl }
      />
    </Card>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import Divider from 'material-ui/Divider';
import globalStyles from '../styles/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import withWidth, { LARGE, SMALL } from 'material-ui/utils/withWidth';

const BasePage = props => {

  const { title, navigation } = props;

    const styles = {papertitle: {
        border: 'solid 0px red',
        padding: 10,
        zIndex: 900,
        top: 108,
        right: '20px',
        left: props.sidebarOpen 
    }
};

const { breadcrumb } = globalStyles;

console.log('THIS SHOULD NOT BE PRINTING')


  return (
      <div>
        <div style={breadcrumb}>
          <Breadcrumbs 
            separator="›" 
            arial-label="Breadcrumb"
          >



          {/* <Link color="inherit" href={"/"}>{"Home"}</Link>
            { navigation ? 
            
              navigation.map((item, i) => {
                const { Title } = item;
                return (
                  <Link
                    key={i} 
                    color="inherit" 
                    href={"/" + Title}  
                    onClick={(e) => props.HandleNavLinkClick({e, item})}
                  >
                      {item.Title}
                  </Link>
                )
              })
            
            : null } */}
               <Typography color="textPrimary">{title}</Typography>
              </Breadcrumbs>
          </div>
          <Paper style={globalStyles.paper}>
              <div style={globalStyles.title}>{title}</div>
        {props.children}
        <div style={globalStyles.clear} />
      </Paper>
    </div>
  );
};

BasePage.propTypes = {
  title: PropTypes.string,
  navigation: PropTypes.array,
  children: PropTypes.element
};

export default BasePage;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '95%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddContractorSelect(props) {
  const classes = useStyles();
  const [ contractorId, setContractorId ] = useState(null);

  const handleChange = (e) => {
    const id = e.target.value;
    setContractorId(id);
  };


  // const [state, setState] = React.useState({
  //   contractor: '',
  //   name: 'hai',
  // });

  const { 
    retrieveContractorValue,
    contractorList, 
    category2Finished,
  } = props;

  // const handleChange = name => event => {
  //   setState({
  //     ...state,
  //     [name]: event.target.value,
  //   });
  // };

  useEffect(() => {
    if (contractorId) {
      const contractor = contractorList.find(contractor => contractor.UserID == contractorId);
      retrieveContractorValue(contractor);
    } else {
      retrieveContractorValue(null);
    }
  }, [contractorId]);

  return (
    <div>
      <FormControl disabled={ !category2Finished } variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-contractor-native-simple">Contractor</InputLabel>
        <Select
          native
          value={contractorId}
          onChange={handleChange}
          inputprops={{
            contractorId: 'contractorId',
            id: 'filled-contractor-native-simple',
          }}
        >
          <option value="" />
          { contractorList.map((contractor) => {
            const name = contractor.UserName;
            const value = contractor.UserID;
            return (
              <option value={ value }>{ name }</option>
            )
          }) }
        </Select>
      </FormControl>
    </div>
  );
}

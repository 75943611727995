import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import {
  Paper,
  Grid,
  Modal,
  Button,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';


export default function DocumentDeleteModal(props) {
  const [fileName, setFileName] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const {
    handleDeleteModalClose,
    handleDeleteDocument,
    documentDeleteModalOpen,
    deleteDocumentCompanyId,
    deleteDocumentId,
    deleteDocumentFile,
    identityId,
    token,
  } = props;

  const handleModalBlur = ({ nativeEvent }) => {
    const targetId = nativeEvent.target.id;
    if (targetId == 'delete-container') {
      handleDeleteModalClose();
    }
  };

  const deleteDocument = () => {
    AWS.config.region = 'us-west-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token
      }
    });

    const s3 = new AWS.S3;

    s3.config.region = 'us-west-2';
    s3.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token
      }
    });

    var params = {
      Bucket: 'bluefinfiles',
      Key: deleteDocumentFile.FileName,
    };

    try {
      Promise.resolve(s3.headObject(params))
      console.log("File Found in S3")
      try {
        Promise.resolve(s3.deleteObject(params))
        console.log("file deleted Successfully" + buildingName);
        handleDeleteDocument(deleteDocumentId, buildingName);
      }
      catch (err) {
        console.log("ERROR in file Deleting : " + JSON.stringify(err))
      }
    } catch (err) {
      console.log("File not Found ERROR : " + err.code)
    }
  };

  useEffect(() => {
    if (deleteDocumentFile) {
      const { FileName, BuildingName } = deleteDocumentFile;
      setFileName(FileName)
      setBuildingName(BuildingName)
    }    
  }, [deleteDocumentFile]);

  return (
    <Modal open={documentDeleteModalOpen}>
      <div id={'delete-container'} style={{ width: '100%', height: '100%' }} onClick={handleModalBlur}>
        <Grid container>
          <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
            <Grid item xs={10}>
              <Paper id='paper' style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', height: '15rem', width: '22rem', backgroundColor: 'rgb(246, 243, 235)', textAlign: 'center' }}>
                <Grid container direction='row' style={{ width: '100%', backgroundColor: '#3f51b5', height: '15%' }}>
                  <Grid item style={{margin: '1%', display: 'inline-flex'}}>
                    <DeleteIcon style={{color: 'white'}} />
                    <Typography style={{color: 'white'}}>Delete Document</Typography>
                  </Grid>
                </Grid>
                
                <Grid container direction='row'>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1'>Are you sure you want to delete </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' style={{color: 'darkGrey'}}>{ fileName }</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1'>from</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' style={{ color: 'darkGrey' }}>{buildingName} <span style={{ color: 'black' }}>?</span></Typography>
                  </Grid>
                </Grid>
                <Grid container direction='row' style={{position: 'absolute', bottom: '5%', bottom: '5%'}}>
                  <Grid item xs={12}>
                    <Button onClick={()=>deleteDocument()} variant='contained' color='secondary'>
                      <DeleteIcon />
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
} 

// '<span style={{ textDecoration: 'underline' }}>{fileName}</span>' from { buildingName }?

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  const { 
    saveOrCancelWorkOrderItems,
    handleSaveAndCancelWorkOrderItem,
    handleCancelSaveWorkOrderItemModal,
   } = props;

   const handleSelection = (save) => {
    handleSaveAndCancelWorkOrderItem(save);
   };

  return (      
      <Dialog
        open={saveOrCancelWorkOrderItems}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Do you want to save your changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved changes in this Work Order item, would you like to save and continue this later?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button color="primary" onClick={() => handleCancelSaveWorkOrderItemModal()}>
          Cancel
          </Button>
          <Button color="primary" onClick={()=>handleSelection(false)}>
            Discard
          </Button>
          <Button color="primary" onClick={()=>handleSelection(true)} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  );
}

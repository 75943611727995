import React, { Component } from 'react';
import WarrantyStartDatePicker from './Subcomponents/WarrantyStartDatePicker';
import DocumentWarrantySectionList from './Subcomponents/DocumentWarrantySectionList';
import WarrantyDurationSelect from './Subcomponents/WarrantyDurationSelect';
import WarrantyContractorInput from './Subcomponents/WarrantyContractorInput';
import WarrantyManufacturerInput from './Subcomponents/WarrantyManufacturerInput';
import {
  Grid,
} from '@material-ui/core';

class DocumentsWarranty extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const {
      handleSectionSelect,
      handleStartDateSelect,
      handleDurationSelect,
      handleContractorSelect,
      handleManufacturerSelect,
      document,
      propertySections,
    } = this.props;

    return (
      <Grid container alignItems='center' justify='center' style={{ height: '100%', width: '99%', padding: '1%' }}>
        <Grid item xs={12}>
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <WarrantyStartDatePicker
                handleStartDateSelect={handleStartDateSelect}
                document={document}
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <WarrantyDurationSelect
                handleDurationSelect={handleDurationSelect}
                document={document}
              />
            </Grid>
          </Grid>
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <WarrantyContractorInput
                handleContractorSelect={handleContractorSelect}
                document={document}
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
              <WarrantyManufacturerInput
                handleManufacturerSelect={handleManufacturerSelect}
                document={document}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ maxHeight: '10rem', overflow: 'auto' }}>
            <DocumentWarrantySectionList
              handleSectionSelect={handleSectionSelect}
              document={document}
              propertySections={propertySections}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
};

export default DocumentsWarranty

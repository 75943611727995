

// export default function CustomizedInputBase(props) {
//   const [text, setText] = useState('');
//   const { handleSearch, handleMapToggle, mobile, mapViewSelected } = props;
//   const style = PropertySearchBarStyle();
//   const { 
//     root, 
//     iconButton, 
//     input, 
//     divider 
//   } =  style;

//   useEffect(() => {
//     handleSearch(text);
//   }, [text])

//   return (
//     <Paper className={ root }>
     
//       <IconButton className={iconButton} aria-label="search">
//         <SearchIcon />
//       </IconButton>
      
//       <InputBase
//         // variant={ 'outlined' }
//         className={ input }
//         placeholder="Search Properties"
//         onKeyPress={ e => (e.key === 'Enter' ? handleSearch(text) : null) }
//         onChange={ e => setText(e.target.value) }
//         value={ text }
//         inputprops={{
//           shrink: true,
//         }}
//       />
//       { mobile ? 
//           mapViewSelected?
//             <IconButton onClick={() => handleMapToggle()} className={ iconButton } aria- label="search">
//               <ListIcon /> 
//             </IconButton>
//             :
//             <IconButton onClick={() => handleMapToggle()} className={iconButton} aria-label="Map View">
//               <RoomIcon />
//             </IconButton>
//         : 
//           null 
//         }
//       <Divider className={ divider } orientation="vertical" />
//     </Paper>
//   );
// }




import React, { useState, useEffect } from 'react';
import {  
  Paper,
  IconButton,
  Input,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import RoomIcon from '@material-ui/icons/Room';
import PropertySearchBarStyle from './Styles/PropertySearchBarStyle';
import ListIcon from '@material-ui/icons/List';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  margin: {
    width: '95%',
    margin: theme.spacing(1),
    fontFamily: 'Ebrima',
  },
}));

export default function CustomizedInputBase(props) {
  const [text, setText] = useState('');
  const { handleSearch, handleMapToggle, mobile, mapViewSelected } = props;
  const style = PropertySearchBarStyle();
  const { 
    iconButton, 
  } =  style;
  const classes = useStyles();

  useEffect(() => {
    handleSearch(text);
  }, [text])

  return (
    <Paper square style={{ boxShadow: 'none' }}>
      <FormControl className={classes.margin}>
        <Input
          id="input-with-icon-adornment"
          placeholder="Search Properties"
          onKeyPress={ e => (e.key === 'Enter' ? handleSearch(text) : null) }
          onChange={ e => setText(e.target.value) }
          value={ text }
          style={{ width: '100%',   fontFamily: 'Ebrima', }}
          inputprops={{
            shrink: true,
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            mobile ?
              mapViewSelected ?
                <IconButton onClick={() => handleMapToggle()} className={iconButton} aria- label="search">
                  <ListIcon />
                </IconButton>
                :
                <IconButton onClick={() => handleMapToggle()} className={iconButton} aria-label="Map View">
                  <RoomIcon />
                </IconButton>
              :
              null
          }
        />
      </FormControl>
    </Paper>
  );
}

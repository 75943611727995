import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  IconButton,
} from '@material-ui/core';
import UndoIcon from '@material-ui/icons/Undo';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    margin: theme.spacing(1),
    marginTop: '0',
    marginBottom: '0'
  },
  textField: {
    width: '95%',
  }
}));

export default function EditAssignedName(props) {
  const [ edited, setEdited ] = useState(false);
  const [ disabled, setDisabled ] = useState(true);
  const [ phone, setPhone ] = useState(null);
  const classes = useStyles();

  const {
    retrieveWorkOrderAssignedPhone,
    editAssigned,
    assignedToPhone,
  } = props;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const handleChange = (e) => {
    const number = e.target.value;
    setPhone(number);
  };

  const handleUndoChange = () => {
    setPhone(assignedToPhone);
  };

  useEffect(() => {
    setPhone(assignedToPhone);
  }, []);

  useEffect(() => {
    if (editAssigned) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [editAssigned]);

  const prevPhone = usePrevious(phone);
  useEffect(() => {
    if (phone && prevPhone) {
      if (name != prevPhone) {
        retrieveWorkOrderAssignedPhone(phone);
      }

      if (phone != assignedToPhone) {
        setEdited(true);
      } else {
        setEdited(false);
      }
    }
  }, [phone]);

  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={10}>
        <form className={classes.container} noValidate autoComplete="off">
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            disabled={disabled}
            value={phone}
            className={classes.textField}
            onChange={handleChange}
            id="standard-basic"
            className={classes.textField}
            margin="normal"
            helperText='Assigned Phone'
          />
        </form>
      </Grid>
      <Grid xs={2}>
        { edited && !disabled ?
          <IconButton onClick={handleUndoChange}>
            <UndoIcon />
          </IconButton>
        : null }
      </Grid>
    </Grid>
  );
}


import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes';
import 'flexboxgrid/css/flexboxgrid.css';
import './styles/app.css';
import theme from './theme';

import { ThemeProvider } from '@material-ui/core/styles';

ReactDOM.render(<ThemeProvider theme={theme}><App /></ThemeProvider>, document.getElementById('App'));


import React, { useState, useEffect } from 'react';
import {
  ListItem,
  Paper,
  Button,
  Typography,
} from '@material-ui/core';

const paperStyle = { 
  width: '-webkit-fill-available', 
  boxShadow: 'none',
  fontFamily: 'Ebrima',
};

const typographyPagesStyle = { 
  fontSize: '12px',
  fontFamily: 'Ebrima',
};

export default function ApplicationSearchPagination(props) {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [buttonCount, setButtonCount] = useState([]);
  const [buttonBegin, setButtonBegin] = useState(1);
  const [buttonEnd, setButtonEnd] = useState(4);

  const { pages, dispatchPaginationSelect } = props;

  const createPagination = () => {
    const numberOfPages = pages.length;
    const buttonArr = [];

    for (let i = buttonBegin; i < buttonEnd; i++) {
      buttonArr.push(i);
    }
    setNumberOfPages(numberOfPages);
    setButtonCount(buttonArr);
  };

  const paginationSelect = (pageNumber) => {
    setSelectedPage(pageNumber);
    dispatchPaginationSelect(pageNumber);
  }

  const incrementPagination = () => {
    if (buttonEnd < numberOfPages) {
      setButtonBegin(buttonBegin + 1);
      setButtonEnd(buttonEnd + 1);
    }
  };

  const decrementPagination = () => {
    if (buttonBegin > 1) {
      setButtonBegin(buttonBegin - 1);
      setButtonEnd(buttonEnd - 1);
    }
  };

  useEffect(() => {
    createPagination();
  }, [buttonBegin]);
  
  return (
    <ListItem>
      <Paper style={ paperStyle }>
        <Button onClick={ ()=>decrementPagination() }>-</Button>
          { buttonCount.map(pageNumber => 
            <Button 
              value={ pageNumber } 
              style={ selectedPage == pageNumber ? { textDecoration: 'underline', fontFamily:'Ebrima', } : {fontFamily:'Ebrima'}}
              onClick={ () => paginationSelect(pageNumber) }>
              { pageNumber }
            </Button>) 
          }
        <Button onClick={ ()=>incrementPagination() }>+</Button>
        <Typography align={'center'} style={ typographyPagesStyle }>{ pages.length } pages</Typography>
      </Paper>
    </ListItem>
  );
}

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '90%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

export default function AddWorkOrderTypeSelect(props) {
  const classes = useStyles();
  const [workOrderType, setWorkOrderType] = useState(null);

  const { 
    retrieveSelectedWoType,
    selectedProperty, 
    woTypeList 
  } = props;

  const handleChange = e => {
    const id = e.target.value;
    setWorkOrderType(id);
  };

  useEffect(() => {
    if (workOrderType) {
      const selectedWoType = woTypeList.find(wo => wo.WotypeId == workOrderType);
      retrieveSelectedWoType(selectedWoType);
    } else {
      retrieveSelectedWoType(null);
    }
  }, [workOrderType]);

  useEffect(() => {
    //not functioning
    // if (!selectedProperty) {
    //   setWorkOrderType(null);
    // }
  }, [selectedProperty]);

  return (
    <FormControl disabled={ !selectedProperty } variant="filled" className={classes.formControl}>
      <InputLabel htmlFor="filled-wo-native-simple">Work Order Type</InputLabel>
      <Select
        native
        value={workOrderType}
        onChange={handleChange}
        inputprops={{
          workOrderType: 'workOrderType',
          id: 'filled-wo-native-simple',
        }}
      >
        <option value={ null } />
        { woTypeList.map((type) => {
          const name = type.Wotype1;
          const value = type.WotypeId;
          return (
            <option value={value}>{ name }</option>
          )
        }) }
      </Select>
    </FormControl>
  );
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const headerBorderStyle = '2px solid #e8e8e8';
const columnMinWidth = '110px';
const mainColumnWidth = '500px';
const mainColumnMinWidth = '200px';

const useStyles = makeStyles((theme) => ({
  wrapperSelect: {
    padding: '10px',
    border: '1px solid',
  },
  selectAll: {
    position: 'absolute',
    margin: '-37px 0 0 0px',
  },
  loading: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-webkit-translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-moz-translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-ms-translate(-50%, -50%)',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '15px',
    background: 'white',
  },
  buttonsFilters: {
    float: 'left',
  },
  autocompleteFilters: {
    float: 'left',
    paddingRight: '9px',
    marginBottom: '20px',
    '& input': {
      padding: '9px 4px',
    },
    '& label': {
      transform: 'translate(14px,10px) scale(1)',
    }
  },
  autocompleteTextboxFilters: {
    '& input': {
      padding: '0 4px !important',
    },
    '& label': {
      transform: 'translate(14px,10px) scale(1)',
    }
  },
  tableHeader: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '60px',
    borderTop: headerBorderStyle,
    borderBottom: headerBorderStyle,
    background: '#f8f8f8',
    borderRight: headerBorderStyle,
    borderLeft: headerBorderStyle,
  },
  columnTitle: {
    flex: '1',
    display: 'flex',
    fontSize: '16px',
    minWidth: columnMinWidth,
    fontWeight: 600,
    color: '#546E7A',
    alignItems: 'center',
    padding: '0 10px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    borderRight: headerBorderStyle,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  columnTitleMain: {
    flex: `0 ${mainColumnWidth}`,
    maxWidth: mainColumnWidth,
    minWidth: mainColumnMinWidth,
  },
  tableBody: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    borderRight: headerBorderStyle,
    borderLeft: headerBorderStyle,
  },
  addbutton: {
    marginLeft: 'auto',
    float: 'right',
  },
}));

export const useTreeNodeStyles = makeStyles((theme) => ({
  treeNodeWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  treeNodeButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '5px 10px',
  },
  projectOptions: {
    display: 'flex',
    marginLeft: 'auto',
    zIndex: 10,
  },
  optionsMenu: {
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
  },
  dots: {
    width: '25px',
    height: '25px',
  },
  treeNodeMenuOption: {
    backgroundColor: 'white',
  },
  treeNodeButton: {
    margin: '5px',
  },
  treeNode: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  treeNodeHeader: {
    display: 'flex',
    height: '40px',
  },
  treeNodeColumn: {
    flex: '1',
    display: 'flex',
    fontSize: '12px',
    fontWeight: 400,
    color: '#546E7A',
    minWidth: columnMinWidth,
    alignItems: 'center',
    padding: '0 10px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    border: headerBorderStyle,
    borderLeft: 'none',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  treeNodeColumnTitle: {
    flex: `0 ${mainColumnWidth}`,
    maxWidth: mainColumnWidth,
    minWidth: mainColumnMinWidth,
  },
  projectName: {
    display: 'flex',
    fontWeight: 600,
    cursor: 'pointer',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  caretIcon: {
    width: '25px',
    height: '25px',
  },
  treeNodeBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  hide: {
    display: 'none',
  },
}));

export default useStyles;

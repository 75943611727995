//Languages
import React from 'react';
import dotnetify from 'dotnetify';

//Libraries
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

//Components
import auth from '../../auth';
import FinancialForecastGraph from '../RoofDashboard/FinancialForecastGraph';
import AreaByLocationGraph from '../RoofDashboard/AreaByLocationGraph';
import AssetsByLocationGraph from '../RoofDashboard/AssetsByLocationGraph';
import CorrectiveMaintenanceGraph from '../RoofDashboard/CorrectiveMaintenanceGraph';
import RemainingServiceGraph from '../RoofDashboard/RemainingServiceGraph';
import EmergencyRepairsGraph from '../RoofDashboard/EmergencyRepairsGraph';
import EmergencyCostsGraph from '../RoofDashboard/EmergencyRepairsCostGraph';
import RoofDashboardHeader from '../RoofDashboard/RoofDashboardHeader';
import GlobalStyles from '../../styles/styles';

//Styles
const gridItemStyle = {
  padding: '1%',
}

const roofDashboardHeaderStyle = {
  padding: '0% 1% 1% 1%', width: '-webkit-fill-available'
}

const masterGridStyle = {
  overflow: 'hidden', 
  paddingTop: '1%'
}

const enclosingDivStyle = { 
  padding: '1%', 
}

const pageHeaderStyle = { 
  marginLeft: '10px', 
  marginBottom: '10px' 
};

class PavementDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.vm = dotnetify.react.connect('PavementDashboard', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        console.log(ex);
          auth.signOut();
      }
    });

    this.vm.onRouteEnter = (path, template) => (template.Target = 'Content');
    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
        numberOfForcastResults: 10,
        Projections: [],
        AreaByLocationData: [],
        AssetsByLocationData: [],
        EmergencyCostsData: [],
        CorrectiveMaintenanceData: [],
        EmergencyRepairsData: [],
        RemainingServiceData: [],
    }
  }

  handleNumberOfResults = (event, data) => {
    const { props } = data;
    const { value } = props;

    this.setState({
      numberOfForcastResults: value,
    })
  }

  componentWillUnmount() {
    console.log('unmounting PAVEMENT DASHBOARD')
    this.vm.$destroy();
  }

  render() {
    
    const { 
      Projections, 
      AreaByLocationData,
      AssetsByLocationData, 
      EmergencyCostsData, 
      CorrectiveMaintenanceData, 
      EmergencyRepairsData, 
      RemainingServiceData 
    } = this.state;

    return (
      <div style={ enclosingDivStyle }>
        <div style={ pageHeaderStyle }>
          <h3 style={ GlobalStyles.navigation }>Pavement Dashboard</h3>
        </div>
        <Grid id='master-grid' container spacing={3} style={ masterGridStyle }>
          <Grid justify='center' item style={ roofDashboardHeaderStyle }>
            <RoofDashboardHeader />
          </Grid>
          <Grid style={gridItemStyle} item xs={12} sm={12} md={12} lg={12}>
            <Paper>
              <FinancialForecastGraph
                financialForcastData={ Projections }
              />
            </Paper>
          </Grid>
          <Grid style={gridItemStyle} item xs={12} sm={12} md={6} lg={6}>
            <Paper>
              <AreaByLocationGraph
                areaByLocationData={ AreaByLocationData }
              />
            </Paper>
          </Grid>
          <Grid style={gridItemStyle} item xs={12} sm={12} md={6} lg={6}>
            <Paper>
              <AssetsByLocationGraph
                assetsByLocationData={ AssetsByLocationData }
              />
            </Paper>
          </Grid>
          <Grid style={gridItemStyle} item xs={12} sm={12} md={6} lg={6}>
            <Paper>
              <CorrectiveMaintenanceGraph
                correctiveMaintenanceData={ CorrectiveMaintenanceData }
              />
            </Paper>
          </Grid>
          <Grid style={gridItemStyle} item xs={12} sm={12} md={6} lg={6}>
            <Paper>
              <RemainingServiceGraph
                remainingServiceData={ RemainingServiceData }
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default PavementDashboard;

import React, { useState, useEffect, useRef } from 'react';
import MoveBuildingsModalFromCard from './MoveBuildingsModalFromCard';
import MoveBuildingsModalToCard from './MoveBuildingsModalToCard';
import {
  Paper,
  Grid,
  Modal,
  Typography,
  Avatar,
  IconButton,
  Button,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ForwardIcon from '@material-ui/icons/Forward';

export default function MoveBuildingsSaveModal(props) {
  const {
    handleSaveMoveBuildingsDispatch,
    handleSaveMoveBuildingsCancel,

    toSelectedCompany,
    selectedCompany,
    selectedBuildings,
    selectedTags,

    saveSelected,
  } = props;

  return (
    <Modal id='modal' open={saveSelected}>
      <Grid container direction='row' justify='center' alignItems='center' style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', overflow: 'auto' }}>
        <Grid xs={12} sm={6}>
          <Paper id='paper'>
            <Grid container direction='row' style={{ height: '100%', display: 'table' }}>
              <Grid item xs={12} style={{ display: 'table-header-group', top: '0', left: '0', zIndex: '1' }}>
                <Grid container direction='row' alignItems='center' justify='space-between' style={{ backgroundColor: '#66BFFA', textAlign: 'center' }}>
                  <Grid item xs={1} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    <Avatar style={{ backgroundColor: 'gainsboro', margin: '2%' }}>
                      <InsertDriveFileIcon />
                    </Avatar>
                    <Typography variant='h6' style={{ margin: '4%', fontFamily:'Ebrima' }}>Move Buildings</Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right', color: 'white', display: 'contents' }}>
                    <IconButton onClick={()=>handleSaveMoveBuildingsCancel()}>
                      <HighlightOffIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ display: 'table-cell' }}>
                <Grid container direction='row' justify='center' alignItems='center'>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant='h6'>Are you sure you want to move </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction='row' justify='center' alignItems='center'>
                      <Grid item xs={5}>
                        <MoveBuildingsModalFromCard
                          toSelectedCompany={toSelectedCompany}
                          selectedCompany={selectedCompany}
                          selectedBuildings={selectedBuildings}
                          selectedTags={selectedTags}
                        />
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'center' }}>
                        <ForwardIcon fontSize='large' />
                      </Grid>
                      <Grid item xs={5}>
                        <MoveBuildingsModalToCard
                          toSelectedCompany={toSelectedCompany}
                          selectedCompany={selectedCompany}
                          selectedBuildings={selectedBuildings}
                          selectedTags={selectedTags}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant='caption' style={{ color: 'darkGrey' }}>*This action cannot be undone.</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ display: 'table-footer-group' }}>
                <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA' }}>
                  <Grid item xs={3} style={{ textAlign: 'center', padding: '4px' }}>
                    <Button style={{ backgroundColor: 'gainsboro' }} onClick={()=>handleSaveMoveBuildingsDispatch()}>Continue</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Modal>
  );
};
import React from 'react';
import dotnetify from 'dotnetify';
import PropTypes from 'prop-types';
import auth from '../../auth';
import ApplicationHeader from './ApplicationHeader/ApplicationHeader';
import ApplicationSidebar from './ApplicationSidebar/ApplicationSidebar';
import ApplicationContent from './ApplicationContent';
import ApplicationFeedbackModal from './ApplicationFeedback/ApplicationFeedbackModal';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Typography,
} from '@material-ui/core';
import withWidth, { LARGE, SMALL } from 'material-ui/utils/withWidth';

class ApplicationLayout extends React.Component {
  constructor(props) {
    super(props);

    // dotnetify.react.router.urlPath = null;
    dotnetify.react.router.notFound404Url = null;
    this.vm = dotnetify.react.connect('AppLayout', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: _ => {
        console.log("SIGNOUT");
        auth.signOut();
      }
    });

    this.vm.onRouteEnter = (path, template) => (template.Target = 'Content');
    this.routeTo = route => this.vm.$routeTo(route);
    this.layoutWrapperRef = React.createRef();

    this.state = {
      RoutingState: [],
      xPortalUserOrgUnits: [],
      Menus: [],
      Profile: {},
      sidebarOpen: true,
      topbarOpen: false,
      siteFilterOpen: false,
      profileOpen: false,
      searchActive: false, 
      searchCriteria: null,
      selectedRoute: null,
      componentTitle: 'Dashboard',
      selectedCompany: '',      
      selectedTags: [],
      selectedMaterial: '',
      mobile: null,
      feedbackOpen: false,
      LifeSafetyData: {},
      LifeSafetyProperties: [],
      // userName: null,
    };
  }

  componentDidMount = (prevProps, prevState) => {
    const { clientWidth } = this.layoutWrapperRef.current;
    const { 
      componentTitle,
    } = this.state;

    const pastTitle = localStorage.getItem('componentTitle');
    if (pastTitle != componentTitle) {
      this.setState({
        componentTitle: pastTitle,
      });
    }

    //handles auto toggle of sidebar on small screens
    if (clientWidth <= 1050) {
      this.setState({ 
        sidebarOpen: false, 
      });
    }
    
    //handles mobile on load
    if (clientWidth <= 600) {
      this.setState({
        mobile: true,
      })
    } else {
      this.setState({
        mobile: false,
      })
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { clientWidth } = this.layoutWrapperRef.current;
    const { componentTitle, mobile } = this.state;
    localStorage.setItem('componentTitle', componentTitle);

    // handles mobile on update
    if (!mobile) {
      if (clientWidth <= 600) {
        this.setState({
          mobile: true,
        })
      }
    } else {
      if (clientWidth >= 600) {
        this.setState({
          mobile: false,
        })
      }
    }

    //handles auto close of mobile menu
    const {
      selectedRoute,
      topbarOpen,
    } = this.state;
    const previousSelectedRoute = prevState.selectedRoute;

    if (topbarOpen) {
      if (selectedRoute) {
        if (previousSelectedRoute != selectedRoute) {
          this.handleTopbar();
        }
      }
    }
  };

  toggleSidebar = () => {
    const { sidebarOpen } = this.state;
    this.setState({
      sidebarOpen: !sidebarOpen,
    })
  };

  toggleTopbar = () => {
    const { topbarOpen } = this.state;
    this.setState({
      topbarOpen: !topbarOpen,
    })
  };

  handleTopbar = () => {
    const { sidebarOpen } = this.state;
    if (sidebarOpen) {
      this.toggleSidebar();
      this.toggleTopbar();
    } else {
      this.toggleTopbar();
    }
  };

  siteFilterSelect = () => {
    const { siteFilterOpen } = this.state;
    this.setState({
        siteFilterOpen: !siteFilterOpen,
    });
  }; 

  handleProfileClick = () => {
    const { profileOpen } = this.state;
    this.setState({
        profileOpen: !profileOpen,
    });
  }; 

  handleProfileOpen = () => {
    this.setState({
      profileOpen: true
    })
  };

  handleProfileClose = () => {
    this.setState({
      profileOpen: false
    });
  };

  handleOpenFeedBackModal = () => {
    this.setState({
      feedbackOpen: true,
    })
  };

  handleCloseFeedBackModal = () => {
    this.setState({
      feedbackOpen: false,
    })
  };

  handlePasswordChanged = (ischanged) => {
    const route = Menus[0].Route;
    this.setState({
      IsResetPassword: !ischanged
    }, this.vm.$routeTo(route))
  };

  handleProfileUpdate = (changes) => {
    const { FirstName, LastName, ProfilePhotoUrl } = changes;
    this.setState({
      FName: FirstName,
      LName: LastName,
      ProfilePhotoUrl: ProfilePhotoUrl,
    })
  };

  searchSelected = () => {
    const { searchActive } = this.state;
    this.setState({
      searchActive: !searchActive,
    })
  };

  selectRoute = (selectedRoute, title) => {
    console.log(selectedRoute, title);
    this.setState({
      selectedRoute,
      componentTitle: title,
    }, this.vm.$routeTo(selectedRoute))
  };

  handleLogOut = () => {
    this.vm.$exceptionHandler();
  };

  propertySelect = (event) => {
    console.log(event.target.value.Route);
    this.vm.$routeTo(event.target.value.Route);
  };

  componentWillUnmount() {
    this.vm.$destroy();
  };

  render() {
    const { 
      Menus, 
      Profile, 
      ProfilePhotoUrl, 
      Env, 
      LName, 
      FName, 
      sidebarOpen,
      siteFilterOpen,
      profileOpen,
      feedbackOpen,
      searchActive,
      searchCriteria,
      selectedRoute,
      componentTitle,
      selectedCompany,     
      selectedTags,
      selectedMaterial,
      mobile,
      topbarOpen,
      LifeSafetyData,
      LifeSafetyProperties,
    } = this.state;

    const hostingEnv = Env ? Env : "Staging";
    const sideBarWidth = 240;
    const userName= FName ? `${ FName } ${ LName }` : null;
    const contentId = 'Content';

    return (
      <div id='application-layout-container' style={{ height: '100%', backgroundColor: '#f6f3eb' }} ref={this.layoutWrapperRef}>
        <ApplicationHeader 
          searchSelected={ this.searchSelected }
          hostingEnv={ hostingEnv }
          searchActive={ searchActive }
          searchCriteria={ searchCriteria }
          sidebarOpen={ sidebarOpen }
          toggleSidebar={this.toggleSidebar}
          handleTopbar={this.handleTopbar}
          handleOpenFeedback={this.handleOpenFeedBackModal}
          handleLogOut={ this.handleLogOut }
          componentTitle={ componentTitle }
          profile={ Profile }
          profilePhotoUrl={ ProfilePhotoUrl }
          handleProfileClick={this.handleProfileClick}
          userName={ userName }
          selectRoute={ this.selectRoute }
          mobile={ mobile }
          topbarOpen={ topbarOpen }
          menus={ Menus }
          siteFilterOpen={siteFilterOpen}
          siteFilterSelect={this.siteFilterSelect}
        /> 
        <ApplicationSidebar
          vm={ this.vm }
          menus={ Menus }
          userName={ userName }
          sideBarWidth={ sideBarWidth }
          siteFilterSelect={ this.siteFilterSelect }
          sidebarOpen={ sidebarOpen }
          selectRoute={ this.selectRoute }
          getTitle={ this.getTitle }
          profileOpen={this.profileOpen}
          selectedCompany= { selectedCompany }
          selectedMaterial= { selectedMaterial }
          selectedTags= { selectedTags }
          lifeSafetyData = { LifeSafetyData}
          lifeSafetyProperties = {LifeSafetyProperties}
          propertySelect = {this.propertySelect }
        />
        <ApplicationContent 
          vm={this.vm}
          siteFilterSelect={this.siteFilterSelect}
          handleCloseFeedBackModal={this.handleCloseFeedBackModal}
          selectRoute={ this.selectRoute}
          contentId={contentId}
          siteFilterOpen={siteFilterOpen}
          sidebarOpen={sidebarOpen}
          selectedRoute={selectedRoute}
          feedbackOpen={feedbackOpen}
        />
        <Dialog
          open={profileOpen}
          onClose={this.handleProfileClose}
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title"><span style={{fontFamily: 'Ebrima',}}>Profile</span></DialogTitle>
          <DialogContent>
            <div>
              <ProfileFormPage
                handleProfileClick={this.handleProfileClick}
                handleProfileUpdate={this.handleProfileUpdate}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleProfileClose} color="primary" style={{ fontFamily: 'Ebrima',}}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {/* <ApplicationFeedbackModal 
          handleCloseFeedBackModal={this.handleCloseFeedBackModal}
          feedbackOpen={feedbackOpen}
        /> */}
      </div>
    )
  }
};

ApplicationLayout.propTypes = {
  userName: PropTypes.string,
  menus: PropTypes.array,
  width: PropTypes.number
};

export default withWidth()(ApplicationLayout);

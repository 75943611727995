import React from 'react';
import BluefinLogo from '../../../images/Mantis_Logo_Perform_White_Horizontal_Outlined.png';

function ApplicationSidebarLogo(){
  const style = {
    backgroundColor: '#0672BA', 
    minHeight: '64px', 
    textAlign: 'center',
    paddingTop: '15px',
    fontFamily:'Ebrima'
  };

  return (
    <div style={ style }>
      <img src={ BluefinLogo } width="215"></img>
    </div>
  )
}

export default ApplicationSidebarLogo
import React, { useEffect, useState } from 'react';
import {
    InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  IconButton,
    Typography,
    MenuItem,
    FormControl,
  Select,
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropertyYearSelector from '../PropertyGraphs/SubComponents/PropertyYearSelector';
import PropertyFinancialSectionDetail from './SubComponents/PropertyFinancialSectionDetail';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    fontFamily: 'Ebrima', 
  },
  tableWrapper: {
    maxHeight: '100%',
    overflow: 'auto',
    fontFamily: 'Ebrima', 
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
}));

const forceSticky = { position: 'sticky', top: '0', zIndex: '10', backgroundColor: 'white',  fontFamily: 'Ebrima', };

export default function PropertyFinancialForecast(props) {
  const [replace, setReplace] = useState(0);
  const [replaceActual, setReplaceCount] = useState(0);
  const [rehabActual, setRehabActual] = useState(0);
  const [rehab, setRehab] = useState(0);
  const [forecast, setForecast] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [expandedDefects, setExpandedDefects] = useState([]);


    const {
        handleYearChange,
      year,
    forecastData,
    property,
  } = props;

  const handleExpandClick = (sectionId) => {
    if (expanded != sectionId) {
      setExpanded(sectionId);
    } else {
      setExpanded(null);
    }
  };

  const findSectionDetails = (expanded) => {
    const sectionDefects = [];
    const propertyDefects = property.DefectCM;
    const expandedSectionId = expanded.split('-')[expanded.split('-').length - 1];
    propertyDefects.map((defectGroup) => {
      const defects = defectGroup.Defects;
      defects.map((defect) => {
        const defectSectionId = defect.SectionId;
        const proposed = 2;
        const proposedId = defect.DefectStateLast.ListId;
        if (defectSectionId == expandedSectionId) {
          if (proposedId == proposed) {
            sectionDefects.push(defect);
          }
        }
      })
    })
    return [...new Set(sectionDefects)];
  };

  useEffect(() => {
    if (expanded) {
      const sectionDefects = findSectionDetails(expanded);
      setExpandedDefects(sectionDefects);
    }
  }, [expanded]);

  useEffect(() => {
    setForecast(forecastData);
  }, [forecastData]);

  useEffect(() => {
    if (property) {
      const propertyExists = Object.values(property).length;
      if (propertyExists) {
        const {
          Forecast,
          CurrentBudget,
        } = property;
        setForecast(Forecast);

        if (CurrentBudget) {
          const {
            Replace,
            ReplaceActual,
            Rehab,
            RehabActual,
          } = CurrentBudget;
          setReplace(Replace);
          setReplaceCount(ReplaceActual);
          setRehab(Rehab);
          setRehabActual(RehabActual);
        }
      }
    }
  }, [property]);

  const classes = useStyles();
  return (

      <Paper className={classes.root}>
          
          <div className={classes.tableWrapper}>
           <Table stickyHeader aria-label="sticky table">
           <TableHead>
                      <TableRow>
                          <TableCell forceSticky align="left" style={{fontFamily: 'Ebrima', }}>
                              <PropertyYearSelector
                                  year={year}
                                  handleYearChange={handleYearChange}
                                  forecastData={forecastData}
                                  property={property}
                              />
                          </TableCell>
              <TableCell align="right" style={forceSticky}>Section</TableCell>
              <TableCell align="right" style={forceSticky}>Square Feet</TableCell>
              <TableCell align="right" style={forceSticky}>Operating Costs</TableCell>
              <TableCell align="right" style={forceSticky}>Capital</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {forecast.map((data) => {
                if (data.Year == year) {
                    const sections = data.sections;
                    const year = data.Year;
                    return (
                        <div style={{ display: 'contents' }}>
                            {sections.map((section) => {
                                const sectionId = `${year}-${section.SectionId}`;
                                const sectionOE = section.OE;
                                const sectionSF = section.SF;
                                console.log(section);
                                console.log(replace);
                                return (
                                    <div style={{ display: 'contents' }}>
                                        <TableRow key={section.Name}>
                                            <TableCell align="left" style={{fontFamily: 'Ebrima', }}>
                                                <IconButton style={{fontFamily: 'Ebrima', }}
                                                    size={'small'}
                                                    className={clsx(classes.expand, {
                                                        [classes.expandOpen]: expanded == sectionId,
                                                    })}
                                                    onClick={() => handleExpandClick(sectionId)}
                                                    aria-expanded={expanded == sectionId}
                                                    aria-label="show more"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="right" style={{fontFamily: 'Ebrima', }}>{section.Name}</TableCell>
                                            <TableCell align="right" style={{fontFamily: 'Ebrima', }}>{new Intl.NumberFormat('en').format(section.SF)} <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>(Sq Ft)</span></TableCell>
                                            <TableCell align="right" style={{fontFamily: 'Ebrima', }}>
                                                {`${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(section.OE + section.Repair)}`}
                                            </TableCell>
                                            <TableCell align="right" style={{fontFamily: 'Ebrima', }}>
                                                {`${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(section.Rehab + section.Replace)}`}
                                            </TableCell>
                                        </TableRow>
                                        {expanded == sectionId ?
                                           
                                            <TableRow>
                                                <TableCell style={{fontFamily: 'Ebrima', }}>
                                                   

                          </TableCell>
                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}></TableCell>
                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}></TableCell>
                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}>
                                                    
                                                    <Table size='small'>
                                                        <TableRow align="right">
                                                            {expandedDefects.map((defect) => {
                                                                const defectName = defect.DefectName;
                                                                const defectCost = defect.RecommendCost;
                                                                return (
                                                                    <TableRow>
                                                                        <TableCell align="right" style={{fontFamily: 'Ebrima', }}>{defectName}:</TableCell>
                                                                        <TableCell align="right"style={{fontFamily: 'Ebrima', }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(defectCost)}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                            <TableRow align="right">
                                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}>Operating Expense:</TableCell>
                                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sectionOE)}</TableCell>
                                                            </TableRow>
                                                        </TableRow>
                                                        <TableRow align='right'>
                                                            <TableRow align='right'>
                                                                <TableCell align="right style={{fontFamily: 'Ebrima', }}">
                                                                    <span style={{ fontWeight: 'bold' }}>{`${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(section.OE + section.Repair)}`}</span>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableRow>
                                                    </Table>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Table size='small'>
                                                        <TableRow align='right'>
                                                            <TableRow>
                                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}>Replace Area Sq Ft:</TableCell>
                                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}>{section.Replace != 0 ? new Intl.NumberFormat('en').format(section.SF) : "N/A"}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}>$/Sq Ft:</TableCell>
                                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}>{section.Replace != 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(section.Replace / section.SF) : "N/A"}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}>Rehab Area Sq Ft:</TableCell>
                                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}>{section.Rehab != 0 ? new Intl.NumberFormat('en').format(section.SF) : "N/A"}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}>$/Sq Ft:</TableCell>
                                                                <TableCell align="right" style={{fontFamily: 'Ebrima', }}>{section.Rehab != 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(section.Rehab / section.SF) : "N/A"}</TableCell>
                                                            </TableRow>
                                                        </TableRow>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                            : null}
                                    </div>
                                );
                            })}
                        </div>
                    )
                }})}

          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}



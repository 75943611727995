import React, { useState, useEffect, useRef } from 'react';
import {
  InputLabel,
  FormHelperText,
  FormControl,
  NativeSelect,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '90%',
    fontFamily: 'Ebrima', 
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontFamily: 'Ebrima', 
  },
}));

export default function NativeSelects(props) {
  const [ reportType, setReportType ] = useState(0);

  const { 
    retrieveSelectedReportType,
    selectedReportType,
   } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    if (value) {
      const number = parseInt(value);
      setReportType(number);
    }
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    retrieveSelectedReportType(reportType);
  }, [reportType]);

  const previousReportType = usePrevious(selectedReportType);
  useEffect(() => {
    if (!selectedReportType) {
      if (previousReportType) {
        setReportType(0);
      }
    }
  }, [selectedReportType]);

  const classes = useStyles();
  return (
   
     <FormControl className={classes.formControl}>
      <InputLabel htmlFor="age-native-helper">Report Type</InputLabel>
      <NativeSelect
        onChange={handleChange}
        value={reportType}
        inputprops={{
          shrink: true,
        }}
        style={{fontFamily: 'Ebrima', }}
      >
        <option aria-label="None" value={0} />
        <option value={2} style={{fontFamily: 'Ebrima', }}>Scope Of Work</option>
        <option value={3} style={{fontFamily: 'Ebrima', }}>Condition Assessment Report</option>
        <option value={6} style={{fontFamily: 'Ebrima', }}>Quantity Report</option>
        <option value={7} style={{fontFamily: 'Ebrima', }}>CAD Image Report</option>
        <option value={8} style={{fontFamily: 'Ebrima', }}>CMSR (Corrective Maintenance Summary)</option>
        <option value={9} style={{fontFamily: 'Ebrima', }}>Pavement Summary Report</option>
      </NativeSelect>
      <FormHelperText  style={{fontFamily: 'Ebrima', }}>Select A Report To Run</FormHelperText>
    </FormControl>
  );
}


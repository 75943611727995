
import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    Typography,
    FormControl,
    FormGroup,
    FormHelperText,
    Switch,
} from '@material-ui/core';

export default function PropertyInventoryListHeader(props) {
    const [checked, setChecked] = useState(true);

    const {
        handleDisplayInventory,
    } = props;

    const handleChange = () => {
        setChecked(!checked);
    };

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    };

    const previousChecked = usePrevious(checked);
    useEffect(() => {
        if (checked != previousChecked) {
            handleDisplayInventory(checked);
        }
    }, [checked]);

    return (
        <Grid container direction='row' justify={'center'} alignItems={'center'} style={{ backgroundColor: 'gainsboro' }}>
            <Grid item xs={9}>
                <Grid container direction='row' justify='flex-start' alignItems='center'>
                    <Grid item xs={12}>
                        <Typography style={{ color: 'white', marginLeft: '12px', fontFamily: 'Ebrima', }} variant="h5" component="h2">
                            {'Inventory'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Grid container direction='row' alignItems='center' justify='center'>
                    <Grid item xs={12} style={{ textAlign: 'center', padding: '2%', paddingBottom: '6%' }}>
                        <FormControl size='small' style={{ padding: '2%' }}>
                            <FormGroup aria-label="position" row>
                                <Switch size={'small'} onChange={handleChange} checked={checked} color="default" />
                            </FormGroup>
                            <FormHelperText style={{ margin: '0', padding: '1%', fontFamily: 'Ebrima', }}>Display</FormHelperText>

                        </FormControl>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

import React from 'react';
import {SvgIcon} from '@material-ui/core';

let SvgTags = (props) => (
  <SvgIcon {...props} >
       <polyline points="2.2 9.8 12 0 9.8 0 0 9.8 9.9 19.7 9.8 19.6 10.9 18.5 2.1 9.7"/>
       <path d="M4.4,12l9.8,9.8L24,12V2.2H14.2ZM17.5,6.5a2.2,2.2,0,1,1,2.2,2.2A2.22,2.22,0,0,1,17.5,6.5Z" transform="translate(0 -2.2)"/>
  </SvgIcon>
);

export default SvgTags;

import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ThemeDefault from '../../styles/theme-default';
import globalStyles from '../../styles/styles';

import {
    CartesianGrid,
    ComposedChart,
    Area,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ResponsiveContainer,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const graphContainerStyle = {
    textAlign: 'center'
}

const YAxisTickFormatter = (value) => {
    return `${new Intl.NumberFormat('en').format(value)}`;
};

const CustomTooltipContent = (props) => {
    const payload = props.payload;

    if (props.active) {
        const newPayload = payload; 

        if (payload) {
            payload.map((entry, i) => {
                const dataKey = entry.dataKey;
                return entry.formatter = (value) => YAxisTickFormatter(value);
            })    
        }

        return <DefaultTooltipContent {...props} payload={newPayload} />
    } else {
        return <DefaultTooltipContent {...props} payload={payload}/>
    }
};

class FinancialForecastGraph extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { emergencyRepairsData } = this.props;
        
        return (
            <div id='graph-container' style={ graphContainerStyle }>
                {/* <MuiThemeProvider muiTheme={ ThemeDefault }> */}
                    <Typography color='textSecondary' variant='h6'>Emergency Repairs By Location (Last Year)</Typography>
                    <ResponsiveContainer width='96%' height='100%' aspect={ 12.0 / 5.0 } >
                        <ComposedChart width={730} height={250} data={ emergencyRepairsData }>
                            <XAxis dataKey="name" type="category" />
                            <YAxis type="number" tickFormatter={ YAxisTickFormatter }/>
                            <Tooltip content={ <CustomTooltipContent props={ emergencyRepairsData } /> } />
                            <Legend />
                            <CartesianGrid stroke="#f5f5f5" />
                            <Bar dataKey="pv" barSize={20} name="Actual Repair Volume" fill="#1F5998" />
                            <Line type="monotone" dataKey="uv" name="Average Repair Volume" stroke="#ff7300" />
                        </ComposedChart>
                    </ResponsiveContainer>
                {/* </MuiThemeProvider> */}
            </div>
        );
    }
}

export default FinancialForecastGraph

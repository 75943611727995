import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import ScriptLoaded from '../../../../../GoogleMaps/LoadScript/LoadScript';

export default function PropertiesMapHook(props) {
  const [ selectedProperty, setSelectedProperty ] = useState({});
  const [ selectedLocations, setSelectedLocations ] = useState([]);
  const [ latLng, setLatLng ] = useState({});
  const {
    handleCoordinantClick,
    
    selectedCoordinants,
    leakResponse,
    width,
  } = props;

  const mapElementStyle = { height: `100%` };

  const mapContainerElementStyle = {
    height: '100%',
    width: '100%'
  };

  useEffect(() => {
    if (leakResponse) {
      const {
        Property,
        Locations,
      } = leakResponse;
      const lat = parseFloat(Property.Lat);
      const lng = parseFloat(Property.Lng);
      const coordinants = { lat, lng };      
      setLatLng(coordinants);
      setSelectedLocations(Locations);
      setSelectedProperty(Property);
    }
  }, [leakResponse]);

  return (
    <Grid container direction='row' style={{ width: '100%', height: '100%' }}>
      <Grid xs={12}>
        <ScriptLoaded>
          <PropertiesMap
            handleCoordinantClick={handleCoordinantClick}
            
            selectedLocations={selectedLocations}
            selectedCoordinants={selectedCoordinants}
            selectedProperty={selectedProperty}
            latLng={latLng}
            width={width}
            isMarkerShown={true}
            containerElement={<div style={mapContainerElementStyle} />}
            mapElement={<div style={mapElementStyle} />}
          />
        </ScriptLoaded>
      </Grid>
    </Grid>
  )
};

const getMapOptions = (maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,
    data: {
      style: {
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      }
    },
    styles: [{
      featureType: "poi.business",
      elementType: "labels",
      stylers: [{
        visibility: "off"
      }]
    }],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 11,
    maxZoom: 23,
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.SATELLITE,
    tilt: 0,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID
      ]
    },
    zoomControl: true,
    clickableIcons: false
  };
};

const createBounds = (selectedLocations) => {
  const bounds = new google.maps.LatLngBounds();
  selectedLocations.map((selectedLocation, i) => {
    const lat = parseFloat(selectedLocation.lat);
    const lng = parseFloat(selectedLocation.lng);

    if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
      if (lat != 0 && lng != 0) {
        const latLng = { lat, lng };
        bounds.extend(latLng);
      }
    }
  });
  return bounds;
};

const PropertiesMap = withGoogleMap(props => {
  const {
    handleCoordinantClick,

    selectedLocations,
    selectedCoordinants,
    latLng,
    width,
  } = props;

  const handleCoordClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const latLng = { lat, lng };
    handleCoordinantClick(latLng);
  };

  console.log(selectedLocations)
  let bounds;
  if (selectedLocations) {
    console.log(1)
    if (selectedLocations.length > 1) {
      console.log(2)
      bounds = createBounds(selectedLocations);
    }
  }

  return (
    <GoogleMap
      ref={map => map && bounds ? map.fitBounds(bounds) : new google.maps.LatLng(latLng)}
      defaultZoom={ width > 959 ? 22 : 17 }
      defaultCenter={latLng}
      options={getMapOptions(window.google.maps)}
      onClick={handleCoordClick}
    >
      { selectedLocations.map((selectedLocation, i) => {
        const lat = selectedLocation.lat;
        const lng = selectedLocation.lng;
        const position = { lat, lng };
        return (
          <Marker position={position} icon={"http://client.roof-express.com/IconCreater/icon.aspx?label=" + (i+1).toString() + "&def=0"} ></Marker>
        )
      }) }
      { selectedCoordinants ? 
        <Marker
          position={selectedCoordinants}
        ></Marker>
      : null }
    </GoogleMap>
  )
});
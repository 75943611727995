import React, { useState} from 'react';
import PropertySectionMaterials from './PropertySectionMaterials';
import PropertySectionExtPerimeter from './PropertySectionExtPerimeter';
import PropertySectionIntPerimeter from './PropertySectionIntPerimeter';
import PropertySectionInventory from './PropertySectionInventory';
import PropertySectionMap from './PropertySectionMap';
import {
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Avatar,
  IconButton,
  Paper,
  Grid,
  Chip,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
    boxShadow: 'none',
    padding: 'none',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PropertySectionsCards(props) {
  const [expanded, setExpanded] = useState(false);
  const [selectedPerimeterId, setSelectedPerimeterId] = useState(null);
  const { 
    section, 
    propertyBounds, 
    addressCoordinants, 
    i, 
  } = props;

  const { 
    Name, 
    SecId, 
    SectionId, 
    Surface, 
    SurfaceImages, 
    Inventory, 
    InteriorPerimeter, 
    ExteriorPerimeter, 
    Defects, 
    SFFormatted, 
    RoofTypeTitle, 
    RoofTypeShortTitle,
    HasActiveWarranty, 
  } = section;

  const classes = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSelectedPerimeter = (selectedPerimeterId) => {
    setSelectedPerimeterId(selectedPerimeterId);
  };

  const subheader = RoofTypeShortTitle ? `${RoofTypeShortTitle} - ${SFFormatted} SqFt` : `${SFFormatted} SqFt`;

  return (
    <Card key={`${i}-${SectionId}`} className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="section" className={classes.avatar}>
           {SecId}
          </Avatar>
        }
        action={
          <Grid container direction='row' alignItems='center' justify='center'>
            { HasActiveWarranty ?
              <Chip
                icon={<InsertDriveFileIcon />}
                color='primary'
                label='Warrantied'
                variant='default'
                style={{ backgroundColor: 'cadetBlue' }}
              >
              </Chip> 
            : null }
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Grid>
        }
        title={`Section ${SecId}`}
        subheader={
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={12}>
              { subheader }
            </Grid>
            <Grid item xs={12}>
              { RoofTypeTitle }
            </Grid>
          </Grid>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container direction='row' justify="space-between" alignItems="center">
            <Grid item xs={12}>
              <Paper style={{ backgroundColor: 'rgb(233, 233, 233)', width: '100%', boxShadow: 'none' }}>
                <PropertySectionMap 
                  selectedPerimeterId={ selectedPerimeterId }
                  addressCoordinants={ addressCoordinants }
                  propertyBounds={ propertyBounds }
                  section={ section }
                />
              </Paper>
              <Paper square style={{ backgroundColor: 'rgb(233, 233, 233)', width: '100%', boxShadow: 'none', marginTop: '5%' }}>
                <PropertySectionExtPerimeter 
                  handleSelectedPerimeter={ handleSelectedPerimeter }
                  exteriorPerimeter={ ExteriorPerimeter }
                />
                <PropertySectionIntPerimeter 
                  interiorPerimeter={ InteriorPerimeter }
                />
                <PropertySectionInventory
                  inventory={Inventory}
                />
                <PropertySectionMaterials 
                  surfaces={ Surface }
                  surfaceImages={ SurfaceImages }
                />
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}

import React from 'react';
import {SvgIcon} from '@material-ui/core';

let SvgProperties = (props) => (
  <SvgIcon {...props} >
       <path d="M16,7h8V24H0V0H16ZM7,19H5v4H7Zm4,0H9v4h2Zm7,0H16v4h2ZM22,9H16v9h3v4h3ZM14,2H2V22H4V18h8v4h2ZM7,15H4V12H7Zm5,0H9V12h3ZM7,11H4V8H7Zm5,0H9V8h3Zm8,0v4H18V11ZM7,7H4V4H7Zm5,0H9V4h3Z"/>
  </SvgIcon>
);

export default SvgProperties;


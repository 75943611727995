import React from 'react';
import {SvgIcon} from '@material-ui/core';

let SvgDocs = (props) => (
  <SvgIcon {...props} >
        <path d="M14,8.3V0H6.7V20.6H22.2V8.3Zm8.1-2.2H16V0Z" transform="translate(-3.1)"/>
        <polygon points="0 24 16.5 24 16.5 21.9 2.1 21.9 2.1 2.3 0 2.3 0 24" />
  </SvgIcon>
);

export default SvgDocs;
import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
} from '@material-ui/core';

export default function AddBluefinInvoiceDoc(props) {
  const {
    handleDone,
  } = props;

  const onClickDone = () => {
    handleDone();
  };

  return (
    <Button variant='contained' color='secondary' onClick={() => onClickDone()}>Done</Button>   
  );
};




import React, { useState, useEffect } from 'react';
import PropertyFinancialForecast from './PropertyFinancialForecast';
import PropertyGraphsHeader from '../PropertyGraphs/SubComponents/PropertyGraphsHeader';
import {
  Paper,
  Grid,
  Modal,
  Avatar,
  Typography,
  IconButton,
    Button,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { makeStyles } from '@material-ui/core/styles';


export default function PropertyFinancialForecastModal(props) {
    const [forecastData, setForecastData] = useState([]);
    const [year, setYear] = React.useState('');
  const { 
    closeFinancialForecastWindow, 
    financialForecastWindowOpen, 
    property, 
  } = props;

  useEffect(() => {
    if (property) {
      const forecast = property.Forecast;
      if (forecast) {
          setForecastData(forecast);
          if (year == '') {
              setYear(forecast[0].Year);
          }
      }
    }
  }, [property]);

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const useStyles = makeStyles(theme => ({
        root: {
            height: '100%',
            width: '100%',
        },
        tableWrapper: {
            maxHeight: '100%',
            overflow: 'auto',
        },
        expand: {
            transform: 'rotate(-90deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(0deg)',
        },
    }));
    const classes = useStyles();

  return (
    <Modal open={financialForecastWindowOpen}>
      <Paper id='paper' style={{ position: 'absolute', height: '95%', width: '95%', top: '2.5%', left: '2.5%' }}>
        
        
        <Grid container direction='row' style={{ height: '100%' }}>
          <Grid item xs={12} style={{ height: '15%' }}>
            <Grid container direction='row' alignItems='center' justify='space-between' style={{ backgroundColor: '#66BFFA', position: 'sticky', top: '0', left: '0', width: '100%', textAlign: 'center' }}>
              <Grid item xs={1} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                <Avatar style={{ backgroundColor: 'gainsboro', margin: '2%' }}>
                  <EqualizerIcon />
                </Avatar>
                <Typography variant='h6' style={{ margin: '4%', fontFamily: 'Ebrima', }}>Financials</Typography>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'right', color: 'white', display: 'contents' }}>
                <IconButton onClick={() => closeFinancialForecastWindow()}>
                  <HighlightOffIcon />
                </IconButton>
              </Grid>
              <PropertyGraphsHeader
                property={property}
                          />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ height: '80%' }}>
                      <PropertyFinancialForecast
                          year={year}
                          handleYearChange={handleYearChange}
              forecastData={forecastData}
              property={property}
            />
          </Grid>
          <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA', width: '100%', textAlign: 'center' }}>
            <Grid item xs={3} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', alignItems: 'center', whiteSpace: 'nowrap' }}>
              <Button style={{ margin: '2%', fontFamily: 'Ebrima', }} variant={'contained'} color={'primary'} onClick={closeFinancialForecastWindow}>Close</Button>
            </Grid>
          </Grid>
        </Grid>        

        
      </Paper>
    </Modal>
  )
} 
import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Polyline, Polygon } from "react-google-maps";

const mapContainerElementStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%'
};

const mapElementStyle = { height: '100%' };

const createLines = (extPerimeters) => {
  const lineCoordinantsArray = [];

  extPerimeters.map((perimeter) => {
    const points = perimeter.points;
    points.map((point) => {
      const pointObj = {
        lat: parseFloat(point.Lat),
        lng: parseFloat(point.Lng)
      };
      if (!isNaN(pointObj.lat) && !isNaN(pointObj.lng)) {
        lineCoordinantsArray.push(pointObj);
      }
    })
  })
  return lineCoordinantsArray;
}

const SectionMap = withGoogleMap(props => {
  const {
    section,
    propertyBounds,
    addressCoordinants,
    selectedPerimeterId,
  } = props;

  const extPerimeters = section.ExteriorPerimeter;
  const polylinesArray = createLines(extPerimeters);  
  let selectedPerimeter = [];

  if (selectedPerimeterId) {
   const matchingPerimeter =  extPerimeters.find(perimeter => perimeter.id == selectedPerimeterId);
   matchingPerimeter.points.map((point) => {
     const selectedCoordinant = {
       lat: parseFloat(point.Lat),
       lng: parseFloat(point.Lng)
     };
     if (!isNaN(selectedCoordinant.lat) && !isNaN(selectedCoordinant.lng)) {
      selectedPerimeter.push(selectedCoordinant);
     }
   })
  }

  return (
    <GoogleMap
      options={ getMapOptions(window.google.maps) }
      defaultZoom={ 20 }
      ref={map => { map ? map.fitBounds(propertyBounds) : null } }
    >
      <Polygon
        defaultPosition={addressCoordinants}
        path={polylinesArray}
        geodesic={true}
        options={{ strokeColor: '#FF0000', strokeOpacity: 0, strokeWeight: 0, fillColor: '#FF0000', fillOpacity: 0.35 }}
      />   
      { selectedPerimeter ? 
        <Polyline
          defaultPosition={addressCoordinants}
          path={selectedPerimeter}
          geodesic={true}
          options={{ strokeColor: '#FFFF00', strokeOpacity: 1, strokeWeight: 2 }}
        />  
      : null }
    </GoogleMap>
  )
});

const getMapOptions = (maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,
    data: {
      style: {
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      }},
    styles: [{
      featureType: "poi.business",
      elementType: "labels",
      stylers: [{
        visibility: "off"
      }]
    }],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 11,
    maxZoom: 23,
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.SATELLITE,
    tilt: 0,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID
      ]
    },

    zoomControl: true,
    clickableIcons: false
  };
}

class PropertySectionMap extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { propertyBounds } = this.props;
    const previousPropertyBounds = prevProps.propertyBounds;

    if (propertyBounds) {
      if (propertyBounds != previousPropertyBounds) {
        this.setState({
          propertyBounds,
        })
      }
    }
  }

  render() {
    const { section, propertyBounds, addressCoordinants, selectedPerimeterId } = this.props;

    return (
      <div id='map' style={{ position: 'sticky', height: '18rem', width: '100%', top: '100px' }}>
        <SectionMap
          selectedPerimeterId={ selectedPerimeterId }
          addressCoordinants={ addressCoordinants }
          propertyBounds={ propertyBounds }
          section={ section }
          containerElement={<div style={mapContainerElementStyle} />}
          mapElement={<div style={mapElementStyle} />}
        />
      </div>
    )
  }
}

export default PropertySectionMap
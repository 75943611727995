import React from 'react';
import {
  ListItem,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import SvgWorkOrders from '../../SvgIcons/SvgWorkOrders';
import SvgDocs from '../../SvgIcons/SvgDocs';
import ApplicationSearchItemStyle from '../../../styles/ApplicationHeaderStyles/ApplicationSearchItemStyle';

function ApplicationSearchItem(props) {
  const { 
    // selectRoute,
    handleSelectRoute, 
    searchResult, 
    selectedTab, 
  } = props;

  const classes = ApplicationSearchItemStyle();
  return (
    <div className={classes.root}>
      <ListItem button onClick={() => handleSelectRoute(searchResult.Route, searchResult.Description)}>
        <IconButton>
          { selectedTab == 0 ?
              <BusinessIcon />
           : selectedTab == 1 ? 
              <SvgWorkOrders />
           : selectedTab == 2 ?
              <SvgDocs />
           : null }
        </IconButton>
        <ListItemText primary={ searchResult.Title } classes={{primary:classes.root}} />
      </ListItem>
    </div>
  )
};

export default ApplicationSearchItem
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    fontFamily: 'Ebrima',
  },
}));

const tabTitleStyle = {
  fontFamily: 'Ebrima'
}

export default function ProperyImageStepper(props) {
  const [value, setValue] = useState(0);
    
  const {
      handleSwitchSelectedMedia,
      property,
  } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    handleSwitchSelectedMedia(value);
  }, [value]);

  const classes = useStyles();
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12} className={classes.root} style={{ display: 'inline-flex', backgroundColor: '#fff', fontFamily: 'Ebrima' }}>
        <AppBar position="static" color="default" style={{ display: 'inline-grid', boxShadow: 'none', fontFamily: 'Ebrima', }}>
         
            {
                property.MaterialType != 6 ?

                          <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="scrollable"
                              scrollButtons="on"
                              aria-label="scrollable auto tabs example">
                            <Tab label="Defects" style={tabTitleStyle} {...a11yProps(0)} />
                            <Tab label="Aerial Map"  style={tabTitleStyle} {...a11yProps(1)} />
                            <Tab label="Images"  style={tabTitleStyle} {...a11yProps(2)} />
                            <Tab label="Building Plan"  style={tabTitleStyle} {...a11yProps(3)} />
                            <Tab label="Defect Plan"  style={tabTitleStyle} {...a11yProps(4)} />
                          </Tabs>
                          :
                          <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="scrollable"
                              scrollButtons="on"
                              aria-label="scrollable auto tabs example">
                              <Tab label="Inventory" style={tabTitleStyle} {...a11yProps(0)} />
                              <Tab label="Images"  style={tabTitleStyle} {...a11yProps(1)} />
                              <Tab label="Building Plan" style={tabTitleStyle} {...a11yProps(2)} />
                          </Tabs>
                    
             }
           
         
        </AppBar>
      </Grid>
    </Grid>
  );
}

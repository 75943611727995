import React, { Component } from 'react';
import PropertyGraphsHeader from '../PropertyGraphs/SubComponents/PropertyGraphsHeader';
import PropertyInventory from '../PropertyInventory/PropertyInventory';
import PropertyInventoryMap from '../PropertyInventory/PropertyInventoryMap';
import PropertyDefectsSelect from './SubComponents/PropertyDefectsSelect';
import {
    Grid,
} from '@material-ui/core';

class PropertyDetailsInventory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            //defects
            selectedInventory: [],
            selectedInventorySection: null,
            selectedInventorySections: [],
            highlightedInventory: null,
            //screen resizing
            mobile: null,
        }
    }

    componentDidMount = () => {
        this.handleScreenResize();
    };

    componentDidUpdate = (prevProps, prevState) => {
        window.addEventListener('resize', this.handleScreenResize);
    };

    handleScreenResize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 600) {
            this.setState({
                mobile: true,
            });
        } else {
            this.setState({
                mobile: false,
            });
        }
    };

    retrieveSelectedInventorySection = (selectedInventorySection) => {
        this.setState({
            selectedInventorySection,
        })
    };

    retrieveSelectedInventory = (selectedInventory) => {
        this.setState({
            selectedInventory,
        })
    };

    retrieveHighlightedInventory = (highlightedInventory) => {
        this.setState({
            highlightedInventory,
        })
    };

    render() {
        const {
            addressCoordinants,
            propertyBounds,
            selectedResult,
            selectedProperty,
        } = this.props;

        const {
            //defects
            selectedInventorySection,
            selectedInventorySections,
            selectedInventory,
            highlightedInventory,
            //resizing
            mobile,
        } = this.state;

        return (
            <Grid container direction={'row'} style={{ height: '100%' }}>
                <Grid item xs={12} sm={6} md={6} lg={8} xl={8} style={mobile ? { height: '50%' } : { height: '100%', position: 'sticky', bottom: '0', left: '0' }}>
                    <PropertyInventoryMap
                        selectedInventorySection={selectedInventorySection}
                        selectedInventorySections={selectedInventorySections}
                        selectedInventory={selectedInventory}
                        highlightedInventory={highlightedInventory}

                        //data for initialization
                        addressCoordinants={addressCoordinants}
                        propertyBounds={propertyBounds}

                        //default props
                        selectedResult={selectedResult}
                        selectedProperty={selectedProperty}

                        mobile={mobile}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} style={mobile ? { height: '50%' } : { height: '100%', overflow: 'auto' }}>
                    <PropertyInventory
                        retrieveSelectedInventorySection={this.retrieveSelectedInventorySection}
                        retrieveSelectedInventory={this.retrieveSelectedInventory}
                        retrieveHighlightedInventory={this.retrieveHighlightedInventory}
                        selectedResult={selectedResult}
                        selectedProperty={selectedProperty}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default PropertyDetailsInventory
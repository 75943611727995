import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  TextField,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '95%',
    margin: theme.spacing(1),
    minWidth: 120,
    marginBottom: 'inherit',
  },
}));

export default function WarrantyContractorInput(props) {
  const [value, setValue] = useState(0);
  const {
    handleDurationSelect,
    document,
  } = props;

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value != previousValue) {
      handleDurationSelect(value);
    } 
  }, [value]);

  useEffect(() => {
    if (document) {
      const { Id, Duration } = document;
      if (Id && Duration) {
        setValue(Duration);
      } else {
        setValue(0);
      }
    }
  }, [document]);

  const classes = useStyles();

  return (
    <Grid container direction='row' justify='center' alignItem='center'>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} noValidate autoComplete="off">
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            id="standard-basic"
            label="Duration"
            type='number'
            onChange={handleChange}
            value={value}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
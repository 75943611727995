import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Button,
  Grid,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
    margin: '0',
  }
}));

export default function AddBluefinInvoiceDoc(props) {
  const [ready, setReady] = useState(null);
  const classes = useStyles();
  const { 
    handleNext,
    category1Finished,
    category2Finished,
    category3Finished,
    category4Finished,
    category5Finished,
   } = props;

  useEffect(() => {
    if(  category1Finished
      && category2Finished
      && category3Finished
      && category4Finished
      && category5Finished) {
        setReady(true);
      } else {
        setReady(false);
      }
  }, [props]);

  const handleNextClick = () => {
    handleNext();
  };

  return (
    <FormControl variant="filled" className={classes.formControl}>
      <Paper square style={{ backgroundColor: '#3f51b5', width: '100%' }}>
        <Grid item xs={12}>
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item={4} style={{ margin: '1%' }}>
              <Button disabled={ !ready } variant='contained' color='secondary' onClick={ ()=>handleNextClick() }>Next</Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </FormControl>
  );
};




import React, { useState, useEffect, useRef } from 'react';
import PropertyGraphsHeader from '../PropertyGraphs/SubComponents/PropertyGraphsHeader'; 
import PropertyCondition from './PropertyCondition';
import {
  Paper,
  Grid,
  Modal,
  Avatar,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EqualizerIcon from '@material-ui/icons/Equalizer';

export default function PropertyDefectsModal(props) {
  const [ sections, setSections ] = useState([]);
  const { 
    handleDispatchGetRCS,
    conditionWindowOpen, 
    closeConditionWindow, 
    property, 
    selectedResult,
    rcsInfos, 
  } = props;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousProperty = usePrevious(property);
  useEffect(() => {
    if (property != previousProperty) {
      const sections = property.Sections;
      setSections(sections);
    }
  }, [property]);

  return (
    <Modal open={conditionWindowOpen}>    
      <Paper id='paper' square style={{ position: 'absolute', height: '95%', width: '95%', top: '2.5%', left: '2.5%'}}>
        <Grid container direction='row' style={{height: '100%'}}>
          <Grid item xs={12} style={{height: '15%'}}>
            <Grid container direction='row' alignItems='center' justify='space-between' style={{ backgroundColor: '#66BFFA', position: 'sticky', top: '0', left: '0', width: '100%', textAlign: 'center' }}>
              <Grid item xs={1} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                <Avatar style={{ backgroundColor: 'gainsboro', margin: '2%', fontFamily:'Ebrima', }}>
                  <EqualizerIcon />
                </Avatar>
                <Typography variant='h6' style={{ margin: '4%', fontFamily:'Ebrima', }}>Condition</Typography>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'right', color: 'white', display: 'contents' }}>
                <IconButton onClick={() => closeConditionWindow()}>
                  <HighlightOffIcon />
                </IconButton>
              </Grid>
              <PropertyGraphsHeader
                property={property}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{height: '80%'}}>
            <PropertyCondition
              handleDispatchGetRCS={handleDispatchGetRCS}
              sections={sections}
              selectedResult={selectedResult}
              rcsInfos={rcsInfos}
            />
          </Grid>
          <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA', width: '100%', textAlign: 'center' }}>
            <Grid item xs={3} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', alignItems: 'center', whiteSpace: 'nowrap' }}>
              <Button style={{ margin: '2%', fontFamily:'Ebrima', }} variant={'contained'} color={'primary'}  onClick={closeConditionWindow}>Close</Button>
            </Grid>
          </Grid>
        </Grid> 
      </Paper>
    </Modal>
  )
} 
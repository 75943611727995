import React from 'react';
import {
  Paper,
  Button,
  Grid,
} from '@material-ui/core';

export default function SiteFilterSave(props) {
  const { 
    dispatchSave 
  } = props;
  
  return (
    <Paper square style={{ height: '7%', backgroundColor: '#66BFFA'}}>
      <Grid container justify={'center'} alignItems='center' direction={'row'} style={{height: '100%'}}>
        <Grid item>
          <Button onClick={ ()=>dispatchSave() } variant={'contained'} color={'primary'}>Save</Button>
        </Grid>
      </Grid>
    </Paper>
  )
}



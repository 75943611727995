import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '95%',
  },
}));

export default function AddAssignedToEmailSelect(props) {
  const classes = useStyles();
  const [value, setValue] = useState(null);

  const {
    retrieveAssignedEmail,
    category4Finished,
    workOrderAssigned,
  } = props;

  const handleChange = e => {
    const text = e.target.value;
    setValue(text);
  };

  useEffect(() => {
    if (workOrderAssigned) {
      const email = 'Burritos@gmail.com';
      setValue(email);
    } else {
      setValue(null);
    }
  }, [workOrderAssigned]);

  useEffect(() => {
    retrieveAssignedEmail(value);
  }, [value]);

  return (
    <form className={classes.container} noValidate autoComplete="off">
       <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
        disabled={!category4Finished || workOrderAssigned}
        onChange={handleChange}
        value={value ? value : ''}
        id="standard-textarea"
        label="Assigned Email"
        placeholder="Assigned Email"
        InputLabelProps={{ shrink: true }}
        className={classes.textField}
        margin="normal"
      />
    </form>
  );
}

import React from 'react';
import ApplicationSidebarOpen from './Subcomponents/ApplicationSidebarOpen';
// import ApplicationSidebarClosed from './Subcomponents/ApplicationSidebarClosed';

export default function ApplicationSidebar(props) {
  const { 
    vm, 
    menus, 
    siteFilterSelect,
    sidebarOpen, 
    selectRoute,
    selectedCompany,     
    selectedTags,
    selectedMaterial,
    lifeSafetyData,
    lifeSafetyProperties,
    propertySelect,
  } = props;

  return (
    <div style={ sidebarOpen ? null : { display: 'none' } }>
      <ApplicationSidebarOpen
        vm={vm}
        menus={menus}
        siteFilterSelect={siteFilterSelect}
        sidebarOpen={sidebarOpen}
        selectRoute={selectRoute}
        selectedCompany={selectedCompany}
        selectedTags={selectedTags}
        selectedMaterial={selectedMaterial}
        lifeSafetyData={lifeSafetyData}
        lifeSafetyProperties={lifeSafetyProperties}
        propertySelect={propertySelect}
      />
    </div>
  );
}


// <div>
    //   { sidebarOpen ? 
    //     <ApplicationSidebarOpen
    //       vm={vm} 
    //       menus={menus} 
    //       siteFilterSelect={siteFilterSelect}
    //       sidebarOpen={sidebarOpen} 
    //       selectRoute={selectRoute}
    //       selectedCompany={selectedCompany}     
    //       selectedTags={selectedTags}
    //       selectedMaterial={selectedMaterial}
    //     />
    //   :
    //     <ApplicationSidebarClosed 
    //       vm={vm}
    //       menus={menus}
    //       siteFilterSelect={siteFilterSelect}
    //       sidebarOpen={sidebarOpen}
    //       selectRoute={selectRoute}
    //       selectedCompany={selectedCompany}
    //       selectedTags={selectedTags}
    //       selectedMaterial={selectedMaterial}
    //     /> 
    //   }
    // </div>
import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone'
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function DropZoneArea(props) {
  const [files, setFiles] = useState([]);
  const {
    handleFileSelect,
    uploadDisabled,
  } = props;

  const handleChange = debounce(files => {
    setFiles(files);
  }, 600);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousFilesLength = usePrevious(files.length);
  useEffect(() => {
    if (files.length != previousFilesLength) {
      handleFileSelect(files);
    }
  }, [files]);

  const classes = useStyles();
  return (
    <Grid container direction='row' justify='center' alignItems='center'>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <DropzoneArea
            style={{ height: '20rem', width: '20rem' }}
            filesLimit={1}
            showAlerts={['error', 'info']}
            onChange={!uploadDisabled ? handleChange : null}
            dropzoneText={!uploadDisabled ? 'Drag And Drop File Or Click' : 'Select Property/Tags and Type'}
            showAlerts={false}
            showFileNamesInPreview={true}
            maxFileSize={180000000}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
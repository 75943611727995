import 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  IconButton,
} from '@material-ui/core';
import UndoIcon from '@material-ui/icons/Undo';
import ScheduleIcon from '@material-ui/icons/Schedule';
var moment = require('moment');

export default function EditTimeStart(props) {
  const [ edited, setEdited ] = useState(false);
  const [ initialTime, setInitialTime ] = useState(null);
  const [ selectedTime, setSelectedTime ] = useState(null);
  const { retrieveStartTime, timeWindowStart, editSchedule } = props;
  const currentDateTime = moment();

  const handleTimeChange = time => {
    setSelectedTime(time);
  };

  const handleUndoChange = () => {
    setSelectedTime(initialTime);
  };

  useEffect(() => {
    let element = document.getElementsByClassName('MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal');
    element[1].style.marginTop='7px';
  }, []);

  useEffect(() => {
    if (timeWindowStart) {
      const timeWindowObj = moment(timeWindowStart, 'HH:mm a')
      setSelectedTime(timeWindowObj);
      setInitialTime(timeWindowObj);
    } else {
      setSelectedTime(currentDateTime);
      setInitialTime(currentDateTime);
    }
  }, []);

  useEffect(() => {
    const formattedInitialTime = moment(initialTime).format('HH:mm');
    const formattedSelectedTime = moment(selectedTime).format('HH:mm');
    
    //if there is a time saved for this value
    if (timeWindowStart) {
      //if selected doesnt equal the initial value
      if (formattedSelectedTime != formattedInitialTime) {
        //its been edited
        setEdited(true);
      } else {
        //it has not been edited
        setEdited(false);
      }
    //if no time was passed to the component (ie, none was previously saved)
    } else {
      //if the initial value (which should be right now) doesnt equal the selected time
      if (formattedInitialTime != formattedSelectedTime) {
        //its been edited
        setEdited(true)
      } else {
        //it has not been edited
        setEdited(false);
      }
    }
  }, [selectedTime]);

  useEffect(() => {
    const formattedSelectedTime = moment(selectedTime).format('HH:mm');
    if (formattedSelectedTime != 'Invalid date') {
      retrieveStartTime(formattedSelectedTime);
    }
  }, [selectedTime]);

  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ width: '85%' }}>
      <Grid item xs={10}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            disabled={!editSchedule}
            fullWidth
            margin="normal"
            id="time-picker"
            label="Est. Start Time"
            value={selectedTime}
            onChange={handleTimeChange}
            keyboardIcon={<ScheduleIcon />}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2}>
        { edited ?
          <IconButton style={{ marginTop: '18%' }} size='small' color="default" aria-label="edit" onClick={() => handleUndoChange()}>
            <UndoIcon />
          </IconButton>
        : null }
      </Grid>
    </Grid> 
  );
}
import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
    fontFamily:'Ebrima',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontFamily:'Ebrima',
  },
}));

const WARRANTY_TYPE = 2;
const APPEND_TO_TAG = 2;

export default function DocumentTypeSelect(props) {
  const [value, setValue] = useState(0);
  const {
    handleTypeSelect,
    fileTypes,
    document,
    typeDisabled,
    appendTo,
  } = props;

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value != previousValue) {
      handleTypeSelect(value);
    }
  }, [value]);

  useEffect(() => {
    if (document) {
      const { Id, FileType } = document;
      if (Id) {
        setValue(FileType);
      } else {
        setValue(0);
      }
    }
  }, [document]);

  const previousAppendTo = usePrevious(appendTo);
  useEffect(() => {
    if (appendTo != previousAppendTo) {
      if (document) {
        const { Id } = document;
        if (!Id) {
          setValue(0);
        }
      }
    }
  }, [appendTo]);

  const classes = useStyles();
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label-id" style={{fontFamily:'Ebrima'}} >Select Type</InputLabel>
          <Select
            disabled={typeDisabled}
            labelId="demo-simple-select-label-li"
            id="demo-simple-select-id"
            value={value}
            onChange={handleChange}
            style={{fontFamily:'Ebrima'}} 
          >
            <MenuItem value={0} style={{ height: '2rem', fontFamily:'Ebrima' }}></MenuItem>
            { fileTypes.map((fileType) => {
              const id = fileType.Id;
              const name = fileType.Name;
              const disableWarranty = appendTo == APPEND_TO_TAG && id == WARRANTY_TYPE ? true : false; 
              return(
                <MenuItem value={id} style={{fontFamily:'Ebrima'}} disabled={disableWarranty}>{ name }</MenuItem>
              )
            }) }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

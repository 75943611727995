import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    fontFamily: 'Ebrima', 
  },
  select: {
    backgroundColor: 'white',
    color: 'darkgrey',
    textAlign: 'center',
    fontFamily: 'Ebrima', 
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontFamily: 'Ebrima', 
  },
}));

export default function SimpleSelect(props) {
  const [value, setValue] = useState(-1);

  const { handleDispatchMin } = props;

  const handleChange = event => {
    setValue(event.target.value);
  };

  useEffect(() => {
    handleDispatchMin(value);
  }, [value]);

  const classes = useStyles();
  return (
    <div>
      <FormControl className={classes.formControl}>
         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
          className={classes.select} style={{ fontFamily: 'Ebrima', }}
          id="demo-simple-select"
          value={ value == -1 ? '' : value }
          // defaultValue={0}
          onChange={handleChange}
          inputprops={{
            shrink: true,
          }}
        >
        </TextField>
        <FormHelperText style={{ fontFamily: 'Ebrima', }}>Min</FormHelperText>
      </FormControl>
    </div>
  );
}

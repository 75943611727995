import React, { useState, useEffect, useRef } from 'react';
import EditSubmitterName from './EditSubmitterName';
import EditSubmitterEmail from './EditSubmitterEmail';
import EditSubmitterPhone from './EditSubmitterPhone';
import {
  Grid,
} from '@material-ui/core';

export default function EditAssigned(props) {
  const {
    retrieveWorkOrderSubmitterName,
    retrieveWorkOrderSubmitterEmail,
    retrieveWorkOrderSubmitterPhone,
    submitName,
    submitEmail,
    submitPhone,
    editSubmitter,
  } = props;

  return (
    <Grid id='yolo-2' container direction='row'>
      <Grid item xs={12}>
        <EditSubmitterName
          retrieveWorkOrderSubmitterName={retrieveWorkOrderSubmitterName}
          submitName={submitName}
          editSubmitter={editSubmitter}
        />
      </Grid>
      <Grid item xs={12}>
        <EditSubmitterEmail
          retrieveWorkOrderSubmitterEmail={retrieveWorkOrderSubmitterEmail}
          submitEmail={submitEmail}
          editSubmitter={editSubmitter}
        />
      </Grid>
      <Grid item xs={12}>
        <EditSubmitterPhone
          retrieveWorkOrderSubmitterPhone={retrieveWorkOrderSubmitterPhone}
          submitPhone={submitPhone}
          editSubmitter={editSubmitter}
        />
      </Grid>
    </Grid>
  );
}


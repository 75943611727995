import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '90%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddUrgencySelect(props) {
  const classes = useStyles();
  const [ urgencyId, setUrgencyId ] = useState(null);

  const { 
    retrieveSelectedWoUrgency,
    workOrderStatus,
    urgencyList 
  } = props;

  const handleChange = (e) => {
    const id = e.target.value;
    setUrgencyId(id);
  };

  useEffect(() => {
    if (urgencyId) {
      const urgency = urgencyList.find(urgency => urgency.UrgencyId == urgencyId);
      retrieveSelectedWoUrgency(urgency);
    } else {
      retrieveSelectedWoUrgency(null);
    }
  }, [urgencyId])

  return (
    <div>
      <FormControl disabled={ !workOrderStatus } variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-urgency-native-simple">Urgency</InputLabel>
        <Select
          native
          value={urgencyId}
          onChange={handleChange}
          inputprops={{
            urgencyId: 'urgencyId',
            id: 'filled-urgency-native-simple',
          }}
        >
          <option value="" />
          { urgencyList.map((urgency) => {
            const name = urgency.UrgencyName;
            const value = urgency.UrgencyId;
            return (
              <option value={value}>{name}</option>
            )
          }) }
        </Select>
      </FormControl>
    </div>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function AddExternalWorkOrderNumber(props) {
  const { retrieveExternalNumber } = props;
  const classes = useStyles();

  const handleChange = (e) => {
    const value = e.target.value;
    retrieveExternalNumber(value);
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <div>
         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
          onChange={handleChange}
          id="standard-basic"
          className={classes.textField}
          margin="normal"
          helperText='External Work Order'
        />
      </div>
    </form>
  );
}

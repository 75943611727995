import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { Grid } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import WarningIcon from '@material-ui/icons/Warning';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles({
  card: {
    width: '100%',
    boxShadow: 'none',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    fontFamily: 'Ebrima', 
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
    fontFamily: 'Ebrima', 
  },
  title: {
    fontSize: 14,
    fontFamily: 'Ebrima', 
  },
});

export default function PropertyGraphsHeaderCard(props) {
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const { selectedResult, mobile, property } = props;
  const classes = useStyles();

  //TODO: get state from dewayne for address
  useEffect(() => {
    if (property) {
      const propertyValues = Object.values(property);
      if (propertyValues.length > 0) {
        const { Name, Address1, Address2, City, Zip } = property;
        const address = `${Address1} ${Address2} ${City} ${Zip}`;
        setTitle(Name);
        setAddress(address);
      }
    }
  }, [property]);

  return (
    <Card square style={mobile ? { display: 'none' } : null} className={classes.card}>
      <CardContent>
        <Grid container direction='row' justify={'center'} alignItems={'center'}>
          <Grid item xs={12} style={{ textAlign: 'center', backgroundColor: '#66BFFA' }}>
            <Typography variant="h5" component="h2" style={{fontFamily:'Ebrima',fontFamily: 'Ebrima', }}>
              {title}
            </Typography>
            <Typography color="textSecondary" style={{fontFamily:'Ebrima',fontFamily: 'Ebrima', }}>
              {address}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

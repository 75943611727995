import React from 'react';
import { withGoogleMap, GoogleMap, Polyline, Polygon, Marker } from "react-google-maps";
import imgwarning from '../../../images/baseline_warning_amber_black_24dp.png';
import {
    Grid,
} from '@material-ui/core';

const getMapOptions = (maps) => {
    return {
        streetViewControl: false,
        scaleControl: true,
        fullscreenControl: false,
        data: {
            style: {
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2
            }
        },
        styles: [{
            featureType: "poi.business",
            elementType: "labels",
            stylers: [{
                visibility: "off"
            }]
        }],
        gestureHandling: "greedy",
        disableDoubleClickZoom: true,
        minZoom: 11,
        maxZoom: 23,
        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.SATELLITE,
        tilt: 0,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID
            ]
        },
        zoomControl: true,
        clickableIcons: false
    };
};

const createInventoryPoints = (points) => {
    const formattedPoints = [];
    points.map((point) => {
        const formattedPoint = {
            lat: point.Lat,
            lng: point.Lng
        }
        formattedPoints.push(formattedPoint);
    })
    return [...new Set(formattedPoints)];
};

const InventoryMap = withGoogleMap(props => {
    const {
        //Inventory
        selectedInventory,
        highlightedInventory,
        //centering
        propertyBounds,
        addressCoordinants,
    } = props;

    return (
        <GoogleMap
            options={getMapOptions(window.google.maps)}
            defaultZoom={8}
            ref={map => { map ? map.fitBounds(propertyBounds) : null }}
        >
            { selectedInventory ? selectedInventory.map((inventory, i) => {
                const inventoryId = inventory.InvId;

                //console.log("INVENTORY:" + inventory);

                const formattedPoints = createInventoryPoints(inventory.Points);
                if (formattedPoints.length > 1) {
                    return (
                        <Polyline
                            defaultPosition={addressCoordinants}
                            path={formattedPoints}
                            geodesic={true}
                            options={highlightedInventory == inventoryId ? { strokeColor: '#FFFF00', strokeOpacity: 1, strokeWeight: 6 } : { strokeColor: '#FF0000', strokeOpacity: 1, strokeWeight: 2 }}
                        />
                    )
                } else {
                    //console.log(inventory.WbsInvId)
                    if (inventory.wbsInvId == 654) {
                        return (
                            <Marker
                                animation={highlightedInventory == inventoryId ? '1' : '0'}
                                defaultAnimation={google.maps.Animation.DROP}
                                position={formattedPoints[0]}
                                options={{ icon: { url: imgwarning } }}>
                            </Marker>
                        )
                    } else {
                        return (
                            <Marker
                                animation={highlightedInventory == inventoryId ? '1' : '0'}
                                defaultAnimation={google.maps.Animation.DROP}
                                position={formattedPoints[0]}>
                            </Marker>
                        )
                    }
                }
            }) : null}
        </GoogleMap>
    )
});

export default function PropertyInventoryMap(props) {
    const {
        //inventory
        selectedInventory,
        highlightedInventory,
        //map centering
        addressCoordinants,
        sectionPerimeter,
        propertyBounds,
        //resizing
        mobile,
    } = props;

    const mapContainerElementStyle = {
        height: '100%',
        width: '100%'
    };

    const mapElementStyle = { height: '100%' };

    return (
        <Grid id='map' container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>
            <Grid item xs={12} style={{ height: '100%' }}>
                <InventoryMap
                    //Inventory
                    highlightedInventory={highlightedInventory}
                    sectionPerimeter={sectionPerimeter}
                    selectedInventory={selectedInventory}
                    //map centering
                    addressCoordinants={addressCoordinants}
                    propertyBounds={propertyBounds}
                    //required style props
                    containerElement={<div style={mapContainerElementStyle} />}
                    mapElement={<div style={mapElementStyle} />}
                />
            </Grid>
        </Grid>
    )
}
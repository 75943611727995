import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
    fontFamily: 'Ebrima'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontFamily: 'Ebrima'
  },
}));

export default function FromCompanySelect(props) {
  const [ fromSelectedCompany, setFromSelectedCompany ] = useState({});
  const {
    handleFromCompanySelect,
    userCompanies,
    movePropertyResponse,
  } = props;
  
  const handleChange = (e) => {
    const id = e.target.value;
    const company = userCompanies.find(company => company.CompanyId == id);
    if (company) {
      setFromSelectedCompany(company);
    } else {
      setFromSelectedCompany({});
    }
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousFromSelectedCompany = usePrevious(fromSelectedCompany);
  useEffect(() => {
    if (fromSelectedCompany != previousFromSelectedCompany) {
      const fromSelectedCompanyExists = Object.values(fromSelectedCompany).length;
      if (fromSelectedCompanyExists) {
        const { CompanyId } = fromSelectedCompany;
        if (CompanyId) {
          handleFromCompanySelect(fromSelectedCompany);
        } else {
          handleFromCompanySelect({});
        }
      } else {
        handleFromCompanySelect({});
      }
    }
  }, [fromSelectedCompany]);

  const previousPropertyResponse = usePrevious(movePropertyResponse);
  useEffect(() => {
    if (movePropertyResponse != previousPropertyResponse) {
      if (fromSelectedCompany) {
        const fromSelectedCompanyExists = Object.values(fromSelectedCompany).length;
        if (fromSelectedCompanyExists) {
          setFromSelectedCompany({});
        }
      }
    }
  }, [movePropertyResponse]);

  const classes = useStyles();
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">From Company</InputLabel>
          <Select
            native
            value={fromSelectedCompany.CompanyId ? fromSelectedCompany.CompanyId : ''}
            onChange={handleChange}
            inputprops={{
              name: 'age',
              id: 'age-native-simple',
              
            }}
          >
            <option aria-label="None" value=""/>
            { userCompanies ? userCompanies.map((company) => {
              const name = company.Name;
              const id = company.CompanyId;
              return (
                <option value={id}>{name}</option>
              )
            }) : null }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

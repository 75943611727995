import React, { useState, useEffect } from 'react';
import AddLeakResponseStepper from './Subcomponents/AddLeakResponseStepper';
import AddLeakResponseMobileStepper from './Subcomponents/AddLeakResponseMobileStepper';
import RequestLeakResponse from './Wizard/LeakResponseRequest/RequestLeakResponse';
import LeakResponseContacts from './Wizard/LeakResponseContacts/LeakResponseContacts';
import LeakResponseLocation from './Wizard/LeakResponseLocation/LeakResponseLocation';
import LeakResponseDocuments from './Wizard/LeakResponseDocuments/LeakResponseDocuments';
import LeakResponseReview from './Wizard/LeakResponseReview/LeakResponseReview';
import {
  Grid,
} from '@material-ui/core';

function AddLeakResponse(props) {
  const [width, setWidth] = useState(0);
  const [wizardStep, setWizardStep] = useState(0);
  const {
    handleIncrementWizardStep,
    handleEmergencySwitch,
    handleUpdateDescription,
    handleUpdateSelectedTag,
    handleUpdateSelectedBuilding,
    handleUpdateLocationDescription,
    handleUpdateLocationCoordinants,
    handleUpdateSelectedLocation,
    handleUpdateSelectedLocations,
    handleUpdateRemovedLocation,
    handleUpdateDocuments,
    handleRemovedDocument,
    handleUpdateComment,
    handleSubmitLeakResponse,
    handleNavigateToLeakResponseList,

    handleUpdateModifiedRequester,
    handleUpdateModifiedContact,
    handleUpdateStepTwoComplete,
    handleUpdateStepThreeComplete,

    leakResponse,
    editedLeakResponse,
    selectedWizardStep,
    propertyList,
    requesterIsUser,
    contactIsRequester,
    userTags,
    stepTwoComplete,
    stepThreeComplete,
    uploadProgress,
    selectedTag,
    selectedProperty,
    selectedDocuments,
    existingDocuments,
    leakResponseComments,
  } = props;

  const updateWindowDimensions = () => {
    const windowWidth = window.innerWidth;
    setWidth(windowWidth);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    }
  }, []);

  useEffect(() => {
    if (wizardStep != selectedWizardStep) {
      setWizardStep(selectedWizardStep);
    }
  }, [selectedWizardStep]);

  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ width: '100%' }}>
      <Grid item xs={12} style={{ padding: '1%' }}>
        {width > 1279 ?
          <AddLeakResponseStepper
            wizardStep={wizardStep}
          />
          :
          <AddLeakResponseMobileStepper
            wizardStep={wizardStep}
          />
        }
      </Grid>
      <Grid item xs={12}>
        {
          wizardStep == 0 ?
            <RequestLeakResponse
              handleIncrementWizardStep={handleIncrementWizardStep}
              handleEmergencySwitch={handleEmergencySwitch}
              handleUpdateDescription={handleUpdateDescription}

              leakResponse={leakResponse}
            />
            : wizardStep == 1 ?
              <LeakResponseContacts
                handleIncrementWizardStep={handleIncrementWizardStep}
                handleUpdateSelectedTag={handleUpdateSelectedTag}
                handleUpdateSelectedBuilding={handleUpdateSelectedBuilding}
                handleUpdateModifiedRequester={handleUpdateModifiedRequester}
                handleUpdateModifiedContact={handleUpdateModifiedContact}
                handleUpdateStepTwoComplete={handleUpdateStepTwoComplete}

                
                leakResponse={leakResponse}
                editedLeakResponse={editedLeakResponse}
                requesterIsUser={requesterIsUser}
                contactIsRequester={contactIsRequester}
                selectedTag={selectedTag}
                selectedProperty={selectedProperty}
                propertyList={propertyList}
                userTags={userTags}
                stepTwoComplete={stepTwoComplete}
              />
              : wizardStep == 2 ?
              <LeakResponseLocation
                handleIncrementWizardStep={handleIncrementWizardStep}
                handleUpdateStepThreeComplete={handleUpdateStepThreeComplete}
                handleUpdateLocationDescription={handleUpdateLocationDescription}
                handleUpdateLocationCoordinants={handleUpdateLocationCoordinants}
                handleUpdateSelectedLocation={handleUpdateSelectedLocation}
                handleUpdateSelectedLocations={handleUpdateSelectedLocations}
                handleUpdateRemovedLocation={handleUpdateRemovedLocation}

                leakResponse={leakResponse}
                editedLeakResponse={editedLeakResponse}
                stepThreeComplete={stepThreeComplete}
                width={width}
              />
            : wizardStep == 3 ?
              <LeakResponseDocuments
                handleIncrementWizardStep={handleIncrementWizardStep}
                handleUpdateDocuments={handleUpdateDocuments}
                handleRemovedDocument={handleRemovedDocument}

                leakResponse={leakResponse}
                selectedDocuments={selectedDocuments}
                existingDocuments={existingDocuments}
              />
            : wizardStep == 4 ?
              <LeakResponseReview
                handleIncrementWizardStep={handleIncrementWizardStep}
                editedLeakResponse={editedLeakResponse}
                handleSubmitLeakResponse={handleSubmitLeakResponse}
                uploadProgress={uploadProgress}
                handleNavigateToLeakResponseList={handleNavigateToLeakResponseList}
                handleUpdateComment={handleUpdateComment}

                leakResponse={leakResponse}
                selectedDocuments={selectedDocuments}
                existingDocuments={existingDocuments}
                leakResponseComments={leakResponseComments}
                width={width}
              />
            : null
        }

      </Grid>
    </Grid>
  )
}


export default AddLeakResponse
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useProjectPhaseStyles = makeStyles((theme) => ({
  loading: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-webkit-translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-moz-translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-ms-translate(-50%, -50%)',
  },
  autocompleteCombo: {
    minWidth: 180,
    width: 300,
    marginLeft: 10,
  },
  dragWrapper: {
    display: "flex",
    justifyContent: "left",
    height: "100%",
    overflowX: 'auto',
    width: '100%',
    borderRadius: '5px',
  },
  columnWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  divColumnWrapper: {
    margin: 8,
    backgroundColor: 'lightgrey',
  },
  h2Column: {
    marginLeft: 15,
  },
  editIcon: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  taskIcon: {
    position: 'absolute',
    right: '40px',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  autocompleteTextboxFilters: {
    '& input': {
      padding: '0 4px !important',
    },
    '& label': {
      transform: 'translate(14px,10px) scale(1)',
    }
  },
  strikeThrough: {
    textDecoration: 'line-through',
  },
  hidden: { display: 'none' },
  scrollBar: {
    '&::-webkit-scrollbar': {
      width: '0.2em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 2px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(69,108,134,0.8)',
      outline: '1px solid slategrey'
    }
  },
  filtersWrapper: {
    width: '100%',
    overflowX: 'scroll',
    display: 'flex',
    position: 'relative',
    top: '5px',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginBottom: '30px',
    borderBottom: '1px solid lightgrey',
    '&::-webkit-scrollbar': {
      width: '0.2em',
      height: '0.2em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 2px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(69,108,134,0.8)',
      outline: '1px solid slategrey'
    }
  },
}));

export default useProjectPhaseStyles;
import React, { useState, useEffect } from 'react';
import LeakResponseCommentList from './LeakResponseCommentList';
import {
  Card,
  Grid,
  Input,
  FormControl,
  FormHelperText,  
  Typography,
  IconButton,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
  },
}));

export default function ComposedTextField(props) {
  const [disableAdd, setDisableAdd] = useState(true);
  const [addClicked, setAddClicked] = useState(false);
  const [comment, setComment] = React.useState('');
  const {
    handleAddComment,

    leakResponse,
    leakResponseComments,
  } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    setComment(value);
  };

  const handleAddClick = () => {
    setAddClicked(true);
  };

  useEffect(() => {
    if (comment) {
      setDisableAdd(false);
    }
  }, [comment]);

  useEffect(() => {
    if (addClicked) {
      handleAddComment(comment);
      setComment('');
      setAddClicked(false);
      setDisableAdd(true);
    }
  }, [addClicked]);

  const classes = useStyles();

  return (
    <Card style={{ backgroundColor: 'whitesmoke', height: '22rem', width: '100%', marginTop: '1%' }}>
      <Grid container direction='row' style={{ padding: '1%', height: '100%' }}>
        <Grid item xs={12} style={{ height: '100%' }}>
          
          <Grid container direction='row' style={{ height: '10%' }}>
            <Typography variant='h6'>Add Comment:</Typography>
          </Grid>
          
          <Grid container direction='row' style={{ backgroundColor: 'lightGrey', height: '28%' }}>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl style={{ width: '98%' }}>
                <Input
                  id="component-helper"
                  value={comment}
                  onChange={handleChange}
                  aria-describedby="component-helper-text"
                  endAdornment={
                    <IconButton disabled={disableAdd} onClick={ () => handleAddClick() }>
                      <AddCircleIcon />
                    </IconButton>
                  }
                />
                <FormHelperText id="component-helper-text">Add A Comment To This Request</FormHelperText>
              </FormControl>
            </form>
          </Grid>

          <Grid container direction='row' style={{ height: '57%', marginTop: '1%', overflow: 'auto', overflow: 'auto' }}>
            <LeakResponseCommentList 
              leakResponse={leakResponse}
              leakResponseComments={leakResponseComments}
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

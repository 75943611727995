import React, { useState, useEffect, useRef } from 'react';
import PropertyGraphCarouselStepper from './PropertyGraphCarouselStepper';
import PropertyGraphsFinancialForecast from './PropertyGraphsFinancialForecast';
import PropertyDefectsGraph from './PropertyDefectsGraph';
import PropertyConditionGraph from './PropertyConditionGraph';
import PropertyRemainingLifeGraph from './PropertyRemainingLifeGraph';
import PropertyReportLoading from './SubComponents/PropertyReportLoading';
import PropertyTagList from '../PropertyTags/PropertyTagsList';
import HVACUnitsPerSection from './HVACUnitsPerSection';
import HVACMfgDist from './HVACMfgDist';
import HVACErslDist from './HVACErslDist';
import HVACCriticality from './HVACCriticalityDist';
import HVACGHGEmissions from './HVACGHGEmissions';
import {
  Paper, 
  Grid,
  Button,
  Divider,
  LinearProgress,
} from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SaveIcon from '@material-ui/icons/Save';


export default function PropertyGraphCarousel(props) {
  const [safeToClick, setSafeToClick] = useState(false);
  const [selectedGraph, setSelectedGraph] = useState(0);
  const [defectData, setDefectData] = useState([]);
  const [conditionData, setConditionData] = useState([]);
  const [forecastData, setForecastData] = useState([]);
  const [ERSLGraphData, setERSLData] = useState([]);

  const { 
    openDefectsWindow, 
    openConditionWindow, 
    openRemainingLifeWindow, 
    openFinancialForecastWindow,
    handleDispatchReport,
    selectedResult,
    property,
    selectedProperty,
    materialType,
    reportLoading,
    sowReportUrl,
    carReportUrl,
    sowReportLoading,
    carReportLoading,
  } = props;

  const handleIncrementGraph = (selectedStep) => {
    setSelectedGraph(selectedStep);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousProperty = usePrevious(property);
  useEffect(() => {
    if (property) {
      const defectCMCount = property.DefectCM.reduce((acc, defectCM) => acc + defectCM.Defects.length, 0);
      const defectRepairsCount = property.DefectRepairs.reduce((acc, defectRepairs) => acc + defectRepairs.Defects.length, 0);
      
      const adequate = property.WeightedRCSAdequate;
      const degraded = property.WeightedRCSDegraded;
      const unsatisfactory = property.WeightedRCSUnsatis;

      const forecast = property.Forecast;
      const ERSL = property.ERSL;

      const adequateCount = {
        amt: adequate,
        name: 'Adequate',
        ad: adequate,
      };
      
      const degradedCount = {
        amt: degraded,
        name: 'Degraded',
        de: degraded,
      }; 
      
      const unsatisfactoryCount = {
        amt: unsatisfactory,
        name: 'Deficient',
        un: unsatisfactory,
      };

      const condition = [adequateCount, degradedCount, unsatisfactoryCount];
      setConditionData(condition);

      const defects = {
        amt: defectCMCount,
        name: 'Defects',
        de: defectCMCount,
      };

      const repairs = {
        amt: defectRepairsCount,
        name: 'Repairs',
        re: defectRepairsCount,
      };

      const defectArray = [defects, repairs];
      setDefectData(defectArray);

      if (forecast) {
        const forecastArray = [];
        forecast.map((data) => {
          const forecastObj = {
            'AccDepFormatted': `$${new Intl.NumberFormat('en').format(data.OperatingCost)}`,
            'Accumulated': data.OperatingCost,
            'Funded': data.Capital,
            'FundedFormatted': `$${new Intl.NumberFormat('en').format(data.Capital)}`,
            'Scenario': "Target RCS",
            'Score': data.score,
            'Year': data.Year,
          }
          forecastArray.push(forecastObj);
        })
        setForecastData(forecastArray)
      } 

      if (!forecast) {
        setForecastData([]);
      }

      if (ERSL) {
        const {
          SixTenSects,
          SixTenTotal,
          TenPlusSects,
          TenPlusSectsTotal,
          TotalSF,
          TwoFiveSects,
          TwoFiveTotal,
          ZeroOneSects,
          ZeroOneTotal,
        } = ERSL;

        const ERSLData = [
          {
            amt: 100*(ZeroOneTotal/TotalSF),
            name: '0-1',
          }, {
            amt: 100*(TwoFiveTotal/TotalSF),
            name: '2-5',
          }, {
            amt: 100*(SixTenTotal/TotalSF),
            name: '6-10',
          }, {
            amt: 100*(TenPlusSectsTotal/TotalSF),
            name: '10+',
          }
        ];
        setERSLData(ERSLData);
      } else {
        setERSLData([]);
      }
    }
  }, [property]);

  useEffect(() => {
    if (selectedProperty) {
      setSafeToClick(true);
    } else {
      setSafeToClick(false);
    }
  }, [selectedProperty]);

  return(
    <Paper style={{ height: '100%', width: '100%', boxShadow: 'none', padding: '1%' }}>
      <Grid container direction="row" alignItems={'center'} justify={'center'}>
        <Grid item xs={12} sm={10} md={8} lg={12} xl={12}>
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={12}>
                          {
                              materialType != 6 ?
                                  (selectedGraph == 0 ?
                                      <PropertyGraphsFinancialForecast
                                          property={property}
                                          selectedResult={selectedResult}
                                          selectedProperty={selectedProperty}
                                          openFinancialForecastWindow={openFinancialForecastWindow}
                                          financialForecastData={forecastData}
                                          materialType={materialType}
                                      />
                                      : selectedGraph == 1 ?
                                          <PropertyDefectsGraph
                                              property={property}
                                              selectedProperty={selectedProperty}
                                              openDefectsWindow={openDefectsWindow}
                                              defectData={defectData}
                                          />
                                          : selectedGraph == 2 ?
                                              <PropertyConditionGraph
                                                  property={property}
                                                  selectedProperty={selectedProperty}
                                                  openConditionWindow={openConditionWindow}
                                                  conditionData={conditionData}
                                                  defectData={defectData}
                                              />
                                              : selectedGraph == 3 ?
                                                  <PropertyRemainingLifeGraph
                                                      property={property}
                                                      selectedProperty={selectedProperty}
                                                      openRemainingLifeWindow={openRemainingLifeWindow}
                                                      ERSLGraphData={ERSLGraphData}
                                                  />
                                                  : null)
                                  //HVAC GRAPHS

                                  : (selectedGraph == 0 ?
                                      <PropertyGraphsFinancialForecast
                                          property={property}
                                          selectedResult={selectedResult}
                                          selectedProperty={selectedProperty}
                                          openFinancialForecastWindow={openFinancialForecastWindow}
                                          financialForecastData={forecastData}
                                          materialType={materialType}
                                      />
                                      : selectedGraph == 1 ?
                                          <HVACUnitsPerSection
                                              property={property}
                                              selectedProperty={selectedProperty}
                                              openDefectsWindow={openDefectsWindow}
                                              SectHVACCntInfo={selectedProperty.SectHVACCntInfo}
                                          />
                                          : selectedGraph == 2 ?
                                              <HVACMfgDist
                                                  property={property}
                                                  selectedProperty={selectedProperty}
                                                  openDefectsWindow={openDefectsWindow}
                                                  HVACMfgCount={selectedProperty.HVACMfgCount}
                                              />
                                              : selectedGraph == 3 ?
                                                  <HVACErslDist
                                                      property={property}
                                                      selectedResult={selectedResult}
                                                      selectedProperty={selectedProperty}
                                                      openFinancialForecastWindow={openFinancialForecastWindow}
                                                      financialForecastData={forecastData}
                                                      HVACErslDist={selectedProperty.HVACErslDist}
                                                      materialType={materialType}
                                                  />
                                                  : selectedGraph == 4 ?
                                                      <HVACCriticality
                                                          property={property}
                                                          selectedResult={selectedResult}
                                                          selectedProperty={selectedProperty}
                                                          openFinancialForecastWindow={openFinancialForecastWindow}
                                                          financialForecastData={forecastData}
                                                          materialType={materialType}
                                                          HVACCriticalityDist={selectedProperty.HVACCriticalityDist}
                                                      />
                                                      : selectedGraph == 5 ?
                                                          <HVACGHGEmissions
                                                              property={property}
                                                              selectedResult={selectedResult}
                                                              selectedProperty={selectedProperty}
                                                              openFinancialForecastWindow={openFinancialForecastWindow}
                                                              financialForecastData={forecastData}
                                                              materialType={materialType}
                                                              HVACGHGEmissions={selectedProperty.ghgUnitList}
                                                          />
                                                          : null)
                          }
            </Grid>
          </Grid>

          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={12}>
              <PropertyGraphCarouselStepper
                              safeToClick={safeToClick}
                              handleIncrementGraph={handleIncrementGraph}
                              materialType={materialType}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <PropertyTagList
                property={property}
              />
            </Grid> */}
          </Grid>          
        </Grid>
        <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: 'aliceblue', marginTop: '1%' }}>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <Button disabled={!property} onClick={!sowReportUrl ? () => handleDispatchReport(2) : () => window.open(sowReportUrl) } size='small'>
              <a style={{ color: 'rgb(31, 89, 152)', padding: '3px' }}> 
                { sowReportLoading ? <PropertyReportLoading /> : sowReportUrl ? 'Download SOW' : 'Quick SOW' } 
              </a>
            </Button>
          </Grid>
          <Divider />
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <Button disabled={!property} onClick={!carReportUrl ? () => handleDispatchReport(3) : () => window.open(carReportUrl) } size='small'>
              <a style={{ color: 'rgb(31, 89, 152)', padding: '3px' }}> 
                { carReportLoading ? <PropertyReportLoading /> : carReportUrl ? 'Download CAR' : 'Quick CAR' } 
              </a>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
};





import React, { useEffect, useState } from 'react';
import {
  MobileStepper,
  Button,
} from '@material-ui/core';
import {
  makeStyles,
  useTheme
} from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

const stepperStyle = {
  backgroundColor: 'rgb(233,233,233)', 
  borderRadius: '2%',
};

export default function PropertyDefectsCardImagesStepper(props) {
  const { numberOfImages, setActiveImage } = props;
  const [activeStep, setActiveStep] = useState(0);

  const classes = useStyles();
  const theme = useTheme();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  useEffect(() => {
    setActiveImage(activeStep);
  }, [activeStep])


  return (
    <MobileStepper
      style={ stepperStyle }
      variant={ 'text' }
      steps={numberOfImages}
      position="static"
      activeStep={activeStep}
      className={classes.root}
      nextButton={
        <Button size="small" onClick={handleNext} disabled={activeStep == numberOfImages - 1} style={{fontFamily: 'Ebrima',}}>
          Next
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0} style={{fontFamily: 'Ebrima',}}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          Back
        </Button>
      }
    />
  )
};
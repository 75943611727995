import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import {
  List,
  Collapse,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PropertySectionsIntPerimeter(props) {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const { interiorPerimeter } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  }

  return (
    <Grid container direction={'row'} alignItems={'flex-end'} justify={'flex-start'} style={{ marginBottom: '5%' }}>
      <Grid direction={'row'} style={{ display: 'flex', marginLeft: '2%' }}>
        <Grid item xs={2} style={{ display: 'flex', marginTop: '1%' }}>
          <FullscreenExitIcon fontSize={'default'} />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h6" style={{ fontSize: '18px' }}>Interior Perimeter</Typography>
        </Grid>
        <Grid item xs={1} style={{ display: 'flex' }}>
          <IconButton
            size={'small'}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={expanded}>
          {/* <List>
            { interiorPerimeter.map((perimeter, i) => {
              return (
                <Grid key={i} container direction='row' alignItems={'center'} justify={'center'} style={{ width: '100%' }}>
                  <Grid item xs={6} style={{ textAlign: 'center' }}>
                    <Typography>Perimeter {i + 1}: </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                  </Grid>
                </Grid>
              )
            }) }
          </List> */}
        </Collapse>
      </Grid>
    </Grid>
  );
}

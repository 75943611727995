import React from 'react';
import {
  Grid,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';

export default function PropertyHeaderCard(props) {
  const {
    jj
  } = props;
  console.log(jj);
  return (
  <Grid container direction='row' alignItems='center' justify='center'>
    <Grid item xs={12} style={{ display: 'flex' }}>
      <CircularProgress size={'1.3rem'} thickness={6} style={{ alignSelf: 'baseline' }} />
    </Grid>
  </Grid>
  );
}

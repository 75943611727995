import React, { useState, useEffect, useRef } from 'react';
import EditAssigned from './EditAssigned';
import EditContractor from './EditContractor';
import EditSubmitter from './EditSubmitter';
import {
  Grid,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';

const selectedStyle = { backgroundColor: '#66BFFA', color: 'white', fontWeight: 'bold', width: '100%' };
const unselectedStyle = { width: '100%' };

export default function FullWidthTabs(props) {
  const [value, setValue] = useState(0);

  const {
    //Container 
    saveContractorData,
    saveSubmitterData,
    saveAssignedData,
    setEditContractor,
    setEditSubmitter,
    setEditAssigned,
    saveContractor,
    saveSubmitter,
    saveAssigned,
    //Contractor
    retrieveWorkOrderContractorName,
    retrieveWorkOrderContractorEmail,
    retrieveWorkOrderContractorPhone,
    contractorName,
    contractorEmail,
    contractorPhone,
    editContractor,
    //Submitter
    retrieveWorkOrderSubmitterName,
    retrieveWorkOrderSubmitterEmail,
    retrieveWorkOrderSubmitterPhone,
    submitName,
    submitEmail,
    submitPhone,
    editSubmitter,
    //Assigned
    retrieveWorkOrderAssignedName,
    editAssigned,
    assignedToName,
    retrieveWorkOrderAssignedEmail,
    assignedToEmail,
    retrieveWorkOrderAssignedPhone,
    assignedToPhone,
  } = props;

  const handleChange = (selected) => {
    setValue(selected);
  };
 
  return (
      <Grid container>
        <Grid container direction='row' justify='space-between' alignItems='center'>  
          <Grid item xs={4} style={{ height: '100%' }}>
            <Button color='primary' variant={ value == 0 ? 'outlined' : null } style={ value == 0 ? selectedStyle : unselectedStyle } onClick={()=>handleChange(0)}>Contractor</Button>
          </Grid>
          <Grid item xs={4} style={{ height: '100%' }}>
            <Button color='primary' variant={ value == 1 ? 'outlined' : null} style={ value == 1 ? selectedStyle : unselectedStyle } onClick={()=>handleChange(1)}>Submitter</Button>
          </Grid>
          <Grid item xs={4} style={{ height: '100%' }}>
            <Button color='primary' variant={ value == 2 ? 'outlined' : null} style={ value == 2 ? selectedStyle : unselectedStyle } onClick={()=>handleChange(2)}>Assigned</Button>
          </Grid>
        </Grid>
        { 
          value == 0 ?
            <Grid container direction='row'>
              <Grid item xs={10}>
                <Typography variant='h6' style={{ margin: '2%' }}>Contractor:</Typography>
              </Grid>
              <Grid item xs={2}>
                { saveContractor ?
                  <IconButton onClick={() => saveContractorData()}>
                    <GetAppIcon />
                  </IconButton>
                  :
                  <IconButton onClick={() => setEditContractor()}>
                    <EditIcon />
                  </IconButton>
                }
              </Grid>
              <EditContractor
                retrieveWorkOrderContractorName={retrieveWorkOrderContractorName}
                retrieveWorkOrderContractorEmail={retrieveWorkOrderContractorEmail}
                retrieveWorkOrderContractorPhone={retrieveWorkOrderContractorPhone}
                contractorName={contractorName}
                contractorEmail={contractorEmail}
                contractorPhone={contractorPhone}
                editContractor={editContractor}
              />
            </Grid>
          : 
          value == 1 ?
            <Grid container direction='row'>
              <Grid item xs={10}>
                <Typography variant='h6' style={{ margin: '2%' }}>Submitter:</Typography>
              </Grid>
              <Grid item xs={2}>
                { saveSubmitter ?
                  <IconButton onClick={() => saveSubmitterData()}>
                    <GetAppIcon />
                  </IconButton>
                  :
                  <IconButton onClick={() => setEditSubmitter()}>
                    <EditIcon />
                  </IconButton>
                }
              </Grid>
              <EditSubmitter 
                retrieveWorkOrderSubmitterName={retrieveWorkOrderSubmitterName}
                retrieveWorkOrderSubmitterEmail={retrieveWorkOrderSubmitterEmail}
                retrieveWorkOrderSubmitterPhone={retrieveWorkOrderSubmitterPhone}
                submitName={submitName}
                submitEmail={submitEmail}
                submitPhone={submitPhone}
                editSubmitter={editSubmitter}
              />
            </Grid>
          :
          value == 2 ? 
            <Grid container direction='row'>
              <Grid item xs={10}>
                <Typography variant='h6' style={{ margin: '2%' }}>Assigned:</Typography>
              </Grid>
              <Grid item xs={2}>
                { saveAssigned ?
                  <IconButton onClick={() => saveAssignedData()}>
                    <GetAppIcon />
                  </IconButton>
                  :
                  <IconButton onClick={() => setEditAssigned()}>
                    <EditIcon />
                  </IconButton>
                }
              </Grid>
              <EditAssigned
                retrieveWorkOrderAssignedName={retrieveWorkOrderAssignedName}
                retrieveWorkOrderAssignedEmail={retrieveWorkOrderAssignedEmail}
                retrieveWorkOrderAssignedPhone={retrieveWorkOrderAssignedPhone}
                assignedToName={assignedToName}
                assignedToEmail={assignedToEmail}
                assignedToPhone={assignedToPhone}
                editAssigned={editAssigned}
              />
            </Grid>
          :
          null 
        }
      </Grid>
     
  );
}

import React, { useState, useEffect, useRef } from 'react';
import {
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    width: '95%',
    fontFamily: 'Ebrima',
  },
}));

export default function InputWithIcon(props) {
  const [value, setValue] = useState('');
  const { 
    retrieveSearchText,
    selectedReportType,
   } = props;

  const handleChange = (e) => {
    const incomingText = e.target.value;
    setValue(incomingText);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    retrieveSearchText(value);
  }, [value]);

  const previousReportType = usePrevious(selectedReportType);
  useEffect(() => {
    if (selectedReportType != previousReportType) {
      setValue('');
    }
  }, [selectedReportType]);

  const classes = useStyles();
  return (
    <div>
       <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
        className={classes.margin}
        id="input-with-icon-textfield"
        onChange={handleChange}
        value={value}
        inputprops={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

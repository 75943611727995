import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  Modal,
} from '@material-ui/core';

export default function WorkOrderDetailsModal(props) {
  const {
    handleDetailsClose,
    workOrderDetailsModalOpen,
  } = props;

  const handleModalBlur = ({ nativeEvent }) => {
    const targetId = nativeEvent.target.id;
    if (targetId == 'details-container') {
      handleDetailsClose();
    }
  };

  return (
    <Modal open={workOrderDetailsModalOpen}>
      <div id={'details-container'} style={{ width: '100%', height: '100%' }} onClick={handleModalBlur}>
        <Grid container>
          <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
            <Grid item xs={10}>
              <Paper id='paper' style={{ position: 'absolute', height: '75%', width: '75%', marginTop: '7%', marginLeft: '5%', marginRight: '5%', marginBottom: '7%', backgroundColor: 'rgb(246, 243, 235)' }}>
                oh joy, work order details modal
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
} 
import React, { useState, useEffect, useRef } from 'react';
import PropertyMediaStepper from './Subcomponents/PropertyMediaStepper';
import PropertyMediaContent from './Subcomponents/PropertyMediaContent';
import {
  Grid, 
} from '@material-ui/core';

export default function ProperyMedia(props) {
  const [selectedMedia, setSelectedMedia] = useState(0);
  const [propertyBounds, setPropertyBounds] = useState({});
  const [addressCoordinants, setAddressCoordinants] = useState({});
  const [sections, setSections] = useState([]);
  const {
    selectedProperty,
    selectedResult,
    property,
    aerialMapURL,
    mobile,
  } = props;

  const handleSwitchSelectedMedia = (mediaId) => {
    setSelectedMedia(mediaId);
  };

  const createBounds = (sections) => {
    const bounds = new google.maps.LatLngBounds();

    sections.map((section, i) => {
      const extPerimeters = section.ExteriorPerimeter;
      extPerimeters.map((extPerimeter, i) => {
        const perimetersPoints = extPerimeter.points;
        perimetersPoints.map((perimeterPoint, i) => {
          const lat = parseFloat(perimeterPoint.Lat);
          const lng = parseFloat(perimeterPoint.Lng);
          if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
            if (lat != 0 && lng != 0) {
              const latLng = { lat, lng };
              bounds.extend(latLng);
            }
          }
        })
      })
    })
    return bounds;
  };

  useEffect(() => {
    if (selectedProperty) {
      const propertySections = selectedProperty.Sections;
      const sortedPropertySections = [...propertySections.sort((a, b) => a.Name.localeCompare(b.Name))];
      setSections(sortedPropertySections);
      const bounds = createBounds(sortedPropertySections);
      setPropertyBounds(bounds);
    }
  }, [selectedProperty]);

  useEffect(() => {
    const addressCoordinants = {
      lat: parseFloat(selectedResult.Lat),
      lng: parseFloat(selectedResult.Lng),
    };

    if (!isNaN(addressCoordinants.lat) && !isNaN(addressCoordinants.lng)) {
      setAddressCoordinants(addressCoordinants);
    }
  }, [selectedResult]);

  return (
    // <Grid id='media-row' container direction='row' alignItems='center' style={{ display: 'flex', flexDirection: 'column' }}>
    <Grid id='media-row' container direction='row' alignItems='center' style={{ height: '100%', display: 'table' }}>
      <Grid item xs={12} style={{ display: 'table-header-group' }}>
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid id='media-stepper-item' item xs={12}>
                      <PropertyMediaStepper
                          property={property}
              handleSwitchSelectedMedia={handleSwitchSelectedMedia}
              mobile={mobile}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ display: 'table-row' }}>
      {/* <Grid item xs={12} style={{ display: 'table-row', flexGrow: 1 }}> */}
        {/* !!!SUSPECT STYLING - FREEZE BUG!!! TRY TO AVOID SETTING HEIGHT TO 100% */}
        <Grid container direction='row' alignItems='center' justify='center' style={{ display: 'table-cell' }}>
          {/* <Grid id='media-content-item' item xs={12} style={{ flexGrow: 1 }}> */}
          {/* you CANNOT pull out the image filters from within mediaContent and make them a sibling */}
           
            <PropertyMediaContent
              property={property}
              aerialMapURL={aerialMapURL}
              selectedMedia={selectedMedia}
              sections={sections}
              addressCoordinants={addressCoordinants}
              propertyBounds={propertyBounds}
              selectedResult={selectedResult}
              selectedProperty={selectedProperty}
              mobile={mobile}
            />
             <div>&nbsp;</div>
          {/* </Grid> */}
        </Grid>
      </Grid>   
    </Grid>
  );
}

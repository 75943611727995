import React from 'react';
import {
  Typography, 
  Card,
  CardContent,
  Button,
  Icon,
} from '@material-ui/core';
import SidebarDisplayFilterStyle from '../../../styles/SidebarDisplayFilterStyle';
export default function SidebarDisplayFilter(props) {
  const classes = SidebarDisplayFilterStyle();
  
  const {
    card,
    cardMedia
  } = classes;

  const { 
    selectedCompany,
    selectedMaterial,
    selectedTags,
    siteFilterSelect
  } = props;

  return (
              <Card className={classes.card} onClick={ siteFilterSelect }>
                <CardContent>
                <Button size="small" onClick={ siteFilterSelect } style={{fontFamily: 'Ebrima', }}><Icon>assessment</Icon>Site Filter</Button>
                  <Typography className={classes.pos} color="textPrimary">
                    {selectedMaterial} -  {selectedCompany}
                  </Typography>
                  <Typography variant="body2" component="p" className={classes.body2}>
                    Tags: { selectedTags ? selectedTags.map(tag => "#" + tag + " ") : "no tags" }
                  </Typography>
                </CardContent>

              </Card>   
  );
}
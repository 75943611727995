import React, { useState, useEffect } from 'react';
import {
  Grid,
  Slider,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

export default function DiscreteSlider(props) {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [selectedPageSize, setSelectedPageSize] = useState(0);

  const { recordCount, selectPageSize, retrieveResultSize} = props;

  const handleResultSize = (event, newValue) => {
    setSelectedPageSize(newValue);
    selectPageSize(newValue);
    //retrieveResultSize(newValue);
  };

  const PrettoSlider = withStyles({
    root: {
      color: '#0672BA',
      height: 4,
      fontFamily: 'Ebrima',
    },
    thumb: {
      height: 12,
      width: 12,
      backgroundColor: '#0672BA',
      border: '2px solid currentColor',
      marginTop: -4,
      marginLeft: -6,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 4,
      borderRadius: 2,
    },
    rail: {
      height: 4,
      borderRadius: 2,
    },
  })(Slider);

  useEffect(()=>{
    if (recordCount) {
      setMax(recordCount);
    }
  }, [recordCount]);

  useEffect(() => {
    if (recordCount > 10) {
      setMin(10);
    } else {
      recordCount ? setMin(1) : setMin(0);
    }
  }, [max]);

  console.log("what selectedPageSize" + selectedPageSize);

  return (
    
    <Grid container direction='row' justify={'center'} alignItems={'center'}>
      <Grid item xs={0} sm={0} md={0} lg={2} xl={1} style={{ textAlign: 'center' }}> {min} </Grid>
        <Grid item xs={5} sm={6} md={8} lg={6} xl={10} style={{ marginTop: '1%' }}>
          <PrettoSlider
            onChangeCommitted={ handleResultSize }
            defaultValue={10}
            value={selectedPageSize}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={ 10 }
            min={ min }
            max={ max }
            disabled={ recordCount <= 10 ? true : false }
          />
        </Grid>
      <Grid item xs={0} sm={0} md={0} lg={2} xl={1} style={{ textAlign: 'center' }}> {max} </Grid>
    </Grid>

  );
}

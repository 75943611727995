import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    fontFamily: 'Ebrima',
  },
  tableWrapper: {
    maxHeight: '100%',
    overflow: 'auto',
    fontFamily: 'Ebrima',
  },
});

const forceSticky = { position: 'sticky', top: '0', zIndex: '10', backgroundColor: 'white', fontFamily: 'Ebrima', };

export default function PropertyFinancialForecast(props) {
  const [ zeroOne, setZeroOne ] = useState([]);
  const [ twoFive, setTwoFive ] = useState([]);
  const [ sixTen, setSixTen ] = useState([]);
  const [ tenPlus, setTenPlus ] = useState([]);

  const classes = useStyles();
  
  const {
    property,
    materialType,
  } = props;

  useEffect(() => {
    if (property) {
      const propertyExists = Object.values(property).length;
      if (propertyExists) {
        const {
          ERSL,
        } = property;

        if (ERSL) {
          const {
            ZeroOneSects,
            TwoFiveSects,
            SixTenSects,
            TenPlusSects,
          } = ERSL;
          setZeroOne(ZeroOneSects);
          setTwoFive(TwoFiveSects);
          setSixTen(SixTenSects);
          setTenPlus(TenPlusSects);
        }
      }
    }
  }, [property]);

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={forceSticky}>Section</TableCell>
              <TableCell align="right" style={forceSticky}>Square Feet</TableCell>
                          <TableCell align="right" style={forceSticky}>{materialType == 1 ? 'RCS' : (materialType == 6 ? 'Criticality' : 'PCI') }</TableCell>
              <TableCell align="right" style={forceSticky}>ERSL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            { zeroOne ? zeroOne.length > 0 ? zeroOne.map((data) => {
              const rcsColor = data.RCSColor;
              return (
                <TableRow key={data.Name}>
                  <TableCell style={{fontFamily: 'Ebrima',}}>{data.Name}</TableCell>
                  <TableCell align="right">{`${new Intl.NumberFormat('en').format(data.SF)}`}<span style={{color:'rgba(0, 0, 0, 0.54)', fontFamily: 'Ebrima',}}> (Sq Ft)</span></TableCell>
                  <TableCell align="right"><Chip style={{ backgroundColor: rcsColor, fontWeight: 'bold', fontFamily: 'Ebrima', }} label={`${new Intl.NumberFormat('en').format(data.RCS)}`} /></TableCell>
                  <TableCell align="right">{`${new Intl.NumberFormat('en').format(data.ERSL)}`}<span style={{ color: 'rgba(0, 0, 0, 0.54)', fontFamily: 'Ebrima', }}> (Yrs)</span></TableCell>
                </TableRow>
              )
            }) : null : null }

            { twoFive ? twoFive.length > 0 ? twoFive.map((data) => {
              const rcsColor = data.RCSColor;
              return (
                <TableRow key={data.Name} >
                  <TableCell>{data.Name}</TableCell>
                  <TableCell align="right">{`${new Intl.NumberFormat('en').format(data.SF)}`}<span style={{ color: 'rgba(0, 0, 0, 0.54)', fontFamily: 'Ebrima', }}> (Sq Ft)</span></TableCell>
                  <TableCell align="right"><Chip style={{ backgroundColor: rcsColor, fontWeight: 'bold', fontFamily: 'Ebrima', }} label={`${new Intl.NumberFormat('en').format(data.RCS)}`} /></TableCell>
                  <TableCell align="right">{`${new Intl.NumberFormat('en').format(data.ERSL)}`}<span style={{ color: 'rgba(0, 0, 0, 0.54)', fontFamily: 'Ebrima', }}> (Yrs)</span></TableCell>
                </TableRow>
              )   
            }) : null : null }
            { sixTen ? sixTen.length > 0 ? sixTen.map((data) => {
              const rcsColor = data.RCSColor;
              return (
                <TableRow key={data.Name} >
                  <TableCell style={{fontFamily: 'Ebrima',}}>{data.Name}</TableCell>
                  <TableCell align="right">{`${new Intl.NumberFormat('en').format(data.SF)}`}<span style={{ color: 'rgba(0, 0, 0, 0.54)', fontFamily: 'Ebrima', }}> (Sq Ft)</span></TableCell>
                  <TableCell align="right"><Chip style={{ backgroundColor: rcsColor, fontWeight: 'bold', fontFamily: 'Ebrima', }} label={`${new Intl.NumberFormat('en').format(data.RCS)}`} /></TableCell>
                  <TableCell align="right">{`${new Intl.NumberFormat('en').format(data.ERSL)}`}<span style={{ color: 'rgba(0, 0, 0, 0.54)', fontFamily: 'Ebrima', }}> (Yrs)</span></TableCell>
                </TableRow>
              )   
            }) : null : null }
            { tenPlus ? tenPlus.length > 0 ? tenPlus.map((data) => {
              const rcsColor = data.RCSColor;
              return (
                <TableRow key={data.Name} >
                  <TableCell>{data.Name}</TableCell>
                  <TableCell align="right">{`${new Intl.NumberFormat('en').format(data.SF)}`}<span style={{ color: 'rgba(0, 0, 0, 0.54)', fontFamily: 'Ebrima', }}> (Sq Ft)</span></TableCell>
                  <TableCell align="right"><Chip style={{ backgroundColor: rcsColor, fontWeight: 'bold', fontFamily: 'Ebrima', }} label={`${new Intl.NumberFormat('en').format(data.RCS)}`} /></TableCell>
                  <TableCell align="right">{`${new Intl.NumberFormat('en').format(data.ERSL)}`}<span style={{ color: 'rgba(0, 0, 0, 0.54)', fontFamily: 'Ebrima', }}> (Yrs)</span></TableCell>
                </TableRow>
              )    
        }) : null : null }

          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}



import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  IconButton,
} from '@material-ui/core';
import UndoIcon from '@material-ui/icons/Undo';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    margin: theme.spacing(1),
    marginBottom: '0'
  },
  textField: {
    width: '95%',
  }
}));

export default function EditSubmitterName(props) {
  const classes = useStyles();
  const [ edited, setEdited ] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState(null);

  const {
    retrieveWorkOrderSubmitterName,
    editSubmitter,
    submitName,
  } = props;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const handleChange = (e) => {
    const text = e.target.value;
    setName(text);
  };

  const handleUndoChange = () => {
    setName(submitName);
    setEdited(false);
  };

  useEffect(() => {
    setName(submitName);
  }, []);

  useEffect(() => {
    if (editSubmitter) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [editSubmitter]);

  const prevName = usePrevious(name);
  useEffect(() => {
    if (name && prevName) {
      if (name != prevName) {
        retrieveWorkOrderSubmitterName(name);
      }

      if (name != submitName) {
        setEdited(true);
      } else {
        setEdited(false);
      }
    }
  }, [name]);

  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={10}>
        <form className={classes.container} noValidate autoComplete="off">
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            disabled={disabled}
            value={name}
            className={classes.textField}
            onChange={handleChange}
            id="standard-basic"
            className={classes.textField}
            margin="normal"
            helperText='Contractor Name'
          />
        </form>
      </Grid>
      <Grid item xs={2}>
        { edited && !disabled ? 
          <IconButton onClick={handleUndoChange}>
            <UndoIcon />
          </IconButton>
        : null }
      </Grid>
    </Grid>
    
  );
}


import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    fontFamily: 'Ebrima',
  },
}));

export default function PropertyTagsList(props) {
  const [ orgUnits, setOrgUnits ] = useState([]);
  const {
    property,
  } = props;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousProperty = usePrevious(property);
  useEffect(() => {
    if (property) {
      if (property != previousProperty) {
        const propertyExists = Object.values(property).length;
        if (propertyExists) {
          const {
            OrgUnits,
          } = property;
          if (OrgUnits != orgUnits) {
            setOrgUnits(OrgUnits);
          }
        }
      }
    }
  }, [property]);

  const classes = useStyles();

  return (
      <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: 'white', minHeight: '2.84rem' }}>
        <Grid item xs={12}>
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={3}>
              <Grid container direction='row' alignItems='center' justify='center'>
                <Grid item xs={12} style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.54)', fontFamily: 'Ebrima', }}>
                  Property Tags
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9} style={{ overflowX: 'auto' }}>
              {orgUnits.map((orgUnit) => {
                return (
                    <Chip
                        key={orgUnit}
                    label={orgUnit}
                    className={classes.chip}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}

import React, { useEffect, useState, useReducer, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardHeader,
    Avatar,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: 'none',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

export default function PropertyInventoryGroupCard() {
    const classes = useStyles();

    return (
        <Card key={0} square className={classes.card}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        <ErrorIcon />
                    </Avatar>
                }
                title={'No Inventory'}
            />
        </Card>
    );
}

import React, { Component } from 'react';
import PropertyHeaderCard from './SubComponents/PropertyHeaderCard';
import PropertyLoading from './SubComponents/PropertyLoading';
import PropertyMedia from './PropertyMedia/PropertyMedia';
import PropertyGraphs from './PropertyGraphs/PropertyGraphs';
import PropertySections from './PropertySections/PropertySections';
import PropertyContacts from './PropertyContacts/PropertyContacts';
import PropertyDocuments from './PropertyDocuments/PropertyDocuments';
import PropertyExecutiveSummary from './PropertyExecutiveSummary/PropertyExecutiveSummary';
import PropertyConditionModal from './PropertyCondition/PropertyConditionModal';
import PropertyRemainingLifeModal from './PropertyRemainingLife/PropertyRemainingLifeModal';
import PropertyFinancialForecastModal from './PropertyFinancialForecast/PropertyFinancialForecastModal';
import PropertyTagList from './PropertyTags/PropertyTagsList';
import PropertyWarranties from './PropertyWarranties/PropertyWarranties';
import PropertyWarrantySnackBar from './SubComponents/PropertyWarrantySnackBar';
import dotnetify from 'dotnetify';
import auth from '../../auth';
import {
  Grid,
  Paper,
  Button,
  Fab,
} from '@material-ui/core';

class Property extends Component {
  constructor(props) {
    super(props)
    this.vm = dotnetify.react.connect('Property', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
         auth.signOut();
      }
    });
    this.dispatch = state => this.vm.$dispatch(state);

    this.state = {
      selectedProperty: null,
      defectsWindowOpen: null,
      localLoading: true,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { 
      localLoading,
      selectedProperty, 
    } = this.state;

    const { 
      property, 
      selectedResult 
    } = this.props;
    const previousProperty = prevProps.property;

    if (property) {
      if (selectedResult) {
        const propertyId = property.Id;
        const selectedResultId = selectedResult.Id;
      
        if (localLoading) {
          if (propertyId == selectedResultId) {
            this.setState({
              localLoading: false,
            })
          }
        }
      
        if (!localLoading) {
          if (propertyId != selectedResultId) {
            this.setState({
              localLoading: true,
            })
          }
        }
      }
    }

    if (property) {
      const { 
        BuildingPlanURL,
        BuildingPlanURLDWG, 
        BuildingPlanURLPDF,
        BuildingDrawing,
        BuildingDrawingDWG,
        DefectPlanURL,
        DefectPlanURLDWG,
        DefectPlanURLPDF,
        AerialMapURL,
      } = property;

      if (!selectedProperty && !prevState.selectedProperty) {
        this.setState({
          selectedProperty: property,
          buildingPlanUrl: BuildingPlanURL,
          buildingPlanDwg: BuildingPlanURLDWG,
          aerialMapURL: AerialMapURL,
          buildingDrawing: BuildingDrawing,
          buildingDrawingDwg: BuildingDrawingDWG,
          defectPlanUrl: DefectPlanURL,
          defectPlanUrlDwg: DefectPlanURLDWG,
          defectPlanUrlPdf: DefectPlanURLPDF,
          buildingPlanUrlPdf: BuildingPlanURLPDF,
        })
      }
    }

    if (property != previousProperty) {
      this.setState({
        selectedProperty: property,
      })      
    }
  } 

  openDefectsWindow = () => {
    this.setState({
      defectsWindowOpen: true,
    })
  };

  closeDefectsWindow = () => {
    this.setState({
      defectsWindowOpen: false,
    })
  };

  openConditionWindow = () => {
    this.setState({
      conditionWindowOpen: true,
    })
  };

  closeConditionWindow = () => {
    this.setState({
      conditionWindowOpen: false,
    })
  };

  openRemainingLifeWindow = () => {
    this.setState({
      remainingLifeWindowOpen: true,
    })
  };

  closeFinancialForecastWindow = () => {
    this.setState({
      financialForecastWindowOpen: false,
    })
  };

  openFinancialForecastWindow = () => {
    this.setState({
      financialForecastWindowOpen: true,
    })
  };

  closeRemainingLifeWindow = () => {
    this.setState({
      remainingLifeWindowOpen: false,
    })
  };

  openBuildingPlanWindow = () => {
    this.setState({
      buildingPlanWindowOpen: true,
    })
  };

  closeBuildingPlanWindow = () => {
    this.setState({
      buildingPlanWindowOpen: false,
    })
  };

  openBuildingDefectPlanWindow = () => {
    this.setState({
      buildingDefectPlanWindowOpen: true,
    })
  };

  closeBuildingDefectPlanWindow = () => {
    this.setState({
      buildingDefectPlanWindowOpen: false,
    })
  };

  openBuildingAerialMapWindow = () => {
    this.setState({
      aerialMapWindowOpen: true,
    })
  };

  closeBuildingAerialMapWindow = () => {
    this.setState({
      aerialMapWindowOpen: false,
    })
  };

  openBuildingPlan = (url) => {
    var windowStyle = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
    if(url.indexOf("html")!= -1){
      var win = window.open(url, '_blank', windowStyle);
      win.focus();
    }else{
      var image = new Image();
      image.src = url;
      var w = window.open("", '_blank', windowStyle);
      w.document.write(image.outerHTML);
    }
  };

  componentWillUnmount = () => {
    console.log('unmounting PROPERTY');
     this.vm.$destroy();
  };

  render() {
    const {
      handleDispatchReport,
      handleEditExecutiveSummary,
      handleDispatchGetRCS,
      selectedResult,
      property,
      loading,
      userRank,
      materialType,
      mobile,
      reportLoading,
      rcsInfos,
      sowReportUrl,
      carReportUrl,
      sowReportLoading,
      carReportLoading,
    } = this.props;

    const { 
      selectedProperty,
      defectsWindowOpen,
      conditionWindowOpen,
      remainingLifeWindowOpen,
      financialForecastWindowOpen,
      aerialMapURL,
      localLoading,
    } = this.state;

    return (
      <Grid id='property-container-row' container direction='row' style={{ height: '100%' }}>
        <Grid item xs={12} style={{ height: '100%' }}>
        <PropertyConditionModal 
          handleDispatchGetRCS={handleDispatchGetRCS}
          closeConditionWindow={ this.closeConditionWindow }
          conditionWindowOpen={ conditionWindowOpen }
          selectedResult={ selectedResult }
          property={ property }
          rcsInfos={ rcsInfos }
        />
        <PropertyRemainingLifeModal 
          closeRemainingLifeWindow={ this.closeRemainingLifeWindow }
          remainingLifeWindowOpen={ remainingLifeWindowOpen }
          selectedResult={ selectedResult }
          property={ property }
          materialType={materialType}
        />
        <PropertyFinancialForecastModal 
          closeFinancialForecastWindow={ this.closeFinancialForecastWindow }
          financialForecastWindowOpen={ financialForecastWindowOpen }
          selectedResult={ selectedResult }
          property={ property }
        />
        <PropertyWarrantySnackBar 
          selectedResult={ selectedResult }
          selectedProperty={ selectedProperty }
          property={ property }
        />
        {/* <Grid id='property-row' container direction={'row'} alignItems='flex-start' justify='space-between' style={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5' }}> */}
        <Grid id='property-row' container direction={'row'} alignItems='flex-start' justify='space-between' style={{ height: '100%', backgroundColor: '#f5f5f5' }}>
          <Grid id='left-column-item' item xs={12} sm={12} md={12} lg={4} xl={4} style={{ height: '100%', flexGrow: 1 }}>
            <Grid id='left-column-row' container direction='row' style={{ display: 'table', height: '100%', overflow: 'auto' }}>
              <Grid id='header-card-item' item xs={12} style={{ display: 'table-cell' }}>
                <PropertyHeaderCard
                  selectedResult={selectedResult}
                  property={property}
                  materialType={materialType}
                />
              </Grid>
              { mobile ? 
                <Grid id='property-data-item' item xs={12} style={{ height: '100%', display: 'table-row' }}>
                  <Grid id='property-data-row' container direction='row' justify='center' alignItems='center' style={{ height: '100%' }}>
                    <Grid id='data-body-item' item xs={12} style={{ height: '100%' }}>
                    { localLoading ? <PropertyLoading /> : 
                      <PropertyMedia
                        // images
                        images={selectedProperty.PropertyImages}
                        property={property}
                        //defects
                        closeDefectsWindow={this.closeDefectsWindow}
                        property={property}
                        selectedResult={selectedResult}
                        selectedProperty={selectedProperty}
                        defectsWindowOpen={defectsWindowOpen}
                        //aerial
                        aerialMapURL={aerialMapURL}
                        mobile={mobile}
                      /> }
                  </Grid>
                </Grid>
              </Grid>
              :
              <Grid id='property-data-item' item xs={12} style={{ height: '100%', display: 'table-row' }}>
                <Grid id='property-data-row' container direction='row' justify='center' alignItems='center' style={{ height: '100%', display: 'block', overflow: 'auto' }}>
                  <Grid id='data-body-item' item xs={12} style={{ height: '100%' }}>
                    <Grid id='data-body-row' container direction='row' justify='space-between' alignItems='center' style={{ height: '100%', width: '100%', display: 'table' }}>
                      <Grid id='data-body-graphs' item xs={12} style={{ display: 'table-header-group' }}>
                        <PropertyGraphs
                          openDefectsWindow={this.openDefectsWindow}
                          openConditionWindow={this.openConditionWindow}
                          openRemainingLifeWindow={this.openRemainingLifeWindow}
                          openFinancialForecastWindow={this.openFinancialForecastWindow}
                          handleDispatchReport={handleDispatchReport}
                          property={property}
                          selectedProperty={selectedProperty}
                          selectedResult={selectedResult}
                          loading={loading}
                          materialType={materialType}
                          reportLoading={reportLoading}
                          sowReportUrl={sowReportUrl}
                          carReportUrl={carReportUrl}
                          sowReportLoading={sowReportLoading}
                          carReportLoading={carReportLoading}
                        />
                      </Grid>                
                      <Grid id='data-body-summary' item xs={12} style={{ display: 'table-row', verticalAlign: 'bottom' }}>
                        <Grid container direction='column' justify='space-between' alignItems='center' style={{ display: 'table', height: '100%' }}>
                          <Grid item xs={12} style={{ width: '100%', display: 'table-row' }}>
                            <PropertyExecutiveSummary
                              handleEditExecutiveSummary={handleEditExecutiveSummary}
                              property={property}
                              userRank={userRank}
                            />
                          </Grid>
                          <Grid id='data-body-details' item xs={12} style={{ width: '100%', height: '100%', display: 'table-row' }}>
                            <Paper style={{ width: '100%', boxShadow: 'none', backgroundColor: 'rgb(245, 245, 245)', paddingBottom: '3%' }}>
                              <PropertySections
                                property={property}
                                selectedProperty={selectedProperty}
                                selectedResult={selectedResult}
                              />
                              <PropertyContacts
                                selectedProperty={selectedProperty}
                              />
                              <PropertyWarranties
                                property={property}
                              />
                              <PropertyDocuments
                                selectedProperty={selectedProperty}
                              />
                            </Paper>
                          </Grid>
                          <Grid item xs={12} style={{ width: '100%', display: 'table-footer-group' }}>
                                                            <PropertyTagList
                                                                key='propTagList'
                              property={property}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              }
            </Grid> 
          </Grid>
          <Grid item xs={0} sm={0} md={0} lg={8} xl={8} style={ mobile ? { height: '0%', display: 'none' } : { flexGrow: 1, height: '100%' } }>
            { localLoading ? <PropertyLoading /> : 
              <Grid container direction={'row'} alignItems={'center'} justify={'center'} style={{ height: '100%' }}>
                <Grid item xs={12} style={{ height: '100%' }}>
                  <Paper style={{ height: '100%', backgroundColor: 'rgb(233, 233, 233)' }}>
                    <Grid container direction='row' style={{ height: '100%' }}>
                      <Grid item xs={12} style={{ height: '100%' }}>
                        <PropertyMedia
                          images={selectedProperty.PropertyImages}
                          property={property}
                         
                          closeDefectsWindow={this.closeDefectsWindow}
                          property={property}
                          selectedResult={selectedResult}
                          selectedProperty={selectedProperty}
                          defectsWindowOpen={defectsWindowOpen} 
                        
                          aerialMapURL={aerialMapURL}
                          mobile={mobile}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid> 
             }
          </Grid>
        </Grid>
        </Grid>
        
      </Grid>
    )
  }
};

export default Property
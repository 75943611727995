import React, { useState, useEffect } from 'react';
import LeakResponseContactsForm from './LeakResponseContactsForm';
import {
  Grid,
  Card,
  Button,
} from '@material-ui/core';

const COMPONENT_LEAK_RESPONSE_STEP = 1;

function RequestLeakResponse(props) {
  const [disabled, setDisabled] = useState(true);
  const [leakResponseId, setLeakResponseId] = useState(0);
  const [nextSelected, setNextSelected] = useState(false);
  const [backSelected, setBackSelected] = useState(false);
  const {
    handleIncrementWizardStep,
    handleUpdateSelectedTag,
    handleUpdateSelectedBuilding,

    handleUpdateModifiedRequester,
    handleUpdateModifiedContact,
    handleUpdateStepTwoComplete,
    
    propertyList,
    userTags,

    leakResponse,
    editedLeakResponse,
    requesterIsUser,
    contactIsRequester,
    stepTwoComplete,
    selectedTag,
    selectedProperty,
  } = props;

  const handleNextClick = () => {
    setNextSelected(true);
  };

  const handleBackClick = () => {
    setBackSelected(true);
  };

  useEffect(() => {
    if (nextSelected) {
      const nextStep = COMPONENT_LEAK_RESPONSE_STEP + 1;
      handleIncrementWizardStep(nextStep);
      setNextSelected(false);
      setDisabled(true);
    }
  }, [nextSelected]);

  useEffect(() => {
    if (backSelected) {
      const backStep = COMPONENT_LEAK_RESPONSE_STEP - 1;
      handleIncrementWizardStep(backStep);
      setBackSelected(false);
    }
  }, [backSelected]);

  useEffect(() => {
    if (stepTwoComplete) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [stepTwoComplete]);
  
  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%', width: '100%' }}>
      <Grid item xs={12} style={{ height: '95%', width: '100%' }}>
        <Card square style={{ boxShadow: 'none' }}>
          <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>
            <Grid container direction='row' alignItems='center' justify='center' style={{ display: 'flex', padding: '2%', height: '93%', marginBottom: '10%' }}>
              <LeakResponseContactsForm 
                handleUpdateSelectedTag={handleUpdateSelectedTag}
                handleUpdateSelectedBuilding={handleUpdateSelectedBuilding}

                handleUpdateModifiedRequester={handleUpdateModifiedRequester}
                handleUpdateModifiedContact={handleUpdateModifiedContact}
                handleUpdateStepTwoComplete={handleUpdateStepTwoComplete}

                propertyList={propertyList}
                userTags={userTags}

                leakResponse={leakResponse}
                editedLeakResponse={editedLeakResponse}
                requesterIsUser={requesterIsUser}
                contactIsRequester={contactIsRequester}
                selectedTag={selectedTag}
                selectedProperty={selectedProperty}
              />
            </Grid>
            <Grid container direction='row' alignItems='center' justify='center' style={{ position: 'fixed', bottom: '1%', width: '97.5%', left: '1.5%', backgroundColor: '#66BFFA' }}>
              <Grid item xs={12} style={{ width: '100%' }}>
                <Grid container direction='row' alignItems='center' justify='space-evenly'>
                  <Grid item xs={3} style={{ textAlign: 'center', padding: '5px' }}>
                    <Button variant='contained' color='default' style={{ boxShadow: 'none' }} onClick={() => handleBackClick()}>Back</Button>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: 'center', padding: '5px' }} >
                    <Button variant='contained' color='default' style={{ boxShadow: 'none' }} disabled={disabled} onClick={() => handleNextClick()} style={{ boxShadow: 'none' }}>Next</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid container direction='row' alignItems='center' justify='center' style={{ position: 'fixed', bottom: '1%', left: '1.5%', width: '97.5%', backgroundColor: '#66BFFA' }}>
              
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <Button variant='contained' color='default' style={{ margin: '3%', boxShadow: 'none' }} onClick={()=>handleBackClick()}>Back</Button>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'center' }} >
                <Button variant='contained' color='default' style={{ margin: '3%', boxShadow: 'none' }} disabled={disabled} onClick={()=>handleNextClick()} style={{ boxShadow: 'none' }}>Next</Button>
              </Grid>
                
            </Grid> */}
          </Grid>

        </Card>
      </Grid>
    </Grid>
  )
};

export default RequestLeakResponse
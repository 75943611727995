import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import ScriptLoaded from '../../../../../GoogleMaps/LoadScript/LoadScript';

export default function LeakResponseReviewMap(props) {
  const [ center, setCenter ] = useState({});
  const [ existingLocations, setExistingLocations ] = useState([]);
  const {
    leakResponse,
    width,
  } = props;

  const mapElementStyle = { height: `100%` };

  const mapContainerElementStyle = {
    height: '100%',
    width: '100%'
  };

  useEffect(() => {
    if (Object.values(leakResponse).length) {
      const { 
        Property,
        Locations, 
      } = leakResponse;
      const lat = parseFloat(Property.Lat);
      const lng = parseFloat(Property.Lng);
      const latLng = {
        lat,
        lng
      };
      setCenter(latLng);
      setExistingLocations(Locations);
    }
  }, [leakResponse]);

  return (
    <Grid container direction='row' style={{ width: '100%', height: '100%' }}>
      <Grid xs={12}>
        <ScriptLoaded>
          <PropertiesMap
            leakLocations={existingLocations}
            center={center}
            width={width}

            isMarkerShown={true}
            containerElement={<div style={mapContainerElementStyle} />}
            mapElement={<div style={mapElementStyle} />}
          />
        </ScriptLoaded>
      </Grid>
    </Grid>
  )
};

const getMapOptions = (maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,
    data: {
      style: {
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      }
    },
    styles: [{
      featureType: "poi.business",
      elementType: "labels",
      stylers: [{
        visibility: "off"
      }]
    }],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 11,
    maxZoom: 23,
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.SATELLITE,
    tilt: 0,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID
      ]
    },
    draggable: false,
    zoomControl: false,
    clickableIcons: false
  };
};

const createBounds = (leakLocations) => {
  const bounds = new window.google.maps.LatLngBounds();
  leakLocations.map((leakLocation) => {
    const lat = parseFloat(leakLocation.lat);
    const lng = parseFloat(leakLocation.lng);
    if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
      if (lat != 0 && lng != 0) {
        const latLng = { lat, lng };
        bounds.extend(latLng);
      }
    }
  });
  return bounds;
};

const PropertiesMap = withGoogleMap(props => {
  const {
    leakLocations,
    center,
    width,
  } = props;

  let bounds;
  if (leakLocations) {
    if (leakLocations.length > 1) {
      bounds = createBounds(leakLocations);
    }
  }

  return (
    <GoogleMap
      ref={map => map && bounds ? map.fitBounds(bounds) : new google.maps.LatLng(center)}
      defaultZoom={ width > 959 ? 18 : 16 }
      defaultCenter={center}
      options={getMapOptions(window.google.maps)}
    >
      { leakLocations.map((leakLocation, i) => {
        const lat = leakLocation.lat;
        const lng = leakLocation.lng;
        const position = { lat, lng };
        return (
          <Marker position={position} icon={"http://client.roof-express.com/IconCreater/icon.aspx?label=" + (i + 1).toString() + "&def=0"} ></Marker>
        )
      }) }
    </GoogleMap>
  )
});
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    fontFamily: 'Ebrima', 
  },
  select: {
    backgroundColor: 'white',
    color: 'darkgrey',
    textAlign: 'center',
    fontFamily: 'Ebrima', 
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontFamily: 'Ebrima', 
  },
}));

const options = [
  {
    title: 'Ascending',
    value: 0,
  }, {
    title: 'Descending',
    value: 1,
  }
];

export default function SimpleSelect(props) {
  const [value, setValue] = useState(0);

  const { handleSortDirectionDispatch } = props;

  const handleChange = event => {
    setValue(event.target.value);
  };

  useEffect(() => {
    handleSortDirectionDispatch(value);
  }, [value])

  const classes = useStyles();
  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          className={classes.select}
          id="demo-simple-select-id"
          value={value}
          defaultValue={0}
          onChange={handleChange}
          inputprops={{
            shrink: true,
          }}
        >
        { options.map((option) => {
          const title = option.title;
          const value = option.value;
          return (
            <MenuItem value={value}  style={{fontFamily: 'Ebrima',}}>{title}</MenuItem>
          )
        }) }
        </Select>
        <FormHelperText style={{ fontFamily: 'Ebrima', }}>Sort</FormHelperText>
      </FormControl>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    fontFamily: 'Ebrima', 
  },
  select: {
    backgroundColor: 'white',
    color: 'darkgrey',
    textAlign: 'center',
    fontFamily: 'Ebrima', 
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontFamily: 'Ebrima', 
  },
}));

const options = [
  {
    title: 'Name',
    value: 0,
  },{
    title: 'Capital',
    value: 1,
  },{
    title: 'Operating',
    value: 2,
  },{
    title: 'Repairs',
    value: 3,
  },{
    title: 'Condition',
    value: 4,
  },{
    title: 'ERSL',
    value: 5,
  }, {
    title: 'Area',
    value: 6,
  }
];

export default function SimpleSelect(props) {
  const [value, setValue] = useState(0);
  
  const { handleSortTypeDispatch } = props;

  const handleChange = event => {
    setValue(event.target.value);
  };

  useEffect(() => {
    handleSortTypeDispatch(value);
  }, [value]);

  const classes = useStyles();
  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          className={classes.select}
          id="demo-simple-select-id"
          value={value}
          defaultValue={0}
          onChange={handleChange}
          inputprops={{
            shrink: true,
          }}
        >
          { options.map((option) => {
            const title = option.title;
            const value = option.value;
            return (
              <MenuItem value={value} style={{ fontFamily: 'Ebrima', }}>{title}</MenuItem>
            )
          }) }
        </Select>
        <FormHelperText style={{fontFamily: 'Ebrima'}}>Filter</FormHelperText>
      </FormControl>
    </div>
  );
}

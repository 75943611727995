import React, { useState, useEffect, useRef } from 'react';
import SelectCompany from './ManageFoldersForm/SubComponents/SelectCompany';
import CompanyFolderTree from './ManageFoldersForm/SubComponents/CompanyFolderTree';
import SubManageFoldersForm from './ManageFoldersForm/SubComponents/ManageFoldersForm';
import DeleteFolderModal from './ManageFoldersForm/SubComponents/DeleteFolderModal';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';

export default function ManageFoldersForm(props) {
  const {
    handleSelectCompany,
    handleAddSelected,
    handleAddFolderUnselected,
    handleUpdateAddFolderName,
    handleAddFolder,
    handleAddFolderTypeSelect,
    handleDeleteSelected,
    handleDeleteFolder,
    handleDeleteUnselected,
    handleDeleteBuildingFolder,
    handleFolderSelected,

    handleUpdateBuildingName,
    handleUpdateBuildingAddress,
    handleUpdateBuildingAddressCont,
    handleUpdateBuildingCity,
    handleUpdateBuildingCountry,
    handleUpdateBuildingState,
    handleUpdateBuildingPostalCode,
    handleAddBuildingFolder,

    Companies,
    Folders,
    Countries,
    SelectedCountryId,
    States,
    SelectedStateId,
    selectedCompany,
    addSelected,
    selectedAddFolder,
    addFolderType,
    addFolderName,
    deleteSelected,
    selectedDeleteFolder,
    deleteType,
    deleteNode,
    readyAddBuildingFolder,
  } = props;
    console.log(props);

  return (
    <Grid container direction='row' alignItems='flex-start' justify='center' style={{ height: '100%', padding: '1%', backgroundColor: 'white' }}>
        <SubManageFoldersForm 
        handleUpdateAddFolderName={handleUpdateAddFolderName}
        handleAddFolder={handleAddFolder}
        handleAddFolderUnselected={handleAddFolderUnselected}
        handleAddFolderTypeSelect={handleAddFolderTypeSelect}
        
        handleUpdateBuildingName={handleUpdateBuildingName}
        handleUpdateBuildingAddress={handleUpdateBuildingAddress}
        handleUpdateBuildingAddressCont={handleUpdateBuildingAddressCont}
        handleUpdateBuildingCity={handleUpdateBuildingCity}
        handleUpdateBuildingCountry={handleUpdateBuildingCountry}
        handleUpdateBuildingState={handleUpdateBuildingState}
        handleUpdateBuildingPostalCode={handleUpdateBuildingPostalCode}
        handleAddBuildingFolder={handleAddBuildingFolder}

              Countries={Countries}
              SelectedCountryId={SelectedCountryId}
              States={States}
              SelectedStateId={SelectedStateId}

        addSelected={addSelected}
        addFolderType={addFolderType}
        selectedAddFolder={selectedAddFolder}
        addFolderName={addFolderName}
        readyAddBuildingFolder={readyAddBuildingFolder}
      />
      <DeleteFolderModal 
        handleDeleteFolder={handleDeleteFolder}
        handleDeleteBuildingFolder={handleDeleteBuildingFolder}
        handleDeleteUnselected={handleDeleteUnselected}

        deleteSelected={deleteSelected}
        selectedDeleteFolder={selectedDeleteFolder}
        deleteType={deleteType}
        deleteNode={deleteNode}
      />
      <Grid item xs={4} style={{ height: '100%', textAlign: 'center' }}>
        <Grid container direction='row' justify='center' alignItems='center'>
          <Grid item xs={12}>
            <SelectCompany
              handleSelectCompany={handleSelectCompany}

              Companies={Companies}
              Folders={Folders}
            />
          </Grid>
          <Grid item xs={12}>
            <CompanyFolderTree
              handleAddSelected={handleAddSelected}
              handleDeleteSelected={handleDeleteSelected}
              handleFolderSelected={handleFolderSelected}

              Folders={Folders}
              selectedCompany={selectedCompany}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

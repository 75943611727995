import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const SiteFilterInputStyle = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      fontFamily: 'Ebrima',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      fontFamily: 'Ebrima',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
      fontFamily: 'Ebrima',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      fontFamily: 'Ebrima',
    },
}));

export default SiteFilterInputStyle
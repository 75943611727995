import React, { useEffect, useState, useRef } from 'react';
import PropertyConditionTableLoading from './PropertyConditionTableLoading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Collapse,
  Grid,
  Checkbox,
  Chip,
} from '@material-ui/core';

export default function PropertyCondition(props) {
  const [open, setOpen] = useState(false);
  const {
    handleDispatchGetRCS,
    section,
  } = props;

  const handleSetOpen = () => {
    setOpen(!open)
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousOpen = usePrevious(open);
  useEffect(() => {
    if (previousOpen != open) {
      const {
        InspectId,
        SectionId,
      } = section;
      if (open) {
        handleDispatchGetRCS(InspectId, SectionId);
      }
    }
  }, [open]);

  let backgroundColor;
  let color;
  if (section.RCSTitle == 'Adequate') {
    backgroundColor = 'rgb(20, 167, 108)';
    color = 'white';
  };
  if (section.RCSTitle == 'Degraded') {
    backgroundColor = 'rgb(255, 228, 0)';
    color = 'black';
  };
  if (section.RCSTitle == 'Unsatisfactory') {
    backgroundColor = 'rgb(247, 70, 73)';
    color = 'black';
  };

  const style = { backgroundColor: backgroundColor, color: color, fontFamily:'Ebrima', };

  return (

    <div style={{ display: 'contents'}}>
    <TableRow style={{ width: '100%' }} onClick={() => handleSetOpen()}>
      <TableCell align="left" component="th" scope="row" style={{fontFamily:'Ebrima',}}>
        { section.Name }
      </TableCell>
      <TableCell style={{ width: '100%' }} component="th" scope="row" align='right' style={{ color: 'black', fontFamily:'Ebrima', }}>
        { new Intl.NumberFormat('en').format(section.SF) }<span style={{ color: 'rgba(0, 0, 0, 0.54)', fontFamily:'Ebrima', }}> (Sq Ft)</span>
      </TableCell> 
      <TableCell style={{ width: '100%' }} component="th" scope="row" align='right' style={{ color: 'black', fontFamily:'Ebrima', }}>
        {section.RCS}
      </TableCell>
      <TableCell style={{ width: '100%' }} component="th" scope="row" align='right' style={{ color: 'black', fontFamily:'Ebrima', }}>
  {/* <Chip className={classes.card} size='small' style={{ height: 'unset', backgroundColor: rcsColor }} label={new Intl.NumberFormat('en').format(rcs)} /> */}
        <Chip  style={style} label={section.RCSTitle} />
        {/* <Button style={style} disabled>{section.RCSTitle}</Button> */}
      </TableCell>
    </TableRow>    
    <TableRow key={section.RCS}>
      <TableCell align='right' style={{ paddingBottom: 0, paddingTop: 0, fontFamily:'Ebrima', }} colSpan={6}>
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item xs={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              { open ? section.RCSInfos.length == 0 ? <PropertyConditionTableLoading /> : 
              <Table size="small" style={{fontFamily:'Ebrima',}}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{fontFamily:'Ebrima',}}>Question</TableCell>
                    <TableCell align="right" style={{fontFamily:'Ebrima',}}>Answer</TableCell>
                    <TableCell align="right" style={{fontFamily:'Ebrima',}}>Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{fontFamily:'Ebrima',}}>
                  { open ? section.RCSInfos.map((data) => {
                    const { 
                      Answer, 
                      Question, 
                      Score 
                    } = data;
                    return (
                      <TableRow key={Question}>
                        <TableCell component="th" scope="row" style={{fontFamily:'Ebrima',}}>
                          {Question}
                        </TableCell>
                        <TableCell style={ !Answer ? { color: 'lightGrey', fontFamily:'Ebrima' } :{fontFamily: 'Ebrima'} } align="right">
                          <Checkbox
                            checked={Answer}
                            disableRipple={true}
                            disableFocusRipple={true}
                            inputprops={{ 'aria-label': 'primary checkbox' }}
                          />
                        </TableCell>
                        <TableCell align="right" style={{fontFamily:'Ebrima',}}>
                          {Score}
                        </TableCell>
                      </TableRow>
                    )
                  }) : null }
                </TableBody>
              </Table>
              : null }
            </Collapse>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
    </div>
  );
}



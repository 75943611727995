import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '95%',
  },
}));

export default function AddDescriptionSelect(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState('Controlled');

  const { 
    retrieveWoDescription,
    category2Finished, 
    workOrderUrgency 
  } = props;

  const handleChange = event => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (value) {
      retrieveWoDescription(value);
    } else {
      retrieveWoDescription(null);
    }
  }, [value]);

  return (
    <form className={classes.container} noValidate autoComplete="off">
       <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
        disabled={ !category2Finished || !workOrderUrgency }
        onChange={handleChange}
        id="standard-textarea"
        label="Description"
        placeholder="Description (Optional)"
        multiline
        className={classes.textField}
        margin="normal"
      />
    </form>
  );
}

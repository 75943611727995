import React, { useState, useEffect } from 'react';
import SurfaceAttributeListItem from './SurfaceAttributeListItem';
import {
  Grid,
  ListItem,
  Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
    boxShadow: 'none',
    padding: 'none',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PropertySectionsCards(props) {
  const {
    i,
    attribute,
  } = props;

  return (
    <ListItem key={`${i}-attribute`}>
      <Grid container direction='row' alignItems='center' justify='center'>
        <Grid item xs={6}>
          <Typography variant='caption'>
            { attribute.AttType }
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='caption'>
            { attribute.AttValue }
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
}

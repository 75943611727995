import React, { useState, useEffect, useLayoutEffect } from 'react';
import Gallery from 'react-grid-gallery';
import {
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function PropertyImages(props) {
  const { images, galleryTitle } = props;

  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>
      { images && images.length > 0 ?
        <Grid id='gallery-container' item xs={12}>
          <Gallery
            images={images}
          />
        </Grid>
      : null }
    </Grid>
  )
};
import React, { Component } from 'react';
import PropertySearchBar from './SubComponents/PropertySearchBar';
import PropertyFilterSelect from './SubComponents/PropertyFilterSelect';
import PropertySortSelect from './SubComponents/PropertySortSelect';
import PropertyMinInput from './SubComponents/PropertyMinInput';
import PropertyMaxInput from './SubComponents/PropertyMaxInput';
import {
  Paper,
  Grid,
  Typography,
} from '@material-ui/core';

const containerPaperStyle = {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: '#66BFFA',
    padding: '1%',
    fontFamily: 'Ebrima',
};

const typographyPagesStyle = {
  fontSize: '12px',
  color: '#ffffff',
  fontFamily: 'Ebrima',
};

const filterHeaderStyle = {
  width: '100%',
  padding: '1%',
  backgroundColor: '#66BFFA',
  fontFamily: 'Ebrima',
}

const filterRowStyle = {
  width: '100%',
  fontFamily: 'Ebrima'
};

class PropertiesSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    const {
      handleSearch,
      handleMapToggle,
      handleSortTypeDispatch,
      handleSortDirectionDispatch,
      handleDispatchMax,
      handleDispatchMin,
      mobile,
      mapViewSelected,
    } = this.props;
    
    return (
      <Paper square={true} style={containerPaperStyle}>
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item xs={12} style={{ padding: '1%' }}>
            <PropertySearchBar
              handleSearch={handleSearch}
              handleMapToggle={handleMapToggle}
              mobile={mobile}
              mapViewSelected={mapViewSelected}
            />
          </Grid>
        </Grid>
        
        <Grid container direction={'row'} alignItems={'center'} justify={'center'} style={filterHeaderStyle}>
          <Grid item xs={12} style={{ backgroundColor: '#A7A9AC' }}>
            <Typography align={'center'} style={typographyPagesStyle}>Filters: </Typography>
          </Grid>
        </Grid>

        <Grid container direction='row' alignItems='flex-start' justify='center' style={filterRowStyle}>
          <Grid item xs={4} style={{ padding: '1%' }}>
            <PropertyFilterSelect 
              handleSortTypeDispatch={handleSortTypeDispatch}
            />
          </Grid>
          <Grid item xs={4} style={{ padding: '1%', }}>
            <PropertySortSelect 
              handleSortDirectionDispatch={handleSortDirectionDispatch}
            />
          </Grid>
          <Grid item xs={2} style={{ padding: '1%' }}>
            <PropertyMinInput               
              handleDispatchMin={handleDispatchMin}
            />
          </Grid>
          <Grid item xs={2} style={{ padding: '1%' }}>
            <PropertyMaxInput 
              handleDispatchMax={handleDispatchMax}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default PropertiesSearch
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
} from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import WarningIcon from '@material-ui/icons/Warning';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles({
    card: {
        width: '100%',
        boxShadow: 'none',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        fontFamily: 'Ebrima', 
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
        fontFamily: 'Ebrima', 
    },
    title: {
        fontSize: 14,
        fontFamily: 'Ebrima', 
    },
    Typography: {
        color: '#000000',
        fontSize: 20,
        fontWeight: 'fontWeightBold',
        fontFamily: 'Ebrima', 
    }
});

export default function PropertyYearSelector(props) {
    const {
        year,
        forecastData,
        property,
        handleYearChange,
    } = props;

    const classes = useStyles();

    return (
        
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple"> <Typography className={classes.Typography} variant="h5" component="h3">
                Year
            </Typography></InputLabel>
                            <Select
                                native
                                value={year}
                                onChange={handleYearChange}
                                inputprops={{
                                    name: 'age',
                                    id: 'age-native-simple',
                                }}
                                style={{fontFamily: 'Ebrima', }}>
                                {forecastData ? forecastData.map((data) => {
                                    const name = data.YearText;
                                    const id = data.Year;
                                    return (
                                        <option key={id} value={id} style={{fontFamily: 'Ebrima', }}>{name}</option>
                                    )
                                }) : null}
                            </Select>
                        </FormControl>
    );
}

import React from 'react';
import ApplicationFeedback from './ApplicationFeedback';
import {
  Modal,
  Grid,
  Typography,
  IconButton,
  Button,
  Paper,
  Avatar,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AnnouncementIcon from '@material-ui/icons/Announcement';

export default function SimpleModal(props) {
  const { 
    handleCloseFeedBackModal,
    feedbackOpen, 
  } = props;

  return (
      <Modal
        open={feedbackOpen}
        onBackdropClick={handleCloseFeedBackModal}
      >
        <Paper style={{ position: 'sticky', height: '95%', width: '95%', top: '2.5%', left: '2.5%' }}>
          <Grid container direction='row'>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' justify='space-between' style={{ backgroundColor: '#66BFFA', position: 'sticky', top: '0', left: '0', width: '100%', textAlign: 'center' }}>
                <Grid item xs={1} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                  <Avatar style={{ backgroundColor: 'gainsboro', margin: '2%' }}>
                    <AnnouncementIcon />
                  </Avatar>
                  <Typography variant='h6' style={{ margin: '4%' }}>Feedback</Typography>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right', color: 'white', display: 'contents' }}>
                  <IconButton onClick={() => handleCloseFeedBackModal()}>
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>  
          
          <ApplicationFeedback />

          <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA', position: 'sticky', top: '0', left: '0', width: '100%', textAlign: 'center' }}>
            <Grid item xs={3} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', alignItems: 'center', whiteSpace: 'nowrap' }}>
              <Button style={{ margin: '2%', }} variant={'contained'} color={'primary'} onClick={handleCloseFeedBackModal}>Close</Button>
            </Grid>
          </Grid>

        </Paper>

      </Modal>
  );
}

import React, { useState, useEffect } from 'react';
import AddLeakResponse from './AddLeakResponse';
import {
  Paper,
  Grid,
  Modal,
  Avatar,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EqualizerIcon from '@material-ui/icons/Equalizer';

function AddLeakResponseModal(props) {
  const {
    handleCloseLeakResponseModal,
    handleIncrementWizardStep,
    handleEmergencySwitch,
    handleUpdateDescription,
    handleUpdateSelectedTag,
    handleUpdateSelectedBuilding,
    handleUpdateLocationDescription,
    handleUpdateLocationCoordinants,
    handleUpdateSelectedLocation,
    handleUpdateSelectedLocations,
    handleUpdateRemovedLocation,
    handleUpdateDocuments,
    handleRemovedDocument,
    handleUpdateComment,
    handleSubmitLeakResponse,
    handleNavigateToLeakResponseList,

    handleUpdateModifiedRequester,
    handleUpdateModifiedContact,
    handleUpdateStepTwoComplete,
    handleUpdateStepThreeComplete,
    
    userTags,
    propertyList,

    leakResponse,
    editedLeakResponse,
    selectedWizardStep,
    addLeakResponse,
    selectedProperty,
    requesterIsUser,
    contactIsRequester,
    stepTwoComplete,
    stepThreeComplete,
    uploadProgress,
    selectedTag,
    selectedDocuments,
    existingDocuments,
    leakResponseComments,
  } = props;

  return (
    <Modal open={addLeakResponse}>
      <Paper id='paper' style={{ position: 'absolute', height: '97.5%', width: '97.5%', top: '1.5%', left: '1.5%', overflow: 'auto' }}>
        <Grid container direction='row' style={{ height: '100%', display: 'inline-block' }}>
          
          <Grid item xs={12} style={{ position: 'sticky', top: '0', left: '0', zIndex: '1' }}>
            <Grid container direction='row' alignItems='center' justify='space-between' style={{ backgroundColor: '#66BFFA', textAlign: 'center' }}>
              <Grid item xs={1} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                <Avatar style={{ backgroundColor: 'gainsboro', margin: '2%' }}>
                  <EqualizerIcon />
                </Avatar>
                <Typography variant='h6' style={{ margin: '4%' }}>Leak Response</Typography>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'right', color: 'white', display: 'contents' }}>
                <IconButton onClick={() => handleNavigateToLeakResponseList()}>
                  <HighlightOffIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <AddLeakResponse 
              handleEmergencySwitch={handleEmergencySwitch}
              handleIncrementWizardStep={handleIncrementWizardStep}
              handleUpdateDescription={handleUpdateDescription}
              handleUpdateSelectedTag={handleUpdateSelectedTag}
              handleUpdateSelectedBuilding={handleUpdateSelectedBuilding}
              handleUpdateLocationDescription={handleUpdateLocationDescription}
              handleUpdateLocationCoordinants={handleUpdateLocationCoordinants}
              handleUpdateSelectedLocation={handleUpdateSelectedLocation}
              handleUpdateSelectedLocations={handleUpdateSelectedLocations}
              handleUpdateRemovedLocation={handleUpdateRemovedLocation}
              handleUpdateDocuments={handleUpdateDocuments}
              handleRemovedDocument={handleRemovedDocument}
              handleUpdateComment={handleUpdateComment}
              handleSubmitLeakResponse={handleSubmitLeakResponse}
              handleNavigateToLeakResponseList={handleNavigateToLeakResponseList}

              handleUpdateModifiedRequester={handleUpdateModifiedRequester}
              handleUpdateModifiedContact={handleUpdateModifiedContact}
              handleUpdateStepTwoComplete={handleUpdateStepTwoComplete}
              handleUpdateStepThreeComplete={handleUpdateStepThreeComplete}
              
              userTags={userTags}
              propertyList={propertyList}

              leakResponse={leakResponse}
              editedLeakResponse={editedLeakResponse}
              selectedWizardStep={selectedWizardStep}
              requesterIsUser={requesterIsUser}
              contactIsRequester={contactIsRequester}
              stepTwoComplete={stepTwoComplete}
              stepThreeComplete={stepThreeComplete}
              uploadProgress={uploadProgress}
              selectedTag={selectedTag}
              selectedProperty={selectedProperty}
              selectedDocuments={selectedDocuments}
              existingDocuments={existingDocuments}
              leakResponseComments={leakResponseComments}
            />
          </Grid>

          {/* <Grid item xs={12} style={{ position: 'absolute', bottom: '0', left: '0', backgroundColor: '#66BFFA', width: '100%', textAlign: 'center' }}>
            <Grid container direction='row' alignItems='center' justify='center'>
              <Grid item xs={3} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', alignItems: 'center', whiteSpace: 'nowrap' }}>
                <Button style={{ margin: '2%', }} variant={'contained'} color={'primary'} onClick={() => handleCloseLeakResponseModal()}>Close</Button>
              </Grid>
            </Grid>
          </Grid> */}
         
        </Grid>
      </Paper>
    </Modal>
  )
} 

export default AddLeakResponseModal
import React from 'react';
import {
  ListItem,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ApplicationSearchItemStyle from '../../../styles/ApplicationHeaderStyles/ApplicationSearchItemStyle';

function ApplicationSearchNoResults(props) {
  const classes = ApplicationSearchItemStyle();
  
  return (
    <div className={classes.root}>
      <ListItem>
        <IconButton>
          <ErrorIcon />
        </IconButton>
        <ListItemText primary={ 'Sorry, No Results for that Criteria' } />
      </ListItem>
    </div>
  )
};

export default ApplicationSearchNoResults
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  LinearProgress,
  Typography,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function LeakResponseUploadingLoading(props) {
  const {
    handleNavigateToLeakResponseList,

    files,
    uploadProgress,
  } = props;

  useEffect(() => {
    if (uploadProgress) {
      if (uploadProgress == files.length) {
        handleNavigateToLeakResponseList();
      }
    }
  }, [uploadProgress]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction='row' alignItems='center' justify='center'>
        <Grid item xs={3} style={{textAlign: 'center'}}>
          <Typography variant='h6'>{`loading, ${uploadProgress}/${files.length} complete`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Snackbar, 
} from '@material-ui/core';

import { 
  Alert, 
  AlertTitle,
} from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function MoveProeprtySuccessAlert(props) {
  const [success, setSuccess] = useState(null);
  const {
    movePropertyResponse,
  } = props;

  const handleClose = () => {
    setSuccess(null);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousMovePropertyResponse = usePrevious(movePropertyResponse);
  useEffect(() => {
    if (movePropertyResponse != previousMovePropertyResponse) {
      if (movePropertyResponse) {
        const movePropertyResponseExists = movePropertyResponse.length;
        if (movePropertyResponseExists) {
          const response = movePropertyResponse[movePropertyResponseExists-1];
          const {
            Success,
          } = response;
          setSuccess(Success);
        }
      }
    }
  }, [movePropertyResponse]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      { success == true ?
        <div>
          <Snackbar open={success} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleClose} severity="success">
              Property Successfully Moved
            </Alert>
          </Snackbar>
        </div>
        : 
        success == false ?
          <div>
            <Snackbar open={!success} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert onClose={handleClose} severity="error">
                Property Move Failed
              </Alert>
            </Snackbar>
          </div>
        : 
        null
      }
    </div>
  );
}


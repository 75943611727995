import React, { useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';

class AddContactCheckBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: false,
      selectedBuildings: [],
    }
  }

  handleChange = (building) => {
    const { selectBuilding } =  this.props;
    this.setState({
      checked: !this.state.checked,
    }, selectBuilding(building))
  };

  render() {

    const {
      building,
    } = this.props;

    return (
      <div>
        <Checkbox
          checked={this.state.checked}
          onChange={()=>this.handleChange(building)}
          value={this.state.checked}
          color="primary"
          inputprops={{
            'aria-label': 'secondary checkbox',
          }}
        />
      </div>
    );
  }
}

export default AddContactCheckBox

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Avatar,
  TextField,
  ListItem,
  IconButton,
} from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import DescriptionIcon from '@material-ui/icons/Description';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export default function LeakResponseSelectedLocationsCard(props) {
  const [ lat, setLat ] = useState('');
  const [ lng, setLng ] = useState('');

  const {
    handleRemoveFromSelectedLocations,
    
    location,
    coordinants,
    description,
  } = props;

  const handleRemoveFromLocationsClick = () => {
    handleRemoveFromSelectedLocations(location);
  };

  useEffect(() => {
    if (coordinants) {
      const {
        lat,
        lng
      } = coordinants;
      setLat(lat);
      setLng(lng);
    }
  }, [coordinants]);

  const classes = useStyles();

  return (
    <ListItem disableGutters style={{ padding: '1%', maxHeight: '14rem', minWidth: '15rem' }}>
      <Card square style={{ height: '100%', width: '100%', padding: '1.5%' }} variant="outlined">
        <CardContent style={{ height: '100%', padding: '0' }}>
          <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA' }}>
            <Grid item xs={1}>
              <Avatar className={classes.small} style={{ color: 'white', backgroundColor: 'gainsboro', marginLeft: '10%' }}>
                { location.LocationNumber }
              </Avatar>
            </Grid>
            <Grid item xs={10} style={{ textAlign: 'center', color: 'white' }}>
              <Typography variant='button'>Leak Location</Typography>
            </Grid>
            <Grid item xs={1} style={{ height: '100%' }}>
              
              <Grid container direction='row' alignItems='flex-start' justify='flex-start' style={{ height: '100%' }}>
                <Grid item xs={12} style={{ height: '100%' }}>
                  <HighlightOffIcon onClick={()=>handleRemoveFromLocationsClick()} fontSize='small' style={{ height: '100%', width: '100%', color: 'white' }} />
                </Grid>
              </Grid>

            </Grid>
          </Grid>

          <Grid container direction='row' alignItems='flex-start' justify='center' style={{ height: '90%' }}>
            <Grid item xs={12} style={{ height: '60%', padding: '1%' }}>
              <Grid container direction='row' style={{ height: '100%' }}>
                <Grid item xs={12} style={{ height: '100%' }}>
                  <Grid container direction='row' style={{ height: '100%' }}>
                    <Grid item xs={12} style={{ height: '100%' }}>

                      <Grid container direction='row'>
                        <Grid item xs={12} style={{ backgroundColor: 'lightGrey', color: 'white', textAlign: 'center' }}>
                          <Typography variant='subtitle2'>Description</Typography>
                        </Grid>
                      </Grid>

                      <Grid container direction='row'>
                        <Grid item xs={12} style={{ height: '100%' }}>
                          <Grid container direction='row' alignItems='flex-start' justify='flex-start' style={{ height: '100%' }}>
                            <Grid item xs={12} style={{ height: '90%', top: '3%', position: 'relative' }}>
                               <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                                square
                                id="filled-basic"
                                variant="filled"
                                multiline
                                disabled={true}
                                value={description}
                                rows={2}
                                style={{ height: '100%', width: '100%', overflow: 'auto' }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ListItem>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ProjectComments from './SubComponents/ProjectComments';
import ProjectForm from './SubComponents/ProjectForm';
import ProjectFiles from './SubComponents/ProjectFiles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'} variant={'body2'}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
}));

export default function FullWidthTabs({
  vm,
  dispatch,
  users,
  properties,
  handleAddComment,
  projectSelected,
  token,
  identityId,
  showNotification,
  notificationMessage,
  types,
  phaseTemplates,
  woMasters,
  inspections,
  navRoutes,
  currentUserLogged,
  systemTypeInfo,
  companies,
  currentCompanyId,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  let isEnableTab = true;
  if (
    !window.location.href.includes('subproject') &&
    !window.location.href.includes('property') &&
    projectSelected &&
    'ProjectId' in projectSelected &&
    projectSelected.ProjectId.length > 0
  ) {
    isEnableTab = false;
  }
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Project Settings" {...a11yProps(0)} />
          <Tab disabled={isEnableTab} label="Comments" {...a11yProps(1)} />
          <Tab disabled={isEnableTab} label="Documents" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ProjectForm
            currentCompanyId={currentCompanyId}
            companies={companies}
            types={types}
            dispatch={dispatch}
            vm={vm}
            users={users}
            properties={properties}
            projectSelected={projectSelected}
            showNotification={showNotification}
            notificationMessage={notificationMessage}
            phaseTemplates={phaseTemplates}
            woMasters={woMasters}
            inspections={inspections}
            navRoutes={navRoutes}
            systemTypeInfo={systemTypeInfo}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ProjectComments
            comments={
              projectSelected && projectSelected.ProjectComments
                ? projectSelected.ProjectComments
                : []
            }
            projectId={
              projectSelected && projectSelected.ProjectId ? projectSelected.ProjectId : ''
            }
            dispatch={dispatch}
            vm={vm}
            handleAddComment={handleAddComment}
            currentUserLogged={currentUserLogged}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <ProjectFiles
            dispatch={dispatch}
            vm={vm}
            token={token}
            identityId={identityId}
            files={
              projectSelected && projectSelected.ProjectFiles ? projectSelected.ProjectFiles : []
            }
            projectId={
              projectSelected && projectSelected.ProjectId ? projectSelected.ProjectId : ''
            }
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

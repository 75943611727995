import React, { useState, useEffect } from 'react';
import WorkOrderItems from '../WorkOrderItems';
import WorkOrderPhotosLoading from './WorkOrderPhotosLoading';
import WindowSize from '../../../../../../Application/Global/WindowSize';
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Create Item', 'Manage Costs', 'Manage Defects', 'Manage Photos'];
}

export default function WorkOrderItemsStepper(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [disableNextButton, setDisableNextButton] = useState(true);
  const [disableFinishButton, setDisableFinishButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const steps = getSteps();
  const classes = useStyles();

  const { 
    handleSaveAndCancelWorkOrderItem,
    handleCancelSaveWorkOrderItemModal,
    handleRemoveCostItem,
    propertySections,
    dispatchCreateNewWorkOrderItem, 
    workOrderId,
    retrieveCreateNewWorkOrderItem,
    retrieveEditingWorkOrderItem,
    retrieveEditedWorkOrderItem,
    retrieveWorkOrderItemImages,
    retrieveWorkOrderItemFulfilling,
    saveOrCancelWorkOrderItems,
    propertyDefects,
    editingWorkOrderItem,
    editedWorkOrderItem,
    selectedWorkOrder,
    editWorkOrder,
    itemPromiseFulfilling,
    token,
    identityId,
  } = props;

  const handleDisableNextButton = (bool) => {
    setDisableNextButton(bool);
  };

  const handleDisableFinishButton = (bool) => {
    setDisableFinishButton(bool);
  };

  const handleNext = () => {
    const max = 3;
    if (activeStep < max) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      handleSaveAndCancelWorkOrderItem(true);
    }
  };

  const handleBack = () => {
    const min = 0;
    if (activeStep > min) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    } 
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return 'Provide some general information about the work to be performed';
      case 1:
        return 'Identify the costs associated with the proposed fixes/enhancements';
      case 2:
        return 'Identify the defects to be fixed with this Work Order';
      case 3:
        return loading ? 'Uploading your photos, please wait..' : 'Upload photos to ensure work is performed properly';
    }
  };

  useEffect(() => {
    if (itemPromiseFulfilling) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [itemPromiseFulfilling]);

  const viewport = WindowSize();
  const { width, height } = viewport;

  const disableStepFour = disableFinishButton || loading ;
  const disableStepOne = disableNextButton; 

  return (
    <div className={classes.root}>
        <Stepper orientation={ width > 486 ? 'horizontal' : 'vertical' } activeStep={activeStep}>
          { steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
            );
          }) }
        </Stepper>
      
      { loading ? <WorkOrderPhotosLoading /> : null }

      <Grid container direction='row' alignItems={'center'} justify='center' style={{ top: '15%', left: '0', width: '100%', height: '80%' }}>
        <Grid item xs={12} style={{textAlign: 'center', margin: '2%'}}>
         
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>

              <Grid container direction='row' alignItems='center' justify='center'>
                <Grid item xs={12} style={{margin: '2%', }}>
                  <WorkOrderItems 
                    handleSaveAndCancelWorkOrderItem={handleSaveAndCancelWorkOrderItem}
                    handleCancelSaveWorkOrderItemModal={handleCancelSaveWorkOrderItemModal}
                    handleRemoveCostItem={handleRemoveCostItem}
                    retrieveCreateNewWorkOrderItem={retrieveCreateNewWorkOrderItem}
                    retrieveEditingWorkOrderItem={retrieveEditingWorkOrderItem}
                    retrieveEditedWorkOrderItem={retrieveEditedWorkOrderItem}
                    retrieveWorkOrderItemImages={retrieveWorkOrderItemImages}
                    retrieveWorkOrderItemFulfilling={retrieveWorkOrderItemFulfilling}
                    handleDisableNextButton={handleDisableNextButton}
                    handleDisableFinishButton={handleDisableFinishButton}
                    dispatchCreateNewWorkOrderItem={dispatchCreateNewWorkOrderItem}
                    activeStep={activeStep}
                    propertySections={propertySections}
                    workOrderId={workOrderId}
                    saveOrCancelWorkOrderItems={saveOrCancelWorkOrderItems}
                    propertyDefects={propertyDefects}
                    editingWorkOrderItem={editingWorkOrderItem}
                    selectedWorkOrder={selectedWorkOrder}
                    editWorkOrder={editWorkOrder}
                    itemPromiseFulfilling={itemPromiseFulfilling}
                    token={token}
                    identityId={identityId}
                  />
                </Grid>
              </Grid>

              <div style={{margin: '0%'}}>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={ handleNext }
                  className={ classes.button }
                  disabled={ activeStep == 0 ? disableStepOne : activeStep == 3 ? disableStepFour : null }
                >
                  { activeStep === steps.length - 1 ? 'Finish' : 'Next' }
                </Button>
              </div>
            </div>
          
        </Grid>
      </Grid>
    </div>
  );
}

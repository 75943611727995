import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import {
  List,
  Collapse,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import AllOutIcon from '@material-ui/icons/AllOut';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PropertySectionsInventory(props) {
  const [itemizedInventory, setItemizedInventory] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const { inventory } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  }

  useEffect(() => {
    const itemizedObject = inventory.reduce((acc, o) => (acc[o.invTitle] = (acc[o.invTitle] || 0 ) + 1, acc), {});
    const itemizedInventory = [];

    for (let item in itemizedObject) {
      const organizedItem = {
        name: item,
        quantity: itemizedObject[item],
      }
      itemizedInventory.push(organizedItem);
    }
    setItemizedInventory(itemizedInventory);
  }, [inventory]);

  return (
    <Grid container direction={'row'} alignItems={'flex-end'} justify={'flex-start'} style={{ marginBottom: '5%' }}>
      <Grid direction={'row'} style={{ display: 'flex', marginLeft: '2%' }}>
        <Grid item xs={2} style={{ display: 'flex', marginTop: '1%' }}>
          <AllOutIcon fontSize={'default'} />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h6" style={{ fontSize: '18px' }}>Inventory Items</Typography>
        </Grid>
        <Grid item xs={1} style={{ display: 'flex' }}>
          <IconButton
            size={'small'}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={expanded}>
          <List>
            { itemizedInventory.map((item, i) => {
              return (
                <Grid key={i} container direction='row' alignItems={'center'} justify={'center'} style={{ width: '100%' }}>
                  <Grid item xs={12} style={{ marginLeft: '10%' }}>
                    <Typography> {item.name}: ({item.quantity}) </Typography>
                  </Grid>
                </Grid>
              )
            }) }
          </List>
        </Collapse>
      </Grid>
    </Grid>
  );
}

import React from 'react';
import dotnetify from 'dotnetify';
import auth from '../../auth';
import FinancialForecastGraph from './FinancialForecastGraph';
import AreaByLocationGraph from './AreaByLocationGraph';
import AssetsByLocationGraph from './AssetsByLocationGraph';
import CorrectiveMaintenanceGraph from './CorrectiveMaintenanceGraph';
import RoofDashboardHeader from './RoofDashboardHeader';
import ERSLByTypeChart from './ERSLByTypeChart';
import { 
  Grid, 
  Paper 
} from '@material-ui/core';

class RoofDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    
    // dotnetify.react.router.urlPath = null;
    dotnetify.react.router.notFound404Url = null;
    this.vm = dotnetify.react.connect('RoofDashboard', this, { 
       headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        auth.signOut();
      }
    });
    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
        numberOfForcastResults: 10,
        HeaderData: [],
        Projections: [],
        AreaByFilteredTags: [],
        AssetsByFilteredTags: [],
        EmergencyCostsData: [],
        CorrectiveMaintenanceData: [],
        EmergencyRepairsData: [],
        RemainingServiceData: [],
        ERSLByType: [],
    };
  }

  handleNumberOfResults = (event, data) => {
    const { props } = data;
    const { value } = props;

    this.setState({
      numberOfForcastResults: value,
    });
  };

  handleSelectRoute = (selectedRoute, title) => {
    const { selectRoute } = this.props;
    this.setState({
      selectedRoute,
    }, 
      selectRoute(selectedRoute, title),
      this.searchDeselected());
  };

  componentWillUnmount() {
    this.vm.$destroy();
  };

  render() {
    
    const { 
      HeaderData,
      AreaByFilteredTags,
      AssetsByFilteredTags, 
      CorrectiveMaintenanceData, 
      ERSLByType,
      MaterialType,
    } = this.state;

    return (  
      <Grid id='graph-grid' container direction='row' justify='center' alignItems='center' style={{ height: '100%', width: '100%', overflow: 'auto', backgroundColor: '#A7A9AC' }}>
        <Grid item xs={12}>
          <RoofDashboardHeader headerData={HeaderData}/>
        </Grid>
        <Grid item xs={12} style={{ padding: '1%' }}>
          <Paper>
            <FinancialForecastGraph
              financialForcastData={ HeaderData.forecastDataList }
              materialType={MaterialType}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '1%' }}>
          <Paper >
            <AreaByLocationGraph
              areaByFilteredTags={ AreaByFilteredTags }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '1%' }}>
          <Paper >
            <AssetsByLocationGraph
              assetsByFilteredTags={ AssetsByFilteredTags }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '1%' }}>
          <Paper >
            <CorrectiveMaintenanceGraph
              correctiveMaintenanceData={ CorrectiveMaintenanceData }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: '1%' }}>
          <Paper>
            <ERSLByTypeChart
              ERSLByType={ ERSLByType }
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default RoofDashboardPage;

import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import ReportTypeSelect from './SubComponents/ReportTypeSelect';
import ReportSearch from './SubComponents/ReportSearch';
import ReportPropertyCheckBoxList from './SubComponents/ReportPropertyCheckBoxList';
import auth from '../../auth';
import {
  Grid,
  Paper,
  Button,
  Link,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

class ReportWriter extends Component {
  constructor(props) {
    super(props)
    this.vm = dotnetify.react.connect('ReportWriter', this, {
             headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        auth.signOut();
      }
    });
    this.dispatch = state => this.vm.$dispatch(state);

    this.state = {
      ReportUrl: "",
      ReqId: 1,
      Loading: false,
      Properties: [],
      UserTags: [],
      selectedPropertyId: null,
      ReportOpen: false,
      
      selectedReportType: null,
      searchText: null,
    };
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      Properties,
      UserTags,
      selectedReportType, 
      searchText,
    } = this.state;
    const previousProperties = prevState.Properties;
    const previousUserTags = prevState.UserTags;
    const previousSearchText = prevState.searchText;

    if (Properties != previousProperties) {
      this.setState({
        localProperties: [...Properties],
      })
    }

    if (UserTags != previousUserTags) {
      this.setState({
        localUserTags: [...UserTags],
      })
    }

    if (searchText != previousSearchText) {
      const CMSRId = 8;
      if (selectedReportType) {
        if (selectedReportType == CMSRId) {
          const filteredTags = this.searchTags(searchText);
          this.setState({
            localUserTags: filteredTags,
          })
        }
        if (selectedReportType != CMSRId) {
          const filteredProperties = this.searchProperties(searchText);
          this.setState({
            localProperties: filteredProperties,
          })
        }
      }
    }
  };

  searchProperties = (searchText) => {
    const { Properties } = this.state; 
    const propertiesCopy = [...Properties];

    if (searchText) {
      const filteredArray = propertiesCopy.filter(property => {
        const propertyName = property.Name;
        if (propertyName) {
          const name = propertyName.toLowerCase();
          const search = searchText.toLowerCase();
          const searchIsInName = name.includes(search);
          if (searchIsInName) {
            return true;
          } else {
            return false;
          }
        }
      }) 
      return filteredArray;
    }

    if (!searchText) {
      return propertiesCopy;
    }
  };

  searchTags = (searchText) => {
    const { UserTags } = this.state;
    const userTagsCopy = [...UserTags];

    if (searchText) {
      const filteredArray = userTagsCopy.filter(tag => {   
        const tagName = tag.Title;
        if (tagName) {
          const name = tagName.toLowerCase();
          const search = searchText.toLowerCase();
          const searchIsInName = name.includes(search);

          if (searchIsInName) {
            return true;
          } else {
            return false;
          }
        }
      }) 
      return filteredArray;
    }

    if (!searchText) {
      return userTagsCopy;
    }
  };
  
  retrieveSelectedReportType = (reportType) => {
    this.setState({
      selectedReportType: reportType,
    })
  };

  retrieveSelectedPropertyId = (selectedPropertyId) => {
    this.setState({
      selectedPropertyId,
    })
  };

  retrieveSearchText = (searchText) => {
    this.setState({
      searchText,
    })
  };

  handleCreateReport = () => {
    const { selectedPropertyId } = this.state;

    if (selectedPropertyId) {
      this.dispatchReport();
    }
  };

  dispatchReport = () => {
    const { 
      selectedPropertyId, 
      selectedReportType 
    } = this.state;

    const bfReport = {
        Properties: [ selectedPropertyId ], 
        ReportType: selectedReportType,
        Publish: true, 
      };

    this.setState({
      ReportOpen: true
    }, this.dispatch({ BFReport: bfReport }));
  };

  handleCloseReport = () => {
    this.setState({ 
      ReportOpen: false,
      selectedReportType: null, 
      selectedPropertyId: null,
    });
  };

  componentWillUnmount = () => {
    console.log('unmounting Report Writer');
    this.vm.$destroy();
  };

  render() {
    const {
      localProperties,
      localUserTags,
      selectedPropertyId,
      selectedReportType,
      ReportOpen,
      Loading,
      ReportUrl,
      ReqId,
    } = this.state; 

    return (
      <Grid container direction='row' alignItems='space-between' justify='space-between' style={{ height: '100%', width: '100%', position: 'absolute' }}>
        <Grid item xs={12} sm={12} md={8} lg={4} xl={4} style={{ height:'100%', width: '100%', position: 'absolute', overflow: 'auto' }}>
          
          <Grid container direction='row' style={{ position: 'sticky', top: '0', zIndex: '1', backgroundColor: 'rgb(246, 243, 235)' }}>
            <Grid item xs={12}>
              <Grid container direction='row' style={{ position: 'sticky', top: '0', left: '0', zIndex: '1' }}>
                <Grid item xs={12} style={{ display: 'flex', textAlign: 'left', backgroundColor: 'rgb(0, 194, 243)' }}>
                  <TrendingUpIcon style={{ color: 'white', margin: '2%' }} />
                  <Typography style={{ color: 'white', margin: '2%', fontFamily: 'Ebrima' }}>Run Report</Typography>
                </Grid>
              </Grid>
              <Paper style={{ margin: '2%', position: 'sticky', top: '0', left: '0' }}>
                <Grid container direction='row'>
                  <Grid item xs={12} style={{ backgroundColor: '#66BFFA', textAlign: 'left' }}>
                    <Typography variant='h6' style={{ color: 'white', marginLeft: '4%',  fontFamily: 'Ebrima', }}> Select A Type: </Typography>
                  </Grid>
                </Grid>
                <ReportTypeSelect
                  retrieveSelectedReportType={this.retrieveSelectedReportType}
                  selectedReportType={selectedReportType}
                />
              </Paper>
              { selectedReportType ?
                <Paper style={{ margin: '2%', textAlign: 'left', fontFamily: 'Ebrima', }}>
                  <Grid container direction='row'>
                    <Grid item xs={12} style={{ backgroundColor: '#66BFFA', textAlign: 'left' }}>
                      <Typography variant='h6' style={{ color: 'white', marginLeft: '4%', fontFamily: 'Ebrima', fontFamily: 'Ebrima',  }}> Select A Property: </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={12} style={{ backgroundColor: 'gainsboro' }}>
                      <ReportSearch
                        retrieveSearchText={this.retrieveSearchText}
                        selectedReportType={selectedReportType}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                :
                null }
            </Grid>
          </Grid>


          { selectedReportType ? 
            <Paper>
              <ReportPropertyCheckBoxList 
                retrieveSelectedPropertyId={this.retrieveSelectedPropertyId}
                selectedPropertyId={selectedPropertyId}
                selectedReportType={selectedReportType}
                localProperties={localProperties}
                localUserTags={localUserTags}
              />
            </Paper>
          : 
            null 
          }
          <Grid container direction='row' alignItems='center' justify='center' style={{ textAlign: 'center', position: 'sticky', bottom: '0', left: '0', backgroundColor: 'rgb(0, 194, 243)' }}>
            <Button 
              style={{margin: '1%', backgroundColor: '#8DC63F', fontFamily: 'Ebrima',}}
              color="secondary"
              variant='contained' 
              disabled={!selectedPropertyId} 
              onClick={(e) => { this.handleCreateReport() }}>
              Create
            </Button>
          </Grid>

          <Dialog open={ReportOpen} aria-labelledby="form-dialog-title" fullWidth={false}>
            <DialogActions>
              <Button color="primary" onClick={this.handleCloseReport} style={{fontFamily: 'Ebrima'}}>
                Close
              </Button>
            </DialogActions>
            <DialogTitle id="form-dialog-title" style={{fontFamily: 'Ebrima'}}>Creating Report</DialogTitle>
            <DialogContent>
              { Loading == true ?
                <LinearProgress variant="query" style={{fontFamily: 'Ebrima'}} />
                :
                <Link style={{fontFamily: 'Ebrima'}}  href={ReportUrl} >{ReportUrl != null && ReportUrl.length > 0 ? 'Open Report: (' + ReqId + ')' : ''}</Link>
              }
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    )
  }
};

export default ReportWriter
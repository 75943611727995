import React, { useState, useEffect } from 'react';
import PropertyGraphsHeader from '../PropertyGraphs/SubComponents/PropertyGraphsHeader';
import PropertyRemainingLife from './PropertyRemainingLife';
import {
  Paper,
  Grid,
  Modal,
  Avatar,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EqualizerIcon from '@material-ui/icons/Equalizer';

export default function PropertyDefectsModal(props) {
  const [remainingLifeData, setRemainingLifeData] = useState([]);

  const { 
    closeRemainingLifeWindow,
    remainingLifeWindowOpen, 
    property,  
    materialType,
  } = props;

  return (
    <Modal open={remainingLifeWindowOpen}>
      
      <Paper id='paper' style={{ position: 'absolute', height: '95%', width: '95%', top: '2.5%', left: '2.5%' }}>
        
        <Grid container direction='row' style={{ height: '100%' }}>
          <Grid item xs={12} style={{ height: '15%' }}>
            <Grid container direction='row' alignItems='center' justify='space-between' style={{ backgroundColor: '#66BFFA', position: 'sticky', top: '0', left: '0', width: '100%', textAlign: 'center' }}>
              <Grid item xs={1} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                <Avatar style={{ backgroundColor: 'gainsboro', margin: '2%' }}>
                  <EqualizerIcon />
                </Avatar>
                <Typography variant='h6' style={{ margin: '4%', fontFamily: 'Ebrima', }}>Remaining Life</Typography>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'right', color: 'white', display: 'contents' }}>
                <IconButton onClick={() => closeRemainingLifeWindow()}>
                  <HighlightOffIcon />
                </IconButton>
              </Grid>
              <PropertyGraphsHeader
                property={property}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ height: '80%' }}>
            <PropertyRemainingLife
              property={property}
              materialType={materialType}
            />
          </Grid>
          <Grid container direction='row' alignItems='center' justify='center' style={{ backgroundColor: '#66BFFA', width: '100%', textAlign: 'center' }}>
            <Grid item xs={3} style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', alignItems: 'center', whiteSpace: 'nowrap' }}>
              <Button style={{ margin: '2%', fontFamily: 'Ebrima',}} variant={'contained'} color={'primary'} onClick={closeRemainingLifeWindow}>Close</Button>
            </Grid>
          </Grid>
        </Grid>   

      </Paper>
            
    </Modal>
  )
} 
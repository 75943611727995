import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  Typography,
  CardMedia,
  IconButton,
}  from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

export default function LeakResponseDocumentCard(props) {
  const {
    handleRemoveDocument,

    document,
  } = props;

  const handleRemoveClick = (preview) => {
    handleRemoveDocument(preview);
  };

  const previewName = document.FileName;
  const previewType = document.FileType;
  const previewUrl = document.FileUrl;

  return (
    <Card style={{ height: '100%', backgroundColor: '#66BFFA' }}>
      <Grid container direction='row' style={{ height: '100%' }}>
        <Grid item xs={12} style={{ height: '100%' }}>
          <Grid container direction='row' alignItems='center' justify='center' style={{ height: '20%'}}>
            <Grid item xs={9} style={{ textAlign: 'center', width: '100%', height: '100%', overflow: 'hidden' }}>
              <Typography variant='subtitle1' style={{ color: 'rgba(0, 0, 0, 0.54)'}}>{ previewName }</Typography>
            </Grid>
             <Grid item xs={3} style={{ textAlign: 'center' }}>
              <IconButton aria-label="settings" onClick={()=>handleRemoveClick(preview)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container direction='row' style={{ height: '80%' }}>
            <Grid item xs={12} style={{ height: '100%' }}>
              { previewType == 'image/jpeg' || previewType == 'image/png' ? 
                <CardMedia
                  style={{ height: '100%', padding: '7%', backgroundColor: 'white' }}
                  image={previewUrl}
                />
              :
                <CardMedia
                  style={{ height: '100%', padding: '7%', backgroundColor: 'white' }}
                  image={previewUrl}
                  children={
                    <IconButton disabled={true} style={{ height: '100%', width: '100%' }}>
                      <InsertDriveFileIcon style={{ height: '100%', width: '100%' }} />
                    </IconButton>
                  }
                />
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Button,
  Grid,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    position: 'sticky',
    left: '0',
    width: '100%',
    margin: theme.spacing(1),
    margin: '0',
  }
}));

export default function AddBluefinInvoiceDoc(props) {
  const classes = useStyles();
  const {
    handleCloseWorkOrderItems,
    itemPromiseFulfilling,
  } = props;

  const onButtonClick = () => {
    handleCloseWorkOrderItems();
  };

  return (
    <FormControl variant="filled" className={classes.formControl}>
      <Paper square style={{ backgroundColor: '#3f51b5', width: '100%' }}>
        <Grid item xs={12}>
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item={4} style={{ margin: '1%' }}>
              <Button 
                variant='contained' 
                color='secondary'
                disabled={itemPromiseFulfilling} 
                onClick={() => onButtonClick()}
              >Close</Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </FormControl>
  );
};




import React from 'react';
import dotnetify from 'dotnetify';
import ProjectHierarchy from '../components/Project/ProjectHierarchy';

class ProjectHierarchyPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = dotnetify.react.connect('Project', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        console.log(ex);
          auth.signOut();
      }
    });
    
    this.dispatch = (state) => this.vm.$dispatch(state);
    this.routeTo = (route) => this.vm.$routeTo(route);

    this.state = {
      Users: [],
      Properties: [],
      ShowNotification: false,
      NavRoutes: [],
      RootProjects: [],
      ProjectAdd: [],
      ProjectSelected: [],
      IsLoadingProjects: true,
      PhaseTemplatesFilter: [],
      PhaseTemplateItems: [],
    };
  }

  componentWillUnmount() {
    this.vm.$destroy();
  }

  render() {
    let {
      RootProjects,
      NavRoutes,
      ProjectAdd,
      ProjectSelected,
      IsLoadingProjects,
      Properties,
      PhaseTemplatesFilter,
      PhaseTemplateItems,
    } = this.state;

    return (
      <ProjectHierarchy
        dispatch={this.dispatch}
        rootProjects={RootProjects}
        vm={this.vm}
        navRoutes={NavRoutes}
        projectAdd={ProjectAdd}
        projectSelected={ProjectSelected}
        isLoadingProjects={IsLoadingProjects}
        properties={Properties}
        phaseTemplatesFilter={PhaseTemplatesFilter}
        phaseTemplateItems={PhaseTemplateItems}
      />
    );
  }
}

export default ProjectHierarchyPage;

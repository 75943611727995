import React from 'react';
import {
  Button,
} from '@material-ui/core';
import AppBarStyle from '../../../../styles/AppBarStyle';

function ApplicationFeedBackButton(props) {
  const {
    handleOpenFeedback,
  } = props;

  const style = AppBarStyle();
  const {
    feedbackButton,
  } = style;
  
  return (
    <Button className={feedbackButton} onClick={handleOpenFeedback} variant="contained" color="secondary" style={{boxShadow: 'none'}}>
      Feedback
    </Button>
  );
}

export default ApplicationFeedBackButton
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  List,
  ListItem,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
  Grid,
  Typography,
  Collapse,
  IconButton,
  Divider,
  Chip,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
    margin: '2%',
    overflowY: 'hidden',
  },
  media: {
    paddingTop: '74.25%',
    objectFit: 'contain',
    minHeight: '8rem',
    margin: '1%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  chip: {
    label: {
      paddingLeft: '3px',
      paddingRight: '3px',
    }
  }
}));

export default function PropertyResultsCard(props) {
  const classes = useStyles();
  

  return (
    <Card className={classes.card}>
      <Grid container direction='row'>
        <Grid item xs={12}>
          <CardHeader
            style={{ padding: 0, textAlign: 'center' }}
            title={<Typography gutterBottom={false}>{'No Results'}</Typography>}
            subheader={<a style={{ color: 'inherit', fontSize: '90%' }}>{'Try Another Search'}</a>}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
import React from 'react';
import ApplicationSidebarFilter from '../ApplicationSidebarFilter';
import ApplicationSidebarLogo from '../ApplicationSidebarLogo';
import ApplicationSidebarMenus from '../ApplicationSidebarMenus';
import {
  Drawer,
  CssBaseline,
  Paper,
  Grid
} from '@material-ui/core';
import ApplicationSidebarStyle from '../../../../styles/ApplicationSidebarStyle';
import LifeSafetyIssues from '../LifeSafetyIssues';

export default function ApplicationSidebarOpen(props) {
const classes = ApplicationSidebarStyle();

const {
  root,
  drawerOpen,
  drawerClosed,
  drawerPaper,
} = classes;

const {
  vm,
  menus,
  siteFilterSelect,
  sidebarOpen,
  selectRoute,
  selectedCompany,
  selectedTags,
  selectedMaterial,
  lifeSafetyProperties,
  lifeSafetyData,
  propertySelect,
} = props;

return (
  <div
    className={root}
  >
    <CssBaseline />
    <Drawer
      className={sidebarOpen ? drawerOpen : drawerClosed }
      variant="permanent"
      classes={{ paper: drawerPaper }}
    >
      <ApplicationSidebarLogo />
      {lifeSafetyData != null?
      <Paper style={{ width: '96%', margin: '2%', fontFamily:'Ebrima', }}>
        <Grid item xs={12}>
          <LifeSafetyIssues lifeSafetyData={lifeSafetyData} lifeSafetyProperties={lifeSafetyProperties}  propertySelect={propertySelect}/>
        </Grid>
      </Paper>
      :""
      }
      <Paper style={{ width: '96%', margin: '2%', fontFamily:'Ebrima', }}>
        <ApplicationSidebarFilter
          siteFilterSelect={siteFilterSelect}
          selectedCompany={selectedCompany}
          selectedMaterial={selectedMaterial}
          selectedTags={selectedTags}
        />
      </Paper>
      <ApplicationSidebarMenus
        vm={vm}
        menus={menus}
        selectRoute={selectRoute}
      />
    </Drawer>
  </div>
  )
};
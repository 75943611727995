import { typography } from 'material-ui/styles';
import { grey600, grey200 } from 'material-ui/styles/colors';

const styles = {
  navigation: {
    fontFamily: "Ebrima",
    fontSize: 15,
    fontWeight: typography.fontWeightLight,
    color: grey600,
    paddingBottom: 15,
    display: 'block'
  },
  title: {
    fontFamily: "Ebrima",
    paddingTop: 20,
    fontSize: 24,
    fontWeight: typography.fontWeightLight,
    marginBottom: 8
  },
    paper: {
        padding: 10,
    },
    breadcrumb: {
        position: 'fixed',
        top: 56,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        paddingTop: 5,
        zIndex: 900,
        backgroundColor: grey200,
        border: 'solid 0px red',
        width: '100%',
        fontFamily: 'Ebrima',
       
  },
    papertitle: {
      fontFamily: 'Ebrima',
        position: 'fixed',
        border: 'solid 1px red',
        padding: 10,
        zIndex: 900,
        top: 98,
        right: '20px'
    }
    ,
    clear: {
      clear: 'both'
    }
};

export default styles;

import React from 'react';
import {SvgIcon} from '@material-ui/core';

let SvgDashboard = (props) => (
  <SvgIcon {...props} >
        <rect  width="11" height="16"/>
        <rect  y="18" width="11" height="6"/>
        <rect  x="13" y="8" width="11" height="16"/>
        <rect  x="13" width="11" height="6"/>
  </SvgIcon>
);

export default SvgDashboard;
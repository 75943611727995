import React, { useEffect, useState, useReducer, useRef } from 'react';
import PropertyDefectCard from './PropertyDefectCard';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Card,
  List,
  CardHeader,
  Collapse,
  Avatar,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

var moment = require('moment');

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
    fontFamily: 'Ebrima',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    fontFamily: 'Ebrima',
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    fontFamily: 'Ebrima',
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PropertyDefectGroupCard(props) {
  const classes = useStyles();
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [ defects, setDefects ] = useState([]);
  const [ formattedTotal, setFormattedTotal ] = useState(0);
  const { 
    i, 
    defectGroup, 
    retrievedSelectedDefectsGroups, 
    highlightSelectedDefect, 
    selectedDefectSection, 
    groupCost 
  } = props;
  
  const { 
    GroupName, 
    DefectCount, 
    Defects, 
    WBSDefId 
  } = defectGroup;
  const groupId = WBSDefId;
  const expanded = groupId == expandedGroup;

  const prevSelectedDefectSection = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const handleExpandClick = () => {
    if (!expanded) {
      retrievedSelectedDefectsGroups(groupId);
      setExpandedGroup(groupId);
    } 

    if (expanded) {
      retrievedSelectedDefectsGroups(groupId);
      setExpandedGroup(false);
    }
  };

  const handleResetExpanded = () => {
    if (expanded) {
      setDefects(Defects);
      setExpandedGroup(false);
    }
  };

  useEffect(() => {
    setDefects(Defects);
  }, []);

  useEffect(() => {
    const formatTotal = formatter.format(groupCost);
    setFormattedTotal(formatTotal);
  }, [groupCost]);

  const previousSelectedDefectSection = prevSelectedDefectSection(selectedDefectSection);
  useEffect(() => {
    if (selectedDefectSection != previousSelectedDefectSection) {
      if (expandedGroup) {
        handleResetExpanded();
      }
    }
  }, [selectedDefectSection]);

  useEffect(() => {
    const filterDefectsForSelectedSection = () => {
      const defectsCopy = [...new Set(Defects)];
      if (selectedDefectSection) {
        const filteredDefectsCopy = defectsCopy.filter(defect => defect.SectionId == selectedDefectSection);
        if (filteredDefectsCopy.length > 0) {
          setDefects(filteredDefectsCopy);
        }
      } else {
        setDefects(Defects);
      }
    };
    filterDefectsForSelectedSection();
  }, [expanded], [selectedDefectSection], [groupId]);

  return (
    <Card key={`${i}-defect-card`} square className={classes.card} style={ expanded ? { backgroundColor: 'gainsboro' } : null }>
      <CardHeader classes={{title: classes.card, subheader: classes.card}}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {GroupName.indexOf('Life safety issue') !== -1 ?
              <WarningIcon/>
            :
            <ErrorIcon/>
            }
          

          </Avatar>
        }
        action={
          <IconButton className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more">
            <ExpandMoreIcon />
          </IconButton>
        }
        title={!selectedDefectSection ? `${GroupName} (${DefectCount})` : `${GroupName}`}
        subheader={`Estimated Cost: ${formattedTotal}`}
      />
      <Collapse in={expanded}>
        <Grid container direction='row' style={{ padding: '2%' }}>
          <Grid item xs={12}>
            { expanded ? defects.map((defect, i) => {
              const images = defect.Images;
              const name = defect.Defect;
              return (
                <Grid container>
                  <Grid container direction = 'row' alignItems={ 'center'} justify={ 'center'}>
                    <Grid item xs={12}>
                      <PropertyDefectCard 
                        defect={ defect }
                        images={ images }
                        highlightSelectedDefect={ highlightSelectedDefect }                         
                        selectedDefectSection={ selectedDefectSection } 
                        previousSelectedDefectSection={ previousSelectedDefectSection }
                      /> 
                    </Grid>
                  </Grid>
                </Grid>
              )
            }) : null }
          </Grid>
        </Grid>
      </Collapse>
    </Card>
  );
}

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  comments: {
    listStyle: 'none',
  },
  inline: {
    display: 'inline',
  },
  textbox: {
    width: '100%',
  },
  addButton: {
    marginTop: '5px',
    float: 'right',
    marginBottom: '5px',
  },
  loading: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-webkit-translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-moz-translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-ms-translate(-50%, -50%)',
  },
}));

export default function Comment({ comments, projectId, dispatch, currentUserLogged }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showModal, setShowModal] = React.useState(false);
  const classes = useStyles();
  const [comment, setComment] = React.useState('');
  const [validationForm, setValidationForm] = React.useState({
    isActiveValidation: false,
    comment: false,
  });
  const [commentToRemove, setCommentToRemove] = React.useState([]);

  const handleChangeComment = (event) => {
    setComment(event.target.value);
    setValidationForm({
      ...validationForm,
      comment: event.target.value.length > 0 ? false : true,
    });
  };

  const handleRemoveComment = (comment) => {
    return () => {
      setCommentToRemove(comment);
      setTimeout(() => {
        setShowModal(true);
      }, 10);
    };
  };

  const handleOkModal = () => {
    dispatch({
      RemoveComment: {
        CommentId: commentToRemove.CommentId,
        ProjectId: commentToRemove.ProjectId,
        Comment: commentToRemove.Comment,
        CreatedAt: commentToRemove.CreatedAt,
        CreatedBy: commentToRemove.CreatedBy,
      },
    });
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const AddComment = () => {
    setValidationForm({
      ...validationForm,
      isActiveValidation: true,
      comment: comment.length > 0 ? false : true,
    });
    if (comment.length > 0) {
      setIsLoading(true);
      dispatch({
        AddComment: {
          ProjectId: projectId,
          Comment: comment,
        },
      });
      setComment('');
      setIsLoading(false);
    }
  };

  return (
    <div>
      {comments.map(function (comment, i) {
        return (
          <div key={i} className={classes.comments}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar>
                  {comment.CreatedByNavigation.FirstName.charAt(0) +
                    comment.CreatedByNavigation.LastName.charAt(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={comment.Comment}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {comment.CreatedByNavigation.FirstName +
                        ' ' +
                        comment.CreatedByNavigation.LastName}
                    </Typography>
                  </React.Fragment>
                }
              />
              {currentUserLogged.UserId == comment.CreatedByNavigation.UserId ? (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={handleRemoveComment(comment)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : (
                  ''
                )}
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
        );
      })}
      <Grid item xs={12} md={12} lg={12}>
         <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
          error={validationForm.isActiveValidation && validationForm.comment}
          className={classes.textbox}
          id="comment"
          label="Comment"
          multiline
          rows={4}
          value={comment}
          variant="outlined"
          inputprops={{ maxLength: 450 }}
          onChange={handleChangeComment}
          disabled={isLoading}
        />
      </Grid>
      <Button
        className={classes.addButton}
        variant="contained"
        color="primary"
        endIcon={isLoading ? <CircularProgress /> : <Icon>add</Icon>}
        onClick={AddComment}
        disabled={isLoading}
      >
        Comment
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{'Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this comment?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOkModal} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

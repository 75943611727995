import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Paper,
    Typography,
    List,
    ListItem,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        paddingTop: '0',
        border: 'solid 0px red',
        fontFamily: 'Ebrima'
    },
    formControl: {
        width: '98%',
        margin: theme.spacing(1),
        minWidth: 120,
        fontFamily:'Ebrima',
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
        fontFamily:'Ebrima',
      },
}));

export default function LifeSafetyIssues(props) {
  const classes = useStyles();
  const [titleHovered, setTitleHovered] = React.useState(false);
  const [cardHovered, setCardHovered] = React.useState(null);
  const { 
    lifeSafetyData,
    lifeSafetyProperties,
    propertySelect,
   } = props;

   const labelStyle = {
     padding: '1%',
     color: 'darkGrey',
     fontFamily: 'Ebrima',
   };

   const headerStyle = {
    color: 'white',
    fontFamily: 'Ebrima',
  };

   const numberStyle = {
     padding: '1%',
     color: 'black', 
     fontWeight: 'bold',
     fontFamily: 'Ebrima',
   };
   
    return (
        lifeSafetyProperties.length > 0?
        
            
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '1%' }}>
              <Paper style={{width: '100%', padding: '1%'}}>
                <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                  <Grid container direction='row' alignItems='center' justify='center'>
                    <Grid item xs={12} style={{ width: '100%', backgroundColor: 'red', textAlign: 'center'}}>
                      <Typography variant='h6' style={headerStyle}>ATTENTION:</Typography>
                      <Typography variant='body2' style={headerStyle}>{lifeSafetyData.LifeSafetyIssuesCount} {lifeSafetyProperties.Lenght>1?" LSI's reported":" LSI reported"}</Typography>
                    </Grid>
                  </Grid>
                  <Grid direction='row' style={{ width: '100%', display: 'flex' }}>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label-id" style={{fontFamily:'Ebrima', fontSize:16}} >Life Safety Properties</InputLabel>
                        <Select
                            labelId="demo-simple-select-label-li"
                            id="demo-simple-select-id"
                            value={0}
                            onClick={propertySelect}
                            style={{fontFamily:'Ebrima'}} 
                        >
                            <MenuItem value={0} style={{ height: '2rem', fontFamily:'Ebrima' }}>Select Property</MenuItem>
                            { lifeSafetyProperties.map((property) => {
                            return(
                                <MenuItem value={property} style={{fontFamily:'Ebrima'}} >{ property.Name } LSI Count:{property.LSICount}</MenuItem>
                            )
                            }) }
                        </Select>
                        </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          :""
    );
}

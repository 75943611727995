import React from 'react';
import EditWorkOrderCommentCard from './EditWorkOrderCommentCard';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function EditWorkOrderCommentsList(props) {
  const { 
    comments,
    activeUser,
    dispatchUpdateComment, 
    dispatchDeleteComment 
  } = props;

  const classes = useStyles();
  return (
    <List className={classes.root} style={{ height: '40rem', maxHeight: '31.5rem', overflow: 'auto' }}>
      { comments.map((comment) => {
        return (
          <EditWorkOrderCommentCard 
            comment={comment}
            comments={comments}
            activeUser={activeUser}
            dispatchUpdateComment={dispatchUpdateComment}
            dispatchDeleteComment={dispatchDeleteComment}
          /> 
        )
      }) }
    </List>
  );
}

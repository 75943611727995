import React, { useState, useEffect } from 'react';
import CreateWOItemSectionList from './SubComponents/CreateWOItemSectionList';
import {
  Grid,
  TextField,
  List,
  ListItem,
  Paper,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

export default function CreateWorkOrderItem(props) {
  const [ name, setName ] = useState(null);
  const [ description, setDescription ] = useState(null);
  const { 
    retrieveName,
    retrieveDescriptions,
    propertySections,
    retrieveSelectedSections,
    editingWorkOrderItem,
    editedWorkOrderItem,
    currentStep,
    previousCurrentStep,
    handleDisableNextButton,
  } = props;
  const classes = useStyles();

  const handleNameChange = e => {
    const text = e.target.value;
    setName(text);
  };

  const handleDescriptionChange = e => {
    const text = e.target.value;
    setDescription(text);
  };

  useEffect(() => {
    if (name) {
      handleDisableNextButton(false);
      retrieveName(name);
    } else {
      handleDisableNextButton(true);
    }
  }, [name]);

  useEffect(() => {
    if (description) {
      retrieveDescriptions(description);
    }
  }, [description]);

  useEffect(() => {
    if (editingWorkOrderItem) {
      if (currentStep != previousCurrentStep) {
        const {
          Name,
          Recommendation,
        } = editingWorkOrderItem;

        setName(Name);
        setDescription(Recommendation);  
      }
    }
  }, [editingWorkOrderItem]);

  useEffect(() => {
    if (editedWorkOrderItem) {
      if (JSON.stringify(editedWorkOrderItem) != JSON.stringify(editingWorkOrderItem)) {
        const {
          Name,
          Recommendation,
        } = editedWorkOrderItem;
        setName(Name);
        setDescription(Recommendation);
      }
    }
  }, [editedWorkOrderItem]);

  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Paper style={{minWidth: '30%'}}>
        <Grid item xs={12} style={{margin: '2%'}}>
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            id="filled-uncontrolled"
            label="Name"
            variant="filled"
            onChange={handleNameChange}
            value={name}
            InputLabelProps={{
              shrink: true,
            }}
          />    
        </Grid>
        <Grid item xs={12} style={{ margin: '2%' }}>
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            id="filled-uncontrolled"
            label="Desciption"
            variant="filled"
            onChange={handleDescriptionChange}
            value={ description }
            InputLabelProps={{
              shrink: true,
            }}
          />    
        </Grid>
        <Grid item xs={12} style={{ margin: '2%' }}>
          <CreateWOItemSectionList             
            retrieveSelectedSections={retrieveSelectedSections}
            propertySections={propertySections}
            editingWorkOrderItem={editingWorkOrderItem}
            editedWorkOrderItem={editedWorkOrderItem}
            currentStep={currentStep}
            previousCurrentStep={previousCurrentStep}
          />
        </Grid>
      </Paper>
    </Grid>
  )
}
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';

export default function SimpleSelect(props) {
  const [ name, setName ] = useState('');
  const [ address, setAddress ] = useState('');
  const [ line2, setLine2 ] = useState('');
  const [ line3, setLine3 ] = useState('');

  const { selectedProperty } = props;

  useEffect(() => {
    if (selectedProperty) {
      const {
        Name, 
        StateName, 
        Address1, 
        Address2, 
        City, 
        Zip,
      } = selectedProperty;
      setName(Name);
      Address2 ? setAddress(`${Address1} ${Address2}, ${City}, ${StateName} ${Zip}`) : setAddress(`${Address1}, ${City}, ${StateName} ${Zip}`);
    }
  }, [selectedProperty]);
  
  return (
    <Grid container direction='row' style={{ height: '100%' }}>
      <Grid item xs={12} style={{padding: '2%', height: '100%'}}>
        <Paper style={{boxShadow: 'none', height: '100%'}}>
          <Grid container direction='row'>
            <Grid item xs={12} style={{ textAlign: 'center', fontWeight: 'bold' }}>
              <Typography variant='h6'>
                { name }
              </Typography>
            </Grid>
            <Divider />
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant='subtitle1'>
                { address }
              </Typography>
            </Grid>
          </Grid>
         
        </Paper>
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const emptyCompany = {
  CompanyId: 0,
  Name: '',
  Selected: false,
  Visible: false,
};

//null, int
export default function FromCompanySelect(props) {
  const [ value, setValue ] = useState(null);
  const {
    handleSelectCompany,

    Companies,
  } = props;
  
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (value != newValue) {
      setValue(newValue);
    }
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value != previousValue) {
      if (value) {
        const selectedCompany = Companies.find(Company => Company.CompanyId == value);
        handleSelectCompany(selectedCompany);
      } else {
        console.log(emptyCompany);
        handleSelectCompany(emptyCompany);
      }
    }
  }, [value]);

  const classes = useStyles();
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">Select Company</InputLabel>
          <Select
            native
            value={value}
            onChange={handleChange}
            inputprops={{
              name: 'age',
              id: 'age-native-simple',
            }}
          >
            <option aria-label="None" value={null} />
              { Companies.map((Company, i) => {
                const name = Company.Name;
                const id = Company.CompanyId;
                const key = `${i}=${id}`;
                return (
                  <option key={key} value={id}>{name}</option>
                )
              }) }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

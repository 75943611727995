/* eslint-disable no-dupe-keys */
import React from 'react';
import NewProject from '../components/Project/AddProject';
import dotnetify from 'dotnetify';
import CircularProgress from '@material-ui/core/CircularProgress';

class AddProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = dotnetify.react.connect('AddProjectPage', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        console.log(ex);
          auth.signOut();
      }
    });
    
    //this.vm = dotnetify.react.connect('User', this);
    this.dispatch = (state) => this.vm.$dispatch(state);
    this.routeTo = (route) => this.vm.$routeTo(route);

    this.state = {
      Users: [],
      Properties: [],
      ShowNotification: false,
      NavRoutes: [],
      ProjectSelected: [],
      Types: [],
      PhaseTemplates: [],
      WoMasters: [],
      Inspections: [],
      CurrentUserLogged: [],
      ProjectView: [],
      SystemTypeInfo: [],
      Companies: [],
      CurrentCompanyId: 0,
    };
  }

  componentWillUnmount() {
    this.vm.$destroy();
  }

  render() {
    let {
      NotificationMessage,
      Users,
      ShowNotification,
      Properties,
      ProjectSelected,
      Token,
      IdentityId,
      Types,
      PhaseTemplates,
      WoMasters,
      Inspections,
      NavRoutes,
      CurrentUserLogged,
      SystemTypeInfo,
      Companies,
      CurrentCompanyId,
    } = this.state;

    if (Users.length < 1 && SystemTypeInfo.length < 1) {
      return (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            transform: '-webkit-translate(-50%, -50%)',
            transform: '-moz-translate(-50%, -50%)',
            transform: '-ms-translate(-50%, -50%)',
          }}
        >
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <div>
          <NewProject
            companies={Companies}
            types={Types}
            dispatch={this.dispatch}
            vm={this.vm}
            users={Users}
            properties={Properties}
            projectSelected={ProjectSelected}
            phaseTemplates={PhaseTemplates}
            token={Token}
            identityId={IdentityId}
            showNotification={ShowNotification}
            notificationMessage={NotificationMessage}
            woMasters={WoMasters}
            inspections={Inspections}
            navRoutes={NavRoutes}
            currentUserLogged={CurrentUserLogged}
            systemTypeInfo={SystemTypeInfo}
            currentCompanyId={CurrentCompanyId}
          />
        </div>
      );
    }
  }
}

export default AddProjectPage;

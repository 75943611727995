import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  Typography,
  CardMedia,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

export default function LeakResponseDocumentCard(props) {
  const {
    document,
  } = props;

  const handleRemoveClick = (preview) => {
    // handleRemoveDocument(preview);
  };

  const {
    FileName,
    FileUrl,
  } = document;

  const nameArray = FileName.split('.');
  const fileType = nameArray[nameArray.length-1];
  const previewUrl = `https://bluefinfiles.s3.us-west-2.amazonaws.com/${FileUrl}`;

  console.log(document)
  console.log(fileType)

  fileType === 'png' ? console.log('iamge') : console.log('icon')

  return (
    <Grid container direction='row' justify='center' alignItems='center'>
      <Grid item xs={12}>
        <Card style={{ height: '20rem', width: '100%', backgroundColor: '#66BFFA' }}>
          <Grid container direction='row' style={{ height: '100%' }}>
            <Grid item xs={12} style={{ height: '100%', display: 'table' }}>
              <Grid container direction='row' alignItems='center' justify='center' style={{ display: 'table-header-group' }}>
                <Grid item xs={12} style={{ textAlign: 'center', width: '100%', height: '100%', overflow: 'hidden' }}>
                  <Typography variant='subtitle1' style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{FileName}</Typography>
                </Grid>
              </Grid>
              <Grid container direction='row' style={{ display: 'table-row' }}>
                <Grid item xs={12} style={{ height: '100%' }}>
                  { fileType == 'png' || 'jpg' || 'jpeg' ?
                    <CardMedia
                      style={{ height: '100%', padding: '7%', backgroundColor: 'white' }}
                      image={previewUrl}
                    />
                    :
                    <CardMedia
                      style={{ height: '100%', padding: '7%', backgroundColor: 'white' }}
                      // image={previewUrl}
                      children={
                        <IconButton disabled={true} style={{ height: '100%', width: '100%' }}>
                          <InsertDriveFileIcon style={{ height: '100%', width: '100%' }} />
                        </IconButton>
                      }
                    /> }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
} from '@material-ui/core';
import ManageContactsAddUserButtonStyle from '../Buttons/Styles/ManageContactsAddUserButtonStyle';

export default function Icons(props) {
  const [ loading, setLoading ] = React.useState(true);

  const style = ManageContactsAddUserButtonStyle();
  const { button, input } = style;
  const { addContactToPropertyClick, userBuildingsContacts } = props;

  useEffect(() => {
    userBuildingsContacts ? setLoading(false) : null;
  });

  return (
      <div>
        <Grid item align={'center'} xs={12}>
          <Button
            disabled={ loading ? true : false }
            className={ button }
            variant="contained"
            color="primary"
            onClick={() => addContactToPropertyClick()}
          >Add Contact To Property
          </Button>
        </Grid>
      </div>
  );
}

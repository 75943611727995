import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import ManageContactsLoading from './ManageContactsLoading';
import auth from '../../auth';

import {
  Grid,
  Paper,
  Typography,
  List,
} from '@material-ui/core';
import ManageContactsBldgsCard from './ManageContactsBldgsCard';

class ManageContactsBldgsContainer extends Component {
  constructor(props) {
    super(props)
  

    this.vm = dotnetify.react.connect('ManageContactsBldgsContainer', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        console.log(ex);
          auth.signOut();
      }
    });

    this.vm.onRouteEnter = (path, template) => (template.Target = 'Contact-Content');
    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
      loading: null,
      pleaseSelect: true,
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { selectedContact, userBuildingsContacts } = this.props;
    const currentLoadStatus = prevState.loading;
    const previouslySelectedContact = prevProps.selectedContact;

    if (selectedContact) {
      if (!userBuildingsContacts) {
        if (!currentLoadStatus) {
          this.setState({
            loading: true,
          })
        } 
      } 

      if (userBuildingsContacts) {
        if (currentLoadStatus) {
          this.setState({
            loading: false,
          })
        }
      }
    }

    if (selectedContact != previouslySelectedContact) {
      if (!userBuildingsContacts) {
        this.setState({
          loading: true,
        })
      } else {
        this.setState({
          loading: false,
        })
      }
    }
  }

  componentWillUnmount = () => {
    console.log('ManageContactsBldgsContainer is UNMOUNTING')
    this.vm.$destroy();
  }

  render() {
    const { userBuildingsContacts, handleRemoveBuilding } = this.props;
    const { loading } = this.state;
    
    console.log(userBuildingsContacts);
    return (
      <Grid id='Contact-Content' style={{display: 'contents', marginRight: '20%'}}>
        <Grid id='properties-column-grid' style={{ display: 'contents', margin: '5%' }} spacing={3} xs={12} sm={12} md={9} lg={9} item>
          <Paper style={{ width: '60%', backgroundColor: '#E9E9E9', marginLeft: '1%' }}>
            <Typography>Properties: </Typography>
            { loading ? <ManageContactsLoading /> :
              <List style={{ height: '37rem', overflowY: 'scroll' }}>
                { userBuildingsContacts ?
                  userBuildingsContacts.map(building => <ManageContactsBldgsCard building={ building } handleRemoveBuilding={ handleRemoveBuilding } />)
                  : <Typography variant={'h1'} style={{ color: '#DDDDDD', textAlign: 'center', marginTop: '20%', marginBottom: '20%' }}>Select a Contact</Typography>}
              </List>
            }
          </Paper>
        </Grid>
      </Grid>
    )
  }
};

export default ManageContactsBldgsContainer
import React from 'react';
import {
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <Grid container direction='row' alignItems={'center'} justify={'space-between'} style={{ height: '12rem', width: '100%' }}>
      <Grid item xs={4} style={{ height: '100%', width: '100%' }}></Grid>
      <Grid item xs={4} style={{ height:'100%', width: '100%', maxHeight: '8rem', textAlign: 'center' }}>
        <div className={classes.root}>
          <CircularProgress variant={ 'indeterminate' } size={ '8rem' } />
        </div>
      </Grid>
      <Grid item xs={4} style={{ height: '100%', width: '100%' }}></Grid>
    </Grid>
  );
}

import React, { useEffect, useState, useReducer, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  Avatar,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
    fontFamily: 'Ebrima',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  avatar: {
    backgroundColor: '#00C2F3',
  },
}));

export default function PropertyNoRepairsCard() {
  const classes = useStyles();
  
  return (
    <Card key={0} square className={classes.card}>
      <CardHeader classes={{title: classes.root, subheader: classes.root}}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            <CheckCircleIcon />
          </Avatar>
        }
        title={'No Repairs'}
      />
    </Card>
  );
}

import React from 'react';
import dotnetify from 'dotnetify';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from 'material-ui/MenuItem';
import MenuItemCore from '@material-ui/core/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import Button from '@material-ui/core/Button';
import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';
import { green, green400, green600, grey50, pink500, grey200, grey300, grey500, grey400, pink400 } from 'material-ui/styles/colors';
// import BasePage from '../components/BasePage';
import ThemeDefault from '../styles/theme-default';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, TableCell } from 'material-ui/Table';
import InlineEdit from '../components/table/InlineEdit';
import InlineEditSpan from '../components/table/InlineEditSpan';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import OrgPermissions from '../components/OrgPermissions';
import { RouteLink } from 'dotnetify';
import Pagination from '../components/table/Pagination';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import Snackbar from 'material-ui/Snackbar';
import IconRemove from 'material-ui/svg-icons/content/clear';
import IconEdit from 'material-ui/svg-icons/content/create';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import TextFieldCore from '@material-ui/core/TextField';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Divider from '@material-ui/core/Divider';
import globalStyles from '../styles/styles';
import Paper from 'material-ui/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import auth from '../auth';
import ProfilePhoto from '../components/ProfilePhoto';
import { DropzoneDialog } from 'material-ui-dropzone';
import S3FileUpload from 'react-s3';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FileFolderShared } from 'material-ui/svg-icons';
import * as AWS from 'aws-sdk';


 const GreenCheckbox = withStyles({
          root: {
            color: '#8DC63F',
            '&$checked': {
              color: '#8DC63F',
            },
          },
          checked: {},
        })(props => <Checkbox color="default" {...props} />);


class ProfileFormPage extends React.Component {
  constructor(props) {
      super(props);
      this.props = props;
    this.vm = dotnetify.react.connect('ProfileForm', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        auth.signOut();
      }
    });
    this.dispatch = state => this.vm.$dispatch(state);
      this.routeTo = route => this.vm.$routeTo(route);
    
     
    this.state = {
      dirty: false,
        updatepassdirty: false,
        UserId: '',
      Email: '',
      Phone1: '',
      Phone2: '',
      FirstName: '',
      LastName: '',
      Add1: '',
      Add2: '',
      City: '',
      Zip: '',
      State: '',
      Country: '',
      RoleId: '',
      RoleTitle: '',
      PortalUserOrgUnits: [],
      ContactTypes: [],
      Rank: 10,
      CurrentPassword: '',
      NewPassword: '',
      ConfirmPassword: '',
      ChangePassword: true,
      EmailUserLogin: true,
      minlength: false,
      lower: false,
      upper: false,
      number: false,
      IdentityId: '',
      Token: '',
      special: false,
        confirmed: false,
        ProfilePhotoUrl: "",
        uploading: false,
        open: false,
            files: []

    };
  }


  componentWillUnmount() {
    this.vm.$destroy();
  }

  render() {
      let { sidebarOpen, dirty, updatepassdirty, UserId, Email, Phone1, Phone2, FirstName, LastName, Add1, Add2, City, Zip, State,
          Country, RoleId, RoleTitle,
          PortalUserOrgUnits, ShowNotification, NotificationMessage, ContactTypes, 
          Rank, CurrentPassword, NewPassword, ConfirmPassword, ChangePassword, minlength, lower, upper, number, special, confirmed, ProfilePhotoUrl, uploading, IdentityId, Token} = this.state;

      const countrylist = ['US', 'CA', 'UK', 'MX', 'UM' ];

      const styles = {

       color: green400,
       passwordprompts: {
           fontSize: 14,
           fontFamily: 'Ebrima',
          },
      countryStateDropdown: {
         backgroundColor: 'black',
         fontSize: 16,
         width:260,
         height:40,
         marginTop:5},
         fontFamily: 'Ebrima',
      countryStateContainer: {
              marginTop: 10,
              fontFamily: 'Ebrima',
          },
      countryStateLabel: {
            fontSize: 12,
            fontFamily: "Ebrima",
        },
      countryStateContainer: {
            marginTop: 10,
            fontFamily: 'Ebrima',
        },
      formControl: {
        margin: ThemeDefault.spacing.unit,
        minWidth: 220,
        maxWidth: 300,
        display: 'flex',
        wrap:'nowrap',
        fontFamily: 'Ebrima',
      },      
      header: { paddingLeft: sidebarOpen ? paddingLeftSidebar : 0 },
      selectLabel: { color: pink400,  fontFamily: 'Ebrima', },
      toggleDiv: {
        maxWidth: 300,
        marginTop: 40,
        marginBottom: 5,
        fontFamily: 'Ebrima',
      },
      toggleLabel: {
        color: grey400,
        fontWeight: 100,
        fontFamily: 'Ebrima',
      },
      buttons: {
          marginTop: 10,
          marginLeft: 40,
          fontFamily: 'Ebrima',
        float: 'right'
        },
      names: {
          marginTop: 0,
          marginLeft: 0,
          marginRight:10,
          fontFamily: 'Ebrima',
          float: 'none',
          fontFamily: 'Ebrima',
          },
      divider: {
          marginTop: 5,
          marginLeft: 0,
          marginRight:0,
          marginBottom: 5,
          fontFamily: 'Ebrima',
          float: 'none'
          },
      columns: {
        Assigned: { width: '5%', paddingRight: 5, paddingLeft: 5,  fontFamily: 'Ebrima',},
        Name: { width: '80%', paddingRight: 5, paddingLeft: 5,  fontFamily: 'Ebrima',}
        },
      textField: {
        marginLeft: 20,
        marginRight: 20,
        width: 400,
        fontFamily: 'Ebrima',
      },
      clear: {
        height: 0,
          borderWidth: 0,
          float: 'left',
          fontFamily: 'Ebrima',
      },
      formControl: {
            margin: 25,
            fontFamily: 'Ebrima',
          },
      saveButton: { marginLeft: 5,  fontFamily: 'Ebrima', },
      removeIcon: { fill: grey50,  fontFamily: 'Ebrima', },
          editIcon: { fill: grey50,  fontFamily: 'Ebrima', },
      card: {
          maxWidth: 345,
          fontFamily: 'Ebrima',
          },
          photoSpan: { float: 'right',  fontFamily: 'Ebrima', },
          uploadingDiv: {

              marginLeft: 25,
              marginRight: 25,
              marginBottom: 10,
              marginTop: 10,
              textAlign:'center',
              fontFamily: 'Ebrima',
             
          },
          uploadingContent: {
              paddingBottom: 10,
              paddingTop: 10,
              textAlign: 'center',
              fontFamily: 'Ebrima',
          }
         

    }

      const ITEM_HEIGHT = 28;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 20.5 + ITEM_PADDING_TOP,
          width: 350,
           fontFamily: 'Ebrima',
        },
      },
    };

    const handleCancel = _ => {
      this.dispatch({ Cancel: UserId });
       this.setState({ dirty: false, adddirty: false });
    };

      const handleSave = _ => {
        let newState = PortalUserOrgUnits.filter(item => (item.View === false && item.Edit === false));

        let newPUOU = PortalUserOrgUnits.filter(item => (item.View === true || item.Edit === true));

           console.log("COUNT:" + PortalUserOrgUnits.length);
          this.dispatch({ Save: { UserId: UserId, Email: Email, Phone1: Phone1, Phone2: Phone2, FirstName: FirstName, 
              LastName: LastName,Add1: Add1, Add2: Add2, City: City, 
              Zip:Zip, State: State, Country:Country, RoleId: RoleId, RoleTitle: RoleTitle, ProfilePhotoUrl: ProfilePhotoUrl,  
              PortalUserOrgUnits: PortalUserOrgUnits } });

          this.setState({ PortalUserOrgUnits: newPUOU, dirty: false, adddirty: false });

          this.props.handleProfileUpdate({LastName:LastName, FirstName:FirstName, ProfilePhotoUrl: ProfilePhotoUrl})
      };


      const handleClose = () => {
          console.log("handleClose");
        this.setState({ open: false });
    };

    const handleNavLinkClick = obj => {
      obj.e.preventDefault();
        console.log(obj.item);
        this.routeTo(obj.item.Route);
    };

     const handleSelectPage = page => {
      const newState = { SelectedPage: page };
      this.setState(newState);
      this.dispatch(newState);
    };

      const handleContactTypeChecked = contacttype => {
          let newState = ContactTypes.map(item => (item.ContactTypeId === contacttype.ContactTypeId ? Object.assign(item, contacttype) : item));
        
          console.log("what" + contacttype.yes);
          this.dispatch({ UpdateContact: {ContactTypeId : contacttype.ContactTypeId, Title: contacttype.Title, Selected: contacttype.Selected } });

          this.setState({ ContactTypes: newState });
      };

      const hideNotification = _ => this.setState({ ShowNotification: false });

      const selectCountry = val => {
        this.setState({Country: val, State:'', dirty:true });
      }

     const selectRegion = val => {
        this.setState({State: val, dirty:true });
     }

    const validatePasswords = _ =>{

        var errors = '';

        if(CurrentPassword ==null || CurrentPassword.length<8){

          errors = 'The user must enter their current password.';
            this.setState({mincurrentpass: false})
        } else {
           this.setState({mincurrentpass: true})
        }

        if (NewPassword ==null || NewPassword.length<8 ) {
            //Check capital characters
            errors = 'The password must contain 8 or more characters.';
            this.setState({minlength: false})
        } else {
           this.setState({minlength: true})
        }

        if (!(/([A-Z]+)/g.test(NewPassword))){
            errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
            this.setState({upper: false})
        } else {
            this.setState({upper: true})
        }

        if (!(/([a-z]+)/g.test(NewPassword))){
            errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
            this.setState({lower: false})
        } else {
            this.setState({lower: true})
        }

        if (!(/([0-9]+)/g.test(NewPassword))){
            errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
            this.setState({number: false})
        } else {
            this.setState({number: true})
        }

        if (!(/[!@#$%^&*(),.?":{}|<>]/.test(NewPassword))){
            errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
            this.setState({special: false})
        }else {
            this.setState({special: true})
        }

        if (ConfirmPassword != null && NewPassword != null){
            if (ConfirmPassword == NewPassword) {
                this.setState({confirmed: true});
                confirmed = true;
            } else {
                this.setState({confirmed: false})
                errors = 'The password must match the confirmed password.';
                confirmed = false;
            }
        } else {
            this.setState({confirmed: false});
            errors = 'The password must match the confirmed password.';
        }


        console.log("ConfirmPassword:" + ConfirmPassword + " NewPassword:" + NewPassword)
        console.log(ConfirmPassword == NewPassword)
        console.log("confirmed:" + confirmed + " special:" + special + " number:" + number + " upper:" +  upper + " lower:" + lower + " MinLength:" +  minlength)  
        if(confirmed && special && number && upper && lower && minlength) {
            this.setState({updatepassdirty: true});
        } else {
            this.setState({updatepassdirty: false});
        }     
    }

    const updatePasswordChange = val => {
        NewPassword = val.NewPassword;
        this.setState({NewPassword: val.NewPassword});
        validatePasswords();
    }

    const updateCurrentPasswordChange = val => {
      CurrentPassword = val.CurrentPassword;
      this.setState({CurrentPassword: val.CurrentPassword});
      validatePasswords();
    }

    const updateConfirmPasswordChange = val => {
        ConfirmPassword = val.ConfirmPassword;
        this.setState({ConfirmPassword: val.ConfirmPassword});

        validatePasswords();
    }

      const cancelPasswordUpdate = _ => {
          this.setState({updatepassdirty: false, CurrentPassword: '', ConfirmPassword: '', NewPassword: '', special: false, number:false, lower:false, upper: false, minlength: false});
      }

      const updatePassword = _ => {
          this.dispatch({UpdatePassword: {UserId: UserId, Password: NewPassword, CurrentPassword: CurrentPassword}});
          this.ConfirmPassword = '';
          this.NewPassword = '';
          this.CurrentPassword = '';
          this.setState({updatepassdirty: false, CurrentPassword: '', ConfirmPassword: '', NewPassword: '', special: false, number:false, lower:false, upper: false, minlength: false});
      }

      const uploadingHandler = item => {
        console.log(item);
        this.setState({ uploading: item.uploading });
      }


      const deleteFileHandler = file => {
          console.log(file);
      }

    const handleCloseProfile = _ => {
        this.setState({
            open: false
        });
    }    
 
    const handleOpenProfile = _ => {
        this.setState({
            open: true,
        });
    }

    const handleSaveProfile = files => {
      console.log(files);
      var file = files[0];

      this.setState({
                  open: false,
                  uploading: true
              });
              
              
      
      const dirName = `${UserId}`;
  
      AWS.config.region = 'us-west-2';
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
        IdentityId: IdentityId,
        Logins: {
          'cognito-identity.amazonaws.com': Token
        }
      });
  
      const s3 = new AWS.S3;
  
      s3.config.region = 'us-west-2';
      s3.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
        IdentityId: IdentityId,
        Logins: {
          'cognito-identity.amazonaws.com': Token
        }
      });
  
      const blob = new Blob([file], { type: 'text/plain' });
  
      const parameters = {
        bucket: 'portalprofiles',
        key: dirName,
        s3: s3,
        stream: blob,
        acl: 'public-read',
        contentType: file.type
      };
  
      uploadBlob(parameters)
        .then(response => {
          console.log(response);
              console.log(response.Location);
              this.setState({
                  files: files, 
                  ProfilePhotoUrl: response.Location,
                  uploading: false
              });

               this.dispatch({ Save: { UserId: UserId, Email: Email, Phone1: Phone1, Phone2: Phone2, FirstName: FirstName, 
                  LastName: LastName,Add1: Add1, Add2: Add2, City: City, 
                  Zip:Zip, State: State, Country:Country, RoleId: RoleId, RoleTitle: RoleTitle, ProfilePhotoUrl: response.Location,  
                  PortalUserOrgUnits: PortalUserOrgUnits } });

              this.props.handleProfileUpdate({LastName:LastName, FirstName:FirstName, ProfilePhotoUrl: response.Location})

        })
        .catch(err => {
          console.error(err);
          props.uploadingHandler({ uploading: false });
        });    
    };
  
    const uploadBlob = async (parameters) => {
      const {
        bucket,
        key,
        s3,
        stream,
        acl,
        contentType
      } = parameters;
      const params = { Bucket: bucket, Key: key, Body: stream, ACL: acl, ContentType: contentType };
      return s3.upload(params).promise();
    };



      return (
          Email!==null&&Email.length>0?(
      <MuiThemeProvider muiTheme={ThemeDefault}>
             
                  <form >
                      <div style={{paddingTop:0, paddingBottom:20}}> 
                      <Paper style={globalStyles.paper}>
                          <div style={{marginTop: 0,  fontFamily: 'Ebrima',}}>
                                   <span style={styles.photoSpan}>
                                      <ProfilePhoto
                                          handleOpenProfile={handleOpenProfile}
                                          uploadingHandler={uploadingHandler}
                                          ProfilePhotoUrl={ProfilePhotoUrl}
                                          UserId={UserId}
                                          uploading={uploading}
                                          styles={styles.header} />

                                        <div>
                                            <DropzoneDialog
                                                open={this.state.open}
                                                onSave={handleSaveProfile}
                                                acceptedFiles={['image/jpeg', 'image/png']}
                                                showPreviews={true}
                                                maxFileSize={5000000}
                                                filesLimit={1}
                                                onClose={handleCloseProfile}
                                          />
                                          <Dialog open={uploading} aria-labelledby="simple-dialog-title" >

                                              <div style={styles.uploadingDiv} >
                                                  <div style={styles.uploadingContent}>
                                                      Uploading...
                                                      </div>
                                                  <div style={styles.uploadingContent}>
                                                      <CircularProgress color={grey500} />
                                                  </div>
                                                </div>
                                            </Dialog>
                                        </div>
                              </span>
                              </div>

                      <div>
                          <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}  style={{ fontFamily: 'Ebrima',}}
                                          hintText="Enter Email Address or Username"
                                          disabled={true}
                      floatingLabelText="Email/Username"
                                fullWidth={false}         
                      value={Email}
                      onChange={event => this.setState({Email: event.target.value, dirty: true })}
                    />
                              </span>
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}  style={{ fontFamily: 'Ebrima',}}
                      hintText="Enter first name"
                      floatingLabelText="First Name"
                                fullWidth={false}         
                      value={FirstName}
                      onChange={event => this.setState({FirstName: event.target.value, dirty: true })}
                    />
                </span>
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}  style={{ fontFamily: 'Ebrima',}}
                      hintText="Enter last name"
                      floatingLabelText="Last Name"
                      fullWidth={false}
                        value={LastName}
                        onChange={event => this.setState({LastName: event.target.value, dirty: true })} />

                </span>
                          </div>
            <div >
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                       style={{ fontFamily: 'Ebrima',}}
                      hintText="Enter Primary Phone"
                      floatingLabelText="Primary Phone"
                                fullWidth={false}         
                      value={Phone1}
                      onChange={event => this.setState({Phone1: event.target.value, dirty: true })}
                    />
                </span>
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}  style={{ fontFamily: 'Ebrima',}}
                      hintText="Enter Secondary Phone"
                      floatingLabelText="Secondary Phone"
                                fullWidth={false}         
                      value={Phone2}
                      onChange={event => this.setState({ Phone2: event.target.value, dirty: true })}
                    />
                </span>
            </div>
            <div >
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}  style={{ fontFamily: 'Ebrima',}}
                      hintText="Enter address"
                      floatingLabelText="Address"
                      fullWidth={false}
                      value={Add1}
                      onChange={event => this.setState({ Add1: event.target.value, dirty: true })}
                             />
                </span>
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}  style={{ fontFamily: 'Ebrima',}}
                      hintText="Address cont."
                      floatingLabelText="Address Cont."
                      fullWidth={false}
                      value={Add2}
                      onChange={event => this.setState({Add2: event.target.value, dirty: true })}
                                />
                </span> 
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}  style={{ fontFamily: 'Ebrima',}}
                      hintText="Enter city"
                      floatingLabelText="City"
                      fullWidth={false}
                      value={City}
                      onChange={event => this.setState({ City: event.target.value, dirty: true })}
                                        />
                </span>
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}  style={{ fontFamily: 'Ebrima',}}
                      hintText="Enter postal code"
                      floatingLabelText="Postal Code"
                      fullWidth={false}
                      value={Zip}
                      onChange={event => this.setState({ Zip: event.target.value, dirty: true })}
                                        />
                            </span>
                </div>
           
                <div style={styles.countryStateContainer}>
                    <span style={styles.names}>
                        <FormControl className={styles.formControl}>
                        <FormLabel style={styles.countryStateLabel}>Country</FormLabel>
                        <CountryDropdown
                            value={Country}
                            whitelist={countrylist}
                                              onChange={selectCountry} 
                                              countryValueType="short"
                            style={styles.countryStateDropdown}/>
                        </FormControl>
                    </span>
                    <span style={styles.names}>
                        <FormControl className={styles.formControl}>
                            <FormLabel style={styles.countryStateLabel}>State/Region</FormLabel>
                            <RegionDropdown
                                country={Country}
                                value={State}
                                onChange={selectRegion}
                                style={styles.countryStateDropdown}/>
                        </FormControl>
                    </span>
                </div>
                <div>
                    <span style={styles.names}>
                        <FormControl component="fieldset"  style={{marginTop: 10}}>
                            <FormLabel component="legend" style={{fontFamily:'Ebrima'}} >Role</FormLabel>{RoleTitle}
                         </FormControl>
                    </span>
                </div>
                <div style={styles.divider}>
                      <Divider variant="fullWidth"  />
                </div>
                <div style={styles.buttons}>
                    <RaisedButton label="Cancel" onClick={handleCancel} disabled={!dirty} />
                    <RaisedButton label="Save" onClick={handleSave} disabled={!dirty} style={styles.saveButton} primary={true} />
                                  </div>
                              <div style={globalStyles.clear} />
                              </Paper></div>
                      {ContactTypes != null && ContactTypes.length>0?
                <div>      
                    <div style={{paddingTop:0, paddingBottom:20}}> 
                        <Paper style={globalStyles.paper}>
                            <div style={{marginTop: 0}}>
                                <h3 style={globalStyles.title}>Contact Types</h3>
                            </div>
                            <div>
                                    {ContactTypes!=null?ContactTypes.map(itemclient => (
                                          <div><div>{itemclient.Client}</div>




                                    {itemclient.ContactTypes!=null?itemclient.ContactTypes.map(item => (
                                              <span><Checkbox key={'cbx' + item.ContactTypeId} checked={item.Selected===true} disabled={true}
                                                  onClick={(e) => handleContactTypeChecked({ ContactTypeId: item.ContactTypeId, Title: item.Title, Selected: e.target.checked})} />{item.Title}</span>
                                              )):''}


                                              </div>
                                              )):''}


                                       


                            </div>
                            <div style={globalStyles.clear} />
                        </Paper>
                    </div>
                              </div>
                          :''}
                <div>
                                 
                          <div style={{paddingTop:0, paddingBottom:20}}> 
                      <Paper style={globalStyles.paper}>
            <div style={{marginTop: 0}}>
                          <h3 style={globalStyles.title}>Update Password</h3>
                                      </div>
                                  <div>
                                  <div>
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}  style={{ fontFamily: 'Ebrima',}}
                      id="standard-current-password-input"
                      hintText="Current Password"
                      floatingLabelText="Current Password"
                                  type="password"
                                  value={CurrentPassword}
                      fullWidth={false}
                      onChange={event =>updateCurrentPasswordChange({ CurrentPassword: event.target.value})}
                    />
                </span>
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}  style={{ fontFamily: 'Ebrima',}}
                      id="standard-password-input"
                      hintText="New Password"
                      floatingLabelText="New Password"
                                  type="password"
                                  value={NewPassword}
                      fullWidth={false}
                      onChange={event =>updatePasswordChange({ NewPassword: event.target.value})}
                    />
                </span>
                <span style={styles.names}>
                     <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}  style={{ fontFamily: 'Ebrima',}}
                      id="standard-confirm-password-input"
                      hintText="Confirm Password"
                      floatingLabelText="Confirm Password"
                                  type="password"
                                  value={ConfirmPassword}
                      fullWidth={false}
                      onChange={event => updateConfirmPasswordChange({ ConfirmPassword: event.target.value})}
                    />
                </span> 
            </div>
                            
            <div style={styles.passwordprompts}>
                                         
                    <span>Password Requirements: </span><span>
                    <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={lower} 
                            
                            value={lower}
                          />
                        }
                        label={<span style={{fontFamily: 'Ebrima',}}>{"Lower Case"}</span>}
                      />  </span><span style={{fontFamily: 'Ebrima',}} > 

                  <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={upper} 
                            
                            value={upper}
                          />
                        }
                        label={<span style={{fontFamily: 'Ebrima',}}>{"Upper Case"}</span>}
                      /></span><span style={{fontFamily: 'Ebrima',}}><FormControlLabel style={{fontFamily: 'Ebrima',}}
                        control={
                          <GreenCheckbox
                            checked={number} 
                            
                            value={number}
                          />
                        }
                        label={<span style={{fontFamily: 'Ebrima',}}>{"Number"}</span>}
                      /></span><span>
                    <FormControlLabel style={{fontFamily: 'Ebrima',}}
                        control={
                          <GreenCheckbox
                            checked={special} 
                            
                            value={special}
                          />
                        }
                        label={<span style={{fontFamily: 'Ebrima',}}>{"Special Character"}</span>}
                      /> </span><span>

                                              <FormControlLabel
        control={
          <GreenCheckbox
            checked={minlength} 
            
            value={minlength}
          />
        }
        label={<span style={{fontFamily: 'Ebrima',}}>{"Min. 8 Characters"}</span>}
      />

                                              </span>
                
            </div></div>
            <div style={styles.divider}>
                <Divider variant="fullWidth"  />
            </div>
                      <div style={styles.buttons}>
                           <RaisedButton label="Cancel" onClick={cancelPasswordUpdate} disabled={!updatepassdirty} style={styles.saveButton} primary={true} />
                <RaisedButton label="Update Password" onClick={updatePassword} disabled={!updatepassdirty} style={styles.saveButton} primary={true} />
                              </div>
                          <div style={globalStyles.clear} />
                                  </Paper>
                              </div>
                          </div>
                       {Rank > 2?
                      <Paper style={globalStyles.paper}>
                          <h3 style={globalStyles.title}>Assigned Organization Tags</h3>
            <div style={styles.buttons}>
                     
               <div>
                <FormControl component="fieldset"  style={{marginTop: 10, fontFamily: 'Ebrima',}}>
                            <FormLabel component="legend">Organization Tags</FormLabel>
                           
                    <div>
                                    <OrgPermissions styles={styles.header} 
                                        userPortalOrgUnits={PortalUserOrgUnits} 
                                        disabled={true}
                                        UserId={UserId}  />
                                    </div>
                </FormControl>
                              </div>
                          <div style={styles.divider}>
                          <Divider variant="fullWidth"  />
                    </div>
                      
                         
            
                          <Snackbar open={ShowNotification} message={NotificationMessage===null?'':NotificationMessage} autoHideDuration={1000} onRequestClose={hideNotification} />
                              </div>
                           <div style={globalStyles.clear} />
                              </Paper>
                          :''}
                      </form>
                  </MuiThemeProvider>
              ):(<div><CircularProgress /></div>)
    )
  }
}

export default ProfileFormPage;


import React, { Component } from 'react';
import {
    Typography,
    Grid
} from '@material-ui/core'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ThemeDefault from '../../../styles/theme-default';
import globalStyles from '../../../styles/styles';

import {
    CartesianGrid,
    BarChart,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ResponsiveContainer,
    LabelList,
    Label,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';


const composedChartMarginStyle = {
    right: 15,
    left: 55,
    top: 15,
    bottom: 40
};

const graphContainerStyle = {
    textAlign: 'center',
    fontFamily: 'Ebrima',
};

const headerStyle = {
    color: 'white',
    fontFamily: 'Ebrima',
};

const XAxisTickFormatter = (value) => {
    return `${value}`;
};

const YAxisTickFormatter = (value) => {
    return `${new Intl.NumberFormat('en').format(value)}`;
};

const CustomTooltipContent = (props) => {
    const payload = props.payload;

    if (props.active) {
        const newPayload = payload;

        if (payload) {
            payload.map((entry, i) => {
                const dataKey = entry.dataKey;
                return entry.formatter = (dataKey) => XAxisTickFormatter(dataKey);
            })
        }

        return <DefaultTooltipContent {...props} payload={newPayload} />
    } else {
        return <DefaultTooltipContent {...props} payload={payload} />
    }
};

class HVACCriticalityDist extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {

        const { HVACCriticalityDist } = this.props;

        return (
            <div id='graph-container' style={graphContainerStyle}>
                <Typography color='subtitle2' style={{ fontFamily: 'Ebrima', }}>Criticality Distribution</Typography>
                <ResponsiveContainer width='96%' height='100%' aspect={12.0 / 5.0} >
                    <BarChart
                        width={730}
                        height={250}
                        data={HVACCriticalityDist}
                        layout='horizontal'
                        style={{ fontFamily: 'Ebrima', }}
                        margin={composedChartMarginStyle}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type='category' dataKey="criticalityName" tickFormatter={XAxisTickFormatter} angle={-45} textAnchor="end">

                        </XAxis>
                        <YAxis type='number' dataKey="count" tickFormatter={YAxisTickFormatter}  >
                            <Label value="Number of Units" position="insideMiddleLeft" angle={-90} />
                        </YAxis>
                        <Tooltip content={<CustomTooltipContent style={{ fontFamily: 'Ebrima', }} props={HVACCriticalityDist} />} style={{ fontFamily: 'Ebrima', }} />
                        <Legend style={{ fontFamily: 'Ebrima', }} />
                        <Bar barSize={20} dataKey="count" name="Criticality Distribution" fill="#00C2F3" style={{ fontFamily: 'Ebrima', }}  >

                        </Bar>
                    </BarChart>

                </ResponsiveContainer>
            </div>
        );
    }
}

export default HVACCriticalityDist;
import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import ManageFoldersForm from './SubComponents/ManageFoldersForm';
import {
  Grid,
} from '@material-ui/core';
import auth from '../../../auth';

const emptyCompany = {
  CompanyId: 0,
  Name: '',
  Selected: false,
  Visible: false,
};

const emptyFolder = {
  NodeId: 0,
  ParentNodeId: 0,
  Name: '',
  Selected: false,
  Type: 0,
  Expanded: false,
  Lev: 0,
  CompanyId: 0,
  Folders: [],
  BuildingId: 0,
  BuildingFolders: []
};

let fakeUniqueId = 2000000000;

class ManageFolders extends Component {
  constructor(props) {
    super(props);
    this.vm = dotnetify.react.connect('ManageFolders', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        console.log(ex);
        //  auth.signOut();
      }
    });
    this.dispatch = state => this.vm.$dispatch(state);
    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
      Companies: [],
      Folders: [],
      
      addSelected: 0,
      deleteSelected: 0,
      selectedCompany: emptyCompany,
      selectedAddFolder: emptyFolder,
      selectedDeleteFolder: emptyFolder,
      addFolderName: '',
      addFolderType: 0,
      deleteNode: {},

      addBuildingName: '',
      addBuildingAddress: '',
      addBuildingAddressCont: '',
      addBuildingCity: '',
      addBuildingCountry: 0,
      addBuildingState: 0,
      addBuildingPostalCode: '',

      readyAddBuildingFolder: false,
        deleteType: 0,
        SelectedCountryId: 0,
        Countries: {},
        States: {},
        SelectedStateId:0,

    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      addBuildingName,
      addBuildingAddress,
      addBuildingAddressCont,
      addBuildingCity,
      addBuildingCountry,
      addBuildingState,
      addBuildingPostalCode,
      readyAddBuildingFolder,
      SelectedCountryId,
      Countries,
      States,
    } = this.state;
    const previousAddBuildingName = prevState.addBuildingName;
    const previousAddBuildingAddress = prevState.addBuildingAddress;
    const previousAddBuildingAddressCont = prevState.addBuildingAddressCont;
    const previousAddBuildingCity = prevState.addBuildingCity;
    const previousAddBuildingCountry = prevState.addBuildingCountry;
    const previousAddBuildingState = prevState.addBuildingState;
    const previousAddBuildingPostalCode = prevState.addBuildingPostalCode;

    if (
      addBuildingName != previousAddBuildingName ||
      addBuildingAddress != previousAddBuildingAddress ||
      addBuildingAddressCont != previousAddBuildingAddressCont ||
      addBuildingCity != previousAddBuildingCity ||
      addBuildingCountry != previousAddBuildingCountry ||
      addBuildingState != previousAddBuildingState ||
      addBuildingPostalCode != previousAddBuildingPostalCode
    ) {
      if (
        addBuildingName &&
        addBuildingAddress &&
        addBuildingCity &&
        addBuildingCountry &&
        addBuildingState &&
        addBuildingPostalCode
      ) {
        if (!readyAddBuildingFolder) {
          this.setState({
            readyAddBuildingFolder: true,
          })
        }
      } else {
        if (readyAddBuildingFolder) {
          this.setState({
            readyAddBuildingFolder: false,
          })
        }
      }
    }
  };

  handleSelectCompany = (newSelectedCompany) => {
    const { selectedCompany } = this.state;
    const selectedCompanyId = selectedCompany.CompanyId;
    const newSelectedCompanyId = newSelectedCompany.CompanyId;

    if (selectedCompanyId != newSelectedCompanyId) {
      if (newSelectedCompanyId) {
        this.setState({
          selectedCompany: newSelectedCompany,
        },
          this.dispatch({ SelectCompany: newSelectedCompany }),
          this.dispatch({ GetCompanyFolders: newSelectedCompany })
        );
      } else {
        this.setState({
          selectedCompany: emptyCompany,
        }, 
        this.dispatch({ ResetSelectedCompany: emptyCompany }),
        this.dispatch({ ResetCompanyFolders: emptyCompany })
        );
      }
    }
  };

  handleFolderSelected = (folderId) => {
          this.dispatch({ LoadFolder: folderId })   
  };

  handleAddSelected = (folderNodeId, selectedFolder) => {
    console.log(folderNodeId)
    const { Folders } = this.state;
    
    this.setState({
      addSelected: folderNodeId,
        selectedAddFolder: selectedFolder,

    });
  };

  handleAddFolderTypeSelect = (type) => {
    this.setState({
      addFolderType: type,
    })
  };
  
  handleUpdateAddFolderName = (name) => {
    this.setState({
      addFolderName: name,
    })
  };

  handleAddFolder = () => {
    const { 
      addSelected,
      addFolderName,
      selectedCompany,
    } = this.state;

    // TODO change back from fakeId
    fakeUniqueId = fakeUniqueId + 1;

    const newFolder = {
      NodeId: fakeUniqueId,
      ParentNodeId: addSelected,
      Name: addFolderName,
      Selected: false,
      Type: 0,
      Expanded: false,
        Lev: 0,
        CompanyId: selectedCompany.CompanyId,
      Folders: [],
      BuildingFolders: [] 
    };

    console.log('dispatching AddFolder')
    console.log(newFolder)
    this.setState({
      addSelected: 0,
    }, this.dispatch({ AddFolder: newFolder }));
  };

  handleAddFolderUnselected = () => {
    this.setState({
      addSelected: 0,
      selectedAddFolder: emptyFolder,
    })
  };

  handleDeleteSelected = (type, node) => {
    const { NodeId, ParentNodeId, BuildingId } = node;

    this.setState({
      deleteSelected: NodeId,
      deleteParentNode: ParentNodeId,
      deleteType: type,
      deleteNode: node,
    })
  };

  handleDeleteFolder = () => {
    const { 
        deleteSelected,
        deleteParentNode,
        selectedCompany,
    } = this.state;

    const deleteFolder = {
      NodeId: deleteSelected,
      ParentNodeId: deleteParentNode,
      Name: '',
      Selected: false,
      Type: 0,
      Expanded: false,
      Folders: [],
      CompanyId: selectedCompany.CompanyId
    };

    console.log('dispatching DeleteFolder');
      console.log(deleteFolder);

    this.setState({
      deleteSelected: 0,
      deleteParentNode: 0,
      deleteType: 0,
      deleteNode: emptyFolder,
    }, this.dispatch({ DeleteFolder: deleteFolder }));
  };

  handleDeleteUnselected = () => {
    this.setState({
      deleteSelected: 0,
      deleteParentNode: 0,
      deleteType: 0,
      deleteNode: emptyFolder,
    })
  };

  handleUpdateBuildingName = (name) => {
    this.setState({
      addBuildingName: name,
    })
  };

  handleUpdateBuildingAddress = (address) => {
    this.setState({
      addBuildingAddress: address,
    })
  };

  handleUpdateBuildingAddressCont = (addressCont) => {
    this.setState({
      addBuildingAddressCont: addressCont,
    })
  };

  handleUpdateBuildingCity = (city) => {
    this.setState({
      addBuildingCity: city,
    })
  };

    handleUpdateBuildingCountry = (country) => {

        console.log(country);

      this.setState({
          addBuildingCountry: country,
      }, this.dispatch({ SelectCountry: country }))
      
  };

  handleUpdateBuildingState = (state) => {
    this.setState({
      addBuildingState: state,
    }, this.dispatch({ SelectState: state }))
  }; 

  handleUpdateBuildingPostalCode = (postalCode) => {
    this.setState({
      addBuildingPostalCode: postalCode,
    })
  };

    handleAddBuildingFolder = () => {
        const {
            addSelected,
            addBuildingName,
            addBuildingAddress,
            addBuildingAddressCont,
            addBuildingCity,
            addBuildingCountry,
            addBuildingState,
            addBuildingPostalCode,
            selectedCompany,
        } = this.state;

        fakeUniqueId = fakeUniqueId + 1;

        const addBuildingFolder = {
            NodeId: fakeUniqueId,
            ParentNodeId: addSelected,
            Name: addBuildingName,
            Selected: false,
            Address1: addBuildingAddress,
            Address2: addBuildingAddressCont,
            City: addBuildingCity,
            Zip: addBuildingPostalCode,
            Country: `${addBuildingCountry}`,
            State: `${addBuildingState}`,
            CompanyId: selectedCompany.CompanyId,
        };

        console.log('dispatching AddBuildingFolder')
        console.log(addBuildingFolder);

        this.setState({
            addSelected: 0,
        }, this.dispatch({ AddBuildingFolder: addBuildingFolder }))
    };

  handleDeleteBuildingFolder = () => {
    const {
      deleteSelected,
      deleteParentNode,
      deleteNode,
    } = this.state;

    console.log('dispatching DeleteBuildingFolder');
    console.log(deleteNode);

    this.setState({
      deleteSelected: 0,
    }, this.dispatch({ DeleteBuildingFolder: deleteNode }));
  };

  componentWillUnmount = () => {
    console.log('unmounting ManageFolders');
    this.vm.$destroy();
  };

  render() {
    const {
      Companies,
      Folders,
      Countries,
      SelectedCountryId,
      selectedCompany,
      addSelected,
      selectedAddFolder,
      addFolderName,
      addFolderType,
      deleteSelected,
      selectedDeleteFolder,
      deleteType,
      deleteNode,
        readyAddBuildingFolder,
        States,
        SelectedStateId,
    } = this.state;

      //console.log(this.state);

    return (
      <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>
        <Grid item xs={12} style={{ height: '100%' }}>
          <ManageFoldersForm 
            handleSelectCompany={this.handleSelectCompany}
            handleAddSelected={this.handleAddSelected}
            handleAddFolderUnselected={this.handleAddFolderUnselected}
            handleUpdateAddFolderName={this.handleUpdateAddFolderName}
            handleAddFolder={this.handleAddFolder}
            handleAddFolderTypeSelect={this.handleAddFolderTypeSelect}
            handleDeleteSelected={this.handleDeleteSelected}
            handleDeleteUnselected={this.handleDeleteUnselected}
            handleDeleteFolder={this.handleDeleteFolder}
            handleDeleteBuildingFolder={this.handleDeleteBuildingFolder}
            handleFolderSelected={this.handleFolderSelected}

            handleUpdateBuildingName={this.handleUpdateBuildingName}
            handleUpdateBuildingAddress={this.handleUpdateBuildingAddress}
            handleUpdateBuildingAddressCont={this.handleUpdateBuildingAddressCont}
            handleUpdateBuildingCity={this.handleUpdateBuildingCity}
            handleUpdateBuildingCountry={this.handleUpdateBuildingCountry}
            handleUpdateBuildingState={this.handleUpdateBuildingState}
            handleUpdateBuildingPostalCode={this.handleUpdateBuildingPostalCode}
            handleAddBuildingFolder={this.handleAddBuildingFolder}

            Companies={Companies}
            Folders={Folders}
            Countries={Countries}
            States={States}
                    SelectedCountryId={SelectedCountryId}
                    SelectedStateId={SelectedStateId}
            selectedCompany={selectedCompany}
            addSelected={addSelected}
            selectedAddFolder={selectedAddFolder}
            addFolderName={addFolderName}
            addFolderType={addFolderType}
            deleteSelected={deleteSelected}
            selectedDeleteFolder={selectedDeleteFolder}
            readyAddBuildingFolder={readyAddBuildingFolder}
            deleteType={deleteType}
            deleteNode={deleteNode}
          />
        </Grid>
      </Grid>
    )
  }
};

export default ManageFolders

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Slider,
} from '@material-ui/core';

export default function DiscreteSlider(props) {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const { recordCount, selectPageSize } = props;

  const handlePageSize = (event, newValue) => {
    selectPageSize(newValue);
  };

  useEffect(() => {
    if (recordCount) {
      setMax(recordCount);
    }
  }, [recordCount]);

  useEffect(() => {
    if (recordCount > 10) {
      setMin(10);
    } else {
      recordCount ? setMin(1) : setMin(0);
    }
  }, [max]);

  return (
    <Grid container direction='row' justify={'center'} alignItems={'center'}>
      <Grid item xs={0} sm={0} md={0} lg={2} xl={1} style={{ textAlign: 'center' }}> {min} </Grid>
      <Grid item xs={5} sm={6} md={8} lg={6} xl={10} style={{ marginTop: '1%' }}>
        <Slider
          onChangeCommitted={handlePageSize}
          defaultValue={10}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={1}
          min={min}
          max={max}
          disabled={recordCount <= 10 ? true : false}
        />
      </Grid>
      <Grid item xs={0} sm={0} md={0} lg={2} xl={1} style={{ textAlign: 'center' }}> {max} </Grid>
    </Grid>

  );
}

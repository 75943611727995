import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
    fontFamily:'Ebrima',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontFamily:'Ebrima',
  },
}));

const WARRANTY_TYPE = 2;
const APPEND_TO_TAG = 2;

export default function DocumentTypeFilter(props) {
  const [value, setValue] = useState(0);
  const {
    handleTypeSelect,
    fileTypes,
    fileTypeId = 0,
  } = props;

  const handleChange = (event) => {
    handleTypeSelect(event.target.value);
  };

  
  const classes = useStyles();
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label-id" style={{fontFamily:'Ebrima'}} >Document Type Filter</InputLabel>
          <Select
            labelId="demo-simple-select-label-li"
            id="demo-simple-select-id"
            value={fileTypeId}
            onChange={handleChange}
            style={{fontFamily:'Ebrima'}} 
          >
            <MenuItem value={0} style={{ height: '2rem', fontFamily:'Ebrima' }}>All Document Types</MenuItem>
            { fileTypes.map((fileType) => {
              const id = fileType.Id;
              const name = fileType.Name;
              return(
                <MenuItem value={id} style={{fontFamily:'Ebrima'}} >{ name }</MenuItem>
              )
            }) }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

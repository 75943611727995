import React, { useEffect, useState, useReducer, useRef } from 'react';
import PropertyRepairCard from './PropertyRepairCard';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Card,
  List,
  CardHeader,
  Collapse,
  Avatar,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
var moment = require('moment');

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
    fontFamily: 'Ebrima',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  avatar: {
    backgroundColor: '#00C2F3',
  },
}));



export default function PropertyDefectGroupCard(props) {
  const classes = useStyles();
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [defects, setDefects] = useState([]);
  const { i, defectGroup, retrievedSelectedDefectsGroups, highlightSelectedDefect, selectedDefectSection, selectedDefects, getSelectedCard, selectedGroupCard } = props;
  const { GroupName, DefectCount, Defects, WBSDefId } = defectGroup;
  const groupId = WBSDefId;
  const expanded = groupId == expandedGroup;

  const prevSelectedDefectSection = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const handleExpandClick = () => {
    if (!expanded) {
      retrievedSelectedDefectsGroups(groupId);
      setExpandedGroup(groupId);
    }

    if (expanded) {
      retrievedSelectedDefectsGroups(groupId);
      setExpandedGroup(false);
    }
  };

  const handleResetExpanded = () => {
    if (expanded) {
      setDefects(Defects);
      setExpandedGroup(false);
    }
  };

  useEffect(() => {
    setDefects(Defects);
  }, []);

  const previousSelectedDefectSection = prevSelectedDefectSection(selectedDefectSection);
  useEffect(() => {
    if (selectedDefectSection != previousSelectedDefectSection) {
      if (expandedGroup) {
        handleResetExpanded();
      }
    }
  }, [selectedDefectSection]);

  useEffect(() => {
    const filterDefectsForSelectedSection = () => {
      const defectsCopy = [...new Set(Defects)];
      if (selectedDefectSection) {
        const filteredDefectsCopy = defectsCopy.filter(defect => defect.SectionId == selectedDefectSection);
        if (filteredDefectsCopy.length > 0) {
          setDefects(filteredDefectsCopy);
        }
      } else {
        setDefects(Defects);
      }
    };
    filterDefectsForSelectedSection();
  }, [expanded], [selectedDefectSection], [groupId]);

  return (
    <Card key={i} square className={classes.card}>
      <CardHeader classes={{title: classes.card, subheader: classes.card}}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            <CheckCircleIcon />
          </Avatar>
        }
        action={
          <IconButton className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more">
            <ExpandMoreIcon />
          </IconButton>
        }
        title={`${GroupName} (${DefectCount})`}
      />
      <Collapse in={expanded}>
        <Grid container direction='row'>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <List>
              { expanded ? defects.map((defect, i) => {
                const images = defect.Images;
                const name = defect.Defect;
                return (
                  <Grid container>
                    <Grid container direction='row' alignItems={'center'} justify={'center'} >
                      <Grid item xs={12}>
                        <PropertyRepairCard
                          defect={defect}
                          images={images}
                          highlightSelectedDefect={highlightSelectedDefect}
                          selectedDefectSection={selectedDefectSection}
                          previousSelectedDefectSection={previousSelectedDefectSection}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }) : null }
            </List>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Collapse>
    </Card>
  );
}

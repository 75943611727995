import React from 'react';
import {SvgIcon} from '@material-ui/core';

let SvgUsers = (props) => (
  <SvgIcon {...props} >
       <path d="M20,9.5H14.6a1.7018,1.7018,0,0,0-.7.1A3.3991,3.3991,0,0,1,16.3,13V24H24V13.1A3.8138,3.8138,0,0,0,20,9.5Z" transform="translate(0 0.0434)" />
       <path d="M4.1,6.7c4,2.8,8.3-1.6,5.5-5.5,0-.1-.2-.3-.3-.4C5.3-1.9,1,2.4,3.8,6.4,3.8,6.5,4,6.6,4.1,6.7Z" transform="translate(0 0.0434)"/>
       <path d="M14.6,6.7c4,2.8,8.3-1.6,5.5-5.5,0-.1-.2-.3-.3-.4-4-2.7-8.3,1.6-5.5,5.6C14.3,6.5,14.5,6.6,14.6,6.7Z" transform="translate(0 0.0434)" />
       <path d="M13.4,23.9V13c0-1.9-1.8-3.4-4-3.4H4c-2.2,0-4,1.5-4,3.4V23.9Z" transform="translate(0 0.0434)" />
  </SvgIcon>
);

export default SvgUsers;
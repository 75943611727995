import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import * as AWS from 'aws-sdk';
import 'regenerator-runtime/runtime';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Comment from '@material-ui/icons/Comment';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: '8px',
    marginBottom: '5px',
    color: '#f44336',
    border: '1px solid #f44336',
  },
  fileUpload: {
    marginTop: '8px',
    marginBottom: '5px',
  },
  files: {
    listStyle: 'none',
  },
  inline: {
    display: 'inline',
  },
  textbox: {
    marginTop: '5px',
    width: '100%',
  },
  addButton: {
    marginTop: '5px',
    float: 'right',
    marginBottom: '5px',
  },
  fileButton: {
    display: 'inline-block',
    color: '#444',
    border: '1px solid #CCC',
    background: '#DDD',
    boxShadow: '0 0 5px -1px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    VerticalAlignBottom: 'middle',
    maxWidth: '140px',
    padding: '5px',
    textAlign: 'center',
  },
  disabled: {
    pointerEvents: 'none',
  },
  loading: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-webkit-translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-moz-translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-ms-translate(-50%, -50%)',
  },
}));

const FileInput = ({ rest, value, onChange = {}, classes }) => (
  <div>
    <label className={classes.fileButton}>
      Click to select a file
      <input
        {...rest}
        style={{ display: 'none' }}
        type="file"
        onChange={(e) => {
          onChange([...e.target.files]);
        }}
      />
    </label>
    {Boolean(value.length) && <div>Selected file: {value.map((f) => f.name).join(', ')}</div>}
  </div>
);

export default function File({ dispatch, token, identityId, files, projectId }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let uploadProgress = 0;
  const [document, setDocument] = React.useState('');
  const [fileComponent, setfileComponent] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [fileToRemove, setFileToRemove] = React.useState([]);
  const [state, setState] = React.useState({});
  const [validationForm, setValidationForm] = React.useState({
    isActiveValidation: false,
    comment: false,
    document: false,
  });
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    const showFileComment = {};
    files.map((f) => (showFileComment[f.ProjectFileId] = false));
    setState({ ...state, showFileComment });
  }, []);

  const openLink = (url) => {
    return () => {
      event.preventDefault();
      const win = window.open(url, '_blank');
      win.focus();
    };
  };

  const handleChangeComment = (event) => {
    setComment(event.target.value);
    setValidationForm({
      ...validationForm,
      comment: event.target.value.length > 0 ? false : true,
    });
  };

  const handleChangeDocument = (files) => {
    setfileComponent(files);
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const file = files[0];
    const fileName = file.name;
    const contentType = file.type;
    const dirName = `project/${projectId}/${'_' + timestamp + fileName}`;

    const metaData = {
      fileName,
      dirName,
      file,
      contentType,
    };
    setDocument(metaData);
    setValidationForm({
      ...validationForm,
      document: files[0] && files[0].name.length > 0 ? false : true,
    });
  };

  const handleAddFile = () => {
    setValidationForm({
      ...validationForm,
      isActiveValidation: true,
      document: document && document.fileName && document.fileName.length > 0 ? false : true,
      comment: comment.length > 0 ? false : true,
    });

    if (comment.length > 0 && document && document.fileName && document.fileName.length > 0) {
      setIsLoading(true);
      handleS3Upload(document);
      setDocument([]);
      setComment('');
      setfileComponent('');
    }
  };

  const handleS3Upload = (metaData) => {
    const { dirName, file, contentType, fileName } = metaData;

    const parameters = {
      bucket: 'bluefinfiles',
      key: dirName,
      s3: s3,
      body: file,
      acl: 'public-read',
      contentType: contentType,
    };

    AWS.config.region = 'us-west-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token,
      },
    });

    const s3 = new AWS.S3();

    s3.config.region = 'us-west-2';
    s3.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: identityId,
      Logins: {
        'cognito-identity.amazonaws.com': token,
      },
    });

    return new Promise((resolve, reject) => {
      uploadBlob(parameters)
        .then((result) => {
          const ext = document.fileName.substr(document.fileName.lastIndexOf('.') + 1);
          handleAddDocuments(comment, result.Location, document.fileName, ext, projectId);
          setIsLoading(false);
          resolve(result);
          reject(Error);
        })
        .catch((err) => console.log(err));
    });
  };

  const handleAddDocuments = (note, url, fileName, ext, projectId) => {
    dispatch({
      AddDocument: {
        ProjectId: projectId,
        Note: note,
        Url: url,
        Name: fileName,
        Ext: ext,
      },
    });
  };

  const handleRemoveDocuments = (file) => {
    return () => {
      setFileToRemove(file);
      setTimeout(() => {
        setShowModal(true);
      }, 10);
    };
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOkModal = () => {
    dispatch({
      RemoveDocument: {
        ProjectFileId: fileToRemove.ProjectFileId,
        ProjectId: fileToRemove.ProjectId,
        Note: fileToRemove.Note,
        Url: fileToRemove.Url,
        Name: fileToRemove.Name,
        Ext: fileToRemove.Ext,
      },
    });
    setShowModal(false);
  };

  const uploadBlob = async (parameters) => {
    const { bucket, key, s3, body, acl, contentType } = parameters;
    const params = { Bucket: bucket, Key: key, Body: body, ACL: acl, ContentType: contentType };
    const manager = new AWS.S3.ManagedUpload({ params: params });
    manager.on('httpUploadProgress', (progress) => {
      if (progress) {
        const total = progress.total;
        const loaded = progress.loaded;
        if (total) {
          let percent = (loaded * 100) / total;
          uploadProgress = percent;
        }
      }
    });
    return manager.promise();
  };

  const classes = useStyles();
  if (state.showFileComment) {
    return (
      <div className={classes.files}>
        <Grid item xs={12} md={12}>
          <div>
            <List>
              {files.map((file, index) => {
                return (
                  <div key={index}>
                    <ListItem
                      onClick={() => {
                        setState({
                          ...state,
                          showFileComment: {
                            ...state.showFileComment,
                            [file.ProjectFileId]: !state.showFileComment[file.ProjectFileId],
                          },
                        });
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <ArchiveIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={file.Name}
                        secondary={
                          <Link href={file.Url} onClick={openLink(file.Url)}>
                            {file.Url}
                          </Link>
                        }
                      />
                      {state.showFileComment[file.ProjectFileId] ? <ExpandLess /> : <ExpandMore />}
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={handleRemoveDocuments(file)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Collapse
                      in={state.showFileComment[file.ProjectFileId]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <Comment />
                          </ListItemIcon>
                          <ListItemText primary={file.Note} />
                        </ListItem>
                      </List>
                    </Collapse>
                  </div>
                );
              })}
            </List>
          </div>
        </Grid>
        <Divider variant="inset" component="li" />
        <div className={isLoading ? classes.disabled : ''}>
          <div
            className={
              validationForm.isActiveValidation && validationForm.document
                ? classes.error
                : classes.fileUpload
            }
          >
            <FileInput value={fileComponent} classes={classes} onChange={handleChangeDocument} />
          </div>
        </div>
        <Grid item xs={12} md={12} lg={12}>
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            error={validationForm.isActiveValidation && validationForm.comment}
            className={classes.textbox}
            id="comment"
            label="Comment"
            multiline
            rows={4}
            value={comment}
            variant="outlined"
            inputprops={{ maxLength: 200 }}
            onChange={handleChangeComment}
            disabled={isLoading}
          />
        </Grid>
        <Button
          className={classes.addButton}
          variant="contained"
          color="primary"
          endIcon={isLoading ? <CircularProgress /> : <Icon>add</Icon>}
          onClick={handleAddFile}
          disabled={isLoading}
        >
          Add file
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{'Confirmation'}</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this file?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleOkModal} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
}

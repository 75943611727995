import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import {
  List,
  Collapse,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PropertySectionsExtPerimeter(props) {
  const [expanded, setExpanded] = useState(false);
  const [perimeterExpanded, setPerimeterExpanded] = useState(null);
  const classes = useStyles();
  const { exteriorPerimeter, handleSelectedPerimeter } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePerimeterExpanded = (perimeterId) => {
    if (perimeterId == perimeterExpanded) {
      setPerimeterExpanded(null);
    } else {
      setPerimeterExpanded(perimeterId);
    }
  };

  useEffect(() => {
    handleSelectedPerimeter(perimeterExpanded);
  }, [perimeterExpanded])

  return (
    <Grid container direction={'row'} alignItems={'flex-end'} justify={'flex-start'} style={{ marginBottom: '5%' }}>
      <Grid direction={'row'} style={{ display: 'flex', marginLeft: '2%', marginTop: '2%' }}>
        <Grid item xs={2} style={{ display: 'flex', marginTop: '1%' }}>
          <FullscreenIcon fontSize={'default'} />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h6" style={{ fontSize: '18px' }}>External Perimeter</Typography>
        </Grid>
        <Grid item xs={1} style={{ display: 'flex' }}>
          <IconButton
            size={'small'}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={expanded}>
          <List>
            { exteriorPerimeter.map((perimeter, i) => {
              const title = perimeter.invTitle;
              const perimeterId = perimeter.id;
              const perimeterDetails = perimeter.children;
              
              return (
                <Grid key={i} container direction='row' alignItems={'center'} justify={'center'} style={{ width: '100%' }}>
                  <Grid item xs={12} style={{ marginLeft: '10%', display: 'flex', alignItems: 'center' }}>
                    <Typography>{ title }</Typography>
                    <IconButton
                      size={'small'}
                      style={{marginLeft: '0' }}
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: perimeterExpanded == perimeterId,
                      })}
                      onClick={()=>handlePerimeterExpanded(perimeterId)}
                      aria-expanded={perimeterExpanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Grid>
                  <Collapse in={perimeterExpanded == perimeterId}>
                  { perimeterDetails.map((detail, i) => {
                    const title = detail.invTitle;
                    return <Typography paragraph color={'textSecondary'} style={{margin: '0'}}>({i+1}): {title}</Typography>
                  }) }
                  </Collapse>
                </Grid>
              )
            })}
          </List>
        </Collapse>
        
      </Grid>
    </Grid>
  );
}

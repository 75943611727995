/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { isObjectLiteralElement } from 'typescript';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  selectAll: {
    position: 'absolute',
    margin: '-37px 0 0 0px',
  },
  center: {
    textAlign: 'center',
  },
  loading: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-webkit-translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-moz-translate(-50%, -50%)',
    // eslint-disable-next-line no-dupe-keys
    transform: '-ms-translate(-50%, -50%)',
  },
  formControl: {
    margin: theme.spacing(5),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  hidden: { display: 'none' },
}));

export default function Setting({
  dispatch,
  vm,
  users,
  properties,
  projectSelected,
  showNotification,
  notificationMessage,
  types,
  phaseTemplates,
  woMasters,
  inspections,
  navRoutes,
  systemTypeInfo,
  companies,
  currentCompanyId,
}) {
  const [allProperties, setAllProperties] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();
  const [portalUser, setPortalUser] = React.useState([]);
  const [notificationShow, setShowNotification] = React.useState(showNotification || '');
  const [usersID, setUsersID] = React.useState([]);
  const [projectID, setProjectID] = React.useState(-1);
  const [parentProjectId, setParentProjectId] = React.useState('');
  const [type, setType] = React.useState([]);
  const [canEditProperty, setCanEditProperty] = React.useState(false);
  const [typeInfo, setTypeInfo] = React.useState([]);
  const [company, setCompany] = React.useState([]);
  const [phaseTemplate, setPhaseTemplate] = React.useState([]);
  const [loadPhaseTemplate, setLoadPhaseTemplate] = React.useState(-1);
  const [propertyIDs, setPropertyIDs] = React.useState([]);
  const [startDate, setStartDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(new Date());
  const [completionDate, setCompletionDate] = React.useState(new Date());
  const [projectCode, setProjectCode] = React.useState('');
  const [subProjectCode, setSubProjectCode] = React.useState('');
  const [externalCode, setExternalCode] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [isChild, setIsChild] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [eventIdWoMaster, setEventIdWoMaster] = React.useState([]);
  const [eventIdInspection, setEventIdInspection] = React.useState([]);
  const [eventType, setEventType] = React.useState([]);
  const [projectManagerUsers, setProjectManagerUsers] = React.useState([]);
  const [loadProperty, setLoadProperty] = React.useState(-1);
  const [validationForm, setValidationForm] = React.useState({
    isActiveValidation: false,
    title: false,
    type: false,
    projectManager: false,
    property: false,
    phaseTemplateId: false,
    eventType: false,
    eventId: false,
    systemTypeInfoId: false,
  });

  const handleChangeCheckAllProperty = (event) => {
    setAllProperties(event.target.checked);
    setPropertyIDs([]);
    setValidationForm({
      ...validationForm,
      property: false,
    });
  };

  const filterOptions = createFilterOptions({
    limit: 30,
  });

  const eventsType = [
    { name: 'Inspection', value: 1 },
    { name: 'WoMaster', value: 2 },
  ];

  React.useEffect(() => {
    if (
      projectSelected &&
      projectSelected.hasOwnProperty('ProjectId') &&
      projectSelected.ProjectId.length > 0
    ) {
      setIsLoading(true);
      loadProject();
    }
  }, [projectSelected]);

  React.useEffect(() => {
    if (
      !projectSelected ||
      !projectSelected.hasOwnProperty('ProjectId') ||
      !projectSelected.ProjectId.length > 0
    ) {
      setCompany(companies.find((x) => x.CompanyId == currentCompanyId) || []);
    }
  }, [companies]);

  React.useEffect(() => {
    setProjectManagerUsers(users.filter((x) => x.ProjectManager) || []);
  }, [users]);

  React.useEffect(() => {
    setShowNotification(showNotification);
  }, [showNotification]);

  React.useEffect(() => {
    if (phaseTemplates && phaseTemplates.length > 0 && loadPhaseTemplate != -1) {
      setPhaseTemplate(phaseTemplates.find((x) => x.PhaseTemplateId == loadPhaseTemplate) || []);
      setLoadPhaseTemplate(-1);
    } else {
      setPhaseTemplate([]);
    }
  }, [phaseTemplates]);

  React.useEffect(() => {
    if (properties && properties.length > 0 && loadProperty != -1) {
      setPropertyIDs(properties.find((x) => x.BuildingId == loadProperty) || []);
      if (projectSelected &&
        projectSelected.hasOwnProperty('AssetId') &&
        projectSelected.AssetId > 0) {
        setCanEditProperty(true);
      }
      setLoadProperty(-1);
      setIsLoading(false);
    } else {
      setPropertyIDs([]);
    }
  }, [properties]);

  const loadProject = () => {
    setIsChild(
      window.location.href.includes('subproject') ||
      window.location.href.includes('property') ||
      window.location.href.includes('child')
    );

    setType(
      types.find((x) => x.ProjectTypeId == projectSelected.PhaseTemplate.ProjectTypeId) || []
    );
    setCompany(companies.find((x) => x.CompanyId == projectSelected.CompanyId) || []);
    getTemplates(projectSelected.PhaseTemplate.ProjectTypeId);
    setProjectID(projectSelected.ProjectId);

    if (
      !(
        window.location.href.includes('subproject') ||
        window.location.href.includes('property')
      )
    ) {
      setParentProjectId(projectSelected.ParentProjectId);
      setTypeInfo(systemTypeInfo.find((x) => x.Id == projectSelected.SystemTypeId) || []);
      getProperties(projectSelected.SystemTypeId || -1, projectSelected.CompanyId || -1);
      setIsEdit(true);
      setStartDate(projectSelected.StartDate);
      setDueDate(projectSelected.DueDate);
      setCompletionDate(projectSelected.EndDate);
      setDescription(projectSelected.Description || '');
      setTitle(projectSelected.Title || '');
      setPortalUser(users.find((x) => x.UserId == projectSelected.ProjectManager) || []);
      setEventType(eventsType.find((x) => x.value == projectSelected.EventType) || []);
      if (projectSelected.EventType == 1) {
        setEventIdInspection(inspections.find((x) => x.InspectId == projectSelected.EventId) || []);
      } else {
        setEventIdWoMaster(woMasters.find((x) => x.Woid == projectSelected.EventId) || []);
      }
      const usersIds = projectSelected.ProjectUsers.map((x) => x.PortalUserId);
      setUsersID(users.filter((x) => usersIds.includes(x.UserId)) || []);
      setProjectCode(projectSelected.ProjectCode || '');
      setSubProjectCode(projectSelected.SubProjectCode || '');
      setExternalCode(projectSelected.ExternalCode || '');
      setLoadProperty(projectSelected.AssetId);
    } else {
      setIsLoading(false);
    }
    setLoadPhaseTemplate(projectSelected.PhaseTemplateId);
    projectSelected = null;
  };

  const cleanProject = () => {
    setProjectID(-1);
    setPropertyIDs([]);
    setStartDate(new Date());
    setDueDate(new Date());
    setCompletionDate(new Date());
    setDescription('');
    setTitle('');
    setPortalUser([]);
    setType([]);
    setUsersID([]);
    setProjectCode('');
    setSubProjectCode('');
    setExternalCode('');
    setIsChild(false);
    setIsEdit(false);
    setEventIdWoMaster([]);
    setEventType([]);
    setEventIdInspection([]);
    setPhaseTemplate([]);
    setTypeInfo([]);
    setCompany([]);
    setParentProjectId('');
    setAllProperties(false);
  };

  const handleChangePhaseTemplate = (event, obj) => {
    setPhaseTemplate(obj || []);
    setValidationForm({
      ...validationForm,
      phaseTemplateId: obj && 'PhaseTemplateId' in obj ? false : true,
    });
  };

  const handleChangeCompany = (event, obj) => {
    setCompany(obj || []);
    // setValidationForm({
    //   ...validationForm,
    //   company: obj && 'CompanyId' in obj ? false : true,
    // });
  };

  const handleChangeSystemTypeInfo = (event, obj) => {
    setTypeInfo(obj || []);
    if (obj && 'Id' in obj) {
      getProperties(obj.Id || -1, company.CompanyId || -1);
    } else {
      properties = [];
    }
    setValidationForm({
      ...validationForm,
      systemTypeInfoId: obj && 'Id' in obj ? false : true,
    });
  };

  const handleChangeType = (event, obj) => {
    setType(obj || []);
    if (obj && 'ProjectTypeId' in obj) {
      getTemplates(obj.ProjectTypeId);
    } else {
      phaseTemplates = [];
    }
    setValidationForm({
      ...validationForm,
      type: obj && 'ProjectTypeId' in obj ? false : true,
    });
  };

  const handleChangeEventType = (event, obj) => {
    setEventType(obj);
    setValidationForm({
      ...validationForm,
      eventType: obj && 'value' in obj ? false : true,
    });
  };

  const handleChangeEventIdWoMaster = (event, obj) => {
    setEventIdWoMaster(obj);
    setValidationForm({
      ...validationForm,
      eventId: obj && 'Woid' in obj ? false : true,
    });
  };

  const handleChangeEventInspection = (event, obj) => {
    setEventIdInspection(obj);
    setValidationForm({
      ...validationForm,
      eventId: obj && 'InspectId' in obj ? false : true,
    });
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value || '');
  };

  const handleChangeProjectCode = (event) => {
    setProjectCode(event.target.value || '');
  };

  const handleChangeSubProjectCode = (event) => {
    setSubProjectCode(event.target.value || '');
  };

  const handleChangeExternalCode = (event) => {
    setExternalCode(event.target.value || '');
  };

  const handleChangeUsers = (event, obj) => {
    setUsersID(obj || []);
  };

  const handleChangeProjectManager = (event, obj) => {
    setPortalUser(obj || []);
    setValidationForm({
      ...validationForm,
      projectManager: obj && 'UserId' in obj ? false : true,
    });
  };

  const handleChangeProperty = (event, obj) => {
    setPropertyIDs(obj || []);
    setValidationForm({
      ...validationForm,
      property: canEditProperty
        ? obj && 'BuildingId' in obj
          ? false
          : true
        : obj.length > 0
          ? false
          : true,
    });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  const handleCompletionDateChange = (date) => {
    setCompletionDate(date);
  };

  const handleChangeTitle = (event) => {
    setTitle(event.target.value || '');
    setValidationForm({
      ...validationForm,
      title: event.target.value.length > 0 ? false : true,
    });
  };

  const hideNotification = () => {
    window.location.replace('/' + navRoutes.find((x) => x.Title == 'Project').Route.RedirectRoot);
    setTimeout(() => {
      showNotification = false;
      setShowNotification(false);
    }, 2000);
  };

  function handleSubmit(event) {
    event.preventDefault();
    const usersIds = usersID.map((x) => x.UserId);
    const propertiesIDs =
      propertyIDs && propertyIDs.length > 0
        ? propertyIDs.map((x) => x.BuildingId)
        : propertyIDs && 'BuildingId' in propertyIDs
          ? [propertyIDs.BuildingId]
          : [];
    const eventId =
      eventType.value == 1
        ? eventIdInspection
          ? eventIdInspection.InspectId
          : -1
        : eventIdWoMaster
          ? eventIdWoMaster.Woid
          : -1;

    setValidationForm({
      ...validationForm,
      isActiveValidation: true,
      title: title.length > 0 ? false : true,
      type: 'ProjectTypeId' in type > 0 ? false : true,
      // company: 'CompanyId' in company ? false : true,
      projectManager: 'UserId' in portalUser ? false : true,
      phaseTemplateId: 'PhaseTemplateId' in phaseTemplate ? false : true,
    });

    //TODO: When enable eventID and eventType include next lines in the if
    //||
    // (window.location.href.includes('property') &&
    // (!eventId || eventId < 0 || !eventType || !('value' in eventType))
    if (
      (window.location.href.includes('property') || canEditProperty) &&
      ((propertiesIDs.length < 1 && !allProperties) || !typeInfo || !('Id' in typeInfo))
    ) {
      setValidationForm((oldValue) => {
        return {
          ...oldValue,
          isActiveValidation: true,
          title: title.length > 0 ? false : true,
          type: 'ProjectTypeId' in type > 0 ? false : true,
          // company: 'CompanyId' in company ? false : true,
          projectManager: 'UserId' in portalUser ? false : true,
          property: allProperties
            ? false
            : canEditProperty
              ? propertyIDs && 'BuildingId' in propertyIDs
                ? false
                : true
              : propertyIDs.length > 0
                ? false
                : true,
          phaseTemplateId: 'PhaseTemplateId' in phaseTemplate ? false : true,
          systemTypeInfoId: typeInfo && 'Id' in typeInfo ? false : true,
          // eventType: 'value' in eventType ? false : true,
          // eventId: eventId > -1 ? false : true,
        };
      });
      return false;
    }
    if (
      title.length > 0 &&
      'ProjectTypeId' in type &&
      'UserId' in portalUser &&
      'PhaseTemplateId' in phaseTemplate
    ) {
      dispatch({
        Add: {
          Id: projectID,
          Title: title,
          Type: type ? type.ProjectTypeId : -1,
          ProjectManager: portalUser.UserId,
          PropertyIDs: propertiesIDs,
          UsersID: usersIds,
          ProjectCode: projectCode,
          SubProjectCode: subProjectCode,
          ExternalCode: externalCode,
          StartDate: startDate,
          DueDate: dueDate,
          CompletionDate: completionDate,
          Description: description,
          PhaseTemplateId: phaseTemplate ? phaseTemplate.PhaseTemplateId : -1,
          IsChild: isChild,
          IsEdit: isEdit,
          EventType: eventType.value,
          EventId: eventId,
          systemTypeInfoId: typeInfo ? typeInfo.Id : -1,
          ParentProjectId: parentProjectId ? parentProjectId : '',
          SelectAll: allProperties,
          CompanyId: company ? company.CompanyId : -1
        },
      });
      cleanProject();
    }
  }

  function getTemplates(typeId) {
    dispatch({
      GetPhaseTemplates: {
        projectTypeId: typeId,
      },
    });
  }

  function getProperties(systemTypeInfoId, companyId) {
    dispatch({
      getProperties: {
        systemTypeInfoId: systemTypeInfoId,
        CompanyId: companyId,
      },
    });
  }

  const handleCancel = () => {
    window.location.replace('/' + navRoutes.find((x) => x.Title == 'Project').Route.RedirectRoot);
  };

  if (isLoading) {
    return (
      <div className={classes.center}>
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <div>
        <form className={classes.container}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              id="company"
              disabled={true}
              onChange={handleChangeCompany}
              value={company}
              filterOptions={filterOptions}
              options={companies || []}
              getOptionLabel={(option) => option.Name || ''}
              style={{ width: 300 }}
              renderInput={(params) => (
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  {...params}
                  label="Company"
                  error={validationForm.isActiveValidation && validationForm.company}
                  variant="outlined"
                />
              )}
            />
            <br />
            <Autocomplete
              id="systemTypeInfo"
              disabled={
                window.location.href.includes('property') || canEditProperty ? false : true
              }
              onChange={handleChangeSystemTypeInfo}
              value={typeInfo}
              filterOptions={filterOptions}
              options={systemTypeInfo || []}
              getOptionLabel={(option) => option.Name || ''}
              style={{ width: 300 }}
              renderInput={(params) => (
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  {...params}
                  label="Material Type"
                  error={validationForm.isActiveValidation && validationForm.systemTypeInfoId}
                  variant="outlined"
                />
              )}
            />
            <br />
            <Autocomplete
              id="type"
              disabled={isChild || isEdit}
              onChange={handleChangeType}
              value={type}
              options={types}
              filterOptions={filterOptions}
              getOptionLabel={(option) => option.Title || ''}
              style={{ width: 300 }}
              renderInput={(params) => (
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  {...params}
                  label="Type"
                  error={validationForm.isActiveValidation && validationForm.type}
                  variant="outlined"
                />
              )}
            />
            <br />
            <Autocomplete
              id="template"
              disabled={isChild || isEdit}
              onChange={handleChangePhaseTemplate}
              value={phaseTemplate}
              options={phaseTemplates || []}
              filterOptions={filterOptions}
              getOptionLabel={(option) => option.Name || ''}
              style={{ width: 300 }}
              renderInput={(params) => (
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  {...params}
                  label="Template"
                  error={validationForm.isActiveValidation && validationForm.phaseTemplateId}
                  variant="outlined"
                />
              )}
            />
             <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
              id="title"
              label="Title"
              value={title}
              onChange={handleChangeTitle}
              error={validationForm.isActiveValidation && validationForm.title}
            />
             <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
              id="project-code"
              value={projectCode}
              label="Project code"
              onChange={handleChangeProjectCode}
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="start-date"
                autoOk={true}
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            <br />
             <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
              id="description"
              label="Description"
              multiline
              rows={4}
              value={description}
              variant="outlined"
              onChange={handleChangeDescription}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <div>
              <div
                className={
                  window.location.href.includes('property') ? classes.selectAll : classes.hidden
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!typeInfo || !('Id' in typeInfo)}
                      checked={allProperties}
                      onChange={handleChangeCheckAllProperty}
                      defaultChecked
                      color="default"
                    />
                  }
                  label={'Select all' + properties != null && properties && properties.length > 0 ? ' (' + properties.length + ') properties' : ''}
                />
              </div>
              <Autocomplete
                multiple={window.location.href.includes('property')}
                limitTags={3}
                disabled={
                  (window.location.href.includes('property') || canEditProperty) && !allProperties
                    ? false
                    : true
                }
                id="property"
                onChange={handleChangeProperty}
                value={propertyIDs}
                options={properties || []}
                getOptionLabel={(option) => option.Name || ''}
                style={{ width: 300 }}
                filterOptions={filterOptions}
                renderInput={(params) => (
                   <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                    {...params}
                    label={allProperties ? 'All properties are selected' : 'Property'}
                    variant="outlined"
                    error={validationForm.isActiveValidation && validationForm.property}
                  />
                )}
              />
            </div>
             <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
              id="sub-project-code"
              label="Sub Project Code"
              value={subProjectCode}
              onChange={handleChangeSubProjectCode}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                autoOk={true}
                id="due-date"
                label="Due Date"
                value={dueDate}
                onChange={handleDueDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            <Autocomplete
              multiple
              id="users"
              limitTags={3}
              onChange={handleChangeUsers}
              filterOptions={filterOptions}
              options={users}
              value={usersID}
              getOptionLabel={(option) =>
                option.FirstName ? option.FirstName + ' ' + option.LastName : ''
              }
              style={{ width: 300 }}
              renderInput={(params) => (
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  {...params}
                  label="Users"
                  variant="outlined"
                  error={validationForm.isActiveValidation && validationForm.usersIds}
                />
              )}
            />
            <br></br>
            <Autocomplete
              id="event-type"
              // disabled={window.location.href.includes('property') ? false : true}
              disabled={true}
              onChange={handleChangeEventType}
              value={eventType}
              options={eventsType}
              filterOptions={filterOptions}
              getOptionLabel={(option) => option.name || ''}
              style={{ width: 300 }}
              renderInput={(params) => (
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  {...params}
                  label="Event Type"
                  variant="outlined"
                  error={validationForm.isActiveValidation && validationForm.eventType}
                />
              )}
            />
            <br></br>
            <Autocomplete
              className={eventType && eventType.value == 2 ? '' : classes.hidden}
              id="womaster"
              // disabled={window.location.href.includes('property') ? false : true}
              disabled={true}
              onChange={handleChangeEventIdWoMaster}
              value={eventIdWoMaster}
              options={woMasters}
              filterOptions={filterOptions}
              getOptionLabel={(option) => option.Wonumber || ''}
              style={{ width: 300 }}
              renderInput={(params) => (
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  {...params}
                  label="WoMaster"
                  variant="outlined"
                  error={validationForm.isActiveValidation && validationForm.eventId}
                />
              )}
            />
            <Autocomplete
              className={eventType && eventType.value == 1 ? '' : classes.hidden}
              id="inspection"
              // disabled={window.location.href.includes('property') ? false : true}
              disabled={true}
              onChange={handleChangeEventInspection}
              value={eventIdInspection}
              options={inspections}
              filterOptions={filterOptions}
              getOptionLabel={(option) => option.Name || ''}
              style={{ width: 300 }}
              renderInput={(params) => (
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  {...params}
                  label="Inspection"
                  variant="outlined"
                  error={validationForm.isActiveValidation && validationForm.eventId}
                />
              )}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Autocomplete
              id="project-manager"
              onChange={handleChangeProjectManager}
              value={portalUser}
              options={projectManagerUsers}
              filterOptions={filterOptions}
              getOptionLabel={(option) =>
                option.FirstName ? option.FirstName + ' ' + option.LastName : ''
              }
              style={{ width: 300 }}
              renderInput={(params) => (
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  {...params}
                  label="Project Manager"
                  variant="outlined"
                  error={validationForm.isActiveValidation && validationForm.projectManager}
                />
              )}
              c
            />
             <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
              id="external-code"
              label="External Code"
              value={externalCode}
              onChange={handleChangeExternalCode}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                autoOk={true}
                format="MM/dd/yyyy"
                margin="normal"
                id="completion-date"
                label="Completion Date"
                value={completionDate}
                onChange={handleCompletionDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>

            <br />
            <Button onClick={handleCancel} variant="contained" color="secondary">
              Cancel
            </Button>
            <br />
            <Button variant="contained" color="primary" onClick={handleSubmit} type="submit">
              Save
            </Button>
          </FormControl>
        </form>
        <Snackbar
          open={notificationShow}
          message={notificationMessage}
          autoHideDuration={2000}
          onClose={hideNotification}
        />
      </div>
    );
  }
}

import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Grid,
  Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
    minWidth: 120,
    textAlign: 'left',
  },
  selectEmpty: {
    width: '90%',
    marginTop: theme.spacing(2),
    fontFamily: 'Ebrima',
  },
}));

const MantisBlueCheckbox = withStyles({
  root: {
    color: "#0672BA",
    "&$checked": {
      color: "#0672BA"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

export default function PropertyDefectsSectionDropdown(props) {
  const classes = useStyles();
  const [ value, setValue ] = useState(0);
  const [ options, setOptions ] = useState([]);
  const [ checked, setChecked ] = useState(true);

  const { 
    selectedProperty, 
    retrieveSelectedDefectSection, 
    retrieveFilterDefectsByProposed,
    filteredByProposed,
    displayDefects,
  } = props;

  const handleChange = event => {
    const changedValue = event.target.value;
    setValue(changedValue);
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    setOptions(selectedProperty.Sections);
  }, []);

  const previousValue = usePrevious(value);
  useEffect(() => {
    if (value != previousValue) {
      retrieveSelectedDefectSection(value);
    }
  }, [value]);

  useEffect(() => {
    retrieveFilterDefectsByProposed(checked);
  }, [checked]);

  const previousFilteredByProposed = usePrevious(filteredByProposed);
  useEffect(() => {
    if (filteredByProposed != previousFilteredByProposed) {
      setValue(0);
    }
  }, [filteredByProposed]);

  const previousDisplayDefects = usePrevious(displayDefects);
  useEffect(() => {
    if (displayDefects != previousDisplayDefects) {
      setValue(0);
      setChecked(true);
    }
  }, [displayDefects]);
  
  return (
    <Grid container direction='row' justify='center' alignItems='center'>
      <Grid item xs={12}>
        <form className={classes.root} autoComplete="off">
          <FormControl className={classes.formControl}>
            <Grid container direction='row' alignItems='center' justify='space-between'>
              <Grid item xs={10}>
                <Select
                                  value={value}
                                  onChange={handleChange}
                                  name="Section"
                                  className={classes.selectEmpty}
                                  disabled={!displayDefects}
                                  key={'PropertyDefectsSectionDropSelect'}
                >
                                  <MenuItem value={0} style={{ fontFamily: 'Ebrima', }} key={'PropertyDefectsSectionDropSelect0'}>All</MenuItem>
                                  {options.map((option => <MenuItem value={option.SectionId} key={'PropertyDefectsSectionDropSelect' + option.SectionId} style={{fontFamily: 'Ebrima',}}>Section: {option.SecId}</MenuItem>)) }
                </Select>
                <FormHelperText style={{fontFamily: 'Ebrima',}}>Section</FormHelperText>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center', marginTop: '7%' }}>
                <MantisBlueCheckbox
                  checked={checked}
                  onChange={handleChecked}
                  style={{ padding: '0' }}
                                  disabled={!displayDefects}
                                  key={'PropertyDefectsSectionDropDisplay'}

                />
                <FormHelperText style={{fontFamily: 'Ebrima',}}>Proposed</FormHelperText>
              </Grid>
            </Grid>

          </FormControl>
        </form>
      </Grid>
    </Grid>
  );
}

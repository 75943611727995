import React, { Component } from 'react';
import { 
  Typography,
  Grid,
} from '@material-ui/core';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ThemeDefault from '../../styles/theme-default';
import globalStyles from '../../styles/styles';

import {
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    Line,
    ResponsiveContainer,
    ComposedChart,
} from 'recharts';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const smallStyle = {
    textAlign: 'center',
    fontFamily: 'Ebrima', 
};

const headerStyle = {
  color: 'white',
  fontFamily: 'Ebrima',
  fontFamily: 'Ebrima', 
};

const bigStyle = {
    textAlign: 'center',
    fontFamily: 'Ebrima', 
};

const lineLabelStyle = { 
    fill: "#000000", 
    fontSize: 12, 
    fontWeight: 'bold',
    dataKey: "avgscore",
    position: "top",
    fontFamily: 'Ebrima', 
};

const composedChartMarginStyle = { 
    right: 15, 
    left: 55, 
    top: 15, 
    bottom: 40
};

const graphContainerStyle = (windowWidth) => {
    return windowWidth <= 500 ? smallStyle : bigStyle
}

const responsiveContainerAspectRatio = (windowWidth) => {
    return windowWidth <= 1300 ? 10.0 / 4.0 : 20.0 / 4.0
}

const RCSTickFormatter = (value) => {
    return `$${new Intl.NumberFormat('en').format(value)}`;
};

const YAxisPercentageFormatter = (value) => {
    return `${value}`;
};

const CustomTooltipContent = (props) => {
    const payload = props.payload;

    if (props.active) {
        const newPayload = payload; 

        if (payload) {
            payload.map((entry, i) => {
                const dataKey = entry.dataKey;

                switch(dataKey) {
                    case 'avgscore':
                        return entry.formatter = (value) => `${value}`;
                        break;
                    case 'repair':
                        return entry.formatter = (value) => `$${new Intl.NumberFormat('en').format(value)}`;
                        break;
                    case 'oe':
                        return entry.formatter = (value) => `$${new Intl.NumberFormat('en').format(value)}`;
                        break;
                    case 'Capital':
                      return entry.formatter = (value) => `$${new Intl.NumberFormat('en').format(value)}`;
                      break;
                    default:
                        break;    
                }
            })    
        }

        return <DefaultTooltipContent {...props} payload={newPayload} />
    } else {
        return <DefaultTooltipContent {...props} payload={payload}/>
    }
};

class FinancialForecastGraph extends Component {
    constructor(props) {
        super(props);
    }

    render() {
      const { financialForcastData, materialType } = this.props;
        const windowWidth = window.innerWidth;

        return (
            <div id='graph-container' style={ graphContainerStyle(windowWidth) }>
                <Grid container direction='row' style={{ width: '100%', backgroundColor: 'rgb(6, 114, 186)'}}>
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography style={headerStyle} variant='h6'>10 Year Financial Forecast</Typography>
                  </Grid>
                </Grid>
                    <ResponsiveContainer 
                        width='99%' 
                        height='100%' 
                        aspect={ responsiveContainerAspectRatio(windowWidth) } 
                    >
                        <ComposedChart 
                          data={ financialForcastData } 
                          barGap={ 0 } 
                          margin={ composedChartMarginStyle }
                        >
                            <CartesianGrid 
                              strokeDasharray="3 3" 
                            />
                            <XAxis 
                              dataKey="Year" 
                            />
                            <YAxis 
                              stroke='black'
                              datakey="avgscore" 
                              tickFormatter={ RCSTickFormatter } 
                            />
                            <YAxis
                              stroke="#D36135"
                              yAxisId="right" 
                              orientation='right' 
                              tickFormatter={ YAxisPercentageFormatter } 
                            />
                            <Tooltip 
                              content={ <CustomTooltipContent style={{fontFamily: 'Ebrima', }} props={ financialForcastData } /> }
                            />
                            <Legend style={{fontFamily: 'Ebrima', }}/>
                            <Bar
                              barSize={25}
                              dataKey="oe"
                              name="Budget"
                              fill="#0672BA"
                              stackId='OPExpense'
                              style={{fontFamily: 'Ebrima', }}
                            />
                            <Bar
                              barSize={25}
                              dataKey="repair"
                              name='Repairs'
                              fill="#66BFFA"
                              stackId='OPExpense'
                              style={{fontFamily: 'Ebrima', }}
                            />
                            <Bar
                              barSize={25}
                              dataKey="Capital"
                              name='Capital'
                              fill="#8DC63F"
                              style={{fontFamily: 'Ebrima', }}
                            />
                            <Line 
                              type="monotone" 
                              dataKey="avgscore" 
                              name={materialType == 1 ? "RCS" : 'PCI'} 
                              yAxisId="right" 
                              stroke="#D36135" 
                              label={ lineLabelStyle } 
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
            </div>
        );
    }
}

export default FinancialForecastGraph

import React, { Component } from 'react';
import AddPropertyAutoSelect from './Add/Selects/AddPropertyAutoSelect';
import AddWorkOrderTypeSelect from './Add/Selects/AddWorkOrderTypeSelect';
import AddStatusSelect from './Add/Selects/AddStatusSelect';
import AddUrgencySelect from './Add/Selects/AddUrgencySelect';
import AddDescriptionSelect from './Add/Selects/AddDescriptionSelect';
import AddContractorSelect from './Add/Selects/AddContractorSelect';
import AddDateSelect from './Add/Selects/AddDateSelect';
import AddTimeSelect from './Add/Selects/AddTimeSelect';
import AddTimeSelectEnd from './Add/Selects/AddTimeSelectEnd';
import AddSubmittedBySelect from './Add/Selects/AddSubmittedBySelect';
import AddAssignedToSelect from './Add/Selects/AddAssignedToSelect';
import AddContractorInvoiceNumber from './Add/Selects/AddContractorInvoiceNumber';
import AddBluefinInvoiceNumber from './Add/Selects/AddBluefinInvoiceNumber';
import AddPurchaseOrderNumber from './Add/Selects/AddPurchaseOrderNumber';
import AddExternalWorkOrderNumber from './Add/Selects/AddExternalWorkOrderNumber';
import AddContractorNameSelect from './Add/Selects/Network/AddContractorNameSelect';
import AddContractorPhoneSelect from './Add/Selects/Network/AddContractorPhoneSelect';
import AddContractorEmailSelect from './Add/Selects/Network/AddContractorEmailSelect';
import AddAssignedToNameSelect from './Add/Selects/Network/AddAssignedToNameSelect';
import AddAssignedToPhoneSelect from './Add/Selects/Network/AddAssignedToPhoneSelect';
import AddAssignedToEmailSelect from './Add/Selects/Network/AddAssignedToEmailSelect';
import AddSubmittedByNameSelect from './Add/Selects/Network/AddSubmittedByNameSelect';
import AddSubmittedByPhoneSelect from './Add/Selects/Network/AddSubmittedByPhoneSelect';
import AddSubmittedByEmailSelect from './Add/Selects/Network/AddSubmittedByEmailSelect';
import AddNTENumber from './Add/Selects/AddNTENumber';
import AddEstimateNumber from './Add/Selects/AddEstimateNumber';
import AddBidNumber from './Add/Selects/AddBidNumber';
import SubmitWorkOrder from './Add/SubComponents/SubmitWorkOrder';
import {
  Grid,
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

class WorkOrderAddForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      //finished categories
      category1Finished: null,
      category2Finished: null,
      category3Finished: null,
      category4Finished: null,
      category5Finished: null,
      //user selections
      selectedProperty: null,
      workOrderType: null,
      workOrderStatus: null,
      workOrderUrgency: null,
      workOrderContractor: null,
      workOrderContractorName: null,
      workOrderContractorPhone: null,
      workOrderContractorEmail: null,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {       
      //finished categories
      category1Finished,
      category2Finished,
      category3Finished,
      category4Finished,
      category5Finished,
      //user selections
      selectedProperty, 
      workOrderType,
    } = this.state;

    const previousSelectedProperty = prevState.selectedProperty;
    const previousWorkOrderType = prevState.workOrderType;

    //category 1
    if (selectedProperty) {
      if (workOrderType) {
        if (selectedProperty != previousSelectedProperty || workOrderType != previousWorkOrderType) {
          this.setState({
            category1Finished: true,
          })
        } 
      } 
    }

    if (!selectedProperty || !workOrderType) {
      if (selectedProperty != previousSelectedProperty || workOrderType != previousWorkOrderType) {
        this.setState({
          category1Finished: false,
        })
      }
    }

    //category 2
    if (category1Finished) {
      const { 
        workOrderStatus, 
        workOrderUrgency 
      } = this.state;

      const previousWorkOrderStatus = prevState.workOrderStatus;
      const previousWorkOrderUrgency = prevState.workOrderUrgency;

      if (workOrderStatus) {
        if (workOrderUrgency) {
          if (workOrderStatus != previousWorkOrderStatus || workOrderUrgency != previousWorkOrderUrgency) {
            this.setState({
              category2Finished: true,
            })
          }
        }
      }

      if (!workOrderStatus || !workOrderUrgency) {
        if (workOrderStatus != previousWorkOrderStatus || workOrderUrgency != previousWorkOrderUrgency) {
          this.setState({
            category2Finished: false,
          })
        }
      }
    }

    // category 3
    if (category2Finished) {
      const {
        workOrderContractor,
        workOrderContractorName,
        workOrderContractorPhone,
        workOrderContractorEmail,
      } = this.state;

      const previousWorkOrderContractor = prevState.workOrderContractor;
      const previousWorkOrderContractorName = prevState.workOrderContractorName;
      const previousWorkOrderContractorPhone = prevState.workOrderContractorPhone;
      const previousWorkOrderContractorEmail = prevState.workOrderContractorEmail;

      if (workOrderContractor) {
        if (workOrderContractor != previousWorkOrderContractor) {
          this.setState({
            category3Finished: true,
          })
        }
      }

      if (!workOrderContractor) {
        if (workOrderContractorName) {
          if (workOrderContractorPhone) {
            if (workOrderContractorEmail) {
              if (workOrderContractorName != previousWorkOrderContractorName
                || workOrderContractorPhone != previousWorkOrderContractorPhone
                || workOrderContractorEmail != previousWorkOrderContractorEmail) {
                this.setState({
                  category3Finished: true,
                });
              }
            }
          }
        }
        if (workOrderContractor != previousWorkOrderContractor) {
          this.setState({
            category3Finished: false,
          });
        }
        if ( !workOrderContractorName
          || !workOrderContractorPhone 
          || !workOrderContractorEmail) {
            if ( workOrderContractorName != previousWorkOrderContractorName
              || workOrderContractorPhone != previousWorkOrderContractorPhone
              || workOrderContractorEmail != previousWorkOrderContractorEmail) {
                this.setState({
                  category3Finished: false,
                })
              }
          }
      }
    }

    //category 4
    if (category3Finished) {
      const {
        workOrderSubmitter,
        workOrderSubmitterName,
        workOrderSubmitterPhone,
        workOrderSubmitterEmail,
      } = this.state;

      const previousWorkOrderSubmitter = prevState.workOrderSubmitter;
      const previousWorkOrderSubmitterName = prevState.workOrderSubmitterName;
      const previousWorkOrderSubmitterPhone = prevState.workOrderSubmitterPhone;
      const previousWorkOrderSubmitterEmail = prevState.workOrderSubmitterEmail;

      if (workOrderSubmitter) {
        if (workOrderSubmitter != previousWorkOrderSubmitter) {
          this.setState({
            category4Finished: true,
          })
        }
      }

      if (!workOrderSubmitter) {
        if (workOrderSubmitterName) {
          if (workOrderSubmitterPhone) {
            if (workOrderSubmitterEmail) {
              if ( workOrderSubmitterName != previousWorkOrderSubmitterName
                || workOrderSubmitterPhone != previousWorkOrderSubmitterPhone
                || workOrderSubmitterEmail != previousWorkOrderSubmitterEmail) {
                  this.setState({
                    category4Finished: true,
                  });
              }
            }
          }
        }
        if (workOrderSubmitter != previousWorkOrderSubmitter) {
          this.setState({
            category4Finished: false,
          })
        }

        if ( !workOrderSubmitterName
          || !workOrderSubmitterPhone
          || !workOrderSubmitterEmail) {
            if ( workOrderSubmitterName != previousWorkOrderSubmitterName 
              || workOrderSubmitterPhone != previousWorkOrderSubmitterPhone
              || workOrderSubmitterEmail != previousWorkOrderSubmitterEmail) {
                this.setState({
                  category4Finished: false,
                })
              }
          }
      }  
    }
    //category 5
    if (category4Finished) {
      const {
        workOrderAssigned,
        workOrderAssignedName,
        workOrderAssignedPhone,
        workOrderAssignedEmail,
      } = this.state;

      const previousWorkOrderAssigned = prevState.workOrderAssigned;
      const previousWorkOrderAssignedName = prevState.workOrderAssignedName;
      const previousWorkOrderAssignedPhone = prevState.workOrderAssignedPhone;
      const previousWorkOrderAssignedEmail = prevState.workOrderAssignedEmail;

      if (workOrderAssigned) {
        if (workOrderAssigned != previousWorkOrderAssigned) {
          this.setState({
            category5Finished: true,
          })
        }
      }

      if (!workOrderAssigned) {
        if (workOrderAssignedName) {
          if (workOrderAssignedPhone) {
            if (workOrderAssignedEmail) {
              if ( workOrderAssignedName != previousWorkOrderAssignedName
                || workOrderAssignedPhone != previousWorkOrderAssignedPhone
                || workOrderAssignedEmail != previousWorkOrderAssignedEmail) {
                  this.setState({
                    category5Finished: true,
                  })
                }
            }
          }
        }
        if (workOrderAssigned != previousWorkOrderAssigned) {
          this.setState({
            category5Finished: false,
          })
        }
        if ( !workOrderAssignedName
          || !workOrderAssignedPhone
          || !workOrderAssignedEmail ) {
            if ( workOrderAssignedName != previousWorkOrderAssignedName
              || workOrderAssignedPhone != previousWorkOrderAssignedPhone
              || workOrderAssignedEmail != previousWorkOrderAssignedEmail) {
                this.setState({
                  category5Finished: false,
                })
              } 
        }
      }
    }
  };

  //category 1
  retrieveSelectedProperty = (selectedProperty) => {
    const { dispatchPropertyContractors } = this.props;
    this.setState({
      selectedProperty,
    }, dispatchPropertyContractors(selectedProperty));
  };  

  retrieveSelectedWoType = (workOrderType) => {
    this.setState({
      workOrderType,
    })
  };

  //category 2
  retrieveSelectedWoStatus = (workOrderStatus) => {
    this.setState({
      workOrderStatus,
    })
  };

  retrieveSelectedWoUrgency = (workOrderUrgency) => {
    this.setState({
      workOrderUrgency,
    })
  };

  retrieveWoDescription = (workOrderDescription) => {
    this.setState({
      workOrderDescription,
    })
  };

  //category 3
  retrieveContractorValue = (workOrderContractor) => {
    this.setState({
      workOrderContractor,
    })
  };

  retrieveContractorName = (workOrderContractorName) => {
    this.setState({
      workOrderContractorName,
    })
  };

  retrieveContractorPhone = (workOrderContractorPhone) => {
    this.setState({
      workOrderContractorPhone,
    })
  };

  retrieveContractorEmail = (workOrderContractorEmail) => {
    this.setState({
      workOrderContractorEmail,
    })
  };

  // category 3.5
  retrieveDate = (workOrderDate) => {
    this.setState({
      workOrderDate,
    })
  };

  retrieveTimeStart = (workOrderTimeStart) => {
    this.setState({
      workOrderTimeStart,
    })
  };

  retrieveTimeEnd = (workOrderTimeEnd) => {
    this.setState({
      workOrderTimeEnd,
    })
  };

  //category 4
  retrieveSubmitterValue = (workOrderSubmitter) => {
    this.setState({
      workOrderSubmitter,
    })
  };

  retrieveSubmitterName = (workOrderSubmitterName) => {
    this.setState({
      workOrderSubmitterName,
    })
  };

  retrieveSubmitterPhone = (workOrderSubmitterPhone) => {
    this.setState({
      workOrderSubmitterPhone,
    })
  };

  retrieveSubmitterEmail = (workOrderSubmitterEmail) => {
    this.setState({
      workOrderSubmitterEmail,
    })
  };

  //category 5
  retrieveAssignedValue = (workOrderAssigned) => {
    this.setState({
      workOrderAssigned,
    })
  };

  retrieveAssignedName = (workOrderAssignedName) => {
    this.setState({
      workOrderAssignedName,
    })
  };

  retrieveAssignedPhone = (workOrderAssignedPhone) => {
    this.setState({
      workOrderAssignedPhone,
    })
  };

  retrieveAssignedEmail = (workOrderAssignedEmail) => {
    this.setState({
      workOrderAssignedEmail,
    })
  };

  //category 6
  retrieveScopeOfWorkDoc = (scopeOfWorkDoc) => {
    this.setState({
      scopeOfWorkDoc,
    })
  };

  retrieveBidDoc = (bidDoc) => {
    this.setState({
      bidDoc,
    })
  };

  retrieveContractorInvoiceDoc = (contractorInvoiceDoc) => {
    this.setState({
      contractorInvoiceDoc,
    })
  };
  
  retrieveBluefinInvoiceDoc = (bluefinInvoiceDoc) => {
    this.setState({
      bluefinInvoiceDoc,
    })
  };

  //category 7
  retrieveContractorInvoiceNumber = (workOrderContractorInvNumber) => {
    this.setState({
      workOrderContractorInvNumber,
    })
  };

  retrieveBluefinInvoiceNumber = (workOrderBFInvoiceNumber) => {
    this.setState({
      workOrderBFInvoiceNumber,
    })
  };

  retrievePONumber = (workOrderPONumber) => {
    this.setState({
      workOrderPONumber,
    })
  };

  retrieveExternalNumber = (workOrderExternalNumber) => {
    this.setState({
      workOrderExternalNumber,
    })
  };

  retrieveNTE = (workOrderNTE) => {
    this.setState({
      workOrderNTE,
    })
  };

  retrieveEstimate = (workOrderEstimate) => {
    this.setState({
      workOrderEstimate,
    })
  };

  //submit
  handleNext = () => {
    const {
      selectedProperty,
      workOrderType,
      workOrderStatus,
      workOrderUrgency,
      workOrderDescription,
      workOrderContractor,
      workOrderDate,
      // workOrderTime,
      workOrderTimeStart,
      workOrderTimeEnd,
      workOrderSubmitter,
      workOrderAssigned,
      workOrderContractorInvNumber,
      workOrderBFInvoiceNumber,
      workOrderPONumber,
      workOrderExternalNumber,
      workOrderNTE,
      workOrderEstimate,
    } = this.state;

    const { 
      handleSubmitWorkOrder
    } = this.props;

    const submitWorkOrderObj = {
      ActualPrice: 0,
      AssignedToEmail: workOrderAssigned.Email,
      AssignedToId: workOrderAssigned.UserID,
      AssignedToName: workOrderAssigned.UserName,
      AssignedToPhone: workOrderAssigned.Phone,
      // BFInvoice: '',
      // BFInvoiceUrl: '',
      // BidPrice: '',
      // BidUrl: '',
      // Building: '',
      BuildingId: selectedProperty.Id,
      // Comments: [],
      CompanyId: selectedProperty.CompanyId,
      // CompletedWOURL: '',Í
      // ContractorInvoiceUrl: '',
      // Contractor: null,
      ContractorEmail: workOrderContractor.Email,
      ContractorId: workOrderContractor.UserID,
      // ContractorInvoice: '',
      ContractorName: workOrderContractor.UserName,
      ContractorPhone: workOrderContractor.Phone,
      Description: workOrderDescription ? workOrderDescription : '',
      Estimate: workOrderEstimate ? workOrderEstimate : 0,
      ExternalNumber: workOrderExternalNumber ? workOrderExternalNumber : '',
      NTE: workOrderNTE ? workOrderNTE : 0,
      Ponumber: workOrderPONumber ? workOrderPONumber : '',
      // ProjectId: '',
      // ScopeOfWorkUrl: '',
      Status: [workOrderStatus],
      SubmitEmail: workOrderSubmitter.Email,
      SubmitName: workOrderSubmitter.UserName,
      SubmitPhone: workOrderSubmitter.Phone,
      SubmittedById: workOrderSubmitter.UserID,
      ScheduledDate: workOrderDate ? workOrderDate : '2019-01-01 00:00:00',

      TimeWindowStart: workOrderTimeStart,
      TimeWindowEnd: workOrderTimeEnd,
      UrgencyType: workOrderUrgency,
      UrgencyTypeId: workOrderUrgency.UrgencyId,
      // WOID: '',
      // WOItems: '',
      // WONumber: '',
      WOType: workOrderType,
      WOTypeId: workOrderType.WotypeId,
    };

    const { handleAddModalClose } = this.props;
    handleSubmitWorkOrder(submitWorkOrderObj);
    handleAddModalClose();
  }; 

  render() {
    const {
      //select data
      propertyList,
      statusList,
      urgencyList,
      woTypeList,
      bfAdminUsers,
      contractorList,
    } = this.props;

    const { 
      //category completion
      category1Finished,
      category2Finished,
      category3Finished,
      category4Finished,
      category5Finished,
      //user selections
      selectedProperty,
      workOrderType,
      workOrderStatus,
      workOrderUrgency,
      workOrderContractor,
      workOrderSubmitter,
      workOrderAssigned,
    } = this.state;

    return (
      <Grid container direction='row'>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ height: '100%', width: '100%' }}>
          {/* category 1 */}
          <Grid container direction='row'>
            <Paper style={{ width: '100%', margin: '2%' }}>
              <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                <Grid item xs={4}>
                  <Typography style={{ margin: '2%' }} variant='h6'>Property:</Typography>
                </Grid>
                <Grid item xs={8}>
                  { category1Finished ? <CheckCircleOutlineIcon style={{color: 'green'}} /> : null }
                </Grid>
              </Grid>
              <Grid container direction='row'>
                <Grid item xs={6}>
                  <AddPropertyAutoSelect 
                    retrieveSelectedProperty={ this.retrieveSelectedProperty }
                    propertyList={ propertyList }
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddWorkOrderTypeSelect 
                    retrieveSelectedWoType={ this.retrieveSelectedWoType }
                    selectedProperty={ selectedProperty }
                    woTypeList={ woTypeList }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* category 2 */}
          <Grid container direction='row'>
            <Paper style={{width: '100%', margin: '2%'}}>
              <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                <Grid item xs={4}>
                  <Typography style={{ margin: '2%' }} variant='h6'>General:</Typography>
                </Grid>
                <Grid item xs={8}>
                  { category2Finished ? <CheckCircleOutlineIcon style={{color: 'green'}} /> : null }
                </Grid>
              </Grid>
              <Grid container direction='row'>
                <Grid item xs={6}>
                  <AddStatusSelect 
                    retrieveSelectedWoStatus={ this.retrieveSelectedWoStatus }
                    category1Finished={ category1Finished }
                    statusList={ statusList }
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddUrgencySelect
                    retrieveSelectedWoUrgency={ this.retrieveSelectedWoUrgency } 
                    workOrderStatus={ workOrderStatus }
                    urgencyList={ urgencyList }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddDescriptionSelect
                    retrieveWoDescription={ this.retrieveWoDescription } 
                    category2Finished={ category2Finished }
                    workOrderUrgency={ workOrderUrgency }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* category 3 */}
          <Grid container direction='row'>
            <Paper style={{ width: '100%', margin: '2%' }}>
              <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                <Grid item xs={6}>
                  <Typography variant='h6' style={{ margin: '2%' }}>Contractor:</Typography>
                </Grid>
                <Grid item xs={6}>
                  { category3Finished ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : null }
                </Grid>
              </Grid>
              <Grid container direction='row'>
                <Grid item xs={12}>
                  <AddContractorSelect
                    retrieveContractorValue={this.retrieveContractorValue}
                    category2Finished={category2Finished}
                    contractorList={contractorList}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddContractorNameSelect 
                    retrieveContractorName={this.retrieveContractorName}
                    category2Finished={category2Finished}
                    workOrderContractor={workOrderContractor}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddContractorPhoneSelect
                    retrieveContractorPhone={this.retrieveContractorPhone}
                    category2Finished={category2Finished}
                    workOrderContractor={workOrderContractor}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddContractorEmailSelect
                    retrieveContractorEmail={this.retrieveContractorEmail}
                    category2Finished={category2Finished}
                    workOrderContractor={workOrderContractor}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

          
        {/* category 4 */}
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ height: '100%', width: '100%' }}>
          <Grid container direction='row'>
            <Paper style={{ width: '100%', margin: '2%' }}>
              <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                <Grid item xs={6}>
                  <Typography variant='h6' style={{ margin: '2%' }}>Submitter:</Typography>
                </Grid>
                <Grid item xs={6}>
                  { category4Finished ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : null }
                </Grid>
              </Grid>
              <Grid container direction='row'>
                <Grid item xs={12}>
                  <AddSubmittedBySelect
                    retrieveSubmitterValue={ this.retrieveSubmitterValue } 
                    category3Finished={ category3Finished }
                    bfAdminUsers={ bfAdminUsers }
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddSubmittedByNameSelect
                    retrieveSubmitterName={ this.retrieveSubmitterName }
                    workOrderSubmitter={ workOrderSubmitter }
                    category3Finished={ category3Finished }
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddSubmittedByPhoneSelect 
                    retrieveSubmitterPhone={ this.retrieveSubmitterPhone }
                    workOrderSubmitter={ workOrderSubmitter }
                    category3Finished={ category3Finished }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddSubmittedByEmailSelect 
                    retrieveSubmitterEmail={ this.retrieveSubmitterEmail }
                    workOrderSubmitter={ workOrderSubmitter }
                    category3Finished={ category3Finished }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* category 5 */}
          <Grid container direction='row'>
            <Paper style={{ width: '100%', margin: '2%' }}>
              <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                <Grid item xs={6}>
                  <Typography variant='h6' style={{ margin: '2%' }}>Assigned To:</Typography>
                </Grid>
                <Grid item xs={6}>
                  { category5Finished ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : null }
                </Grid>
              </Grid>
              <Grid container direction='row'>
                <Grid item xs={12}>
                  <AddAssignedToSelect 
                    retrieveAssignedValue={ this.retrieveAssignedValue }
                    contractorList={ contractorList }
                    category4Finished={ category4Finished }
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddAssignedToNameSelect 
                    retrieveAssignedName={this.retrieveAssignedName}
                    category4Finished={category4Finished}
                    workOrderAssigned={workOrderAssigned}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddAssignedToPhoneSelect 
                    retrieveAssignedPhone={this.retrieveAssignedPhone}
                    category4Finished={category4Finished}
                    workOrderAssigned={workOrderAssigned}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddAssignedToEmailSelect
                    retrieveAssignedEmail={this.retrieveAssignedEmail}
                    category4Finished={category4Finished}
                    workOrderAssigned={workOrderAssigned}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {/* category 6 */}
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ height: '100%', width: '100%' }}>
          <Grid container direction='row'>
            <Paper style={{ width: '100%', margin: '2%' }}>
              <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                <Grid item xs={4}>
                  <Typography style={{ margin: '2%' }} variant='h6'>Date:</Typography>
                </Grid>

              </Grid>
              <Grid container direction='row' alignItems={'center'} justify={'flex-start'}>
                <Grid item xs={6}>
                  <AddDateSelect
                    retrieveDate={this.retrieveDate}
                    category3Finished={category3Finished}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddTimeSelect
                    retrieveTimeStart={this.retrieveTimeStart}
                    category3Finished={category3Finished}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddTimeSelectEnd
                    retrieveTimeEnd={this.retrieveTimeEnd}
                    category3Finished={category3Finished}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid container direction='row'>
            <Paper style={{ width: '100%', margin: '2%' }}>
            <Typography variant='h6' style={{ margin: '2%' }}>Invoice Numbers:</Typography>
              <Grid container direction='row'>
                <Grid item xs={4}>
                  <AddContractorInvoiceNumber
                    retrieveContractorInvoiceNumber={this.retrieveContractorInvoiceNumber}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AddBluefinInvoiceNumber 
                    retrieveBluefinInvoiceNumber={this.retrieveBluefinInvoiceNumber}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AddPurchaseOrderNumber 
                    retrievePONumber={this.retrievePONumber}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AddExternalWorkOrderNumber 
                    retrieveExternalNumber={this.retrieveExternalNumber}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid container direction='row'>
            <Paper style={{ width: '100%', margin: '2%' }}>
              <Typography variant='h6' style={{ margin: '2%' }}>Estimates:</Typography>
              <Grid container direction='row'>
                <Grid item xs={4}>
                  <AddNTENumber
                    retrieveNTE={this.retrieveNTE}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AddEstimateNumber
                    retrieveEstimate={this.retrieveEstimate}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AddBidNumber
                    retrieveEstimate={this.retrieveEstimate}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>        
        </Grid>
      {/* footer */}
      <SubmitWorkOrder 
        handleNext={ this.handleNext }
        category1Finished={ category1Finished }
        category2Finished={ category2Finished }
        category3Finished={ category3Finished }
        category4Finished={ category4Finished }
        category5Finished={ category5Finished }
      />
    </Grid>
    )
  }
};

export default WorkOrderAddForm
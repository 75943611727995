
import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import { white } from 'material-ui/styles/colors';
import auth from '../auth';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn, TableCell } from 'material-ui/Table';
import IconRemove from 'material-ui/svg-icons/content/clear';
import IconEdit from 'material-ui/svg-icons/content/create';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { pink500, grey200, grey500, grey400, pink400 } from 'material-ui/styles/colors';
import InlineEdit from '../components/table/InlineEdit';
import InlineEditSpan from '../components/table/InlineEditSpan';
import FloatingActionButton from 'material-ui/FloatingActionButton';

const OrgPermissions = props => {
  const { styles, UserId, userPortalOrgUnits} = props;

  const style = {
    container: {  marginLeft: 10,
      border:'0px solid #000000'
      },
      addButton: { margin: '1em' },
      removeIcon: { fill: grey500 },
      editIcon: { fill: grey500 },
      columns: {
        View: { width: '10%', minWidth: 25, paddingRight: 5, paddingLeft: 5},
        Group: { width: '20%', minWidth: 45, paddingRight: 5, paddingLeft: 5},
        Edit: { width: '10%', minWidth: 45, paddingRight: 5, paddingLeft: 5}
      }
      };

  return (
   
      <div style={style.container}>

           <Table >
              <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                  <TableRow>
                  <TableHeaderColumn style={style.columns.Group}>Company</TableHeaderColumn>
                      <TableHeaderColumn style={style.columns.Group}>Organization Tag</TableHeaderColumn>
                      <TableHeaderColumn style={style.columns.View}>View</TableHeaderColumn>
                  <TableHeaderColumn style={style.columns.Edit}>Edit</TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody displayRowCheckbox={false}> 
                {userPortalOrgUnits!=null?userPortalOrgUnits.map(item => (
                      <TableRow key={item.OrgUnitId}>
                          <TableRowColumn style={style.columns.Group}>
                     {item.CompanyName}
                    </TableRowColumn>
                    <TableRowColumn style={style.columns.Group}>
                      {item.Title}
                    </TableRowColumn>
                          <TableRowColumn style={style.columns.View}>
                               <Checkbox key={"cbxview" + item.OrgUnitId.toString()}
                                  disabled={!item.Editable}
                                             value={item.UserId.toString()}
                                  onClick={(e) => props.handleViewPermUpdate({ OrgUnitId: item.OrgUnitId, UserId: item.UserId, 
                                      View: e.target.checked})}
                                  checked={item.View} 
                                  disabled={!item.Editable}
                                            />
                           
                    </TableRowColumn>
                          <TableRowColumn style={style.columns.Edit}>
                              <Checkbox key={"cbxedit" + item.OrgUnitId.toString()}
                                  disabled={!item.Editable}
                                            value={item.UserId.toString()}
                                            onClick={(e) => props.handleEditPermUpdate({ OrgUnitId: item.OrgUnitId, UserId: item.UserId, Edit: e.target.checked})}
                                            checked={item.Edit} disabled={!item.Editable}
                                            />
                      
                    </TableRowColumn>
                  </TableRow>
                )):''}
              </TableBody>
            </Table>
              </div>
   
  );
};

OrgPermissions.propTypes = {
  styles: PropTypes.object,
    handleChangeRequestNavDrawer: PropTypes.func
};

export default OrgPermissions;


import React, { useState, useEffect } from 'react';
import PropertyDefectsCardImagesStepper from './PropertyDefectsCardImagesStepper';
import Gallery from 'react-grid-gallery';
import {
  Grid,
} from '@material-ui/core';

export default function PropertySectionMaterialImages(props) {
  const [openLightBox, setOpenLightBox] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  const { images } = props;

  const handleLightBoxOpen = () => {
    setOpenLightBox(!openLightBox);
  };

  const currentImageWillChange = () => {
    return activeImage;
  };

  return (
    <div style={{ display: 'contents' }}>{images.length > 0 ?
      <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
        <Grid item xs={10} style={{ width: '100%', marginTop: '2%', marginBottom: '2%', marginLeft: '20%', marginRight: '20%' }}>
          <Gallery
            currentImage={activeImage}
            lightboxWillOpen={handleLightBoxOpen}
            lightboxWillClose={handleLightBoxOpen}
            onClickPrev={() => setActiveImage(activeImage - 1)}
            onClickNext={() => setActiveImage(activeImage + 1)}
            enableImageSelection={false}
            currentImageWillChange={currentImageWillChange}
            showImageCount={false}
            showLightboxThumbnails={true}
            backdropClosesModal={true}
            images={openLightBox ? images : [images[activeImage]]}
            lightBoxProps={{ currentImage: activeImage }}
            maxRows={1}
          />
        </Grid>
        <Grid item xs={12} style={{ width: '100%' }}>
          <PropertyDefectsCardImagesStepper
            numberOfImages={images.length}
            setActiveImage={setActiveImage}
          />
        </Grid>
      </Grid>
      : null}
    </div>
  )
}
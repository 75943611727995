import React, { Component } from 'react'
import { LoadScript, LoadScriptNext, useLoadScript, GoogleMap } from '@react-google-maps/api'

function Wrap(props){  
  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey="AIzaSyCv4vxcO_u035c4cM2TjRqTxW6X36Scl-c"
    >
      {props.children}
    </LoadScript>
  )
};

export default function ScriptLoaded(props) {
  if (props.children) {
    return (
      <Wrap>{props.children}</Wrap>
    )
  }
};

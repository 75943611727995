import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import PropertyDefectsList from './PropertyDefectsList';
import PropertyDefectsListHeader from './SubComponents/PropertyDefectsListHeader';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { bool } from 'prop-types';

class PropertyDefects extends Component {
  constructor(props) {
    super(props)

    this.state = {
      defects: null,
      defectsCategorySelected: null,
      repairsCategorySelected: null,
      selectedDefectSection: null,
      selectedDefectGroupIds: [],
      defectGroups: [],
      selectedDefects: [],
      defectRepairs: [],
      defectsTotal: null,
      filteredByProposed: null,

      retrievedDefects: null,
      highlightedDefect: null,
      selectedGroupCard: null,
      displayDefects: true,
    };
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { 
      defectsCategorySelected,
      selectedDefectSection,
      selectedDefectGroupIds,
      selectedDefects,
      defectGroups,
      defectRepairs,
      highlightedDefect,
      defectsTotal,
      filteredByProposed,
      displayDefects,
    } = this.state;

    const previousHighlightedDefect = prevState.highlightedDefect;
    const previousSelectedDefects = prevState.selectedDefects;
    const previousSelectedDefectSection = prevState.selectedDefectSection;
    const previousDefectsCategorySelected = prevState.defectsCategorySelected;
    const previousSelectedDefectGroupIds = prevState.selectedDefectGroupIds;
    const previousFilteredByProposed = prevState.filteredByProposed;
    const previousDisplayDefects = prevState.displayDefects;
    
    const {
      selectedProperty,
    } = this.props;

    const allDefectsFilteredByProposed = this.filterAllResultsByProposed(selectedProperty.DefectCM);
    const allDefectGroups = filteredByProposed ? allDefectsFilteredByProposed : [...new Set(selectedProperty.DefectCM)];
    const allDefectsArray = this.getAllDefects(allDefectGroups);
    const defectGroupsInSection = this.filterDefectGroups(allDefectGroups, selectedDefectSection);
    const defectsFromGroups = this.getDefectsFromGroups(defectGroupsInSection, selectedDefectSection);

    if (selectedProperty) {
      //resets defect groups and selected defects if the user has decided to filter the results by 'proposed'      
      if (filteredByProposed != previousFilteredByProposed) {
        this.setState({
          defectGroups: allDefectGroups,
          selectedDefects: allDefectsArray,
        })
      }

      if (displayDefects != previousDisplayDefects) {
        if (displayDefects) {
          this.setState({
            defectGroups: allDefectGroups,
            selectedDefects: allDefectsArray,
          })
        }
        if (!displayDefects) {
          this.setState({
            defectGroups: [],
            selectedDefects: [],
          })
        }
      }

      // if (defectsCategorySelected) {
        if (allDefectGroups.length > 0 && displayDefects) {
          if (defectGroups.length == 0) {
            if (!selectedDefectSection) {
              this.setState({
                defectGroups: allDefectGroups,
                selectedDefects: allDefectsArray,
              }
              // , console.log('initialing with allDefectGroups')
              )
            }
            if (selectedDefectSection) {
              if (selectedDefectSection != previousSelectedDefectSection) {
                this.setState({
                  defectGroups: [...new Set(defectGroupsInSection)],
                  selectedDefects: [...new Set(defectsFromGroups)], 
                }
                // , console.log('initializing with defectGroupsInSection')
                )
              }
            }
          } 
        }
      // }
      
        if (defectGroups.length > 0) {
            if (defectsCategorySelected != previousDefectsCategorySelected) {
                if (defectsCategorySelected) {
                    const allDefectsArray = this.getAllDefects(allDefectGroups);
                    this.setState({
                        selectedDefects: [...new Set(allDefectsArray)],
                    })
                }
                if (!defectsCategorySelected) {
                    this.setState({
                        selectedDefects: [],
                    })
                }
            }

            //if (selectedDefectSection == previousSelectedDefectSection) {
                console.log("selectedDefectSection same as before");
                if (selectedDefectGroupIds != previousSelectedDefectGroupIds) {
                    if (selectedDefectGroupIds.length > 0) {
                        const selectedDefectsArray = this.getSelectedDefects();
                        this.setState({
                            selectedDefects: [...new Set(selectedDefectsArray)],
                        })
                    }
                    if (selectedDefectGroupIds.length == 0) {
                        let defects;
                        if (!selectedDefectSection) {
                            defects = this.getAllDefects(allDefectGroups);
                        }
                        if (selectedDefectSection) {
                            const defectGroupsInSection = this.filterDefectGroups(allDefectGroups, selectedDefectSection);
                            const defectsFromGroups = this.getDefectsFromGroups(defectGroupsInSection, selectedDefectSection);
                            defects = defectsFromGroups;
                        }
                        this.setState({
                            selectedDefects: [...new Set(defects)],
                            highlightedDefect: null,
                        })
                    }
                }
            //}

            if (selectedDefectSection != previousSelectedDefectSection) {
                if (selectedDefectSection) {
                    if (!previousSelectedDefectSection) {
                        console.log(defectGroups);
                        const defectGroupsInSection = this.filterDefectGroups(allDefectGroups, selectedDefectSection);
                        const defectsFromGroups = this.getDefectsFromGroups(defectGroupsInSection, selectedDefectSection);
                        console.log(defectGroupsInSection.length);
                        const totalDefectsCost = (defectGroupsInSection.length > 0 ? this.evaluateTotalDefectsCost(defectGroupsInSection) : 0);
                        console.log(totalDefectsCost);
                        this.setState({
                            selectedDefects: [...new Set(defectsFromGroups)],
                            defectGroups: [...new Set(defectGroupsInSection)],
                            selectedDefectGroupIds: [],
                            highlightedDefect: null,
                            defectsTotal: totalDefectsCost,
                        })
                    }
                    if (previousSelectedDefectSection) {
                        console.log(defectGroups);
                        const defectGroupsInSection = this.filterDefectGroups(allDefectGroups, selectedDefectSection);
                        const defectsFromGroups = this.getDefectsFromGroups(defectGroupsInSection, selectedDefectSection);
                        console.log(defectGroupsInSection.length);
                        const totalDefectsCost = (defectGroupsInSection.length > 0 ? this.evaluateTotalDefectsCost(defectGroupsInSection) : 0);
                        console.log(totalDefectsCost);
                        this.setState({
                            selectedDefects: [...new Set(defectsFromGroups)],
                            defectGroups: [...new Set(defectGroupsInSection)],
                            selectedDefectGroupIds: [],
                            highlightedDefect: null,
                            defectsTotal: totalDefectsCost,
                        })
                    }
                }else{
                    const allDefectsArray = this.getAllDefects(allDefectGroups);
                    const totalDefectsCost = (defectGroupsInSection.length > 0 ? this.evaluateTotalDefectsCost(allDefectGroups) : 0);
                    console.log(totalDefectsCost);
                    this.setState({
                        selectedDefects: [...new Set(allDefectsArray)],
                        defectGroups: [...new Set(allDefectGroups)],
                        selectedDefectGroupIds: [],
                        highlightedDefect: null,
                        defectsTotal: totalDefectsCost,
                    })
                }
            }
        }

      //for property's total estimated defect cost

        if (allDefectGroups) {
            console.log("allDefectGroups is valid" + selectedDefectSection);

            /*if (selectedDefectSection == 0) {

                if (filteredByProposed != previousFilteredByProposed) {
                    if (!filteredByProposed) {
                        const totalDefectsCost = this.evaluateTotalDefectsCost(allDefectGroups);
                        this.setState({
                            defectsTotal: totalDefectsCost,
                        })
                    } else {
                        const filteredDefectsCost = this.evaluateTotalDefectsCost(allDefectsFilteredByProposed);
                        this.setState({
                            defectsTotal: filteredDefectsCost,
                        })
                    }
                }

            }*/
            if (!defectsTotal) {
                const totalDefectsCost = this.evaluateTotalDefectsCost(allDefectGroups);

                console.log("Cost:" + totalDefectsCost);
                if (totalDefectsCost) {
                    this.setState({
                        defectsTotal: totalDefectsCost,
                    })
                } else {
                    this.setState({
                        defectsTotal: 'N/A',
                    })
                }
                console.log("Cost:" + totalDefectsCost);
            } else {
                if (filteredByProposed != previousFilteredByProposed) {
                    if (!filteredByProposed) {
                        const totalDefectsCost = this.evaluateTotalDefectsCost(allDefectGroups);
                        this.setState({
                            defectsTotal: totalDefectsCost,
                        })
                    }else{
                        const filteredDefectsCost = this.evaluateTotalDefectsCost(allDefectsFilteredByProposed);
                        this.setState({
                            defectsTotal: filteredDefectsCost,
                        })
                    }
                }
            }
        }
    }

    const { 
      retrieveSelectedDefectSection,
      retrieveSelectedDefects,
      retrieveHighlightedDefect, 
    } = this.props;

    if (selectedDefectSection != previousSelectedDefectSection) {
      retrieveSelectedDefectSection(selectedDefectSection);
    }

    if (selectedDefects != previousSelectedDefects) {
      retrieveSelectedDefects(selectedDefects);
    }

    if (highlightedDefect != previousHighlightedDefect) {
      retrieveHighlightedDefect(highlightedDefect);
    }
  };

  filterAllResultsByProposed = (allDefectGroups) => {
    if (allDefectGroups) {
      const proposedGroups = [];
      const proposedDefectValue = 2;
      allDefectGroups.map((defectGroup) => {
        const proposedDefectsInGroup = [];
        const defectGroupName = defectGroup.GroupName;
        const defId = defectGroup.WBSDefId;
        const defectCount = defectGroup.DefectCount;
        const groupCost = defectGroup.GroupCost;
        const defects = defectGroup.Defects;
        defects.map((defect) => {
          const defectStatusState = defect.DefectStateLast;
          const proposedStatus = defectStatusState.ListId;
          if (proposedStatus == proposedDefectValue) {
             proposedDefectsInGroup.push(defect);
          }
        })
        if (proposedDefectsInGroup.length > 0) {
          const filteredDefectGroup = {
            GroupName: defectGroupName,
            WBSDefId: defId,
            DefectCount: defectCount,
            Defects: proposedDefectsInGroup,
            GroupCost: groupCost,
          };
          proposedGroups.push(filteredDefectGroup);
        }
      })
      return [...new Set(proposedGroups)];
    }
  };

  evaluateTotalDefectsCost = (allDefectGroups) => {
    let totalDefectCost = 0;

    allDefectGroups.map((defectGroup) => {
      const groupCost = defectGroup.GroupCost;
      totalDefectCost = totalDefectCost + groupCost;
    });

    return totalDefectCost;
  };

  filterDefectGroups = (defectGroups, selectedDefectSection) => {
    const defectGroupsInSection = [];
    defectGroups.map((defectGroup) => {
        const defects = defectGroup.Defects;
      defects.map((defect) => {
        const defectSectionId = defect.SectionId;
          if (defectSectionId == selectedDefectSection) {
              var alreadyInArray = false;

              defectGroupsInSection.map((defCheck) => {
                  if (defCheck.GroupName === defectGroup.GroupName && defCheck.WBSDefId === defectGroup.WBSDefId && defCheck.DefectCount === defectGroup.DefectCount) {
                      alreadyInArray = true;
                  }
              });

              if (!alreadyInArray) {
                  defectGroupsInSection.push(defectGroup);
              }
          }
      })
    })
    return defectGroupsInSection;
  };

  getDefectsFromGroups = (defectGroupsInSection, selectedDefectSection) => {
    const defectsInSection = [];
    defectGroupsInSection.map((defectGroup) => {
      const defects = defectGroup.Defects;
      defects.map((defect) => {
        if (selectedDefectSection) {
          if (defect.SectionId == selectedDefectSection) {
            defectsInSection.push(defect);
          }
        } else {
          defectsInSection.push(defect);
        }
      })
    })
    return defectsInSection;
  };

  getAllDefects = (defectGroups) => {
    const allDefects = [];
    defectGroups.map((defectGroup) => {
      const defects = defectGroup.Defects;
      defects.map((defect) => {
        allDefects.push(defect);
      })
    });
    return allDefects;
  };

  getSelectedDefects = () => {
    const { selectedDefectGroupIds, defectGroups, selectedDefectSection } = this.state;
    const defectGroupsCopy = [...defectGroups];
    const selectedDefectsArray = [];
    selectedDefectGroupIds.map((selectedDefectGroupId => {
      const selectedGroup = defectGroupsCopy.find(defectGroupCopy => defectGroupCopy.WBSDefId == selectedDefectGroupId);
      const selectedGroupDefects = selectedGroup.Defects;
      selectedGroupDefects.map((selectedGroupDefect) => {
        const sectionId = selectedGroupDefect.SectionId; 
        if (selectedDefectSection) {
          if (selectedDefectSection == sectionId) {
            selectedDefectsArray.push(selectedGroupDefect);
          }
        } else {
          selectedDefectsArray.push(selectedGroupDefect);
        }
      })
    }))
    return selectedDefectsArray;
  };

  handleDefectsCategorySelected = (selected) => {
    this.setState({
      defectsCategorySelected: selected,
    })
  };

  handleRepairsCategorySelected = (selected) => {
    this.setState({
      repairsCategorySelected: selected,
    })
  };

  retrieveSelectedDefectSection = (selectedDefectSection) => {
    this.setState({
      selectedDefectSection,
    })
  };

    retrieveFilterDefectsByProposed = (bool) => {
        console.log("Proposed flag:" + bool);
    this.setState({ 
      filteredByProposed: bool,
    })
  };

  retrievedSelectedDefectsGroups = (defectGroupId) => {
    const { selectedDefectGroupIds } = this.state;
    const included = selectedDefectGroupIds.includes(defectGroupId);
    if (included) {
      const selectedDefectGroupIdsCopy = [...selectedDefectGroupIds];
      const filteredDefectsGroups = selectedDefectGroupIdsCopy.filter(selectedDefectGroupCopy => selectedDefectGroupCopy != defectGroupId);
      this.setState({
        selectedDefectGroupIds: filteredDefectsGroups,
      })
    }

    if (!included) {
      this.setState({
        selectedDefectGroupIds: [...selectedDefectGroupIds, defectGroupId],
      })
    }
  };

  highlightSelectedDefect = (selectedDefectId) => {
    const { highlightedDefect } = this.state;
    if (selectedDefectId == highlightedDefect) {
      this.setState({
        highlightedDefect: null,
      })
    } else {
      this.setState({
        highlightedDefect: selectedDefectId,
      })
    }
  };

  handleDisplayDefects = (checked) => {
    this.setState({
      displayDefects: checked,
    })
  };


  componentWillUnmount = () => {
    console.log('unmounting PROPERTYDEFECTS');
  };

  render() {
    const { 
      selectedProperty,
    } = this.props;

    const { 
      selectedDefectSection,
      defectGroups,
      defectsTotal,
      filteredByProposed,
      displayDefects,
     } = this.state;

    return (
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item xs={12}>
          <PropertyDefectsListHeader 
            handleDisplayDefects={this.handleDisplayDefects}
          />
        </Grid>
        <Grid item xs={12}>
          <PropertyDefectsList
            handleDefectsCategorySelected={this.handleDefectsCategorySelected}
            retrievedSelectedDefectsGroups={this.retrievedSelectedDefectsGroups}
            retrieveSelectedDefectSection={this.retrieveSelectedDefectSection}
            retrieveFilterDefectsByProposed={this.retrieveFilterDefectsByProposed}
            highlightSelectedDefect={this.highlightSelectedDefect}
            defectGroups={defectGroups}
            selectedProperty={selectedProperty}
            selectedDefectSection={selectedDefectSection}
            defectsTotal={defectsTotal}
            filteredByProposed={filteredByProposed}
            displayDefects={displayDefects}
          />
        </Grid>
      </Grid>
    )
  }
};

export default PropertyDefects
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Paper,
  Grid,
}  from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '96%',
    left: '.5%',
    fontFamily: 'Ebrima',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontFamily: 'Ebrima',
  },
}));

export default function SimpleSelect(props) {
  const [ tagOptions, setTagOptions ] = useState([]);
  const [ tag, setTag ] = useState(0);

  const { 
    handleUpdateTags,

    originalLeakResponseId,
    selectedTag,
    userTags 
  } = props;
  
  const handleChange = (e) => {
    const tagId = e.target.value;
    setTag(tagId);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if (userTags && userTags.length > 0) {
      setTagOptions(userTags);
    }
  }, [userTags]);

  const previousTag = usePrevious(tag);
  useEffect(() => {
    if (tag && userTags.length) {
      const selectedTagObj = userTags.find((userTag => userTag.OrgUnitId == tag));
      handleUpdateTags(selectedTagObj);
    }
    
    //if initializing
    if (!previousTag) {
      if (tag && userTags.length) {
        const selectedTagObj = userTags.find((userTag => userTag.OrgUnitId == tag));
        handleUpdateTags(selectedTagObj);
      }
    }
    
    //if initialized
    if (previousTag) {
      if (tag && userTags.length) {
        const selectedTagObj = userTags.find((userTag => userTag.OrgUnitId == tag));
        handleUpdateTags(selectedTagObj);
      }

      // user set tag to null
      if (!tag) {
        if (tag != previousTag) {
          console.log('updating tags to null')
          handleUpdateTags(null);
        }
      }
    }
  }, [tag]);

  useEffect(() => {
    if (selectedTag) {
      if (!tag) {
        if (Object.values(selectedTag).length) {
          const tagId = selectedTag.OrgUnitId;
          setTag(tagId);
        }
      }
    }
  }, [selectedTag]);

  const classes = useStyles();

  // console.log(props)

  return (
    <Grid container direction='row'>
      <Grid xs={12} style={{ padding: '2%' }}>
        <Paper style={{ width: '100%', boxShadow: 'none' }}>
          
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label" style={{fontFamily: 'Ebrima',}}>
              Property Tags
            </InputLabel>
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              autoFocus
              value={tag}
              onChange={handleChange}
              className={classes.selectEmpty}
            >
              { originalLeakResponseId ? 
              null
              :
              <MenuItem value={0}>
                <em>Select A Tag</em>
              </MenuItem>
              }
              { tagOptions.map((tagOption) => {
                const id = tagOption.OrgUnitId;
                const title = tagOption.Title;
                return (
                  <MenuItem value={ id }>{ title }</MenuItem>
                )
              }) }
            </Select>
          </FormControl>

        </Paper>
      </Grid>
    </Grid>
  );
}

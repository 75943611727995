import React, { useState, useEffect, useRef } from 'react';
import FromCompanySelect from './Subcomponents/FromCompanySelect';
import ToCompanySelect from './Subcomponents/ToCompanySelect';
import FromBuildingSelect from './Subcomponents/FromBuildingSelect';
import ToCompanyTagSelect from './Subcomponents/ToCompanyTagSelect';
import MoveBuildingsSaveModal from './Subcomponents/MoveBuildingsSaveModal';
import MovePropertySuccessAlert from './Subcomponents/MovePropertySuccessAlert';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';

export default function AdminTaskContainer(props) {
 const{
   handleFromCompanySelect,
   handleFromBuildingSelect,
   handleToCompanySelect,
   handleToCompanyTagSelect,
   handleSaveMoveBuildingsSelect,
   handleSaveMoveBuildingsCancel,
   handleSaveMoveBuildingsDispatch,

   userCompanies,
   userBuildings,
   moveToCompanies,
   moveToCompanyTags,

   toSelectedCompany,
   selectedCompany,
   selectedBuildings,
   selectedTags,

   movePropertyResponse,

   selectedFromBuildings,
   formComplete,
   saveSelected,
 } = props;

  return (
    <Grid container direction='row' alignItems='flex-start' justify='center' style={{ padding: '1%', backgroundColor: 'white' }}>
      <MoveBuildingsSaveModal 
        handleSaveMoveBuildingsDispatch={handleSaveMoveBuildingsDispatch}
        handleSaveMoveBuildingsCancel={handleSaveMoveBuildingsCancel}

        toSelectedCompany={toSelectedCompany}
        selectedCompany={selectedCompany}
        selectedBuildings={selectedBuildings}
        selectedTags={selectedTags}

        saveSelected={saveSelected}
      />
      <MovePropertySuccessAlert 
        movePropertyResponse={movePropertyResponse}
      />
      <Grid item xs={12}>
        <Typography variant='h6' style={{fontFamily:'Ebrima'}}>Move Buildings</Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction='row'>
          <Grid item xs={12}>
            <FromCompanySelect 
              handleFromCompanySelect={handleFromCompanySelect}
              userCompanies={userCompanies}
              selectedCompany={selectedCompany}
              movePropertyResponse={movePropertyResponse}
            />
          </Grid>
          <Grid item xs={12}>
            <FromBuildingSelect
              handleFromBuildingSelect={handleFromBuildingSelect}
              userBuildings={userBuildings}
              selectedCompany={selectedCompany}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction='row'>
          <Grid item xs={12}>
            <ToCompanySelect
              handleToCompanySelect={handleToCompanySelect}
              moveToCompanies={moveToCompanies}
              selectedFromBuildings={selectedFromBuildings}
              toSelectedCompany={toSelectedCompany}
              masterSelectedCompany={selectedCompany}
            />
          </Grid>
          <Grid item xs={12}>
            <ToCompanyTagSelect
              handleToCompanyTagSelect={handleToCompanyTagSelect}
              toSelectedCompany={toSelectedCompany}
              selectedFromBuildings={selectedFromBuildings}
              moveToCompanyTags={moveToCompanyTags}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} style={{ textAlign: 'center'}}>
        <Button disabled={!formComplete} variant='contained' color='primary' onClick={()=>handleSaveMoveBuildingsSelect()}>Move</Button>
      </Grid>
    </Grid>
  );
}

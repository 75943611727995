import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  const [ name, setName ] = useState(null);
  const {
    deleteWorkOrderItem,
    handleDeleteWorkOrderItemResponse,
    // saveOrCancelWorkOrderItems,
    // handleSaveAndCancelWorkOrderItem,
    // handleCancelWorkOrderItem,
  } = props;

  const handleDeleteClick = () => {
    handleDeleteWorkOrderItemResponse(true);
  };

  const handleCancelClick = () => {
    handleDeleteWorkOrderItemResponse(false);
  };

  useEffect(() => {
    if (deleteWorkOrderItem) {
      const { Name } = deleteWorkOrderItem;
      setName(Name);
    }
  }, [deleteWorkOrderItem]);

  return (
    <Dialog
      open={deleteWorkOrderItem}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to delete item <span style={{fontWeight: 'bold'}}>'{name}'</span> from this Work Order? </DialogTitle>
      <DialogActions>
        <Button color="primary" onClick={() => handleDeleteClick()}>
          Delete
          </Button>
        <Button color="primary" onClick={() => handleCancelClick() } autoFocus>
          Cancel
          </Button>
      </DialogActions>
    </Dialog>
  );
}

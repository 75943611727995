import { makeStyles } from '@material-ui/core/styles';

const ManageContactsAddUserButtonStyle = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

export default ManageContactsAddUserButtonStyle
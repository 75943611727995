import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  IconButton,
} from '@material-ui/core';

export default function EditExternalWONumber(props) {
 

  return (
    <div style={{ height: '90%', overflow: 'auto', display: 'flex' }}>
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item style={{height: '100%', width: '100%'}}>
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdAGB0ubMnKkzKQ8renO8poB7mOifDGo4gZ2D6wJWQHsl3Pwg/viewform?embedded=true"
            width="100%"
            height="100%"
            frameborder="0"
            marginheight="0"
            marginwidth="0">
            Loading…</iframe>
        </Grid>
      </Grid>
    </div>
  );
}
class Auth {
    url = "/token";


    signIn(username, password, reset) {

        if(password == undefined){
            password = "";
        }

        return fetch(this.url, {
            method: 'post',
            mode: 'no-cors',
            body: "username=" + username + "&password=" + encodeURIComponent(password) + "&reset=" + reset + "&grant_type=password&client_id=dotnetifydemo",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
        })
            .then(response => {
            if (!response.ok) throw new Error(response.status);
            return response.json();
        })
            .then(token => {
            window.sessionStorage.setItem("access_token", token.access_token);
        });
    }

    signOut() {
        console.log("SignOut()XXXXXXXXXXXXX");
        window.sessionStorage.removeItem("access_token");
        window.location.href = "/";
    }

    changePassword(username, tmppassword, newpassword) {
        console.log(this.url);
       
        
        return fetch(this.url, {
            method: 'post',
            mode: 'no-cors',
            body: "username=" + username + "&tmppassword=" + tmppassword + "&newpassword=" + encodeURIComponent(newpassword ) + "&grant_type=newpassword&client_id=dotnetifydemo",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
        })
            .then(response => {
                console.log(response);
            if (!response.ok) throw new Error(response.status);
            return response.json();
        })
            .then(token => {
            window.sessionStorage.setItem("access_token", token.access_token);
        });
    }

    getAccessToken() {
        console.log("Getting ACCESS TOKEN DKDKDKDK");
        return window.sessionStorage.getItem("access_token");
    }

    hasAccessToken() {
        return this.getAccessToken() != null;
    }
}

export default new Auth();
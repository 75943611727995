import React from 'react';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Avatar,
  IconButton,
  Grid,
  ListItem,
  Typography,
} from '@material-ui/core';
import ManageContactsCardStyle from '../../../styles/ManageContactsStyles/ManageContactsCardStyle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

export default function ManageContactsCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  
  const { 
    contact
  } = props;
  
  const { 
    FirstName,
    LastName,
    FullName, 
    Add1, 
    Add2, 
    City, 
    State, 
    Country, 
    ContactTypesFormatted, 
    Email, 
    Phone1, 
    Phone2, 
    ProfileUrl 
  } = contact;

  const classes = ManageContactsCardStyle();
  const {
    card,
    avatar,
    cardContent
  } = classes;

  const initials = `${FirstName.charAt(0)}${LastName.charAt(0)}`;

  function handleExpandClick() { setExpanded(!expanded) };

  console.log(contact)
  return (
    <Grid container>
      <ListItem>
        <Grid style={{ width: '100%' }} item>
          <Card onClick={handleExpandClick} className={card}>
            <CardHeader
              avatar={<Avatar className={avatar} src={ProfileUrl}>{initials}</Avatar>}
              title={FullName}
              subheader={ContactTypesFormatted}
              action={
                <div>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded ? true : false,
                    })}
                    aria-expanded={expanded ? true : false}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </div>
              }
            />
            <Collapse in={expanded ? true : false} timeout="auto" unmountOnExit>
              <CardContent className={cardContent}>
                <Grid container direction={'row'}>
                  <Grid item align={'left'} xs={2}>
                    <HomeIcon />
                  </Grid>
                  <Grid item align={'left'} xs={10}>
                    {/* <Typography><HomeIcon /> Location:</Typography> */}
                    <Typography>{Add1} {Add2}</Typography>
                    <Typography>{City}, {State}</Typography>
                    <Typography>{Country}</Typography>
                  </Grid>
                </Grid>
                <br></br>
                <Grid container direction={'row'}>
                  <Grid item align={'left'} xs={2}>
                    <ContactPhoneIcon />
                  </Grid>
                  <Grid item align={'left'} xs={10}>
                    <Typography>{Email}</Typography>
                    <Typography>{Phone1}</Typography>
                    <Typography>{Phone2}</Typography>
                  </Grid>
                </Grid>
                
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
      </ListItem>
    </Grid>
  );
}

import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import auth from '../../auth';
import Iframe from 'react-iframe';
import {
  Grid,
  List,
  Paper,
} from '@material-ui/core';

class SiteMap extends Component {
  constructor(props){
    super(props);
    this.vm = dotnetify.react.connect('SiteMap', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        auth.signOut();
      }
    });
    this.dispatch = state => this.vm.$dispatch(state);
    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
      SiteMapUrl: "http://www.blue"
    }
  }

  componentWillUnmount = () => {
    console.log('unmounting SiteMap');
    this.vm.$destroy();
  };

  render () {
    const {
      SiteMapUrl
    } = this.state;

    console.log(this.state)

    return (
      
        <Iframe url={SiteMapUrl}
        width="100%"
        height="100%"
        id="myId"
        display="initial"
        position="relative"
        frameBorder="0"/>
    )
  }
};

export default SiteMap


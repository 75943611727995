import React, { useEffect, useState } from 'react';
import {
    InputLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Button,
    IconButton,
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
    },
    tableWrapper: {
        maxHeight: '100%',
        overflow: 'auto',
    },
}));

const forceSticky = { position: 'sticky', top: '0', zIndex: '10', backgroundColor: 'white' };

export default function PropertyFinancialSectionDetail(props) {
    const {
        expandedDefects,
        section,
        forecastData,
        property,
    } = props;

    const classes = useStyles();

    return (
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    {forecastData ? forecastData.map((data) => {
                        const name = data.YearText;
                        const id = data.Year;
                        return (
                            <TableCell align="right">{name}</TableCell>
                        )
                    }) : null}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Repair Budget</TableCell>
                    {forecastData ? forecastData.map((dataForecast) => {
                        const sects = dataForecast.sections;
                        
                        {
                            sects ? sects.map((dataSect) => {
                                (dataSect && dataSect.SectionId == section.SectionId ?

                                    console.log(dataSect.Year + " " + dataSect.SectionId + "|" + dataSect.Repair + "|" + dataSect.Replace + "|" + dataSect.Rehab)

                                    : 
                                        null
                                    )

                            }) : null
                        }
                                                 
                    }) : null}
                </TableRow>
                <TableRow>
                     <TableCell align="right">
                         <Table size='small'>
                             <TableRow align="right">
                                {expandedDefects.map((defect) => {
                                    const defectName = defect.DefectName;
                                    const defectCost = defect.RecommendCost;
                                    return (
                                        <TableRow>
                                            <TableCell align="right">{defectName}:</TableCell>
                                            <TableCell align="right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(defectCost)}</TableCell>
                                        </TableRow>
                                     )
                                })}
                                 <TableRow align="right">
                                    <TableCell align="right">Operating Expense:</TableCell>
                                    <TableCell align="right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(section.OE)}</TableCell>
                                 </TableRow>
                            </TableRow>
                            <TableRow align='right'>
                                <TableRow align='right'>
                                    <TableCell align="right">
                                        <span style={{ fontWeight: 'bold' }}>{`${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(section.OE + section.Repair)}`}</span>
                                    </TableCell>
                                </TableRow>
                            </TableRow>
                        </Table>
                    </TableCell>
                    <TableCell align="right">
                        <Table size='small'>
                            <TableRow align='right'>
                                <TableRow>
                                    <TableCell align="right">Replace Area Sq Ft:</TableCell>
                                    <TableCell align="right">{section.Replace != 0 ? new Intl.NumberFormat('en').format(section.SF) : "N/A"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">$/Sq Ft:</TableCell>
                                    <TableCell align="right">{section.Replace != 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(section.Replace / section.SF) : "N/A"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">Rehab Area Sq Ft:</TableCell>
                                    <TableCell align="right">{section.Rehab != 0 ? new Intl.NumberFormat('en').format(section.SF) : "N/A"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">$/Sq Ft:</TableCell>
                                    <TableCell align="right">{section.Rehab != 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(section.Rehab / section.SF) : "N/A"}</TableCell>
                                </TableRow>
                            </TableRow>
                        </Table>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}



import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  Grid,
} from '@material-ui/core';
import LocationCityIcon from '@material-ui/icons/LocationCity';

const useStyles = makeStyles({
  card: {
    width: '100%',
    boxShadow: 'none',
    position: 'sticky',
    padding: '1%',
    zIndex: 1,
    fontFamily: 'Ebrima'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
    fontFamily: 'Ebrima'
  },
  title: {
    fontSize: 14,
    fontFamily: 'Ebrima'
  },
  pos: {
    marginBottom: 12,
    fontFamily: 'Ebrima'
  },
});

export default function PropertyHeaderCard(props) {
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const [rcs, setRcs] = useState(0);
  const [ersl, setErsl] = useState(0);
  const [weightedErsl, setWeightedErsl] = useState(0);
  const [weightedRcs, setWeightedRcs] = useState(0);
    const [area, setArea] = useState(0);
    const [qty, setQty] = useState(0);
  const [surveyBy, setSurveyBy] = useState('');
  const [surveyDate, setSurveyDate] = useState('');
  const {
    mobile,
    property,
    selectedResult,
      materialType,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (property) {
      if (selectedResult) {
        const selectedResultId = selectedResult.Id;
        const propertyId = property.Id;
        if (selectedResultId == propertyId) {
          const {
            Name,
            Address1,
            Address2,
            City,
            StateName,
            Zip,
            CurrentBudget,
            SurveyBy,
            SurveyDate,
            WeightedERSL,
              WeightedRCS,
            Qty,
          } = property;
          const address = `${Address1} ${Address2} ${City} ${StateName} ${Zip}`;
          setTitle(Name);
          setAddress(address);
          setSurveyBy(SurveyBy);
            setSurveyDate(SurveyDate);
            setWeightedErsl(WeightedERSL);
            setWeightedRcs(WeightedRCS);
            setQty(Qty);

          if (CurrentBudget) {
            const CurrentBudgetExists = Object.values(CurrentBudget).length;
            if (CurrentBudgetExists) {
              const {
                Area,
                StartErsl,
                StartCs,
              } = CurrentBudget;
              setArea(Area);
              setErsl(StartErsl);
              setRcs(StartCs);
            }
          }
        } else {
          setTitle('');
          setAddress('');
        }
      }
    }
  }, [property]);

  return (
    <Card square style={mobile ? { display: 'none' } : null} className={classes.card}>
      <CardContent style={{ padding: '0px' }}>
        <Grid container direction='row' justify={'center'} alignItems={'center'}>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <LocationCityIcon fontSize='large' />
          </Grid>
          <Grid item xs={10} style={{ textAlign: 'center', minHeight: '4rem' }}>
            <Typography variant="h5" component="h2" style={{fontFamily:'Ebrima',}}>
              {title}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {address}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction='row' justify='center' alignItems='center' style={{ backgroundColor: 'aliceblue', minHeight: '20px', marginBottom: '1%' }}>
          <Grid item xs={12} style={{ textAlign: 'center', fontFamily: 'Ebrima' }}>
            {property ? surveyBy || surveyDate ? `${surveyBy} ${surveyDate}` : 'No Survey Data' : null}
          </Grid>
        </Grid>

        <Grid container direction='row' style={{ backgroundColor: '#66BFFA' }}>
          <Grid item xs={12} style={{ display: 'inline-flex', textAlign: 'center' }}>
            <Grid container direction='row' style={{ backgroundColor: 'gainsboro' }}>
              <Grid style={{ textAlign: 'center' }} item xs={4} style={{ display: 'inline-flex', textAlign: 'center' }}>
                <Grid container direction='row' justify='center' alignItems='center'>
                   <Typography variant="h6" component="h6" style={{ color: 'darkGrey', fontSize: '99%', fontWeight: 'normal', padding: '2%',  fontFamily:'Ebrima',}}>{materialType == 1 ? 'RCS:' : (materialType ==6?'Criticality:':'PCI:')}</Typography>
                   <Typography variant="h6" component="h5" style={{ fontWeight: 'normal', padding: '2%', fontSize: '99%',  fontFamily:'Ebrima',}}>{weightedRcs == 0 ? rcs : weightedRcs}</Typography>
                </Grid>
              </Grid>
              <Grid style={{ textAlign: 'center' }} item xs={4} style={{ display: 'inline-flex', textAlign: 'center' }}>
                <Grid container direction='row' justify='center' alignItems='center'>
                  <Typography variant="h6" component="h6" style={{ color: 'darkGrey', fontSize: '99%', fontWeight: 'normal', padding: '2%', fontFamily:'Ebrima', }}>ERSL:</Typography>
                  <Typography variant="h6" component="h5" style={{ fontWeight: 'normal', padding: '2%', fontSize: '99%', fontFamily:'Ebrima'}}>{weightedErsl}</Typography>
                </Grid>
              </Grid>
              <Grid style={{ textAlign: 'center' }} item xs={4} style={{ display: 'inline-flex', textAlign: 'center' }}>
                <Grid container direction='row' justify='center' alignItems='center'>
                                  <Typography variant="h6" component="h6" style={{ color: 'darkGrey', fontSize: '99%', fontWeight: 'normal', padding: '2%', fontFamily: 'Ebrima', }}>{materialType == 6 ? 'Units:' : 'Area'}</Typography>
                                  <Typography variant="h6" component="h5" style={{ fontWeight: 'normal', padding: '2%', fontSize: '99%', fontFamily: 'Ebrima', }}>{new Intl.NumberFormat('en').format(qty)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';

export default function PropertyContactPhone(props) {
  const [phone, setPhone] = useState('');
  const {
    handleUpdatePropertyContactPhone,

    modifiedContact,
    contactIsRequested, 
  } = props;

  const handleUpdatePhone = (e) => {
    const number = e.target.value;
    setPhone(number);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if (modifiedContact) {
      const {
        Phone,
      } = modifiedContact;
      setPhone(Phone);
    }
  }, [modifiedContact]);

  const previousPhone = usePrevious(phone);
  useEffect(() => {
    if (phone != previousPhone) {
      handleUpdatePropertyContactPhone(phone);
    }
  }, [phone]);

  return (
    <Grid container direction='row'>
      <Grid xs={12} style={{ padding: '2%' }}>
        <Paper style={{ width: '100%', boxShadow: 'none' }}>
          <Grid container direction='row' alignItems='center' justify='center'>
            <Grid item xs={12}>
               <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                id="standard-full-width"
                label="Phone"
                placeholder="Contacts's Phone"
                autoFocus
                value={phone}
                disabled={contactIsRequested}
                onChange={handleUpdatePhone}
                margin="normal"
                style={{ width: '95%', left: '2.5%', right: '2.5%', marginTop: '2%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}


import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import UndoIcon from '@material-ui/icons/Undo';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '90%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function EditBluefinInvoiceNumber(props) {
  const [edited, setEdited] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [input, setInput] = useState(null);

  const {
    retrieveWorkOrderBluefinInvoice,
    bluefinInvoice,
    editInvoices,
  } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    setInput(value);
  };

  const handleUndoChange = () => {
    setInput(bluefinInvoice);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    setInput(bluefinInvoice);
  }, []);

  useEffect(() => {
    setInput(bluefinInvoice);
  }, [bluefinInvoice]);

  useEffect(() => {
    if (editInvoices) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [editInvoices]);

  const prevInput = usePrevious(input);
  useEffect(() => {
    if (input && prevInput) {
      if (input != prevInput) {
        retrieveWorkOrderBluefinInvoice(input);
      }

      if (input != bluefinInvoice) {
        setEdited(true);
      } else {
        setEdited(false);
      }
    }
  }, [input]);

  const classes = useStyles();

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Grid container direction='row' alignItems='center' justify='center'>
        <Grid item xs={10}>
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            onChange={handleChange}
            value={input}
            disabled={disabled}
            id="standard-basic"
            className={classes.textField}
            margin="normal"
            helperText='Bluefin Invoice'
          />
        </Grid>
        <Grid item xs={2}>
          {edited && !disabled ?
            <IconButton onClick={handleUndoChange} style={{ padding: '0' }}>
              <UndoIcon />
            </IconButton>
            : null}
        </Grid>
      </Grid>
    </form>
  );
}
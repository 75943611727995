import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '57px',
    backgroundColor: '#0672BA',
    fontFamily: 'Ebrima'
  },
  appBar: {
    height: '57px',
    backgroundColor: '#0672BA',
    fontFamily: 'Ebrima'
  },
  menuButton: {
    marginLeft: 220,
    marginRight: theme.spacing(2),
    fontFamily: 'Ebrima',
  },
  feedbackButton: {
    height: 35,
    width: 100,
    backgroundColor: '#8DC63F',
    fontFamily: 'Ebrima',
  },
  title: {
    flexGrow: 1,
    display: 'none',
    fontFamily: 'Ebrima',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    paddingLeft: 5,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 300,
      '&:focus': {
        width: 300,
      },
    },
  },
  listItem: {
    // margin: 10,
    // lineHeight: 0,
    cursor: 'pointer'
  },
}));

export default useStyles
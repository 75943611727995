import React, { useEffect, useState} from 'react';
import {
  MobileStepper,
  Button,
} from '@material-ui/core';
import {
  makeStyles,
  useTheme
} from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    margin: '0%',
  },
});

export default function PropertyGraphCarouselStepper(props) {
const { handleIncrementGraph, safeToClick } = props;
    const [activeStep, setActiveStep] = useState(0);
    const { materialType, setMaterialType } = props;

    
    console.log(materialType);
const classes = useStyles();
const theme = useTheme();

const handleNext = () => {
  setActiveStep(prevActiveStep => prevActiveStep + 1);
};

const handleBack = () => {
  setActiveStep(prevActiveStep => prevActiveStep - 1);
};

useEffect(() => {
  handleIncrementGraph(activeStep);
}, [activeStep])

return (
    <MobileStepper
      style={{backgroundColor: 'rgb(233,233,233)', borderRadius: '2%', padding: '4px' }}
      variant="dots"
      steps={materialType!=6?4:6}
      position="static"
      activeStep={activeStep}
      className={classes.root}
      nextButton={
        <Button size="small" onClick={handleNext} disabled={activeStep === (materialType != 6?3:5) || !safeToClick}>
          Next
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0 || !safeToClick}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          Back
        </Button>
      }
    />
  )
};
import React, { useEffect, useState, useRef } from 'react';
import PropertyConditionTableRow from './SubComponents/PropertyConditionTableRow';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    fontFamily:'Ebrima',
  },
  tableWrapper: {
    maxHeight: '100%',
    overflow: 'auto',
    fontFamily:'Ebrima',
  },
});

const forceSticky = { position: 'sticky', top: '0', zIndex: '10', backgroundColor: 'white', fontFamily:'Ebrima', };

export default function PropertyCondition(props) {
  const classes = useStyles();

  const { 
    handleDispatchGetRCS,
    sections, 
    rcsInfos,
  } = props;

  return (
    <Paper square className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={forceSticky}>Section</TableCell>
              <TableCell align="right" style={forceSticky}>Square Feet</TableCell>
              <TableCell align="right" style={forceSticky}>Score</TableCell>
              <TableCell align="right" style={forceSticky}>Condition</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ width: '100%' }}>
            { sections.map(section => {
              return (
                <PropertyConditionTableRow
                  handleDispatchGetRCS={handleDispatchGetRCS} 
                  section={section} 
                  rcsInfos={rcsInfos}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}



import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '90%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

export default function AddPropertyAutoSelect(props) {
  const classes = useStyles();
  const [ propertyId, setPropertyId ] = useState(null);

  const { 
    retrieveSelectedProperty, 
    propertyList 
  } = props;

  const handleChange = (e) => {
    const id = e.target.value;
    setPropertyId(id);
  };

  useEffect(() => {
    if (propertyId) {
      const selectedProperty = propertyList.find(property => property.Id == propertyId);
      retrieveSelectedProperty(selectedProperty);
    } else {
      retrieveSelectedProperty(null);
    }
  }, [propertyId]);

  return (
    <FormControl variant="filled" className={classes.formControl}>
      <InputLabel htmlFor="filled-property-native-simple" style={{fontFamily:'Ebrima',}}>Property</InputLabel>
      <Select
        native
        value={propertyId}
        onChange={handleChange}
        inputprops={{
          propertyId: 'propertyId',
          id: 'filled-property-native-simple',
        }}
        style={{fontFamily:'Ebrima',}}
      >
        <option value="" style={{fontFamily:'Ebrima',}}/>
          { propertyList.map((property) => {
            const name = property.Name;
            const value = property.Id;
            return (
              <option value={ value } style={{fontFamily:'Ebrima',}}>{ name }</option>
            )
          }) }
      </Select>
    </FormControl>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import SvgRepair from '../SvgIcons/SvgWORepair';
import SvgMaint from '../SvgIcons/SvgWOMaint';
import SvgLeak from '../SvgIcons/SvgWOLeak';

const useStyles = makeStyles(theme => ({
  card: {
    height: '15rem',
    width: '20rem',
    overflow: 'hidden',
    textAlign: 'left',
    cursor: 'pointer',
  },
  title: {
    fontSize: 14,
  },
  buttons: {
    textAlign: 'left',
    marginRight: '10%',
  }, 
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function DocumentCard(props) {
  const classes = useStyles();
  const {
    i,
    workOrder,
    handleEditModalOpen,
    handleDeleteModalOpen,
  } = props;

  const {
    AssignedToName,
    Building,
    UrgencyType,
    ContractorName,
    SubmitName,
  } = workOrder;

  const {
    Address1,
    Address2,
    City,
  } = Building;
  
  const address = Address2 ? `${Address1} ${Address2}, ${City}` : `${Address1}, ${City}`;
  const workOrderNumber = workOrder.WONumber;
  const workOrderType = workOrder.WOType.Wotype1;
  const currentStatus = workOrder.CurrentStatus!=null?workOrder.CurrentStatus.Name:"Unknown";

  return (
    
    <Card 
      key={i}
      className={classes.card}
      onClick={() => handleEditModalOpen(workOrder)}
    >
      <CardContent>
        <CardHeader disableTypography={false}
          style={{padding: '0', maxHeight: '3rem', overflow: 'hidden', whiteSpace: 'nowrap'}}
          avatar={workOrderType==='Repair'?<SvgRepair />:workOrderType==='Maintenance'?<SvgMaint />:workOrderType==='Emergency Response'?<SvgLeak />:<SvgRepair />
          }
          title={"#" + workOrderNumber + " " + Building.Name}
          subheader={ address }
        />
        <Grid container direction='row' alignItems='center' justify='center' style={{marginTop: '3%', backgroundColor: 'lightGrey'}}>
          <Grid item xs={12}>
            <Typography variant="body2" style={{textAlign: 'center'}}>{currentStatus}</Typography>
          </Grid>
        </Grid>

        <List style={{ height: '8rem', overflow: 'auto', padding: '0', margin: '5%' }}>
          <ListItem disableGutters style={{ padding: '0', textAlign: 'center'}}>
            <ListItemText secondary={`Contractor: ${ContractorName}`} />
          </ListItem>
          <ListItem disableGutters style={{padding: '0', textAlign: 'center'}}>
            <ListItemText secondary={`Submitter: ${SubmitName}`} />
          </ListItem>
          <ListItem disableGutters style={{padding: '0', textAlign: 'center'}}>
            <ListItemText secondary={`Approver: ${AssignedToName}`} />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    margin: theme.spacing(1),
  },
  textField: {
    width: '95%',
  }
}));

export default function EditWorkOrderComments(props) {
  const [ disabled, setDisabled ] = useState(true);
  const [ comment, setComment ] = useState('');
  const [ dispatch, setDispatch ] = useState(false);
  const {
    dispatchAddComment,
  } = props;

  const handleUpdateComment = (e) => {
    const value = e.target.value;
    setComment(value);
  };

  const handleDispatchComment = () => {
    setDispatch(true);
  };

  useEffect(() => {
    if (dispatch) {
      dispatchAddComment(comment);
      setComment('');
      setDispatch(false);
    }
  }, [dispatch]);

  useEffect(() => {
    const detectText = comment.trim().length;
    if (detectText) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [comment]);

  const classes = useStyles();
  
  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ marginLeft: '1%', height: '25%'}}>
      <Grid item xs={10}>
        <div className={classes.container}>
           <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
            value={comment}
            className={classes.textField}
            onChange={handleUpdateComment}
            id="standard-basic"
            className={classes.textField}
            margin="normal"
            helperText='Add Comment'
            multiline
            rows="1"
          />
        </div>
      </Grid>
      <Grid xs={2}>
        <IconButton disabled={ disabled } onClick={() => handleDispatchComment()}>
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}


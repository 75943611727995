import React, { useEffect, useState } from 'react';
import PropertySectionsMaterialsImages from './PropertySectionMaterialsImages';
import PropertySectionMaterialCard from './SubComponents/PropertySectionMaterialCard';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LayersIcon from '@material-ui/icons/Layers';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PropertySectionsMaterials(props) {
  const [expanded, setExpanded] = useState(false);
  const { surfaces, surfaceImages } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();
  return (
      <Grid container direction={'row'} alignItems={'flex-end'} justify={'flex-start'} style={{marginBottom: '2%'}}>
        <Grid direction={'row'} style={{ display: 'flex', marginLeft: '2%' }}>
          <Grid item xs={2} style={{display: 'flex', marginTop: '1%'}}>
            <LayersIcon fontSize={'default'} />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h6" style={{fontSize: '18px'}}>Material Layers</Typography>
          </Grid>
          <Grid item xs={1} style={{display: 'flex'}}>
            <IconButton
              size={'small'}
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expanded}>
            <Grid item xs={12}>
              <PropertySectionsMaterialsImages 
                surfaceImages={ surfaceImages }
              />
            </Grid>
            <Grid item xs={12}>
              <List>
                { surfaces.map((surface, i) => {
                  const invTitle = surface.invTitle;
                  return (
                    <PropertySectionMaterialCard
                      invTitle={invTitle}
                      surface={surface}
                    />
                  )
                }) }
              </List>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
  );
}

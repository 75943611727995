import React, { PureComponent } from 'react';
import PropertyResultsCard from './Components/PropertiesResultsCard';
import PropertyNoResultsCard from './Components/PropertiesNoResultsCard';
import PropertiesLoading from '../PropertiesLoading/PropertiesLoading';
import PropertiesPagination from '../PropertiesPagination/PropertiesPagination';
import PropertiesSearch from '../PropertiesSearch/PropertiesSearch';
import PropertiesMapHook from '../../GoogleMaps/PropertiesMapHook/PropertiesMapHook';
import {
  Grid,
  Paper,
} from '@material-ui/core';

const leftColumnStyle = { 
  position: 'absolute',
  width: '100%',
  height: '100%', 
};

const listPaperStyle = { 
  height: '100%', 
  overflow: 'auto', 
  width: '100%', 
  boxShadow: 'none',
};

const paginationStyle = {
  position: 'sticky',
  bottom: '0',
  width: '100%',
  boxShadow: 'none',
};

const noShowMapView = { 
  display: 'none',
};

const mapContainerElementStyle = { 
  position: 'absolute', 
  height: '100%', 
  width: '66.5%' 
};

const mapContainerElementMobile = {
  position: 'absolute', 
  height: '66%', 
  width: '30%', 
};

const mapElementStyle = { 
  height: `100%` 
};

class PropertiesResults extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      bounds: null,
      mapCenter: null,
      searchResults: [],
      originalResults: [],
      clickedCard: null,
      clickedMarker: null,
      reloading: null,
      selectedPosition: null,
      SelectedPageSize: 10,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { 
      results, 
      selectResult, 
      SelectedPageSize, 
      selectedResult,
      property,
    } = this.props;
    const previousResults = prevProps.results;
    const previousSelectedPageSize = prevProps.SelectedPageSize;

    if (results) {
      if (results != previousResults) {
        this.setState({
          originalResults: previousResults,
          searchResults: results,
        })
      }
    }

      console.log(results);

    const { clickedCard, clickedMarker, searchResults, reloading, selectedPosition } = this.state;
    const previousCard =  prevState.clickedCard;
    const previousMarker = prevState.clickedMarker;
    const previousSearchResults = prevState.searchResults;

    if (clickedCard) {
      if (clickedCard != previousCard) {
        const selectedProperty = searchResults.find(result => result.Id == clickedCard);
        selectResult(selectedProperty);
      }
    }
   
    if (clickedMarker) {
      if (clickedMarker != previousMarker) {
        const selectedProperty = searchResults.find(result => result.Id == clickedMarker);
        selectResult(selectedProperty);
      }
    }

    if (searchResults) {
      if (SelectedPageSize != previousSelectedPageSize) {
        this.setState({
          reloading: true,
        })
        if (selectedPosition) {
          this.setState({
            selectedPosition: false,
            clickedMarker: false,
          })
        }
      }
      if (searchResults != previousSearchResults) {
        if (SelectedPageSize == previousSelectedPageSize) {
          if (reloading) {
            this.setState({
              selectedPosition: false,
              reloading: false,
            })
          }
        }
      }
    }
  }

  setReloadingFalse = () => {
    this.setState({
      reloading: false,
    })
  }

  onMarkerClick = (marker) => {
    const { clickedMarker, clickedCard, searchResults } = this.state;
    const clickedMarkerId = parseFloat(marker.Id);
    const markerMetaData = searchResults.find(result => result.Id == clickedMarkerId);

    //if no selected card
    if (!clickedCard) {
      //if the newly clicked marker is not equal to the value saved from the last clicked marker
      if (clickedMarkerId != clickedMarker) {
        //set it to the prop to the newly clicked marker
        this.setState({
          clickedMarker: clickedMarkerId,
        }, this.setActiveMarker(marker))
      }
      //if the newly clicked marker equals the value saved from the last clicked marker
      if (clickedMarkerId == clickedMarker) {
        //turn the prop value off
        this.setState({
          clickedMarker: false,
        })
      }
    }

    //if there is a clicked card
    if (clickedCard) {
      //if the clicked card is not equal to the incoming clicked marker
      if (clickedCard != clickedMarkerId) {
        this.setState({
          clickedMarker: clickedMarkerId,
          clickedCard: false,
        }, this.setActiveMarker(marker))
      }

      //and the clicked card is equal to the incoming clicked marker
      if (clickedCard == clickedMarkerId) {
        this.setState({
          clickedMarker: false,
          clickedCard: false,
        })
      }
    } 

    const selectedPosition = {
      lat: parseFloat(markerMetaData.Lat),
      lng: parseFloat(markerMetaData.Lng),
    };

    this.setState({
      selectedPosition,
    })
  };

  onCardClick = (selectedCard) => {
    const { clickedCard } = this.state;
    const clickedId = parseFloat(selectedCard.Id);

    if (clickedCard != clickedId) {
      this.setState({
        clickedCard: clickedId,
        clickedMarker: false,
      })
    }

    if (clickedCard == clickedId) {
      this.setState({
        clickedCard: false,
      })
    }
  };

  setActiveMarker = (marker) => {
    const { mapViewSelected } = this.props;

    if (!mapViewSelected) {
      document.getElementById(marker.Id).scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  onMapClick = () => {
    this.setState({
      clickedCard: false,
      clickedMarker: false,
    })
  };

  render() {
    const {
      handleOpenModal,
      handleOpenDetailModal,
      loading,
      pages,
      handleSearch,
      handleMapToggle,
      dispatchPaginationSelect,
      handleSortTypeDispatch,
      handleSortDirectionDispatch,
      handleDispatchMax,
      handleDispatchMin,
      mobile,
      mapViewSelected,
      recordCount,
      selectPageSize,
      results,
      userRank,
      materialType,
      SelectedPageSize,
    } = this.props;

    const { 
      searchResults, 
      clickedCard, 
      clickedMarker,
      selectedPosition,
      originalResults,
    } = this.state;

    return (
      <Paper id='properties-results-container'>
        <Grid container direction='row' style={{ height: '100%' }}> 
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} style={ leftColumnStyle }>
            
            <Grid container direction='row' style={{ height: '100%', backgroundColor: 'gainsboro' }}>
              <Grid item xs={12} style={{ height: '100%', position: 'absolute', overflow: 'auto' }}>
                <Grid container direction='row'> 
                  <Grid id='properties-search-item' item xs={12} style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                    <PropertiesSearch
                      handleSearch={handleSearch}
                      handleMapToggle={handleMapToggle}
                      handleSortTypeDispatch={handleSortTypeDispatch}
                      handleSortDirectionDispatch={handleSortDirectionDispatch}
                      handleDispatchMax={handleDispatchMax}
                      handleDispatchMin={handleDispatchMin}
                      mobile={mobile}
                      mapViewSelected={mapViewSelected}
                    />
                  </Grid>
                  <Grid id ='properties-list-item' item xs={12}>
                    <Paper square={true} style={listPaperStyle}>
                      {loading ? <PropertiesLoading results={results} /> :
                        !mapViewSelected ? searchResults.length ?
                          searchResults.map(searchResult =>
                            <PropertyResultsCard
                              onCardClick={this.onCardClick}
                              clickedCard={clickedCard}
                              clickedMarker={clickedMarker}
                              key={searchResult.Id}
                              searchResult={searchResult}
                              handleOpenModal={handleOpenModal}
                              handleOpenDetailModal={handleOpenDetailModal}
                              materialType={materialType}
                            />
                          )
                          : <PropertyNoResultsCard /> :
                          <Paper square={true} id={'map'}>
                            <PropertiesMapHook
                              onMapClick={this.onMapClick}
                              onMarkerClick={this.onMarkerClick}
                              setReloadingFalse={this.setReloadingFalse}
                              handleOpenDetailModal={handleOpenDetailModal}
                              searchResults={searchResults}
                              originalResults={originalResults}
                              clickedCard={clickedCard}
                              clickedMarker={clickedMarker}
                              selectedPosition={selectedPosition}
                              mobile={mobile}
                              isMarkerShown={true}
                              containerElement={<div style={mobile ? mapContainerElementMobile : mapContainerElementStyle} />}
                              mapElement={<div style={mapElementStyle} />}
                            />
                          </Paper>
                      }
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ position: 'sticky', bottom: '0' }}>
                  <Paper square={true} style={paginationStyle}>
                    <PropertiesPagination
                      dispatchPaginationSelect={dispatchPaginationSelect}
                      pages={pages}
                      recordCount={recordCount}
                      selectPageSize={selectPageSize}
                      SelectedPageSize={SelectedPageSize}
                    />
                  </Paper>
                </Grid>
              </Grid>

            </Grid>

          </Grid>

          <Grid item xs={0} sm={0} md={0} lg={8} xl={8} style={ mobile ? mapViewSelected ? null : noShowMapView : null }>
           
            { !mapViewSelected ? 
              <Paper square={true} id={'map'}>
                <PropertiesMapHook 
                  onMapClick={this.onMapClick}
                  onMarkerClick={this.onMarkerClick}
                  setReloadingFalse={this.setReloadingFalse}
                  handleOpenDetailModal={handleOpenDetailModal}
                  searchResults={searchResults}
                  originalResults={originalResults}
                  clickedCard={clickedCard}
                  clickedMarker={clickedMarker}
                  selectedPosition={selectedPosition}
                  mobile={mobile}
                  isMarkerShown={true}
                  containerElement={<div style={mobile ? mapContainerElementMobile : mapContainerElementStyle} />}
                  mapElement={<div style={mapElementStyle} />}
                />
              </Paper> 
             :
              null 
            }
          </Grid>
        </Grid>
      </Paper>
    )
  }
};

export default PropertiesResults
import React, { useEffect, useState } from 'react';
import PropertyDefectGroupCard from './PropertyDefectGroupCard';
import PropertyDefectsSectionDropdown from './SubComponents/PropertyDefectsSectionDropdown';
import PropertyNoDefectsCard from './SubComponents/PropertyNoDefectsCard';
import { 
  List, 
  Grid,
  Typography,
  Paper,
  IconButton,
  Collapse,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(-90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
}));

export default function PropertyDefectsList(props) {
   
  const [ defectsSelected, setDefectsSelected ] = useState(null);  
  const [ defectGroupsList, setDefectGroupsList ] = useState([]);
  const [ formattedTotal, setFormattedTotal ] = useState(0);
  const { 
    handleDefectsCategorySelected,
    selectedProperty, 
    retrievedSelectedDefectsGroups, 
    retrieveSelectedDefectSection,
    retrieveFilterDefectsByProposed,
    highlightSelectedDefect, 
    selectedDefects,
    defectGroups,
    selectedDefectSection,
    defectsTotal,
    filteredByProposed,
    displayDefects,
  } = props;

  const { 
    DefImages, 
    DefectCM, 
  } = selectedProperty;

    new Intl.NumberFormat('en').format

  const formatter = new Intl.NumberFormat('en', {
  });

  const handleDefectSelected = () => {
    setDefectsSelected(!defectsSelected);
  };

  useEffect(() => {
      setDefectGroupsList(defectGroups);
      
    handleDefectsCategorySelected(defectsSelected);
  }, [defectsSelected]);

  useEffect(() => {
      setDefectGroupsList(defectGroups);

  }, [defectGroups]);

  useEffect(() => {
    // console.log('a')
    if (defectsTotal) {
      // console.log('b')
      if (defectsTotal != 'N/A') {
        
        const formatTotal = formatter.format(defectsTotal);
          setFormattedTotal(formatTotal);
      }
    }
  }, [defectsTotal]);

  useEffect(() => {
    // console.log(filteredByProposed)
  }, [filteredByProposed]);

  // console.log(defectsTotal)
   
  return (
    <Grid id='defects-list-container' container direction='row' justify='center' alignItems='flex-start'>
      <Grid item xs={12} style={{ height: '100%' }}>
        <PropertyDefectsSectionDropdown
          selectedProperty={selectedProperty}
          retrieveSelectedDefectSection={retrieveSelectedDefectSection}
          retrieveFilterDefectsByProposed={retrieveFilterDefectsByProposed}
          filteredByProposed={filteredByProposed}
          displayDefects={displayDefects}
        />
        <Grid container direction='row' style={{ padding: '1%' }}>
          <Grid item xs={12} style={{ backgroundColor: 'gainsboro' }}>
            <Grid container direction='row' alignItems='flex-end' justify='center' style={{ padding: '1%' }}>
              <Grid item xs={3} style={{ color: 'white' }}>
                <Typography variant="body2" style={{fontFamily: 'Ebrima',}}>Total: </Typography> 
              </Grid>
                          <Grid item xs={9} style={{ textAlign: 'right', color: 'white' }}>
                              {
                                  defectGroupsList.length > 0 ?
                                      <Typography variant="body2" style={{fontFamily: 'Ebrima',}}>${new Intl.NumberFormat('en').format(defectsTotal)}</Typography>
                                      :
                                      <Typography variant="body2" style={{fontFamily: 'Ebrima',}}>0</Typography>

                              }
                                    
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        { displayDefects ?  
          <List style={{ width: '100%', padding: '1%' }}>
            { defectGroupsList.length > 0 ?
              defectGroupsList.map((defectGroup, i) => {
                const groupCost = defectGroup.GroupCost;
                return (
                  <PropertyDefectGroupCard
                        i={i}
                        key={'defect' + i}
                    defectGroup={defectGroup}
                    groupCost={groupCost}
                    defectImages={DefImages}
                    retrievedSelectedDefectsGroups={retrievedSelectedDefectsGroups}
                    highlightSelectedDefect={highlightSelectedDefect}
                    selectedDefects={selectedDefects}
                    selectedDefectSection={selectedDefectSection}
                  />
                )
              })
              : <PropertyNoDefectsCard />
            }        
          </List>
        : null }
      </Grid>
    </Grid>
  )
}
import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import TaskMoveBuildings from '../Tasks/TaskMoveBuildings/TaskMoveBuildings';
import {
  Grid,
} from '@material-ui/core';
import auth from '../../../auth';

class MoveProperty extends Component {
  constructor(props) {
    super(props);
    this.vm = dotnetify.react.connect('MoveProperty', this, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        console.log(ex);
          auth.signOut();
      }
    });
    this.dispatch = state => this.vm.$dispatch(state);
    this.routeTo = route => this.vm.$routeTo(route);

    this.state = {
      UserCompanies: [],
      UserBuildings: [],
      MoveToCompanies: [],
      MoveToCompanyTags: [],

      SelectedCompany: {},
      SelectedBuildings: [],
      ToSelectedCompany: {},
      ToSelectedBuildings: [],

      MovePropertyResponse: {},

      selectedFromCompany: {},
      selectedFromBuildings: [],
      selectedToCompany: {},
      selectedTags: [],
      formComplete: false,
    }
  }

  componentDidMount = () => {
    this.dispatch({ GetUserCompanies: 1 });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      SelectedCompany,
      SelectedBuildings,
      ToSelectedCompany,
      SelectedTags,

      formComplete,
    } = this.state;
    const previousSelectedCompany = prevState.SelectedCompany;
    const previousToSelectedCompany = prevState.ToSelectedCompany;
    const previousSelectedBuildings = prevState.SelectedBuildings;
    const previousSelectedTags = prevState.SelectedTags;

    if (SelectedCompany != previousSelectedCompany ||
      ToSelectedCompany != previousToSelectedCompany ||
      SelectedBuildings != previousSelectedBuildings ||
      SelectedTags != previousSelectedTags
    ) {
      if (SelectedCompany && ToSelectedCompany) {
        const selectedCompanyLength = Object.values(SelectedCompany).length;
        const toSelectedCompanyLength = Object.values(ToSelectedCompany).length;
        if (selectedCompanyLength && toSelectedCompanyLength) {
          const selectedCompanyId = SelectedCompany.CompanyId;
          const toSelectedCompanyId = ToSelectedCompany.CompanyId;
          if (selectedCompanyId && toSelectedCompanyId) {
            if (SelectedBuildings) {
              if (SelectedBuildings.length) {
                if (SelectedTags) {
                  if (SelectedTags.length) {
                    if (!formComplete) {
                      this.setState({
                        formComplete: true,
                      })
                    }
                  } else {
                    if (formComplete) {
                      this.setState({
                        formComplete: false,
                      })
                    }
                  }
                }
              } else {
                if (formComplete) {
                  this.setState({
                    formComplete: false,
                  })
                }
              }
            }
          } else {
            if (formComplete) {
              this.setState({
                formComplete: false,
              })
            }
          }

        } else {
          if (formComplete) {
            this.setState({
              formComplete: false,
            })
          }
        }
      }
    }
  };

  ////////////////////FROM/////////////////////////////

  handleFromCompanySelect = (selectedCompany) => {
    const { selectedFromCompany } = this.state;

    if (selectedCompany) {
      const selectedCompanyExists = Object.values(selectedCompany).length;
      this.setState({
        selectedFromCompany: selectedCompany,
      });

      if (selectedCompanyExists) {
        const selectedCompanyId = selectedCompany.CompanyId;
        const selectedFromCompanyId = selectedFromCompany.CompanyId;
        if (selectedCompanyId != selectedFromCompanyId) {
          console.log('dispatching SelectedUserCompany')
          console.log(selectedCompany);
          this.dispatch({ SelectUserCompany: selectedCompany });
        }
      } else {
        const selectedFromCompanyExists = Object.values(selectedFromCompany).length;
        if (selectedFromCompanyExists) {
          console.log('dispatching ResetSelectedBuildings 0')
          console.log('');
          this.dispatch({ ResetSelectedCompany: '' });
        }
      }
    }
  };

  handleFromBuildingSelect = (buildings) => {
    const { selectedFromCompany, selectedFromBuildings } = this.state;

    if (buildings.length) {
      if (buildings.length != selectedFromBuildings.length) {
        this.setState({
          selectedFromBuildings: buildings,
        })

        if (buildings.length > selectedFromBuildings.length) {
          const buildingsCopy = [...buildings];
          const selectedFromBuilding = buildingsCopy.find(building => !selectedFromBuildings.includes(building));
          console.log('dispatching AddSelectedBuilding')
          console.log(selectedFromBuilding);
          this.dispatch({ AddSelectedBuilding: selectedFromBuilding });
        } else {
          const selectedFromBuildingsCopy = [...selectedFromBuildings];
          const selectedFromBuilding = selectedFromBuildingsCopy.find(selectedFromBuilding => !buildings.includes(selectedFromBuilding));
          console.log('dispatching RemoveSelectedBuilding')
          console.log(selectedFromBuilding);
          this.dispatch({ RemoveSelectedBuilding: selectedFromBuilding });
        }
      }
    } else {
      //here
      if (buildings.length != selectedFromBuildings.length) {
        console.log('dispatching ResetSelectedBuildings 1');
        console.log('');
        this.setState({
          selectedFromBuildings: [],
        }, this.dispatch({ ResetSelectedBuildings: '' }));
      }
    }
  };

  ////////////////////TO/////////////////////////////

  handleToCompanySelect = (company) => {
    const { selectedToCompany } = this.state;
    const selectedToCompanyId = selectedToCompany.CompanyId;
    const companyId = company.CompanyId;

    if (companyId != selectedToCompanyId) {
      this.setState({
        selectedToCompany: company,
      });

      if (companyId) {
        this.dispatch({ GetMoveToCompanyTags: company });
      } else {
        this.dispatch({ ResetMoveToCompanyTags: '' });
      }
    }
  };

  handleToCompanyTagSelect = (tags) => {
    const { selectedTags } = this.state;

    if (tags.length) {
      if (tags.length != selectedTags.length) {
        this.setState({
          selectedTags: tags,
        });

        if (tags.length > selectedTags.length) {
          const tagsCopy = [...tags];
          const selectedTag = tagsCopy.find(tag => !selectedTags.includes(tag));
          console.log('dispatching AddSelectedTag')
          console.log(selectedTag)
          this.dispatch({ AddSelectedTag: selectedTag });
        } else {
          const selectedTagsCopy = [...selectedTags];
          const selectedTag = selectedTagsCopy.find(tag => !tags.includes(tag));
          console.log('dispatching RemoveSelectedTag')
          console.log(selectedTag)
          this.dispatch({ RemoveSelectedTag: selectedTag });
        }
      }
    } else {
      if (tags.length != selectedTags.length) {
        this.setState({
          selectedTags: [],
        }, this.dispatch({ ResetSelectedTags: '' }), console.log('dispatching ResetSelectedTags'), console.log(''));
      }
    }
  };

  handleSaveMoveBuildingsSelect = () => {
    this.setState({
      saveSelected: true,
    })
  };

  handleSaveMoveBuildingsCancel = () => {
    this.setState({
      saveSelected: false,
    })
  };

  handleSaveMoveBuildingsDispatch = () => {
    this.setState({
      saveSelected: false,
      selectedFromCompany: {},
      selectedFromBuildings: [],
      selectedToCompany: {},
      selectedTags: [],
    }, 
    this.dispatch({ SaveMoveBuildings: '' }));
  };

  componentWillUnmount = () => {
    console.log('unmounting MoveBuildings');
    this.vm.$destroy();
  };

  render() {
    const {
      UserCompanies,
      UserBuildings,
      MoveToCompanies,
      MoveToCompanyTags,

      ToSelectedCompany,
      SelectedCompany,
      SelectedBuildings,
      SelectedTags,

      MovePropertyResponse,

      selectedFromBuildings,
      formComplete,
      saveSelected,
    } = this.state;

    console.log(this.state)

    return (   
      <Grid container direction='row' alignItems='center' justify='center'>
        <Grid item xs={12}>
          <TaskMoveBuildings
            handleFromCompanySelect={this.handleFromCompanySelect}
            handleFromBuildingSelect={this.handleFromBuildingSelect}
            handleToCompanySelect={this.handleToCompanySelect}
            handleToCompanyTagSelect={this.handleToCompanyTagSelect}
            handleSaveMoveBuildingsSelect={this.handleSaveMoveBuildingsSelect}
            handleSaveMoveBuildingsCancel={this.handleSaveMoveBuildingsCancel}
            handleSaveMoveBuildingsDispatch={this.handleSaveMoveBuildingsDispatch}

            userCompanies={UserCompanies}
            userBuildings={UserBuildings}
            moveToCompanies={MoveToCompanies}
            moveToCompanyTags={MoveToCompanyTags}

            toSelectedCompany={ToSelectedCompany}
            selectedCompany={SelectedCompany}
            selectedBuildings={SelectedBuildings}
            selectedTags={SelectedTags}

            movePropertyResponse={MovePropertyResponse}

            selectedFromBuildings={selectedFromBuildings}
            formComplete={formComplete}
            saveSelected={saveSelected}
          />
        </Grid>
      </Grid>
    )
  }
};

export default MoveProperty

import React, { Component } from 'react';
import PropertyLoading from '../SubComponents/PropertyLoading';
import PropertyGraphsNoDataCard from './PropertyGraphsNoDataCard';
import {
    CartesianGrid,
    ComposedChart,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ResponsiveContainer,
    BarChart,
    LabelList
} from 'recharts';
import {
    Typography,
} from '@material-ui/core';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const graphContainerStyle = {
    textAlign: 'center',
    // overflow: 'overlay',
    minWidth: '21rem',
    minHeight: '14.78rem',
    fontFamily: 'Ebrima',
};

const barChartMarginStyle = {
    top: 10,
    right: 50,
    left: 20,
    bottom: 10,
    fontFamily: 'Ebrima',
};

const XAxisTickFormatter = (value) => {
    return `${new Intl.NumberFormat('en').format(value)}`;
};

const CustomTooltipContent = (props) => {
    const payload = props.payload;

    if (props.active) {
        const newPayload = payload;
        if (payload) {
            payload.map((entry, i) => {
                const dataKey = entry.dataKey;
                const id = payload.indexOf(entry);
                if (dataKey == 'uv') {
                    const newEntry = {
                        color: '#707070',
                        dataKey: entry.dataKey,
                        fill: '#707070',
                        formatter: entry.formatter = (value) => XAxisTickFormatter(value),
                        name: entry.name,
                        payload: entry.payload,
                        type: entry.type,
                        unit: entry.unit,
                        value: entry.value,
                    }
                    newPayload.splice(id, 1, newEntry);
                } else {
                    entry.formatter = (value) => XAxisTickFormatter(value);
                }
            })
        }
        return <DefaultTooltipContent {...props} payload={newPayload} />
    } else {
        return <DefaultTooltipContent {...props} payload={payload} />
    }
};


class HVACMfgDist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: null,
            HVACMfgCount: [],
        }
    }

    componentDidMount = () => {
        const { HVACMfgCount } = this.props;

        if (HVACMfgCount.length == 0) {
            this.setState({
                noChartData: true,
            })
        }
    };

    render() {
        const {
            openDefectsWindow,
            HVACMfgCount
        } = this.props;

        const {
            loading,
            noChartData
        } = this.state;

        return (
            <div style={{ display: 'grid', overflow: 'hidden' }}>
                <div id='graph-container' style={graphContainerStyle} >
                    <Typography color='subtitle2' style={{ fontFamily: 'Ebrima', }}>Manufacturer Distribution</Typography>
                    <ResponsiveContainer width={'100%'} aspect={12.0 / 6.0}>
                        {noChartData ? <PropertyGraphsNoDataCard /> :

                            <BarChart
                                width={730}
                                height={250}
                                data={HVACMfgCount}
                                layout='vertical'
                                style={{ fontFamily: 'Ebrima', }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type='number' tickFormatter={XAxisTickFormatter} />
                                <YAxis type='category' dataKey="ManufacturerName" angle={-45} textAnchor="end" />
                                <Tooltip content={<CustomTooltipContent style={{ fontFamily: 'Ebrima', }} props={HVACMfgCount} />} style={{ fontFamily: 'Ebrima', }} />
                                <Legend style={{ fontFamily: 'Ebrima', }} />
                                <Bar barSize={20} dataKey="Count" name="# of units" fill="#00C2F3" style={{ fontFamily: 'Ebrima', }} >
                                    <LabelList dataKey="Count" position="right" />
                                </Bar>
                            </BarChart>
                        }
                    </ResponsiveContainer>
                </div>
            </div>
        );
    }
}

export default HVACMfgDist
import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ThemeDefault from '../styles/theme-default';
import auth from '../auth';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green, green400, green600, grey50, pink500, grey200, grey300, grey500, grey400, pink400 } from 'material-ui/styles/colors';

import { withStyles } from '@material-ui/core/styles';

const GreenCheckbox = withStyles({
  root: {
    color: green400,
    '&$checked': {
      color: green600,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      minlength: false,
      lower: false,
      upper: false,
      special: false,
      number: false,
      confirmed: false,
      updatepassdirty: false
    };
  }

  render() {
    let { password, confirmpass, minlength, lower, upper, number, special, confirmed, error, updatepassdirty } = this.state;
      const { tmppass, user, onAuthenticated } = this.props;

    const styles = {
      loginContainer: {
        minWidth: 660,
        maxWidth:660,
        height: 'auto',
        position: 'absolute',
        top: '20%',
        left: 0,
        right: 0,
        margin: 'auto'
      },
      paper: {
        padding: 20,
        overflow: 'auto'
      },
      loginBtn: {
        float: 'right'
      },
      logo: {
        width: 270,
          height: 119,
          marginLeft: 35,
          marginRight: 35,
        backgroundImage: 'url(' + require('../images/bf_bg.png') + ')',
       
        display: 'inline-block',
        horizontalAlign: 'center'
      },
      text: {
          marginTop: 16,
        color: '#333',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        verticalAlign: 'text-bottom',
        textAlign: 'center',
        fontSize: '18px'
      },
      error: { color: 'red' }
    };

    const handleChangePassword = _ => {
      console.log(password);

      this.setState({ error: null });
        auth.changePassword(user, tmppass, password).then(_ => onAuthenticated()).catch(error => { 
          console.log(error);
          if(error.message == '400') {
            this.setState({ error: "Invalid password!" });
          }
        else this.setState({ error: error.message });
      });
    };

    const updatePasswordChange = val => {
      password = val.password;
      this.setState({password: val.password});
      validatePasswords();
    }

    const updateConfirmPasswordChange = val => {
      confirmpass = val.confirmpass;
      
      this.setState({confirmpass: val.confirmpass});

      validatePasswords();
    }

    const validatePasswords = _ =>{
      var errors = '';

      if(password==null || password.length === 0){

        minlength = false;
        this.setState({minlength: false});
        

        this.setState({upper: false});
        this.setState({lower: false});
        this.setState({number: false});
        this.setState({special: false})
        this.setState({error: ''});
        this.setState({confirmed: fasle});

      }else if (password ==null || password.length<8 ) {
          //Check capital characters
          errors = 'The password must contain 8 or more characters.';
          this.setState({minlength: false})
          minlength = false;
      } else {
         this.setState({minlength: true})
         minlength = true;
      }

      if (!(/([A-Z]+)/g.test(password))){
        errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        upper = false;
        this.setState({upper: false})
      } else {
        this.setState({upper: true})
      }

      if (!(/([a-z]+)/g.test(password))){
        errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        lower = false;
        this.setState({lower: false});
      } else {
        lower = true;
        this.setState({lower: true});
      }

      if (!(/([0-9]+)/g.test(password))){
        errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
        number = false;
        this.setState({number: false});
      } else {
        number = true;
        this.setState({number: true});
      }

      if (!(/[!@#$%^&*(),.?":{}|<>]/.test(password))){
          errors = 'The password must contain at least 1 of the following:  special character, uppercase letter, lowercase letter and number.';
          special = false;
          this.setState({special: false})
      }else {
          special = true;
          this.setState({special: true})
      }

      if (confirmpass != null && password != null){
          if (confirmpass == password) {
              this.setState({confirmed: true});
              confirmed = true;
          } else {
              this.setState({confirmed: false})
              errors = 'Confirm password.';
              confirmed = false;
          }
      } else {
          this.setState({confirmed: false});
          errors = 'Confirm password.';
      }

      error = errors;
      this.setState({error:errors});
      console.log("confirmpass:" + confirmpass + " password:" + password)
      console.log(confirmpass === password)
      console.log("confirmed:" + confirmed + " special:" + special + " number:" + number + " upper:" +  upper + " lower:" + lower + " MinLength:" +  minlength)  
      if(confirmed && special && number && upper && lower && minlength) {
          this.setState({updatepassdirty: true});
      } else {
          this.setState({updatepassdirty: false});
      }     
    }

    return (
      <MuiThemeProvider muiTheme={ThemeDefault}>
        <div>
          <div style={styles.loginContainer}>
            <Paper style={styles.paper}>
              <div>
                <div style={styles.logo} />
                <div style={styles.text}>Create New Password</div>
              </div>
              <form>
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  hintText="New Password"
                  floatingLabelText="New Password"
                  fullWidth={true}
                  type="password"
                  value={password}
                  onChange={event =>updatePasswordChange({ password: event.target.value})}
                                />
                 <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                  hintText="Confirm Password"
                  floatingLabelText="Confirm Password"
                  fullWidth={true}
                  type="password"
                  value={confirmpass}
                  onChange={event => updateConfirmPasswordChange({ confirmpass: event.target.value})}
                />
               
                {error ? <div style={styles.error}>{error}</div> : ''}
                <div>
                <div style={styles.passwordprompts}>                    
                    <div>Password Requirements: </div>
                    <span>
                        <FormControlLabel label="Lower Case"
                            control={ 
                                <GreenCheckbox checked={lower} value={lower}/>
                            } />  
                      </span>
                      <span>     

                        <FormControlLabel label="Upper Case"
                              control={
                              <GreenCheckbox checked={upper} value={upper}/>
                              } />
                      </span>
                      <span>
                        <FormControlLabel label="Number"
                          control={
                          <GreenCheckbox checked={number} value={number}/>
                        } />
                      </span>
                     
                      <span>
                          <FormControlLabel label="Special Character" 
                          control={
                              <GreenCheckbox checked={special} value={special}/>
                          }/> 
                      </span>
                    <span>
                        <FormControlLabel label="Min. 8 Characters"
                        control={ 
                        <GreenCheckbox checked={minlength} value={minlength} />
                        }/>
                    </span>
                </div>
                  <span>
                    <RaisedButton label="Change Password and Login" disabled={!updatepassdirty} onClick={handleChangePassword} primary={true} style={styles.loginBtn} />
                  </span>
                </div>
              </form>
            </Paper>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

ResetPassword.propTypes = {
  onAuthenticated: PropTypes.func
};

export default ResetPassword;
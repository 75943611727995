import React, { useState, useEffect, useRef } from 'react';
import EditAssignedName from './EditAssignedName';
import EditAssignedEmail from './EditAssignedEmail';
import EditAssignedPhone from './EditAssignedPhone';
import {
  Grid,
} from '@material-ui/core';

export default function EditAssigned(props) {
  const {
    retrieveWorkOrderAssignedName,
    editAssigned,
    assignedToName,
    retrieveWorkOrderAssignedEmail,
    assignedToEmail,
    retrieveWorkOrderAssignedPhone,
    assignedToPhone,
  } = props;

  return (
    <Grid id='yolo-2' container direction='row'>
      <Grid item xs={12}>
        <EditAssignedName
          retrieveWorkOrderAssignedName={retrieveWorkOrderAssignedName}
          editAssigned={editAssigned}
          assignedToName={assignedToName}
        />
      </Grid>
      <Grid item xs={12}>
        <EditAssignedEmail
          retrieveWorkOrderAssignedEmail={retrieveWorkOrderAssignedEmail}
          editAssigned={editAssigned}
          assignedToEmail={assignedToEmail}
        />
      </Grid>
      <Grid item xs={12}>
        <EditAssignedPhone
          retrieveWorkOrderAssignedPhone={retrieveWorkOrderAssignedPhone}
          editAssigned={editAssigned}
          assignedToPhone={assignedToPhone}
        />
      </Grid>
    </Grid>
  );
}


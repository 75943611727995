import React, { useState, useEffect, useRef } from 'react';
import TaskMoveBuildings from '../Tasks/TaskMoveBuildings/TaskMoveBuildings'
import {
  Grid,
} from '@material-ui/core';

export default function AdminTaskContainer(props) {
  const [ taskId, setTaskId ] = useState(0);
  const {
    handleFromCompanySelect,
    handleFromBuildingSelect,
    handleToCompanySelect,
    handleToCompanyTagSelect,
    handleSaveMoveBuildingsSelect,
    handleSaveMoveBuildingsCancel,
    handleSaveMoveBuildingsDispatch,

    userCompanies,
    userBuildings,
    moveToCompanies,
    moveToCompanyTags,
    
    toSelectedCompany,
    selectedCompany,
    selectedBuildings,
    selectedTags,

    selectedTask,
    selectedFromBuildings,
    formComplete,
    saveSelected,
  } = props;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousSelectedTask = usePrevious(selectedTask);
  useEffect(() => {
    if (selectedTask != previousSelectedTask) {
      if (selectedTask) {
        const { Id } = selectedTask;
        if (Id) {
          setTaskId(Id);
        } else {
          setTaskId(0);
        }
      }
    }
  }, [selectedTask]);
  
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12}>
        {
          taskId == 1 ?
            <TaskMoveBuildings
              handleFromCompanySelect={handleFromCompanySelect}
              handleFromBuildingSelect={handleFromBuildingSelect}
              handleToCompanySelect={handleToCompanySelect}
              handleToCompanyTagSelect={handleToCompanyTagSelect}
              handleSaveMoveBuildingsSelect={handleSaveMoveBuildingsSelect}
              handleSaveMoveBuildingsCancel={handleSaveMoveBuildingsCancel}
              handleSaveMoveBuildingsDispatch={handleSaveMoveBuildingsDispatch}

              userCompanies={userCompanies}
              userBuildings={userBuildings}
              moveToCompanies={moveToCompanies}
              moveToCompanyTags={moveToCompanyTags}

              toSelectedCompany={toSelectedCompany}
              selectedCompany={selectedCompany}
              selectedBuildings={selectedBuildings}
              selectedTags={selectedTags}

              selectedFromBuildings={selectedFromBuildings}
              formComplete={formComplete}
              saveSelected={saveSelected}
            />
            : null
        }
      </Grid>
    </Grid>
  );
}

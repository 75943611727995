import React, { useEffect, useState, useReducer } from 'react';
import PropertyDefectsCardImages from './SubComponents/PropertyDefectsCardImages';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RoomIcon from '@material-ui/icons/Room';
import ShowMore from 'react-show-more';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    boxShadow: 'none',
    fontFamily: 'Ebrima',
    
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    fontFamily: 'Ebrima',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    fontFamily: 'Ebrima',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    fontFamily: 'Ebrima',
  },
  avatar: {
    backgroundColor: 'rgb(31, 89, 100)',
    fontFamily: 'Ebrima',
  },
}));

export default function RecipeReviewCard(props) {
  const [expanded, setExpanded] = useState(false);

  const { 
    defect, 
    images, 
    highlightSelectedDefect 
  } = props;

  const { 
    SecId, 
    SeverityDesc, 
    Recommend,
    RecommendCost,
  } = defect;

  const handleSelectedDefect = () => {
    const defectId = defect.DefId;
     highlightSelectedDefect(defectId);
  };

  const classes = useStyles();

  return (
    <Card square className={classes.card}>
      <CardHeader classes={{title: classes.card, subheader: classes.card}}
        onClick={()=>handleSelectedDefect(defect)}
        avatar={ <Avatar aria-label="defect" className={classes.avatar}>{SecId}</Avatar> }
        action={
          <IconButton aria-label="settings">
            <RoomIcon />
          </IconButton>
        }
        title={`${SeverityDesc}`}
      />
      <CardMedia>
        <PropertyDefectsCardImages 
          images={ images }
        />
      </CardMedia>
      <CardContent>
        <div>
          <Typography variant="body2" color="textPrimary" component="p" style={{fontFamily: 'Ebrima',}}>
            <ShowMore
              lines={2}
              more='more'
              less='less'
              anchorClass=''
            >
            Recommend: {Recommend}
            </ShowMore>
          </Typography>  
        </div>
        <div>
          <Typography variant="body2" color="textPrimary" style={{fontFamily: 'Ebrima',}} component="p">
            Estimated Defect Cost: ${ RecommendCost }
          </Typography>
        </div>
      </CardContent>    
    </Card>
  );
}

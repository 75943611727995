import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    Typography: {
      fontFamily: ['Ebrima', 'Open Sans'].join(','),
      h1: {
        fontFamily: 'Ebrima',
       },
       h2: {
        fontFamily: 'Ebrima',
       },
       h3: {
        fontFamily: 'Ebrima',
       },
       h4: {
        fontFamily: 'Ebrima',
       },
       h5: {
        fontFamily: 'Ebrima',
       },
       h6: {
        fontFamily: 'Ebrima',
       },
       
     }
   })

export default theme;
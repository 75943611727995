import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Chip,
  Checkbox,
  TextField,
  FormControl,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

        /////////////!IMPORTANT/////////////!
//the mess you see in the onChange event is to enable 
//the component to initialize with the documents previous selected values without 
//disturbing its internal lifecycle / material ui magic (for editing)

export default function DocumentTagSelect(props) {
  const [ value, setValue ] = useState([])
  const {
    handleTagSelect,
    tags,
    document,
  } = props;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValueLength = usePrevious(value.length);
  useEffect(() => {
    if (value.length != previousValueLength) {
      if (value) {
        handleTagSelect(value);
      } else {
        handleTagSelect([]);
      }
    }  
  }, [value]);

  useEffect(() => {
    if (document) {
      const { Id, OrgTags } = document;
      if (Id && OrgTags) {
        if (OrgTags.length > 0) {
          setValue([...OrgTags]);
        }
      }
    }
  }, [document]);

  const classes = useStyles();
  return (
    <Grid container direction='row' justify='center' direction='row'>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            multiple
            fullWidth
            id="checkboxes-tags-demo"
            options={tags}
            value={value}
            disableCloseOnSelect
            getOptionLabel={(option) => option.Title}
            onChange={(event, newValue) => {
              const selectedOption = newValue.filter(x => !value.includes(x))
              if (selectedOption.length) {
                const selectedOrgUnitId = selectedOption[0].OrgUnitId;
                const selectedIndex = value.findIndex(tag => tag.OrgUnitId == selectedOrgUnitId);
                if (selectedIndex > -1) {
                  const valueCopy = [...value];
                  valueCopy.splice(selectedIndex, 1);
                  setValue(valueCopy);
                } else {
                  setValue([
                    ...newValue,
                  ])
                }
              } else {
                setValue([
                  ...newValue,
                ])
              }
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => {
                return (
                  <Chip
                    label={option.Title}
                    {...getTagProps({ index })}
                  />
                )
              })
            }}
            renderOption={(option, { selected }) => {
              const checked = value.filter(selection => selection.OrgUnitId == option.OrgUnitId).length || selected;
              return (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    checked={checked}
                  />
                  {option.Title}
                </React.Fragment>
              )
            }}
            style={{ width: '100%' }}
            renderInput={(params) => (
               <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}} {...params} label="Select Tags" />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
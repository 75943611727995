import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '95%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function DocumentWarrantySectionList(props) {
  const [checked, setChecked] = useState([]);
  const {
    handleSectionSelect,
    propertySections,
    document,
  } = props;

  const handleToggle = (value) => () => {
    console.log(value);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    const index = propertySections.findIndex(item => item.SecId === value);
    console.log(index);
    propertySections[index].Selected = propertySections[index].Selected ?false:true;

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    
    setChecked(newChecked);
    
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousChecked = usePrevious(checked.length);
  useEffect(() => {
    if (checked.length != previousChecked) {
      console.log(checked);
      handleSectionSelect(checked);
    }
  }, [checked]);

  useEffect(() => {
    console.log("document");
    if (document) {
      const { Id, Sections } = document;
      console.log(Id);
      console.log(Sections);
      if (Id && Sections) {
        const sections = [...new Set(Sections.map(section => section.SecId))];
        setChecked(sections);
      } else {
        setChecked([]);
      }
    }
  }, [document]);

  const classes = useStyles();
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12}>
        <List className={classes.root}>
          { propertySections ? propertySections.map((propertySection) => {
            const { SecId, SectionName, Selected } = propertySection;
            return (
              <ListItem key={SecId} role={undefined} dense button onClick={handleToggle(SecId)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(SecId) !== -1}
                    tabIndex={-1}
                    disableRipple
                    
                  ></Checkbox>
                </ListItemIcon>
                <ListItemText id={SectionName} primary={SectionName} />
              </ListItem>
            );
          }) : null }
        </List>
      </Grid>
    </Grid>

  );
}

import React, { Component } from 'react';
import ManageContactsLoading from './ManageContactsLoading';
import ManageContactsAddUserButton from './Buttons/ManageContactsAddUserButton';

import {
  Grid,
  Paper,
  Typography,
  List,
} from '@material-ui/core';
import ManageContactsCard from './ManageContactsCard';

class ManageContactsUsers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      renderAddContactCard: null,
    };
  }

  render() {
    const {
      filteredContacts,
      handleCardClick,
      selectedContact,
      loading,
      addContactToPropertyClick,
      addContactToProperty,
      userBuildingsContacts,
    } = this.props;

    return (
      <Grid style={{ display: 'contents' }}>
        <Grid id='contacts-column-grid' style={{ display: 'contents', margin: '5%' }} spacing={3} item xs={12} sm={12} md={3} lg={3} item>
          <Paper style={{ width: '35%', backgroundColor: '#E9E9E9', marginRight: '1%' }}>
            <Typography>Contacts: </Typography>
            { loading ? <ManageContactsLoading /> :
              <List style={{ height: '37rem', overflowY: 'scroll' }}>
                { filteredContacts.map((contact, i) =>                  
                  <ManageContactsCard
                    i={ i }
                    contact={ contact }
                    handleCardClick={ handleCardClick }
                    selectedContact={ selectedContact }
                  />
                )}
                { selectedContact && !addContactToProperty ? 
                  <ManageContactsAddUserButton
                    addContactToPropertyClick={ addContactToPropertyClick }
                    addContactToProperty={ addContactToProperty }
                    userBuildingsContacts={ userBuildingsContacts }
                  /> 
                  : null }
              </List>
            }
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default ManageContactsUsers

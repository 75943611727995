import React, { Component } from 'react';
import TagSelect from './Subcomponents/SelectProperty/TagSelect';
import PropertySelect from './Subcomponents/SelectProperty/PropertySelect';
import SelectedPropertyAddress from './Subcomponents/SelectProperty/SelectedPropertyAddress';
import RequesterSelect from './Subcomponents/RequestedBy/RequesterSelect';
import RequesterNameInput from './Subcomponents/RequestedBy/RequesterNameInput';
import RequestedByEmailInput from './Subcomponents/RequestedBy/RequestedByEmailInput';
import RequestedByPhoneInput from './Subcomponents/RequestedBy/RequestedByPhoneInput';
import PropertyContactName from './Subcomponents/PropertyContact/PropertyContactName';
import PropertyContactPhone from './Subcomponents/PropertyContact/PropertyContactPhone';
import PropertyContactEmail from './Subcomponents/PropertyContact/PropertyContactEmail';
import {
  Grid,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

class LeakResponseContactsForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      requestedIsUser: true,
      contactIsRequested: true,
      
      originalLeakResponseCopy: null,
      originalLeakResponseCopy: null,
      originalRequesterCopy: null,
      originalContactCopy: null,
      originalLeakResponseId: null,

      editedLeakResponseCopy: null,

      selectedTag: null,
      selectedProperty: null,
      modifiedRequesterComplete: true,
      modifiedContactComplete: true,

      lastSelectedProperty: {},

      updatedRequesterName: '',
      updatedRequesterPhone: '',
      updatedRequesterEmail: '',

      updatedContactName:'',
      updatedContactPhone: '',
      updatedContactEmail: '',

      dataComplete: false,
    }
  }

  componentDidMount = () => {
    const {
      userTags,
      selectedTag,
      selectedProperty,
      leakResponse,
      editedLeakResponse,
    } = this.props;

    const originalLeakResponseCopy = { ...leakResponse };
    const originalRequesterCopy = leakResponse.Requester;
    const originalContactCopy = leakResponse.PropertyContact;
    const originalTagId = leakResponse.TagId;
    const originalLeakResponseId = leakResponse.LeakResponseId;
    
    const editedLeakResponseCopy = { ...editedLeakResponse };
    const editedRequesterCopy = editedLeakResponse.Requester;
    const editedContactCopy = editedLeakResponse.PropertyContact;
    const editedPropertyCopy = leakResponse.Property;

    const emptyRequesterCopy = { Name: '', Phone: '', Email: '' };
    const emptyContactCopy = { Name: '', Phone: '', Email: '' };    

    const modifiedRequester = leakResponse.Requester;
    const modifiedContact = leakResponse.PropertyContact;
    //TODO: TRY TO USE SELECTED PROPERTY INSTEAD
    const lastSelectedProperty = { ...selectedProperty };

    let selectedTagCopy;
    let selectedPropertyCopy; 

    if (originalTagId) {
      const originalSelectedTag = userTags.find(userTag => userTag.OrgUnitId == originalTagId);
      const originalSelectedProperty = leakResponse.Property;
      selectedTagCopy = originalSelectedTag;
      selectedPropertyCopy = originalSelectedProperty;
    }

    if (!originalTagId) {
      selectedTagCopy = { ...selectedTag };
      selectedPropertyCopy = { ...selectedProperty };
    }

    this.setState({
      originalLeakResponseCopy,
      originalTagId,
      originalRequesterCopy,
      originalContactCopy,
      originalLeakResponseId,
      editedLeakResponseCopy,
      editedRequesterCopy,
      editedContactCopy,
      emptyRequesterCopy,
      emptyContactCopy,
      editedPropertyCopy,
      modifiedRequester,
      modifiedContact,
      lastSelectedProperty,
      selectedTag: selectedTagCopy, 
      selectedProperty: selectedPropertyCopy,
      dataComplete: false,
    })
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      selectedTag,
      selectedProperty,
      requestedIsUser,
      contactIsRequested,
      
      originalRequesterCopy,
      emptyRequesterCopy,
      emptyContactCopy,
      modifiedRequester,
      modifiedContact,

      updatedRequesterName,
      updatedRequesterPhone,
      updatedRequesterEmail,
      modifiedRequesterComplete,

      updatedContactName,
      updatedContactPhone,
      updatedContactEmail,
      modifiedContactComplete,

      tagPropertyComplete,
      requesterComplete,
      propertyContactComplete,
      dataComplete,
    } = this.state;
    const previousRequestedIsUser = prevState.requestedIsUser;
    const previousContactIsRequested = prevState.contactIsRequested;
    const previousUpdatedRequesterName = prevState.updatedRequesterName;
    const previousUpdatedRequesterPhone = prevState.updatedRequesterPhone;
    const previousUpdatedRequesterEmail = prevState.updatedRequesterEmail;
    const previousUpdatedContactName = prevState.updatedContactName;
    const previousUpdatedContactPhone = prevState.updatedContactPhone;
    const previousUpdatedContactEmail = prevState.updatedContactEmail;
    const previousModifiedRequester = prevState.modifiedRequester;
    const previousModifiedContact = prevState.modifiedContact;
    const previousSelectedTag = prevState.selectedTag;
    const previousSelectedProperty = prevState.selectedProperty;
    const previousTagPropertyComplete = prevState.tagPropertyComplete;
    const previousRequesterComplete = prevState.requesterComplete;
    const previousPropertyContactComplete = prevState.propertyContactComplete;
    const previousDataComplete = prevState.dataComplete;

    if (requestedIsUser != previousRequestedIsUser) {
      if (!requestedIsUser) {
        this.setState({
          modifiedRequester: emptyRequesterCopy,
        })
      }

      if (requestedIsUser) {
        if (contactIsRequested) {
          this.setState({
            modifiedRequester: originalRequesterCopy,
            modifiedContact: originalRequesterCopy,
          })
        }

        if (!contactIsRequested) {
          this.setState({
            modifiedRequester: originalRequesterCopy,
          })
        }
      }
    }

    if (contactIsRequested != previousContactIsRequested) {
      if (!contactIsRequested) {
        this.setState({
          modifiedContact: emptyContactCopy,
        })
      }

      if (contactIsRequested) {
        this.setState({
          modifiedContact: modifiedRequester,
        })
      }
    }

    if (!requestedIsUser && contactIsRequested) {
      const updatedRequesterObject = {
        Name: updatedRequesterName,
        Phone: updatedRequesterPhone,
        Email: updatedRequesterEmail,
      };

      if (JSON.stringify(updatedRequesterObject) != JSON.stringify(modifiedContact)) {
        this.setState({
          modifiedContact: updatedRequesterObject,
        })
      } 
    }

    if (updatedRequesterName != previousUpdatedRequesterName) {
      this.handleUpdateModifiedRequesterName();
    }

    if (updatedRequesterPhone != previousUpdatedRequesterPhone) {
      this.handleUpdateModifiedRequesterPhone();
    }

    if (updatedRequesterEmail != previousUpdatedRequesterEmail) {
      this.handleUpdateModifiedRequesterEmail();
    }

    if (updatedContactName != previousUpdatedContactName) {
      this.handleUpdateModifiedContactName();
    }

    if (updatedContactPhone != previousUpdatedContactPhone) {
      this.handleUpdateModifiedContactPhone();
    }

    if (updatedContactEmail != previousUpdatedContactEmail) {
      this.handleUpdateModifiedContactEmail();
    }

    if (modifiedRequester != previousModifiedRequester) {
      const {
        Name,
        Phone,
        Email,
      } = modifiedRequester;

      if (!Name || !Email) {
        this.handleRequesterIncomplete();
      }

      if (Name && Email) {
        this.handleRequesterComplete();
      }
    } 

    if (modifiedContact != previousModifiedContact) {
      const {
        Name,
        Phone,
        Email,
      } = modifiedContact;

      if (!Name || !Email) {
        this.handleContactIncomplete();
      }

      if (Name && Email) {
        this.handleContactComplete();
      }
    }

    if (
      selectedTag != previousSelectedTag ||
      selectedProperty != previousSelectedProperty
    ) {
      let selectedTagExists = 0;
      let selectedPropertyExists = 0;


      if (selectedTag && selectedProperty) {
        selectedTagExists = Object.values(selectedTag).length;
        selectedPropertyExists = Object.values(selectedProperty).length;
      }
      
      if (selectedTagExists && selectedPropertyExists) {
        this.setState({
          tagPropertyComplete: true,
        })
      }

      if (!selectedTagExists || !selectedPropertyExists) {
        this.setState({
          tagPropertyComplete: false,
        })
      }
    }

    if (modifiedRequester != previousModifiedRequester) {
      if (modifiedRequesterComplete) {
        this.setState({
          requesterComplete: true,
        })
      }
      if (!modifiedRequesterComplete) {
        this.setState({
          requesterComplete: false,
        })
      }
    }

    if (modifiedContact != previousModifiedContact) {
      if (modifiedContactComplete) {
        this.setState({
          propertyContactComplete: true,
        })
      }

      if (!modifiedContactComplete) {
        this.setState({
          propertyContactComplete: false,
        })
      }
    }

    if (
      tagPropertyComplete != previousTagPropertyComplete || 
      requesterComplete != previousRequesterComplete || 
      propertyContactComplete != previousPropertyContactComplete
      ) {
        if (tagPropertyComplete && requesterComplete && propertyContactComplete) {
          this.setState({
            dataComplete: true,
          })
        }

        if (!tagPropertyComplete || !requesterComplete || !propertyContactComplete) {
          this.setState({
            dataComplete: false,
          })
        }
      }

    if (dataComplete != previousDataComplete) {
        const { handleUpdateStepTwoComplete } = this.props;
        handleUpdateStepTwoComplete(dataComplete);
    }

    if (dataComplete) {
      const { 
        handleUpdateModifiedRequester, 
        handleUpdateModifiedContact 
      } = this.props;
      
      if (modifiedRequester != previousModifiedRequester) {
        handleUpdateModifiedRequester(modifiedRequester);
      }
      if (modifiedContact != previousModifiedContact) {
        handleUpdateModifiedContact(modifiedContact);
      }
    }
  };

  handleUpdateTags = (selectedTag) => {
    const { handleUpdateSelectedTag } = this.props;
    this.setState({
      selectedTag,
    }, () => handleUpdateSelectedTag(selectedTag));
  };

  handleUpdateProperty = (selectedProperty) => {
    const { handleUpdateSelectedBuilding } = this.props;
    this.setState({
      selectedProperty,
    }, () => handleUpdateSelectedBuilding(selectedProperty));
  };

  handleUpdateRequestedIsUser = () => {
    const { requestedIsUser } = this.state;
    this.setState({
      requestedIsUser: !requestedIsUser,
    })
  };

  handleRequesterIncomplete = () => {
    this.setState({
      modifiedRequesterComplete: false,
    })
  };

  handleRequesterComplete = () => {
    this.setState({
      modifiedRequesterComplete: true,
    })
  };

  handleContactIncomplete = () => {
    this.setState({
      modifiedContactComplete: false,
    })
  };

  handleContactComplete = () => {
    this.setState({
      modifiedContactComplete: true,
    })
  };

  handleUpdateContactIsRequested = () => {
    const { contactIsRequested } = this.state;
    this.setState({
      contactIsRequested: !contactIsRequested,
    })
  };

  handleUpdateRequesterName = (name) => {
    this.setState({
      updatedRequesterName: name,
    })
  };

  handleUpdateModifiedRequesterName = () => {
    const { modifiedRequester, updatedRequesterName } = this.state;
    let modifiedRequesterCopy = { ...modifiedRequester };
    modifiedRequesterCopy.Name = updatedRequesterName;

    this.setState({
      modifiedRequester: modifiedRequesterCopy,
    })
  };

  handleUpdateRequesterPhone = (phone) => {
    this.setState({
      updatedRequesterPhone: phone,
    })
  };

  handleUpdateModifiedRequesterPhone = () => {
    const { modifiedRequester, updatedRequesterPhone } = this.state;
    let modifiedRequesterCopy = { ...modifiedRequester };
    modifiedRequesterCopy.Phone = updatedRequesterPhone;

    this.setState({
      modifiedRequester: modifiedRequesterCopy,
    })
  };

  handleUpdateRequesterEmail = (email) => {
    this.setState({
      updatedRequesterEmail: email,
    })
  };

  handleUpdateModifiedRequesterEmail = () => {
    const { modifiedRequester, updatedRequesterEmail } = this.state;
    let modifiedRequesterCopy = { ...modifiedRequester };
    modifiedRequesterCopy.Email = updatedRequesterEmail;

    this.setState({
      modifiedRequester: modifiedRequesterCopy,
    })
  };

  handleUpdatePropertyContactName = (name) => {
    this.setState({
      updatedContactName: name,
    })
  };

  handleUpdateModifiedContactName = () => {
    const { modifiedContact, updatedContactName } = this.state;
    let modifiedContactCopy = { ...modifiedContact };
    modifiedContactCopy.Name = updatedContactName;

    this.setState({
      modifiedContact: modifiedContactCopy,
    })
  };


  handleUpdatePropertyContactPhone = (phone) => {
    this.setState({
      updatedContactPhone: phone,
    })
  };

  handleUpdateModifiedContactPhone = () => {
    const { modifiedContact, updatedContactPhone } = this.state;
    let modifiedContactCopy = { ...modifiedContact };
    modifiedContactCopy.Phone = updatedContactPhone;

    this.setState({
      modifiedContact: modifiedContactCopy,
    })
  };

  handleUpdatePropertyContactEmail = (email) => {
    this.setState({
      updatedContactEmail: email,
    })
  };

  handleUpdateModifiedContactEmail = () => {
    const { modifiedContact, updatedContactEmail } = this.state;
    let modifiedContactCopy = { ...modifiedContact };
    modifiedContactCopy.Email = updatedContactEmail;

    this.setState({
      modifiedContact: modifiedContactCopy,
    })
  };

  render() {
    const {
      userTags,
      propertyList,
      editedLeakResponse,
      leakResponse,
    } = this.props;

    const {
      originalLeakResponseId,
      selectedTag,
      lastSelectedProperty,
      selectedProperty,
      modifiedRequester,
      modifiedContact,

      editedPropertyCopy,

      requestedIsUser,
      contactIsRequested,
    } = this.state;

    return (
      <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%', width: '100%', backgroundColor: 'cornsilk', overflow: 'auto' }}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} style={{ height: '100%', padding: '2%' }}>
          <Grid container direction='row' style={{height: '100%', width: '100%'}}>
            <Grid xs={12} style={{ height: '7%', width: '100%' }}>
              <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>
                <Grid item xs={12}>
                  <Typography>Select Property</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ height: '93%', width: '100%' }}>
              <Paper style={{ width: '100%', height: '100%', backgroundColor: 'gainsboro', boxShadow: 'none' }}>
                <Grid container direction='row' style={{ height: '100%', width: '100%' }}>
                  <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
                    <Grid container direction='row' alignItems='center' justify='center'>
                      <Grid item xs={12}>
                        <TagSelect
                          handleUpdateTags={this.handleUpdateTags}

                          leakResponse={leakResponse}
                          originalLeakResponseId={originalLeakResponseId}                          
                          selectedTag={selectedTag}
                          userTags={userTags}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='center' justify='center'>
                      <Grid item xs={12}>
                        <PropertySelect
                          handleUpdateProperty={this.handleUpdateProperty}

                          leakResponse={leakResponse}
                          originalLeakResponseId={originalLeakResponseId}                          
                          editedPropertyCopy={editedPropertyCopy}
                          lastSelectedProperty={lastSelectedProperty}
                          selectedTag={selectedTag}
                          propertyList={propertyList}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='center' justify='center'>
                      <Grid item xs={12}>
                        <SelectedPropertyAddress
                          selectedProperty={selectedProperty}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} style={{ height: '100%', padding: '2%' }}>
          <Grid container direction='row' style={{ height: '100%', width: '100%' }}>
            <Grid xs={12} style={{ height: '7%', width: '100%' }}>
              <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>
                <Grid item xs={12}>
                  <Grid container direction='row' alignItems='center' justify='center'>
                    <Grid xs={6}>
                      <Typography>Requested By</Typography>
                    </Grid>
                    <Grid xs={6} style={{ textAlign: 'right' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={requestedIsUser}
                            onChange={() => this.handleUpdateRequestedIsUser()}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={<Typography variant='subtitle2'>Same as User</Typography>}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} style={{ height: '93%', width: '100%' }}>
              <Paper style={{ width: '100%', height: '100%', backgroundColor: 'gainsboro', boxShadow: 'none' }}>
                <Grid container direction='row' style={{ height: '100%', width: '100%' }}>
                  <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
                    <Grid container direction='row' alignItems='center' justify='center'>
                      <Grid item xs={12}>
                        <RequesterNameInput 
                          handleUpdateRequesterName={this.handleUpdateRequesterName}

                          modifiedRequester={modifiedRequester}
                          requestedIsUser={requestedIsUser}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='center' justify='center'>
                      <Grid item xs={12}>
                        <RequestedByPhoneInput 
                          handleUpdateRequesterPhone={this.handleUpdateRequesterPhone}

                          modifiedRequester={modifiedRequester}
                          requestedIsUser={requestedIsUser}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='center' justify='center'>
                      <Grid item xs={12}>
                        <RequestedByEmailInput 
                          handleUpdateRequesterEmail={this.handleUpdateRequesterEmail}

                          modifiedRequester={modifiedRequester}
                          requestedIsUser={requestedIsUser}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={4} xl={4} style={{ height: '100%', padding: '2%' }}>
          <Grid container direction='row' style={{ height: '100%', width: '100%' }}>
            <Grid xs={12} style={{ height: '7%', width: '100%' }}>
              <Grid container direction='row' alignItems='center' justify='center'>
                <Grid xs={6}>
                  <Typography>Property Contact</Typography>
                </Grid>
                <Grid xs={6} style={{ textAlign: 'right' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contactIsRequested}
                        onChange={() => this.handleUpdateContactIsRequested()}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={<Typography variant='subtitle2'>Same as requested by</Typography>}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} style={{ height: '93%', width: '100%' }}>
              <Paper style={{ width: '100%', height: '100%', backgroundColor: 'gainsboro', boxShadow: 'none' }}>
                <Grid container direction='row' style={{ height: '100%', width: '100%' }}>
                  <Grid item xs={12} style={{ height: '100%', width: '100%' }}>
                    <Grid container direction='row' alignItems='center' justify='center'>
                      <Grid item xs={12}>
                        <PropertyContactName 
                          handleUpdatePropertyContactName={this.handleUpdatePropertyContactName}

                          contactIsRequested={contactIsRequested}
                          modifiedContact={modifiedContact}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='center' justify='center'>
                      <Grid item xs={12}>
                        <PropertyContactPhone 
                          handleUpdatePropertyContactPhone={this.handleUpdatePropertyContactPhone}
                          
                          contactIsRequested={contactIsRequested}
                          modifiedContact={modifiedContact}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction='row' alignItems='center' justify='center'>
                      <Grid item xs={12}>
                        <PropertyContactEmail 
                          handleUpdatePropertyContactEmail={this.handleUpdatePropertyContactEmail}
                          
                          contactIsRequested={contactIsRequested}
                          modifiedContact={modifiedContact}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
};

export default LeakResponseContactsForm
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  FormControl,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '98%',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ToCompanyTagSelect(props) {
  const [value, setValue] = useState([]);
  const {
    handleToCompanyTagSelect,
    toSelectedCompany,
    selectedFromBuildings,
    moveToCompanyTags,
  } = props;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousValueLength = usePrevious(value.length);
  useEffect(() => {
    if (value.length != previousValueLength) {
      if (value.length) {
        handleToCompanyTagSelect(value);
      } else {
        handleToCompanyTagSelect([]);
      }
    }
  }, [value]);

  //set selected values back to initial state if 
  //company from which the tags were determined, is changed
  const previousMoveToCompanyTags = usePrevious(moveToCompanyTags);
  useEffect(() => {
    if (moveToCompanyTags) {
      const moveToCompanyTagsExists = moveToCompanyTags.length;
      if (!moveToCompanyTagsExists) {
        if (previousMoveToCompanyTags) {
          if (previousMoveToCompanyTags.length) {
            setValue([]);
          }
        }
      }
    }
  }, [moveToCompanyTags]);


  const previousToSelectedCompany = usePrevious(toSelectedCompany);
  useEffect(() => {
    if (toSelectedCompany) {
      const toSelectedCompanyId = toSelectedCompany.CompanyId;
      const previousToSelectedCompanyId = previousToSelectedCompany ? previousToSelectedCompany.CompanyId : 0;
      if (toSelectedCompanyId != previousToSelectedCompanyId) {
        setValue([]);
      }
    }
  }, [toSelectedCompany]);

  const classes = useStyles();
  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            multiple
            disableClearable
            disableCloseOnSelect
            disabled={!toSelectedCompany || !toSelectedCompany.CompanyId || !selectedFromBuildings.length }
            id="tags-standard"
            onChange={(e, newValue) => setValue(newValue)}
            value={value}
            options={moveToCompanyTags ? moveToCompanyTags : []}
            getOptionLabel={(tag) => tag.Title}
            renderInput={(params) => (
               <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                {...params}
                variant="standard"
                label="Company Tags"
                placeholder={ !value.length ? "Select Tags" : false }
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

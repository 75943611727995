import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import PropertyRepairsList from './PropertyRepairsList';
import PropertyRepairsListHeader from './SubComponents/PropertyRepairsListHeader';
import auth from '../../../auth';
import {
  Grid,
  Paper,
  ButtonGroup,
} from '@material-ui/core';

class PropertyRepairs extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      defects: null,
      defectsCategorySelected: null,
      repairsCategorySelected: null,
      selectedDefectSection: null,
      selectedDefectGroupIds: [],
      defectGroups: [],
      selectedDefects: [],
      defectRepairs: [],

      retrievedDefects: null,
      highlightedDefect: null,
      selectedGroupCard: null,
      selectedPropertyId: null,
      displayRepairs: false,
    };
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      defectsCategorySelected,
      selectedDefectSection,
      selectedDefectGroupIds,
      selectedDefects,
      defectGroups,
      defectRepairs,
      highlightedDefect,
      selectedPropertyId,
      displayRepairs,
    } = this.state;

    const previousHighlightedDefect = prevState.highlightedDefect;
    const previousSelectedDefects = prevState.selectedDefects;
    const previousSelectedDefectSection = prevState.selectedDefectSection;
    const previousDefectsCategorySelected = prevState.defectsCategorySelected;
    const previousSelectedDefectGroupIds = prevState.selectedDefectGroupIds;
    const previousDisplayRepairs = prevState.displayRepairs;

    const {
      selectedProperty,
    } = this.props;
    const previousSelectedProperty = prevProps.selectedProperty;

    const allDefectGroups = [...new Set(selectedProperty.DefectRepairs)];
    const allDefectsArray = this.getAllDefects(allDefectGroups);
    const defectGroupsInSection = this.filterDefectGroups(allDefectGroups, selectedDefectSection);
    const defectsFromGroups = this.getDefectsFromGroups(defectGroupsInSection, selectedDefectSection);

    if (selectedProperty) {      
      if (selectedProperty.Id != selectedPropertyId) {
        this.setState({
          defectGroups: allDefectGroups,
          selectedDefects: allDefectsArray,
          selectedPropertyId: selectedProperty.Id,
        })
      }

      if (displayRepairs != previousDisplayRepairs) {
        if (displayRepairs) {
          this.setState({
            defectGroups: allDefectGroups,
            selectedDefects: allDefectsArray,
          })
        }
        if (!displayRepairs) {
          this.setState({
            defectGroups: [],
            selectedDefects: [],
          })
        }
      }

      if (defectsCategorySelected) {
        if (allDefectGroups.length > 0) {
          if (defectGroups.length == 0) {
            if (!selectedDefectSection) {
              this.setState({
                defectGroups: allDefectGroups,
                selectedDefects: allDefectsArray,
              }, console.log('initialing with allDefectGroups'))
            }
            if (selectedDefectSection) {
              if (selectedDefectSection != previousSelectedDefectSection) {
                this.setState({
                  defectGroups: [...new Set(defectGroupsInSection)],
                  selectedDefects: [...new Set(defectsFromGroups)],
                }, console.log('initializing with defectGroupsInSection'))
              }
            }
          }
        }
      }

      if (defectGroups.length > 0) {
        if (defectsCategorySelected != previousDefectsCategorySelected) {
          if (defectsCategorySelected) {
            const allDefectsArray = this.getAllDefects(allDefectGroups);
            this.setState({
              selectedDefects: [...new Set(allDefectsArray)],
            }, console.log('selected group 0'))
          }
          if (!defectsCategorySelected) {
            this.setState({
              selectedDefects: [],
            })
          }
        }

        if (selectedDefectSection == previousSelectedDefectSection) {
          if (selectedDefectGroupIds != previousSelectedDefectGroupIds) {
            if (selectedDefectGroupIds.length > 0) {
              const selectedDefectsArray = this.getSelectedDefects();
              this.setState({
                selectedDefects: [...new Set(selectedDefectsArray)],
              }, console.log('selected group 1'))
            }
            if (selectedDefectGroupIds.length == 0) {
              let defects;
              if (!selectedDefectSection) {
                defects = this.getAllDefects(allDefectGroups);
              }
              if (selectedDefectSection) {
                const defectGroupsInSection = this.filterDefectGroups(allDefectGroups, selectedDefectSection);
                const defectsFromGroups = this.getDefectsFromGroups(defectGroupsInSection, selectedDefectSection);
                defects = defectsFromGroups;
              }
              this.setState({
                selectedDefects: [...new Set(defects)],
                highlightedDefect: null,
              }, console.log('selected group 2'))
            }
          }
        }

        if (selectedDefectSection != previousSelectedDefectSection) {
          if (selectedDefectSection) {
            if (!previousSelectedDefectSection) {
              const defectGroupsInSection = this.filterDefectGroups(defectGroups, selectedDefectSection);
              const defectsFromGroups = this.getDefectsFromGroups(defectGroupsInSection, selectedDefectSection);
              this.setState({
                selectedDefects: [...new Set(defectsFromGroups)],
                defectGroups: [...new Set(defectGroupsInSection)],
                selectedDefectGroupIds: [],
                highlightedDefect: null,
              }, console.log('group 1'))
            }
            if (previousSelectedDefectSection) {
              const defectGroupsInSection = this.filterDefectGroups(allDefectGroups, selectedDefectSection);
              const defectsFromGroups = this.getDefectsFromGroups(defectGroupsInSection, selectedDefectSection);
              this.setState({
                selectedDefects: [...new Set(defectsFromGroups)],
                defectGroups: [...new Set(defectGroupsInSection)],
                selectedDefectGroupIds: [],
                highlightedDefect: null,
              }, console.log('group 2'))
            }
          }
          if (!selectedDefectSection) {
            const allDefectsArray = this.getAllDefects(allDefectGroups);
            this.setState({
              selectedDefects: [...new Set(allDefectsArray)],
              defectGroups: [...new Set(allDefectGroups)],
              selectedDefectGroupIds: [],
              highlightedDefect: null,
            }, console.log('group 3'))
          }
        }
      }
    }

    const {
      retrieveSelectedRepairSection,
      retrieveSelectedRepairs,
      retrieveHighlightedRepair,
    } = this.props;

    if (selectedDefectSection != previousSelectedDefectSection) {
      retrieveSelectedRepairSection(selectedDefectSection);
    }

    if (selectedDefects != previousSelectedDefects) {
      retrieveSelectedRepairs(selectedDefects);
    }

    if (highlightedDefect != previousHighlightedDefect) {
      retrieveHighlightedRepair(highlightedDefect);
    }
  };

  filterDefectGroups = (defectGroups, selectedDefectSection) => {
    const defectGroupsInSection = [];
    defectGroups.map((defectGroup) => {
      const defects = defectGroup.Defects;
      defects.map((defect) => {
        const defectSectionId = defect.SectionId;
        if (defectSectionId == selectedDefectSection) {
          defectGroupsInSection.push(defectGroup);
        }
      })
    })
    return defectGroupsInSection;
  };

  getDefectsFromGroups = (defectGroupsInSection, selectedDefectSection) => {
    const defectsInSection = [];
    defectGroupsInSection.map((defectGroup) => {
      const defects = defectGroup.Defects;
      defects.map((defect) => {
        if (selectedDefectSection) {
          if (defect.SectionId == selectedDefectSection) {
            defectsInSection.push(defect);
          }
        } else {
          defectsInSection.push(defect);
        }
      })
    })
    return defectsInSection;
  };

  getAllDefects = (defectGroups) => {
    const allDefects = [];
    defectGroups.map((defectGroup) => {
      const defects = defectGroup.Defects;
      defects.map((defect) => {
        allDefects.push(defect);
      })
    });
    return allDefects;
  };

  getSelectedDefects = () => {
    const { selectedDefectGroupIds, defectGroups, selectedDefectSection } = this.state;
    const defectGroupsCopy = [...defectGroups];
    const selectedDefectsArray = [];
    selectedDefectGroupIds.map((selectedDefectGroupId => {
      const selectedGroup = defectGroupsCopy.find(defectGroupCopy => defectGroupCopy.WBSDefId == selectedDefectGroupId);
      const selectedGroupDefects = selectedGroup.Defects;
      selectedGroupDefects.map((selectedGroupDefect) => {
        const sectionId = selectedGroupDefect.SectionId;
        if (selectedDefectSection) {
          if (selectedDefectSection == sectionId) {
            selectedDefectsArray.push(selectedGroupDefect);
          }
        } else {
          selectedDefectsArray.push(selectedGroupDefect);
        }
      })
    }))
    return selectedDefectsArray;
  };

  handleDefectsCategorySelected = (selected) => {
    this.setState({
      defectsCategorySelected: selected,
    })
  };

  handleRepairsCategorySelected = (selected) => {
    this.setState({
      repairsCategorySelected: selected,
    })
  };

  retrieveSelectedDefectSection = (selectedDefectSection) => {
    this.setState({
      selectedDefectSection,
    })
  };

  retrievedSelectedDefectsGroups = (defectGroupId) => {
    const { selectedDefectGroupIds } = this.state;
    const included = selectedDefectGroupIds.includes(defectGroupId);
    if (included) {
      const selectedDefectGroupIdsCopy = [...selectedDefectGroupIds];
      const filteredDefectsGroups = selectedDefectGroupIdsCopy.filter(selectedDefectGroupCopy => selectedDefectGroupCopy != defectGroupId);
      this.setState({
        selectedDefectGroupIds: filteredDefectsGroups,
      })
    }

    if (!included) {
      this.setState({
        selectedDefectGroupIds: [...selectedDefectGroupIds, defectGroupId],
      })
    }
  };

  highlightSelectedDefect = (selectedDefectId) => {
    const { highlightedDefect } = this.state;
    if (selectedDefectId == highlightedDefect) {
      this.setState({
        highlightedDefect: null,
      })
    } else {
      this.setState({
        highlightedDefect: selectedDefectId,
      })
    }
  };

  handleDisplayRepairs = (checked) => {
    this.setState({
      displayRepairs: checked,
    })
  }

  componentWillUnmount = () => {
    console.log('unmounting PROPERTYDEFECTS');
     //this.vm.$destroy();
  };

  render() {
    const {
      selectedResult,
      selectedProperty,
      propertyBounds,
      addressCoordinants,
    } = this.props;

    const {
      selectedDefects,
      highlightedDefect,
      selectedDefectSection,
      defectGroups,
      displayRepairs,
    } = this.state;

    return (
      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid item xs={12}>
          <PropertyRepairsListHeader 
            handleDisplayRepairs={this.handleDisplayRepairs}
          />
        </Grid>
        <PropertyRepairsList
          selectedProperty={selectedProperty}
          handleDefectsCategorySelected={this.handleDefectsCategorySelected}
          retrievedSelectedDefectsGroups={this.retrievedSelectedDefectsGroups}
          retrieveSelectedDefectSection={this.retrieveSelectedDefectSection}
          highlightSelectedDefect={this.highlightSelectedDefect}
          defectGroups={defectGroups}
          selectedDefectSection={selectedDefectSection}
          displayRepairs={displayRepairs}
        />
      </Grid>
    )
  }
};

export default PropertyRepairs
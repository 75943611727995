import React from 'react';
import {SvgIcon} from '@material-ui/core';

let SvgWOMaint = (props) => (
  <SvgIcon {...props} >
       <path id="time-6" d="M11,6v8h7V12H13V6ZM2.6,15.4l-1.8.9a18.243,18.243,0,0,1-.7-2.5l2-.2A9.4648,9.4648,0,0,0,2.6,15.4ZM7,22.9,7.7,21c-.5-.3-1.1-.6-1.6-.9L4.7,21.5A10.8866,10.8866,0,0,0,7,22.9ZM1.7,18.1c.4.7,1,1.4,1.5,2.1l1.4-1.4a14.3092,14.3092,0,0,1-1.1-1.5ZM12,0A11.8826,11.8826,0,0,0,4.6,2.6L2.4.4,1,7.4,8,6,6,4a10.2832,10.2832,0,0,1,6-2,10,10,0,0,1,0,20,10.6792,10.6792,0,0,1-2.5-.3l-.7,1.9a10.55,10.55,0,0,0,3.1.4,12.0353,12.0353,0,0,0,12-12A11.8863,11.8863,0,0,0,12,0ZM0,11.8l2-.2a6.4477,6.4477,0,0,1,.3-2H.2A17.96,17.96,0,0,0,0,11.8Z"/>
  </SvgIcon>
);

export default SvgWOMaint;

import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';

export default function PropertyDefectGroupCard() {

  return (
    <Grid container direction='row' alignItems='center' justify='center' style={{ height: '100%', padding: '16%' }}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant={'h6'} style={{fontFamily: 'Ebrima',}}>Loading..</Typography>
      </Grid>
    </Grid>
  );
}

import React from 'react';
import { withGoogleMap, GoogleMap, Polyline, Polygon, Marker } from "react-google-maps";
import imgwarning from '../../../images/baseline_warning_amber_black_24dp.png';
import {
  Grid,
} from '@material-ui/core';

const getMapOptions = (maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,
    data: {
      style: {
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      }
    },
    styles: [{
      featureType: "poi.business",
      elementType: "labels",
      stylers: [{
        visibility: "off"
      }]
    }],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 11,
    maxZoom: 23,
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.SATELLITE,
    tilt: 0,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID
      ]
    },
    zoomControl: true,
    clickableIcons: false
  };
};

const createDefectPoints = (points) => {
  const formattedPoints = [];
  points.map((point) => {
    const formattedPoint = {
      lat: point.Lat,
      lng: point.Lng
    }
    formattedPoints.push(formattedPoint);
  })
  return [...new Set(formattedPoints)];
};

const DefectsMap = withGoogleMap(props => {
  const {
    //repairs
    selectedRepairs,
    selectedRepairSection,
    highlightedRepair,
    //defects
    selectedDefects,
    highlightedDefect,
    //centering
    propertyBounds,
    addressCoordinants,
  } = props;

  return (
    <GoogleMap
      options={getMapOptions(window.google.maps)}
      defaultZoom={8}
      ref={map => { map ? map.fitBounds(propertyBounds) : null }}
    >      
    { selectedDefects ? selectedDefects.map((defect, i) => {
      const defectId = defect.DefId;
      console.log(defect);
      const formattedPoints = createDefectPoints(defect.Points);
      if (formattedPoints.length > 1) {
        return (
          <Polyline
            defaultPosition={addressCoordinants}
            path={formattedPoints}
            geodesic={true}
            options={highlightedDefect == defectId ? { strokeColor: '#FFFF00', strokeOpacity: 1, strokeWeight: 6 } : { strokeColor: '#FF0000', strokeOpacity: 1, strokeWeight: 2 }}
          />
        )
      } else {
        if(defect.WbsdefId == 654){
          return (
            <Marker 
              animation={highlightedDefect == defectId ? '1' : '0'}
              defaultAnimation={google.maps.Animation.DROP}
              position={formattedPoints[0]}
                options={{ icon: { url: imgwarning } }}>
            </Marker>
          )
        }else{
          return (
            <Marker 
              animation={highlightedDefect == defectId ? '1' : '0'}
              defaultAnimation={google.maps.Animation.DROP}
              position={formattedPoints[0]}>
            </Marker>
          )
        }
      }
    }) : null }
      { selectedRepairs ? selectedRepairs.map((defect, i) => {
        const defectId = defect.DefId;
        const formattedPoints = createDefectPoints(defect.Points);
        if (formattedPoints.length > 1) {
          return (
            <Polyline
              defaultPosition={addressCoordinants}
              path={formattedPoints}
              geodesic={true}
              options={highlightedRepair == defectId ? { strokeColor: '#00FF00', strokeOpacity: 1, strokeWeight: 6 } : { strokeColor: '#0000FF', strokeOpacity: 1, strokeWeight: 2 }}
            />
          )
        } else {
          return (
            <Marker
              animation={highlightedRepair == defectId ? '1' : '0'}
              defaultAnimation={google.maps.Animation.DROP}
              position={formattedPoints[0]}
              options={{ icon: { url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" } }}>
            </Marker>
          )
        }
      }) : null }
    </GoogleMap>
  )
});

export default function PropertyDefectsMap(props) {
  const { 
    //defects
    selectedDefects, 
    highlightedDefect,
    //repairs
    selectedRepairs,
    selectedRepairSection,
    highlightedRepair,
    //map centering
    addressCoordinants,
    sectionPerimeter,
    propertyBounds,
    //resizing
    mobile,
  } = props;

  const mapContainerElementStyle = {
    height: '100%',
    width: '100%'
  };

  const mapElementStyle = { height: '100%' };

  return (
    <Grid id='map' container direction='row' alignItems='center' justify='center' style={{ height: '100%' }}>
      <Grid item xs={12} style={{ height: '100%' }}>
        <DefectsMap
          //repairs
          selectedRepairs={selectedRepairs}
          selectedRepairSection={selectedRepairSection}
          highlightedRepair={highlightedRepair}
          //defects
          highlightedDefect={highlightedDefect}
          sectionPerimeter={sectionPerimeter}
          selectedDefects={selectedDefects}
          //map centering
          addressCoordinants={addressCoordinants}
          propertyBounds={propertyBounds}
          //required style props
          containerElement={<div style={mapContainerElementStyle} />}
          mapElement={<div style={mapElementStyle} />}
        />
      </Grid>
    </Grid>
  )
}
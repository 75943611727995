import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  IconButton,
  TextField,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

export default function PropertySections(props) {
  const [enabled, setEnabled] = useState(false);
  const [edited, setEdited] = useState(false);
  const [editing, setEditing] = useState(false);
  const [description, setDescription] = useState('');

  const {
    handleEditExecutiveSummary,
    property,
    userRank,
  } = props;

  const handleEdit = () => {
    setEditing(!editing);
  };

  const handleSave = () => {
    setEditing(false);
    setEdited(false);
    if (property) {
      const { ExecutiveSummary } = property;
      if (ExecutiveSummary != description) {
        handleEditExecutiveSummary(description);
      }
    }
  };

  const handleCancel = () => {
    const { ExecutiveSummmary } = property;
    setEditing(false);
    setEdited(false);
    if (ExecutiveSummmary) {
      setDescription(ExecutiveSummmary);
    } 
    if (!ExecutiveSummmary) {
      setDescription('');
    }
  };

  const onChange = (e) => {
    const text = e.target.value;
    setDescription(text);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  useEffect(() => {
    if (property) {
      const { ExecutiveSummmary } = property;
      if (ExecutiveSummmary) {
        setDescription(ExecutiveSummmary);
      } 
    }
  }, [property]);

  const previousDescription = usePrevious(description);
  useEffect(() => {
    if (property) {
      const propertyExists = Object.values(property).length;
      if (propertyExists) {
        const executiveSummary = property.ExecutiveSummary;

        if (description != previousDescription) {
          if (description != executiveSummary) {
            if (editing && !edited) {
              setEdited(true);
            }
          }
        }
      }
    }
  }, [description]);

  useEffect(() => {
    if (userRank >= 4) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  }, [userRank]);

  return (
    <Grid container direction='row' alignItems='center' justify='center'>
      <Grid item xs={12}>

        <Grid container direction='row' alignItems='center' justify='space-between'>
          <Grid item xs={10}>
            <Typography variant='h6' style={{fontFamily: 'Ebrima',}}>Executive Summary</Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center', fontFamily: 'Ebrima', }}>
            { edited ?
              <Grid container direction='row' alignItems='center' justify='center'>
                <Grid item xs={6}>
                  <IconButton size='small' onClick={handleSave}>
                    <SaveIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <IconButton size='small' onClick={handleCancel}>
                    <CancelIcon fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
              :
              <Grid container direction='row' alignItems='center' justify='flex-end'>
                <Grid item xs={6}>
                  <IconButton disabled={!enabled} size='small' onClick={handleEdit}>
                    <EditIcon fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>

        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item xs={12}>
            <form style={{ width: '98%', marginLeft: '1%', marginRight: '1%', fontFamily: 'Ebrima', }}>
               <TextField
            inputprops={{style: {fontFamily: 'Ebrima'}}} // font size of input text
            InputLabelProps={{style: {fontFamily: 'Ebrima'}}}
                id="outlined-multiline-static"
                style={{width: '100%', fontFamily: 'Ebrima',}}
                onChange={onChange}
                multiline
                rows="4"
                placeholder={'Add A Description'}
                value={description}
                disabled={!editing}
              />
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
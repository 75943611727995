import React, { useState, useEffect, useRef } from 'react';
import EditContractorName from './EditContractorName';
import EditContractorEmail from './EditContractorEmail';
import EditContractorPhone from './EditContractorPhone';
import {
  Grid,
} from '@material-ui/core';

export default function EditAssigned(props) {
  const {
    retrieveWorkOrderContractorName,
    retrieveWorkOrderContractorEmail,
    retrieveWorkOrderContractorPhone,
    contractorName,
    contractorEmail,
    contractorPhone,
    editContractor,
  } = props;

  return (
    <Grid id='yolo-2' container direction='row'>
      <Grid item xs={12}>
        <EditContractorName
          retrieveWorkOrderContractorName={retrieveWorkOrderContractorName}
          contractorName={contractorName}
          editContractor={editContractor}
        />
      </Grid>
      <Grid item xs={12}>
        <EditContractorEmail
          retrieveWorkOrderContractorEmail={retrieveWorkOrderContractorEmail}
          contractorEmail={contractorEmail}
          editContractor={editContractor}
        />
      </Grid>
      <Grid item xs={12}>
        <EditContractorPhone
          retrieveWorkOrderContractorPhone={retrieveWorkOrderContractorPhone}
          contractorPhone={contractorPhone}
          editContractor={editContractor}
        />
      </Grid>
    </Grid>
  );
}


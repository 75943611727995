import React, { Component, useState, useEffect } from 'react';
import LeakResponseLocationDescription from './Subcomponents/LeakResponseLocationDescription';
import LeakResponseLocationMap from './Subcomponents/LeakResponseLocationMap';
import LeakResponseLocationList from './Subcomponents/LeakResponseLocationList';
import {
  Grid,
  Card,
  Paper,
  Button,
  Typography
} from '@material-ui/core';

const COMPONENT_LEAK_RESPONSE_STEP = 2;

class LeakResponseLocation extends Component {
  constructor(props){
    super(props);

    this.state = {
      editedLeakResponseCopy: {},
      deletedLocation: {},
      locationObject: {
        location: {},
        description: '',
      },

      selectedLocations: [],

      selectedCoordinants: '',
      locationDescription: '',

      disabled: true,
      nextSelected: false,
      backSelected: false,
      selectingLocation: false,
      dataComplete: false,
      addingLocationMode: false
    }
  }

  componentDidMount = () => {
    const { leakResponse } = this.props;
    const editedLeakResponseCopy = { ...leakResponse };

    this.setState({
      editedLeakResponseCopy,
    })
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { 
      editedLeakResponseCopy 
    } = this.state;
    const previousEditedLeakResponseCopy = prevState.editedLeakResponseCopy;

    if (editedLeakResponseCopy != previousEditedLeakResponseCopy) {
      if (Object.values(editedLeakResponseCopy).length) {
        const {
          Locations,
        } = editedLeakResponseCopy;

        if (Locations.length) {
          if (Locations != selectedLocations) {
            this.setState({
              selectedLocations: Locations,
            })
          }
        }
      }  
    }

    const {
      handleIncrementWizardStep,
    } = this.props;
    
    const {
      nextSelected,
      backSelected,
    } = this.state;
    const previousNextSelected = prevState.nextSelected;
    const previousBackSelected = prevState.backSelected;
    const nextStep = COMPONENT_LEAK_RESPONSE_STEP + 1;
    const backStep = COMPONENT_LEAK_RESPONSE_STEP - 1;
    
    if (nextSelected != previousNextSelected) {
      handleIncrementWizardStep(nextStep);
      this.handleResetNextSelected();
      this.handleResetDisabled();
    }

    if (backSelected != previousBackSelected) {
      handleIncrementWizardStep(backStep);
    }

    const {
      locationObject,
      selectedLocations,
      locationDescription,
      selectedCoordinants,
      deletedLocation,
      dataComplete,
    } = this.state;
    const previousSelectedLocations = prevState.selectedLocations;
    const previousLocationDescription = prevState.locationDescription;
    const previousSelectedCoordinants = prevState.selectedCoordinants;
    const previousLocationObject = prevState.locationObject;
    const previousDeletedLocation = prevState.deletedLocation; 
    const previousDataComplete = prevState.dataComplete;

    const {
      handleUpdateLocationDescription,
      handleUpdateLocationCoordinants,
      handleUpdateSelectedLocation,
      handleUpdateSelectedLocations,
      handleUpdateRemovedLocation,
    } = this.props;

    if (selectedCoordinants != previousSelectedCoordinants) {
      if (selectedCoordinants) {
        this.handleUpdateSelectedLocations();
      }
    }

    if (locationDescription != previousLocationDescription) {
      if (locationDescription) {
        this.handleUpdateLocationObject();
      }
    }

    if (selectedLocations != previousSelectedLocations) {
      handleUpdateSelectedLocations(selectedLocations);

      if (deletedLocation != previousDeletedLocation) {
        handleUpdateRemovedLocation(deletedLocation);
      }
    }

    if (locationDescription != previousLocationDescription) {
      handleUpdateLocationDescription(locationDescription);
    }

    if (
      locationDescription != previousLocationDescription ||
      selectedCoordinants != previousSelectedCoordinants ||
      selectedLocations != previousSelectedLocations
      ) {

      if (selectedLocations.length > 0) {
        this.setState({
          dataComplete: true,
        })
      }

      if (selectedLocations.length == 0) {
        this.setState({
          dataComplete: false,
        })
      }
    }

    if (dataComplete != previousDataComplete) {
      const { handleUpdateStepThreeComplete } = this.props;
      handleUpdateStepThreeComplete(dataComplete);
    }
  };

  handleUpdateSelectedLocations = () => {
    const {
      handleUpdateSelectedLocation,
      handleUpdateLocationCoordinants,
    } = this.props;

    const { 
      locationDescription,
      selectedLocations,
      selectedCoordinants, 
      locationObject 
    } = this.state;

    //TODO: this gets the description from locationDescription, 
    //and doesn't take into effect that the description, when passed
    // from the backend is passed as 'Description'
    const selectedLocationsCopy = [ ...selectedLocations ];
    const selectedCoordinantsCopy = { ...selectedCoordinants };
    const newLocationsObject = {
      Description: locationDescription,
      Guid: 0,
      Id: 0,
      Qty: 0,
      UOM: 0,
      lat: selectedCoordinantsCopy.lat,
      lng: selectedCoordinantsCopy.lng
    };
    
    selectedLocationsCopy.push(newLocationsObject);

    this.setState({
      selectedLocations: selectedLocationsCopy,
      locationObject: newLocationsObject,
      locationDescription: '',
      selectedCoordinants: '',
    }, handleUpdateSelectedLocation(newLocationsObject));
  };

  handleAddLocation = () => {    
    this.setState({
      addingLocationMode: true,
    });
  };

 //TODO: here --> move to new object structure
  handleUpdateLocationObject = () => {
    const { 
      locationObject,
      selectedCoordinants,
      locationDescription,
     } = this.state;
    const locationObjectCopy = { ...locationObject };
    locationObjectCopy.location = selectedCoordinants;
    locationObjectCopy.description = locationDescription;
    
    this.setState({
      locationObject: locationObjectCopy,
    })
  };

  handleNextClick = () => {
    this.setState({
      nextSelected: true,
    })
  };

  handleBackClick = () => {
    this.setState({
      backSelected: true,
    })
  };

  handleResetNextSelected = () => {
    this.setState({
      nextSelected: false,
    })
  };

  handleResetDisabled = () => {
    this.setState({
      disabled: true,
    })
  };

  handleDescriptionChange = (description) => {
    const {locationDescription}=this.state;
    this.setState({
      locationDescription: description,
    })
  };

  handleToggleLocationClick = () => {
    const { selectingLocation } = this.state;
    this.setState({
      selectingLocation: !selectingLocation,
    })
  };

  handleCoordinantClick = (latLng) => {
    const { addingLocationMode } = this.state;
    if(addingLocationMode){
      this.setState({ 
        selectedCoordinants: latLng,
        addingLocationMode: false,
      }, this.handleToggleLocationClick())
    }
  };

  handleRemoveFromSelectedLocations = (location) => {
    const { selectedLocations } = this.state;
    const savedLocations = [ ...selectedLocations ];
    const locationToDeleteId = savedLocations.findIndex(savedLocation => savedLocation.description == location.description && location.location == savedLocation.location);
    savedLocations.splice(locationToDeleteId, 1);

    this.setState({
      deletedLocation: location,
      selectedLocations: savedLocations,
    })
  };

  render() {
    const {
      leakResponse,
      editedLeakResponse,
      stepThreeComplete,
      width,
    } = this.props;

    const {      
      addingLocationMode,
      selectedCoordinants,
      locationDescription,
      selectedLocations,
    } = this.state;    

    return(
      <Grid container direction='row' alignItems='center' justify='center'>
        <Grid item xs={12}>
          <Card square style={{ boxShadow: 'none' }}>
            <Grid container direction='row' alignItems='center' justify='center'>

              <Grid container direction='row' alignItems='center' justify='center' style={{ display: 'flex', padding: '1%' }}>
                <Grid item xs={12} style={{ backgroundColor: 'gainsboro', overflow: 'auto' }}>
                  
                  <Grid container direction='row'>
                    <Grid xs={12} sm={12} md={9} lg={9} xl={9} style={width > 959 ? { minHeight: '26rem' } : { minHeight: '17rem' } }>

                      <Grid container direction='row' alignItems='center' justify='center' style={{ width:'100%' }}>
                         <Grid item xs={8} style={{ height: '100%', backgroundColor: 'gainsboro' }}>
                            <LeakResponseLocationDescription 
                              handleDescriptionChange={this.handleDescriptionChange}
                              
                              selectedCoordinants={selectedCoordinants}
                              selectedLocations={selectedLocations}
                              locationDescription={locationDescription}
                              width={width}
                            />
                          </Grid>
                          <Grid item xs={4}>
                          <Button variant='contained' onClick={() => this.handleAddLocation()} disabled={addingLocationMode} color='secondary' style={{ width: '97%' }}>{width > 959 ? 'Add Location' : 'Add' }</Button>
                          </Grid>
                      </Grid>

                      <Grid container direction='row' style={{ height: '75%', paddingLeft: '1%', paddingRight: '1%' }}>
                        <Grid item xs={12}>
                          <Paper square style={{ width: '100%', height: '100%', boxShadow: 'none' }}>
                            <LeakResponseLocationMap
                              handleCoordinantClick={this.handleCoordinantClick}

                              leakResponse={leakResponse}
                              width={width}
                            />
                          </Paper>
                        </Grid>
                      </Grid>
                      { addingLocationMode ?
                        <Grid container direction='row' container direction='row' alignItems='center' style={{ width: '100%', height: '6%',  backgroundColor: 'red'   }}>
                          <Typography style={{ fontSize: 16, width: '100%', color: 'white', fontWeight: 'bold' }} align='center'>Touch Map to Add Leak Location</Typography>
                        </Grid>
                      : null }
                     </Grid> 
                    
                    <Grid xs={12} sm={12} md={3} lg={3} xl={3} style={{ minHeight: '10rem', maxHeight: '25.5rem' }}>
                      <LeakResponseLocationList 
                        handleRemoveFromSelectedLocations={this.handleRemoveFromSelectedLocations}

                        leakResponse={leakResponse}
                        selectedLocations={selectedLocations}
                        selectedCoordinants={selectedCoordinants}
                        width={width}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction='row' alignItems='center' justify='center' style={{ position: 'fixed', bottom: '1%', width: '97.5%', left: '1.5%', backgroundColor: '#66BFFA' }}>
                <Grid item xs={12} style={{ width: '100%' }}>
                  <Grid container direction='row' alignItems='center' justify='space-evenly'>
                    <Grid item xs={3} style={{ textAlign: 'center', padding: '5px' }}>
                      <Button variant='contained' color='default' style={{ boxShadow: 'none' }} onClick={ () => this.handleBackClick() }>Back</Button>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'center', padding: '5px' }} >
                      <Button variant='contained' color='default' disabled={!stepThreeComplete} onClick={()=>this.handleNextClick()} style={{ boxShadow: 'none' }}>Next</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Card>
        </Grid>
      </Grid>
    )
  }
}

export default LeakResponseLocation
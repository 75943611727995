import React, { useEffect, useState } from 'react';
import {
  Avatar,
  List,
  ListItem,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
  Grid,
  Typography,
  Collapse,
  IconButton,
  Divider,
  Chip,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
    margin: '2%',
    overflowY:'hidden',
    fontFamily: 'Ebrima'
  },
  media: {
    paddingTop: '74.25%',
    objectFit: 'contain',
    minHeight: '8rem',
    margin: '1%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  chip: {
    label: {
      paddingLeft: '3px',
      paddingRight: '3px',
      fontFamily: 'Ebrima'
    }
  }
}));

export default function PropertyResultsCard(props) {
  const classes = useStyles();
  const [area, setArea] = useState(0);
  const [capital, setCapital] = useState(0);
    const [repair, setRepair] = useState(0);
    const [oe, setOe] = useState(0);
  const [ersl, setErsl] = useState(0);
  const [rcs, setRcs] = useState(0);
  const [rcsColor, setRcsColor] = useState('');
  const [ surveyBy, setSurveyBy ] = useState('');
  const [ surveyDate, setSurveyDate ] = useState('');
  const [titleHovered, setTitleHovered] = useState(false);
  const [cardHovered, setCardHovered] = useState(null);

  const { 
    handleOpenModal,
    handleOpenDetailModal,
    searchResult,
    onCardClick,
    clickedMarker,
    clickedCard,
    materialType,
   } = props;

  const { 
    Id, 
    Title, 
    Description, 
    Images,
  } = searchResult;

  let id = parseFloat(Id);
  
  let imageUrl;
  if (Images && Images.length > 0) {
    imageUrl = `https://client.roof-express.com/ws/thumbimg.aspx?id=${Images[0].MediaId}`;
  } else {
    imageUrl = 'https://www.mayline.com/products/images/product/noimage.jpg';
  }

  const handleTitleHovered = ({currentTarget}) => {
    const titleId = parseFloat(currentTarget.id);
    setTitleHovered(titleId);
  };

  const handleTitleBlur = () => {
    setTitleHovered(null);
  };

  const handleCardHover = ({ currentTarget }) => {
    const cardHoveredId = parseFloat(currentTarget.id);
    setCardHovered(cardHoveredId);
  };

  const handleCardBlur = () => {
    setCardHovered(false);
  };

  const handleCardClicked = ({currentTarget}) => {
    onCardClick(searchResult);
  };

  useEffect(() => {
    if (searchResult) {
      const searchResultExists = Object.values(searchResult).length;
      if (searchResultExists) {
        const {
          CurrentBudget,
          Repair,
          SurveyBy,
            SurveyDate,
          Oe,
        } = searchResult;

          if (Repair) {
              setRepair(Repair);
          }

          if (Oe) {
              setOe(Oe);
          }

        if (SurveyBy) {
          setSurveyBy(SurveyBy);
        }

        if (SurveyDate) {
          setSurveyDate(SurveyDate);
        }
        
        if (CurrentBudget) {
          const currentBudgetExists = Object.values(CurrentBudget).length;
          if (currentBudgetExists) {
            const {
              Area,
              Capital,
              StartErsl,
                StartCs,
                Repair,
              Oe,
            } = CurrentBudget;
            setArea(Area);
            setCapital(Capital);
            setErsl(StartErsl);
              setRcs(StartCs);
              setOe(Oe);
              setRepair(Repair);

            if(StartCs >= 80){
              //green
              setRcsColor('rgb(20, 167, 108)');
            }else if (StartCs >= 61) {
              //yellow
              setRcsColor('rgb(255, 228, 0)');
            }else{
              //red
              setRcsColor('rgb(247, 70, 73)');
            }
          }
        }
      }
    }
  }, [searchResult]);

  return (
    <Card id={id} className={classes.card} style={cardHovered == id || clickedCard == id || clickedMarker == id ? { backgroundColor: 'rgb(233, 233, 233)' } : { backgroundColor: 'white' } } onClick={ handleCardClicked } onMouseOver={ handleCardHover } onMouseOut={ handleCardBlur }>
      <Grid container>
        <Grid container direction='row'>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{padding: '1%'}} onClick={() => handleOpenModal(Images)}>
            <CardMedia
              className={classes.media}
              image={ imageUrl }
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Grid container direction='row'>
              <Grid item xs={12}>
                <CardHeader
                  style={{ padding: 0, textAlign: 'center' }}
                  title={<Typography id={id} gutterBottom={false} onClick={handleOpenDetailModal} onMouseOver={handleTitleHovered} onMouseOut={handleTitleBlur} style={titleHovered == id ? { color: '#0672BA', cursor: 'pointer', padding: '0',   fontFamily: 'Ebrima', } : { padding: '0',   fontFamily: 'Ebrima', }}>{Title}</Typography>}
                  subheader={<a style={{ color: 'inherit', cursor: 'pointer', fontSize: '90%' }}>{Description}</a>}
                />
              </Grid>
              <Grid item xs={12} style={{ backgroundColor: '#00AAAE', color: 'white', textAlign: 'center', fontSize: '90%', overflowY: 'auto' }}>
                { surveyDate } { surveyBy } 
              </Grid>
              <Grid item xs={12} style={{ padding: '1%' }}>
                <Divider style={{ marginTop: '2%', marginBottom: '2%' }} />
                <CardContent style={{ padding: '0' }}>
                  <Grid container direction='row'>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <List style={{ padding: '0', width: '90%' }}>
                        <ListItem style={{ padding: '0' }}>
                          <Grid container direction='row'>
                            <Grid item xs={6}>
                              <span style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'left', fontSize: '75%' }}>Capital:</span>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                              <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>$</span>
                              {new Intl.NumberFormat('en').format(capital)}
                            </Grid>
                          </Grid>
                        </ListItem>
                        <ListItem style={{ padding: '0' }}>
                          <Grid container direction='row'>
                            <Grid item xs={6}>
                              <span style={{ color: 'rgba(0, 0, 0, 0.54)', textAlign: 'left', fontSize: '75%' }}>Expenses:</span>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                              <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>$</span>
                              {new Intl.NumberFormat('en').format(oe)}
                            </Grid>
                          </Grid>
                        </ListItem>
                        <ListItem style={{ padding: '0' }}>
                          <Grid container direction='row'>
                            <Grid item xs={6}>
                              <span style={{ color: 'rgba(0, 0, 0, 0.54)', textAlignt: 'left', fontSize: '75%' }}>Repair:</span>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                              <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>$</span>
                              {new Intl.NumberFormat('en').format(repair)}
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <List style={{ padding: '0', width: '90%' }}>
                        <ListItem style={{ padding: '0' }}>
                          <Grid container direction='row'>
                            <Grid item xs={6}>
                                                          <span style={{ color: 'rgba(0, 0, 0, 0.54)', textAlignt: 'left', fontSize: '75%' }}>{materialType == 1 ? 'Area:' : (materialType == 6 ? '# of Units:' : 'Area:')}</span>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                              {new Intl.NumberFormat('en').format(area)}
                            </Grid>
                          </Grid>
                        </ListItem>
                        <ListItem style={{ padding: '0' }}>
                          <Grid container direction='row'>
                            <Grid item xs={6} style={{ textAlign: 'left' }}>
                                                          <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '75%' }}>{materialType == 1 ? 'RCS:' : (materialType == 6 ? 'Criticality:' : 'PCI:')}</span>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                              <Chip className={classes.card} size='small' style={{ height: 'unset', backgroundColor: rcsColor }} label={ new Intl.NumberFormat('en').format(rcs) } />
                            </Grid>
                          </Grid>
                        </ListItem>
                        <ListItem style={{ padding: '0' }}>
                          <Grid container direction='row'>
                            <Grid item xs={6} style={{ textAlign: 'left' }}>
                              <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '75%', fontFamily:'Ebrima', }}>ERSL:</span>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right', fontFamily:'Ebrima', }}>
                              {new Intl.NumberFormat('en').format(ersl)}
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider style={{ marginTop: '2%', marginBottom: '2%' }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    height: '100%',
  },
  inline: {
    display: 'inline',
  },
}));

export default function LeakResponseCommentList(props) {
  const [comments, setComments] = useState([]);
  const { 
    leakResponse, 
  } = props;

  useEffect(() => {
    if (leakResponse) {
      const leakResponseExists = Object.values(leakResponse).length;
      if (leakResponseExists) {
        const {
          Comments,
        } = leakResponse;
        if (Comments) {
          setComments(Comments);
        }
      }
    }
  }, [leakResponse]);

  const classes = useStyles();
  return (
    <List className={classes.root}>
     { comments.map((comment) => {
        const text = comment.Comment;
        const author = comment.Author;
        const name = author.UserName;
        return (
          <div>
            <ListItem alignItems="flex-start">
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                    { name }
                    </Typography>
                    {` - ${text}`}
                  </React.Fragment>
                }
              />
              <Divider variant="inset" component="li" />
            </ListItem>
          </div>
        )
      }) }
    </List>
  );
}

import React, { Component } from 'react';
import dotnetify from 'dotnetify';
import auth from '../../auth';
import AddLeakResponseModal from './AddLeakResponse/AddLeakResponseModal';
import LeakResponseCard from './LeakResponseCard';
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
// import S3FileUpload from 'react-s3';
import * as AWS from 'aws-sdk';


const requestStep = 0;
const contactStep = 1;
const locationStep = 2;

class LeakResponse extends Component {
  constructor(props){
    super(props);
    this.vm = dotnetify.react.connect('LeakResponses', this, {
       
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      exceptionHandler: ex => {
        console.log(ex);
          auth.signOut();
      }
    });
    this.dispatch = state => this.vm.$dispatch(state);
    this.routeTo = route => this.vm.$routeTo(route);
    
    this.state = {
      Results: [],
      PropertyList: [],
      IdentityId: null,
      Token: null,

      propertyList: [],
      selectedLocations: [],
      selectedDocuments: [],
      addedFiles: [],
      leakResponseComments: [],

      selectedWizardStep: 0,
      uploadProgress: 0,

      selectedLeakResponse: {},
      editedLeakResponse: {},
      selectedTag: {},
      selectedProperty: {},
      selectedLocation: {},
      modifiedRequester: {},
      modifiedContact: {},
      removedDocument: {},

      leakResponseDescription: '',
      reviewComments: '',

      addLeakResponse: false,
      emergencyLeakResponse: false,
      requesterIsUser: true,
      contactIsRequester: true,
      leakResponseSubmitted: false,
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      LeakResponse, 
      UserTags,
      PropertyList,
      SelectedTag,
      SelectedProperty,

      existingLeakResponse,
      selectedLeakResponse,
      leakResponse,
      existingEmergency,
      emergencyLeakResponse,
      leakResponseDescription,  
      selectedWizardStep, 
      selectedTag,
      selectedProperty,
      selectedLocations,
      selectedLocation,
      locationDescription,
      locationCoordinants,
      selectedDocuments,
      addedFiles, 
      removedDocument,
      reviewComments,
      leakResponseComments,
      leakResponseSubmitted,
      leakResponseSaved,
      routingToList,
    } = this.state;

    const previousSelectedLeakResponse = prevState.selectedLeakResponse;
    const previousLeakResponse = prevState.LeakResponse;
    const previousPropertyList = prevState.PropertyList;
    const previousEmergencyLeakResponse = prevState.emergencyLeakResponse;
    const previousLeakResponseDescription = prevState.leakResponseDescription;
    const previousSelectedWizardStep = prevState.selectedWizardStep;
    const previousSelectedTag = prevState.selectedTag;
    const previousSelectedProperty = prevState.selectedProperty;
    const previousSelectedPropertyObj = prevState.SelectedProperty;
    const previousSelectedLocations = prevState.selectedLocations;
    const previousSelectedLocation = prevState.selectedLocation;
    const previousSelectedDocuments = prevState.selectedDocuments;
    const previousAddedFiles = prevState.addedFiles;
    const previousRemovedDocument = prevState.removedDocument;
    const previousReviewComments = prevState.reviewComments;
    const previousLeakResponseComments = prevState.leakResponseComments;
    const previousLeakResponseSubmitted = prevState.leakResponseSubmitted;
    const previousLeakResponseSaved = prevState.leakResponseSaved;
    const previousRoutingToList = prevState.routingToList;

    //handles opening of add modal, creates edited leak response obj, pulls out dropdown selections
    if (previousLeakResponse != LeakResponse) {
      if (!previousLeakResponse) {
        if (LeakResponse) {
          const editedLeakResponse = { ...LeakResponse };
          const userTags = [ ...UserTags ];
          const existingDocuments = LeakResponse.Files;
          const existingEmergency = LeakResponse.Emergency;
          const leakResponseId = LeakResponse.LeakResponseId;
          const existingLeakResponse = leakResponseId ? true : false;

          this.setState({
            existingLeakResponse,
            existingEmergency,
            existingDocuments,
            editedLeakResponse,
            userTags,
            addLeakResponse: true,
            selectedTag: SelectedTag,
          })
        }
      }
      
      if (previousLeakResponse) {
        const userTags = [ ...UserTags ];
        const existingEmergency = LeakResponse.Emergency;

        this.setState({
          userTags,
          existingEmergency,
        })
      }
    }

    if (selectedLeakResponse != previousSelectedLeakResponse) {
      const selectedLeakResponseExists = Object.values(selectedLeakResponse).length;
      if (selectedLeakResponseExists) {
        this.dispatchUpdateSelectedLeakResponse();
      }
    }

    if (LeakResponse) {

      //if loading, load on review
      if (existingLeakResponse) {
        if (LeakResponse != previousLeakResponse) {
          // this.handleIncrementWizardStep(4);
        }
      }

      if (emergencyLeakResponse != previousEmergencyLeakResponse) {
        if (existingEmergency != emergencyLeakResponse)
          this.handleUpdateEmergencyDispatch();
        }

      if (leakResponseDescription != previousLeakResponseDescription) {
        this.handleUpdateDescriptionDispatch();
      }

      if (selectedWizardStep != previousSelectedWizardStep) {
        this.handleWizardStepDispatch();
      }

      if (PropertyList != previousPropertyList) {
        this.handleSavePropertyList();
      }

      if (selectedTag != previousSelectedTag) {
        if (selectedTag) {
          this.handleUpdateSelectedTagDispatch();
        }
      }

      if (selectedProperty != previousSelectedProperty) {
        if (selectedProperty) {
          this.handleUpdateSelectedPropertyDispatch();
        }
      }

      if (selectedLocations != previousSelectedLocations) {
        if (selectedLocations && previousSelectedLocations) {
          this.handleUpdateLocationDispatch();
        }      
      }

      if (selectedDocuments != previousSelectedDocuments) {
        if (selectedDocuments.length) {
          this.handleUploadToS3();
        }
      }

      if (addedFiles != previousAddedFiles) {
        if (addedFiles.length) {
          this.handleUpdateDocumentsDispatch();
        }
      }

      if (removedDocument != previousRemovedDocument) {
        if (removedDocument) {
          const removedDocumentExists = Object.values(removedDocument).length;
          if (removedDocumentExists) {
            this.handleRemoveDocumentDispatch();
          }
        }
      }

      if (reviewComments != previousReviewComments) {
        this.handleUpdateReviewComments();
      }
    }

    if (
      previousSelectedWizardStep == requestStep &&
      selectedWizardStep == contactStep
    ) {
      const editedRequestLeakResponse = this.mergeRequestIntoLeakResponse();
      this.setState({
        editedLeakResponse: editedRequestLeakResponse,
      })
    }

    if (
      previousSelectedWizardStep == contactStep && 
      selectedWizardStep == locationStep
      ) {
        const editedContactsLeakResponse = this.mergeContactsIntoLeakResponse();
        this.setState({
          editedLeakResponse: editedContactsLeakResponse,
        })
      }

    if (leakResponseSubmitted != previousLeakResponseSubmitted) {
      if (leakResponseSubmitted && !leakResponseSaved) {
          this.handleSubmitLeakResponseDispatch();
        }
      }

    if (leakResponseSaved != previousLeakResponseSaved) {
      if (leakResponseSubmitted && leakResponseSaved) {
        if (JSON.stringify(leakResponse) != JSON.stringify(previousLeakResponse)) {
          this.setState({
            leakResponseSaved: false,
            leakResponseSubmitted: false,
          }, this.handleNavigateToLeakResponseList());
        }
      }
    }

    if (routingToList != previousRoutingToList) {
      if (routingToList) {
        if (LeakResponse != previousLeakResponse) {
          if (!LeakResponse && previousLeakResponse) {
            this.setState({
              routingToList: false,
            }, this.handleCloseLeakResponseModal());
          }
        }
      }
    }
  };

  mergeRequestIntoLeakResponse = () => {
    const {
      editedLeakResponse,
      leakResponseDescription,
      emergencyLeakResponse,
    } = this.state;
    const editedLeakResponseCopy = { ...editedLeakResponse };
    editedLeakResponseCopy.Description = leakResponseDescription;
    editedLeakResponseCopy.Emergency = emergencyLeakResponse;
    return editedLeakResponseCopy;
  };
 
  mergeContactsIntoLeakResponse = () => {
    const {
      editedLeakResponse, 
      modifiedContact, 
      modifiedRequester 
    } = this.state;
    const editedLeakResponseCopy = { ...editedLeakResponse };
    editedLeakResponseCopy.Requester = modifiedRequester;
    editedLeakResponseCopy.PropertyContact = modifiedContact;
    return editedLeakResponseCopy;
  };

  handleRemoveDocumentDispatch = () => {
    const { removedDocument } = this.state;
    console.log('dispatching remove file')
    console.log(removedDocument)
    this.setState({
      removedDocument: {},
    }, this.dispatch({ RemoveFile: removedDocument }));
  };

  handleIncrementWizardStep = (step) => {
    this.setState({
      selectedWizardStep: step,
    })
  };

  handleWizardStepDispatch = () => {
    const { selectedWizardStep } = this.state;
    console.log('dispatch update wizard step')
    console.log(selectedWizardStep)
    this.dispatch({ UpdateWizardStep: selectedWizardStep });
  }; 

  handleAddLeakResponse = () => {
    const { NavRoutes } = this.state;
    if (NavRoutes) {
      const addRoute = NavRoutes[1].Route;
      this.routeTo(addRoute);
    }
  };

  handleUpdateSelectedLeakResponse = (selectedLeakResponse) => {
    this.setState({
      selectedLeakResponse,
    });
  };

  dispatchUpdateSelectedLeakResponse = () => {
    const { selectedLeakResponse } = this.state;
    const route = selectedLeakResponse.Route;
    this.routeTo(route);
  };

  handleNavigateToLeakResponseList = () => {
    const { NavRoutes } = this.state;
    if (NavRoutes) {
      const listRoute = NavRoutes[0].Route;
      this.setState({
        routingToList: true,
      }, this.routeTo(listRoute));
    }
  };

  handleCloseLeakResponseModal = () => {
    this.setState({
      addLeakResponse: false,
    })
  };

  //Step 1
  handleEmergencySwitch = (bool) => {
    this.setState({
      emergencyLeakResponse: bool,
    })
  };

  handleUpdateEmergencyDispatch = () => {
    const { emergencyLeakResponse, LeakResponse } = this.state;
    const {
      Emergency,
    } = LeakResponse;

    if (Emergency != emergencyLeakResponse) {
      console.log('update emergency dispatch')
      console.log(emergencyLeakResponse);
      this.dispatch({ UpdateEmergency: emergencyLeakResponse });
    }
  };

  handleUpdateDescription = (description) => {
    this.setState({
      leakResponseDescription: description,
    })
  };

  handleUpdateDescriptionDispatch = () => {
    const { leakResponseDescription } = this.state;
    console.log('update description dispatch')
    console.log(leakResponseDescription)
    this.dispatch({ UpdateDescription: leakResponseDescription })
  };

  //Step 2
  handleSavePropertyList = () => {
    const { PropertyList } = this.state;
    this.setState({
      propertyList: PropertyList,
    })
  };

  handleUpdateSelectedTag = (selectedTag) => {
    this.setState({
      selectedTag,
    })
  };

  handleUpdateSelectedTagDispatch = () => {
    const { selectedTag, SelectedTag, LeakResponse } = this.state;
    const selectedTagId = selectedTag.OrgUnitId;
    const leakResponseTagId = (SelectedTag != null?SelectedTag.OrgUnitId:0);

    if (SelectedTag == null || selectedTagId != leakResponseTagId) {
      console.log('update selected tag dispatch')
      console.log(selectedTag, SelectedTag)
      this.dispatch({ UpdateSelectedTag: selectedTag });
    }
  };

  handleUpdateSelectedBuilding = (selectedProperty) => {
    this.setState({
      selectedProperty,
    })
  };

  handleUpdateSelectedPropertyDispatch = () => {
    const { selectedProperty, LeakResponse } = this.state;
    const {
      Property,
    } = LeakResponse;
       
    if (selectedProperty) {
      const selectedPropertyExists = Object.values(selectedProperty).length;
      if (selectedPropertyExists) {
        const selectedPropertyId = selectedProperty.Id;
        const propertyId = Property ? Property.Id : null;
        if (selectedPropertyId != propertyId) {
          console.log('update selected property dispatch')
          console.log(selectedProperty, Property)
          this.dispatch({ UpdateSelectedProperty: selectedProperty });
        }
      }
    }
  };

  handleUpdateModifiedRequester = (modifiedRequester) => {
    this.setState({
      modifiedRequester,
    })
  };

  handleUpdateModifiedContact = (modifiedContact) => {
    this.setState({
      modifiedContact,
    })
  };

  handleUpdateStepTwoComplete = (bool) => {
    this.setState({
      stepTwoComplete: bool,
    })
  };

  //Step 3
  handleUpdateLocationDescription = (locationDescription) => {
    this.setState({
      locationDescription,
    })
  };

  handleUpdateLocationCoordinants = (locationCoordinants) => {
    this.setState({
      locationCoordinants,
    })
  };

  handleUpdateSelectedLocation = (selectedLocation) => {
    this.setState({
      selectedLocation,
    })
  };

  handleUpdateSelectedLocations = (selectedLocations) => {
    this.setState({
      selectedLocations,
    })
  };

  handleUpdateRemovedLocation = (removedLocation) => {
    this.setState({
      removedLocation,
    })
  };

  handleUpdateLocationDispatch = () => {
    const { selectedLocations, LeakResponse } = this.state;
    const { Locations } = LeakResponse;

    if (selectedLocations.length > Locations.length) {
      this.handleAddLocationDispatch();
    }

    if (selectedLocations.length < Locations.length) {
      this.handleRemoveLocationDispatch();
    }
  };

  handleAddLocationDispatch = () => {
    const { selectedLocation, LeakResponse } = this.state;
    const { Description, lat, lng } = selectedLocation;
    const { Guid } = LeakResponse;
    const addLocation = {
      Id: 0,
      UOM: 0,
      Qty: 1,
      Guid: Guid,
      Description: Description,
      lat: lat,
      lng: lng,
    };
    //guards against firing on initialization where lat & lng are 'undefined'
    if (lat && lng) {
      this.setState({
        selectedLocation: {},
      }, this.dispatch({ AddLocation: addLocation }), console.log('Add Location Dispatch'), console.log(addLocation));
    }
  };

  handleRemoveLocationDispatch = () => {
    const { removedLocation, LeakResponse } = this.state;
    const { description, lat, lng } = removedLocation;
    const { Guid } = LeakResponse;

    const removeLocation = {
      Id: 0,
      UOM: 0,
      Qty: 1,
      Guid: Guid,
      Description: description,
      lat: lat,
      lng: lng,
    };

    //guards against firing on initialization where lat & lng are 'undefined'
    if (lat && lng) {
      this.setState({
        removedLocation: {},
      }, this.dispatch({ RemoveLocation: removeLocation }), console.log('Remove Location Dispatch'), console.log(removeLocation));
    }
  };

  handleUpdateStepThreeComplete = (bool) => {
    this.setState({
      stepThreeComplete: bool,
    })
  };

  //Step 4
  handleUpdateDocuments = (selectedDocuments) => {
    this.setState({
      selectedDocuments,
    })
  };

  handleRemovedDocument = (removedDocument) => {
    this.setState({
      removedDocument,
    })
  };

  //Step 5
  handleUpdateComment = (reviewComments) => { 
    this.setState({
      reviewComments: reviewComments,
    })
  };

  handleUpdateReviewComments = () => {
    const { reviewComments, ActiveUser } = this.state;
    const dispatchAddComment = {
      Id: 0,
      CreatedDate: new Date(),
      Author: ActiveUser,
      Comment: reviewComments,
    };
    console.log('dispatching addComment')
    console.log(dispatchAddComment)
    this.dispatch({ AddComment: dispatchAddComment });
  };

  handleSubmitLeakResponse = () => {
    this.setState({
      leakResponseSubmitted: true,
    });
  };

  uploadDocument = (document) => {
    const { SelectedTag, LeakResponse, IdentityId, Token } = this.state;
    const { Property, Guid } = LeakResponse;
    const { CompanyId } = SelectedTag;
    const { Id, Name } = Property;
    const { type, name } = document;

    const guid = LeakResponse.Guid;
    const dirName = `leakresponse/${guid}`;

    AWS.config.region = 'us-west-2';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: IdentityId,
      Logins: {
        'cognito-identity.amazonaws.com': Token
      }
    });

    const s3 = new AWS.S3;

    s3.config.region = 'us-west-2';
    s3.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-west-2:7639f83e-05ce-41b2-bfd7-a6a1954cffda',
      IdentityId: IdentityId,
      Logins: {
        'cognito-identity.amazonaws.com': Token
      }
    });

    const blob = new Blob([document], { type: 'text/plain' });

    const parameters = {
      bucket: 'leakresponse',
      key: `${dirName}/${name}`,
      s3: s3,
      stream: blob,
      acl: 'public-read',
      contentType: type
    };

    return new Promise((resolve, reject) => {
      this.uploadBlob(parameters)
      .then(result => {
        const url = result.key;
        
        const addedFileObject = {
          Id: 0,
          CompanyId: CompanyId,
          BuildingId: Id,
          BuildingName: Name,
          Notes: '',
          FileUrl: url,
          FileName: name,
          FileType: 3, //hardcoded? ask dewayne --> set to back end value --> needs to loop through backend array, not sure if thats possible
          OrgTags: null,
          Guid: Guid,
          FileBlob: '',
        };
        resolve(addedFileObject);
        reject(Error);
      })
      .catch(err => {
        console.error(err);
      }); 
    })
  };

  uploadBlob = async (parameters) => {
    const {
      bucket,
      key,
      s3,
      stream,
      acl,
      contentType
    } = parameters;
    const params = { Bucket: bucket, Key: key, Body: stream, ACL: acl, ContentType: contentType };
    return s3.upload(params).promise();
  };

  handleUploadToS3 = () => {
    const { selectedDocuments } = this.state;
    const promises = [];

    selectedDocuments.map((document) => {
      const upload = this.uploadDocument(document);
      promises.push(upload);
    });

    Promise.all(promises)
      .then(response => {
        this.setState({
          addedFiles: response,
          selectedDocuments: [],
        })
      })
      .catch(err => console.error(err));
  };

  handleUpdateDocumentsDispatch = () => {
    const { addedFiles } = this.state;
    console.log('dispatch AddNewFile');
    console.log(addedFiles)
    this.dispatch({ AddNewFiles: addedFiles });
    this.setState({
      addedFiles: [],
    })
  };

  handleSubmitLeakResponseDispatch = () => {
    const { LeakResponse } = this.state;
    const savedLeakResponseCopy = { ...LeakResponse };
    console.log('SaveLeakResponse dispatch');
    this.setState({
      leakResponseSaved: savedLeakResponseCopy,
    }, this.dispatch({ SaveLeakResponse: '' }));
  };

  componentWillUnmount = () => {
    console.log('unmounting LeakResponse');
    this.vm.$destroy();
  };

  render() {
    const {
      Results,
      LeakResponse,
      
      userTags,
      propertyList,

      editedLeakResponse,
      selectedWizardStep,
      addLeakResponse,

      selectedProperty,
      requesterIsUser,
      contactIsRequester,

      stepTwoComplete,
      stepThreeComplete,

      uploadProgress,
      selectedTag,

      selectedDocuments,
      existingDocuments,
      leakResponseComments,
    } = this.state;
            
    return (
      <Grid container direction='row' style={{ height: '100%', width: '100%' }} alignItems='center' justify='center' id='container'>
        <AddLeakResponseModal 
          handleIncrementWizardStep={this.handleIncrementWizardStep}
          handleEmergencySwitch={this.handleEmergencySwitch}
          handleUpdateDescription={this.handleUpdateDescription}
          handleUpdateSelectedTag={this.handleUpdateSelectedTag}
          handleUpdateSelectedBuilding={this.handleUpdateSelectedBuilding}
          handleUpdateModifiedRequester={this.handleUpdateModifiedRequester}
          handleUpdateModifiedContact={this.handleUpdateModifiedContact}
          handleUpdateStepTwoComplete={this.handleUpdateStepTwoComplete}
          handleUpdateLocationDescription={this.handleUpdateLocationDescription}
          handleUpdateLocationCoordinants={this.handleUpdateLocationCoordinants}
          handleUpdateSelectedLocation={this.handleUpdateSelectedLocation}
          handleUpdateSelectedLocations={this.handleUpdateSelectedLocations}
          handleUpdateRemovedLocation={this.handleUpdateRemovedLocation}
          handleUpdateStepThreeComplete={this.handleUpdateStepThreeComplete}
          handleUpdateDocuments={this.handleUpdateDocuments}
          handleRemovedDocument={this.handleRemovedDocument}
          handleUpdateComment={this.handleUpdateComment}
          handleSubmitLeakResponse={this.handleSubmitLeakResponse}
          handleNavigateToLeakResponseList={this.handleNavigateToLeakResponseList}
          handleCloseLeakResponseModal={this.handleCloseLeakResponseModal}

          leakResponse={LeakResponse}
          userTags={userTags}
          propertyList={propertyList}
          editedLeakResponse={editedLeakResponse}
          selectedWizardStep={selectedWizardStep}
          addLeakResponse={addLeakResponse}
          selectedProperty={selectedProperty}         
          requesterIsUser={requesterIsUser}
          contactIsRequester={contactIsRequester}
          stepTwoComplete={stepTwoComplete}
          stepThreeComplete={stepThreeComplete}
          uploadProgress={uploadProgress}
          selectedTag={selectedTag}
          selectedDocuments={selectedDocuments}
          existingDocuments={existingDocuments}
          leakResponseComments={leakResponseComments}
        />
        <Grid item xs={2} style={{textAlign: 'center'}}>
          <Button variant='contained' color='default' onClick={()=>this.handleAddLeakResponse()}>Add Leak Response</Button>
        </Grid>
        <Grid container direction='row' alignItems={'center'} justify={'center'} style={{ position: 'relative', height: '73%', width: '100%', overflow: 'auto', backgroundColor: 'lightGrey' }}>
           { Results.map((leakResponse, i) => {
              return (
                <Grid item style={{ margin: '1%' }}>
                  <LeakResponseCard
                    i={i}
                    handleUpdateSelectedLeakResponse={this.handleUpdateSelectedLeakResponse}
                    handleEditModalOpen={this.handleEditModalOpen}
                    handleDeleteModalOpen={this.handleDeleteModalOpen}
                    leakResponse={leakResponse}
                  />
                </Grid>
              )
            }) }
        </Grid>
      </Grid>
    )
  }
};

export default LeakResponse

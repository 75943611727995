import React from 'react';
import {SvgIcon} from '@material-ui/core';

let SvgWORepair = (props) => (
  <SvgIcon {...props} >
       <path d="M23.8,5.1a.5487.5487,0,0,0-.9-.3L19.4,8.3l-3.2-.5-.5-3.2,3.5-3.5a.5187.5187,0,0,0-.3-.9A6.4808,6.4808,0,0,0,12.5,2c-1.9,1.9-1.7,3.8-.9,6.2L.9,18.9a2.9,2.9,0,0,0,0,4.2,2.9,2.9,0,0,0,4.2,0L15.8,12.4c2.3.8,4.3,1,6.2-.9A6.4808,6.4808,0,0,0,23.8,5.1ZM3.3,22.4A1.7,1.7,0,1,1,5,20.7,1.7524,1.7524,0,0,1,3.3,22.4Z" transform="translate(0 0.025)"/>
  </SvgIcon>
);

export default SvgWORepair;

import React, { PureComponent } from 'react';
import PropertiesMapCard from '../../Properties/PropertiesResults/Components/PropertiesMapCard';
import {
  Grid,
  Paper,
  Typography,  
} from '@material-ui/core';
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import ScriptLoaded from '../../GoogleMaps/LoadScript/LoadScript';
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";

export default function PropertiesMapHook(props) {
  const {
    mobile,
    //functions
    setReloadingFalse,
    handleOpenDetailModal,
    onMapClick,
    onMarkerClick,
    //state values
    searchResults,
    originalResults,
    clickedCard,
    clickedMarker,
    selectedPosition,
    //required map props
    isMarkerShown,
    containerElement,
    mapElement,
  } = props;

  const mapElementStyle = { height: `100%` };

  const mapContainerElementStyle = {
    position: 'absolute',
    height: '100%',
    width: '66%',
    marginLeft: '34%',
  };

  const mapContainerElementMobile = {
    position: 'absolute',
    height: '67%',
    width: '99%',
  };

  return(
    <Grid container direction='row' style={{ width: '100%', height: '100%' }}>
      <Grid xs={12}>
        <ScriptLoaded>
          <PropertiesMap 
            onMapClick={onMapClick}
            onMarkerClick={onMarkerClick}
            setReloadingFalse={setReloadingFalse}
            handleOpenDetailModal={handleOpenDetailModal}
            searchResults={searchResults}
            originalResults={originalResults}
            clickedCard={clickedCard}
            clickedMarker={clickedMarker}
            selectedPosition={selectedPosition}
            isMarkerShown={true}
            containerElement={<div style={mobile ? mapContainerElementMobile : mapContainerElementStyle} />}
            mapElement={<div style={mapElementStyle} />}
          />
        </ScriptLoaded>
      </Grid>
    </Grid>
    )
};

const createBounds = (searchResults) => {
  const bounds = new google.maps.LatLngBounds();
  searchResults.map((result, i) => {
    const lat = parseFloat(result.Lat);
    const lng = parseFloat(result.Lng);

    if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
      if (lat != 0 && lng != 0) {
        const latLng = { lat, lng };
        bounds.extend(latLng);
      }
    }
  });
  return bounds;
};

const createCardFunctionality = (handleOpenDetailModal, result, id, event) => {
  const target = document.getElementById(`map-card-title-${id}`)
  target.addEventListener('click', () => handleOpenDetailModal(result));
  target.addEventListener('mouseover', () => createMapCardHover(target));
  target.addEventListener('mouseleave', () => removeMapCardHover(target));
};

const createMapCardHover = (target) => {
  target.style.color = '#0672BA';
};

const removeMapCardHover = (target) => {
  target.style.color = 'black';
};

const PropertiesMap = withGoogleMap(props => {
  const {
    //functions
    setReloadingFalse,
    handleOpenDetailModal,
    onMapClick,
    onMarkerClick,
    //state values
    searchResults,
    originalResults,
    clickedCard,
    clickedMarker,
    selectedPosition,
    // reloading,
  } = props;

  const bounds = searchResults.length > 0 ? createBounds(searchResults) : createBounds(originalResults);

  return (
    <GoogleMap
      ref={map => map && !selectedPosition ? map.fitBounds(bounds) : new google.maps.LatLng(selectedPosition)}
      onClick={() => onMapClick()}
      defaultZoom={13}
      defaultCenter={selectedPosition ? selectedPosition : null}
      onResize={() => setReloadingFalse()}
    >
      {
        searchResults.map((result, i) => {
          const id = result.Id;
          const lat = parseFloat(result.Lat);
          const lng = parseFloat(result.Lng);
          const latLng = { lat: lat, lng: lng };
          const title = result.Title;
          const images = result.Images;
          const address = result.Description;
          const image = result.Images.length > 0 ? result.Images[0].MediaId : null;
          const imageUrl = `https://client.roof-express.com/ws/thumbimg.aspx?id=${image}`
          return (
            <Marker
              onClick={() => onMarkerClick(result)}
              animation={clickedCard == id ? '1' : '0'}
              id={id}
              name={title}
              images={images}
              address={address}
              position={latLng}
            >
              { clickedMarker == id ?
                <InfoBox
                  options={{ enableEventPropagation: false }}
                  onDomReady={() => createCardFunctionality(handleOpenDetailModal, result, id, event)}
                >
                  <PropertiesMapCard
                    id={id}
                    title={title}
                    address={address}
                    imageUrl={imageUrl}
                  />
                </InfoBox>
                : null }
            </Marker>
          )
        })
      }
    </GoogleMap>
  )
});